import { useAppSelector } from 'client/hooks/useAppSelector';
import { useQuery } from 'client/hooks/useQuery';
import {
  deleteArticle,
  getArticles,
  getBlogCover,
} from 'dashboard/_redux/actions/blogArticles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useBlogDashboard = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDelete = (id?: string) => {
    dispatch(deleteArticle(id));
    setOpenDeleteModal(false);
  };

  const loading = useAppSelector((state) => state.loading.loading);
  const articles = useAppSelector(({ dashboradBlog }) => ({
    articles: dashboradBlog.articles,
  }));
  const cover = useAppSelector(({ dashboradBlog }) => dashboradBlog.blogCover);

  const pageNbr = Number(useQuery('page') || '1');

  useEffect(() => {
    dispatch(getBlogCover());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getArticles({ page: pageNbr, limit: 6 }));
  }, [dispatch, pageNbr]);

  return {
    loading,
    articles: articles.articles,
    t,
    openDeleteModal,
    setOpenDeleteModal,
    handleDelete,
    cover,
  };
};
