import { useTranslation } from 'react-i18next';
import { ISearchCriteriaQuery } from 'types/ISearchCriteriaQuery';

export const useGeneralFilter = (searchCriteria?: ISearchCriteriaQuery) => {
  const [t] = useTranslation();

  const formattedDuration =
    searchCriteria?.duration?.map((el) => ({ label: el, value: el })) || [];
  const formattedPrices = searchCriteria?.prices.map((el) => ({ label: el, value: el })) || [];

  return { formattedDuration, t, formattedPrices };
};
