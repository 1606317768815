import Input from 'client/components/Form/Input';
import ImageSliderPicker from 'dashboard/components/ImageSliderPicker';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
import { ICON_LIST_OPTIONS } from 'client/constants/iconsList/iconListOption';
import FormIconSelect from 'dashboard/components/Forms/FormIconSelect';

import FilterSelectionContainer from 'dashboard/components/FilterSelectionContainer';
import LazyImage from 'client/components/ImageCarousel/LazyImage';
import { getCompressedImageUrl } from 'client/helpers/getCompressedImageUrl';
import { IProductEditingValues, useProductEditor } from './useProductEditor';
import ImageLoader from './Loader';

interface IProps {
  onSubmit: SubmitHandler<IProductEditingValues>;
  selectedEditingProduct?: IExhibitedProduct;
  loading?: boolean;
}

const ProductEditor: React.FC<IProps> = ({ onSubmit, selectedEditingProduct, loading }) => {
  const {
    formMethods,
    handleSubmit,
    handleChangeMainImage,
    _onSubmit,
    watch,
    image,
  } = useProductEditor(onSubmit, selectedEditingProduct);

  return (
    <div className=" container border p-4 " style={{ height: '100%', background: '#d7d7d7' }}>
      <div
        className="border p-4"
        style={{
          borderRadius: '8px',
          background: 'white',
          boxShadow: '0px 0.375rem 1.5rem 0px rgb(140 152 164 / 13%) !important',
        }}
      >
        <FormProvider {...formMethods}>
          <form className="mt-5" onSubmit={handleSubmit(_onSubmit)}>
            <div className="d-flex justify-content-between">
              <h1 style={{ textAlign: 'center' }}>{selectedEditingProduct?.name}</h1>
              <button className="btn btn-primary" type="submit">
                <i className="fa fa-save mr-2" />
                Sauvegarder
              </button>
            </div>
            <Input
              maxLength={60}
              name="shortDescription"
              label="Description"
              placeholder="saisir la description"
            />
            {[1, 2, 3].map((field, index) => (
              <div key={field}>
                <Input
                  maxLength={50}
                  name={`mainDescription[${index}].text`}
                  label={`Avantage principle ${index + 1}`}
                  validate={Validators([{ validation: 'required' }])}
                />
              </div>
            ))}
            <h3>ajouter un lien Youtube vidéo</h3>
            <h5>
              example: https://youtu.be/<span className="text-warning">_tV5LEBDs7w</span>
            </h5>
            <div>
              <Input maxLength={50} name="youtubeEmbed" label="youtube EmbedId" />
            </div>

            <h3>Sélectionner la photo principale</h3>
            <div
              className="d-flex border p-2 flex-column align-items-center"
              style={{ borderRadius: '8px' }}
            >
              {!loading ? (
                <LazyImage
                  alt="presentation"
                  src={`${getCompressedImageUrl(encodeURI(image))}?tr=w-400,h-300`}
                  width="450px"
                />
              ) : (
                <ImageLoader />
              )}
            </div>
            <ImageSliderPicker
              images={selectedEditingProduct?.media}
              onSelectImage={(index) => handleChangeMainImage(index)}
            />
            <br />
            <br />
            <h3>Sélectionner les icons et saisir le titre des icons</h3>
            <br />
            <div className="row mb-5">
              {[1, 2, 3, 4].map((field, index) => (
                <div key={field} className="col-md-3 border  ">
                  <div className="p-3 mb-2 ">
                    {watch(`icons[${index}].icon`) && (
                      <div className="d-flex flex-column align-items-center w-100">
                        <img width="45px" src={watch(`icons[${index}].icon`)} alt="grat" />
                      </div>
                    )}
                    <Input
                      name={`icons[${index}].title`}
                      placeholder="entry your icon title"
                      validate={Validators([{ validation: 'required' }])}
                    />

                    <FormIconSelect
                      isSearchable
                      isSearchBar
                      isClearable
                      options={ICON_LIST_OPTIONS}
                      name={`icons[${index}].icon`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <FilterSelectionContainer />
            <div className="d-flex justify-content-end ">
              <button className="btn btn-primary" type="submit">
                <i className="fa fa-save mr-2" />
                Sauvegarder
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ProductEditor;
