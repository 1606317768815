/* eslint-disable no-nested-ternary */
import { useQuery } from 'client/hooks/useQuery';
import { saveFlights } from 'client/_redux/actions/flights';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IFlightClient, ISelectedFlights } from 'types/IFlightData';

export const useFlightReservationConfirmation = (
  selectedFlights: ISelectedFlights,
  passengers: IFlightClient[],
) => {
  const { seats, babies, kids } = useQuery(['seats', 'babies', 'kids']) as Record<
    string,
    string
  >;

  const pax = Number(seats || '1');
  const adults = Number(pax || '0') - Number(kids || '0') - Number(babies || '0');
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState('');
  const totalPrice = selectedFlights.singleFlight
    ? (selectedFlights.singleFlight.price * pax).toFixed(2)
    : (
        (selectedFlights.goingFlight.price + selectedFlights.returningFlight.price) *
        pax
      ).toFixed(2);
  const handleCreatePayment = () =>
    paymentMethod
      ? dispatch(
          saveFlights({
            userData: passengers,
            flights: Object.values(selectedFlights),
            paymentMode: paymentMethod,
          }),
        )
      : toast('Veuiller choisir une méthode de paiement !', { type: 'warning' });
  const formattedSelectedFlights =
    selectedFlights &&
    (selectedFlights.singleFlight ||
      (selectedFlights.goingFlight && selectedFlights.returningFlight))
      ? selectedFlights.singleFlight
        ? {
            oneWayFlights: selectedFlights.singleFlight,
          }
        : {
            goingFlight: selectedFlights.goingFlight,
            returningFlight: selectedFlights.returningFlight,
          }
      : undefined;

  return {
    t,
    handleCreatePayment,
    setPaymentMethod,
    totalPrice,
    adults,
    kids,
    babies,
    selectedFlights,
    formattedSelectedFlights,
    pax,
  };
};
