import React from 'react';

const FlightPrice = ({ price, availableSeats }: { price: number; availableSeats: number }) => (
  <div
    style={{
      flexGrow: '1',
    }}
    className="d-flex flex-column justify-content-center align-items-center text-dark"
  >
    <strong className="h1">
      {Math.round(price)}€<small className="h6">/pers</small>
    </strong>
    {availableSeats < 10 && (
      <p className="text-danger font-weight-bold">Plus que {availableSeats} billets !</p>
    )}
  </div>
);

export default FlightPrice;
