import FlightsTopFilter from 'client/components/FlightsTopFilter';
import React from 'react';

interface IProps {
  onChange?: (value: string) => void;
  value?: string;
  flightFilter?: boolean;
  responsiveMode: boolean;
}

const FilterContentTripType: React.FC<IProps> = ({ onChange, value, responsiveMode }) => {
  const OptionList = [
    {
      id: 1,
      option: 'SéJOUR'.toUpperCase(),
      icon: <i className="fas fa-umbrella-beach font-size-laarge" />,
      commingSoon: false,
    },
    {
      id: 2,
      option: 'CIRCUIT',
      icon: <i className="fas fa-suitcase-rolling font-size-laarge" />,
      commingSoon: false,
    },
    {
      id: 3,
      option: 'HÔTEL',
      icon: <i className="fas fa-hotel font-size-laarge" />,
      commingSoon: false,
    },
    {
      id: 4,
      option: 'VOLS',
      icon: <i className="fas fa-plane-departure font-size-laarge" />,
      commingSoon: true,
    },
    {
      id: 5,
      option: 'CROISIÈRE',
      icon: <i className="fas fa-ship font-size-laarge" />,
      commingSoon: true,
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div
        className={` ${
          responsiveMode
            ? 'd-flex flex-column w-100 flex-wrap overflow-auto justify-content-center'
            : 'd-flex flex-row w-100 p-3 '
        } `}
      >
        {OptionList.map((el) => (
          <React.Fragment key={el.id}>
            {!el.commingSoon ? (
              <button
                type="button"
                className={`border btn btn-link   ${
                  responsiveMode ? 'px-0 py-1 w-100' : 'w-100 py-2 m-2 mx-4'
                } rounded ${value === el.option && 'active-btn'} active-btn-hover`}
                onClick={() => onChange?.(el.option)}
              >
                <div className="d-flex flex-column justify-content-center">
                  {el.icon}
                  <span className="font-weight-bold font-size-small">{el.option}</span>
                </div>
              </button>
            ) : (
              <button
                type="button"
                className={`border btn btn-link ${
                  responsiveMode ? 'px-0 py-1 w-100 ' : 'w-100 py-2 m-2'
                } rounded disabled-btn`}
                disabled
              >
                <div className="d-flex flex-column justify-content-center text-white ">
                  {el.icon}
                  <span className="font-weight-bold font-size-small">{el.option}</span>
                  <span className="font-size-x-small">Comming Soon ...</span>
                </div>
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
      {value === 'VOLS' && <FlightsTopFilter isHomePage />}
    </div>
  );
};

export default FilterContentTripType;
