import React from 'react';

interface IProps {
  onChange?: (value: string) => void;
  value?: string;
  responsiveMode: boolean;
}

const FilterContentDuration: React.FC<IProps> = ({ onChange, value, responsiveMode }) => {
  const durationOptions = [
    {
      option: 'Court séjour (1 à 4 nuits)',
    },
    {
      option: '1 semaine (4 à 8 nuits)',
    },
    {
      option: '+ de 1 semaine (8 nuits et +)',
    },
  ];

  return (
    <div
      className={` d-flex flex-row justify-content-between p-3 ${
        responsiveMode ? 'flex-wrap  overflow-auto ' : ''
      }`}
    >
      {durationOptions.map((el) => (
        <button
          type="button"
          key={el.option}
          className={` btn ${
            responsiveMode ? 'w-100 py-1 m-2 mx-2' : 'w-100 py-2 m-2 mx-4'
          } rounded ${value === el.option && 'active-btn'} active-btn-hover`}
          onClick={() => onChange?.(el.option)}
        >
          {el.option}
        </button>
      ))}
    </div>
  );
};

export default FilterContentDuration;
