import { IReduxAction } from 'types/IReduxAction';
import { searchBarFilter } from 'client/_redux/types/searchBarFilter';

export interface ISearchBarSelectedFilter {
  filterSelected: {
    title: string;
    isOpen: boolean;
  };
  loading: boolean;
}
const initialState: ISearchBarSelectedFilter = {
  loading: true,
  filterSelected: {
    title: '',
    isOpen: false,
  },
};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): ISearchBarSelectedFilter => {
  switch (type) {
    case searchBarFilter.FILTER_SELECTED:
      return { ...state, loading: false, filterSelected: payload };

    default:
      return state;
  }
};
