import { IThunkAction } from 'types/IThunkAction';
import * as BlogServices from 'dashboard/_redux/services/blogArticles';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import { BlogArticlesTypes } from 'dashboard/_redux/types/blogArticlesTypes';
import { toast } from 'react-toastify';
import { getFromData } from 'client/helpers/getFromData';
import { SearchQuery } from 'dashboard/_redux/services/blogArticles';
import { IArticle } from 'server/models/Article/types';
import { IBlogCoverDocument } from 'server/models/BlogCover/types';

export const getArticles = (query: SearchQuery<IArticle>): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const res = await BlogServices.getArticles()(query);

    dispatch({
      type: BlogArticlesTypes.GET_ARTICLES_SUCCESS,
      payload: res,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const addArticle = (data: IArticle): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const formData = new FormData();

    getFromData(formData, data);

    const res = await BlogServices.addArticle()(formData);

    dispatch({
      type: BlogArticlesTypes.ADD_ARTICLE_SUCCESS,
      payload: res.data.article,
    });
    toast('Article added with success');
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const updateArticle = (data: IArticle, id?: string): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const formData = new FormData();

    getFromData(formData, data);

    const res = await BlogServices.updateArticle()(formData, id);

    dispatch({
      type: BlogArticlesTypes.UPDATE_ARTICLE_SUCCESS,
      payload: res.data,
    });
    toast('Article updated with success');
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const deleteArticle = (id?: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await BlogServices.deleteArticle()(id);

    dispatch({
      type: BlogArticlesTypes.DELETE_ARTICLE_SUCCESS,
      payload: id,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const getBlogCover = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await BlogServices.getBlogCover()();

    if (res.metaTitle !== undefined) {
      dispatch({
        type: BlogArticlesTypes.GET_BLOG_COVER_SUCCESS,
        payload: res,
      });
    }
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const createOrUpdateBlogCover = (
  data: Partial<IBlogCoverDocument>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const formData = new FormData();

    getFromData(formData, data);

    const res = await BlogServices.createOrUpdateBlogCover()(formData);

    dispatch({
      type: BlogArticlesTypes.BLOG_COVER_SUCCESS,
      payload: res.data,
    });
    toast('Blog cover updated with success');
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
