import { clientApi } from 'api';
import { IEntity } from 'server/models/Entity/types';
export const getEntities = (isServer = false) => async (
  entity: (RegExp | 'City' | 'Country' | 'MealPlan' | 'Keyword' | 'Rating' | 'Type')[],
) => {
  let url = '';

  for (let i = 0; i < entity.length; i++) {
    if (i === 0) {
      url += `entity-type=${entity[i]}`;
    } else {
      url += `&entity-type=${entity[i]}`;
    }
  }
  const response = await clientApi.get<{ _id: string; entity: IEntity[] }[]>(
    `/entities?${url}`,
  );

  return response;
};
