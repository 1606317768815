import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import * as React from 'react';
import { Link } from 'react-router-dom';
export interface ICardProps {
  title: string;
  subtitle: string;
  image?: string;
  
}

const CountryCard: React.FC<ICardProps> = ({ title, image, subtitle }) => (
  
  <div data-testid="homePageCard" className="grid-item pt-3 px-2">
    <Link to={`/products/?country=${title.replace(' ','%20')}`}>
      <div
        className="position-relative  relative card-hover "
        style={{ height: '400px', width: '100%', objectFit: 'cover' }}
      >
        <img
          src={`https://www.keplertravel.com/${image}`}
          className="w-100 h-100  top-0"
          alt={title}
          style={{ objectFit: 'cover', zIndex: -1 }}
        />

        <div
          style={{
            background: 'rgba(0,0,0,0.65)',
          }}
          className="bottom-0 position-absolute px-4 w-100 pt-2 pb-5"
        >
          <h3 className="text-white ">{upperCaseFirstLetter(title)}</h3>
          <span className="d-block text-white">{subtitle}</span>
        </div>
      </div>
    </Link>
  </div>
);

export default CountryCard;
