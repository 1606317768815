import { useFieldArray, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import { ISelectorOption } from 'types/ISelectorOption';
import { IFilter } from 'server/models/Filter/types';
import { DBDocument } from 'client/models/utils';
import { useTranslation } from 'react-i18next';

export const useFilterSelection = (
  filterIndex: number,
  rawOptions: ISelectorOption<string>[],
  productsFilter?: (IFilter & DBDocument)[],
) => {
  const { control, watch } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    control,
    name: `filters[${filterIndex}].attributes`,
  });
  const filters = watch('filters');
  const [t] = useTranslation();
  const selectedFilter = useMemo(
    () => rawOptions?.find((filter) => filter.value === filters?.[filterIndex]?.filterId),
    [filterIndex, filters, rawOptions],
  );

  const options = useMemo(
    () =>
      rawOptions?.filter(
        (filter) =>
          !(filters as { filterId: string }[]).find(
            (watchFilter) =>
              watchFilter.filterId === filter.value && filter.value !== selectedFilter?.value,
          ),
      ),
    [filters, rawOptions, selectedFilter?.value],
  );

  const filterId = filters?.[filterIndex]?.filterId;

  const productFilter = useMemo(
    () => productsFilter?.find((filter) => filter._id === filterId) as IFilter,
    [filterId, productsFilter],
  );
  const attributeOptions = useMemo(
    () =>
      productFilter?.attributes?.map((attribute) => ({
        label: attribute.title,
        value: attribute.title,
      })),

    [productFilter?.attributes],
  );

  return { options, append, remove, fields, attributeOptions, productFilter, t };
};
