import React from 'react';
import { components, GroupTypeBase, OptionProps } from 'react-select';
import { ISelectorOption } from 'types/ISelectorOption';

export const IconOption: React.FC<
  OptionProps<ISelectorOption<unknown>, boolean, GroupTypeBase<ISelectorOption<unknown>>>
> = (props) => {
  const { data, innerRef, innerProps } = props;
  const { icon, label } = data;

  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-between" ref={innerRef} {...innerProps}>
        <span> {label}</span>
        <img src={`${icon}`} style={{ width: 40 }} alt={label} />
      </div>
    </components.Option>
  );
};
