import React from 'react';
import { ICatalogue } from 'server/models/Catalogue/types';

const Description = ({ product }: { product?: ICatalogue }) => {
  if (!product) return null;
  // console.log('%csrcclientpagesProductDescription.tsx:6 product', 'color: #007acc;', product);
  const { description } = product;
  const { body } = description;
  const includes = body[0];
  const excludes = body[1];
  const sousExcludes = excludes.paragraph;
  const rest = body.slice(2);

  return (
    <div className="py-6 col-12 col-md-8">
      <div className="row py-7">
        <div className="col-md-6">
          <h2>{includes.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: (!Array.isArray(includes.paragraph) && includes.paragraph?.Text) || '',
            }}
          />
        </div>
        <div className="col-md-12">
          <h2>{excludes.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html:
                (!Array.isArray(excludes.paragraph) && excludes.paragraph?.Text) ||
                (Array.isArray(sousExcludes)
                  ? sousExcludes?.map((item) => item.Title.replace(',', '') + item.Text)
                  : '') ||
                '',
            }}
          />
        </div>
      </div>
      {rest.map((item, index) => (
        <div key={`${index + 1}+1`} className="row">
          <div className="col-12">
            <h2>{item.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: (!Array.isArray(item.paragraph) && item.paragraph?.Text) || '',
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Description;
