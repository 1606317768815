import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllUsers } from 'dashboard/_redux/actions/users';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'client/hooks/useAppSelector';
export const useUserTable = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { users } = useAppSelector((state) => state.dashboardUserList);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>();
  const [keyword, setKeyword] = useState('');

  const items = users?.items;
  const totalPages = users?.totalPages;

  useEffect(() => {
    dispatch(getAllUsers(page, keyword, limit));
  }, [page, limit, keyword, dispatch]);

  return {
    items,
    setPage,
    setLimit,
    limit,
    keyword,
    setKeyword,
    page,
    totalPages,
    t,
  };
};
