import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import React from 'react';

const Loader = () => (
  <>
    <ResponsiveWrapper>
      <svg
        data-testid="Loader"
        role="img"
        width="auto"
        height="auto"
        aria-labelledby="loading-ariaTitle"
        viewBox="0 0 410 10"
        preserveAspectRatio="none"
      >
        <title id="loading-ariaTitle">Loading...</title>
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          clipPath="url(#clip-pathTitle)"
          style={{
            fill: 'url("#fill")',
          }}
        />
        <defs>
          <clipPath id="clip-pathTitle">
            <rect x={0} y={0} rx={5} ry={5} width="100%" height="100%" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </ResponsiveWrapper>
    <ResponsiveWrapper isMobile>
      <svg
        role="img"
        width="auto"
        height="auto"
        aria-labelledby="loading-ariaTitleMobile"
        viewBox="0 0 410 30"
        preserveAspectRatio="none"
      >
        <title id="loading-ariaTitleMobile">Loading...</title>
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          clipPath="url(#clip-pathTitleMobile)"
          style={{
            fill: 'url("#fillMobile")',
          }}
        />
        <defs>
          <clipPath id="clip-pathTitleMobile">
            <rect x={0} y={0} rx={8} ry={8} width="100%" height="100%" />
          </clipPath>
          <linearGradient id="fillMobile">
            <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </ResponsiveWrapper>
  </>
);

export default Loader;
