import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { IReservationForm } from 'types/IReservationForm';
import ReservationFormContainer from 'client/containers/ReservationFormContainer';
import SEO from 'client/components/SEO';
import useReservationForm from './useReservationForm';

interface IFormProps {
  onSubmit: (formData: IReservationForm) => void;
  initialValues?: IReservationForm;
}
const ReservationForm: React.FC<IFormProps> = () => {
  const {
    onSubmit,
    selectedRooms,
    permalink,
    handleSubmit,
    formMethods,
  } = useReservationForm();

  if (selectedRooms.length === 0) {
    return <Redirect to={`/product/${permalink}`} />;
  }

  return (
    <>
      <SEO title="Reservation Form page" />
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="container container-xxl px-4 pb-4 mt-2"
        >
          <ReservationFormContainer />
        </form>
      </FormProvider>
    </>
  );
};

export default ReservationForm;
