import React from 'react';

const SearchSubmitButton = ({ value }: { value: string }) => (
  <div
    className={`d-flex justify-content-center ${
      value === 'Durée' || value === 'Date de vol' ? 'last-search-button' : ''
    }`}
  >
    <button
      type="submit"
      className={`btn d-flex flex-row text-white my-auto search-button-btn search-rounded ${
        value === 'Durée' || value === 'Date de vol' ? '' : 'search-rounded'
      }`}
    >
      <span className="font-size align-self-center px-3">Rechercher</span>
    </button>
  </div>
);

export default SearchSubmitButton;
