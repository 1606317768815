import { PromotionState } from 'dashboard/_redux/reducers/promotion';
import { getPromotion, setPromotion } from 'dashboard/_redux/actions/promotion';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const usePromotionsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit: SubmitHandler<PromotionState> = (values) => {
    dispatch(setPromotion(values, history.goBack));
  };

  useEffect(() => {
    dispatch(getPromotion());
  }, [dispatch]);
  const promotion = useAppSelector((state) => state.dashboradPromotion);
  const loading = useAppSelector((state) => state.loading.loading);

  return { onSubmit, promotion, loading };
};
