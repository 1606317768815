/* eslint-disable max-len */
import * as React from 'react';
import { SVGProps } from 'react';

const LaugageSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      d="M13.333 12.663v-8.33c0-.919.673-1.666 1.5-1.666h3c.827 0 1.5.747 1.5 1.666v8.33h.993l1.018.026s1.986-.026 1.986 2.205v10.01c0 2.207-1.986 2.207-1.986 2.207h-.01v1.111c0 .614-.448 1.111-1 1.111-.553 0-1-.497-1-1.11V27.11h-6v1.111c0 .614-.448 1.111-1 1.111-.553 0-1-.497-1-1.11V27.11c-.2-.006-2-.121-2-2.207v-10.01c0-2.246 2.017-2.205 2.017-2.205l1.008-.026h.974zm-.985 4.48h7.985L20.32 16h-7.986l.015 1.144zm1.985-11.698v7.222h4V5.445c0-.307-.224-.556-.5-.556h-3c-.276 0-.5.249-.5.556z"
      clipRule="evenodd"
    />
  </svg>
);

export default LaugageSVG;
