import * as React from 'react';
import CountryCard from 'dashboard/components/CountryCard';
import { useCountriesCardsContainer } from './useCountriesCardsContainer';

interface IProps {
  sectionTitle: string;
}

const CountriesContainer: React.FC<IProps> = ({ sectionTitle }) => {
  const { countries, titleIcon, handleDelete } = useCountriesCardsContainer();

  return (
    <div data-testid="homePageCardContainer">
      <div className="container">
        <div className="row mt-4 ml-4">
          <p className={`fa-2x text-dark pr-3 mt-3 ${titleIcon}`} />
          <h1 className="font-weight-bold ml-1">{`${sectionTitle}`}</h1>
        </div>
      </div>
      <div className="row no-gutters">
        {countries?.map((country) => (
          <CountryCard
            id={country._id}
            key={country._id}
            title={country.title}
            subtitle={country.subtitle}
            image={country.countryImage as string}
            deleteCountry={handleDelete}
          />
        ))}
      </div>
    </div>
  );
};

export default CountriesContainer;
