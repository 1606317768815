import React, { useEffect, useState } from 'react';
import Modal from 'client/components/Modal';
import { ISelectorOption } from 'types/ISelectorOption';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import { useFormContext } from 'react-hook-form';
import FormSelectList from './FormSelectList';

interface Iprops<T extends unknown> {
  options: ISelectorOption<T>[];
  label: string;
  name: string;
  classNameIcon?: string;
}

const SelectModalContainer = <T extends unknown = unknown>({
  options,
  label,
  name,
  classNameIcon,
}: Iprops<T>) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(!open);
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    if (name === 'numberOfNights') {
      setValue('numberOfNights', options?.[0].label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('from')]);

  return (
    <div className="mb-3">
      <div className={`${open && 'show-input-open-modal bg-white position-relative'}`}>
        <div className={`font-size-1 ${open && 'bg-modal-color text-white px-2'}`}>
          {label}
        </div>
        <div
          className={`border d-inline-flex  px-2 py-2 w-100 justify-content-between  ${
            open && 'border-primary '
          }`}
          onClick={() => setOpen(true)}
          style={{ cursor: 'pointer' }}
        >
          <div
            className="  d-flex align-items-center justify-content-center text-primary font-weight-bold "
            style={{ fontSize: '14px' }}
          >
            <i className={`${classNameIcon} mr-2`} /> {upperCaseFirstLetter(watch(name) || '')}
          </div>
          <div
            className=" d-flex align-items-center justify-content-center font-weight-bold text-dark"
            style={{
              fontSize: '10px',
            }}
          >
            Modifier
          </div>
        </div>
      </div>

      <Modal
        closeModal={handleClose}
        isOpen={open}
        modalHead={label}
        modalHeadStyle="px-5 py-3 z-index-999 position-fixed bg-primary d-inline-flex 
        text-white align-item-center justify-content-between row no-gutters w-md-30 w-100"
        buttonCloseStyle="text-white"
        modalClassName=""
      >
        <FormSelectList handleClose={handleClose} name={name} options={options} />
      </Modal>
    </div>
  );
};

export default SelectModalContainer;
