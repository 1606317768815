import React from 'react';
import { Addons } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import AddonsList from './AddonsList';
import { useProductAddons } from './useProductAddons';

type IProductAddonsProps = {
  productAddons: Addons[];
};
const ProductAddons = ({ productAddons }: IProductAddonsProps) => {
  const { addonsTotalPrice, onChange, t } = useProductAddons();

  return (
    <div className="mt-4 mb-4 border rounded p-3">
      <div className="card border shadow-serious rounded mt-2">
        <div className="card-header">
          <h2>{t('productAddons.title')}</h2>
          {addonsTotalPrice > 0 && (
            <div className="right-0">
              {`${t('productAddons.totalAddons')} : `}
              <b>
                {(addonsTotalPrice / 100).toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </b>
            </div>
          )}
        </div>
        <div className="card-body">
          {AddonsList({
            addons: productAddons,
            onChange,
            noResultText: t('productPrices.AucunRésultat'),
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductAddons;
