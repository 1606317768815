/* eslint-disable max-len */
import * as React from 'react';
import SEO from 'client/components/SEO';
import { useDispatch } from 'react-redux';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CreateAdminForm, { ICreateAdminFormValues } from 'dashboard/containers/CreateAdminForm';
import { addAdmin } from 'dashboard/_redux/actions/administrator';

const CreateAdmin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit: SubmitHandler<ICreateAdminFormValues> = (values) => {
    dispatch(addAdmin(values, () => history.push('/dashboard')));
  };

  return (
    <>
      <SEO title="Create Admin page" description="Création Administrateur" />
      <CreateAdminForm onSubmit={onSubmit} />
    </>
  );
};

export default CreateAdmin;
