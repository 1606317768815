import { IThunkAction } from 'types/IThunkAction';
import { reservationFormDetails } from 'client/_redux/types/reservationForm';
import { IReservationForm } from 'types/IReservationForm';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from '../loading';

export const createReservationForm = (value: IReservationForm): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    dispatch({ type: reservationFormDetails.SET_RESERVATION_FORM_DETAILS, payload: value });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: reservationFormDetails.SET_RESERVATION_FORM_DETAILS_FAILED });
  }
  dispatch(stopLoading());
};
