import { useAppSelector } from 'client/hooks/useAppSelector';
import { useQuery } from 'client/hooks/useQuery';
import {
  getCalendarFlights,
  getInitialRoundedCalendarFlights,
  setFlightType,
} from 'client/_redux/actions/flights';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ISelectorOption } from 'types/ISelectorOption';
export type IDefaultFlightFilterValues = {
  viaAirportOptions: string;
  luggageOptions: string;
  seats: string;
  adults: string;
  kids: string;
  babies: string;
};
export type ISearchCriteria = {
  viaAirportOptions: ISelectorOption[];
  luggageOptions: ISelectorOption[];
};
export const useFlightFilter = () => {
  const dispatch = useDispatch();
  const searchCriteria: ISearchCriteria = {
    viaAirportOptions: [
      { label: 'Peu importe', value: 'all' },
      { label: 'Avec escales', value: 'with-stops' },
      { label: 'Sans escales', value: 'no-stops' },
    ],
    luggageOptions: [
      { label: 'Peu importe', value: 'all' },
      { label: 'Inclus', value: 'included' },
      { label: 'Non Inclus', value: 'not-included' },
    ],
  };
  const history = useHistory();
  const { pax, isRoundTrip } = useAppSelector(({ flightsReducer }) => ({
    pax: flightsReducer.pax,
    isRoundTrip: flightsReducer.isRoundFlight,
  }));
  const {
    luggage,
    'via-airports': viaAirports,
    departure,
    destination,
    'start-date': departureDate,
    'returning-date': returningDate,
    seats,
    kids,
    babies,
  } = useQuery([
    'luggage',
    'via-airports',
    'departure',
    'destination',
    'start-date',
    'returning-date',
    'seats',
    'kids',
    'babies',
  ]) as Record<string, string>;

  const { handleSubmit, ...formMethods } = useForm<IDefaultFlightFilterValues>({
    defaultValues: {
      luggageOptions: luggage,
      viaAirportOptions: viaAirports,
      seats: seats || (pax && pax.toString()) || '',
      kids,
      babies,
      adults: `${
        Number(seats || (pax && pax.toString()) || '1') -
        Number(kids || '0') -
        Number(babies || '0')
      }`,
    },
  });
  const { setValue } = formMethods;

  useEffect(() => {
    const value = seats || (pax && pax.toString()) || '';

    setValue('seats', value);
  }, [pax, seats, setValue]);
  const onSubmit = useCallback(
    ({ luggageOptions, viaAirportOptions, seats, adults, kids, babies }) => {
      history.push(
        `/flights?departure=${departure}&destination=${destination}${
          departureDate ? `&start-date=${departureDate}` : ''
        }${returningDate && isRoundTrip ? `&returning-date=${returningDate}` : ''}${
          luggageOptions ? `&luggage=${luggageOptions}` : ''
        }${viaAirportOptions ? `&via-airports=${viaAirportOptions}` : ''}${
          adults || kids || babies
            ? `&seats=${Number(adults || '1') + Number(kids || '0') + Number(babies || '0')}`
            : ''
        }${kids && Number(kids) > 0 ? `&kids=${kids}` : ''}${
          babies && Number(babies) > 0 ? `&babies=${babies}` : ''
        }`,
      );
    },
    [departure, departureDate, destination, history, isRoundTrip, returningDate],
  );
  const handleTripType = useCallback(
    (value) => {
      value === 'round'
        ? dispatch(
            getInitialRoundedCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: departure || '',
              destination: destination || '',
              pax,
            }),
          )
        : dispatch(
            getCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: departure || '',
              destination: destination || '',
              pax,
            }),
          );
      dispatch(setFlightType(value === 'round'));
    },
    [departure, destination, dispatch, pax],
  );

  return {
    pax,
    onSubmit,
    formMethods,
    handleSubmit,
    searchCriteria,
    isRoundTrip,
    handleTripType,
    defaultSeats: seats || (pax && pax.toString()) || '',
    defaultKids: kids,
    defaultBabies: babies,
  };
};
