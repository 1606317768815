/* eslint-disable max-lines */
import { AxiosError } from 'axios';
import { errorHandler } from 'client/helpers/errorHandler';
import { flightsTypes } from 'client/_redux/types/flightsTypes';
import { IThunkAction } from 'types/IThunkAction';
import * as FlightService from 'client/_redux/services/flights';
import { IFlightClient } from 'types/IFlightData';
import { TFlightResponse } from 'server/services/FlightsService';
export const getCalendarFlights = (data: {
  year: number;
  month: number;
  departure: string;
  destination: string;
  isRoundTrip?: boolean;
  selectedDate?: Date;
  pax?: number;
}): IThunkAction => async (dispatch) => {
  const { year, month, departure, destination, isRoundTrip, selectedDate, pax } = data;

  try {
    const res = await FlightService.getCalendarFlights()(
      year,
      month,
      departure,
      destination,
      isRoundTrip,
      selectedDate,
      pax,
    );

    await dispatch({
      type: flightsTypes.GET_CALENDAR_FLIGHTS_SUCCESS,
      payload: { data: res.data, pax },
    });
    isRoundTrip &&
      dispatch({
        type: flightsTypes.GET_CALENDAR_ROUND_FLIGHTS_SUCCESS,
        payload: { data: res.data, selectedDate, pax },
      });
  } catch (error) {
    errorHandler(error as AxiosError);
    dispatch({ type: flightsTypes.GET_CALENDAR_FLIGHTS_FAILED });
  }
};
export const getInitialRoundedCalendarFlights = (data: {
  year: number;
  month: number;
  departure: string;
  destination: string;
  pax?: number;
}): IThunkAction => async (dispatch) => {
  try {
    const { year, month, departure, destination, pax } = data;
    const res = await FlightService.getInitialRoundedCalendarFlights()(
      year,
      month,
      departure,
      destination,
      pax,
    );

    dispatch({
      type: flightsTypes.GET_INITIAL_ROUNDED_CALENDAR_FLIGHTS_SUCCESS,
      payload: { data: res.data, pax },
    });
  } catch (error) {
    errorHandler(error as AxiosError);
    dispatch({ type: flightsTypes.GET_CALENDAR_FLIGHTS_FAILED });
  }
};
export const setDepartureAerport = (aerport: string): IThunkAction => async (dispatch) => {
  try {
    dispatch({ type: flightsTypes.SET_DEPARTURE_AERPORT, payload: aerport });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const setDestinationCountry = (country: string): IThunkAction => async (dispatch) => {
  try {
    dispatch({ type: flightsTypes.SET_DESTINATION_COUNTRY, payload: country });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const setFuturePrices = (date: Date, price?: number): IThunkAction => async (
  dispatch,
) => {
  try {
    dispatch({ type: flightsTypes.SET_FUTURE_PRICE, payload: { date, price } });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const saveDates = (
  departureDate: Date | null,
  returningDate?: Date | null,
): IThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: flightsTypes.SAVE_DATES_SUCCESS,
      payload: { departureDate, returningDate },
    });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const setPax = (pax: string): IThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: flightsTypes.SET_PAX_SUCCESS,
      payload: +pax,
    });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const getDetailedFlights = (data: {
  departureDate: string;
  departure: string;
  destination: string;
  luggage: string;
  viaAirports: string;
  isRoundTrip?: boolean;
  returningDate?: string;
  seats?: string;
}): IThunkAction => async (dispatch) => {
  try {
    dispatch({ type: flightsTypes.CLEAR_DETAILED_FLIGHTS });
    const flights = await FlightService.getFlightsDetails()(data);

    dispatch({ type: flightsTypes.GET_DETAILED_FLIGHTS_SUCCESS, payload: flights });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const setSelectedFlights = (selectedFlight: {
  typeOfFlight: 'Single' | 'Going' | 'Returning';
  _id: string;
}): IThunkAction => async (dispatch) => {
  try {
    dispatch({ type: flightsTypes.SELECT_FLIGHTS, payload: selectedFlight });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const resetCalendar = (): IThunkAction => async (dispatch) => {
  try {
    dispatch({ type: flightsTypes.RESET_CALENDAR });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const saveFlightsPassengers = (userData: IFlightClient[]): IThunkAction => async (
  dispatch,
) => {
  try {
    // await FlightService.saveFlights()(flights, userData);
    dispatch({ type: flightsTypes.SAVE_PASSENGER, payload: userData });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const saveFlights = ({
  flights,
  userData,
  paymentMode,
}: {
  paymentMode: string;
  flights: TFlightResponse[];
  userData: IFlightClient[];
}): IThunkAction => async (dispatch) => {
  try {
    const res = await FlightService.saveFlights()(flights, userData, paymentMode);

    window.location.replace(res.PaymentPageUrl);
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
export const setFlightType = (isRoundFlight: boolean): IThunkAction => async (dispatch) => {
  try {
    dispatch({ type: flightsTypes.CHANGE_FLIGHT_TYPE, payload: isRoundFlight });
  } catch (error) {
    errorHandler(error as AxiosError);
  }
};
