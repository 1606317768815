import { useAppSelector } from 'client/hooks/useAppSelector';
import { checkLoggingStatus } from 'dashboard/_redux/actions/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
export const useDashboardRouter = () => {
  const initiated = useAppSelector(({ init: { initiated } }) => initiated);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLoggingStatus());
  }, [dispatch]);

  return { initiated };
};
