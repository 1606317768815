import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import {
  largeScreenClassNameList,
  mobileScreenClassNameList,
} from 'client/constants/ClassNameList/ClassNameList';
import React from 'react';
import CustomSkeleton from './CustomSkeleton';

const LoaderLayout = () => (
  <div className="image-gallery-loader-container">
    <ResponsiveWrapper>
      <div className="loader-layout-grid-container ">
        {largeScreenClassNameList.map((el) => (
          <CustomSkeleton key={el} className={el} />
        ))}
      </div>
    </ResponsiveWrapper>
    <ResponsiveWrapper isMobile>
      <div className="loader-layout-grid-container-mobile">
        {mobileScreenClassNameList.map((el) => (
          <CustomSkeleton key={el} className={el} />
        ))}
      </div>
    </ResponsiveWrapper>
  </div>
);

export default LoaderLayout;
