import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler, successHandler } from 'client/helpers/errorHandler';
import { PromotionTypes } from 'dashboard/_redux/types/promotionTypes';
import { IThunkAction } from 'types/IThunkAction';
import * as promotionServices from 'dashboard/_redux/services/promotion';
import { PromotionState } from 'dashboard/_redux/reducers/promotion';
export const getPromotion = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const promotion = await promotionServices.getPromotion()();

    dispatch({
      type: PromotionTypes.GET_PROMOTION,
      payload: promotion
        ? { ...promotion, image: `${process.env.RAZZLE_HOST}/${promotion.image}` }
        : promotion,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const setPromotion = (
  data: PromotionState,
  onSuccess?: () => void,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const promotion = await promotionServices.setPromotion()(data);

    dispatch({ type: PromotionTypes.SET_PROMOTION, payload: promotion });
    successHandler('Promotion mise en place avec succes!');
    // onSuccess?.();
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
