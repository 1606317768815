import * as React from 'react';

interface IFilterHeaderProps {
  setShowSideProductFilters: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSideFilter: React.Dispatch<React.SetStateAction<boolean>>;
  showSideFilter: boolean;
  showSideProductFilters: boolean;
}

const FilterHeader: React.FC<IFilterHeaderProps> = ({
  setShowSideProductFilters,
  setShowSideFilter,
  showSideFilter,
  showSideProductFilters,
}) => (
  <div className="d-flex justify-content-between shadow-less-serious">
    <button
      type="button"
      onClick={() => {
        setShowSideProductFilters(false);
        setShowSideFilter(!showSideFilter);
      }}
      className={`btn btn-sm rounded-0 w-100 border ${
        showSideFilter ? 'bg-primary text-white border-primary' : 'text-primary'
      }`}
    >
      <i className="fas fa-search" /> Rechercher
    </button>
    <button
      type="button"
      onClick={() => {
        setShowSideFilter(false);
        setShowSideProductFilters(!showSideProductFilters);
      }}
      className={`btn btn-sm rounded-0 w-100 border ${
        showSideProductFilters ? 'bg-primary text-white border-primary' : 'text-primary'
      }`}
    >
      <i className="fas fa-sliders-h" /> Affiner
    </button>
  </div>
);

export default FilterHeader;
