import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { useHistory, useParams } from 'react-router-dom';
import { getOne } from 'client/_redux/actions/product/getOne';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'client/hooks/useQuery';
import { useAppSelector } from 'client/hooks/useAppSelector';
export const useStepOne = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();
  const fromQuery = useQuery('from');
  const { customCatalogue, loading, product } = useAppSelector(({ productReducer }) => ({
    ...productReducer,
  }));

  const { permalink } = useParams<{ permalink: string }>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  const {
    'number-of-nights': numberOfNight,
    from: from_id,
    'start-date': startDate,
  } = queryStringToObject<{
    from: string;
    'number-of-nights': string;
    'start-date': string;
  }>(history.location.search);

  const from =
    from_id !== 'XXX'
      ? product?.froms?.find((e) => e.id === `_LT_${from_id}`)?.title
      : String(t('searchBar.flightSearchForm.SanDepart'));

  useEffect(() => {
    dispatch(getOne(permalink, history));
  }, [dispatch, permalink, history]);

  return {
    startDate,
    from,
    numberOfNight,
    product: customCatalogue,
    loading,
    fromQuery,
  };
};
