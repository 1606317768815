export const objectToQueryString = (
  queryParameters?: Record<string, undefined | string | number | (string | number)[]>,
) =>
  queryParameters
    ? Object.entries(queryParameters).reduce((queryString, [key, val]) => {
        let symbol = queryString.length === 0 ? '?' : '&';

        if ((typeof val === 'string' && val.length > 0) || typeof val === 'number')
          queryString += `${symbol}${key}=${val.toString().toLowerCase()}`;
        else if (Array.isArray(val))
          val.forEach((v) => {
            symbol = queryString.length === 0 ? '?' : '&';
            if ((typeof v === 'string' && v.length > 0) || typeof v === 'number')
              queryString += `${symbol}${key}=${v.toString().toLowerCase()}`;
          });

        return queryString;
      }, '')
    : '';
