import Collapsible from 'client/components/Collapsible';
import FlightDetailsCard from 'client/components/FlightDetailsCard';
import { formatDayMonthYear, formatHourAndMinutes } from 'client/helpers/format/timeFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Transportation } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
interface IFlightDetailModalCollapsibleProps {
  data: Transportation;
  index: number;
  flightIndex: number | undefined;
  selected?: boolean;
}
const FlightDetailModalCollapsible: React.FC<IFlightDetailModalCollapsibleProps> = ({
  selected = false,
  data,
  index,
  flightIndex,
}) => {
  const [t] = useTranslation();

  return (
    <Collapsible
      title={
        <div className="row align-items-center">
          <div className="col-12 col-md-3 pl-md-5">
            {data.details.map((tripDetail) => (
              <div className="my-1" key={JSON.stringify(tripDetail)}>
                {tripDetail.map(({ carrier }) => (
                  <img
                    className="pl-3"
                    src={`https://images.kiwi.com/airlines/64/${carrier.code}.png`}
                    alt={carrier.code}
                    height={25}
                    key={JSON.stringify(carrier)}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="col-12 col-md-9 my-2 my-md-0 d-flex justify-content-around align-items-center">
            <span className="col">{formatDayMonthYear(data.details[0][0].start)}</span>
            <span className="d-none d-md-block col-md ">
              <div>{formatHourAndMinutes(data.details[0][0].start)}</div>
              <div>{data.details[0][0].from.code}</div>
            </span>
            <span className="d-none d-md-block col-md">
              <div>
                {formatHourAndMinutes(data.details[0][data.details[0].length - 1].end)}
              </div>
              <div>{data.details[0][data.details[0].length - 1].to.code}</div>
            </span>
            {data.details[data.details.length - 1].length + data.details[0].length - 2 ===
            0 ? (
              <span className="col ">{t('FlightDetailsCard.nonstop')}</span>
            ) : (
              <span className="col ">
                {data.details[data.details.length - 1].length + data.details[0].length - 2}{' '}
                {data.details[data.details.length - 1].length + data.details[0].length - 2 ===
                1
                  ? t('FlightDetailsCard.stopover')
                  : t('FlightDetailsCard.stopovers')}
              </span>
            )}
          </div>
          <span className="col-12 d-block d-md-none d-flex justify-content-around">
            <span className="col">
              {formatHourAndMinutes(data.details[0][0].start)} {data.details[0][0].from.code}
            </span>
            <span className="col">
              {formatHourAndMinutes(data.details[0][data.details[0].length - 1].end)}{' '}
              {data.details[0][data.details[0].length - 1].to.code}
            </span>
          </span>
        </div>
      }
      open={false}
    >
      {data.details.map((el) =>
        el.map((vol) => (
          <FlightDetailsCard
            flightDetails={vol}
            key={`${vol.start}${vol.end}${vol.flightNumber}`}
            bgClassName={` ${index === flightIndex && 'bg-primary text-white'}`}
          />
        )),
      )}
    </Collapsible>
  );
};

export default FlightDetailModalCollapsible;
