import React, { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'client/components/Modal';
import { useFormContext } from 'react-hook-form';
import { formatDate } from 'client/helpers/format/formatDate';
import FormSelectList from './FormSelectList';

interface IProps {
  options: { date?: Date; value?: string | number }[];
  label: string;
  name: string;
  classNameIcon?: string;
  isMinimumPrice?: number;
  setWatchPrice: Dispatch<SetStateAction<string | number | undefined>>;
}

const SelectListModalPrices: React.FC<IProps> = ({
  isMinimumPrice,
  options,
  label,
  name,
  classNameIcon,
  setWatchPrice,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(!open);

  const { watch } = useFormContext();

  return (
    <div className="mb-3">
      <div className={`${open && 'show-input-open-modal bg-white position-relative'}`}>
        <div className={`font-size-1 ${open && 'bg-modal-color text-white px-2'}`}>
          {label}
        </div>
        <div
          className={`border d-flex px-2 py-2 w-100 justify-content-between ${
            open && 'border-primary '
          }`}
          onClick={() => setOpen(true)}
          style={{ cursor: 'pointer' }}
        >
          <div
            className="  d-flex align-items-center justify-content-center text-primary font-weight-bold"
            style={{ fontSize: '14px' }}
          >
            <i className={`${classNameIcon} mr-2`} />
            {formatDate(watch(name))}
          </div>
          <div
            className="d-flex align-items-center justify-content-center font-weight-bold text-dark"
            style={{
              fontSize: '10px',
            }}
          >
            Modifier
          </div>
        </div>
      </div>
      <Modal
        closeModal={handleClose}
        isOpen={open}
        modalHead={label}
        modalHeadStyle="px-5 py-3 z-index-999 position-fixed bg-primary d-inline-flex 
        text-white align-item-center justify-content-between row no-gutters w-md-30 w-100"
        buttonCloseStyle="text-white"
        modalClassName="w-md-20 w-100 align-items-start align-content-md-center"
      >
        <FormSelectList
          handleClose={handleClose}
          name={name}
          options={options}
          isMinimumPrice={isMinimumPrice}
          setWatchPrice={setWatchPrice}
        />
      </Modal>
    </div>
  );
};

export default SelectListModalPrices;
