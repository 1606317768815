import { outputUrlStepOne } from 'client/helpers/outputUrlStepOne';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const useProductDetailsPriceStepOne = () => {
  const formMethods = useForm({ shouldFocusError: true });
  const { handleSubmit } = formMethods;
  const { push, location } = useHistory();
  const { pathname, search } = location;
  const [t] = useTranslation();

  const _onSubmit: SubmitHandler<{
    adultCount: number;
    childCount: number;
    email: string;
  }> = ({ adultCount, childCount, email }) => {
    push(outputUrlStepOne({ pathname, search, adultCount, childCount, email }));
  };

  return {
    formMethods,
    handleSubmit,
    _onSubmit,
    t,
  };
};
