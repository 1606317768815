export const groupBy = <T>(list: T[], keyGetter: (value: T) => string) => {
  const map = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);

    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
};
