import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITransaction } from 'server/models/Transaction/types';
type IClientCredentialsProps = {
  clientCredentials: ITransaction['clientCredentials'];
};
const ClientCredentials: React.FC<IClientCredentialsProps> = ({
  clientCredentials: {
    postalcode,
    city,
    adress: address,
    email,
    nationality,
    phoneNumber1,
    phoneNumber2,
  },
}) => {
  const [t] = useTranslation();
  const notProvided = (
    <span className="badge-danger">{t('clientCredentials.notProvided')}</span>
  );

  return (
    <div className="d-flex flex-column">
      <div className="px-lg-2 px-md-2 pt-2 pb-3">
        <p className="mb-1 text-dark">
          <b>{t('clientCredentials.credentials')}</b>
        </p>
      </div>
      <div className="pl-lg-3 pl-md-3">
        <div className="row mb-1 justify-content-between">
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">Email : </span>
            {email}
          </span>
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder"> {t('clientCredentials.nationality')}</span>
            {nationality}
          </span>
        </div>
        <div className="row mb-1 justify-content-between">
          <span className="col-lg-6 col-md-6 col-sm-12 ">
            <span className="font-weight-bolder">{t('clientCredentials.address')}</span>
            {city + address || notProvided}
          </span>
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">{t('clientCredentials.postalCode')}</span>
            {postalcode || notProvided}
          </span>
        </div>
        <div className="row mb-1 justify-content-between">
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">{t('clientCredentials.phone1')}</span>
            {phoneNumber1 || notProvided}
          </span>
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">{t('clientCredentials.phone2')}</span>
            {phoneNumber2 || notProvided}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ClientCredentials;
