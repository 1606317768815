import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { UsersTypes } from 'dashboard/_redux/types/usersTypes';
import * as usersServices from 'dashboard/_redux/services/users';
export const getAllUsers = (
  page: number,
  keyword: string,
  limit?: number,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: UsersTypes.FETCH_USERS_START });
  try {
    const users = await usersServices.getAllUsers()({ page, limit, keyword });

    dispatch({ type: UsersTypes.FETCH_USERS_SUCCESS, payload: users });
  } catch (error) {
    dispatch({ type: UsersTypes.FETCH_USERS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
