import Input from 'client/components/Form/Input';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import Validators from 'client/helpers/formValidator';
import SubmitButton from 'client/components/Form/SubmitButton';
import InputPlusMinusButtons from 'client/components/Form/InputPlusMinusButtons';
import { useProductDetailsPriceStepOne } from './useProductDetailsPriceStepOne';

interface IProps {
  startDate: string;
  from: string | undefined;
  numberOfNight: string;
}

const ProductDetailsPriceStepOne: React.FC<IProps> = ({ startDate, from, numberOfNight }) => {
  const { t, formMethods, handleSubmit, _onSubmit } = useProductDetailsPriceStepOne();

  return (
    <div>
      <div className="mb-5">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="col mt-2">
            <div className="text-primary h5 font-weight-normal">
              {t('rangeDatePicker.dateDedDebut')}:
            </div>
            <div>{startDate}</div>
          </div>
          <div className="col mt-2">
            <div className="text-primary h5 font-weight-normal">
              {t('searchBar.flightSearchForm.lieuDeDepart')}:
            </div>
            <div>{from}</div>
          </div>
          <div className="col mt-2">
            <div className="text-primary h5 font-weight-normal">
              {t('searchBar.flightSearchForm.numberOfNights')}:
            </div>
            <div>{numberOfNight}</div>
          </div>
        </div>
      </div>
      <div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <div className="rounded mb-5 bg-orange">
              <div className="d-flex flex-wrap justify-content-around ">
                <div className="col-md-3 no-gutters">
                  <div className="text-primary h5 font-weight-normal">
                    {t('ProductDetailsPriceStepOne.Adult')}:
                  </div>
                  <div className="w-md-75 w-50">
                    <InputPlusMinusButtons min={1} max={6} name="adultCount" />
                  </div>
                </div>
                <div className="col-md-3 no-gutters">
                  <div className="text-primary h5 font-weight-normal">
                    {t('ProductDetailsPriceStepOne.Child')}:
                  </div>
                  <div className="w-md-75 w-50">
                    <InputPlusMinusButtons min={0} max={6} name="childCount" />
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="text-primary h5 font-weight-normal">Email:</div>
                  <Input
                    type="email"
                    name="email"
                    validate={Validators([
                      { validation: 'required' },
                      { validation: 'email' },
                    ])}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-md-around flex-wrap  ">
              <span className="col-md-9 font-size-1 ">
                {t('ProductDetailsPriceStepOne.InfoSubmitBook')}
              </span>
              <div className="col-md-3  mt-2 d-flex justify-content-end">
                <SubmitButton
                  fontIcon="fas fa-chevron-circle-right"
                  name={t('ProductDetailsPriceStepOne.Book')}
                  className=" text-white shadow py-2 border-0 rounded w-50 w-md-100 bg-danger font-size-2"
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ProductDetailsPriceStepOne;
