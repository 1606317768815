import Modal from 'client/components/Modal';
import { PriceDetails } from 'client/containers/QuotedAvailability/PriceDetails/usePriceDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Price } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { AddonFormValues } from '../ProductAddons/useProductAddons';
import PriceDetailsTable from './PriceDetailsTable';
type IProps = {
  quotedPriceDetails: PriceDetails[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedAddons: AddonFormValues[];
  supplement: Price | undefined;
};

const ModalPriceDetails: React.FC<IProps> = ({
  quotedPriceDetails,
  isOpen,
  setIsOpen,
  selectedAddons,
  supplement,
}) => {
  const [t] = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      modalHead={`${t('priceDetails.title')}`}
      isScrollable
    >
      <div className="table-responsive{-sm|-md|-lg|-xl}">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t('priceDetails.type')}</th>
              <th>{t('priceDetails.quantity')}</th>
              <th>{t('priceDetails.unitPrice')}</th>
              <th>{t('priceDetails.totalPrice')}</th>
            </tr>
          </thead>
          <tbody>
            {quotedPriceDetails.map((priceDetail) => (
              <PriceDetailsTable
                key={priceDetail.type}
                type={priceDetail.type.replace('Room', t('room.room'))}
                description={priceDetail.price.description}
                quantity={priceDetail.price.quantity}
                unitPrice={priceDetail.price.unitPrice}
                totalPrice={priceDetail.price.totalPrice}
                currency={priceDetail.price.currency}
              />
            ))}
            {supplement && (
              <PriceDetailsTable
                type={`${t('quotedAvailabilityPage.mandatorySupplement')}`}
                description={supplement.description}
                quantity={supplement.quantity}
                unitPrice={supplement.unitPrice}
                totalPrice={supplement.totalPrice}
                currency={supplement.currency}
              />
            )}
            {selectedAddons.length > 0 &&
              selectedAddons.map((priceDetail) => (
                <PriceDetailsTable
                  key={`${priceDetail.id}`}
                  type={`${t('priceDetails.optionelServices')}`}
                  description={priceDetail.description}
                  quantity={priceDetail.nbrPersons}
                  unitPrice={priceDetail.price / priceDetail.nbrPersons / 100}
                  totalPrice={(priceDetail.price * priceDetail.nbrPersons) / 100}
                  currency="EUR"
                />
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ModalPriceDetails;
