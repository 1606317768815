export const PAYMENT_METHODS_LIST_OPTIONS = [
  {
    label: 'Carte bancaire',
    value: 'creditcard',
    icon: '/assets/svg/payment-icons/visa.svg',
  },
  // {
  //   label: 'PAYER EN 3 FOIS',
  //   value: 'BanqueCasinoEureka3x',
  //   icon: '/assets/svg/payment-icons/cb-3x.svg',
  // },
  // {
  //   label: 'PAYER EN 4 FOIS',
  //   value: 'BanqueCasinoEureka4x',
  //   icon: '/assets/svg/payment-icons/cb-4x.svg',
  // },
  {
    label: 'Cheque de vacances',
    value: 'cvconnect',
    icon: '/assets/svg/payment-icons/ancv.svg',
  },
];
