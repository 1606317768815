import { getProductIcon, productType } from 'client/helpers/getProductIcon';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const useProductType = () => {
  const { productType, loading } = useAppSelector(({ productReducer }) => ({
    productType: productReducer.product?.productType,
    loading: productReducer.loading,
  }));

  const { icon } = getProductIcon((productType || '').toLowerCase() as productType);

  return {
    productType,
    loading,
    icon,
  };
};
