import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useCheckingReservationForm = () => {
  const [t] = useTranslation();
  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  return { formMethods, handleSubmit, t };
};

export default useCheckingReservationForm;
