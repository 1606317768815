export enum FilterProductTypes {
  GET_FILTER_SEARCH_CRITERIA = 'GET_FILTER_SEARCH_CRITERIA',
  ADD_FILTER_PRODUCTS = 'ADD_FILTER_PRODUCTS',
  ADD_FILTER_PRODUCTS_FAILED = 'ADD_FILTER_PRODUCTS_FAILED',
  GET_FILTER_PRODUCTS = 'GET_FILTER_PRODUCTS',
  GET_FILTER_PRODUCTS_FAILED = 'GET_FILTER_PRODUCTS_FAILED',
  GET_FILTER_PRODUCTS_By_ID = 'GET_FILTER_PRODUCTS_By_ID',
  DELETE_FILTER_PRODUCTS = 'DELETE_FILTER_PRODUCTS',
  UPDATE_FILTER_PRODUCTS = 'UPDATE_FILTER_PRODUCTS',
}
