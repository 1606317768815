import React from 'react';

export interface IProps {
  name?: string;
  className?: string;
  fontIcon?: string;
}
const SubmitButton: React.FC<IProps> = ({ name, className, fontIcon }) => (
  <button
    data-testid="submitButton"
    className={`${className} d-flex justify-content-around align-items-center`}
    type="submit"
    name="submit"
  >
    {name}

    {fontIcon && <i className={fontIcon} />}
  </button>
);

export default SubmitButton;
