import FormImagePicker from 'dashboard/components/Forms/FormImagePicker';
import Input from 'client/components/Form/Input';
import SectionTitle from 'client/components/SectionTitle';
import React from 'react';
import { ArrayField } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextEditor from '../TextEditor';

type TourProgramProps = {
  fields: Partial<ArrayField<Record<string, string>, 'id'>>[];
  removeSection: (index?: number | number[] | undefined) => void;
  addSection: (
    value: Partial<Record<string, string>> | Partial<Record<string, string>>[],
  ) => void;
  title: string;
  type?: boolean;
};

const TourProgram: React.FC<TourProgramProps> = ({
  fields,
  removeSection,
  addSection,
  title,
}) => {
  const [t] = useTranslation();

  return (
    <div>
      <SectionTitle title={title} />

      {fields.map((item, index) => (
        <div key={item.id}>
          <div className="row ml-4">
            <Input
              name={`tourProgram.${index}.day`}
              label={t('productDetails.day')}
              className="col-4 w-100"
              defaultValue={`${t('productDetails.day')} ${index + 1}`}
            />
            <Input
              name={`tourProgram.${index}.title`}
              label={t('productDetails.title')}
              className="col-8"
            />
          </div>
          <TextEditor name={`tourProgram.${index}.content`} label="Description" />
          {/* TODO: here there is a problem with initial values  */}
          <FormImagePicker name={`tourProgram.${index}.media`} label="Image" maxFiles={1} />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => removeSection(index)}
          >
            {t('productDetails.deleteField')}
          </button>
          <hr />
        </div>
      ))}
      <div className=" text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addSection({ title: '', content: '', day: '' })}
        >
          {t('productDetails.addField')}
        </button>
      </div>
    </div>
  );
};

export default TourProgram;
