import { clientApi } from 'api';
import { ICatalogue } from 'server/models/Catalogue/types';
interface IEntireCatalogue {
  catalogue: ICatalogue;
  customCatalogue?: ICatalogue;
}
export const getOne = (isServer = false) => async (permalink: string) => {
  const response = await clientApi.get<IEntireCatalogue>('/catalogues/one', {
    params: { permalink },
  });

  return response;
};
