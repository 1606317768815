/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'client/hooks/useAppSelector';
import {
  getCalendarFlights,
  setFuturePrices,
  saveDates,
  getInitialRoundedCalendarFlights,
  setPax,
  setFlightType,
  resetCalendar,
} from 'client/_redux/actions/flights';
import { isBefore, isEqual } from 'date-fns';
import { useDispatch } from 'react-redux';
import { removeTimeFromDate } from 'client/helpers/removeTimeFromDate';
import { useWindowSize } from 'client/hooks/useWindowSize';
import { ICalendarFLight } from 'client/_redux/services/flights';
import { useQuery } from 'client/hooks/useQuery';
export const useFilterContentFlight = () => {
  const { flights, departureAerport, destinationCountry, pax, isRoundTrip } = useAppSelector(
    ({ flightsReducer }) => ({
      flights: flightsReducer.calendarFlights,
      departureAerport: flightsReducer.departureAerport,
      destinationCountry: flightsReducer.destinationCountry,
      pax: flightsReducer.pax,
      isRoundTrip: flightsReducer.isRoundFlight,
    }),
  );
  const { windowSize } = useWindowSize();
  const dispatch = useDispatch();
  const [dates, setDates] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [lastSelectedMonth, setLastSelectedMonth] = useState<Date>(new Date());
  const [date, setDate] = useState<Date | null>(null);
  const [focusedInput, setFocusedInput] = useState<string | null>(null);
  const [focusedSingleDateInput, setFocusedSingleDateInput] = useState<{ focused: boolean }>({
    focused: false,
  });
  const [initialMonth, setInitialMonth] = useState<Date | null>(new Date());
  const roundTripStartDateClick = useMemo(
    () => isRoundTrip && dates.startDate && !dates.endDate,
    [dates.endDate, dates.startDate, isRoundTrip],
  );
  const leftSlideMonth = useCallback(
    (startDate: Date) => {
      const selectedMonth = startDate.getMonth() + 1;
      const isRightMonth = selectedMonth % 2 !== (lastSelectedMonth.getMonth() + 1) % 2;

      if (!isRightMonth) return;

      setInitialMonth(null);
      setTimeout(() => setInitialMonth(startDate), 0);
    },
    [lastSelectedMonth],
  );
  const handleDatesChange = async (dates: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    setDates(dates);
    dispatch(saveDates(dates.startDate, dates.endDate));
    if (dates.startDate && !dates.endDate) {
      leftSlideMonth(dates.startDate);
      await dispatch(
        getCalendarFlights({
          year: dates.startDate?.getFullYear(),
          month: dates.startDate?.getMonth() + 1,
          departure: departureAerport || '',
          destination: destinationCountry || '',
          isRoundTrip: isRoundTrip && !dates.endDate,
          selectedDate: dates.startDate,
          pax,
        }),
      );
      dispatch(setFuturePrices(dates.startDate, getPriceByDate(dates.startDate)?.value));
    }
  };
  const handleSingleDatesChange = (date: Date | null) => {
    setDate(date);
    dispatch(saveDates(date));
  };
  const initialCalendarFetch = useCallback(
    () =>
      isRoundTrip
        ? dispatch(
            getInitialRoundedCalendarFlights({
              year: dates.startDate?.getFullYear() || new Date().getFullYear(),
              month: (dates.startDate?.getMonth() || new Date().getMonth()) + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
            }),
          )
        : dispatch(
            getCalendarFlights({
              year: dates.startDate?.getFullYear() || new Date().getFullYear(),
              month: (dates.startDate?.getMonth() || new Date().getMonth()) + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
            }),
          ),
    [dates.startDate, departureAerport, destinationCountry, dispatch, isRoundTrip],
  );

  useEffect(() => {
    !flights?.length && !roundTripStartDateClick && initialCalendarFetch();
  }, [flights?.length, initialCalendarFetch, roundTripStartDateClick]);
  const handleTripType = useCallback(
    (value) => {
      value === 'round'
        ? dispatch(
            getInitialRoundedCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
              pax,
            }),
          )
        : dispatch(
            getCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
              pax,
            }),
          );
      dispatch(setFlightType(value === 'round'));
    },
    [departureAerport, destinationCountry, dispatch, pax],
  );
  const handlePaxChange = useCallback(
    (value) => {
      isRoundTrip
        ? dispatch(
            getInitialRoundedCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
              pax: +value,
            }),
          )
        : dispatch(
            getCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
              pax: +value,
            }),
          );
      dispatch(setPax(value));
    },
    [departureAerport, destinationCountry, dispatch, isRoundTrip],
  );

  const isBestPrice = useCallback(
    (price: number, flights: ICalendarFLight[]) => {
      if (dates.startDate)
        flights = flights.filter(
          (e) =>
            !isEqual(
              removeTimeFromDate(dates.startDate as Date),
              removeTimeFromDate(new Date(e.dateLocalTakeoff)),
            ),
        );

      return price === Math.min(...flights.filter((el) => el.price !== 0).map((e) => e.price));
    },
    [dates.startDate],
  );
  const getPriceByDate = useCallback(
    (day) => {
      let flattenedPrices = flights?.reduce(
        (curr: ICalendarFLight[], prev) => [...curr, ...prev.data],
        [],
      );

      if (dates.startDate)
        flattenedPrices = flattenedPrices.filter(
          (e) =>
            !isEqual(removeTimeFromDate(dates.startDate as Date), removeTimeFromDate(day)),
        );

      const dayPrices = flattenedPrices?.filter((el) =>
        isEqual(removeTimeFromDate(new Date(el.dateLocalTakeoff)), removeTimeFromDate(day)),
      );

      if (dayPrices?.length && flattenedPrices) {
        const minPrice = Math.min(...dayPrices.map((e) => e.price));

        return minPrice
          ? {
              view: (
                <span
                  className={`${isBestPrice(minPrice, flattenedPrices) ? 'text-success' : ''}`}
                >{`${minPrice.toFixed(0)}€`}</span>
              ),
              value: minPrice,
            }
          : { view: '', value: 0 };
      }

      return null;
    },

    [dates.startDate, flights, isBestPrice],
  );
  const renderDayContents = useCallback(
    (day: Date) => (
      <div className="d-flex flex-column">
        <div>{day.getDate()}</div>
        {!isBefore(day, new Date()) && (
          <small>
            {flights.length ? (
              getPriceByDate(day)?.view
            ) : (
              <span className="blurry-price">999</span>
            )}
          </small>
        )}
      </div>
    ),
    [flights, getPriceByDate],
  );
  const handleMonthChange = useCallback(
    (date: Date) => {
      setLastSelectedMonth(date);
      isRoundTrip
        ? dispatch(
            getInitialRoundedCalendarFlights({
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
              pax,
            }),
          )
        : dispatch(
            getCalendarFlights({
              year: date.getFullYear(),
              month: date.getMonth() + 1,
              departure: departureAerport || '',
              destination: destinationCountry || '',
              pax,
            }),
          );
    },

    [departureAerport, destinationCountry, dispatch, isRoundTrip, pax],
  );
  const handleBlockedDays = useCallback(
    (date: Date) =>
      isBefore(removeTimeFromDate(date), removeTimeFromDate(new Date())) ||
      (!getPriceByDate(date) &&
        isEqual(removeTimeFromDate(date), removeTimeFromDate(new Date()))),

    [getPriceByDate],
  );
  const handleReset = useCallback(() => {
    dispatch(
      isRoundTrip
        ? getInitialRoundedCalendarFlights({
            year: lastSelectedMonth.getFullYear(),
            month: lastSelectedMonth.getMonth() + 1,
            departure: departureAerport || '',
            destination: destinationCountry || '',
          })
        : getCalendarFlights({
            year: lastSelectedMonth.getFullYear(),
            month: lastSelectedMonth.getMonth() + 1,
            departure: departureAerport || '',
            destination: destinationCountry || '',
          }),
    );
    setDates({ startDate: null, endDate: null });
    setDate(null);
  }, [departureAerport, destinationCountry, dispatch, isRoundTrip, lastSelectedMonth]);
  const { 'start-date': queryDepartureDate, 'returning-date': queryReturningDate } = useQuery([
    'luggage',
    'via-airports',
    'departure',
    'destination',
    'start-date',
    'returning-date',
    'seats',
  ]) as Record<string, string>;

  useEffect(
    () => () => {
      dispatch(resetCalendar());
    },
    [dispatch],
  );

  return {
    initialCalendarFetch,
    pax,
    date,
    dates,
    flights,
    windowSize,
    handleReset,
    isRoundTrip,
    focusedInput,
    initialMonth,
    handleTripType,
    setFocusedInput,
    handlePaxChange,
    renderDayContents,
    handleMonthChange,
    handleBlockedDays,
    handleDatesChange,
    queryDepartureDate,
    queryReturningDate,
    focusedSingleDateInput,
    handleSingleDatesChange,
    roundTripStartDateClick,
    setFocusedSingleDateInput,
  };
};
