import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import { formCivilityOptions } from 'client/constants/formCivilityOptions';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { ISelectorOption } from 'types/ISelectorOption';
import useReservationFormContainer from './useReservationFormContainer';

const MainInformation = ({
  familyOptions,
  childCount,
  adultCount,
}: {
  familyOptions: ISelectorOption<string>[];
  childCount: number;
  adultCount: number;
}) => {
  const { t, countryList } = useReservationFormContainer();

  return (
    <>
      <h2 className=" text-success">
        {t('room.accommodation')} {t('room.for')} {adultCount} {t('room.Adult')}{' '}
        {childCount > 0 && `et ${childCount} ${t('room.child')}`} :
      </h2>
      <div className="d-flex flex-wrap border-top border-bottom py-3">
        {familyOptions.map((person: { label: string; value: string }, index) => (
          <div key={`${person.value}${index + 1}`} className="p-2 border col-12 col-md-6">
            <h3>{person.label}</h3>
            <div className="d-flex flex-row align-items-center w-100 flex-start">
              <Input
                name={`travellers[${index}].id`}
                type="text"
                readOnly
                value={person.value}
                style={{ display: 'none' }}
                labelClassName="w-100 d-flex flex-start"
              />
              <Input
                name={`travellers[${index}].firstName`}
                type="text"
                label={`${t('room.formReservation.firstName')} (*)`}
                placeholder={`${t('room.formReservation.firstName')}`}
                className="mr-3 py-0 w-100 "
                validate={Validators([
                  { validation: 'required' },
                  { minParams: 3, validation: 'minLength' },
                ])}
                defaultValue=""
                labelClassName="w-100 d-flex flex-start"
              />
              <FormSelect
                label={`${t('room.formReservation.civility')} (*)`}
                name={`travellers[${index}].civility`}
                options={formCivilityOptions}
                validate={Validators([{ validation: 'required' }])}
                initialValue={[{ label: '', value: '' }]}
                selectClassName="w-100"
              />
            </div>
            <Input
              name={`travellers[${index}].lastName`}
              type="text"
              label={`${t('room.formReservation.lastName')} (*)`}
              placeholder={`${t('room.formReservation.lastName')}`}
              validate={Validators([
                { validation: 'required' },
                { minParams: 3, validation: 'minLength' },
              ])}
              defaultValue=""
              labelClassName="w-100 d-flex flex-start"
            />
            <Input
              name={`travellers[${index}].birthDate`}
              type="date"
              label={`${t('room.formReservation.birthDate')} (*)`}
              placeholder="..-..-.."
              validate={Validators([{ validation: 'required' }])}
              defaultValue=""
              labelClassName="w-100 d-flex flex-start"
            />
            <FormSelect
              label={`${t('room.formReservation.nationality')} (*)`}
              name={`travellers[${index}].nationality`}
              options={countryList}
              isSearchable
              validate={Validators([{ validation: 'required' }])}
              initialValue={[{ label: '', value: '' }]}
            />
            <Input
              name={`travellers[${index}].passportDeliveryDate`}
              type="date"
              label={`${t('room.formReservation.passportDeliveryDate')} (*)`}
              placeholder={`${t('room.formReservation.passportDeliveryDate')}`}
              validate={Validators([{ validation: 'required' }])}
              defaultValue=""
              labelClassName="w-100 d-flex flex-start"
            />
            <Input
              name={`travellers[${index}].passport`}
              type="text"
              label={`${t('room.formReservation.passportID')} (*)`}
              placeholder={`${t('room.formReservation.passportID')}`}
              defaultValue=""
              labelClassName="w-100 d-flex flex-start"
              validate={Validators([{ validation: 'required' }])}
            />
            {person.label.includes('Child') ? (
              <Input
                name={`travellers[${index}].type`}
                type="text"
                readOnly
                value="Child"
                style={{ display: 'none' }}
              />
            ) : (
              <Input
                name={`travellers[${index}].type`}
                type="text"
                readOnly
                value="Adult"
                style={{ display: 'none' }}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default MainInformation;
