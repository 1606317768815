/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import DataTable from 'react-data-table-component';
import columns from './columns';
import { useUserTable } from './useUserTable';

const UserTable: React.FC = () => {
  const {
    items,
    setPage,
    setLimit,
    limit,
    keyword,
    setKeyword,
    totalPages,
    t,
  } = useUserTable();

  const cols = columns(
    t('dashboard.userList.lastName'),
    t('dashboard.userList.firstName'),
    t('dashboard.userList.email'),
  );

  return (
    <DataTable
      title="User's List"
      data={items || []}
      columns={cols}
      pagination
      selectableRows
      paginationTotalRows={totalPages}
      paginationServer
      paginationPerPage={limit}
      subHeader
      persistTableHead
      expandOnRowClicked
      highlightOnHover
      noDataComponent={t('dashboard.userList.noData')}
      onChangePage={(page) => setPage(page)}
      onChangeRowsPerPage={(limit) => setLimit(limit)}
      subHeaderComponent={
        <input
          className="form-control mb-3"
          placeholder={t('dashboard.userList.searchInput')}
          value={keyword}
          onChange={(event) => setKeyword(event.target.value)}
        />
      }
    />
  );
};

export default UserTable;
