import Modal from 'dashboard/components/Modal';
import AddProductForm from 'dashboard/containers/AddProductForm';
import CountriesContainer from 'dashboard/containers/CountriesCardsContainer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CountriesPage = () => {
  const [t] = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <CountriesContainer sectionTitle={t('homePageSections.Countries')} />
      <div className="add_country_btn">
        <button
          type="button"
          className="btn kc_fab_main_btn p-0 mb-5 ml-5 "
          onClick={() => setOpenModal(true)}
        >
          +
        </button>
      </div>
      <Modal
        closeModal={() => setOpenModal(false)}
        isOpen={openModal}
        modalHead={t('dashboard.addDestination.addNewDestination')}
      >
        <AddProductForm openModal={setOpenModal} />
      </Modal>
    </>
  );
};

export default CountriesPage;
