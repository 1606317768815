/* eslint-disable no-nested-ternary */
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useQuery } from 'client/hooks/useQuery';
import { saveFlightsPassengers } from 'client/_redux/actions/flights';
import { differenceInYears } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IFlightClient } from 'types/IFlightData';

export const useFlightsForm = () => {
  const selectedFlights = useAppSelector(
    ({ flightsReducer }) => flightsReducer.selectedFlights,
  );

  const dispatch = useDispatch();
  const formMethods = useForm<IFlightClient>();
  const { seats: pax, babies, kids } = useQuery(['seats', 'babies', 'kids']) as Record<
    string,
    string
  >;
  const history = useHistory();
  const onSubmit = useCallback(
    (values: { userData: IFlightClient[] }) => {
      const userData = values.userData.map((person) => ({
        ...person,
        isInfant: differenceInYears(new Date(), new Date(person.dateOfBirth)) <= 1,
      }));

      dispatch(saveFlightsPassengers(userData));
      history.push(
        `/flights-confirmation${pax ? `?seats=${pax}` : 'seats=1'}${
          kids && Number(kids) > 0 ? `&kids=${kids}` : ''
        }${babies && Number(babies) > 0 ? `&babies=${babies}` : ''}`,
      );
    },
    [babies, dispatch, history, kids, pax],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  const formattedSelectedFlights =
    selectedFlights &&
    (selectedFlights.singleFlight ||
      (selectedFlights.goingFlight && selectedFlights.returningFlight))
      ? selectedFlights.singleFlight
        ? {
            oneWayFlights: selectedFlights.singleFlight,
          }
        : {
            goingFlight: selectedFlights.goingFlight,
            returningFlight: selectedFlights.returningFlight,
          }
      : undefined;

  return {
    formMethods,
    onSubmit,
    pax,
    selectedFlights: formattedSelectedFlights,
    babies,
    kids,
  };
};
