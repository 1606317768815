import { IThunkAction } from 'types/IThunkAction';
import { errorHandler } from 'client/helpers/errorHandler';
import { getQuotation } from 'client/_redux/services/quotations';
import { availibility, quote, roomDetails } from 'client/_redux/types/availability';
import { IQuotedAvailabilityParams } from 'types/IQuotedAvailabilityRequest';
import { FormattedRoom } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { AddonFormValues } from 'client/containers/QuotedAvailability/ProductAddons/useProductAddons';
import { SelectedTripTypes } from 'client/_redux/types/selectedTripTypes';
import { startLoading, stopLoading } from '../loading';

export const getQuotedAvailability = (
  params: IQuotedAvailabilityParams,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await getQuotation()(params);

    if (response.status === 'UNAVAILABLE') throw new Error(response.errorMessage);

    dispatch({
      type: availibility.STATUS_AVAILABLE_SUCCESS,
      payload: {
        ...response,
        childCount: params['child-count'],
        adultCount: params['adult-count'],
        permalink: params.permalink,
      },
    });

    dispatch({
      type: SelectedTripTypes.SET_TAX_AND_TRANSPORTATION_AND_SUPPLEMENT,
      payload: Array.isArray(response.trip?.priceDetails)
        ? {
            tax: response.trip?.priceDetails.find((o) => o.type === 'Tax')?.price,
            transportation: response.trip?.priceDetails.find(
              (o) => o.type === 'Transportation',
            )?.price,
            supplement: response.trip?.priceDetails.find((o) => o.type === 'Supplement')
              ?.price,
          }
        : { [response.trip?.priceDetails.type as string]: response.trip?.priceDetails.price },
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: availibility.STATUS_AVAILABLE_FAILED });

    return false;
  }

  dispatch(stopLoading());

  return true;
};

export const reserveRoom = (rooms: FormattedRoom[]): IThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  try {
    const {
      selectedTripReducer: { selectedRooms },
    } = getState();
    let updatedRooms = rooms;

    if (selectedRooms.length && selectedRooms.length === rooms.length) {
      updatedRooms = rooms.map((room, i) => ({
        ...{
          adultCount: selectedRooms[i].adultCount,
          childCount: selectedRooms[i].childCount,
        },
        ...room,
        alternativeRooms: undefined,
      }));
    }

    dispatch({ type: roomDetails.SET_ROOMS, payload: updatedRooms });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: roomDetails.SET_ROOMS_FAILED });
  }
  dispatch(stopLoading());
};

export const setAddons = (values: AddonFormValues): IThunkAction => async (dispatch) => {
  try {
    dispatch({ type: quote.SET_ADDONS, payload: values });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: quote.SET_ADDONS_FAILED });
  }
};
