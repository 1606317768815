import { clientApi } from 'api';
import { getFromData } from 'dashboard/helpers/getFromData';
import { IDestinationFormValues } from 'dashboard/containers/AddProductForm/useAddProductForm';

export const updateCountry = (isServer = false) => async (
  data: IDestinationFormValues,
  id?: string,
) => {
  const formData = new FormData();

  getFromData(formData, data);

  const response = await clientApi.patch<IDestinationFormValues>(`/countries/${id}`, formData);

  return response;
};
