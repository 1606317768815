import { useAppSelector } from 'client/hooks/useAppSelector';
import { getTransaction } from 'client/_redux/actions/transaction';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

export const useReservationDetails = () => {
  const dispatch = useDispatch();
  const { transactionId } = useParams<{ transactionId: string }>();
  const { transaction } = useAppSelector((state) => state.transactionsReducer);
  const [t] = useTranslation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  useEffect(() => {
    dispatch(getTransaction(transactionId));
  }, [dispatch, transactionId]);

  return { transaction, t };
};
