import { upperCaseFirstLetter } from './upperCaseFirstLetter';

export const formatSelectOptions = <T extends Record<string, unknown>, B extends keyof T>(
  array: T[] = [],
  labelKey: B,
): { label: string; value: T[B] }[] =>
  array.map((o) => ({
    label: upperCaseFirstLetter(o[labelKey] as string),
    value: o[labelKey],
  }));
