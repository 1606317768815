/* eslint-disable react-hooks/exhaustive-deps */
import { initializeImagePicker } from 'client/helpers/initializeImagePicker';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { IFileWithPreview } from 'types/IFileWithPreview';
import { useTranslation } from 'react-i18next';
import ImagePickerPreview from './ImagePickerPreview';

export interface IProps {
  maxFiles?: number;
  label?: string;
  onChange?: (files: File[]) => void;
  errors?: FieldError;
  name: string;
}

interface IComponentProps extends IProps {
  initialValue: (File | string)[];
}

const isString = (el: unknown): el is string => typeof el === 'string';

const ImagePicker: React.FC<IComponentProps> = ({
  name,
  maxFiles = 0,
  label,
  onChange,
  errors,
  initialValue,
}) => {
  const [t] = useTranslation();
  const [files, setFiles] = useState<IFileWithPreview[]>([]);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles
          .filter((_file, index) => prev.length + index + 1 <= maxFiles)
          .map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
      ]);
    },
    [maxFiles],
  );

  const _initialValue = useRef<string[] | null>(null);
  const initImagePicker = useCallback(async () => {
    const initialFiles = await initializeImagePicker(_initialValue.current as string[]);

    setFiles(initialFiles);
  }, [_initialValue.current]);

  useEffect(() => {
    if (!(initialValue instanceof Array)) {
      setFiles([]);

      return;
    }
    if (initialValue?.filter(isString).length === 0 || _initialValue.current) return;
    _initialValue.current = initialValue.filter(isString);
    initImagePicker();
  }, [initialValue, initImagePicker]);

  useEffect(() => {
    onChange?.(files);
  }, [files]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    accept: 'image/jpeg, image/png, image/webp',
  });

  const deleteFile = (index: number) => {
    setFiles((prev) => prev.filter((_file, i) => index !== i));
  };

  return (
    <div className="form-group">
      <label htmlFor={name} className="form-label">
        <span className="d-flex justify-content-between align-items-center">{label}</span>
      </label>
      <div
        {...getRootProps()}
        className={`u-dropzone flex-column ${errors ? `border-danger` : ''}`}
      >
        <input {...getInputProps()} id={name} />
        <span className="text-center">
          {isDragActive ? t('imagePicker.dragPrompt') : t('imagePicker.dragPrompt')}
          {maxFiles > 0 && (
            <span className={`ml-1 ${files.length >= maxFiles ? 'text-danger' : ''}`}>
              {t('imagePicker.maxFilesPrompt').replace(':maxFiles', maxFiles.toString())}
            </span>
          )}
        </span>
        {files.length > 0 && (
          <div className="row mt-5">
            {files.map((file, index) => (
              <ImagePickerPreview
                key={file.preview || file.name + index}
                {...{ file, index, deleteFile }}
              />
            ))}
          </div>
        )}
      </div>
      {errors && (
        <div
          data-testid="inputError"
          className="invalid-feedback"
          style={{ display: 'block' }}
        >
          {errors.message}
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
