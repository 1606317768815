import FormImagePicker from 'dashboard/components/Forms/FormImagePicker';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import { PromotionState } from 'dashboard/_redux/reducers/promotion';
import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { usePromotionFrom } from './usePromotionForm';

interface IPorps {
  onSubmit: SubmitHandler<PromotionState>;
  initialValues: PromotionState | null;
}

const PromotionForm: React.FC<IPorps> = ({ initialValues, onSubmit }) => {
  const { formMethods, handleSubmit, _onSubmit, t } = usePromotionFrom(
    onSubmit,
    initialValues,
  );

  return (
    <FormProvider {...formMethods}>
      <form className="mt-5" onSubmit={handleSubmit(_onSubmit)}>
        <Input
          type="url"
          name="url"
          label="Lien:"
          placeholder="https://kepler-travel.com/..."
          validate={Validators([{ validation: 'required' }])}
        />
        <Input
          type="text"
          name="title"
          label="Titre:"
          placeholder="Title"
          validate={Validators([{ validation: 'required' }])}
        />
        <Input
          type="textarea"
          name="description"
          label="Paragraph:"
          placeholder="Description..."
          validate={Validators([{ validation: 'required' }])}
        />
        <FormImagePicker
          validate={Validators([{ validation: 'required' }])}
          name="image"
          label="Image de promotion:"
          maxFiles={1}
        />
        <button
          name="Commencer"
          data-testid="submitButton"
          className="btn btn-primary mb-1"
          type="submit"
        >
          {t('productDetails.save')}
        </button>
      </form>
    </FormProvider>
  );
};

export default PromotionForm;
