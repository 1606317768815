export enum AdministratorTypes {
  ADD_ADMIN = 'ADD_ADMIN',
  UPDATE_ADMIN = 'UPDATE_ADMIN',
  FETCH_ADMIN = 'FETCH_ADMIN',
  SET_ADMIN = 'SET_ADMIN',
  RESET_ADMIN = 'RESET_ADMIN ',
  SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',
}
export enum GetAllAdminsTypes {
  ADMINS_SUCCESS = 'ADMINS_SUCCESS',
  ADMINS_FAILURE = 'ADMINS_FAILURE',
  ADMINS_START = 'ADMINS_START',
}
export enum DeleteAdminTypes {
  DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE',
}
