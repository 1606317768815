import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import YoutubeEmbed from 'client/components/YoutubeEmbed';
import React from 'react';
import LightboxGallery from '../LightboxGallery';

interface IProps {
  images?: string[];
  openSlider: () => void;
  getIndex: (value: number) => void;
  youtubeEmbedID?: string;
}

const ImageGallery: React.FC<IProps> = ({ images, openSlider, getIndex, youtubeEmbedID }) => {
  const openSiderWithIndex = (index: number) => {
    getIndex(index);
    openSlider();
  };
  let firstIndex = 0;
  let leftImagesIndex = 0;

  if (youtubeEmbedID && youtubeEmbedID?.length > 0) {
    leftImagesIndex = 1;
    firstIndex = -1;
  }

  if (!images) return null;

  return (
    <>
      <ResponsiveWrapper>
        <div className="row no-gutters">
          <div className="p-1 col-md-4">
            {images.slice(0, 3 - leftImagesIndex).map((image, index) => (
              <div key={image} className="pb-1">
                {index !== firstIndex && (
                  <LightboxGallery
                    backgroundSize="cover"
                    index={index}
                    tabIndex={index}
                    height="25vh"
                    imageUrl={image}
                    onClick={() => openSiderWithIndex(index)}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="pr-1 pl-1 pb-1 col-md-8 mt-1">
            {youtubeEmbedID && youtubeEmbedID?.length > 0 ? (
              <YoutubeEmbed embedId={youtubeEmbedID} />
            ) : (
              <LightboxGallery
                backgroundSize="cover"
                index={0}
                tabIndex={0}
                height="50.5vh"
                imageUrl={images[0]}
                onClick={() => openSiderWithIndex(0)}
              />
            )}
          </div>
        </div>
        <div className="row no-gutters">
          {images.slice(3, 8).map((image, index) => (
            <div className="p-1 col" key={`${index + 1}`}>
              <LightboxGallery
                backgroundSize="cover"
                index={index + 5}
                tabIndex={index}
                height="15vh"
                imageUrl={image}
                onClick={() => openSiderWithIndex(index + 3)}
              >
                {index === 4 && (
                  <button
                    style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                    className="d-flex align-items-center justify-content-center w-100 bottom-0 position-absolute"
                    type="button"
                    onClick={openSlider}
                  >
                    <h3 className="text-white" style={{ textShadow: '2px 2px #000000' }}>
                      {`${images.length - 8} autres photos`}
                    </h3>
                  </button>
                )}
              </LightboxGallery>
            </div>
          ))}
        </div>
      </ResponsiveWrapper>
      <ResponsiveWrapper isMobile>
        <div className="row no-gutters">
          <div className="p-1 col-md-4">
            {images.slice(0, 1 - leftImagesIndex).map((image, index) => (
              <div key={image} className="pb-1">
                {index !== firstIndex && (
                  <LightboxGallery
                    backgroundSize="cover"
                    index={index}
                    tabIndex={index}
                    height="25vh"
                    imageUrl={image}
                    onClick={() => openSiderWithIndex(index)}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="pr-1 pl-1 pb-1 col-md-8 mt-1">
            {youtubeEmbedID && youtubeEmbedID?.length > 0 ? (
              <YoutubeEmbed embedId={youtubeEmbedID} isMobile />
            ) : (
              <LightboxGallery
                backgroundSize="cover"
                index={0}
                tabIndex={0}
                height="50.5vh"
                imageUrl={images[0]}
                onClick={() => openSiderWithIndex(0)}
              />
            )}
          </div>
        </div>
        <div className="row no-gutters">
          {images.slice(3, 6).map((image, index) => (
            <div className="p-1 col" key={`${index + 1}`}>
              <LightboxGallery
                backgroundSize="cover"
                index={index + 3}
                tabIndex={index}
                height="15vh"
                imageUrl={image}
                onClick={() => openSiderWithIndex(index + 3)}
              >
                {index === 2 && (
                  <button
                    style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                    className="d-flex align-items-center justify-content-center w-100 bottom-0 position-absolute"
                    type="button"
                    onClick={openSlider}
                  >
                    <h3 className="text-white" style={{ textShadow: '2px 2px #000000' }}>
                      {`${images.length - 6} autres photos`}
                    </h3>
                  </button>
                )}
              </LightboxGallery>
            </div>
          ))}
        </div>
      </ResponsiveWrapper>
    </>
  );
};

export default ImageGallery;
