import React from 'react';

const SidebarTitle: React.FC<{ title: string }> = ({ title }) => (
  <>
    <hr
      style={{
        borderTop: '1px solid #677788',
      }}
    />
    <div className="pb-3">
      <span className="h5">
        <strong>{title}</strong>
      </span>
    </div>
  </>
);

export default SidebarTitle;
