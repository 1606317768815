import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import { FilterProductTypes } from 'dashboard/_redux/types/FilterProductTypes';
import * as ProductFilterServices from 'dashboard/_redux/services/productsFilter';
import { IThunkAction } from 'types/IThunkAction';
import { IFilter } from 'server/models/Filter/types';
import { RouteComponentProps } from 'react-router';

export const addFilter = (
  value: IFilter,
  history: RouteComponentProps['history'],
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    ProductFilterServices.addProductFilter()(value);

    history.push('/dashboard/filter-products');

    dispatch({
      type: FilterProductTypes.ADD_FILTER_PRODUCTS,
      payload: value,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: FilterProductTypes.ADD_FILTER_PRODUCTS_FAILED });
  }

  dispatch(stopLoading());
};
export const getfilterById = (id: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const productFiltre = await ProductFilterServices.getProductfilterById()(id);

    dispatch({
      type: FilterProductTypes.GET_FILTER_PRODUCTS_By_ID,
      payload: productFiltre.data,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const getfilter = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const productFiltre = await ProductFilterServices.getProductfilter()();

    dispatch({
      type: FilterProductTypes.GET_FILTER_PRODUCTS,
      payload: productFiltre.data.filterProducts,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: FilterProductTypes.GET_FILTER_PRODUCTS_FAILED });
  }

  dispatch(stopLoading());
};
export const deletefilter = (id: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await ProductFilterServices.deleteFilterProduct()(id);

    dispatch({
      type: FilterProductTypes.DELETE_FILTER_PRODUCTS,
      payload: id,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const updatefilter = (
  id: string,
  value: IFilter,
  history: RouteComponentProps['history'],
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const ProductFilter = await ProductFilterServices.updateProductFilter()(id, value);

    history.push('/dashboard/filter-products');

    dispatch({
      type: FilterProductTypes.UPDATE_FILTER_PRODUCTS,
      payload: ProductFilter.data,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
