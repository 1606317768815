import * as React from 'react';
import SEO from 'client/components/SEO';
import GeneralFilter from 'client/containers/ProductListFormFilter/GeneralFilter';
import { ISelectorOption } from 'types/ISelectorOption';
import ProductListCardContainer from 'client/containers/ProductListCardContainer';
import { ICustomCatalogue } from 'types/ICatalogue';
import CustomFilter from 'client/containers/ProductListFormFilter/CustomFilter';
import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import Pagination from 'client/components/Pagination';
import Select from 'client/components/Form/Select';
import FilterHeader from './FilterHeader';
import { useProductListPage } from './useProductListPage';
export type filterValues = {
  from: string[] | string;
  city: string[];
  country: string[];
  'product-type': ISelectorOption[] | string[];
  duration: ISelectorOption[] | string[];
  prices: string[];
  rating: string[];
  custom: Record<string, string[]>;
  attributes?: string[];
};
const ProductList: React.FC = () => {
  const {
    products,
    loading,
    t,
    productsCriteria: productSearchCriteriaValue,
    onSubmit,
    searchObjectFilter,
    FormProvider,
    formMethods,
    handleSubmit,
    fromQuery,
    filterProducts: productsFilter,
    showSideFilter,
    setShowSideFilter,
    showSideProductFilters,
    setShowSideProductFilters,
    page,
    totalPages,
    totalItems,
    handleChange,
    AscDesc,
    countriesOptions,
    sortDirection,
  } = useProductListPage();

  return (
    <>
      <SEO
        title="Catalogue des produits"
        description="Nous donnons accès à tous nos partenaires et clients des packages inédits, disponibles 
      immédiatement et permettant de réserver des voyages avec une grande flexibilité à des prix très attractifs."
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className=" max-w-100">
          <div className="container container-xxl">
            <div className="row justify-content-between">
              <ResponsiveWrapper className="col-12 px-0" isMobile>
                <FilterHeader
                  setShowSideProductFilters={setShowSideProductFilters}
                  setShowSideFilter={setShowSideFilter}
                  showSideFilter={showSideFilter}
                  showSideProductFilters={showSideProductFilters}
                />
              </ResponsiveWrapper>
              <div className="col-12 col-md-3 px-0">
                <div className={`${!showSideFilter ? 'is-mobile-hidden' : ''}`}>
                  <GeneralFilter
                    searchObjectFilter={searchObjectFilter}
                    searchCriteria={productSearchCriteriaValue}
                    setShowSideFilter={setShowSideFilter}
                    showCloseButton
                    countriesOptions={countriesOptions}
                  />
                </div>
                <div className={`${!showSideProductFilters ? 'is-mobile-hidden' : ''}`}>
                  <CustomFilter
                    productFilers={productsFilter}
                    setShowSideProductFilters={setShowSideProductFilters}
                    showCloseButton
                  />
                </div>
              </div>
              <div className="col-12 col-md-9 no-gutters mt-5">
                <div className="row justify-content-between px-5">
                  <p className="my-auto">{totalItems} produits trouvés</p>
                  <Select
                    options={AscDesc}
                    onChange={handleChange}
                    selectClassName="w-md-20 w-40"
                    value={sortDirection}
                  />
                </div>
                {(totalItems || 0) > 0 ? (
                  products
                    ?.filter((o: ICustomCatalogue) => o.permalink !== 'circuit-test')
                    .map((product: ICustomCatalogue) => (
                      <div key={product._$id} className="ml-md-5 mb-3">
                        <ProductListCardContainer
                          fromQuery={fromQuery as string}
                          product={product}
                          loading={loading}
                        />
                      </div>
                    ))
                ) : (
                  <div className="space-2 container">
                    Aucun produit ne correspand à votre recherche!
                    <br />
                    Veuillez réduire le nombre de filtre que vous avez utilisé
                  </div>
                )}

                {(totalItems || 0) > 0 && page && totalPages && (
                  <Pagination
                    totalPages={totalPages}
                    page={page}
                    next={t('pagination.next')}
                    previous={t('pagination.previous')}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default ProductList;
