import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import * as React from 'react';
import { ValidationRules } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';

export interface IListSelectInputProps<T extends unknown> {
  options: ISelectorOption<T>[];

  label?: string;

  validate?: ValidationRules;
  Link?: React.LinkHTMLAttributes<string>;
  handleSelect: (e: string) => void;

  handleClose?: () => void;
  value: T;
}
const SelectList = <T extends unknown = unknown>({
  options,
  handleSelect,
  handleClose,
  value,
}: IListSelectInputProps<T>) => {
  const onSelect = (selectedValue: string) => {
    handleSelect(selectedValue);
    handleClose?.();
  };

  return (
    <div className="d-flex list-group rounded-0 m-2 mb-0  mt-5" style={{ overflowY: 'auto' }}>
      {options.map((o, index) => (
        <button
          type="button"
          key={`${o?.label}${index + 1}`}
          onClick={() => onSelect(o?.label)}
          className={`border px-3 py-2 mb-3 text-left h3 font-weight-light ${
            value === o?.label && 'text-primary border-primary'
          }`}
          style={{ cursor: 'pointer' }}
        >
          {upperCaseFirstLetter(o?.label || '')}
        </button>
      ))}
    </div>
  );
};

export default SelectList;
