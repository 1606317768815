import { UsersTypes } from 'dashboard/_redux/types/usersTypes';
import { IUser } from 'server/models/User';
import { PaginatedResponse } from 'client/models/utils';
import { IReduxAction } from 'types/IReduxAction';

const initialState = {
  loading: false,
};

export interface IUsersState {
  users?: PaginatedResponse<IUser>;
  loading?: boolean;
  error?: Error;
}
export default (
  state: IUsersState = initialState,
  { type, payload, error }: IReduxAction,
): IUsersState => {
  switch (type) {
    case UsersTypes.FETCH_USERS_START:
      return { ...state, loading: true };
    case UsersTypes.FETCH_USERS_SUCCESS:
      return { ...state, loading: false, users: payload };
    case UsersTypes.FETCH_USERS_FAILURE:
      return { ...state, loading: false, error };
    default:
      return state;
  }
};
