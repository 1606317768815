import { availibility, roomDetails } from 'client/_redux/types/availability';
import { IFormattedQuotedAvailability } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { IReduxAction } from 'types/IReduxAction';

export interface IQuotedAvailabilityState {
  loading: boolean;
  trip?: IFormattedQuotedAvailability['trip'];
  adultCount: string;
  childCount: string;
  permalink: string;
}
const initialState: IQuotedAvailabilityState = {
  loading: true,
  adultCount: '0',
  childCount: '0',
  permalink: '',
};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): IQuotedAvailabilityState => {
  switch (type) {
    case availibility.STATUS_AVAILABLE_FAILED:
    case roomDetails.SET_ROOMS_FAILED:
      return { ...state, loading: false };

    case availibility.STATUS_AVAILABLE_SUCCESS:
      const { trip, childCount, adultCount, permalink } = payload;
      const loading = false;

      return {
        ...state,
        trip,
        loading,
        childCount,
        adultCount,
        permalink,
      };

    default:
      return state;
  }
};
