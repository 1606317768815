import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const usePaymentError = () => {
  const { pathname } = useLocation();
  const [t] = useTranslation();
  const isError = !!pathname.match('/payment-error');

  return { isError, t };
};
