import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import { PromotionTypes } from 'dashboard/_redux/types/promotionTypes';
import { IThunkAction } from 'types/IThunkAction';
import * as promotionServices from 'dashboard/_redux/services/promotion';
export const getPromotion = (): IThunkAction => async (dispatch, getState) => {
  const isPreloaded = getState().promotionReducer?.isPreloaded;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const promotion = await promotionServices.getPromotion()();

    dispatch({
      type: PromotionTypes.GET_PROMOTION,
      payload: promotion
        ? { ...promotion, image: `${process.env.RAZZLE_HOST}/${promotion.image}` }
        : promotion,
    });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
