import { Link } from 'react-router-dom';
import * as React from 'react';
import { ValidationRules, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

type InputOrAreaProps = React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>;

export interface IInputProps extends InputOrAreaProps {
  toggleShowPassword?: () => void;
  type?: string;
  isPasswordShown?: boolean;
  id?: string;
  label?: string;
  forgotPasswordMessage?: string;
  forgotPasswordLink?: string;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  name: string;
  validate?: ValidationRules;
  isSearchBar?: boolean;
  className?: string;
  labelClassName?: string;
}

const Input: React.FC<IInputProps> = ({
  isSearchBar,
  id,
  name,
  label,
  type,
  forgotPasswordLink,
  rightIcon,
  leftIcon,
  forgotPasswordMessage,
  validate,
  className,
  labelClassName,
  ...props
}) => {
  const isPassword = type === 'password';
  const [isPasswordShown, toggleShowPassword] = React.useState(false);
  const handletoggleShowPassword = (): void => {
    toggleShowPassword(!isPasswordShown);
  };
  const passwordFieldType = isPasswordShown ? 'text' : 'password';

  const { errors, register } = useFormContext();

  const fieldError = get(errors, name);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.preventDefault();
  };

  return (
    <div
      data-testid="inputFormGroup"
      className={`form-group ${className} ${isSearchBar ? 'my-0' : ' '}${
        fieldError ? 'u-has-error' : ''
      }`}
    >
      {!isSearchBar && label && (
        <label className={`form-label ${labelClassName} `} htmlFor={id}>
          <span className="d-flex justify-content-center align-items-center">
            {label}
            {isPassword && forgotPasswordMessage && (
              <Link
                className="pl-2 link-muted  font-weight-normal"
                to={forgotPasswordLink || ''}
              >
                {forgotPasswordMessage}
              </Link>
            )}
          </span>
        </label>
      )}
      <div className="input-group">
        {leftIcon && (
          <div className="input-group-prepend">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {leftIcon}
            </span>
          </div>
        )}
        {type === 'textarea' ? (
          <textarea
            {...props}
            ref={validate ? register(validate) : register}
            name={name}
            className={`form-control ${fieldError ? 'is-invalid' : ''}`}
          />
        ) : (
          <input
            name={name}
            {...props}
            ref={validate ? register(validate) : register}
            type={!isPassword ? type : passwordFieldType}
            className={`form-control ${className} ${fieldError ? 'is-invalid' : ''}`}
          />
        )}
        {rightIcon && (
          <div className="input-group-append">
            <span
              className={`input-group-text 
            ${fieldError ? 'border-danger' : ''}
            `}
              id="basic-addon2"
            >
              {rightIcon}
            </span>
          </div>
        )}
        {isPassword && (
          <div className="input-group-append">
            <button
              type="button"
              className="input-group-text"
              aria-label="toggle password visibility"
              onClick={handletoggleShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {!isPasswordShown ? (
                <i className="far fa-eye" />
              ) : (
                <i className="far fa-eye-slash" />
              )}
            </button>
          </div>
        )}
      </div>
      {fieldError && (
        <div
          data-testid="inputError"
          className="invalid-feedback"
          style={{ display: 'block' }}
        >
          {fieldError.message}
        </div>
      )}
    </div>
  );
};

export default Input;
