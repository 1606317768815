import { clientApi } from 'api';
import { ICustomCatalogue } from 'types/ICatalogue';
import { IPaginatedResult } from 'types/IPaginatedResult';

export const getCustomProducts = (isServer = false) => async (filter: string) => {
  const response = await clientApi.get<IPaginatedResult<ICustomCatalogue>>(
    `/catalogues/custom-catalogues${filter}`,
  );

  return response;
};
