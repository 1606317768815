import React,{useEffect} from 'react';

const QuiSommesNous: React.FC = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
 
return(
  <div style={{ marginTop: '-40px' }} >
    <div className="bg" style={{backgroundImage:`url("/assets/images/travel.jpg")`}}>
      <h2 className="bgTitle">Qui Sommes-Nous !</h2>
    </div>
    <div className="container" style={{ padding: '40px', textAlign: 'center' }}>
      <h2 className="sousTitle">Présentation de Kepler Travel</h2>

      <p className="text">
        -  Chez Kepler Travel, nous sommes passionnés par l’art de voyager. En tant que
        tour-opérateur spécialisé, nous nous engageons à offrir des expériences de voyage
        uniques et mémorables à travers le monde. Nous proposons une gamme diversifiée de
        circuits, séjours balnéaires et combinés sur plusieurs destinations. Notre mission est
        de faciliter l’accès à des packages exclusifs et attractifs, tout en garantissant
        flexibilité et sécurité dans les réservations. Notre Philosophie
      </p>

      <p className="text">
        Nous croyons que chaque voyage doit être une évasion parfaite, c’est pourquoi nous
        sélectionnons rigoureusement nos offres pour répondre aux attentes les plus exigeantes.
        Notre équipe d’experts dévoués est toujours prête à vous conseiller et à vous
        accompagner dans la réalisation de vos projets de voyage, que ce soit pour des vacances
        en famille, des aventures culturelles, ou des escapades romantiques.
      </p>

      <h2 className="sousTitle">Nos Atouts</h2>

      <p className="text">
        Des Voyages d’Exception : Des offres haut de gamme minutieusement choisies. Les
        Meilleures Réductions : Des prix exclusifs et des remises exceptionnelles. Experts à
        Votre Service : Une équipe formée pour répondre à toutes vos questions. Satisfaction
        Client : 95% de clients satisfaits, votre satisfaction est notre priorité. Facilité de
        Paiement : Options de paiement flexibles et sécurisées.
      </p>

      <h2 className="sousTitle">Pourquoi Nous Choisir ?</h2>

      <p className="text">
        Chez Kepler Travel, nous nous distinguons par notre souci du détail et notre engagement
        envers la qualité. Nous offrons une réservation simple et un paiement en ligne 100%
        sécurisé, afin que vous puissiez planifier votre voyage en toute sérénité.
        Rejoignez-nous pour vivre vos plus belles évasions et découvrez pourquoi tant de
        voyageurs nous font confiance.
      </p>

      <h2 className="sousTitle">Contactez-Nous</h2>

      <p className="text">- Adresse : <a style={{fontWeight:"bold"}} href="https://www.google.com/maps/place/27+Rue+Joubert,+75009+Paris,+France/@48.8746129,2.3304111,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66e3667a0e80b:0xedcd9befafa80b21!8m2!3d48.8746129!4d2.3304111!16s%2Fg%2F11c5pkdyq8?entry=ttu"><i className="fa fa-map-marker " style={{marginRight:"3px"}}/> 27 rue Joubert, 75009 Paris</a></p>
      <p className="text">- Téléphone : <a  href="tel:01 85 08 18 84" style={{fontWeight:"bold"}}><i className="fas fa-phone-alt " style={{marginRight:"3px"}}/> 01 85 08 18 84</a></p>
      <p className="text">- Email : <a  href="/contactez-us" style={{fontWeight:"bold"}}><i className="fas fa-envelope " style={{marginRight:"3px"}}/>reservations@keplertravel.com</a></p>
      <p className="text">
        - Pour plus d’informations et pour découvrir toutes nos offres, visitez notre site web :{' '}
        <a href="/" style={{fontWeight:"bold"}}><i className="fas fa-globe " style={{marginRight:"3px"}}/> Kepler Travel</a>.
      </p>
    </div>
  </div>
);
};

export default QuiSommesNous;
