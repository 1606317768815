import {
  Price,
  Transportation,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';

export const formatTransportationDetails = (transportationPrice: Transportation): Price => ({
  description: transportationPrice.price.description,
  quantity: transportationPrice.price.quantity,
  unitPrice: transportationPrice.price.unitPrice,
  totalPrice: transportationPrice.price.totalPrice,
  ref: transportationPrice.price.ref,
  currency: transportationPrice.price.currency,
});
