import { useAppSelector } from 'client/hooks/useAppSelector';
import { useQuery } from 'client/hooks/useQuery';
import { getArticles, getBlogCover } from 'client/_redux/actions/blogArticle';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useBlog = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const loading = useAppSelector((state) => state.loading.loading);
  const articles = useAppSelector(({ blog }) => ({
    articles: blog.articles,
  }));
  const pageNbr = Number(useQuery('page') || '1');

  useEffect(() => {
    dispatch(getBlogCover());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getArticles({ limit: 5, page: pageNbr }));
  }, [dispatch, pageNbr]);
  const cover = useAppSelector(({ blog }) => blog.blogCover);

  return {
    loading,
    articles: articles.articles,
    t,
    cover,
  };
};
