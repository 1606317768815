import * as React from 'react';
import FullScreenSlider from './FullScreenSlider';
import ImageGallery from './imageGallery';
import { useImageGalleryContainer } from './useImageGalleryContainer';

interface IProps {
  images?: string[];
  youtubeEmbedID?: string;
}

const ImageGalleryContainer: React.FC<IProps> = ({ images, youtubeEmbedID }) => {
  const {
    getIndexCallBack,
    showSlider,
    setShowSlider,
    currentIndex,
  } = useImageGalleryContainer();

  if (showSlider)
    return (
      <FullScreenSlider
        closeSlider={() => setShowSlider(false)}
        images={images}
        currentIndex={currentIndex || 0}
      />
    );

  return (
    <ImageGallery
      youtubeEmbedID={youtubeEmbedID}
      images={images}
      openSlider={() => setShowSlider(true)}
      getIndex={getIndexCallBack}
    />
  );
};

export default ImageGalleryContainer;
