import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import getRemainingAdultsChildren from 'client/helpers/getRemainingAdultsChildren';
import { formattedArrayOptionsForm } from 'client/helpers/formattedArrayOptionsForm';
import { useAppSelector } from 'client/hooks/useAppSelector';

const useReservationFormContainer = () => {
  const [t] = useTranslation();
  const formMethods = useFormContext();

  const { watch } = formMethods;

  const { adultCount, childCount, selectedRooms, selectedAddons } = useAppSelector(
    ({
      quotedAvailabilityReducer: { adultCount, childCount },
      selectedTripReducer: { selectedRooms, selectedAddons },
    }) => ({
      adultCount,
      childCount,
      selectedRooms,
      selectedAddons,
    }),
  );

  const { familyOptions, adultOptions, childOptions } = useMemo(
    () => formattedArrayOptionsForm(+adultCount, +childCount),
    [adultCount, childCount],
  );

  const roomList = watch('roomList');

  const { remainingChildren, remainingAdults } = useMemo(
    () =>
      getRemainingAdultsChildren({
        roomList,
        adultOptions,
        childOptions,
      }),
    [adultOptions, childOptions, roomList],
  );

  const countryList = t('country', { returnObjects: true }).map((c) => ({
    label: `${c.name}`,
    value: `${c.name}`,
  }));

  return {
    t,
    selectedRooms,
    adultOptions: remainingAdults,
    childOptions: remainingChildren,
    familyOptions,
    childCount,
    adultCount,
    selectedAddons,
    countryList,
  };
};

export default useReservationFormContainer;
