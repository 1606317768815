export enum availibility {
  STATUS_AVAILABLE_SUCCESS = 'STATUS_AVAILABLE_SUCCESS',
  STATUS_AVAILABLE_FAILED = 'STATUS_AVAILABLE_FAILED',
}

export enum roomDetails {
  SET_ROOMS = 'SET_ROOMS',
  SET_ROOMS_FAILED = 'SET_ROOMS_FAILED',
}

export enum quote {
  STATUS_AVAILABLE_SUCCESS = 'STATUS_AVAILABLE_SUCCESS',
  STATUS_AVAILABLE_FAILED = 'STATUS_AVAILABLE_FAILED',
  SET_ADDONS = 'SET_ADDONS',
  SET_ADDONS_FAILED = 'SET_ADDONS_FAILED',
  SET_NEW_QUOTED_PRICE_DETAILS = 'SET_NEW_QUOTED_PRICE_DETAILS',
  SET_NEW_QUOTED_PRICE_DETAILS_FAILED = 'SET_NEW_QUOTED_PRICE_DETAILS_FAILED',
}
