/* eslint-disable max-lines */
import SEO from 'client/components/SEO';
import React, { useEffect } from 'react';
const TermsAndConditions = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <SEO title="Terms and Conditions page" image="/assets/images/logoKepler.png" />
      <div className="container">
        <p className="p-5 w-50 m-auto">
          <br />
          <img src="/assets/images/logoKepler.png" alt="KeplerLogo" className="w-100" />
          <br />
        </p>
        <h3 className="font-weight-bold">CONDITIONS GENERALES ET PARTICULIERES DE VENTE</h3>
        <p>
          La soci&eacute;t&eacute; KEPLER est une SAS euros dont le si&egrave;ge social se
          situe 27 rue Joubert, 75009 Paris et inscrite au registre du commerce et des
          soci&eacute;t&eacute;s de Paris sous le n&deg; 804 360 402.
        </p>
        <p>
          La soci&eacute;t&eacute; KEPLER est immatricul&eacute;e au registre des
          op&eacute;rateurs de voyages et de s&eacute;jours sous le n&deg; IM075150004, elle a
          souscrit aupr&egrave;s de April Entreprise EST, 200 Avenue de Colmar, 67100
          Strasbourg, un contrat d&rsquo;assurance (n&deg; GPH 000392) qui couvre sa
          responsabilit&eacute; civile professionnelle.
        </p>
        <p>
          Les pr&eacute;sentes conditions de vente s&rsquo;appliquent &agrave; l&rsquo;ensemble
          produits touristiques propos&eacute;es par KEPLER (voyages, s&eacute;jours, circuits,
          location de voitures etc..) &agrave; ses clients.
        </p>
        <ol>
          <li>- Inscription et passation d&rsquo;une commande :</li>
        </ol>
        <p>
          Les clients peuvent r&eacute;server les prestations souhait&eacute;es sur le site
          KEPLER et de ses partenaires.
        </p>
        <p>
          Les voyages propos&eacute;s sont sous r&eacute;serve de disponibilit&eacute; au
          moment de l&apos;inscription. Une r&eacute;servation n&apos;est d&eacute;finitive
          qu&apos;apr&egrave;s confirmation au client par KEPLER de la disponibilit&eacute; du
          s&eacute;jour.
        </p>
        <ol>
          <li>- Capacit&eacute; :</li>
        </ol>
        <p>
          Le client reconna&icirc;t avoir la capacit&eacute; de contracter aux conditions
          d&eacute;crites, c&apos;est &agrave; dire &ecirc;tre majeur tel que cela est
          d&eacute;fini par le pays d&rsquo;accueil, &ecirc;tre capable juridiquement de
          contracter et ne pas &ecirc;tre sous tutelle ou curatelle. Le client garantit la
          v&eacute;racit&eacute; et l&apos;exactitude des informations fournies par ses soins
          ou tout autre membre de sa famille.
        </p>
        <ol>
          <li>- Prix et paiement :</li>
          <ol>
            <li>Prix :</li>
          </ol>
        </ol>
        <p>
          Tous les prix sont affich&eacute;s en Euros. Ces prix incluent les taxes et les
          surcharges carburant. Ces prix n&rsquo;incluent pas les assurances voyages qui sont
          facultatives. Tarifs particuliers : Les tarifs faisant l&apos;objet de r&egrave;gles
          particuli&egrave;res, tels que les suppl&eacute;ments chambres individuelles et les
          r&eacute;ductions enfants, sont fournis &agrave; titre indicatif. Si une modification
          devait affecter le montant de ces tarifs, le client en serait inform&eacute; avant
          confirmation de sa commande.
        </p>
        <ol>
          <li>R&eacute;vision des prix :</li>
        </ol>
        <p>
          Le prix forfaitaire des voyages et s&eacute;jours varie notamment selon la
          p&eacute;riode d&apos;ex&eacute;cution du voyage, selon le nombre de participants ou
          selon la compagnie a&eacute;rienne. Les prix sont des tarifs contractuels. Aucune
          contestation relative au prix du s&eacute;jour ne pourra donc &ecirc;tre prise en
          compte d&egrave;s lors que la demande de r&eacute;servation est effective.
        </p>
        <p>
          Les prix indiqu&eacute;s ont &eacute;t&eacute; &eacute;tablis en fonction des
          conditions &eacute;conomiques en vigueur au moment o&ugrave; ils ont
          &eacute;t&eacute; communiqu&eacute;s &agrave; Kepler, par ses prestataires, par les
          voyagistes ou les compagnies a&eacute;riennes. Ces prix peuvent &ecirc;tre
          r&eacute;vis&eacute;s, dans les conditions fix&eacute;es par l&rsquo;article L 221-12
          du code du tourisme, tant &agrave; la hausse qu&apos;&agrave; la baisse, afin de
          tenir compte des variations : du co&ucirc;t des transports, li&eacute; notamment au
          carburant, des redevances et taxes aff&eacute;rentes aux prestations offertes telles
          que taxes d&apos;atterrissage et embarquement, d&eacute;barquement dans les
          a&eacute;roports et des taux de change.
        </p>
        <p>
          En cas de variation du montant des taxes et redevances passagers, celle-ci sera
          int&eacute;gralement et imm&eacute;diatement r&eacute;percut&eacute;e dans le prix de
          tous les produits &agrave; compter de sa date d&apos;application, y compris pour les
          clients d&eacute;j&agrave; inscrits et ayant d&eacute;j&agrave; r&eacute;gl&eacute;
          la prestation correspondante. Cependant aucune r&eacute;vision du prix ne pourra
          intervenir moins de 30 jours avant le d&eacute;part.
        </p>
        <p>
          Des taxes locales suppl&eacute;mentaires peuvent &ecirc;tre impos&eacute;es par les
          autorit&eacute;s locales de certains pays. Dans ce cas, ces taxes sont &agrave; la
          charge de l&apos;acheteur et sont &agrave; r&eacute;gler sur place et en monnaie
          locale. Les prix ne comprennent pas les services ant&eacute;rieurs &agrave;
          l&apos;enregistrement &agrave; l&apos;a&eacute;roport et post&eacute;rieurs au retour
          &agrave; l&apos;a&eacute;roport, les repas pris aux escales lors du transit
          &eacute;ventuel entre deux vols, ainsi que les boissons et d&eacute;penses
          d&apos;ordre personnel.
        </p>
        <ol>
          <li>Conditions de r&egrave;glement :</li>
        </ol>
        <p>a/ Tout type de voyage hors week-end r&eacute;serv&eacute; sur internet</p>
        <p>
          Les r&eacute;servations effectu&eacute;es &agrave; plus de 30 jours avant la date de
          d&eacute;part peuvent &ecirc;tre r&eacute;gl&eacute;es par cartes bancaires (VISA,
          MASTERCARD), virements bancaires, et ch&egrave;ques bancaires. Un acompte &eacute;gal
          au minimum &agrave; 50% du montant total du
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>
          voyage devra &ecirc;tre r&eacute;gl&eacute; lors de la r&eacute;servation. A date
          d&rsquo;&eacute;ch&eacute;ance, le solde sera pr&eacute;lev&eacute; automatiquement
          du montant restant d&ucirc; en utilisant l&rsquo;empreinte de la carte bancaire ayant
          servie au r&egrave;glement de l&rsquo;acompte. Dans le cas o&ugrave; plusieurs cartes
          bancaires ont &eacute;t&eacute; utilis&eacute;es, Kepler proc&egrave;dera au
          pr&eacute;l&egrave;vement du solde dans les m&ecirc;mes proportions que
          l&rsquo;acompte pour chaque carte.
        </p>
        <p>
          Pour toute r&eacute;servation effectu&eacute;e &agrave; 30 jours et moins avant la
          date de d&eacute;part, la totalit&eacute; du montant du s&eacute;jour sera
          exig&eacute;e lors de la r&eacute;servation et le r&egrave;glement devra
          s&rsquo;effectuer par carte bancaire.
        </p>
        <p>b/ R&eacute;servations de week-end sur internet</p>
        <p>
          Pour les r&eacute;servations de week-end en ligne, le paiement total du prix doit
          imp&eacute;rativement &ecirc;tre effectu&eacute; par carte bancaire lors de la
          r&eacute;servation.
        </p>
        c/ Pour les achats effectu&eacute;s sur le site vente-priv&eacute;e-voyage.com,
        possibilit&eacute; pour les Membres vente-priv&eacute;e-
        <p>
          <strong>
            voyage.com (VeePee) de de payer en plusieurs fois avec notre partenaire Oney et
            selon les conditions d&rsquo;Oney ci-
          </strong>
        </p>
        <p>
          <strong>dessous</strong>. Paiement de votre commande en 3 fois ou 4 fois par carte
          bancaire &agrave; partir de 100&euro; d&rsquo;achats jusqu&rsquo;&agrave; 6000&euro;
          Notre partenaire Oney Bank propose au Membre une solution de financement, qui permet
          de payer ses achats de 100&euro; &agrave; 6000&euro; en 3 fois ou 4 fois avec sa
          carte bancaire.
        </p>
        <p>
          <br />
          <br />
        </p>
        <h3>Conditions :</h3>
        <p>
          Cette offre est r&eacute;serv&eacute;e aux particuliers effectuant un achat sur le
          site vente-priv&eacute;e-voyage.com (personnes physiques majeures) r&eacute;sidant en
          France et titulaires d&rsquo;une carte bancaire Visa et MasterCard poss&eacute;dant
          une date de validit&eacute; sup&eacute;rieure &agrave; la dur&eacute;e du financement
          choisie.
        </p>
        <p>
          Les cartes &agrave; autorisation syst&eacute;matique notamment de type Electron,
          Maestro, Nickel etc&hellip; ainsi que les e-cards, les cartes Indigo et American
          Express ne sont pas accept&eacute;es.
        </p>
        <h3>Modalit&eacute;s de souscription :</h3>
        <p>
          Apr&egrave;s avoir s&eacute;lectionn&eacute; son voyage, le Membre devra faire sa
          demande de financement en choisissant l&rsquo;onglet
        </p>
        <p>
          &laquo; Paiement en 3 fois &raquo; ou &laquo; Paiement en 4 fois &raquo; sur la page
          de paiement et en cliquant sur le logo 3xCB ou 4xCB. Le Membre est alors
          redirig&eacute; vers la page internet de notre partenaire
          vente-priv&eacute;e-voyage.com affichant le r&eacute;capitulatif
          d&eacute;taill&eacute; de sa commande et la demande de financement
          personnalis&eacute;e, qu&rsquo;il doit ensuite valider.
        </p>
        <p>
          Le Membre saisit ses informations personnelles ou, s&rsquo;il dispose d&rsquo;un
          compte Facily Pay, il s&rsquo;identifie au moyen des identifiants de connexion
          li&eacute;s &agrave; son compte Facily Pay. Il prend connaissance des conditions
          g&eacute;n&eacute;rales de paiement en plusieurs fois auxquelles il souhaite
          souscrire qui lui sont fournies sous format PDF afin qu&rsquo;il puisse les lire, les
          imprimer et les enregistrer avant de les accepter. Le Membre notifie ensuite son
          acceptation &eacute;lectronique par la case &agrave; cocher correspondante.
        </p>
        <p>
          Le Membre reconna&icirc;t que le &laquo; double clic &raquo; associ&eacute; &agrave;
          la case &agrave; cocher sur la prise de connaissance des conditions
          g&eacute;n&eacute;rales valent consentement &agrave; contracter et constituent une
          acceptation irr&eacute;vocable et sans r&eacute;serve des conditions
          g&eacute;n&eacute;rales du produit.
        </p>
        <p>
          Sauf preuve contraire, les donn&eacute;es enregistr&eacute;es par Oney Bank
          constituent la preuve de l&rsquo;ensemble des transactions pass&eacute;es entre le
          Membre et Oney Bank.
        </p>
        <p>
          Si le Membre demande &agrave; b&eacute;n&eacute;ficier d&rsquo;une solution de
          financement propos&eacute;e par Oney Bank, les informations en rapport &agrave; sa
          commande seront transmises &agrave; Oney Bank, qui les utilisera &agrave; des fins
          d&rsquo;&eacute;tude de votre demande pour l&rsquo;octroi, la gestion et le
          recouvrement de cr&eacute;dit.
        </p>
        <p>
          Oney Bank se r&eacute;serve le droit d&rsquo;accepter ou de refuser votre demande de
          financement en 3 ou 4 fois.
        </p>
        <h3>Fonctionnement :</h3>
        <p>
          Le paiement en 3 ou 4 fois par carte bancaire permet de r&eacute;gler la commande
          effectu&eacute;e sur notre site marchand de la fa&ccedil;on suivante :
        </p>
        <ul>
          <li>
            un apport obligatoire, d&eacute;bit&eacute; le jour de la confirmation de
            l&rsquo;exp&eacute;dition de votre commande correspondant &agrave; un tiers de la
            commande, auquel sont ajout&eacute;s des frais correspondants &agrave; 1,45% du
            montant total de la commande pour un 3 fois et &agrave; 2,2% pour un 4 fois (dans
            la limite de 10&euro; maximum pour un paiement en 3 fois et de 20&euro; maximum
            pour un 4 fois) ;
          </li>
          <li>
            deux ou trois mensualit&eacute;s, correspondant chacune &agrave; un tiers ou un
            quart de la commande, pr&eacute;lev&eacute;es &agrave; 30 et 60 jours apr&egrave;s
            pour le 3 fois et &agrave; 30, 60 et 90 jours apr&egrave;s pour le 4 fois.
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>Le paiement en 3 fois</p>
        <p>
          <em>Exemple </em>: Pour un achat de 403,90&euro; effectu&eacute; le 01/03/2017, vous
          payez un apport de 140,49&euro; puis 2 mensualit&eacute;s de 134,64&euro; le
          01/04/2017, le 01/05/2017.
        </p>
        <p>
          Cr&eacute;dit d&apos;une dur&eacute;e de 2 mois au TAEG fixe de 19,27%. Co&ucirc;t du
          financement : 5,86&euro;.
        </p>
        <p>Le paiement en 4 fois</p>
        <p>
          <em>Exemple </em>: Pour un achat de 403,90&euro; effectu&eacute; le 01/03/2017, vous
          payez un apport de 109,87&euro; puis 3 mensualit&eacute;s de 100,97&euro; le
          01/04/2017, le 01/05/2017 et le 01/06/2017.
        </p>
        <p>
          Cr&eacute;dit d&apos;une dur&eacute;e de 3 mois au TAEG fixe de 19,62%. Co&ucirc;t du
          financement : 8,89&euro;.
        </p>
        <p>
          <strong>R&eacute; tractation: </strong>Pour un paiement en 3 ou 4 fois par carte
          bancaire, le Membre dispose d&rsquo;un d&eacute;lai l&eacute;gal de
          r&eacute;tractation de 14 jours calendaires &agrave; compter de son acceptation des
          conditions g&eacute;n&eacute;rales du Tour Op&eacute;rateur KEPLER partenaire de
          vente-priv&eacute;e-voyage.com. Si le Membre renonce &agrave; son cr&eacute;dit, la
          prestation touristique sera tout de m&ecirc;me due au Tour Op&eacute;rateur. Si le
          Membre d&eacute;cide de r&eacute;gler comptant son voyage ou que le voyage a
          d&eacute;j&agrave; &eacute;t&eacute; ex&eacute;cut&eacute;, le Membre devra alors
          r&eacute;gler son dossier de voyage directement au Tour Op&eacute;rateur dans les
          conditions et selon les modalit&eacute;s stipul&eacute;es dans ses conditions
          g&eacute;n&eacute;rales de vente de KEPLER partenaire de
          vente-priv&eacute;e-voyage.com.
        </p>
        <p>
          <strong>
            En cas de modification de la prestation touristique &agrave; l&rsquo;initiative du
            Membre :
          </strong>
          le Membre sera tenu de r&eacute;gler au comptant les &eacute;ventuels frais
          suppl&eacute;mentaires directement au Tour Op&eacute;rateur et/ou &agrave; ses
          prestataires ext&eacute;rieurs et ce conform&eacute;ment aux conditions tarifaires
          pr&eacute;vues par le Tour Op&eacute;rateur KEPLER partenaire de vente-priv&eacute;e-
          voyage.com. Dans cette hypoth&egrave;se, le montant des mensualit&eacute;s restant
          dues continueront &agrave; &ecirc;tre pr&eacute;lev&eacute;es aux dates
          d&rsquo;&eacute;ch&eacute;ance initialement pr&eacute;vues.
        </p>
        <h3>En cas d&rsquo;annulation de tout ou partie du voyage :</h3>
        <p>
          <strong>
            En cas d&rsquo;annulation totale du voyage (l&rsquo;aller et le retour) par le Tour
            Op&eacute;rateur KEPLER :
          </strong>
        </p>
        <p>
          En cas d&rsquo;annulation du voyage dans le respect des conditions
          g&eacute;n&eacute;rales de vente du Tour Op&eacute;rateur KEPLER, le cr&eacute;dit
          sera annul&eacute;. Notre partenaire Oney Bank remboursera au Membre alors
          l&rsquo;int&eacute;gralit&eacute; des sommes per&ccedil;ues conform&eacute;ment aux
          conditions g&eacute;n&eacute;rales de vente et aux modalit&eacute;s de remboursement
          du Tour Op&eacute;rateur KEPLER partenaire de vente-priv&eacute;e-voyage.com.
        </p>
        <h3>
          En cas d&rsquo;annulation partielle (soit l&rsquo;aller, soit le retour) par le Tour
          Op&eacute;rateur :
        </h3>
        <p>
          Dans le cas o&ugrave; seule une partie de la prestation touristique serait
          annul&eacute;e, le cr&eacute;dit sera soit modifi&eacute; au regard du nouveau
          montant du voyage, r&eacute;duisant ainsi la dur&eacute;e de remboursement et/ou le
          montant de la derni&egrave;re mensualit&eacute;, ou annul&eacute;. Les
          int&eacute;r&ecirc;ts &eacute;chus &agrave; la date d&rsquo;annulation partielle
          resteront dus par le Membre.
        </p>
        <p>
          Les nouvelles conditions du cr&eacute;dit seront communiqu&eacute;es au Membre par
          courrier &eacute;lectronique comprenant notamment le montant de ses nouvelles
          mensualit&eacute;s et leurs dates de pr&eacute;l&egrave;vement. Le montant des
          pr&eacute;l&egrave;vements au moyen de la carte bancaire seront
          r&eacute;ajust&eacute;s en cons&eacute;quence.
        </p>
        <p>
          Oney Bank - SA au capital de 50 741 215&euro; - Si&egrave;ge social : 40 avenue de
          Flandre 59170 CROIX - RCS Lille M&eacute;tropole 546 380 197 - n&deg; Orias : 07 023
          261 - <a href="http://www.orias.fr/">www.orias.fr </a>- Correspondance : CS 60006 -
          59 895 Lille Cedex 9 -<a href="http://www.oney.fr/">www.oney.fr</a>
        </p>
        <p>d/ Divers</p>
        <p>
          La communication d&apos;un num&eacute;ro de carte de paiement n&rsquo;est pas
          consid&eacute;r&eacute;e comme lib&eacute;ratoire des sommes dues tant que
          l&apos;accord du centre n&apos;est pas obtenu, &agrave; d&eacute;faut de parfait
          paiement, KEPLER est en droit de consid&eacute;rer que le client a annul&eacute; sa
          r&eacute;servation.
        </p>
        <p>
          Attention : Afin de minimiser les cons&eacute;quences des fraudes aux cartes
          bancaires, KEPLER se r&eacute;serve le droit d&apos;effectuer des
          v&eacute;rifications et de demander au client de lui communiquer par tous moyens la
          preuve de son adresse, une copie recto verso de la carte bancaire servant au
          paiement, ainsi que celle d&apos;une pi&egrave;ce d&apos;identit&eacute; du porteur
          de la carte bancaire et celle des passagers.
        </p>
        <p>
          <br />
          <br />
          <br />
        </p>
        <ol>
          <li>- Formalit&eacute;s :</li>
        </ol>
        <p>
          Les informations communiqu&eacute;es dans les pr&eacute;sentes conditions
          g&eacute;n&eacute;rales de vente ne s&rsquo;appliquent qu&rsquo;aux seuls
          ressortissants nationaux. Il appartient &agrave; toute autre personne d&rsquo;une
          autre nationalit&eacute; de s&rsquo;adresser &agrave; l&rsquo;ambassade ou au
          consulat comp&eacute;tent pour conna&icirc;tre les formalit&eacute;s administratives
          et sanitaires &agrave; respecter.
        </p>
        <p>
          L&rsquo;accomplissement des formalit&eacute;s administratives et sanitaires et les
          &eacute;ventuels frais relatifs &agrave; ces formalit&eacute;s sont la charge
          exclusive des clients.
        </p>
        <p>
          Les clients doivent consulter avant leur date de d&eacute;part les sites internet
          suivants pour v&eacute;rifier les formalit&eacute;s administratives et sanitaires
          &agrave; accomplir pour chacun des pays o&ugrave; ils se rendent, y compris en cas de
          simple escale ou de transit : www.diplomatie.gouv.fr, www.action-visas.com ou
          www.travelsante.com (liste non exhaustive).
        </p>
        <p>
          Toutes les personnes qui voyagent, quel que soit leur &acirc;ge (b&eacute;b&eacute;s,
          enfants mineurs, etc&hellip;) doivent d&eacute;tenir une pi&egrave;ce
          d&rsquo;identit&eacute; individuelle qui porte leur nom (carte
          d&rsquo;identit&eacute; ou passeport selon les pays de destination). Aucun autre
          document tel que les livrets de famille ou les cartes de s&eacute;jour, ne permet de
          franchir les fronti&egrave;res. L&apos;obtention d&apos;un visa peut &ecirc;tre
          n&eacute;cessaire pour certaines destinations.
        </p>
        <p>
          Les clients doivent s&rsquo;assurer suffisamment longtemps avant la date de
          d&eacute;part que les noms et pr&eacute;noms qui figurent sur les documents de voyage
          (billets, bons d&rsquo;&eacute;change, r&eacute;servations, etc&hellip;)
          correspondent exactement &agrave; ceux qui figurent sur leur pi&egrave;ce
          d&rsquo;identit&eacute; et sur leur visa.
        </p>
        <p>
          Certains pays exigent une dur&eacute;e de validit&eacute; des passeports de plus de 6
          mois apr&egrave;s la date de retour ou encore que les clients emportent avec eux
          suffisamment d&rsquo;argent et qu&rsquo;ils soient en mesure de d&eacute;montrer
          qu&rsquo;ils ont bien un billet de retour.
        </p>
        <p>
          Tous les frais relatifs &agrave; l&rsquo;obtention de pi&egrave;ces
          d&rsquo;identit&eacute;, de visas et de vaccins sont &agrave; la charge du client.
        </p>
        <p>
          Si le client se voit refuser l&rsquo;embarquement ou le d&eacute;barquement en raison
          de l&rsquo;inobservation des formalit&eacute;s administratives et/ou sanitaires
          aucune somme ne pourra lui &ecirc;tre rembours&eacute;e et tous les &eacute;ventuels
          frais suppl&eacute;mentaires (achat d&rsquo;un billet d&rsquo;avion, amendes
          etc&hellip;) resteront &agrave; sa charge.
        </p>
        <ol>
          <li>- R&eacute;clamations :</li>
        </ol>
        <p>
          Toute r&eacute;clamation doit imp&eacute;rativement &ecirc;tre adress&eacute;e
          &agrave; KEPLER par lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception
          ou par email &agrave; l&rsquo;adresse
          <a href="mailto:qualite@keplertravel.com">qualite @keplertravel.com, </a>
          accompagn&eacute;e de pi&egrave;ces justificatives.
        </p>
        <p>
          En cas de probl&egrave;mes rencontr&eacute;s au cours des transports assur&eacute;s
          par les compagnies a&eacute;riennes, (retards, etc&hellip;) KEPLER recommande
          &agrave; ses clients de conserver les cartes d&rsquo;embarquement et/ou
          &eacute;tiquettes bagages, dont les originaux devront &ecirc;tre transmis &agrave; la
          compagnie a&eacute;rienne simultan&eacute;ment &agrave; l&rsquo;envoi de la
          r&eacute;clamation, et ce, pour acc&eacute;l&eacute;rer le traitement de la
          r&eacute;clamation par la compagnie a&eacute;rienne.
        </p>
        <p>
          A cet &eacute;gard, dans le cas de retards a&eacute;riens engendrant des frais
          suppl&eacute;mentaires (h&ocirc;tels, repas, boissons, etc&hellip;) la demande de
          d&eacute;dommagement accompagn&eacute;e des justificatifs originaux doit &ecirc;tre
          adress&eacute;e &agrave; la compagnie a&eacute;rienne, au plus vite, en copie KEPLER,
          si possible dans les 30 jours suivant le retour des clients.
        </p>
        <p>
          Les prestations non utilis&eacute;es en raison de cette situation (premi&egrave;re
          nuit, transferts) ne sont pas remboursables.
        </p>
        <ol>
          <li>- Assurances :</li>
        </ol>
        <p>
          Aucune assurance n&apos;est comprise dans les prix propos&eacute;s par KEPLER.
          D&egrave;s lors, KEPLER recommande vivement de souscrire le contrat d&apos;assurance
          propos&eacute; par PRESENCE ASSISTANCE TOURISME couvrant les cons&eacute;quences de
          l&rsquo;annulation, l&apos;assistance, certains risques particuliers notamment les
          frais de rapatriement en cas d&apos;accident ou de maladie ou Covid et la garantie de
          vos bagages.
        </p>
        <ol>
          <li>- Annulation/Modification &agrave; l&rsquo;initiative du client :</li>
        </ol>
        <p>
          Toute demande de modification ou d&apos;annulation doit &ecirc;tre communiqu&eacute;e
          &agrave; KEPLER par email :
          <a href="mailto:reservations@keplertravel.com"> reservations @keplertravel.com, </a>
          ou par lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception &agrave;
          l&apos;adresse suivante. KEPLER - Service r&eacute;servation - 27, rue Joubert,
          75009, Paris
        </p>
        <p>
          La date de r&eacute;ception du courrier recommand&eacute; ou du mail sera la date
          retenue pour appliquer le bar&egrave;me de frais d&apos;annulation ou de
          modification.
        </p>
        <p>
          Toute modification de date ou de destination, d&eacute;faut d&apos;enregistrement,
          absence de pr&eacute;sentation le jour du d&eacute;part, changement de participant
          ainsi que l&rsquo;impossibilit&eacute; d&rsquo;effectuer le d&eacute;part &agrave; la
          suite de la non-pr&eacute;sentation de documents de voyages (passeport, visa,
          certificat de vaccination, etc.) constituent une annulation.
        </p>
        <p>
          Une erreur sur l&rsquo;orthographe du nom d&rsquo;un participant constitue une
          modification.
        </p>
        <p>
          <br />
          <br />
          <br />
        </p>
        <ol>
          <li>: Conditions d&apos;annulation &amp; de modification moyen et long courrier</li>
        </ol>
        <p>
          Toute annulation ou modification de dossier, &agrave; l&apos;initiative du client,
          avant le d&eacute;part entra&icirc;nera la perception des frais suivants par personne
          :
        </p>
        <h3>CONDITIONS D&apos;ANNULATION &amp; DE MODIFICATION MOYEN ET LONG COURRIER</h3>
        <p>
          Les frais mentionn&eacute;s ci-dessous sont appliqu&eacute;s par personne, hors
          assurance et sur le montant TTC du voyage.
        </p>
        <ul>
          <li>
            S&eacute;jour non modifiable (ou &eacute;quivalent &agrave; une annulation si
            besoin de modification).
          </li>
          <li>
            Frais d&rsquo;annulation &agrave; plus de 30 jours avant la date de d&eacute;part :
            50 % du montant TTC
          </li>
          <li>
            Frais d&rsquo;annulation &agrave; 30 jours et moins avant la date de d&eacute;part
            : 100 % du montant TTC
          </li>
        </ul>
        <p>Les assurances souscrites ne sont pas remboursables.</p>
        <p>
          En outre, il est pr&eacute;cis&eacute; que si le tour op&eacute;rateur qui a
          organis&eacute; le voyage r&eacute;serv&eacute; par l&rsquo;interm&eacute;diaire de
          KEPLER applique des frais d&apos;annulation ou de modification sup&eacute;rieurs au
          bar&egrave;me qui figure ci-dessus, ce sont les frais d&apos;annulation ou de
          modification de ce tour op&eacute;rateur qui seront applicables.
        </p>
        <p>
          En cas de force majeure, toute annulation ou modification de dossier par le client ou
          par Kepler entrainera la perception de frais de gestion de 25&euro; par personne.
        </p>
        <p>7.2 :</p>
        <p>
          Le client ne pourra pr&eacute;tendre &agrave; aucun remboursement ni au versement
          d&rsquo;aucune indemnit&eacute; s&rsquo;il interrompt son s&eacute;jour et/ou ne
          consomme pas les prestations pr&eacute;vues et/ou modifie durant son s&eacute;jour
          les prestations initialement pr&eacute;vues.
        </p>
        <ol>
          <li>- H&eacute;bergement et prestations sur place :</li>
        </ol>
        <p>
          Il est de r&egrave;gle en h&ocirc;tellerie internationale de disposer de sa chambre
          &agrave; partir de 14 heures quelle que soit l&apos;heure d&apos;arriv&eacute;e du
          vol et de la lib&eacute;rer avant 12 heures quel que soit l&apos;horaire du vol
          retour. En aucun cas, il ne pourra &ecirc;tre d&eacute;rog&eacute; &agrave; cette
          r&egrave;gle. Dans le cadre du Covid-19, certains h&ocirc;tels ont d&ucirc; adapter
          leurs horaires de check-in/check-out afin d&rsquo;appliquer les mesures sanitaires
          n&eacute;cessaires.
        </p>
        <ol>
          <li>Classification de l&apos;H&ocirc;tel :</li>
        </ol>
        <p>
          Le nombre d&apos;&eacute;toiles attribu&eacute;es &agrave;
          l&apos;&eacute;tablissement h&ocirc;telier figurant dans les descriptifs correspond
          &agrave; une classification &eacute;tablie en r&eacute;f&eacute;rence &agrave; des
          normes locales du pays d&apos;accueil et qui peuvent donc diff&eacute;rer des normes
          fran&ccedil;aises et europ&eacute;ennes. En outre les m&eacute;thodes de
          classification des h&ocirc;tels d&rsquo;un pays &agrave; l&rsquo;autre peuvent
          &eacute;galement varier. KEPLER ne saurait en aucun cas &ecirc;tre tenu responsable
          de toute interpr&eacute;tation sur une &eacute;ventuelle classification locale.
        </p>
        <ol>
          <li>
            Modification des h&ocirc;tels, circuits, autotours, d&eacute;logement h&ocirc;tels
            :
          </li>
        </ol>
        <p>
          Il peut arriver que KEPLER soit amen&eacute;, pour des raisons ind&eacute;pendantes
          de sa volont&eacute;, &agrave; changer les h&ocirc;tels ou les bateaux ou les
          compagnies a&eacute;riennes mentionn&eacute;s sans que cette mesure constitue une
          modification d&apos;un &eacute;l&eacute;ment essentiel du voyage. Dans la mesure du
          possible, les clients seront avis&eacute;s avant leur d&eacute;part et KEPLER
          fournira un service dans la m&ecirc;me cat&eacute;gorie que celui propos&eacute;
          initialement. De m&ecirc;me, dans certains pays, le sens des circuits et des
          autotours pourra &ecirc;tre modifi&eacute; mais toutes les visites et &eacute;tapes
          pr&eacute;vues seront respect&eacute;es. Les f&ecirc;tes tant civiles que
          religieuses, les gr&egrave;ves et les manifestations dans les pays visit&eacute;s
          sont susceptibles d&apos;entra&icirc;ner des modifications des visites ou excursions.
        </p>
        <ol>
          <li>Types de chambres :</li>
        </ol>
        <p>
          Les chambres ou cabines individuelles comprennent un lit pour une personne.
          Pr&eacute;vues en nombre limit&eacute;, elles font souvent l&apos;objet d&apos;un
          suppl&eacute;ment. Les chambres doubles sont pr&eacute;vues, soit avec deux lits,
          soit, tr&egrave;s rarement, avec un lit double. Les chambres triples et quadruples
          sont souvent des chambres doubles &eacute;quip&eacute;es de lits d&apos;appoint.
        </p>
        <ol>
          <li>Repas :</li>
        </ol>
        <p>
          Le nombre des repas d&eacute;pend du nombre de nuits pass&eacute;es &agrave;
          l&apos;h&ocirc;tel.
        </p>
        <ul>
          <li>
            La demi-pension et la pension compl&egrave;te d&eacute;butent avec le d&icirc;ner
            de la premi&egrave;re nuit et prend fin avec le petit d&eacute;jeuner du dernier
            jour. La demi-pension comprend : un petit-d&eacute;jeuner et un repas par jour
            (midi ou soir selon les h&ocirc;tels). Que ce soit dans le cadre de la pension
            compl&egrave;te ou de la demi-pension, les boissons ne sont pas comprises, sauf
            exception d&ucirc;ment mentionn&eacute;e dans le descriptif. Attention : selon les
            pays, les prestataires ne disposent pas toujours d&apos;eau courante potable. Les
            frais d&apos;achats de bouteilles d&apos;eau potable sont alors &agrave; la charge
            du client.
          </li>
        </ul>
        <p>
          <br />
          <br />
        </p>
        <ol>
          <li>Activit&eacute;s propos&eacute;es lors du s&eacute;jour :</li>
        </ol>
        <p>
          Certaines activit&eacute;s propos&eacute;es peuvent pr&eacute;senter des risques
          notamment pour les jeunes enfants. La responsabilit&eacute; de KEPLER ne saurait
          &ecirc;tre engag&eacute;e en cas d&apos;incident ou d&apos;accident imputable
          &agrave; un manquement de vigilance de la part du client. Il peut advenir que
          certaines activit&eacute;s indiqu&eacute;es dans le descriptif soient
          supprim&eacute;es par le prestataire local. La responsabilit&eacute; de KEPLER ne
          saurait &ecirc;tre engag&eacute;e si ces activit&eacute;s sont supprim&eacute;es en
          cas de force majeure, du fait impr&eacute;visible et insurmontable d&apos;un tiers
          &eacute;tranger &agrave; la prestation ou du fait du client.
        </p>
        <p>
          En p&eacute;riode de moindre occupation, l&rsquo;h&ocirc;telier se r&eacute;serve le
          droit d&rsquo;effectuer des travaux de r&eacute;duire et/ou de r&eacute;organiser les
          services propos&eacute;s dans son &eacute;tablissement (acc&egrave;s aux restaurants,
          activit&eacute;s sportives, piscine, animation, etc&hellip;), de fermer une ou
          plusieurs sections du complexe h&ocirc;telier.
        </p>
        <ol>
          <li>Locations de voiture :</li>
        </ol>
        <p>
          Le temps de location correspond &agrave; 24 heures multipli&eacute; par le nombre de
          nuits effectu&eacute;es pendant le s&eacute;jour. A titre d&rsquo;exemple, un
          autotour de 8 jours/7 nuits, correspondra &agrave; une location de v&eacute;hicule
          valable 7x24 heures, &agrave; savoir du jour 1 (10 heures) au jour 8 (10 heures). Si
          ce d&eacute;lai est d&eacute;pass&eacute;, le loueur facturera en jour
          suppl&eacute;mentaire (&agrave; r&eacute;gler sur place par le client). Il arrive
          fr&eacute;quemment qu&rsquo;un suppl&eacute;ment de prix soit pr&eacute;vu quand la
          voiture est retir&eacute;e le soir. Les conditions des loueurs &eacute;tant
          particuli&egrave;res, il convient de consulter KEPLER avant la r&eacute;servation
          pour conna&icirc;tre les sp&eacute;cificit&eacute;s de chaque destination et pouvoir
          r&eacute;server en toute connaissance de cause.
        </p>
        <ol>
          <li>Perte et vol :</li>
        </ol>
        <p>
          KEPLER conseille vivement &agrave; ses clients de n&rsquo;emporter aucun objet de
          valeur pour limiter les risques en cas de perte ou de vol. Tous les effets personnels
          et objets de valeur (argent, bijoux, cartes de cr&eacute;dit, appareils
          &eacute;lectroniques etc) restent sous la responsabilit&eacute; des clients. Ces
          objets de valeur et effets personnels doivent &ecirc;tre plac&eacute;s dans les
          coffres forts qui se trouvent dans les chambres d&rsquo;h&ocirc;tels ou doivent
          &ecirc;tre confi&eacute;s &agrave; la r&eacute;ception de l&rsquo;h&ocirc;tel. KEPLER
          ne peut &ecirc;tre tenu pour responsable du vol ou de la perte des objets de valeur
          et/ou des effets personnels.
        </p>
        <ol>
          <li>- Responsabilit&eacute; de KEPLER :</li>
          <ol>
            <li>Force majeure</li>
          </ol>
        </ol>
        <p>
          KEPLER ne pourra &ecirc;tre tenu pour responsable des cas fortuits, des cas de force
          majeure (notamment les gr&egrave;ves, les intemp&eacute;ries, les catastrophes
          naturelles ou sanitaires, l&apos;interruption des moyens de communications&hellip;)
          du fait des tiers, ou de la faute du client (pr&eacute;sentation apr&egrave;s
          l&apos;heure de convocation, non-respect des formalit&eacute;s administratives,
          douani&egrave;res, de sant&eacute;, non pr&eacute;sentation &agrave;
          l&rsquo;embarquement&hellip;).
        </p>
        <ol>
          <li>Al&eacute;as</li>
        </ol>
        <p>
          Un client ne pourra en aucune mani&egrave;re engager la responsabilit&eacute; de
          KEPLER dans l&apos;hypoth&egrave;se de retards, de modifications
          d&apos;itin&eacute;raire de vol ou de changements d&apos;a&eacute;roport, notamment
          caus&eacute;s, en p&eacute;riode de gros trafic, par des gr&egrave;ves des personnels
          des compagnies a&eacute;riennes ou des a&eacute;roports, par de nombreuses rotations
          des appareils et les imp&eacute;ratifs de s&eacute;curit&eacute;, de m&ecirc;me que
          tout retard d&ucirc; aux conditions atmosph&eacute;riques. Dans ces
          hypoth&egrave;ses, KEPLER ne sera tenue &agrave; aucune indemnisation &agrave;
          quelque titre que ce soit.
        </p>
        <p>
          De mani&egrave;re g&eacute;n&eacute;rale KEPLER conseille vivement &agrave; ses
          clients de pr&eacute;voir un temps de connexion minimum de 3 heures en cas de
          pr&eacute; ou post acheminement par leurs propres moyens pour l&rsquo;a&eacute;roport
          et de r&eacute;server des titres de transport modifiables, &eacute;changeables ou
          remboursables, afin de limiter les &eacute;ventuels frais &agrave; leur charge.
        </p>
        <ol>
          <li>- Packages/forfaits touristiques :</li>
          <ol>
            <li>Dur&eacute;e des Voyages :</li>
          </ol>
        </ol>
        <p>
          Les dur&eacute;es de voyages et les prix sont calcul&eacute;s en fonction d&apos;un
          nombre de nuit&eacute;es, et non de journ&eacute;es enti&egrave;res.
        </p>
        <p>
          La dur&eacute;e ou la nature des prestations terrestres peut &ecirc;tre
          modifi&eacute;e en raison d&apos;al&eacute;as dus au transport ou &agrave; des cas de
          force majeure, sans que le client puisse pr&eacute;tendre &agrave; remboursement ou
          indemnisation. Tout voyage interrompu ou abr&eacute;g&eacute; du fait du client ne
          donnera lieu &agrave; aucun remboursement.
        </p>
        <p>
          Le jour du d&eacute;part &agrave; compter de l&apos;enregistrement et le jour du
          retour jusqu&apos;&agrave; l&apos;heure d&apos;arriv&eacute;e sont inclus dans la
          dur&eacute;e des voyages.
        </p>
        <p>
          L&apos;arriv&eacute;e le 1er jour peut &ecirc;tre tr&egrave;s tardive, voire dans la
          nuit, de m&ecirc;me au retour, le transport peut avoir lieu t&ocirc;t le matin,
          entra&icirc;nant un d&eacute;part du lieu de s&eacute;jour dans le courant de la
          nuit. Si dans ces cas de figure, la premi&egrave;re et/ou la derni&egrave;re nuit se
          trouvaient &eacute;court&eacute;es, aucun remboursement ne pourrait avoir lieu.
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>
          Tout repas suppl&eacute;mentaire devant &ecirc;tre fourni sera &agrave; payer sur
          place. Tout repas non pris ne sera pas rembours&eacute;.
        </p>
        <ol>
          <li>Dispositions applicables &agrave; certains circuits :</li>
        </ol>
        <p>
          L&apos;ex&eacute;cution de certains circuits est soumise &agrave; la r&eacute;union
          d&apos;un nombre minimum de participants pr&eacute;cis&eacute;s dans le tableau des
          prix du circuit concern&eacute;. La confirmation ou l&apos;annulation d&rsquo;un
          circuit intervient au plus tard 15 jours avant la date de d&eacute;part. Le client ne
          pourra pr&eacute;tendre &agrave; aucune indemnit&eacute; si l&apos;annulation du
          circuit intervient en raison d&rsquo;un nombre insuffisant de participants au plus
          tard &agrave; 15 jours du d&eacute;part.
        </p>
        <ol>
          <li>- Conditions particuli&egrave;res li&eacute;es au transport a&eacute;rien :</li>
          <ol>
            <li>Conditions g&eacute;n&eacute;rales du transporteur a&eacute;rien :</li>
          </ol>
        </ol>
        <p>
          KEPLER affr&egrave;te ou co-affr&egrave;te des avions de compagnies a&eacute;riennes
          pour &ecirc;tre en mesure de proposer des voyages, s&eacute;jours et circuits
          &agrave; ses clients. Si la responsabilit&eacute; de KEPLER est mise en cause dans le
          cadre d&rsquo;un transport a&eacute;rien, les conditions g&eacute;n&eacute;rales du
          transporteur a&eacute;rien, qui peuvent notamment comporter des clauses
          d&rsquo;exclusion et/ou de limitation de responsabilit&eacute; seront opposables au
          client.
        </p>
        <ol>
          <li>A&eacute;roport ou gare de d&eacute;part et d&rsquo;arriv&eacute;e :</li>
        </ol>
        <p>
          Le client est inform&eacute; que des changements d&rsquo;a&eacute;roport ou de gare
          &agrave; l&rsquo;aller et au retour peuvent se produire, notamment &agrave; Paris
          (entre l&rsquo;a&eacute;roport d&rsquo;Orly et de Roissy Charles de Gaulle), et que
          cela ne peut donner lieu &agrave; aucun d&eacute;dommagement de quelque nature que ce
          soit. De m&ecirc;me, le d&eacute;part ou l&rsquo;arriv&eacute;e peut
          s&rsquo;effectuer depuis la gare ferroviaire de la ville de d&eacute;part ou
          d&rsquo;arriv&eacute;e.
        </p>
        <ol>
          <li>Attribution des si&egrave;ges :</li>
        </ol>
        <p>
          KEPLER n&rsquo;est pas en mesure de garantir que les clients qui voyagent ensemble
          seront assis dans l&rsquo;avion les uns &agrave; c&ocirc;t&eacute; des autres.
          D&egrave;s lors, aucune indemnit&eacute; ne pourra &ecirc;tre vers&eacute;e si les
          clients sont s&eacute;par&eacute;s au cours de leur trajet.
        </p>
        <ol>
          <li>Non-pr&eacute;sentation au d&eacute;part :</li>
        </ol>
        <p>
          En cas de non-pr&eacute;sentation au d&eacute;part KEPLER se r&eacute;serve le droit
          d&apos;annuler les autres prestations ainsi que le billet de retour sauf si une
          confirmation lui est adress&eacute;e au plus tard dans les 24 heures suivant le vol
          aller.
        </p>
        <p>
          Tout voyage interrompu, abr&eacute;g&eacute; ou toute prestation non consomm&eacute;e
          du fait de l&apos;acheteur ne donnera droit &agrave; aucun remboursement. Si le
          client a souscrit une assurance optionnelle couvrant notamment l&apos;interruption de
          s&eacute;jour, il devra se conformer aux modalit&eacute;s d&apos;annulation figurant
          dans les conditions du contrat d&apos;assurance.
        </p>
        <p>
          Sur les vols r&eacute;guliers, tous les tron&ccedil;ons doivent &ecirc;tre
          utilis&eacute;s faute de quoi la compagnie a&eacute;rienne se r&eacute;serve le droit
          de r&eacute;ajuster le tarif ou d&apos;annuler les places.
        </p>
        <ol>
          <li>Prestations sur place :</li>
        </ol>
        <p>
          Certains tarifs sont sujets &agrave; pr&eacute;sentation de prestations terrestres
          obligatoires lors de l&apos;enregistrement. KEPLER d&eacute;cline toute
          responsabilit&eacute; si un passager se voyait refuser l&apos;embarquement pour
          non-respect de cette disposition.
        </p>
        <ol>
          <li>Animaux :</li>
        </ol>
        <p>
          Chaque compagnie a sa propre politique en mati&egrave;re d&apos;animaux. En principe,
          ils sont interdits sur les vols charters. Sous r&eacute;serve de formalit&eacute;s en
          r&egrave;gle et sur accord pr&eacute;alable de la compagnie a&eacute;rienne, les
          animaux familiers peuvent &ecirc;tre admis en soute sur certains vols
          r&eacute;guliers. La demande doit &ecirc;tre faite directement aupr&egrave;s de la
          compagnie sur laquelle le client a pr&eacute;vu de voyager.
        </p>
        <ol>
          <li>Repas sp&eacute;ciaux :</li>
        </ol>
        <p>
          G&eacute;n&eacute;ralement, les vols charters ne proposent pas de repas
          sp&eacute;ciaux. En revanche, sur les vols r&eacute;guliers, KEPLER peut interroger
          les compagnies a&eacute;riennes qui r&eacute;pondent g&eacute;n&eacute;ralement dans
          un d&eacute;lai de 48 heures sur la possibilit&eacute; de servir un repas
          sp&eacute;cial. KEPLER ne peut &ecirc;tre tenu pour responsable de
          l&rsquo;impossibilit&eacute; de servir un repas sp&eacute;cial.
        </p>
        <ol>
          <li>Bagages :</li>
        </ol>
        <p>
          Chaque compagnie a&eacute;rienne dispose de ses propres r&egrave;gles de transport de
          bagages, qui peuvent changer sans pr&eacute;avis. KEPLER communiquera au client, pour
          chaque compagnie a&eacute;rienne, le poids, et le cas &eacute;ch&eacute;ant le nombre
          de bagages maximum autoris&eacute;s sans suppl&eacute;ment de prix. KEPLER recommande
          de ne pas d&eacute;passer le poids de bagage autoris&eacute; et
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>
          de pr&eacute;voir un seul bagage par personne. Les franchises
          s&rsquo;appr&eacute;cient g&eacute;n&eacute;ralement individuellement. Tout frais
          pour suppl&eacute;ment de bagages impos&eacute; par le transporteur sera &agrave; la
          charge du client.
        </p>
        <p>
          En cas de perte de bagage ou de dommage caus&eacute; &agrave; un bagage lors du
          transport a&eacute;rien, le client doit signaler imm&eacute;diatement, &agrave; son
          arriv&eacute;e dans l&rsquo;a&eacute;roport, cette perte ou ce dommage au
          transporteur a&eacute;rien. Le client doit conserver une copie de sa
          d&eacute;claration de perte ou d&rsquo;avarie, sa carte d&rsquo;embarquement, des
          &eacute;tiquettes bagage et de tous documents utiles. Ces documents devront
          &ecirc;tre envoy&eacute;s, le cas &eacute;ch&eacute;ant, &agrave; la compagnie
          d&rsquo;assurance aupr&egrave;s de laquelle le client aura souscrit une assurance
          voyage.
        </p>
        <p>
          En toute hypoth&egrave;se les pertes, vols ou d&eacute;g&acirc;ts caus&eacute;s aux
          biens suivants ne sont pas indemnisables : bijoux, argent, perles, pierres dures,
          montres, objets d&rsquo;art, objets pr&eacute;cieux ou de valeurs, appareils
          &eacute;lectroniques (appareils photo, cam&eacute;ras, t&eacute;l&eacute;phones,
          tablettes, etc&hellip;) passeports, pi&egrave;ces d&rsquo;identit&eacute;.
        </p>
        <p>
          Il est fortement recommand&eacute; de conserver sur soi pendant toute la dur&eacute;e
          du voyage les biens indispensables tels que les m&eacute;dicaments ou les lunettes de
          vue.
        </p>
        <ol>
          <li>- Photos, illustrations, vid&eacute;os :</li>
          <ol>
            <li>Photos, illustrations et vid&eacute;o sur le site internet kepler.com :</li>
          </ol>
        </ol>
        <p>
          KEPLER s&apos;efforce d&apos;illustrer ses propositions de photos ou illustrations
          donnant un aper&ccedil;u r&eacute;aliste des services propos&eacute;s. Il est
          toutefois pr&eacute;cis&eacute; que les photos et illustrations figurant dans le
          descriptif sont simplement illustratives des services. Ces photos n&rsquo;ont pas de
          caract&egrave;re contractuel
        </p>
        <p>
          Les clients qui s&eacute;journent &agrave; l&rsquo;h&ocirc;tel, sont susceptibles
          d&rsquo;&ecirc;tre film&eacute;s et/ou photographi&eacute;s pendant leur
          s&eacute;jour au cours de leurs activit&eacute;s et/ou de leurs loisirs (sports, jeux
          etc&hellip;). En participant &agrave; ces activit&eacute;s et/ou &agrave; ces
          loisirs, les clients autorisent KEPLER &agrave; diffuser et/ou &agrave; reproduire
          leur image dans le monde entier et pour la dur&eacute;e du droit &agrave;
          l&rsquo;image. KEPLER s&rsquo;engage &agrave; limiter la diffusion et/ou la
          reproduction de ces images aux r&eacute;seaux sociaux et au site internet kepler.com
          Les clients ont cependant la possibilit&eacute; s&rsquo;ils le souhaitent de demander
          durant leur s&eacute;jour aux repr&eacute;sentants ou &agrave; leur retour, que leur
          image ne soit ni diffus&eacute;e ni reproduite.
        </p>
        <ol>
          <li>- Dispositions diverses :</li>
        </ol>
        <p>
          Dans le cas o&ugrave; l&apos;une des dispositions des conditions de vente serait
          d&eacute;clar&eacute;e nulle ou sans effet, cette disposition serait
          r&eacute;put&eacute;e non &eacute;crite, sans que cela n&apos;affecte la
          validit&eacute; des autres dispositions sauf si la disposition d&eacute;clar&eacute;e
          nulle ou sans effet &eacute;tait essentielle et d&eacute;terminante. Dans ce dernier
          cas, les parties n&eacute;gocieront son remplacement par une disposition ayant un
          effet &eacute;conomique &eacute;quivalent.
        </p>
        <ol>
          <li>- Droit applicable :</li>
        </ol>
        <p>
          Les conditions de vente sont soumises au droit fran&ccedil;ais. Tout litige relatif
          &agrave; leur interpr&eacute;tation et/ou &agrave; leur ex&eacute;cution
          rel&egrave;ve des tribunaux de Paris.
        </p>
        <ol>
          <li>- CNIL :</li>
        </ol>
        <ol>
          <li>Informatique et libert&eacute;s :</li>
        </ol>
        <p>
          Les donn&eacute;es &agrave; caract&egrave;re personnel recueillies sur le site KEPLER
          sont collect&eacute;es et trait&eacute;es conform&eacute;ment aux dispositions de la
          loi n&deg; 78-17 modifi&eacute;e du 6 janvier 1978 relative &agrave;
          l&rsquo;informatique, aux fichiers et aux libert&eacute;s (dite &laquo; Loi
          Informatique et Libert&eacute;s &raquo;).
        </p>
        <p>
          Vous disposez d&rsquo;un droit d&rsquo;acc&egrave;s, d&rsquo;opposition, de
          modification, de rectification et de suppression des donn&eacute;es qui vous
          concernent en &eacute;crivant par lettre recommand&eacute;e avec accus&eacute; de
          r&eacute;ception &agrave; KEPLER 27 rue Joubert, 75009 Paris.
        </p>
        <ol>
          <li>Cookies KEPLER :</li>
        </ol>
        <p>
          Lors de la consultation du site KEPLER, des cookies sont d&eacute;pos&eacute;s sur
          votre ordinateur, votre mobile ou votre tablette. Ces cookies nous permettent
          d&rsquo;enregistrer vos pr&eacute;f&eacute;rences et de personnaliser les offres que
          nous vous proposons. Vous pouvez d&eacute;sactiver les cookies en suivant les
          instructions comme suit :
        </p>
        <p>
          <em>1/ si vous utilisez le navigateur Internet Explorer</em>
        </p>
        <p>
          Dans Internet Explorer, cliquez sur le bouton Outils, puis sur Options Internet. Sous
          l&apos;onglet G&eacute;n&eacute;ral, sous Historique de navigation, cliquez sur
          Param&egrave;tres. Cliquez sur le bouton Afficher les fichiers.
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>
          Cliquez sur l&apos;en-t&ecirc;te de colonne Nom pour trier tous les fichiers dans
          l&apos;ordre alphab&eacute;tique, puis parcourez la liste jusqu&apos;&agrave; ce que
          vous voyiez des fichiers commen&ccedil;ant par le pr&eacute;fixe &laquo; Cookie
          &raquo;. (Tous les cookies poss&egrave;dent ce pr&eacute;fixe et contiennent
          habituellement le nom du site Web qui a cr&eacute;&eacute; le cookie).
        </p>
        <p>
          S&eacute;lectionnez-le ou les cookies comprenant le nom &laquo; KEPLER &raquo; et
          supprimez-les.
        </p>
        <p>
          Fermez la fen&ecirc;tre qui contient la liste des fichiers, puis cliquez deux fois
          sur OK pour retourner dans Internet Explorer.
        </p>
        <p>
          <em>2/ si vous utilisez le navigateur Firefox</em>
        </p>
        <p>
          Allez dans l&apos;onglet Outils du navigateur puis s&eacute;lectionnez le menu
          Options
        </p>
        <p>
          Dans la fen&ecirc;tre qui s&apos;affiche, choisissez Vie priv&eacute;e et cliquez sur
          Affichez les cookies Rep&eacute;rez les fichiers qui contiennent le nom &laquo;
          KEPLER &raquo; S&eacute;lectionnez-les et supprimez-les.{' '}
          <em>3/ si vous utilisez le navigateur Safari</em>
        </p>
        <p>
          Dans votre navigateur, choisissez le menu &Eacute;dition &gt;
          Pr&eacute;f&eacute;rences. Cliquez sur S&eacute;curit&eacute;.
        </p>
        <p>Cliquez sur Afficher les cookies.</p>
        <p>
          S&eacute;lectionnez les cookies qui contiennent le nom &laquo; KEPLER &raquo; et
          cliquez sur Effacer ou sur Tout effacer. Apr&egrave;s avoir supprim&eacute; les
          cookies, cliquez sur Termin&eacute;.
        </p>
        <p>
          <em>4/ si vous utilisez le navigateur Google Chrome</em>
        </p>
        <p>Cliquez sur l&apos;ic&ocirc;ne du menu Outils. S&eacute;lectionnez Options.</p>
        <p>
          Cliquez sur l&apos;onglet Options avanc&eacute;es et acc&eacute;dez &agrave; la
          section &quot;Confidentialit&eacute;&quot;. Cliquez sur le bouton Afficher les
          cookies.
        </p>
        <p>
          Rep&eacute;rez les fichiers qui contiennent le nom &laquo; KEPLER &raquo;
          S&eacute;lectionnez-les et supprimez-les. Cliquez sur Fermer pour revenir &agrave;
          votre navigateur
        </p>
        <ol>
          <li>Cookies Tiers :</li>
        </ol>
        <p>
          Lorsque vous visitez notre site Internet, certains cookies ne seront pas
          enregistr&eacute;s par nous mais par des tiers. Ces cookies d&eacute;pendent
          principalement des r&eacute;gies publicitaires.
        </p>
        <p>
          Par exemple, si vous cliquez sur des publicit&eacute;s de tiers qui apparaissent sur
          notre site Internet (c&apos;est-&agrave;-dire qui font la promotion d&apos;un produit
          que nous ne vendons pas nous-m&ecirc;mes), ces tiers enregistrent des cookies sur
          votre appareil. Nous sugg&eacute;rons par cons&eacute;quent de regarder les sites
          Internet de ces tiers pour plus d&apos;informations sur les cookies qu&apos;ils
          enregistrent et comment vous pouvez les g&eacute;rer.
        </p>
        <p>
          Le refus de ces cookies publicitaires n&apos;a pas d&apos;impact sur
          l&apos;utilisation de notre site. Cependant le fait de refuser les cookies
          publicitaires n&apos;entra&icirc;nera pas l&apos;arr&ecirc;t de la publicit&eacute;
          sur notre site ou sur Internet. Cela aura seulement pour effet d&apos;afficher une
          publicit&eacute; qui ne tiendra pas compte de vos centres d&apos;int&eacute;r&ecirc;t
          ou de vos pr&eacute;f&eacute;rences.
        </p>
        <ol>
          <li>- Absence de droit de r&eacute;tractation :</li>
        </ol>
        <p>
          Conform&eacute;ment &agrave; ce que pr&eacute;voit l&rsquo;article L 121-20-4, 2&deg;
          du code de la consommation, le droit de r&eacute;tractation d&rsquo;une dur&eacute;e
          de 7 jours pr&eacute;vu aux articles L121.20 et suivants de ce m&ecirc;me code
          n&rsquo;est pas applicable aux ventes de voyage. D&egrave;s lors toutes les ventes de
          voyages, s&eacute;jours, ou circuits sont soumises aux conditions d&rsquo;annulation
          et de modification fix&eacute;es par les pr&eacute;sentes conditions
          g&eacute;n&eacute;rales.
        </p>
        <p>Extraits du code du tourisme</p>
        <p>
          Conform&eacute;ment &agrave; l&apos;article R 211-12 du code du tourisme, les
          articles R211-3 &agrave; R211-11 du code du tourisme sont ci- dessous reproduits :
        </p>
        <p>
          Il est toutefois pr&eacute;cis&eacute; que ces dispositions ne sont pas applicables
          lorsque les prestations vendues n&apos;entrent pas dans un forfait touristique tel
          que d&eacute;fini &agrave; l&apos;article 2 de ladite loi. Ces dispositions ne sont
          notamment pas applicables lors de la vente de titres de transport seuls, de locations
          de voiture seules, de prestations d&apos;h&eacute;bergement seules.
        </p>
        <p>Les dispositions pr&eacute;cit&eacute;es sont les suivantes :</p>
        <p>Article R211-3</p>
        <p>
          Sous r&eacute;serve des exclusions pr&eacute;vues aux troisi&egrave;me et
          quatri&egrave;me alin&eacute;a de l&apos;article L. 211-7, toute offre et toute vente
          de prestations de voyages ou de s&eacute;jours donnent lieu &agrave; la remise de
          documents appropri&eacute;s qui r&eacute;pondent aux r&egrave;gles d&eacute;finies
          par la pr&eacute;sente section.
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>
          En cas de vente de titres de transport a&eacute;rien ou de titres de transport sur
          ligne r&eacute;guli&egrave;re non accompagn&eacute;e de prestations li&eacute;es
          &agrave; ces transports, le vendeur d&eacute;livre &agrave; l&apos;acheteur un ou
          plusieurs billets de passage pour la totalit&eacute; du voyage, &eacute;mis par le
          transporteur ou sous sa responsabilit&eacute;. Dans le cas de transport &agrave; la
          demande, le nom et l&apos;adresse du transporteur, pour le compte duquel les billets
          sont &eacute;mis, doivent &ecirc;tre mentionn&eacute;s.
        </p>
        <p>
          La facturation s&eacute;par&eacute;e des divers &eacute;l&eacute;ments d&apos;un
          m&ecirc;me forfait touristique ne soustrait pas le vendeur aux obligations qui lui
          sont faites par les dispositions r&eacute;glementaires de la pr&eacute;sente section.
        </p>
        <p>Article R211-3-1</p>
        <p>
          L&apos;&eacute;change d&apos;informations pr&eacute;contractuelles ou la mise
          &agrave; disposition des conditions contractuelles est effectu&eacute; par
          &eacute;crit. Ils peuvent se faire par voie &eacute;lectronique dans les conditions
          de validit&eacute; et d&apos;exercice pr&eacute;vues aux articles 1369-1 &agrave;
          1369-11 du code civil. Sont mentionn&eacute;s le nom ou la raison sociale et
          l&apos;adresse du vendeur ainsi que l&apos;indication de son immatriculation au
          registre pr&eacute;vu au a de l&apos;article L. 141-3 ou, le cas
          &eacute;ch&eacute;ant, le nom, l&apos;adresse et l&apos;indication de
          l&apos;immatriculation de la f&eacute;d&eacute;ration ou de l&apos;union
          mentionn&eacute;es au deuxi&egrave;me alin&eacute;a de l&apos;article R. 211-2.
        </p>
        <p>Article R211-4</p>
        <p>
          Pr&eacute;alablement &agrave; la conclusion du contrat, le vendeur doit communiquer
          au consommateur les informations sur les prix, les dates et les autres
          &eacute;l&eacute;ments constitutifs des prestations fournies &agrave; l&apos;occasion
          du voyage ou du s&eacute;jour tels que : 1&deg; La destination, les moyens, les
          caract&eacute;ristiques et les cat&eacute;gories de transports utilis&eacute;s.
        </p>
        <p>
          2&deg; Le mode d&apos;h&eacute;bergement, sa situation, son niveau de confort et ses
          principales caract&eacute;ristiques, son homologation et son classement touristique
          correspondant &agrave; la r&eacute;glementation ou aux usages du pays d&apos;accueil.
        </p>
        <p>3&deg; Les prestations de restauration propos&eacute;es.</p>
        <p>
          4&deg; La description de l&apos;itin&eacute;raire lorsqu&apos;il s&apos;agit
          d&apos;un circuit.
        </p>
        <p>
          5&deg; Les formalit&eacute;s administratives et sanitaires &agrave; accomplir par les
          nationaux ou par les ressortissants d&apos;un autre Etat membre de l&apos;Union
          europ&eacute;enne ou d&apos;un Etat partie &agrave; l&apos;accord sur l&apos;Espace
          &eacute;conomique europ&eacute;en en cas, notamment, de franchissement des
          fronti&egrave;res ainsi que leurs d&eacute;lais d&apos;accomplissement.
        </p>
        <p>
          6&deg; Les visites, excursions et les autres services inclus dans le forfait ou
          &eacute;ventuellement disponibles moyennant un suppl&eacute;ment de prix.
        </p>
        <p>
          7&deg; La taille minimale ou maximale du groupe permettant la r&eacute;alisation du
          voyage ou du s&eacute;jour ainsi que, si la r&eacute;alisation du voyage ou du
          s&eacute;jour est subordonn&eacute;e &agrave; un nombre minimal de participants, la
          date limite d&apos;information du consommateur en cas d&apos;annulation du voyage ou
          du s&eacute;jour. Cette date ne peut &ecirc;tre fix&eacute;e &agrave; moins de vingt
          et un jours avant le d&eacute;part.
        </p>
        <p>
          8&deg; Le montant ou le pourcentage du prix &agrave; verser &agrave; titre
          d&apos;acompte &agrave; la conclusion du contrat ainsi que le calendrier de paiement
          du solde.
        </p>
        <p>
          9&deg; Les modalit&eacute;s de r&eacute;vision des prix telles que pr&eacute;vues par
          le contrat en application de l&apos;article R. 211-8. 10&deg; Les conditions
          d&apos;annulation de nature contractuelle.
        </p>
        <p>
          11&deg; Les conditions d&apos;annulation d&eacute;finies aux articles R. 211-9, R.
          211-10 et R. 211-11.
        </p>
        <p>
          12&deg; L&apos;information concernant la souscription facultative d&apos;un contrat
          d&apos;assurance couvrant les cons&eacute;quences de certains cas d&apos;annulation
          ou d&apos;un contrat d&apos;assistance couvrant certains risques particuliers,
          notamment les frais de rapatriement en cas d&apos;accident ou de maladie.
        </p>
        <p>
          13&deg; Lorsque le contrat comporte des prestations de transport a&eacute;rien,
          l&apos;information, pour chaque tron&ccedil;on de vol, pr&eacute;vue aux articles R.
          211-15 &agrave; R. 211-18.
        </p>
        <p>Article R211-5</p>
        <p>
          L&apos;information pr&eacute;alable faite au consommateur engage le vendeur, &agrave;
          moins que dans celle-ci le vendeur ne se soit r&eacute;serv&eacute;
          express&eacute;ment le droit d&apos;en modifier certains &eacute;l&eacute;ments. Le
          vendeur doit, dans ce cas, indiquer clairement dans quelle mesure cette modification
          peut intervenir et sur quels &eacute;l&eacute;ments.
        </p>
        <p>
          En tout &eacute;tat de cause, les modifications apport&eacute;es &agrave;
          l&apos;information pr&eacute;alable doivent &ecirc;tre communiqu&eacute;es au
          consommateur avant la conclusion du contrat.
        </p>
        <p>Article R211-6</p>
        <p>
          Le contrat conclu entre le vendeur et l&apos;acheteur doit &ecirc;tre &eacute;crit,
          &eacute;tabli en double exemplaire dont l&apos;un est remis &agrave; l&apos;acheteur,
          et sign&eacute; par les deux parties. Lorsque le contrat est conclu par voie
          &eacute;lectronique, il est fait application des articles 1369-1 &agrave; 1369-11 du
          code civil. Le contrat doit comporter les clauses suivantes :
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>
          1&deg; Le nom et l&apos;adresse du vendeur, de son garant et de son assureur ainsi
          que le nom et l&apos;adresse de l&apos;organisateur. 2&deg; La destination ou les
          destinations du voyage et, en cas de s&eacute;jour fractionn&eacute;, les
          diff&eacute;rentes p&eacute;riodes et leurs dates. 3&deg; Les moyens, les
          caract&eacute;ristiques et les cat&eacute;gories des transports utilis&eacute;s, les
          dates et lieux de d&eacute;part et de retour. 4&deg; Le mode
          d&apos;h&eacute;bergement, sa situation, son niveau de confort et ses principales
          caract&eacute;ristiques et son classement touristique en vertu des
          r&eacute;glementations ou des usages du pays d&apos;accueil.
        </p>
        <p>
          5&deg; Les prestations de restauration propos&eacute;es. 6&deg;
          L&apos;itin&eacute;raire lorsqu&apos;il s&apos;agit d&apos;un circuit.
        </p>
        <p>
          7&deg; Les visites, les excursions ou autres services inclus dans le prix total du
          voyage ou du s&eacute;jour.
        </p>
        <p>
          8&deg; Le prix total des prestations factur&eacute;es ainsi que l&apos;indication de
          toute r&eacute;vision &eacute;ventuelle de cette facturation en vertu des
          dispositions de l&apos;article R. 211-8.
        </p>
        <p>
          9&deg; L&apos;indication, s&apos;il y a lieu, des redevances ou taxes
          aff&eacute;rentes &agrave; certains services telles que taxes d&apos;atterrissage, de
          d&eacute;barquement ou d&apos;embarquement dans les ports et a&eacute;roports, taxes
          de s&eacute;jour lorsqu&apos;elles ne sont pas incluses dans le prix de la ou des
          prestations fournies.
        </p>
        <p>
          10&deg; Le calendrier et les modalit&eacute;s de paiement du prix ; le dernier
          versement effectu&eacute; par l&apos;acheteur ne peut &ecirc;tre inf&eacute;rieur
          &agrave; 30 % du prix du voyage ou du s&eacute;jour et doit &ecirc;tre
          effectu&eacute; lors de la remise des documents permettant de r&eacute;aliser le
          voyage ou le s&eacute;jour.
        </p>
        <p>
          11&deg; Les conditions particuli&egrave;res demand&eacute;es par l&apos;acheteur et
          accept&eacute;es par le vendeur.
        </p>
        <p>
          12&deg; Les modalit&eacute;s selon lesquelles l&apos;acheteur peut saisir le vendeur
          d&apos;une r&eacute;clamation pour inex&eacute;cution ou mauvaise ex&eacute;cution du
          contrat, r&eacute;clamation qui doit &ecirc;tre adress&eacute;e dans les meilleurs
          d&eacute;lais, par tout moyen permettant d&apos;en obtenir un accus&eacute; de
          r&eacute;ception au vendeur, et, le cas &eacute;ch&eacute;ant, signal&eacute;e par
          &eacute;crit, &agrave; l&apos;organisateur du voyage et au prestataire de services
          concern&eacute;s.
        </p>
        <p>
          13&deg; La date limite d&apos;information de l&apos;acheteur en cas d&apos;annulation
          du voyage ou du s&eacute;jour par le vendeur dans le cas o&ugrave; la
          r&eacute;alisation du voyage ou du s&eacute;jour est li&eacute;e &agrave; un nombre
          minimal de participants, conform&eacute;ment aux dispositions du 7&deg; de
          l&apos;article R. 211-4.
        </p>
        <p>14&deg; Les conditions d&apos;annulation de nature contractuelle.</p>
        <p>
          15&deg; Les conditions d&apos;annulation pr&eacute;vues aux articles R. 211-9, R.
          211-10 et R. 211-11.
        </p>
        <p>
          16&deg; Les pr&eacute;cisions concernant les risques couverts et le montant des
          garanties au titre du contrat d&apos;assurance couvrant les cons&eacute;quences de la
          responsabilit&eacute; civile professionnelle du vendeur.
        </p>
        <p>
          17&deg; Les indications concernant le contrat d&apos;assurance couvrant les
          cons&eacute;quences de certains cas d&apos;annulation souscrit par l&apos;acheteur
          (num&eacute;ro de police et nom de l&apos;assureur) ainsi que celles concernant le
          contrat d&apos;assistance couvrant certains risques particuliers, notamment les frais
          de rapatriement en cas d&apos;accident ou de maladie, dans ce cas, le vendeur doit
          remettre &agrave; l&apos;acheteur un document pr&eacute;cisant au minimum les risques
          couverts et les risques exclus.
        </p>
        <p>
          18&deg; La date limite d&apos;information du vendeur en cas de cession du contrat par
          l&apos;acheteur.
        </p>
        <p>
          19&deg; L&apos;engagement de fournir &agrave; l&apos;acheteur, au moins sept jours
          avant la date pr&eacute;vue pour son d&eacute;part, les informations suivantes :
        </p>
        <ol>
          <li>
            Le nom, l&apos;adresse et le num&eacute;ro de t&eacute;l&eacute;phone de la
            repr&eacute;sentation locale du vendeur ou, &agrave; d&eacute;faut, les noms,
            adresses et num&eacute;ros de t&eacute;l&eacute;phone des organismes locaux
            susceptibles d&apos;aider le consommateur en cas de difficult&eacute; ou, &agrave;
            d&eacute;faut, le num&eacute;ro d&apos;appel permettant d&apos;&eacute;tablir de
            toute urgence un contact avec le vendeur.
          </li>
          <li>
            Pour les voyages et s&eacute;jours de mineurs &agrave; l&apos;&eacute;tranger, un
            num&eacute;ro de t&eacute;l&eacute;phone et une adresse permettant
            d&apos;&eacute;tablir un contact direct avec l&apos;enfant ou le responsable sur
            place de son s&eacute;jour.
          </li>
        </ol>
        <p>
          20&deg; La clause de r&eacute;siliation et de remboursement sans
          p&eacute;nalit&eacute;s des sommes vers&eacute;es par l&apos;acheteur en cas de non-
          respect de l&apos;obligation d&apos;information pr&eacute;vue au 13&deg; de
          l&apos;article R. 211-4.
        </p>
        <p>
          21&deg; L&apos;engagement de fournir &agrave; l&apos;acheteur, en temps voulu avant
          le d&eacute;but du voyage ou du s&eacute;jour, les heures de d&eacute;part et
          d&apos;arriv&eacute;e.
        </p>
        <p>Article R211-7</p>
        <p>
          L&apos;acheteur peut c&eacute;der son contrat &agrave; un cessionnaire qui remplit
          les m&ecirc;mes conditions que lui pour effectuer le voyage ou le s&eacute;jour, tant
          que ce contrat n&apos;a produit aucun effet.
        </p>
        <p>
          Sauf stipulation plus favorable au c&eacute;dant, celui-ci est tenu d&apos;informer
          le vendeur de sa d&eacute;cision par tout moyen permettant d&apos;en obtenir un
          accus&eacute; de r&eacute;ception au plus tard sept jours avant le d&eacute;but du
          voyage. Lorsqu&apos;il s&apos;agit d&apos;une croisi&egrave;re, ce d&eacute;lai est
          port&eacute; &agrave; quinze jours. Cette cession n&apos;est soumise, en aucun cas,
          &agrave; une autorisation pr&eacute;alable du vendeur.
        </p>
        <p>
          <br />
          <br />
        </p>
        <p>Article R211-8</p>
        <p>
          Lorsque le contrat comporte une possibilit&eacute; expresse de r&eacute;vision du
          prix, dans les limites pr&eacute;vues &agrave; l&apos;article L. 211-12, il doit
          mentionner les modalit&eacute;s pr&eacute;cises de calcul, tant &agrave; la hausse
          qu&apos;&agrave; la baisse, des variations des prix, et notamment le montant des
          frais de transport et taxes y aff&eacute;rentes, la ou les devises qui peuvent avoir
          une incidence sur le prix du voyage ou du s&eacute;jour, la part du prix &agrave;
          laquelle s&apos;applique la variation, le cours de la ou des devises retenu comme
          r&eacute;f&eacute;rence lors de l&apos;&eacute;tablissement du prix figurant au
          contrat.
        </p>
        <p>Article R211-9</p>
        <p>
          Lorsque, avant le d&eacute;part de l&apos;acheteur, le vendeur se trouve contraint
          d&apos;apporter une modification &agrave; l&apos;un des &eacute;l&eacute;ments
          essentiels du contrat telle qu&apos;une hausse significative du prix et
          lorsqu&apos;il m&eacute;conna&icirc;t l&apos;obligation d&apos;information
          mentionn&eacute;e au 13&deg; de l&apos;article R. 211-4, l&apos;acheteur peut, sans
          pr&eacute;juger des recours en r&eacute;paration pour dommages &eacute;ventuellement
          subis, et apr&egrave;s en avoir &eacute;t&eacute; inform&eacute; par le vendeur par
          tout moyen permettant d&apos;en obtenir un accus&eacute; de r&eacute;ception :
        </p>
        <p>
          Soit r&eacute;silier son contrat et obtenir sans p&eacute;nalit&eacute; le
          remboursement imm&eacute;diat des sommes vers&eacute;es.
        </p>
        <p>
          Soit accepter la modification ou le voyage de substitution propos&eacute; par le
          vendeur. Un avenant au contrat pr&eacute;cisant les modifications apport&eacute;es
          est alors sign&eacute; par les parties, toute diminution de prix vient en
          d&eacute;duction des sommes restant &eacute;ventuellement dues par l&apos;acheteur
          et, si le paiement d&eacute;j&agrave; effectu&eacute; par ce dernier exc&egrave;de le
          prix de la prestation modifi&eacute;e, le trop-per&ccedil;u doit lui &ecirc;tre
          restitu&eacute; avant la date de son d&eacute;part.
        </p>
        <p>Article R211-10</p>
        <p>
          Dans le cas pr&eacute;vu &agrave; l&apos;article L. 211-14, lorsque, avant le
          d&eacute;part de l&apos;acheteur, le vendeur annule le voyage ou le s&eacute;jour, il
          doit informer l&apos;acheteur par tout moyen permettant d&apos;en obtenir un
          accus&eacute; de r&eacute;ception. L&apos;acheteur, sans pr&eacute;juger des recours
          en r&eacute;paration des dommages &eacute;ventuellement subis, obtient aupr&egrave;s
          du vendeur le remboursement imm&eacute;diat et sans p&eacute;nalit&eacute; des sommes
          vers&eacute;es. L&apos;acheteur re&ccedil;oit, dans ce cas, une indemnit&eacute; au
          moins &eacute;gale &agrave; la p&eacute;nalit&eacute; qu&apos;il aurait
          support&eacute;e si l&apos;annulation &eacute;tait intervenue de son fait &agrave;
          cette date.
        </p>
        <p>
          Les dispositions du pr&eacute;sent article ne font en aucun cas obstacle &agrave; la
          conclusion d&apos;un accord amiable ayant pour objet l&apos;acceptation, par
          l&apos;acheteur, d&apos;un voyage ou s&eacute;jour de substitution propos&eacute; par
          le vendeur.
        </p>
        <p>Article R211-11</p>
        <p>
          Lorsque, apr&egrave;s le d&eacute;part de l&apos;acheteur, le vendeur se trouve dans
          l&apos;impossibilit&eacute; de fournir une part pr&eacute;pond&eacute;rante des
          services pr&eacute;vus au contrat repr&eacute;sentant un pourcentage non
          n&eacute;gligeable du prix honor&eacute; par l&apos;acheteur, le vendeur doit
          imm&eacute;diatement prendre les dispositions suivantes sans pr&eacute;juger des
          recours en r&eacute;paration pour dommages &eacute;ventuellement subis :
        </p>
        <p>
          Soit proposer des prestations en remplacement des prestations pr&eacute;vues en
          supportant &eacute;ventuellement tout suppl&eacute;ment de prix et, si les
          prestations accept&eacute;es par l&apos;acheteur sont de qualit&eacute;
          inf&eacute;rieure, le vendeur doit lui rembourser, d&egrave;s son retour, la
          diff&eacute;rence de prix.
        </p>
        <p>
          Soit, s&apos;il ne peut proposer aucune prestation de remplacement ou si celles-ci
          sont refus&eacute;es par l&apos;acheteur pour des motifs valables, fournir &agrave;
          l&apos;acheteur, sans suppl&eacute;ment de prix, des titres de transport pour assurer
          son retour dans des conditions pouvant &ecirc;tre jug&eacute;es &eacute;quivalentes
          vers le lieu de d&eacute;part ou vers un autre lieu accept&eacute; par les deux
          parties. Les dispositions du pr&eacute;sent article sont applicables en cas de
          non-respect de l&apos;obligation pr&eacute;vue au 13&deg; de l&apos;article R. 211-4.
        </p>
        <p>Article R211-12</p>
        <p>
          Les dispositions des articles R. 211-3 &agrave; R. 211-11 doivent obligatoirement
          &ecirc;tre reproduites sur les brochures et les contrats de voyages propos&eacute;s
          par les personnes mentionn&eacute;es &agrave; l&apos;article L. 211-1.
        </p>
        <p>Mis &agrave; jour le 06/08/2021</p>
        <div className="d-flex justify-content-center">
          <p className="text-center col-12 col-md-10 col-lg-6">
            KEPLER TRAVEL - 27 rue Joubert 75009 – Paris
            <br />
            Tél. : 01 85 08 18 84- Email : reservations@keplertravel.com
            <br />
            IM 0751500004- N° TVA intracommunautaire FR 93804360402
            <br />
            SAS CAP 10 000 € -RCS PARIS 804360402
            <br />
            Siret : 804 360 402 00015
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
