import { useAppSelector } from 'client/hooks/useAppSelector';
// import { checkLoggingStatus } from 'client/_redux/actions/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useAppRouter = () => {
  const initiated = useAppSelector(({ init: { initiated } }) => initiated);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      ['/products', '/', '/passenger-details'].includes(location.pathname)
    ) {
      window?.scrollTo(0, 0);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    // dispatch(checkLoggingStatus());
  }, [dispatch]);

  return { initiated };
};
