/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupedRooms } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { ISelectorOption } from 'types/ISelectorOption';
import RoomSelector from './RoomSelector';
interface IProps {
  options: ISelectorOption<GroupedRooms | undefined>[];
  setSelectedRoomsSection: React.Dispatch<React.SetStateAction<GroupedRooms | undefined>>;
}
const RoomSections: React.FC<IProps> = ({ options, setSelectedRoomsSection }) => {
  const [t] = useTranslation();
  const [activeBtn, setActiveBtn] = useState(0);

  return (
    <>
      {options?.map((RoomSection, index) => (
        <div
          className={`btn container-fluid d-flex flex-column flex-sm-row  justify-content-between py-2 ${
            index === 0 ? 'border-0' : 'border-top'
          } ${activeBtn === index ? 'btn-success rounded text-white' : ''}`}
          key={`${RoomSection.label}${index + 1}`}
          onClick={() => {
            setActiveBtn(index);
            setSelectedRoomsSection(RoomSection.value);
          }}
        >
          <RoomSelector
            isInclus={index === 0 || RoomSection?.value?.priceDifference === 0}
            activeBtn={activeBtn}
            description={RoomSection.label}
            index={index}
            includedInfo={` ${t('room.inclus')} `}
            info={`${t('room.extraCharge')} ${RoomSection?.value?.priceDifference?.toFixed(
              2,
            )} ${t('currency01.EURO')}`}
          />
        </div>
      ))}
    </>
  );
};

export default RoomSections;
