import { clientApi } from 'api';

const baseUrl = '/users';

export const getAllUsers = (isServer = false) => async ({
  page,
  limit,
  keyword,
}: {
  page: number;
  limit: number | undefined;
  keyword: string;
}) => {
  const response = await clientApi.get(baseUrl, { params: { page, limit, keyword } });

  return response.data;
};
