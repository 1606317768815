/* eslint-disable @typescript-eslint/ban-ts-comment */
export const queryStringToObject = <T = Record<string, string | number | (string | number)[]>>(
  url: string,
): T =>
  [...new URLSearchParams(url.split('?')[1]).entries()].reduce<T>((acc, [key, val]) => {
    // @ts-ignore
    // eslint-disable-next-line no-prototype-builtins
    if (acc.hasOwnProperty(key)) {
      // @ts-ignore
      if (Array.isArray(acc[key])) {
        // @ts-ignore
        acc[key] = [...acc[key], val.replace(/ /g, '%20')];
      } else {
        // @ts-ignore
        acc[key] = [acc[key], val.replace(/ /g, '%20')];
      }
    } else if (val) {
      // @ts-ignore
      acc[key] = val.replace(/ /g, '%20');
    }

    return acc;
  }, {} as T);
