import {
  calculateDiffenceHourMinutes,
  formatDayMonthYear,
  formatHourAndMinutes,
} from 'client/helpers/format/timeFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlightDetails } from '.';
import ResponsiveWrapper from '../ResponsiveWrapper';
import LangindingPlaneSVG from '../SVG/plane/LandingPlaneSVG';
import PlaneDestinationSVG from '../SVG/plane/PlaneDestinationSVG';
import TakeOffPlaneSVG from '../SVG/plane/TakeOffPlane';
import FlightDetailItem from './FlightDetailItem';

interface IProps {
  flightDetails: FlightDetails;
  bgClassName?: string;
}
const FlightDetailsWeb: React.FC<IProps> = ({ flightDetails, bgClassName = '' }) => {
  const {
    direction,
    date = flightDetails.start,
    // companyLogo = '/assets/images/logo-air.png',
    carrier,
    start,
    from,
    durationAndType,
    end,
    to,
    flightNumber,
  } = flightDetails;
  const [t] = useTranslation();

  return (
    <ResponsiveWrapper>
      <div
        className={`flight-details-card-container  d-flex justify-content-between ${bgClassName}`}
      >
        <FlightDetailItem>
          <div className="flight-landing-taking-off-details">
            <span className={`${bgClassName} h4`}>{direction}</span>
            <span>{flightNumber}</span>
          </div>
        </FlightDetailItem>
        <FlightDetailItem>
          <div className="flight-landing-taking-off-details">
            <span>{formatDayMonthYear(date)}</span>
            <span className={`${bgClassName} h3`}>{carrier.title}</span>
            <img
              src={`https://images.kiwi.com/airlines/64/${carrier.code}.png`}
              alt={carrier.code}
            />
          </div>
        </FlightDetailItem>
        <FlightDetailItem>
          <div className="flight-landing-taking-off-details">
            <TakeOffPlaneSVG height="50" width="50" />
            <span>{formatHourAndMinutes(start)}</span> <span>{from.title}</span>
            <span>{from.code}</span>
          </div>
        </FlightDetailItem>

        <FlightDetailItem className="justify-content-end">
          <div className="flight-landing-taking-off-details">
            <PlaneDestinationSVG height="50" width="50" />
            <span>
              {t('FlightDetailsCard.duration')} : {calculateDiffenceHourMinutes(start, end)}
              {durationAndType}
            </span>
          </div>
        </FlightDetailItem>
        <FlightDetailItem>
          <div className="flight-landing-taking-off-details">
            <LangindingPlaneSVG height="50" width="50" />
            <span>{formatHourAndMinutes(end)}</span> <span>{to.title}</span>
            <span>{to.code}</span>
          </div>
        </FlightDetailItem>
      </div>
    </ResponsiveWrapper>
  );
};

export default FlightDetailsWeb;
