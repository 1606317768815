import { IEntitiesTypes } from 'dashboard/_redux/types/entitiesTypes';
import { CountriesTypes } from 'dashboard/_redux/types/countriesTypes';
import { IReduxAction } from 'types/IReduxAction';

export interface ICountry {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  title: string;
  subtitle: string;
  countryImage: string | File;
}
const initialState = {
  loading: true,
  countries: [],
};

export interface IProductListInterface {
  countriesNames?: { name: string; code: string }[];
  loading?: boolean;
  countries: ICountry[];
}

export default (
  state: IProductListInterface = initialState,
  { type, payload }: IReduxAction,
): IProductListInterface => {
  switch (type) {
    case IEntitiesTypes.GET_COUNTRIES_LIST_SUCCESS:
      return { ...state, countriesNames: payload, loading: false };
    case CountriesTypes.ADD_COUNTRY_SUCCESS:
      return { ...state, loading: false, countries: [...state.countries, payload] };
    case CountriesTypes.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: [
          ...state.countries.filter((country) => country._id !== payload._id),
          payload,
        ],
      };
    case CountriesTypes.GET_COUNTRIES_SUCCESS:
      return { ...state, countries: payload, loading: false };
    case CountriesTypes.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: [...state.countries.filter((country) => country._id !== payload)],
        loading: false,
      };
    default:
      return state;
  }
};
