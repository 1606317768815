/* eslint-disable max-lines */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getImageUrl } from 'client/helpers/getImageUrl';
import Modal from 'dashboard/components/Modal';
import Pagination from 'client/components/Pagination';
import { useBlogDashboard } from './useBlogDashboard';

export interface ICardProps {
  deleteArticle: (id?: string) => void;
  id?: string;
  title: string;
  subtitle: string;
  image: string;
}

const BlogDashboard = () => {
  const {
    setOpenDeleteModal,
    loading,
    articles,
    t,
    handleDelete,
    openDeleteModal,
    cover,
  } = useBlogDashboard();
  const { metaCover, metaDescription, metaTitle } = cover;
  const { items, page, totalPages } = articles;
  const history = useHistory();

  return loading ? (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  ) : (
    <>
      <div
        className="container-fluid content-space-t-3 content-space-t-lg-5 content-space-b-1 content-space-b-md-2 w-100 background-image"
        style={{
          padding: ' 12rem 0',
          backgroundImage: `url(${
            metaCover &&
            (metaCover[0].includes('https') ? metaCover[0] : getImageUrl(metaCover[0]))
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          WebkitBackgroundSize: 'cover',
          backgroundPosition: 'center',
          cursor: 'pointer',
        }}
        onClick={() => history.push('/dashboard/blog/add-cover')}
      >
        <div className="text-center mx-md-auto overflow-hidden text-shadow-article container-xxl mx-auto">
          <h1
            className="display-4 text-white"
            style={{
              textShadow: 'rgb(94 81 81 / 65%) 9px 5px 24px, rgb(0 0 0) 1px 1px 7px',
            }}
          >
            {metaTitle}
          </h1>
          <p
            className="lead text-white"
            style={{
              textShadow: 'rgb(94 81 81 / 65%) 9px 5px 24px, rgb(0 0 0) 1px 1px 7px',
            }}
          >
            {metaDescription}
          </p>
        </div>
      </div>
      <div className="add_article_btn">
        <Link
          type="button"
          className="btn kc_fab_main_article_btn p-0 mb-5 ml-5"
          to="/dashboard/blog/add"
        >
          +
        </Link>
      </div>
      <div
        className="d-flex flex-wrap justify-content-centercontainer"
        style={{ marginTop: '7em', marginBottom: '3rem' }}
      >
        {items &&
          items.map((el) => (
            <React.Fragment key={el._id}>
              <div
                data-testid="homePageCard border"
                className="px-2 pt-3 col-lg-4 col-md-4 col-sm-12"
              >
                <div className="card card-with-image zoom-wrapper overflow-hidden transition-3d-hover card-hover-shadow">
                  <div
                    className=" zoom-image card-image col-sm-12"
                    style={{
                      backgroundImage: `${`url(${getImageUrl(el?.metaImage[0])})`}`,
                    }}
                  />
                  <div className="position-absolute top-0 right-0">
                    <button
                      onClick={() => setOpenDeleteModal(true)}
                      type="button"
                      className="btn btn-xs btn-danger"
                    >
                      X
                    </button>
                  </div>
                  <div className="card-body" style={{ maxHeight: '140px', padding: '15px' }}>
                    <div className="d-flex align-items-baseline mr-1">
                      <p data-testid="homePageCard-icon" className={` mb-0`} />
                      <p className="ml-1 mb-0" />
                    </div>
                    <h3 className="font-weight-bold">{el?.metaTitle}</h3>

                    <div className="justify-content-start" style={{}}>
                      <p
                        className="mb-0 mr-1 font-weight-lighter"
                        style={{
                          width: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {el?.metaDescription}
                      </p>
                    </div>

                    <div className="d-flex align-items-center justify-content-end small font-weight-bold text-font-primary">
                      <Link to={`/dashboard/blog/edit/${el?._id}`}>
                        {t('productCard.PlusDeDetails')}
                      </Link>
                      <i className="fas fa-angle-right fa-sm ml-1" />
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                closeModal={() => setOpenDeleteModal(false)}
                modalHead={`Delete ${el.metaTitle} ?`}
                isOpen={openDeleteModal}
              >
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleDelete(el._id)}
                  >
                    {t('blog.confirm')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success ml-5"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    {t('blog.cancel')}
                  </button>
                </div>
              </Modal>
            </React.Fragment>
          ))}
      </div>
      <Pagination
        totalPages={totalPages}
        page={page}
        next={t('pagination.next')}
        previous={t('pagination.previous')}
      />
    </>
  );
};

export default BlogDashboard;
