import React from 'react';

interface IProps {
  className?: string;
  isMobile?: boolean;
}

const FlightDetailItem: React.FC<IProps> = ({ children, className, isMobile }) => (
  <div
    className={`d-flex ${
      isMobile ? 'justify-content-start h-100' : 'justify-content-center'
    } align-items-center w-100 ${className}`}
  >
    {children}
  </div>
);

export default FlightDetailItem;
