import { IFlight } from 'server/models/Flights/types';
import { IRoundFlightDetails } from '../../client/_redux/services/flights';
export const isRoundedFlight = (flights: unknown): flights is IRoundFlightDetails =>
  (flights && (flights as IRoundFlightDetails).goingFlights) !== undefined &&
  (flights as IRoundFlightDetails).returningFlights !== undefined &&
  Array.isArray((flights as IRoundFlightDetails).goingFlights) &&
  Array.isArray((flights as IRoundFlightDetails).returningFlights) &&
  (flights as IRoundFlightDetails).goingFlights.length > 0 &&
  (flights as IRoundFlightDetails).returningFlights.length > 0;
export const isOneWayFlight = (flights: unknown): flights is { oneWayFlights: IFlight[] }[] =>
  Array.isArray((flights as { oneWayFlights: IFlight[] })?.oneWayFlights) &&
  (flights as { oneWayFlights: IFlight[] })?.oneWayFlights.length > 0;
