import { clientApi } from 'api';
import { getFromData } from 'dashboard/helpers/getFromData';
import { IDestinationFormValues } from 'dashboard/containers/AddProductForm/useAddProductForm';

export const addCountry = (isServer = false) => async (data: IDestinationFormValues) => {
  const formData = new FormData();

  getFromData(formData, data);

  const response = await clientApi.post<IDestinationFormValues>('/countries', formData);

  return response;
};
