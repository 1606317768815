export enum ProductInfoTypes {
  ADD_PRODUCT_INFO_START = 'ADD_PRODUCT_INFO_START',
  ADD_PRODUCT_INFO_FAILED = 'ADD_PRODUCT_INFO_FAILED',
  ADD_PRODUCT_INFO_SUCCESS = 'ADD_PRODUCT_INFO_SUCCESS',
  GET_PRODUCT_INFO_START = 'GET_PRODUCT_INFO_START',
  GET_PRODUCT_INFO_FAILED = 'GET_PRODUCT_INFO_FAILED',
  GET_PRODUCT_INFO_SUCCESS = 'GET_PRODUCT_INFO_SUCCESS',
  DELETE_PRODUCT_INFO_START = 'DELETE_PRODUCT_INFO_START',
  DELETE_PRODUCT_INFO_FAILED = 'DELETE_PRODUCT_INFO_FAILED',
  DELETE_PRODUCT_INFO_SUCCESS = 'DELETE_PRODUCT_INFO_SUCCESS',
  UPDATE_PRODUCT_INFO_START = 'UPDATE_PRODUCT_INFO_START',
  UPDATE_PRODUCT_INFO_FAILED = 'UPDATE_PRODUCT_INFO_FAILED',
  UPDATE_PRODUCT_INFO_SUCCESS = 'UPDATE_PRODUCT_INFO_SUCCESS',
}
