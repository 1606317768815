import { useEffect, useState } from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handler = () => {
      if (typeof window !== 'undefined')
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
    };

    window.addEventListener('resize', handler);
    handler();

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return { windowSize };
};
