import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import useCheckingReservationForm from './useCheckingReservationForm';

export interface ICheckingReservationForm {
  email: string;
  bookingId: string;
}
interface IProps {
  onSubmit: (value: ICheckingReservationForm) => void;
}

const CheckingReservationForm = ({ onSubmit }: IProps) => {
  const { formMethods, handleSubmit, t } = useCheckingReservationForm();

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div className="cards">
        <img src="/assets/svg/logos/logo.svg" alt="KeplerLogo" width="258px" style={{display:"flex",margin: "0 auto"}}/>
          <h3 className="card-header text-primary" style={{padding: "0rem 2rem"}}>
            {t('checkingReservation.CheckingReservationInformation')}
          </h3>
          <div className="card-body">
            <div className="text-primary h5 font-weight-normal mt-3">
              {t('adminsList.email')}:
            </div>
            <Input
              type="email"
              name="email"
              validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
              placeholder="email@example.com"
            />
            <div className="text-primary h5 font-weight-normal">
              {t('checkingReservation.fileReferance')}:
            </div>
            <Input
              type="text"
              name="bookingId"
              validate={Validators([{ validation: 'required' }])}
              placeholder="Référence du dossier"
            />
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-success" style={{width:"100%"}}>
                {t('checkingReservation.send')}
                
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CheckingReservationForm;
