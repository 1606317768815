import { TravellerType } from 'client/containers/ReservationConfirmationCards/useReservationConfirmationCards';
import { formatDate } from 'client/helpers/format/formatDate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITraveller } from 'types/ICreateBookingRequest';

interface IBookingCard {
  isConfirmationPage?: boolean;
  isAddon?: boolean;
  elements: ITravellerElement[];
}
type ITravellerElement = {
  id: string;
  travellers?: TravellerType[] | ITraveller[];
  elementName?: string;
  passportDeliveryDate?: string;
  passportID?: string;
};
const BookingCard: React.FC<IBookingCard> = ({ elements, isAddon, isConfirmationPage }) => {
  const [t] = useTranslation();

  return (
    <div>
      <div>
        {elements.map((el) => (
          <div key={el.id} className="col mb-4 ">
            <div className="card-hover-shadow-lg w-auto w-">
              <div className="card-body">
                <div className="mb-2">
                  <h6>{`${isAddon ? ' ' : 'Room '} ${el.id}`}</h6>
                  <h6>{el.elementName}</h6>
                </div>
                <div className="d-flex justify-content-around flex-wrap">
                  {el.travellers?.map((adult) => (
                    <div className="ml-3 room-card">
                      <div>
                        {isConfirmationPage
                          ? `${(adult as TravellerType)?.firstName} ${
                              (adult as TravellerType)?.lastName
                            }`
                          : `${(adult as ITraveller)?.title} ${
                              (adult as ITraveller)?.name.firstName
                            } ${(adult as ITraveller)?.name.lastName}`}
                      </div>
                      <div>
                        {t('reservationDetails.nationality')}
                        {adult?.nationality}
                      </div>
                      <div>
                        {t('reservationDetails.birthDate')}
                        {adult?.birthDate && formatDate(adult?.birthDate)}
                      </div>
                      <div>
                        <h6 className="mb-0">
                          {t('clientCredentials.passport')}
                          {adult?.passport}
                        </h6>
                      </div>
                      <div>
                        <h6 className="mb-0">
                          {t('clientCredentials.passportDeliviryDate')}
                          {adult?.passportDeliveryDate &&
                            formatDate(adult?.passportDeliveryDate)}
                        </h6>
                      </div>
                      {el.travellers && el.travellers[1] && <hr />}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingCard;
