/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

const LazyImage: React.FC<
  React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ src, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const reducedSize = `${src?.split('?')[0]}?tr=w-4,h-4`;

  useEffect(() => {
    setIsLoaded(false);
  }, [src]);
  if (typeof document !== 'undefined') {
    const image = document.createElement('img');

    image.src = src || '/assets/svg/logos/logo.svg';
    image.onload = () => {
      setIsLoaded(true);
    };
  }

  return (
    <div className={!isLoaded ? 'position-relative' : ''}>
      {!isLoaded && <div className="lds-dual-ring" />}
      <img src={isLoaded ? src : reducedSize} {...props} />
    </div>
  );
};

export default LazyImage;
