import { formatProductFilterOptions } from 'client/helpers/format/formatProductFilterOptions';
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { DBDocument } from 'client/models/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { IFilter } from 'server/models/Filter/types';
import { ISearchCriteriaRouteQueryObject } from 'types/ISearchCriteriaQuery';

export const useCustomFilter = (productFilter?: (IFilter & DBDocument)[]) => {
  const location = useLocation();
  const searchObjectFilter: ISearchCriteriaRouteQueryObject = useMemo(() => {
    const data = queryStringToObject<ISearchCriteriaRouteQueryObject>(
      decodeURI(location.search),
    );

    return {
      ...data,
    };
  }, [location.search]);

  const formattedProductFilersOptions = formatProductFilterOptions(
    productFilter,
    searchObjectFilter as { attributes: string[] | string },
  );

  const [t] = useTranslation();

  return {
    t,
    formattedProductFilersOptions,
    productFilter,
  };
};
