import React from 'react';

const FooterMentionLegales = () => (
  <div className="d-flex justify-content-center bg-primary">
    <p className="h6 text-white text-center col-11 col-md-8 col-lg-10">
      KEPLER TRAVEL - 27 rue Joubert 75009 – Paris
      
      <span style={{paddingLeft:'10px'}}>Tél. : 01 85 08 18 84- Email : reservations@keplertravel.com</span>

      <span style={{paddingLeft:'10px'}}>IM 0751500004- N° TVA intracommunautaire FR 93804360402</span><br/>

      <span style={{paddingLeft:'10px'}}>SAS CAP 10 000 € -RCS PARIS 804360402</span>

      <span style={{paddingLeft:'10px'}}>Siret : 804 360 402 00015</span>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://edonec.com"
        className=" text-white d-block"
        style={{marginBottom:'20px'}}
      >
        Made with ♥ by - eDonec
      </a>
    </p>
  </div>
);

export default FooterMentionLegales;
