import FormImagePicker from 'dashboard/components/Forms/FormImagePicker';
import FormSelect from 'dashboard/components/Forms/FormSelect';
import Input from 'dashboard/components/Forms/Input';
import Validators from 'dashboard/helpers/formValidator';
import { ICountry } from 'dashboard/_redux/reducers/countries';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useAddProductForm } from './useAddProductForm';
type AddProductFormProp = {
  openModal?: React.Dispatch<React.SetStateAction<boolean>>;
  editCountry?: ICountry;
};
const AddProductForm: React.FC<AddProductFormProp> = ({ openModal, editCountry }) => {
  const {
    countriesList,
    handleSubmit,
    onSubmit,
    formMethods,
    t,
    getCountryCode,
  } = useAddProductForm(openModal, editCountry);

  return (
    <>
      <FormProvider {...formMethods}>
        <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="card ">
            <div className="card-body">
              {t('dashboard.addDestination.chooseSubtitle')}
              <Input name="subtitle" validate={Validators([{ validation: 'required' }])} />
              {t('dashboard.addDestination.SelectACountry')}
              <FormSelect
                name="title"
                options={countriesList || []}
                validate={Validators([{ validation: 'required' }])}
                initialValue={editCountry && getCountryCode(editCountry?.title)}
              />
              {t('dashboard.addDestination.AddCountryImage')}
              <FormImagePicker
                name="countryImage"
                maxFiles={1}
                validate={Validators([{ validation: 'required' }])}
              />
            </div>
            <div className="card-footer text-center">
              <button className="btn btn-primary " type="submit">
                {t('dashboard.addDestination.Add')}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default AddProductForm;
