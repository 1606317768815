import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import { countrySelect } from 'client/constants/CountryList';
import { formGenderOptions } from 'client/constants/formCivilityOptions';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ClientData = ({
  passengerNumber,
  isEmailRequired,
}: {
  passengerNumber: number;
  isEmailRequired?: boolean;
}) => {
  const [t] = useTranslation();

  return (
    <>
      <div className="d-flex flex-row align-items-center w-100 flex-start">
        <Input
          name={`userData.${passengerNumber}.firstName`}
          type="text"
          label={`${t('room.formReservation.firstName')} (*)`}
          placeholder={t('room.formReservation.firstName')}
          className="mr-3 py-0 w-100 "
          validate={Validators([
            { validation: 'required' },
            { minParams: 3, validation: 'minLength' },
          ])}
          labelClassName="w-100 d-flex flex-start"
        />
        <Input
          name={`userData.${passengerNumber}.lastName`}
          type="text"
          label={`${t('room.formReservation.lastName')} (*)`}
          placeholder={`${t('room.formReservation.lastName')}`}
          className="mr-3 py-0 w-100 "
          validate={Validators([
            { validation: 'required' },
            { minParams: 3, validation: 'minLength' },
          ])}
          defaultValue=""
          labelClassName="w-100 d-flex flex-start"
        />
      </div>
      <Input
        name={`userData.${passengerNumber}.dateOfBirth`}
        type="date"
        placeholder="dd-mm-yyyy"
        label={`${t('room.formReservation.birthDate')}`}
        labelClassName="w-100 d-flex flex-start"
      />
      <div className="d-flex flex-row align-items-center w-100 flex-start">
        <Input
          name={`userData.${passengerNumber}.email`}
          type="email"
          label={`${t('room.formReservation.email')} ${isEmailRequired ? '(*)' : ''}`}
          placeholder={t('room.formReservation.email')}
          className="mr-3 py-0 w-100 "
          validate={Validators([
            { validation: 'email' },
            ...(isEmailRequired ? [{ validation: 'required' } as const] : []),
          ])}
          labelClassName="w-100 d-flex flex-start"
        />
        <FormSelect
          label={`${t('room.formReservation.civility')} `}
          name={`userData.${passengerNumber}.gender`}
          options={formGenderOptions}
          initialValue={[{ label: '', value: '' }]}
          selectClassName="w-100"
        />
      </div>
      <div className="d-flex flex-row align-items-center w-100 flex-start">
        <FormSelect
          label={`${t('room.formReservation.birthCountry')}`}
          name={`userData.${passengerNumber}.birthCountry`}
          options={countrySelect}
          isSearchable
          initialValue={[{ label: '', value: '' }]}
          selectClassName="w-50 mr-4"
        />
        <FormSelect
          label={`${t('room.formReservation.nationality')}`}
          name={`userData.${passengerNumber}.nationality`}
          options={countrySelect}
          isSearchable
          initialValue={[{ label: '', value: '' }]}
          selectClassName="w-50"
        />
      </div>

      <div className="d-flex flex-row align-items-center w-100 flex-start">
        <Input
          name={`userData.${passengerNumber}.passeportNumber`}
          type="text"
          label={`${t('room.formReservation.passportID')} `}
          placeholder={t('room.formReservation.passportID')}
          className="mr-3 py-0 w-100 "
          labelClassName="w-100 d-flex flex-start"
        />
        <Input
          name={`userData.${passengerNumber}.PassportExpiryDate`}
          type="date"
          label={`${t('room.formReservation.PassportExpiryDate')}`}
          placeholder={`${t('room.formReservation.PassportExpiryDate')}`}
          labelClassName="w-100 d-flex flex-start"
        />
      </div>
    </>
  );
};

export default ClientData;
