import { DBDocument } from 'client/models/utils';
import { IFilter } from 'server/models/Filter/types';

export const formatProductFilterOptions = (
  productFilter?: (IFilter & DBDocument)[],
  searchObjectFilter?: { attributes: string[] | string },
) =>
  productFilter?.map(({ attributes, ...others }) => ({
    ...others,
    attributes: attributes.map((e) => ({
      label: e.title,
      value: e.slug,
      checked:
        searchObjectFilter?.attributes?.includes(e.slug) ||
        e.slug === searchObjectFilter?.attributes,
    })),
  }));
