import React from 'react';
import {
  FormattedRoom,
  GroupedRooms,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import Loader from './Loader';
import RoomsAndAlternativeRooms from './RoomsAndAlternativeRooms';
import RoomSections from './RoomSections';
import { useRoomDetails } from './useRoomDetails';

interface IProps {
  onSubmit: (selectedRoom: FormattedRoom[]) => void;
  loading: boolean;
  alternatives: GroupedRooms[] | undefined;
  recommended: GroupedRooms | undefined;
}

const RoomDetails: React.FC<IProps> = ({ onSubmit, loading, alternatives, recommended }) => {
  const {
    sectionPlaceOptions,
    t,
    handleRoomSelected,
    selectedPlaceSection,
    setSelectedPlaceSection,
  } = useRoomDetails({ alternatives, recommended, onSubmit });

  if (!setSelectedPlaceSection || !sectionPlaceOptions) {
    return (
      <div className="container d-flex vw-100 vh-100 flex-center ">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className="container d-flex vw-100 vh-100 flex-center">
          <Loader />
        </div>
      ) : (
        <div className=" mt-5">
          <h2>{t('quotedAvailabilityPage.1.DistributionOfTravellers')}</h2>
          <div className=" d-flex flex-row align-items-center ">
            <div className="px-0 my-1 w-100 border rounded rooms-section">
              <RoomSections
                options={sectionPlaceOptions}
                setSelectedRoomsSection={setSelectedPlaceSection}
              />
            </div>
          </div>
          <div className=" my-2">
            <h2>{t('quotedAvailabilityPage.2.categoryOfAccommodation')}</h2>
            <h3>{t('room.DetailsRooms')}</h3>
            {selectedPlaceSection && (
              <RoomsAndAlternativeRooms
                selectedPlaceSection={selectedPlaceSection}
                handleRoomSelected={handleRoomSelected}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RoomDetails;
