import { AdministratorTypes } from 'dashboard/_redux/types/administratorTypes';
import { IUser } from 'server/models/User';
import { IReduxAction } from 'types/IReduxAction';

export interface IAdministratorState {
  administrator?: IUser;
  administratorsList: IUser[];
}

const initialState: IAdministratorState = {
  administratorsList: [],
};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): IAdministratorState => {
  switch (type) {
    case AdministratorTypes.ADD_ADMIN:
      return {
        ...state,
        administrator: payload,
        administratorsList: [...state.administratorsList, payload],
      };
    case AdministratorTypes.UPDATE_ADMIN:
      return { ...state };

    default:
      return state;
  }
};
