import FLIGHT_TYPES_ICONS from 'client/constants/flightTypesIcons';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import Countries from 'server/constants/airports.json';
import FlightsPageCalendar from '../FilterContentFlight/FlightsPageCalendar';
import { AutoComplete } from '../Form/AutoComplete';
import Select from '../Form/Select';
import { useFlightsTopFilter } from './useFlightsTopFilter';
const FlightsTopFilter = ({ isHomePage }: { isHomePage?: boolean }) => {
  const {
    onSubmit,
    switchInputs,
    extractDataFromAutoComplete,
    formMethods,
    topFilterData,
    switched,
    defaultDestinationAndDeparture,
    handleFlightTypeChange,
    isRoundFlight,
  } = useFlightsTopFilter(Countries);

  return (
    <FormProvider {...formMethods}>
      <div className=" container position-relative mb-5">
        <div className="card ">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-3 col-5  px-1 m-xl-0 mt-5">
              <AutoComplete
                setOuterState={extractDataFromAutoComplete}
                data={Countries}
                defaultValue={defaultDestinationAndDeparture.from}
                outerState={topFilterData}
                name="from"
                placeholder="Départ"
                className="mb-0"
                value={topFilterData.city?.from.value || topFilterData.from}
              />
            </div>
            <div
              className={`flight-switcher-wrapper m-xl-0 mt-5 ${
                switched ? 'rotate-switch-right bg-dark' : 'rotate-switch-left'
              }`}
              onClick={switchInputs}
            >
              <i
                className={`${
                  switched ? 'bg-dark text-white' : ''
                } fas fa-exchange-alt flight-switcher d-flex align-items-center justify-content-around`}
              />
            </div>
            <div className="col-xl-3 col-5 px-1 m-xl-0 mt-5">
              <AutoComplete
                data={Countries}
                name="destination"
                placeholder="Destination"
                defaultValue={defaultDestinationAndDeparture.destination}
                className="mb-0"
                outerState={topFilterData}
                setOuterState={extractDataFromAutoComplete}
                value={topFilterData.city?.destination.value || topFilterData.destination}
              />
            </div>
            <div className="col-xl-2 col-8  m-xl-0 mt-5">
              <Select
                placeholder="Type de vol"
                selectClassName="mb-0"
                value={isRoundFlight}
                options={FLIGHT_TYPES_ICONS}
                onChange={handleFlightTypeChange}
              />
            </div>
            <div className="col-9 col-xl py-5 w-0 d-flex align-items-center justify-content-center ">
              <FlightsPageCalendar />
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary search-flights-button"
          type="submit"
          onClick={onSubmit}
        >
          <i className="fas fa-search mr-2" /> Chercher
        </button>
      </div>
    </FormProvider>
  );
};

export default FlightsTopFilter;
