import { ProductTypes } from 'client/_redux/types/productTypes';
import { IThunkAction } from 'types/IThunkAction';
import * as ProductService from 'client/_redux/services/product';
import { errorHandler } from 'client/helpers/errorHandler';
import { RouteComponentProps } from 'react-router';
import { AxiosError } from 'axios';
import { startLoading, stopLoading } from '../loading';
export const getOne = (
  permalink: string,
  history: RouteComponentProps['history'],
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: ProductTypes.GET_PRODUCT_START });

  try {
    const res = await ProductService.getOne()(permalink);

    dispatch({ type: ProductTypes.GET_PRODUCT_SUCCESS, payload: res.data });
  } catch (error) {
    errorHandler(error as AxiosError);
    dispatch({ type: ProductTypes.GET_PRODUCT_FAILED });
    history.push('/404-not-found');
  }

  dispatch(stopLoading());
};
