import { DBDocument } from 'client/models/utils';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFilter } from 'server/models/Filter/types';

const useAddAndUpdateFilterProductsForm = (
  id: string,
  updateDefaultValue: (IFilter & DBDocument) | undefined,
) => {
  const formMethods = useForm({
    defaultValues: {
      attributes: updateDefaultValue ? updateDefaultValue?.attributes : [''],
    },
  });
  const [t] = useTranslation();

  const { handleSubmit, control } = formMethods;

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'attributes',
  });

  return { handleSubmit, control, append, remove, fields, formMethods, t };
};

export default useAddAndUpdateFilterProductsForm;
