import { getCompressedImageUrl } from 'client/helpers/getCompressedImageUrl';
import React from 'react';
import Slider from 'react-slick';

interface IProps {
  onRemove?: () => void;
  onSelectImage?: (url: number) => void;
  images?: string[];
  title?: string;
}

interface SliderTypes {
  className?: string;
  style?: StyleSheet;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const SampleNextArrow: React.FC<SliderTypes> = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none', right: '0px' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: React.FC<SliderTypes> = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none', left: '0px' }}
      onClick={onClick}
    />
  );
};
const ImageSliderPicker: React.FC<IProps> = ({ onRemove, onSelectImage, images, title }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
  };

  return (
    <div>
      <h2> {title}</h2>
      <Slider {...settings}>
        {images?.map((url, index) => (
          <div key={url} className="card card-with-image p-2">
            <div
              onClick={() => onSelectImage?.(index)}
              className="card-image rounded"
              style={{
                cursor: 'pointer',
                position: 'relative',
                backgroundImage: `url(${getCompressedImageUrl(url)}?tr=w-300,h-300)`,
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSliderPicker;
