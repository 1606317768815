import { useTranslation } from 'react-i18next';
import { IBookingConfirmation } from 'types/IBookingConfirmation';
export type TravellerType = {
  id: string;
  firstName: string;
  lastName: string;
  civility?: 'Mr' | 'Mrs' | 'Ms' | undefined;
  passport?: string | undefined;
  passportDeliveryDate?: string | undefined;
  birthDate?: string | undefined;
  type: 'Adult' | 'Child';
  nationality?: string | undefined;
};
export type TravellerElement = {
  id: string;
  elementName?: string;
  travellers?: TravellerType[];
};
export const useReservationConfirmationCards = (booking: IBookingConfirmation) => {
  const {
    reservationFormDetails: { travellers },
    selectedRooms,
    selectedAddons,
    formattedData: { hotelsData, addons },
  } = booking;
  const [t] = useTranslation();
  const roomsArray: TravellerElement[] = [];

  hotelsData.forEach((data) =>
    data.rooms?.forEach((room) =>
      roomsArray.push({
        travellers: travellers.filter((traveller) =>
          room.travellersIds.find((tr) => tr === traveller.id),
        ),
        id: room.code,
        elementName: selectedRooms.find((sRoom) => sRoom.roomCode === room.code)?.description,
      }),
    ),
  );

  const addonsArray: TravellerElement[] = [];

  addons?.forEach((addon) =>
    addonsArray.push({
      id: addon.code,
      travellers: travellers.filter((traveller) =>
        addon.travellersIds.find((tr) => tr === traveller.id),
      ),
      elementName: selectedAddons.find((sAddon) => sAddon.id === addon.code)?.description,
    }),
  );

  return { roomsArray, addonsArray, t };
};
