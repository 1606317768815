import React from 'react';
import { Link } from 'react-router-dom';
import { useFooter } from './useFooter';

const FooterInfo = () => {
  const { isProductPage } = useFooter();

  return (
    <div className={`bg-primary px-md-0 px-3 ${isProductPage ? 'product-page-spacing' : ''}`}>
      <div className="container  py-5 text-white d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-md-5 mb-3">
          <div className="px-md-3 mx-auto  ml-md-0 mb-4 mb-md-0">
            <Link to="/">
              <img
                src="/assets/svg/logos/logo-white-with-accent.svg"
                alt="KeplerLogo"
                style={{ width: '100px' }}
              />
            </Link>
          </div>
          <div className="col-12 col-md-9 ">
            <p className="h6 text-white font-weight-normal">
              KEPLER travel est un tour-opérateur spécialiste des circuits, des séjours
              balnéaires, des combinés sur plusieurs destinations à travers le monde. Nous
              donnons accès à tous nos partenaires et clients des packages inédits, disponibles
              immédiatement et permettant de réserver des voyages avec une grande flexibilité à
              des prix très attractifs.
            </p>
          </div>
        </div>
        <div className=" flex-wrap d-flex justify-content-between align-items-start w-100 ">
          <div className="col-12 col-md-3 px-md-0 text-center text-md-left mb-3">
            <p className="h6 text-white ">Paiement sécurisé</p>
            <img
              src="https://back-selectour.orchestra-platform.com/admin/TS/fckUserFiles/Content_Image/refonte/3d-secure.png"
              alt="3d-secure"
            />
          </div>
          <div className="col-12 col-md-9 px-md-0 flex-wrap d-md-flex justify-content-md-between text-center text-md-left">
            <div className="mb-3 w-auto" style={{ marginLeft: '15px' }}>
              <p className="h6 text-white ">Moyens de paiements acceptés</p>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                <img
                  src="/assets/svg/payment-icons/visa.svg"
                  width="50px"
                  alt="Visa"
                  className="m-1"
                />
                <img
                  src="/assets/svg/payment-icons/mastercard.svg"
                  width="50px"
                  alt="ancv"
                  className="m-1"
                />
                <img
                  src="/assets/svg/payment-icons/ancv.svg"
                  width="50px"
                  height="auto"
                  alt="ancv"
                  className="m-1"
                />
              </div>
            </div>
            <div className="d-flex flex-sm-row flex-column justify-content-center w-auto">
              <div className="mb-4 mb-md-0 d-flex flex-column flex-wrap px-3">
                <div>
                  <Link to="/" className="text-center text-md-left h6 text-white d-md-flex">
                    Accueil
                  </Link>
                </div>
                <div>
                  <Link
                    to="/qui-sommes-nous"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Qui Sommes Nous
                  </Link>
                </div>
                <div>
                  <Link
                    to="/Contact-us"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Contactez nous
                  </Link>
                </div>
              </div>
              <div className="mb-4 mb-md-0 d-flex flex-column flex-wrap">
                <div>
                  <Link
                    to="/products"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Nos Séjours
                  </Link>
                </div>
                <div>
                  {' '}
                  <Link
                    to="/products?product-type=tour"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Nos Circuits
                  </Link>
                </div>
                <div>
                  <Link
                    to="/products?product-type=hotel"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Nos Hôtels
                  </Link>
                </div>
              </div>

              <div className="mb-4 mb-md-0 d-flex flex-column flex-wrap px-3">
                <div>
                  <Link
                    to="/checking-reservation-details"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Consulter ma réservation
                  </Link>
                </div>
                {/* <div>
                  <Link to="/" className="text-center text-md-left h6 text-white d-md-flex">
                    Qui sommes-nous ?
                  </Link>
                </div> */}
                <div>
                  <Link
                    to="/terms-and-conditions"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Mentions légales
                  </Link>
                </div>
                <div>
                  <Link
                    to="/privacy-policy"
                    className="text-center text-md-left h6 text-white d-md-flex"
                  >
                    Politique de confidentialité
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
