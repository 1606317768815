import React from 'react';
import { TFlightResponse } from 'server/services/FlightsService';
import FlightLine from '../Flights/FlightLine';

interface IProps {
  flights:
    | {
        goingFlight: TFlightResponse;
        returningFlight: TFlightResponse;
      }
    | {
        oneWayFlights: TFlightResponse;
      };
}

const FligthSummary: React.FC<IProps> = ({ flights }) => {
  if ('oneWayFlights' in flights)
    return (
      <div className="my-3 mx-n3 border p-3">
        <h3>Vue d&apos;ensemble du voyage</h3>
        <FlightLine flightDetails={flights.oneWayFlights} />
      </div>
    );

  return (
    <div className="my-3 mx-n3 border p-3">
      <h3>Vue d&apos;ensemble du voyage</h3>
      <FlightLine flightDetails={flights.goingFlight} />
      <hr
        style={{
          borderTop: '1px solid #677788',
        }}
      />
      <FlightLine flightDetails={flights.returningFlight} isReturn />
    </div>
  );
};

export default FligthSummary;
