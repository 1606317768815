import { useAppSelector } from 'client/hooks/useAppSelector';
import { confirmBookingPayment } from 'client/_redux/actions/bookingPayment';
import { createReservationForm } from 'client/_redux/actions/createReservationForm';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IReservationForm } from 'types/IReservationForm';

const useReservationForm = () => {
  const dispatch = useDispatch();
  const formMethods = useForm();
  const match = useParams<{ permalink: string }>();
  const history = useHistory();

  const { handleSubmit } = formMethods;
  const { permalink } = match;

  const { selectedRooms } = useAppSelector(({ selectedTripReducer: { selectedRooms } }) => ({
    selectedRooms,
  }));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  const onSubmit: SubmitHandler<IReservationForm> = (values) => {
    dispatch(createReservationForm(values));
    dispatch(confirmBookingPayment());
    history.push(`/product/quotation/reservation-confirmation/`);
  };

  return {
    onSubmit,
    selectedRooms,
    permalink,
    handleSubmit,
    formMethods,
  };
};

export default useReservationForm;
