import { clientApi } from 'api';
import { DBDocument } from 'client/models/utils';
import { IFilter } from 'server/models/Filter/types';
import { ISearchCriteriaQuery } from 'types/ISearchCriteriaQuery';
export interface IProductSearchCriteriaValueResponse extends DBDocument {
  filterProducts: (IFilter & DBDocument)[];
  productsCriteria: ISearchCriteriaQuery;
}
export const getProductfilter = (isServer = false) => async () => {
  const response = await clientApi.get<IProductSearchCriteriaValueResponse>(
    `/filter-products/`,
  );

  return response;
};
