import { getBookedFLights } from 'client/_redux/services/flights';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IBookedFlightDocument } from 'server/models/BookedFlights/types';

export const useFlightReservationDetails = () => {
  const [flights, setFlights] = useState<IBookedFlightDocument>();
  const { flightId } = useParams<{
    flightId: string;
  }>();
  const [t] = useTranslation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  useEffect(() => {
    const fetchFlightsDetails = async () => {
      const result = await getBookedFLights(flightId);

      setFlights(result);
    };

    fetchFlightsDetails();
  }, [flightId]);

  return {
    passengers: flights?.flights?.[0].passengers,
    flights,
    t,
  };
};
