import { ICheckingReservationForm } from 'client/containers/CheckingReservationForm/CheckingReservationForm';
import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import * as checkingFormServices from 'client/_redux/services/checkingReservationDetails/sendCheckingReservation';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { startLoading, stopLoading } from '../loading';

export const sendLink = (
  value: ICheckingReservationForm,
  history: RouteComponentProps['history'],
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await checkingFormServices.sendCheckingReservationDetails()(value);

    if (response.data === 'isFlight')
      history.push(`/flight-reservation-details/${value.bookingId}`);
    if (response.data === 'isBooking') history.push(`/reservation-details/${value.bookingId}`);
    else
      toast(
        'Vous allez recevoir un email contenant le lien pour visualiser votre réservation!',
        { type: 'success' },
      );
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
