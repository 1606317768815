import { PromotionTypes } from 'dashboard/_redux/types/promotionTypes';
import { IReduxAction } from 'types/IReduxAction';
import { IWithDBDocument } from 'client/models/utils';
const initialState: PromotionState | null = null;

export interface PromotionState {
  title: string;
  url: string;
  description: string;
  image: string | File;
}

export default (
  state: IWithDBDocument<PromotionState> | null = initialState,
  action: IReduxAction,
): IWithDBDocument<PromotionState> | null => {
  const { type, payload } = action;

  switch (type) {
    case PromotionTypes.GET_PROMOTION:
      return state ? { ...state, ...payload } : payload;
    case PromotionTypes.SET_PROMOTION:
      return state;
    default:
      return state;
  }
};
