import { clientApi } from 'api';
import { IWithDBDocument } from 'client/models/utils';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
export const updateOneExhibitedProduct = (isServer = false) => async (
  _$id: string,
  payload?: Partial<IExhibitedProduct>,
) => {
  const response = await clientApi.patch<IWithDBDocument<IExhibitedProduct>>(
    `/exhibited-products?_$id=${_$id}`,
    {
      ...payload,
    },
  );

  return response;
};
