import { ICheckingReservationForm } from 'client/containers/CheckingReservationForm/CheckingReservationForm';
import { sendLink } from 'client/_redux/actions/checkingReservationDetails';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const useCheckingReservationDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onSubmit = (value: ICheckingReservationForm) => {
    dispatch(sendLink(value, history));
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);

  return { onSubmit };
};

export default useCheckingReservationDetails;
