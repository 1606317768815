import { useAppSelector } from 'client/hooks/useAppSelector';
import { useQuery } from 'client/hooks/useQuery';
import { getDetailedFlights, setSelectedFlights } from 'client/_redux/actions/flights';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
export const useFlights = () => {
  const history = useHistory();
  const {
    seats,
    luggage,
    departure,
    destination,
    kids,
    babies,
    'start-date': departureDate,
    'via-airports': viaAirports,
    'returning-date': returningDate,
  } = useQuery([
    'seats',
    'luggage',
    'departure',
    'start-date',
    'destination',
    'via-airports',
    'returning-date',
    'kids',
    'babies',
  ]) as Record<string, string>;
  const dispatch = useDispatch();
  const rawData = useAppSelector((sel) => sel.flightsReducer.flights);
  const isLoading = useAppSelector((sel) => sel.loading.loading);
  const handleFlightSelection = useCallback(
    (flight: { typeOfFlight: 'Single' | 'Going' | 'Returning'; _id: string }) => {
      dispatch(setSelectedFlights(flight));
    },
    [dispatch],
  );

  const handleSubmitFlights = () => {
    history.push(
      `/passenger-details?${seats ? `seats=${seats}` : 'seats=1'}${
        kids && Number(kids) > 0 ? `&kids=${kids}` : ''
      }${babies && Number(babies) > 0 ? `&babies=${babies}` : ''}`,
    );
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  useEffect(() => {
    dispatch(
      getDetailedFlights({
        seats,
        luggage,
        departure,
        viaAirports,
        destination,
        departureDate,
        returningDate,
        isRoundTrip: !!returningDate,
      }),
    );
  }, [
    seats,
    luggage,
    dispatch,
    departure,
    destination,
    viaAirports,
    departureDate,
    returningDate,
  ]);

  return {
    isLoading,
    seats,
    departure,
    destination,
    departureDate,
    returningDate,
    isRoundTrip: !!returningDate,
    // flights,
    // dataCheck,
    // restOfFlights,
    // cheapestFlight,
    // selectedFlights,
    // restOfGoingFlights,
    // cheapestGoingFlight,
    handleSubmitFlights,
    handleFlightSelection,
    flights: rawData,
    // restOfreturningFlights,
    // cheapestreturningFlight,
  };
};
