import { ProductTypes } from 'client/_redux/types/productTypes';
import { IReduxAction } from 'types/IReduxAction';

const initialState = '';

export default (state: string = initialState, { type, payload }: IReduxAction): string => {
  switch (type) {
    case ProductTypes.SET_PRODUCT_LIST_QUERY:
      return payload;
    default:
      return state;
  }
};
