import { useAppSelector } from 'client/hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
export const usePriceDetails = () => {
  const [t] = useTranslation();

  const {
    booking: { payment },
  } = useAppSelector((state) => state.bookingFormReducer);

  return {
    t,
    priceDetails: payment.priceDetails,
  };
};
