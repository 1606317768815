import { IReduxAction } from 'types/IReduxAction';
import { ProductInfoTypes } from 'dashboard/_redux/types/productInfoTypes';
import { IProductInfo } from 'server/models/ProductInfo/types';
import { ProductTypes } from 'client/_redux/types/productTypes';

const initialState = {
  loading: true,
  productInfo: {} as IProductInfo,
  isPreloaded: false,
};

export interface IProductInfoInterface {
  productInfo: IProductInfo | null;
  loading?: boolean;
  isPreloaded?: boolean;
}

export default (
  state: IProductInfoInterface = initialState,
  { type, payload }: IReduxAction,
): IProductInfoInterface => {
  switch (type) {
    case ProductInfoTypes.GET_PRODUCT_INFO_START:
      return { ...state, loading: true };
    case ProductInfoTypes.GET_PRODUCT_INFO_FAILED:
      return { ...state, loading: false };
    case ProductInfoTypes.GET_PRODUCT_INFO_SUCCESS:
      return {
        ...state,
        productInfo: payload,
        loading: false,
      };
    case ProductTypes.CLEAR_PRODUCT:
      return {
        ...state,
        productInfo: {} as IProductInfo,
        loading: false,
      };
    case ProductInfoTypes.ADD_PRODUCT_INFO_START:
      return { ...state, loading: true };
    case ProductInfoTypes.ADD_PRODUCT_INFO_FAILED:
      return { ...state, loading: false };
    case ProductInfoTypes.ADD_PRODUCT_INFO_SUCCESS:
      return { ...state, productInfo: payload, loading: false };
    case ProductInfoTypes.UPDATE_PRODUCT_INFO_START:
      return { ...state, loading: true };
    case ProductInfoTypes.UPDATE_PRODUCT_INFO_FAILED:
      return { ...state, loading: false };
    case ProductInfoTypes.UPDATE_PRODUCT_INFO_SUCCESS:
      return { ...state, productInfo: payload, loading: false };
    case ProductInfoTypes.DELETE_PRODUCT_INFO_START:
      return { ...state, loading: true };
    case ProductInfoTypes.DELETE_PRODUCT_INFO_FAILED:
      return { ...state, loading: false };
    case ProductInfoTypes.DELETE_PRODUCT_INFO_SUCCESS:
      return { loading: false, productInfo: {} as IProductInfo };

    default:
      return state;
  }
};
