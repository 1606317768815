const columns = (lastName: string, firstName: string, Email: string) => {
  const data = [
    {
      name: `${lastName}`,
      selector: 'lastName',
      sortable: true,
    },
    {
      name: `${firstName}`,
      selector: 'firstName',
      sortable: true,
    },
    {
      name: `${Email}`,
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Banned',
      selector: 'isBanned',
    },
  ];

  return data;
};

export default columns;
