import BookingCard from 'client/components/BookingCard';
import React from 'react';
import { FlightMajorObject } from 'server/helpers/soap/formatCreateBookingSoapResponse/types';
import { ITransaction } from 'server/models/Transaction/types';
import FlightDetailsContainer from '../FlightDetailsContainer';
import { useReservationDetailsCards } from './useReservationDetailsCards';
interface IReservationDetailsRoom {
  transaction: ITransaction;
}
const ReservationDetailsCards: React.FC<IReservationDetailsRoom> = ({ transaction }) => {
  const { roomsArray, addonsArray, t } = useReservationDetailsCards(transaction);

  return (
    <>
      <b className="row ml-5 mt-3">{t('reservationDetails.rooms')}</b>
      <BookingCard elements={roomsArray} />
      {addonsArray[0] && (
        <>
          <b className="row ml-5">{t('reservationDetails.addons')}</b>
          <BookingCard elements={addonsArray} isAddon />
        </>
      )}
      {transaction.bookingResponse?.bookingDetails.flightDetails && (
        <>
          <b className="row ml-5">{t('reservationDetails.flight')}</b>
          <FlightDetailsContainer
            isReservationDetailsPage
            flightDetails={
              transaction.bookingResponse?.bookingDetails.flightDetails as FlightMajorObject
            }
          />
        </>
      )}
    </>
  );
};

export default ReservationDetailsCards;
