import { IAddon } from 'types/ICreateBookingRequest';
import { IReservationForm } from 'types/IReservationForm';

export const getFormattedAddons = (addonList: IReservationForm['addonList']) => {
  const addons: IAddon[] = [];

  if (addonList !== undefined && addonList.length > 0) {
    addonList.map((el) => {
      const addon = { travellersIds: el.family, code: el.addonRef };

      addons.push(addon);

      return addons;
    });

    return addons;
  }

  return addons;
};
