import React from 'react';

const Loader: React.FC = () => (
  <svg
    data-testid="LocationBar-Loader"
    role="img"
    width={100}
    height={15}
    aria-labelledby="loading-ariaLocation"
    viewBox="0 0 auto auto"
    preserveAspectRatio="none"
  >
    <title id="loading-ariaLocation">Loading...</title>
    <rect
      x={0}
      y={0}
      width="100%"
      height="100%"
      clipPath="url(#clip-pathLocation)"
      style={{
        fill: 'url("#fillLocation")',
      }}
    />
    <defs>
      <clipPath id="clip-pathLocation">
        <rect x={0} y={0} rx={8} ry={8} width="100%" height="100%" />
      </clipPath>
      <linearGradient id="fillLocation">
        <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export default Loader;
