export const largeScreenClassNameList = [
  'first-icon',
  'second-icon',
  'forth-block-icon',
  'fifth-block-icon',
  'main-block ',
  'sixth-block-icon',
  'seven-block-icon',
  'eight-block-icon',
];
export const mobileScreenClassNameList = [
  'first-image-block',
  'second-image-block',
  'thrid-image-block',
  'forth-image-block',
  'fifth-image-block',
  'sixth-image-block',
  'seventh-image-block',
  'last-image-block',
];
