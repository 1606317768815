/* eslint-disable react/no-array-index-key */
import FormSelect from 'client/components/Form/FormSelect';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedRoom } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { ISelectorOption } from 'types/ISelectorOption';

const FormSelectFamilyOptions = ({
  selectedRooms,
  adultOptions,
  childOptions,
}: {
  selectedRooms: FormattedRoom[];
  adultOptions: ISelectorOption<string>[];
  childOptions: ISelectorOption<string>[];
}) => {
  const [t] = useTranslation();

  return (
    <>
      <h2 className="w-100 text-success py-2">{t('room.formReservation.dispatching')} : </h2>
      <div className="p-3 m-2 d-flex flex-wrap border">
        {selectedRooms.map((room, roomIndex) => (
          <div
            className="d-flex flex-row align-items-center col-12 col-md-6 "
            key={`room-${roomIndex}`}
          >
            <div className="p-1 m-1 w-100 ">
              <h3>
                {t('room.formReservation.Place')} {roomIndex + 1}
              </h3>
              <h4>
                {room.adultCount} {t('room.Adult')} {room.childCount} {t('room.child')}{' '}
                {room.description}
              </h4>
              {Array(room.adultCount)
                .fill(null)
                .map((adult, adultIndex) => (
                  <FormSelect
                    forceNullSelectedValue={false}
                    label={`Adult ${adultIndex + 1}`}
                    options={adultOptions}
                    name={`roomList[${roomIndex}].adults[${adultIndex}]`}
                    key={`${roomIndex}-${adultIndex}`}
                    validate={Validators([{ validation: 'required' }])}
                  />
                ))}
              {Array(room.childCount)
                .fill(null)
                .map((child, childIndex) => (
                  <FormSelect
                    forceNullSelectedValue={false}
                    isClearable
                    label={`Child ${childIndex + 1}`}
                    options={childOptions}
                    name={`roomList[${roomIndex}].children[${childIndex}]`}
                    key={`${roomIndex}-${childIndex}`}
                    validate={Validators([{ validation: 'required' }])}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FormSelectFamilyOptions;
