import Input from 'client/components/Form/Input';
import SectionTitle from 'client/components/SectionTitle';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { ArrayField } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextEditor from '../TextEditor';

type TextFieldAndInputProps = {
  fields: Partial<ArrayField<Record<string, string>, 'id'>>[];
  removeSection: (index?: number | number[] | undefined) => void;
  addSection: (
    value: Partial<Record<string, string>> | Partial<Record<string, string>>[],
  ) => void;
  title: string;
  type?: boolean;
};

const TextFieldAndInput: React.FC<TextFieldAndInputProps> = ({
  fields,
  removeSection,
  addSection,
  title,
  type,
}) => {
  const [t] = useTranslation();

  return (
    <div>
      <SectionTitle title={title} />

      {fields.map((item, index) => (
        <div key={item.id}>
          <Input
            name={`${type ? 'description' : 'formalities'}.${index}.title`}
            label={t('productDetails.title')}
            validate={Validators([{ validation: 'required' }])}
          />
          <TextEditor
            name={`${type ? 'description' : 'formalities'}.${index}.content`}
            label="Description"
          />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => removeSection(index)}
          >
            {t('productDetails.deleteField')}
          </button>
          <hr />
        </div>
      ))}

      <div className=" text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addSection({ title: '', content: '' })}
        >
          {t('productDetails.addField')}
        </button>
      </div>
    </div>
  );
};

export default TextFieldAndInput;
