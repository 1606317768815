import { BookingFormTypes } from 'client/_redux/types/bookingFormTypes';
import { IBookingConfirmation } from 'types/IBookingConfirmation';
import { IReduxAction } from 'types/IReduxAction';

const initialState = {
  booking: {} as IBookingConfirmation,
};

export interface ICreateBookingInterface {
  booking: IBookingConfirmation;
}

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): ICreateBookingInterface => {
  switch (type) {
    case BookingFormTypes.BOOKING_FORM_SUCCESS:
      return { ...initialState, booking: payload };

    default:
      return state;
  }
};
