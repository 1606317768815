import { IEntitiesTypes } from 'dashboard/_redux/types/entitiesTypes';
import { IThunkAction } from 'types/IThunkAction';
import * as EntityService from 'dashboard/_redux/services/entities';
import * as CountryService from 'dashboard/_redux/services/countries';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import { CountriesTypes } from 'dashboard/_redux/types/countriesTypes';
import { toast } from 'react-toastify';
import { IDestinationFormValues } from 'dashboard/containers/AddProductForm/useAddProductForm';
export const getCountriesNames = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await EntityService.getEntities()(['Country']);

    dispatch({
      type: IEntitiesTypes.GET_COUNTRIES_LIST_SUCCESS,
      payload: res.data
        .find((el) => (el._id = 'Country'))
        ?.entity.map((el) => ({ name: el.title, code: el._$ID })),
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const addCountry = (data: IDestinationFormValues): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await CountryService.addCountry()(data);

    dispatch({
      type: CountriesTypes.ADD_COUNTRY_SUCCESS,
      payload: res.data,
    });
    toast('Country added with success');
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const updateCountry = (
  data: IDestinationFormValues,
  id?: string,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await CountryService.updateCountry()(data, id);

    dispatch({
      type: CountriesTypes.UPDATE_COUNTRY_SUCCESS,
      payload: res.data,
    });
    toast('Country updated with success');
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const getCountries = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await CountryService.getCountries()();

    dispatch({
      type: CountriesTypes.GET_COUNTRIES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const deleteCountry = (id?: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await CountryService.deleteCountry()(id);

    dispatch({
      type: CountriesTypes.DELETE_COUNTRY_SUCCESS,
      payload: id,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
