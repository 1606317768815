import React, { ChangeEvent } from 'react';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import { FlightFilterType } from 'client/components/FlightsTopFilter/useFlightsTopFilter';
import Input from '../Input';
import { useAutoComplete } from './useAutoComplete';
export type ICountryAirports = {
  code: string;
  name: string;
  city: string;
  country: string;
  state: string | null;
};
type AutoCompleteProps = {
  data: ICountryAirports[];
  placeholder?: string;
  name?: 'from' | 'destination';
  id?: string;
  isSearchBar?: boolean;
  className?: string;
  defaultValue?: string;
  outerState?: FlightFilterType;
  setOuterState?: (data: FlightFilterType) => void;
  value?: string;
};

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  data,
  placeholder,
  name,
  id,
  isSearchBar,
  className,
  defaultValue,
  setOuterState,
  outerState,
  value,
}) => {
  const {
    onTextChanged,
    suggestionSelected,
    isComponentVisible,
    setIsComponentVisible,
    suggestions,
    searchText,
    suggestionHeaderSelected,
  } = useAutoComplete({ data, defaultValue, setOuterState, name, value, outerState });

  return (
    <div className="position-relative " data-testid="AutoComplete-ParentDiv">
      <div onClick={() => setIsComponentVisible(false)} />
      <div className="m-0">
        <Input
          id={id}
          className={className}
          isSearchBar={isSearchBar}
          placeholder={placeholder || 'destination'}
          name={name || ''}
          autoComplete="off"
          onFocus={() => onTextChanged(searchText || '')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onTextChanged(e.target.value)}
          value={upperCaseFirstLetter(searchText || '')}
          type="text"
        />
        {suggestions.size > 0 && isComponentVisible && (
          <>
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 0,
              }}
              onClick={() => setIsComponentVisible(false)}
            >
              click me to exit
            </div>
            <div className="autoCompleteContainer p-1" data-testid="Suggestions-Section">
              {Array.from(suggestions.entries()).map((el) => (
                <div className=" my-3" key={el[0]}>
                  <div
                    className="autoCompleteItem pt-2 px-2 h4"
                    style={{ color: '#677788' }}
                    onClick={() => suggestionHeaderSelected(el[0])}
                  >
                    <i className="fas fa-map-marker-alt mr-2" />
                    {el[0]} <small>(Tous les aeroports)</small>
                  </div>
                  {el[1].map((e) => (
                    <div
                      className="autoCompleteItem m-2"
                      onClick={() => suggestionSelected(e.item)}
                      key={e.item.code}
                    >
                      <i className="fas fa-plane mr-2" />
                      <b className="mr-2">{e.item.city}</b>
                      {e.item.name}
                      <small className="ml-2">{e.item.code}</small>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
