import { clientApi } from 'api';
import { IFormattedPrice } from 'server/helpers/soap/formatPricesSoapResponse/types';
import { ICreateBookingBody } from 'types/ICreateBookingRequest';

export const confirmBooking = (isServer = false) => async (
  formattedData: ICreateBookingBody,
) => {
  const res = await clientApi.post<IFormattedPrice>('/payments/confirm', formattedData);

  return res.data;
};
export const createBooking = (isServer = false) => async (
  formattedData: ICreateBookingBody,
) => {
  const res = await clientApi.post<{ redirectionUrl: string }>(
    '/payments/create',
    formattedData,
  );

  return res.data;
};
