// import 'styles/scss/index.scss'
import { Route, Switch } from 'react-router-dom';
import Home from 'client/pages/Home';
import Product from 'client/pages/Product';
import ProductList from 'client/pages/ProductList';
import * as React from 'react';
import QuotedAvailability from 'client/pages/QuotedAvailability';
import StepOne from 'client/pages/StepOne';
import ReservationForm from 'client/pages/ReservationForm';
import TermsAndConditions from 'client/pages/TermsAndConditions';
import ContactUs from 'client/pages/ContactUs';
import QuiSommesNous from 'client/pages/QuiSommesNous';
import Flights from 'client/pages/Flights';
import CheckingReservationDetails from 'client/pages/CheckingReservationForm';
import ReservationDetails from 'client/pages/ReservationDetails';
import PaymentError from 'client/pages/PaymentError';
import ReservationConfirmation from 'client/pages/ReservationConfirmation';
import NotFound from 'client/pages/NotFound';
import SafeAreaView from 'client/components/SafeAreaView';
import Blog from 'client/pages/Blog';
import Article from 'client/pages/Article';
import FlightsForm from 'client/pages/FlightsForm';
import FlightsConfirmation from 'client/pages/FlightsConfirmation';
import FlightsReservationDetails from 'client/pages/FlightsReservationDetails';
import PrivacyPolicy from 'client/pages/PrivacyPolicy';
import { useAppRouter } from './useAppRouter';
import Header from '../Header';
import Footer from '../Footer';
const Root = () => {
  const { initiated } = useAppRouter();

  return initiated ? (
    <SafeAreaView>
      <Header />
      <div>
        <Switch>
          <Route exact path="/product/:permalink" component={Product} />
          <Route exact path="/product/:permalink/quotation" component={QuotedAvailability} />
          <Route
            exact
            path="/product/:permalink/quotation/reservation-Form"
            component={ReservationForm}
          />
          <Route
            exact
            path="/product/quotation/reservation-confirmation"
            component={ReservationConfirmation}
          />
          <Route exact path="/product/:permalink/step-1" component={StepOne} />
          <Route
            exact
            path="/reservation-details/:transactionId"
            component={ReservationDetails}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/Contact-us" component={ContactUs} />
          <Route exact path="/qui-sommes-nous" component={QuiSommesNous} />
          <Route exact path="/products" component={ProductList} />
          <Route exact path="/" component={Home} />
          <Route exact path="/hello-world" component={Home} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:id" component={Article} />
          <Route path="/checking-reservation-details" component={CheckingReservationDetails} />
          <Route path="/payment-error" component={PaymentError} />
          <Route path="/payment-abort" component={PaymentError} />
          <Route path="/flights" component={Flights} />
          <Route path="/passenger-details" component={FlightsForm} />
          <Route path="/flights-confirmation" component={FlightsConfirmation} />
          <Route
            path="/flight-reservation-details/:flightId/"
            component={FlightsReservationDetails}
          />
          <Route path="/404-not-found" component={NotFound} />
          <Route path="/*" component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </SafeAreaView>
  ) : (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  );
};

export default Root;
