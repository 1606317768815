import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { objectToQueryString } from 'client/helpers/objectToQueryString';

export const useRouteQuery = () => {
  const history = useHistory();
  const query = history.location.search;
  const queryObject = queryStringToObject(query);
  const addQuery = useCallback(
    (newQuery: Record<string, string | number | (string | number)[]>) => {
      Object.keys(newQuery).forEach((key) => {
        queryObject[key] = newQuery[key];
      });

      history.push({
        pathname: history.location.pathname,
        search: objectToQueryString(queryObject),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );
  const getNewQueryUrl = useCallback(
    (newQuery: Record<string, string | number | (string | number)[]>) => {
      Object.keys(newQuery).forEach((key) => {
        queryObject[key] = newQuery[key];
      });

      return `${history.location.pathname}${objectToQueryString(queryObject)}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );
  const removeQuery = useCallback(
    (query: string | string[]) => {
      if (Array.isArray(query)) {
        query.forEach((q) => {
          delete queryObject[q];
        });
      } else delete queryObject[query];

      history.push({
        pathname: history.location.pathname,
        search: objectToQueryString(queryObject),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  const setQuery = useCallback(
    (query: Record<string, string | number | (string | number)[]>) => {
      history.push({
        pathname: history.location.pathname,
        search: objectToQueryString(query),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  return { addQuery, removeQuery, setQuery, getNewQueryUrl };
};
