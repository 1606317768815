import AddAndUpdateFilterProductsForm from 'dashboard/containers/AddAndUpdateFilterProductsForm';
import React from 'react';
import useAddProductFilter from './useAddProductFilter';

const AddProductFilter = () => {
  const { onSubmit } = useAddProductFilter();

  return <AddAndUpdateFilterProductsForm onSubmit={onSubmit} />;
};

export default AddProductFilter;
