import Pagination from 'client/components/Pagination';
import ProductCard from 'client/components/ProductCard';
import React from 'react';
import { Link } from 'react-router-dom';
import { useEditProductList } from './useEditProductList';

interface IProps {
  pathName?: string;
}
const EditProductList: React.FC<IProps> = ({ pathName = false }) => {
  const { exhibitedProducts } = useEditProductList();

  return (
    <div>
      <div className="d-flex justify-content-around flex-wrap">
        {exhibitedProducts?.items?.map(
          ({ _$id, name, media, permalink, mainMedia, ...product }) => (
            <Link
              key={_$id}
              to={
                pathName === '/dashboard/product/edit'
                  ? `/dashboard/product/edit/${_$id}`
                  : `/dashboard/product-details/edit/${_$id}/${permalink}`
              }
              style={{ width: '33%', cursor: 'pointer' }}
            >
              <ProductCard
                title={name}
                price={product.bestPrice.value}
                image={mainMedia || media?.[0]}
                category="type"
              />
            </Link>
          ),
        )}
      </div>
      <Pagination
        page={exhibitedProducts?.page || 1}
        totalPages={exhibitedProducts?.totalPages || 0}
      />
    </div>
  );
};

export default EditProductList;
