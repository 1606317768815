/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

const FooterContact = () => (
  <div style={{ background: 'rgb(0 0 64)' }} className="px-md-0 px-3">
    <div className="container  text-white flex-wrap py-3 d-flex align-items-center justify-content-between">
      <div className="h4 text-white mb-3 m-md-0">
        Restez connecté à toutes nos actualités !
      </div>
      <div className=" d-flex align-items-center justify-content-center justify-content-md-end flex-wrap responsive-w">
        {/* <div
          className="d-flex mb-3 m-md-0 w-md-50 w-80 mr-0"
          style={{ height: '45px', borderRadius: '22px', background: 'white' }}
        >
          <input
            type="text"
            className="text-primary border-0 bg-transparent px-5 w-100"
            placeholder="mail@mail.com"
            style={{
              height: '45px',
            }}
          /> */}
          {/* TODO:onClick() */}
          {/* <button
            type="button"
            style={{
              width: '45px',
              height: '45px',
              background: '#4D3191',
            }}
            className="border-0 text-white rounded-circle d-flex align-items-center justify-content-center"
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div> */}
        <div className="text-center  mx-auto mr-md-0 ml-md-5">
          <a
            aria-label="Facebook"
            target="__blank"
            href="https://www.facebook.com/Kepler-Travel-112170487896822"
            className="fab fa-facebook-f h2 text-white px-md-2 px-4"
          />
          {/* <i className="fab fa-twitter h2 text-white  px-md-2 px-4" /> */}
          <a
            aria-label="Instagram"
            target="__blank"
            href="https://www.instagram.com/kepler.travel"
            className="fab fa-instagram h2 text-white  px-md-2 px-4"
          />
             <a
            aria-label="LinkedIn"
            target="__blank"
            href="https://fr.linkedin.com/company/kepler-travel"
            className="fab fa-linkedin-in h2 text-white  px-md-2 px-4"
          />
        </div>
      </div>
    </div>
  </div>
);

export default FooterContact;
