export const swapArrayElements = (arr?: string[], indexA?: number, indexB?: number) => {
  if (arr && indexB) {
    const temp = arr[indexA as number];

    arr[indexA as number] = arr[indexB as number];
    arr[indexB] = temp;

    return arr;
  }

  return undefined;
};
