/* eslint-disable max-lines */
import Collapsible from 'client/components/Collapsible';
import FlightDetailsCard from 'client/components/FlightDetailsCard';
import { formatDayMonthYear, formatHourAndMinutes } from 'client/helpers/format/timeFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransportationDetails } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
interface IFlightDetailCollapsibleProps {
  tripDetail: TransportationDetails[];
}
const FlightDetailCollapsible: React.FC<IFlightDetailCollapsibleProps> = ({ tripDetail }) => {
  const [t] = useTranslation();

  return (
    <Collapsible
      isClicked
      title={
        <div className="row align-items-center">
          <div className="col-12 col-md-3 pl-md-5">
            {tripDetail.map(({ carrier }) => (
              <img
                className="pl-3"
                src={`https://images.kiwi.com/airlines/64/${carrier.code}.png`}
                alt={carrier.code}
                height={25}
                key={JSON.stringify(carrier)}
              />
            ))}
          </div>
          {tripDetail[0] && (
            <div className="col-12 col-md-9 my-2 my-md-0 d-flex justify-content-around">
              <span className="col">{formatDayMonthYear(tripDetail[0].start)}</span>
              <span className="d-none d-md-block col-md ">
                <div>{formatHourAndMinutes(tripDetail[0].start)}</div>
                <div>{tripDetail[0].from.code}</div>
              </span>
              <span className="d-none d-md-block col-md">
                <div>{formatHourAndMinutes(tripDetail[tripDetail.length - 1].end)}</div>
                <div>{tripDetail[tripDetail.length - 1].to.code}</div>
              </span>
              {tripDetail.length - 1 === 0 ? (
                <span className="col ">{t('FlightDetailsCard.nonstop')}</span>
              ) : (
                <span className="col ">
                  {tripDetail.length - 1}{' '}
                  {tripDetail.length - 1 === 1
                    ? t('FlightDetailsCard.stopover')
                    : t('FlightDetailsCard.stopovers')}
                </span>
              )}
            </div>
          )}
          {tripDetail[0] && (
            <span className="col-12 d-block d-md-none d-flex justify-content-around">
              <span className="col">
                {formatHourAndMinutes(tripDetail[0].start)} {tripDetail[0].from.code}
              </span>
              <span className="col">
                {formatHourAndMinutes(tripDetail[tripDetail.length - 1].end)}{' '}
                {tripDetail[tripDetail.length - 1].to.code}
              </span>
            </span>
          )}
        </div>
      }
      open={false}
      key={JSON.stringify(tripDetail)}
    >
      {tripDetail.map((vol) => (
        <FlightDetailsCard
          flightDetails={vol}
          key={`${vol.start}${vol.end}${vol.flightNumber}`}
        />
      ))}
    </Collapsible>
  );
};

export default FlightDetailCollapsible;
