import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import SelectListModal from 'client/components/Form/SelectListModalPrices/SelectListModal';

interface IProps {
  options: { date?: Date; value?: string | number }[];

  label?: string;
  name: string;
  isMinimumPrice?: number;

  handleClose?: () => void;
  setWatchPrice: Dispatch<SetStateAction<string | number | undefined>>;
}

const FormSelectList: React.FC<IProps> = ({
  options,
  name,
  handleClose,
  isMinimumPrice,
  setWatchPrice,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <SelectListModal
          handleClose={handleClose}
          handleSelect={onChange}
          options={options}
          isMinimumPrice={isMinimumPrice}
          value={value}
          setWatchPrice={setWatchPrice}
        />
      )}
    />
  );
};

export default FormSelectList;
