import { ICreateBookingBody } from 'types/ICreateBookingRequest';
import { IReservationForm } from 'types/IReservationForm';

export const getFormattedTravellers = (travellers: IReservationForm['travellers']) => {
  const adults: ICreateBookingBody['adults'] = [];
  const children: ICreateBookingBody['children'] = [];

  travellers.forEach((el) => {
    if (el.type === 'Adult') {
      const adult = {
        id: el.id,
        birthDate: el.birthDate,
        name: { firstName: el.firstName, lastName: el.lastName },
        title: el.civility,
        passport: el.passport,
        passportDeliveryDate: el.passportDeliveryDate,
        type: el.type,
        nationality: el.nationality,
      };

      adults.push(adult);
    }
    if (el.type === 'Child') {
      const child = {
        id: el.id,
        birthDate: el.birthDate,
        name: { firstName: el.firstName, lastName: el.lastName },
        title: el.civility,
        passport: el.passport,
        passportDeliveryDate: el.passportDeliveryDate,
        type: el.type,
        nationality: el.nationality,
      };

      children.push(child);
    }
  });

  return { adults, children };
};
