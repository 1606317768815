import { IThunkAction } from 'types/IThunkAction';
import * as ProductService from 'client/_redux/services/product';
import { errorHandler } from 'client/helpers/errorHandler';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
import { ExhibitedProductDashboardTypes } from 'dashboard/_redux/types/productTypes';

import { startLoading, stopLoading } from '../loading';
export const updateExhibitedProduct = (
  id?: string,
  payload?: Partial<IExhibitedProduct>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());

  try {
    const res = await ProductService.updateOneExhibitedProduct()(id as string, payload);

    dispatch({
      type: ExhibitedProductDashboardTypes.UPDATE_EXHIBITED_PRODUCT_SUCCESS,
      payload: res.data,
    });
    toast('updated successfully');
  } catch (error) {
    errorHandler(error as AxiosError);
  }

  dispatch(stopLoading());
};
