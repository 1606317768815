/* eslint-disable max-lines */
import { AddonFormValues } from 'client/containers/QuotedAvailability/ProductAddons/useProductAddons';
import { IQuotedAvailabilityState } from 'client/_redux/reducers/quotedAvailability';
import {
  FormattedRoom,
  Price,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { ICreateBookingBody } from 'types/ICreateBookingRequest';
import { PaymentTypes } from 'types/IPayment';
import { IReservationForm } from 'types/IReservationForm';
import { getFormattedAddons } from './getFormattedAddons';
import { getFormattedTravellers } from './getFormattedTravellers';
import { getHotelFormattedData } from './getHotelFormattedData';
import { getTotalPrice } from './getTotalPrice';

const formatFinalRservationData = (
  reservationFormData: IReservationForm,
  transportationDetails: Price | undefined,
  trip: IQuotedAvailabilityState,
  value: string,
  selectedRooms: FormattedRoom[],
  selectedAddons: AddonFormValues[],
  supplement: Price | undefined,
  tax: Price | undefined,
  transportation: Price | undefined,
): ICreateBookingBody => {
  const formattedData = {
    permalink: trip.permalink,
    productCode: trip.trip?.details.id as string,
    startDate: trip.trip?.details.startDate as string,
    transportationCode: transportationDetails?.ref ? transportationDetails?.ref : '0',
    from: trip.trip?.from,
    numberOfNights: trip.trip?.duration[0].value.toString() as string,
    payment: {
      amount: getTotalPrice(selectedRooms, selectedAddons, tax, transportation, supplement),
      tax: tax?.totalPrice,
      transportationPrice: transportation?.totalPrice,
      customerEmail: reservationFormData.agent.email,
      paymentMode: value as PaymentTypes,
    },
    clientCredentials: reservationFormData.agent,
    addons: getFormattedAddons(reservationFormData.addonList),
    adults: getFormattedTravellers(reservationFormData.travellers).adults,
    children: getFormattedTravellers(reservationFormData.travellers).children,
    hotelsData: getHotelFormattedData(
      selectedRooms,
      reservationFormData.roomList,
      trip.trip?.details,
    ),
  };

  return formattedData;
};

export default formatFinalRservationData;
