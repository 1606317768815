import { useTranslation } from 'react-i18next';

export const AscDescOptions = () => {
  const [t] = useTranslation();

  return [
    { label: 'Croissant', value: 'asc' },
    { label: 'Decroissant', value: 'desc' },
  ];
};
