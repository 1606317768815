import { useLocationBar } from 'client/containers/LocationBar/useLocationBar';
import { useProductTitle } from '../ProductTitle/useProductTitle';
import { useProductType } from '../ProductType/useProductType';

export const useProductBreadcrumbs = () => {
  const { title } = useProductTitle();
  const { country, city } = useLocationBar();
  const { productType, loading } = useProductType();

  const list = [
    { title: productType, to: `/products?product-type=${productType}` },
    { title: country, to: `/products?product-type=${productType}&country=${country}` },
    {
      title: city,
      to: `/products?product-type=${productType}&country=${country}&city=${city}`,
    },
    { title, to: '#' },
  ];

  return { list, loading };
};
