import { clientApi } from 'api';
import { TFlightResponse } from 'server/services/FlightsService';
import { IFlightClient } from 'types/IFlightData';
import { ICreatePaymenetResponse } from 'types/IPayment';

export const saveFlights = (isServer = false) => async (
  flights: TFlightResponse[],
  userData: IFlightClient[],
  paymentMode: string,
) => {
  const response = await clientApi.post<ICreatePaymenetResponse>('/flights/', {
    flights,
    userData,
    paymentMode,
  });

  return response.data;
};
