import { BlogArticleType } from 'client/_redux/types/BlogArticleType';
import { IReduxAction } from 'types/IReduxAction';
export interface IBlogQueryCover {
  blogQuery: { page: number; limit: number };
  blogCover: { metaDescription: string; metaTitle: string; metaCover: string[] };
}
const initialState = {
  blogQuery: { page: 1, limit: 5 },
  blogCover: { metaDescription: '', metaTitle: '', metaCover: [''] },
};

export default (
  state: IBlogQueryCover = initialState,
  { type, payload }: IReduxAction,
): IBlogQueryCover => {
  switch (type) {
    case BlogArticleType.SET_ARTICLES_QUERY:
      return payload;
    case BlogArticleType.SET_BLOG_COVER:
      return payload;

    default:
      return state;
  }
};
