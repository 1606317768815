/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import LazyImage from 'client/components/ImageCarousel/LazyImage';
import React from 'react';

interface IProps {
  onClick?: () => void;
  tabIndex?: number;
  backgroundSize: 'cover' | 'contain';
  height: string;
  imageUrl: string;
  index: number;
}

const LightboxGallery: React.FC<IProps> = ({
  onClick,
  tabIndex,
  height,
  imageUrl,
  children,
  index,
  backgroundSize,
}) => (
  <div className="position-relative">
    <LazyImage
      key={imageUrl}
      onClick={onClick}
      onKeyPress={onClick}
      alt="visuel du produit"
      src={encodeURI(imageUrl)}
      style={{
        objectFit: backgroundSize,
        width: '100%',
        height,
        cursor: 'pointer',
        borderRadius: '8px',
      }}
    />

    {children}
  </div>
);

export default LightboxGallery;
