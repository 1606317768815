import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { FieldError } from 'react-hook-form';

export type TinyMcProps = {
  errors?: FieldError;
  label?: string;
  name: string;
  onChange: (value: string) => void;
  value: string;
  file_picker_types?: string;
  initialValue?: string;
  menubar?: string;
  height?: number;
  plugins?: string[] | string;
  toolbar?: string;
  content_style?: string;
  image_uploadtab?: boolean;
  images_upload_url?: string;
};

export const TinyMc = ({
  errors,
  label,
  name,
  onChange,
  value,
  initialValue,
  menubar = 'insert',
  height = 250,
  file_picker_types = 'file image media',
  plugins = [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
    'image',
    'image imagetools',
    'autolink',
  ],
  toolbar = 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help |' +
    'image',
  content_style = 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  image_uploadtab = true,
  images_upload_url = `${process.env.RAZZLE_HOST}/api/v1/blog/image-array`,
}: TinyMcProps) => (
  <>
    <div className={`form-group ${errors ? 'is-invalid' : ''}`}>
      <label className="form-label" htmlFor={name}>
        <span className="d-flex justify-content-between align-items-center">{label}</span>
      </label>

      <Editor
        apiKey="7wx8a0mmalxo9wrk5pcmujruofw8uu5p1bjleu4gr373qwsy"
        textareaName={name}
        onEditorChange={onChange}
        value={value}
        initialValue={initialValue}
        init={{
          height,
          menubar,
          plugins,
          toolbar,
          content_style,
          image_uploadtab,
          file_picker_types,
          images_upload_url,
        }}
      />
      {errors && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )}
    </div>
  </>
);
