import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import React from 'react';
import Loader from './Loader';
import { useLocationBar } from './useLocationBar';

const LocationBar: React.FC = () => {
  const { city, country, loading } = useLocationBar();

  return (
    <div data-testid="ProductPage-LocationBar" className="d-flex align-items-baseline">
      {loading ? (
        <div className="m-auto ml-5">
          <Loader />
        </div>
      ) : (
        <>
          <span
            style={{ height: '35px', color: 'black' }}
            className="h3 fas fa-map-marker-alt ml-2 mr-2"
          />
          <span className="link-underline">{`${upperCaseFirstLetter(
            city || '',
          )}, ${upperCaseFirstLetter(country || '')}`}</span>
        </>
      )}
    </div>
  );
};

export default LocationBar;
