import { useAppSelector } from 'client/hooks/useAppSelector';
import { useEffect } from 'react';

export const useReservationConfirmation = () => {
  const {
    bookingReducer: { booking },
    productReducer: { customCatalogue },
  } = useAppSelector((state) => ({
    bookingReducer: state.bookingFormReducer,
    productReducer: state.productReducer,
  }));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);

  return { booking, customCatalogue };
};
