import { IWithDBDocument } from 'client/models/utils';
import { PromotionState } from 'client/_redux/reducers/promotion';
import React from 'react';

const HomePageDescriptionSection: React.FC<IWithDBDocument<PromotionState>> = ({
  image,
  title,
  description,
  url,
}) => (
  <div className="container space-2 position-relative">
    <div className="position-absolute bottom-0 left-0 w-100" style={{ maxWidth: '7rem' }}>
      <div className="mb-n7 ms-n7">
        <img className="img-fluid" src="/assets/svg/components/dots-1.svg" alt="dotted svg" />
      </div>
    </div>
    <div className="position-absolute top-0 right-0 w-100" style={{ maxWidth: '7rem' }}>
      <div className="mb-n7 ms-n7">
        <img className="img-fluid" src="/assets/svg/components/dots-2.svg" alt="dotted svg" />
      </div>
    </div>
    <div className="content-space-2 content-space-lg-3">
      <div className="row bg-light justify-content-lg-between align-items-md-center">
        <div className="col-md-8 order-md-2 mb-10 mb-md-0">
          <a href={url} target="_blank" className="position-relative">
            <img
              className="rounded shadow-serious card-transition"
              height={300}
              width="100%"
              src={`https://www.keplertravel.com/${image}`}
              style={{ objectFit: 'cover' }}
              alt="Imaasdasge Description"
            />
          </a>
        </div>
        <div className="col-md-4 col-lg-4 order-md-1">
          <h2>{title}</h2>
          <blockquote className="blockquote blockquote-lg h3">{description}</blockquote>
        </div>
      </div>
    </div>
  </div>
);

export default HomePageDescriptionSection;
