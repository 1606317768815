import { useAppSelector } from 'client/hooks/useAppSelector';
import { createOrUpdateBlogCover, getBlogCover } from 'dashboard/_redux/actions/blogArticles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IBlogCover, IBlogCoverDocument } from 'server/models/BlogCover/types';

const useAddBlogCoverPage = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const cover = useAppSelector(({ dashboradBlog }) => dashboradBlog.blogCover);

  useEffect(() => {
    dispatch(getBlogCover());
  }, [dispatch]);

  const formMethods = useForm<IBlogCover>({
    defaultValues: {
      metaCover: cover.metaCover,
      metaDescription: cover.metaDescription,
      metaTitle: cover.metaTitle,
    },
  });
  const { handleSubmit } = formMethods;
  const onSubmit = (value: IBlogCoverDocument) => {
    dispatch(createOrUpdateBlogCover({ ...value, _id: cover?._id }));
  };

  return { onSubmit, formMethods, t, handleSubmit, cover };
};

export default useAddBlogCoverPage;
