import React, { useCallback, useMemo, useState } from 'react';
import { IAirport } from 'server/models/Flights/types';
import { TFlightResponse } from 'server/services/FlightsService';
import ExpandedRow from './ExpandedRow';

interface IFligthExpandableCard {
  flight: TFlightResponse;
  isBestPrice?: boolean;
  isRoundTrip?: boolean;
  isReturn?: boolean;
  openByDefault?: boolean;
  setSelectedFlights?: (flight: {
    typeOfFlight: 'Single' | 'Going' | 'Returning';
    _id: string;
  }) => void;
}
const FligthExpandableCard = ({
  isBestPrice,
  isRoundTrip,
  isReturn,
  openByDefault,
  setSelectedFlights,
  flight: {
    _id,
    airline,
    destinationAirport,
    hoursLocalLanding,
    flightNumber,
    luggageOptions,
    originAirport,
    price,
    endSellingDate,
    originalDateLocalTakeOff,
    originalHoursLocalTakeOff,
    viaAirport,
  },
}: IFligthExpandableCard) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpanding = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);
  const [isSelected, setIsSelected] = useState(false);
  const typeOfFlight = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      isRoundTrip && isReturn ? 'Returning' : isRoundTrip && !isReturn ? 'Going' : 'Single',
    [isReturn, isRoundTrip],
  );
  const handleSelect = useCallback(() => {
    setIsSelected(true);
    setSelectedFlights && setSelectedFlights({ _id: _id || '', typeOfFlight });
  }, [_id, setSelectedFlights, typeOfFlight]);

  return (
    <div className="flight-card-wrapper">
      <div className="row">
        <span
          className={`badge badge-dark mb-1  flight-number-badge 
          `}
        >
          {airline + flightNumber}
        </span>
        <span
          className={`badge badge-info mb-1  date-badge 
          `}
        >
          {originalDateLocalTakeOff}
        </span>
      </div>
      <div
        className={`row flight-card-container rounded ${isSelected ? 'border-success ' : ''}`}
      >
        <div className="col-lg-2 col-md-2 col-8">
          <img
            src={`https://images.kiwi.com/airlines/64/${airline}.png`}
            width={80}
            alt={airline}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-4 d-flex flex-column align-items-baseline">
          <div>
            {originalHoursLocalTakeOff}-{hoursLocalLanding}
          </div>
          <div className="text-primary badge">
            {(originAirport as IAirport).code}-{(destinationAirport as IAirport).code}
          </div>
        </div>
        <div className=" col-lg-2 col-md-2  col-sm-4 col-8 ">
          {viaAirport ? (
            <div className="d-flex flex-column">
              <span>{viaAirport.country}</span>
              <span>{viaAirport.state}</span>
            </div>
          ) : (
            <span>sans escale</span>
          )}
        </div>
        <div className="col-sm-3 col-4  px-0 ">
          {setSelectedFlights && (
            <button
              className="btn btn-outline-primary p-2"
              type="button"
              onClick={handleSelect}
            >
              Sélectionner
            </button>
          )}
        </div>
        <div
          className={` d-flex flex-column  col-lg-2 col-md-2 col-sm-4 col-12 align-items-center m-sm-0 mr-5`}
        >
          <div className="d-flex flex-row ">
            <div data-testid="price" className="h3 mt-auto mb-auto ">
              <span className={`${isBestPrice && 'text-success'}`}>
                {Math.ceil(price)}
                <span className="font-weight-light">€</span>
              </span>
            </div>
            <div
              className={`${isBestPrice && 'text-success'} mt-1 mb-auto ml-1 ttc_font_size`}
              style={{
                position: 'relative',
              }}
            >
              TTC/
              <span className={`${isBestPrice && 'text-success'}d-block font-weight-bold`}>
                pers
              </span>
            </div>
          </div>
          <span>{isRoundTrip ? 'aller-retour' : 'sans-retour'}</span>
        </div>
        <div
          onClick={handleExpanding}
          className="cursor-pointer col-12 col-md-1 text-center my-3 my-md-0"
        >
          {!openByDefault && (
            <i
              className={`${
                isExpanded ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'
              } fas fa-chevron-circle-down fa-2x text-primary`}
            />
          )}
        </div>
      </div>
      {(isExpanded || openByDefault) && (
        <ExpandedRow
          isFlightSelected={isSelected}
          originalHoursLocalTakeOff={originalHoursLocalTakeOff}
          originAirport={originAirport}
          hoursLocalLanding={hoursLocalLanding}
          destinationAirport={destinationAirport}
          luggageOptions={luggageOptions}
          endSellingDate={endSellingDate}
        />
      )}
    </div>
  );
};

export default FligthExpandableCard;
