import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const useSelectListModalVoyageur = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(!open);
  const [t] = useTranslation();
  const { watch } = useFormContext();

  return {
    open,
    setOpen,
    handleClose,
    t,
    watch,
  };
};
