import { PaginatedResponse } from 'client/models/utils';
import { BlogArticlesTypes } from 'dashboard/_redux/types/blogArticlesTypes';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { IBlogCoverDocument } from 'server/models/BlogCover/types';

import { IReduxAction } from 'types/IReduxAction';

export interface IArticle {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  article: string;
  metaDescription: string;
  metaTitle: string;
  metaImage: string[];
}
const initialState = {
  loading: true,
  articles: emptyPaginationPayload,
  blogCover: {
    metaDescription: ` “Wherever you go becomes a part of you somehow” – Anita Desai.`,
    metaTitle: 'Read our latest news',
    metaCover: [
      // eslint-disable-next-line max-len
      'https://images.unsplash.com/photo-1530789253388-582c481c54b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    ],
  },
};

export interface IArticalesInterface {
  loading?: boolean;
  articles: PaginatedResponse<IArticle>;
  blogCover: Partial<IBlogCoverDocument>;
}

export default (
  state: IArticalesInterface = initialState,
  { type, payload }: IReduxAction,
): IArticalesInterface => {
  switch (type) {
    case BlogArticlesTypes.GET_ARTICLES_SUCCESS: {
      return { ...state, articles: payload, loading: false };
    }
    case BlogArticlesTypes.BLOG_COVER_SUCCESS: {
      return { ...state, blogCover: payload, loading: false };
    }
    case BlogArticlesTypes.GET_BLOG_COVER_SUCCESS: {
      return { ...state, blogCover: payload, loading: false };
    }
    default:
      return state;
  }
};
