import {
  DetailsRooms,
  FormattedRoom,
  Transportation,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { IRoom, IStayType } from 'types/ICreateBookingRequest';
import { IReservationForm } from 'types/IReservationForm';

interface ITripDetails {
  id?: string;
  startDate?: string;
  description?: string;
  endDate?: string;
  transportation?: Transportation[] | undefined;
  rooms: DetailsRooms;
}
export const getHotelFormattedData = (
  selectedRooms: FormattedRoom[],
  roomList: IReservationForm['roomList'],
  details?: ITripDetails,
) => {
  const rooms: IRoom[] = [];

  selectedRooms.forEach((selectedRoom, index) => {
    const selectedTravellerId: string[] = [];

    roomList[index].adults.forEach((el) => selectedTravellerId.push(el as string));
    roomList[index].children?.forEach((el) => selectedTravellerId.push(el as string));

    const roomsId = {
      code: selectedRoom.roomCode,
      travellersIds: selectedTravellerId,
    };

    rooms.push(roomsId);
  });

  const hoteldata: IStayType = {
    code: details?.id as string,
    beginDate: details?.startDate as string,
    endDate: details?.endDate as string,
    rooms,
  };

  return [hoteldata];
};
