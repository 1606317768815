import BookingCard from 'client/components/BookingCard';
import React from 'react';
import { IBookingConfirmation } from 'types/IBookingConfirmation';
import FlightDetailsContainer from '../FlightDetailsContainer';
import { useReservationConfirmationCards } from './useReservationConfirmationCards';
interface IReservationDetailsRoom {
  booking: IBookingConfirmation;
}
const ReservationConfirmationCards: React.FC<IReservationDetailsRoom> = ({ booking }) => {
  const { roomsArray, addonsArray, t } = useReservationConfirmationCards(booking);

  return (
    <>
      <b className="row ml-5 mt-3">{t('reservationDetails.rooms')}</b>
      <BookingCard elements={roomsArray} isConfirmationPage />
      {addonsArray[0] && (
        <>
          <b className="row ml-5">{t('reservationDetails.addons')}</b>
          <BookingCard isConfirmationPage elements={addonsArray} isAddon />
        </>
      )}
      {booking.trip.trip?.details.transportation &&
        booking.trip.trip?.details.transportation[0].details && (
          <>
            <b className="row ml-5">{t('reservationDetails.flight')}</b>
            <FlightDetailsContainer selected />
          </>
        )}
    </>
  );
};

export default ReservationConfirmationCards;
