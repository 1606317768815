/* eslint-disable max-lines */
import { AuthTypes } from 'client/_redux/types/authTypes';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import * as AuthServices from 'client/_redux/services/auth';
import { errorHandler } from 'client/helpers/errorHandler';
import { db, resetAuthToken } from 'api';
import { IThunkAction } from 'types/IThunkAction';
import { ILoginFormValues } from 'client/containers/LoginForm';
import { IRegisterFormValues } from 'client/containers/RegisterForm/useRegisterForm';
import { toast } from 'react-toastify';
import { AxiosRequestConfig } from 'axios';
import { IByTokenResponse, ISignInResponse } from 'types/Auth';
import { UserTypes } from 'client/_redux/types/userTypes';
import { LoadingTypes } from 'client/_redux/types/loadingTypes';

export const login: (
  values: ILoginFormValues,
  onSuccess: (isModal: boolean) => void,
) => IThunkAction = (values, onSuccess) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const signInData = await AuthServices.login()(values);

    const { user, token } = signInData.data;

    saveUserRefreshToken(signInData.data);

    dispatch({
      type: UserTypes.SIGNIN_USER_SUCCESS,
      payload: user,
    });
    dispatch({
      type: AuthTypes.SIGNIN_SUCCESS,
      payload: token,
    });
    onSuccess(false);
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const signUp: (
  values: IRegisterFormValues,
  onSuccess: (isModal: boolean) => void,
) => IThunkAction = (values, onSuccess) => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await AuthServices.signUp()(values);

    const { user, token } = response.data;

    saveUserRefreshToken(response.data);

    dispatch({
      type: UserTypes.SIGNIN_USER_SUCCESS,
      payload: user,
    });
    dispatch({
      type: AuthTypes.SIGNIN_SUCCESS,
      payload: token,
    });
    onSuccess(false);
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
export const signOut: () => IThunkAction = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const id = window.localStorage.getItem('id');

    if (!id) throw new Error('No user Id');

    const { refreshToken } = await db.getData('user', id);

    await AuthServices.signOut()(refreshToken);
    window.localStorage.removeItem('id');

    dispatch({ type: AuthTypes.USER_LOGGED_OUT_TOKEN });
    dispatch({ type: UserTypes.USER_LOGGED_OUT });
    db.deleteData('user', id);
    window.location.reload();
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const saveUserRefreshToken = async (
  {
    user: { _id },
    token: { refreshToken, accessToken },
  }: Omit<ISignInResponse, 'user'> & { user: IByTokenResponse['user'] },
  isAnonymous?: boolean,
) => {
  isAnonymous ? localStorage.setItem('aid', _id) : localStorage.setItem('id', _id);

  resetAuthToken(accessToken);

  try {
    await db.createOrUpdate('user', { id: _id, refreshToken, accessToken });
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
};

export const refreshUserToken = async () => {
  let id = window.localStorage.getItem('id');
  let token;

  if (!id) {
    const anonymousId = window.localStorage.getItem('aid');

    if (!anonymousId) {
      resetAuthToken();

      return;
    }
    id = anonymousId;
    const { refreshToken } = await db.getData('user', anonymousId);

    token = refreshToken;
  } else {
    const { refreshToken } = await db.getData('user', id);

    token = refreshToken;
  }

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `bearer ${token}`,
    },
  };
  const response = await AuthServices.refreshToken()(config);

  resetAuthToken(response.data.accessToken);
  db.updateData('user', { id, accessToken: response.data.accessToken, refreshToken: token });
};
export const checkLoggingStatus = (): IThunkAction => async (dispatch): Promise<void> => {
  dispatch(startLoading());
  dispatch({ type: LoadingTypes.START_INITIATE });
  try {
    await refreshUserToken();

    const { token, user, isAnonymous } = await AuthServices.userByToken()();

    let _token;

    if (isAnonymous) {
      if (token) {
        _token = token;
        saveUserRefreshToken({ user, token }, true);
      } else {
        _token = await db.getData('user', user._id);
      }

      dispatch({
        type: AuthTypes.SIGNIN_ANONYMOUS,
        payload: _token,
      });
    }
    dispatch({
      type: UserTypes.SIGNIN_USER_SUCCESS,
      payload: user,
    });
  } catch (error) {
    dispatch({ type: AuthTypes.USER_LOGGED_OUT_TOKEN });
    dispatch({ type: UserTypes.USER_LOGGED_OUT });
  }
  dispatch(stopLoading());
  dispatch({ type: LoadingTypes.INITIATE });
};
