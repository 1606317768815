import { BlogArticleType } from 'client/_redux/types/BlogArticleType';
import { IThunkAction } from 'types/IThunkAction';
import * as BlogServices from 'client/_redux/services/blogArticles';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import { SearchQuery } from 'dashboard/_redux/services/blogArticles';
import { IArticle } from 'dashboard/_redux/reducers/blog';

export const getBlogCover = (): IThunkAction => async (dispatch, getState) => {
  const {
    blog: { isPreloaded },
  } = getState();

  if (isPreloaded) return;
  dispatch(startLoading());
  try {
    const res = await BlogServices.getBlogCover()();

    if (res.metaTitle !== undefined) {
      dispatch({ type: BlogArticleType.SET_BLOG_COVER, payload: res });
      dispatch({
        type: BlogArticleType.GET_BLOG_COVER_SUCCESS,
        payload: res,
      });
    }
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const getArticles = (query: SearchQuery<IArticle>): IThunkAction => async (
  dispatch,
  getState,
) => {
  const {
    blogCoverAndQuery: { blogQuery },
    blog: { isPreloaded },
  } = getState();

  if (isPreloaded && blogQuery === query) return;
  dispatch(startLoading());
  try {
    const res = await BlogServices.getArticles()(query);

    dispatch({ type: BlogArticleType.SET_ARTICLES_QUERY, payload: query });
    dispatch({
      type: BlogArticleType.GET_ARTICLES_SUCCESS,
      payload: res,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
