import { useAppSelector } from 'client/hooks/useAppSelector';
import { PromotionState } from 'dashboard/_redux/reducers/promotion';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const usePromotionFrom = (
  onSubmit: SubmitHandler<PromotionState>,
  initalValues: PromotionState | null,
) => {
  const formMethods = useForm<PromotionState>({
    shouldFocusError: true,
    defaultValues: initalValues || {},
  });
  const [t] = useTranslation();
  const _onSubmit: SubmitHandler<PromotionState> = (data) => {
    onSubmit(data);
  };
  const { loading } = useAppSelector((state) => state.loading);
  const { handleSubmit } = formMethods;

  return { formMethods, _onSubmit, t, loading, handleSubmit };
};
