import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { addProductInfo, getProductInfo } from 'dashboard/_redux/actions/productInfo';
import { IProductInfo } from 'server/models/ProductInfo/types';
import { useAppSelector } from 'client/hooks/useAppSelector';

export interface IRouterParams {
  permalink: string;
  id: string;
}
export const useEditProductDetailsDashboard = () => {
  const { productInfo } = useAppSelector((state) => state.productInfo);
  const loading = useAppSelector((state) => state.loading.loading);

  const dispatch = useDispatch();

  const { permalink, id } = useParams<IRouterParams>();

  useEffect(() => {
    dispatch(getProductInfo({ permalink }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleUpdateProductDetails = (data: IProductInfo) => {
    data._$id = id;
    dispatch(addProductInfo(data));
  };

  return { productInfo, handleUpdateProductDetails, permalink, id, loading };
};
