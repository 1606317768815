import React from 'react';
import FlightDetailsMobile from './FlightDetailsMobile';

import FlightDetailsWeb from './FlightDetailsWeb';

export interface FlightDetails {
  id?: string;
  direction: string;
  date?: string;
  companyLogo?: string;
  carrier: { title: string; code: string };
  start: string;
  from: { title: string; code: string };
  durationAndType?: string;
  end: string;
  to: { title: string; code: string };
  bagageInfo?: string;
  flightNumber: string;
}
interface IProps {
  flightDetails: FlightDetails;
  bgClassName?: string;
}
const FlightDetailsCard: React.FC<IProps> = ({ flightDetails, bgClassName }) => (
  <div className={`border rounded px-2 ${bgClassName && bgClassName}`}>
    <FlightDetailsWeb flightDetails={flightDetails} bgClassName={bgClassName} />
    <FlightDetailsMobile flightDetails={flightDetails} bgClassName={bgClassName} />
  </div>
);

export default FlightDetailsCard;
