export const airportTimezone = {
  AAA: 'Pacific/Tahiti',
  AAB: 'Australia/Brisbane',
  AAC: 'Africa/Cairo',
  AAD: 'Africa/Mogadishu',
  AAE: 'Africa/Algiers',
  AAF: 'America/New_York',
  AAG: 'America/Sao_Paulo',
  AAH: 'Europe/Berlin',
  AAI: 'America/Araguaina',
  AAJ: 'America/Paramaribo',
  AAK: 'Pacific/Tarawa',
  AAL: 'Europe/Copenhagen',
  AAM: 'Africa/Johannesburg',
  AAN: 'Asia/Dubai',
  AAO: 'America/Caracas',
  AAP: 'Asia/Makassar',
  AAQ: 'Europe/Moscow',
  AAR: 'Europe/Copenhagen',
  AAS: 'Asia/Jayapura',
  AAT: 'Asia/Urumqi',
  AAU: 'Pacific/Apia',
  AAV: 'Asia/Manila',
  AAX: 'America/Sao_Paulo',
  AAY: 'Asia/Aden',
  AAZ: 'America/Guatemala',
  ABA: 'Asia/Krasnoyarsk',
  ABB: 'Africa/Lagos',
  ABC: 'Europe/Madrid',
  ABD: 'Asia/Tehran',
  ABE: 'America/New_York',
  ABF: 'Pacific/Tarawa',
  ABG: 'Australia/Brisbane',
  ABH: 'Australia/Brisbane',
  ABI: 'America/Chicago',
  ABJ: 'Africa/Abidjan',
  ABK: 'Africa/Addis_Ababa',
  ABL: 'America/Anchorage',
  ABM: 'Australia/Brisbane',
  ABN: 'America/Paramaribo',
  ABO: 'Africa/Abidjan',
  ABP: 'Pacific/Port_Moresby',
  ABQ: 'America/Denver',
  ABR: 'America/Chicago',
  ABS: 'Africa/Cairo',
  ABT: 'Asia/Riyadh',
  ABU: 'Asia/Makassar',
  ABV: 'Africa/Lagos',
  ABW: 'Pacific/Port_Moresby',
  ABX: 'Australia/Sydney',
  ABY: 'America/New_York',
  ABZ: 'Europe/London',
  ACA: 'America/Mexico_City',
  ACB: 'America/Detroit',
  ACC: 'Africa/Accra',
  ACD: 'America/Bogota',
  ACE: 'Atlantic/Canary',
  ACH: 'Europe/Zurich',
  ACI: 'Europe/Guernsey',
  ACJ: 'Asia/Colombo',
  ACK: 'America/New_York',
  ACL: 'America/Bogota',
  ACM: 'America/Bogota',
  ACN: 'America/Matamoros',
  ACP: 'Asia/Tehran',
  ACR: 'America/Bogota',
  ACS: 'Asia/Krasnoyarsk',
  ACT: 'America/Chicago',
  ACU: 'America/Panama',
  ACV: 'America/Los_Angeles',
  ACX: 'Asia/Shanghai',
  ACY: 'America/New_York',
  ACZ: 'Asia/Tehran',
  ADA: 'Europe/Istanbul',
  ADB: 'Europe/Istanbul',
  ADC: 'Pacific/Port_Moresby',
  ADD: 'Africa/Addis_Ababa',
  ADE: 'Asia/Aden',
  ADF: 'Europe/Istanbul',
  ADG: 'America/Detroit',
  ADH: 'Asia/Yakutsk',
  ADI: 'Africa/Windhoek',
  ADJ: 'Asia/Amman',
  ADK: 'America/Adak',
  ADL: 'Australia/Adelaide',
  ADM: 'America/Chicago',
  ADN: 'America/Bogota',
  ADO: 'Australia/Adelaide',
  ADP: 'Asia/Colombo',
  ADQ: 'America/Anchorage',
  ADR: 'America/New_York',
  ADS: 'America/Chicago',
  ADT: 'America/Chicago',
  ADU: 'Asia/Tehran',
  ADV: 'Africa/Khartoum',
  ADW: 'America/New_York',
  ADX: 'Europe/London',
  ADY: 'Africa/Johannesburg',
  ADZ: 'America/Bogota',
  AEA: 'Pacific/Tarawa',
  AEB: 'Asia/Shanghai',
  AEE: 'Africa/Juba',
  AEG: 'Asia/Jakarta',
  AEH: 'Africa/Ndjamena',
  AEK: 'Pacific/Port_Moresby',
  AEL: 'America/Chicago',
  AEM: 'Asia/Vladivostok',
  AEO: 'Africa/Nouakchott',
  AEP: 'America/Argentina/Buenos_Aires',
  AEQ: 'Asia/Shanghai',
  AER: 'Europe/Moscow',
  AES: 'Europe/Oslo',
  AET: 'America/Anchorage',
  AEU: 'Asia/Tehran',
  AEX: 'America/Chicago',
  AEY: 'Atlantic/Reykjavik',
  AFA: 'America/Argentina/Mendoza',
  AFD: 'Africa/Johannesburg',
  AFF: 'America/Denver',
  AFI: 'America/Bogota',
  AFK: 'Asia/Colombo',
  AFL: 'America/Cuiaba',
  AFN: 'America/New_York',
  AFO: 'America/Denver',
  AFR: 'Pacific/Port_Moresby',
  AFS: 'Asia/Samarkand',
  AFT: 'Pacific/Guadalcanal',
  AFW: 'America/Chicago',
  AFY: 'Europe/Istanbul',
  AFZ: 'Asia/Tehran',
  AGA: 'Africa/Casablanca',
  AGB: 'Europe/Berlin',
  AGC: 'America/New_York',
  AGD: 'Asia/Jayapura',
  AGE: 'Europe/Berlin',
  AGF: 'Europe/Paris',
  AGG: 'Pacific/Port_Moresby',
  AGH: 'Europe/Stockholm',
  AGI: 'America/Paramaribo',
  AGJ: 'Asia/Tokyo',
  AGK: 'Pacific/Port_Moresby',
  AGL: 'Pacific/Port_Moresby',
  AGN: 'America/Juneau',
  AGO: 'America/Chicago',
  AGP: 'Europe/Madrid',
  AGQ: 'Europe/Athens',
  AGR: 'Asia/Kolkata',
  AGS: 'America/New_York',
  AGT: 'America/Asuncion',
  AGU: 'America/Mexico_City',
  AGV: 'America/Caracas',
  AGW: 'Australia/Brisbane',
  AGX: 'Asia/Kolkata',
  AGZ: 'Africa/Johannesburg',
  AHB: 'Asia/Riyadh',
  AHC: 'America/Los_Angeles',
  AHD: 'America/Chicago',
  AHE: 'Pacific/Tahiti',
  AHF: 'America/Chicago',
  AHH: 'America/Chicago',
  AHI: 'Asia/Jayapura',
  AHJ: 'Asia/Shanghai',
  AHL: 'America/Guyana',
  AHM: 'America/Los_Angeles',
  AHN: 'America/New_York',
  AHO: 'Europe/Rome',
  AHS: 'America/Tegucigalpa',
  AHU: 'Africa/Casablanca',
  AHW: 'Asia/Muscat',
  AHY: 'Indian/Antananarivo',
  AHZ: 'Europe/Paris',
  AIA: 'America/Denver',
  AIC: 'Pacific/Kwajalein',
  AID: 'America/Indiana/Indianapolis',
  AIE: 'Pacific/Port_Moresby',
  AIF: 'America/Sao_Paulo',
  AIG: 'Africa/Bangui',
  AIH: 'Pacific/Port_Moresby',
  AII: 'Africa/Djibouti',
  AIK: 'America/New_York',
  AIL: 'America/Panama',
  AIM: 'Pacific/Kwajalein',
  AIN: 'America/Anchorage',
  AIO: 'America/Chicago',
  AIP: 'Asia/Kolkata',
  AIR: 'America/Cuiaba',
  AIS: 'Pacific/Tarawa',
  AIT: 'Pacific/Rarotonga',
  AIU: 'Pacific/Rarotonga',
  AIV: 'America/Chicago',
  AIW: 'Africa/Windhoek',
  AIZ: 'America/Chicago',
  AJA: 'Europe/Paris',
  AJF: 'Asia/Riyadh',
  AJI: 'Europe/Istanbul',
  AJJ: 'Africa/Nouakchott',
  AJK: 'Asia/Tehran',
  AJL: 'Asia/Kolkata',
  AJN: 'Indian/Comoro',
  AJR: 'Europe/Stockholm',
  AJS: 'America/Hermosillo',
  AJU: 'America/Noronha',
  AJY: 'Africa/Niamey',
  AKA: 'Asia/Shanghai',
  AKB: 'America/Adak',
  AKC: 'America/New_York',
  AKD: 'Asia/Kolkata',
  AKE: 'Africa/Libreville',
  AKF: 'Africa/Tripoli',
  AKG: 'Pacific/Port_Moresby',
  AKH: 'Asia/Riyadh',
  AKI: 'America/Anchorage',
  AKJ: 'Asia/Tokyo',
  AKK: 'America/Anchorage',
  AKL: 'Pacific/Auckland',
  AKM: 'Africa/Ndjamena',
  AKN: 'America/Anchorage',
  AKO: 'America/Denver',
  AKP: 'America/Anchorage',
  AKQ: 'Asia/Jakarta',
  AKR: 'Africa/Lagos',
  AKS: 'Pacific/Guadalcanal',
  AKT: 'Asia/Nicosia',
  AKU: 'Asia/Shanghai',
  AKV: 'America/Toronto',
  AKW: 'Asia/Tehran',
  AKX: 'Asia/Aqtobe',
  AKY: 'Asia/Yangon',
  ALA: 'Asia/Almaty',
  ALB: 'America/New_York',
  ALC: 'Europe/Madrid',
  ALD: 'America/Lima',
  ALE: 'America/Chicago',
  ALF: 'Europe/Oslo',
  ALG: 'Africa/Algiers',
  ALH: 'Australia/Perth',
  ALI: 'America/Chicago',
  ALJ: 'Africa/Johannesburg',
  ALK: 'Africa/Addis_Ababa',
  ALL: 'Europe/Rome',
  ALM: 'America/Denver',
  ALN: 'America/Chicago',
  ALO: 'America/Chicago',
  ALP: 'Asia/Damascus',
  ALQ: 'America/Sao_Paulo',
  ALR: 'Pacific/Auckland',
  ALS: 'America/Denver',
  ALT: 'America/Santarem',
  ALU: 'Africa/Mogadishu',
  ALW: 'America/Los_Angeles',
  ALX: 'America/Chicago',
  ALY: 'Africa/Cairo',
  ALZ: 'America/Anchorage',
  AMA: 'America/Chicago',
  AMB: 'Indian/Antananarivo',
  AMC: 'Africa/Ndjamena',
  AMD: 'Asia/Kolkata',
  AME: 'Africa/Maputo',
  AMF: 'Pacific/Port_Moresby',
  AMG: 'Pacific/Port_Moresby',
  AMH: 'Africa/Addis_Ababa',
  AMI: 'Asia/Makassar',
  AMJ: 'America/Sao_Paulo',
  AMK: 'America/Denver',
  AML: 'America/Panama',
  AMM: 'Asia/Amman',
  AMN: 'America/Detroit',
  AMO: 'Africa/Ndjamena',
  AMP: 'Indian/Antananarivo',
  AMQ: 'Asia/Jayapura',
  AMS: 'Europe/Amsterdam',
  AMT: 'Australia/Adelaide',
  AMU: 'Pacific/Port_Moresby',
  AMV: 'Europe/Moscow',
  AMW: 'America/Chicago',
  AMX: 'Australia/Darwin',
  AMY: 'Indian/Antananarivo',
  AMZ: 'Pacific/Auckland',
  ANA: 'Africa/Nairobi',
  ANB: 'America/Chicago',
  ANC: 'America/Anchorage',
  AND: 'America/New_York',
  ANE: 'Europe/Paris',
  ANF: 'America/Santiago',
  ANG: 'Europe/Paris',
  ANI: 'America/Anchorage',
  ANJ: 'Africa/Brazzaville',
  ANK: 'Europe/Istanbul',
  ANL: 'Africa/Luanda',
  ANM: 'Indian/Antananarivo',
  ANN: 'America/Metlakatla',
  ANO: 'Africa/Maputo',
  ANP: 'America/New_York',
  ANQ: 'America/Indiana/Indianapolis',
  ANR: 'Europe/Brussels',
  ANS: 'America/Lima',
  ANU: 'America/Antigua',
  ANV: 'America/Anchorage',
  ANW: 'America/Chicago',
  ANX: 'Europe/Oslo',
  ANY: 'America/Chicago',
  ANZ: 'Australia/Darwin',
  AOA: 'Pacific/Port_Moresby',
  AOB: 'Pacific/Port_Moresby',
  AOC: 'Europe/Berlin',
  AOD: 'Africa/Ndjamena',
  AOE: 'Europe/Istanbul',
  AOG: 'Asia/Shanghai',
  AOH: 'America/New_York',
  AOI: 'Europe/Rome',
  AOJ: 'Asia/Tokyo',
  AOK: 'Europe/Athens',
  AOL: 'America/Argentina/Cordoba',
  AOM: 'Asia/Muscat',
  AON: 'Pacific/Port_Moresby',
  AOO: 'America/New_York',
  AOP: 'America/Lima',
  AOR: 'Asia/Kuala_Lumpur',
  AOS: 'America/Anchorage',
  AOT: 'Europe/Rome',
  AOU: 'Asia/Vientiane',
  APA: 'America/Denver',
  APB: 'America/La_Paz',
  APC: 'America/Los_Angeles',
  APF: 'America/New_York',
  APG: 'America/New_York',
  APH: 'America/New_York',
  API: 'America/Bogota',
  APK: 'Pacific/Tahiti',
  APL: 'Africa/Maputo',
  APN: 'America/Detroit',
  APO: 'America/Bogota',
  APP: 'Pacific/Port_Moresby',
  APQ: 'America/Maceio',
  APR: 'Pacific/Port_Moresby',
  APS: 'America/Sao_Paulo',
  APT: 'America/Chicago',
  APU: 'America/Sao_Paulo',
  APV: 'America/Los_Angeles',
  APW: 'Pacific/Apia',
  APX: 'America/Sao_Paulo',
  APY: 'America/Fortaleza',
  APZ: 'America/Argentina/Salta',
  AQA: 'America/Sao_Paulo',
  AQB: 'America/Guatemala',
  AQG: 'Asia/Shanghai',
  AQI: 'Asia/Riyadh',
  AQJ: 'Asia/Amman',
  AQM: 'America/Porto_Velho',
  AQP: 'America/Lima',
  AQS: 'Pacific/Fiji',
  AQY: 'America/Anchorage',
  ARA: 'America/Chicago',
  ARB: 'America/Detroit',
  ARC: 'America/Anchorage',
  ARD: 'Asia/Makassar',
  ARE: 'America/Puerto_Rico',
  ARF: 'America/Bogota',
  ARG: 'America/Chicago',
  ARH: 'Europe/Moscow',
  ARI: 'America/Santiago',
  ARJ: 'Asia/Jayapura',
  ARK: 'Africa/Dar_es_Salaam',
  ARL: 'Africa/Ouagadougou',
  ARM: 'Australia/Sydney',
  ARN: 'Europe/Stockholm',
  ARO: 'America/Bogota',
  ARP: 'Pacific/Port_Moresby',
  ARQ: 'America/Bogota',
  ARR: 'America/Argentina/Catamarca',
  ARS: 'America/Sao_Paulo',
  ART: 'America/New_York',
  ARU: 'America/Sao_Paulo',
  ARV: 'America/Chicago',
  ARW: 'Europe/Bucharest',
  ARX: 'America/Fortaleza',
  ARY: 'Australia/Melbourne',
  ARZ: 'Africa/Luanda',
  ASA: 'Africa/Asmara',
  ASB: 'Asia/Ashgabat',
  ASC: 'America/La_Paz',
  ASD: 'America/Nassau',
  ASE: 'America/Denver',
  ASF: 'Europe/Astrakhan',
  ASG: 'Pacific/Auckland',
  ASH: 'America/New_York',
  ASI: 'Atlantic/St_Helena',
  ASJ: 'Asia/Tokyo',
  ASK: 'Africa/Abidjan',
  ASL: 'America/Chicago',
  ASM: 'Africa/Asmara',
  ASN: 'America/Chicago',
  ASO: 'Africa/Addis_Ababa',
  ASP: 'Australia/Darwin',
  ASQ: 'America/Los_Angeles',
  ASR: 'Europe/Istanbul',
  ASS: 'Africa/Johannesburg',
  AST: 'America/Los_Angeles',
  ASU: 'America/Asuncion',
  ASV: 'Africa/Nairobi',
  ASW: 'Africa/Cairo',
  ASX: 'America/Chicago',
  ASY: 'America/Chicago',
  ASZ: 'Pacific/Port_Moresby',
  ATA: 'America/Lima',
  ATB: 'Africa/Khartoum',
  ATC: 'America/Nassau',
  ATD: 'Pacific/Guadalcanal',
  ATE: 'America/Chicago',
  ATF: 'America/Guayaquil',
  ATG: 'Asia/Karachi',
  ATH: 'Europe/Athens',
  ATI: 'America/Montevideo',
  ATJ: 'Indian/Antananarivo',
  ATK: 'America/Anchorage',
  ATL: 'America/New_York',
  ATM: 'America/Manaus',
  ATN: 'Pacific/Port_Moresby',
  ATO: 'America/New_York',
  ATP: 'Pacific/Port_Moresby',
  ATQ: 'Asia/Kolkata',
  ATR: 'Africa/Nouakchott',
  ATS: 'America/Denver',
  ATT: 'America/Nome',
  ATU: 'America/Adak',
  ATV: 'Africa/Ndjamena',
  ATW: 'America/Chicago',
  ATX: 'Asia/Almaty',
  ATY: 'America/Chicago',
  ATZ: 'Africa/Cairo',
  AUA: 'America/Aruba',
  AUC: 'America/Bogota',
  AUD: 'Australia/Brisbane',
  AUE: 'Africa/Cairo',
  AUF: 'Europe/Paris',
  AUG: 'America/New_York',
  AUH: 'Asia/Dubai',
  AUI: 'Pacific/Port_Moresby',
  AUJ: 'Pacific/Port_Moresby',
  AUK: 'America/Nome',
  AUL: 'Pacific/Majuro',
  AUM: 'America/Chicago',
  AUN: 'America/Los_Angeles',
  AUO: 'America/Chicago',
  AUP: 'Pacific/Port_Moresby',
  AUQ: 'Pacific/Marquesas',
  AUR: 'Europe/Paris',
  AUS: 'America/Chicago',
  AUT: 'Asia/Dili',
  AUU: 'Australia/Brisbane',
  AUV: 'Pacific/Port_Moresby',
  AUW: 'America/Chicago',
  AUX: 'America/Araguaina',
  AUY: 'Pacific/Efate',
  AUZ: 'America/Chicago',
  AVA: 'Asia/Shanghai',
  AVB: 'Europe/Rome',
  AVG: 'Australia/Darwin',
  AVI: 'America/Havana',
  AVK: 'Asia/Ulaanbaatar',
  AVL: 'America/New_York',
  AVN: 'Europe/Paris',
  AVO: 'America/New_York',
  AVP: 'America/New_York',
  AVR: 'Europe/Lisbon',
  AVU: 'Pacific/Guadalcanal',
  AVV: 'Australia/Melbourne',
  AVW: 'America/Phoenix',
  AVX: 'America/Los_Angeles',
  AWA: 'Africa/Addis_Ababa',
  AWB: 'Pacific/Port_Moresby',
  AWD: 'Pacific/Efate',
  AWE: 'Africa/Libreville',
  AWK: 'Pacific/Wake',
  AWM: 'America/Chicago',
  AWN: 'Australia/Adelaide',
  AWP: 'Australia/Darwin',
  AWR: 'Pacific/Port_Moresby',
  AWZ: 'Asia/Tehran',
  AXA: 'America/Anguilla',
  AXB: 'America/New_York',
  AXC: 'Australia/Brisbane',
  AXD: 'Europe/Athens',
  AXE: 'America/Sao_Paulo',
  AXF: 'Asia/Shanghai',
  AXG: 'America/Chicago',
  AXJ: 'Asia/Tokyo',
  AXK: 'Asia/Aden',
  AXL: 'Australia/Darwin',
  AXM: 'America/Bogota',
  AXN: 'America/Chicago',
  AXP: 'America/Nassau',
  AXR: 'Pacific/Tahiti',
  AXS: 'America/Chicago',
  AXT: 'Asia/Tokyo',
  AXU: 'Africa/Addis_Ababa',
  AXV: 'America/New_York',
  AXX: 'America/Denver',
  AYA: 'America/Bogota',
  AYC: 'America/Bogota',
  AYD: 'Australia/Darwin',
  AYG: 'America/Bogota',
  AYI: 'America/Bogota',
  AYK: 'Asia/Almaty',
  AYL: 'Australia/Darwin',
  AYM: 'Asia/Dubai',
  AYN: 'Asia/Shanghai',
  AYO: 'America/Asuncion',
  AYP: 'America/Lima',
  AYQ: 'Australia/Darwin',
  AYR: 'Australia/Brisbane',
  AYS: 'America/New_York',
  AYT: 'Europe/Istanbul',
  AYU: 'Pacific/Port_Moresby',
  AYW: 'Asia/Jayapura',
  AYY: 'Asia/Colombo',
  AZA: 'America/Phoenix',
  AZB: 'Pacific/Port_Moresby',
  AZD: 'Asia/Tehran',
  AZG: 'America/Mexico_City',
  AZI: 'Asia/Dubai',
  AZL: 'America/Cuiaba',
  AZN: 'Asia/Tashkent',
  AZO: 'America/Detroit',
  AZP: 'America/Mexico_City',
  AZR: 'Africa/Algiers',
  AZS: 'America/Santo_Domingo',
  AZT: 'America/Bogota',
  AZZ: 'Africa/Luanda',
  BAA: 'Pacific/Port_Moresby',
  BAB: 'America/Los_Angeles',
  BAC: 'America/Bogota',
  BAD: 'America/Chicago',
  BAE: 'Europe/Paris',
  BAF: 'America/New_York',
  BAG: 'Asia/Manila',
  BAH: 'Asia/Bahrain',
  BAI: 'America/Costa_Rica',
  BAJ: 'Pacific/Port_Moresby',
  BAK: 'Asia/Baku',
  BAL: 'Europe/Istanbul',
  BAM: 'America/Los_Angeles',
  BAN: 'Africa/Lubumbashi',
  BAP: 'Pacific/Port_Moresby',
  BAQ: 'America/Bogota',
  BAR: 'Asia/Shanghai',
  BAS: 'Pacific/Guadalcanal',
  BAT: 'America/Sao_Paulo',
  BAV: 'Asia/Shanghai',
  BAW: 'Africa/Libreville',
  BAX: 'Asia/Omsk',
  BAY: 'Europe/Bucharest',
  BAZ: 'America/Manaus',
  BBA: 'America/Santiago',
  BBB: 'America/Chicago',
  BBC: 'America/Chicago',
  BBD: 'America/Chicago',
  BBG: 'Pacific/Tarawa',
  BBH: 'Europe/Berlin',
  BBI: 'Asia/Kolkata',
  BBJ: 'Europe/Berlin',
  BBK: 'Africa/Gaborone',
  BBL: 'Australia/Brisbane',
  BBM: 'Asia/Phnom_Penh',
  BBN: 'Asia/Kuching',
  BBO: 'Africa/Mogadishu',
  BBP: 'Europe/London',
  BBQ: 'America/Antigua',
  BBR: 'America/Guadeloupe',
  BBS: 'Europe/London',
  BBT: 'Africa/Bangui',
  BBU: 'Europe/Bucharest',
  BBV: 'Africa/Abidjan',
  BBW: 'America/Chicago',
  BBX: 'America/New_York',
  BBY: 'Africa/Bangui',
  BBZ: 'Africa/Lusaka',
  BCA: 'America/Havana',
  BCB: 'America/New_York',
  BCC: 'America/Anchorage',
  BCD: 'Asia/Manila',
  BCE: 'America/Denver',
  BCF: 'Africa/Bangui',
  BCG: 'America/Guyana',
  BCH: 'Asia/Dili',
  BCI: 'Australia/Brisbane',
  BCK: 'Australia/Brisbane',
  BCL: 'America/Costa_Rica',
  BCM: 'Europe/Bucharest',
  BCN: 'Europe/Madrid',
  BCO: 'Africa/Addis_Ababa',
  BCP: 'Pacific/Port_Moresby',
  BCQ: 'Africa/Tripoli',
  BCR: 'America/Manaus',
  BCS: 'America/Chicago',
  BCT: 'America/New_York',
  BCU: 'Africa/Lagos',
  BCV: 'America/Belize',
  BCW: 'Africa/Maputo',
  BCX: 'Asia/Yekaterinburg',
  BCY: 'Africa/Addis_Ababa',
  BCZ: 'Australia/Darwin',
  BDA: 'Atlantic/Bermuda',
  BDB: 'Australia/Brisbane',
  BDC: 'America/Fortaleza',
  BDD: 'Australia/Brisbane',
  BDE: 'America/Chicago',
  BDF: 'America/Chicago',
  BDG: 'America/Denver',
  BDH: 'Asia/Tehran',
  BDI: 'Indian/Mahe',
  BDJ: 'Asia/Makassar',
  BDK: 'Africa/Abidjan',
  BDL: 'America/New_York',
  BDM: 'Europe/Istanbul',
  BDN: 'Asia/Karachi',
  BDO: 'Asia/Jakarta',
  BDP: 'Asia/Kathmandu',
  BDQ: 'Asia/Kolkata',
  BDR: 'America/New_York',
  BDS: 'Europe/Rome',
  BDT: 'Africa/Kinshasa',
  BDU: 'Europe/Oslo',
  BDV: 'Africa/Lubumbashi',
  BDW: 'Australia/Perth',
  BDX: 'America/Denver',
  BDY: 'America/Los_Angeles',
  BDZ: 'Pacific/Port_Moresby',
  BEA: 'Pacific/Port_Moresby',
  BEB: 'Europe/London',
  BEC: 'America/Chicago',
  BED: 'America/New_York',
  BEE: 'Australia/Perth',
  BEF: 'America/Managua',
  BEG: 'Europe/Belgrade',
  BEH: 'America/Detroit',
  BEI: 'Africa/Addis_Ababa',
  BEJ: 'Asia/Makassar',
  BEK: 'Asia/Kolkata',
  BEL: 'America/Belem',
  BEM: 'Africa/Casablanca',
  BEN: 'Africa/Tripoli',
  BEO: 'Australia/Sydney',
  BEP: 'Asia/Kolkata',
  BEQ: 'Europe/London',
  BER: 'Europe/Berlin',
  BES: 'Europe/Paris',
  BET: 'America/Anchorage',
  BEU: 'Australia/Brisbane',
  BEV: 'Asia/Jerusalem',
  BEW: 'Africa/Maputo',
  BEX: 'Europe/London',
  BEY: 'Asia/Beirut',
  BEZ: 'Pacific/Tarawa',
  BFA: 'America/Asuncion',
  BFC: 'Australia/Brisbane',
  BFD: 'America/New_York',
  BFE: 'Europe/Berlin',
  BFF: 'America/Denver',
  BFG: 'America/Denver',
  BFH: 'America/Sao_Paulo',
  BFI: 'America/Los_Angeles',
  BFJ: 'Asia/Shanghai',
  BFK: 'America/Denver',
  BFL: 'America/Los_Angeles',
  BFM: 'America/Chicago',
  BFN: 'Africa/Johannesburg',
  BFO: 'Africa/Harare',
  BFP: 'America/New_York',
  BFQ: 'America/Panama',
  BFR: 'America/Indiana/Indianapolis',
  BFS: 'Europe/London',
  BFT: 'America/New_York',
  BFU: 'Asia/Shanghai',
  BFV: 'Asia/Bangkok',
  BFW: 'Africa/Algiers',
  BFX: 'Africa/Douala',
  BGA: 'America/Bogota',
  BGB: 'Africa/Libreville',
  BGC: 'Europe/Lisbon',
  BGD: 'America/Chicago',
  BGE: 'America/New_York',
  BGF: 'Africa/Bangui',
  BGG: 'Europe/Istanbul',
  BGH: 'Africa/Nouakchott',
  BGI: 'America/Barbados',
  BGJ: 'Atlantic/Reykjavik',
  BGK: 'America/Belize',
  BGL: 'Asia/Kathmandu',
  BGM: 'America/New_York',
  BGN: 'Asia/Magadan',
  BGO: 'Europe/Oslo',
  BGP: 'Africa/Libreville',
  BGQ: 'America/Anchorage',
  BGR: 'America/New_York',
  BGT: 'America/Phoenix',
  BGU: 'Africa/Bangui',
  BGV: 'America/Sao_Paulo',
  BGW: 'Asia/Baghdad',
  BGX: 'America/Sao_Paulo',
  BGY: 'Europe/Rome',
  BGZ: 'Europe/Lisbon',
  BHA: 'America/Guayaquil',
  BHB: 'America/New_York',
  BHD: 'Europe/London',
  BHE: 'Pacific/Auckland',
  BHF: 'America/Bogota',
  BHG: 'America/Tegucigalpa',
  BHH: 'Asia/Riyadh',
  BHI: 'America/Argentina/Buenos_Aires',
  BHJ: 'Asia/Kolkata',
  BHK: 'Asia/Samarkand',
  BHL: 'America/Tijuana',
  BHM: 'America/Chicago',
  BHN: 'Asia/Aden',
  BHO: 'Asia/Kolkata',
  BHP: 'Asia/Kathmandu',
  BHQ: 'Australia/Broken_Hill',
  BHR: 'Asia/Kathmandu',
  BHS: 'Australia/Sydney',
  BHT: 'Australia/Brisbane',
  BHU: 'Asia/Kolkata',
  BHV: 'Asia/Karachi',
  BHW: 'Asia/Karachi',
  BHX: 'Europe/London',
  BHY: 'Asia/Shanghai',
  BHZ: 'America/Sao_Paulo',
  BIA: 'Europe/Paris',
  BIB: 'Africa/Mogadishu',
  BID: 'America/New_York',
  BIE: 'America/Chicago',
  BIF: 'America/Denver',
  BIG: 'America/Anchorage',
  BIH: 'America/Los_Angeles',
  BII: 'Pacific/Kwajalein',
  BIJ: 'Pacific/Port_Moresby',
  BIK: 'Asia/Jayapura',
  BIL: 'America/Denver',
  BIM: 'America/Nassau',
  BIN: 'Asia/Kabul',
  BIO: 'Europe/Madrid',
  BIP: 'Australia/Brisbane',
  BIQ: 'Europe/Paris',
  BIR: 'Asia/Kathmandu',
  BIS: 'America/Chicago',
  BIT: 'Asia/Kathmandu',
  BIU: 'Atlantic/Reykjavik',
  BIV: 'Africa/Bangui',
  BIW: 'Australia/Perth',
  BIX: 'America/Chicago',
  BIY: 'Africa/Johannesburg',
  BIZ: 'Pacific/Port_Moresby',
  BJA: 'Africa/Algiers',
  BJB: 'Asia/Tehran',
  BJC: 'America/Denver',
  BJD: 'Atlantic/Reykjavik',
  BJE: 'Africa/Khartoum',
  BJF: 'Europe/Oslo',
  BJG: 'Asia/Makassar',
  BJH: 'Asia/Kathmandu',
  BJI: 'America/Chicago',
  BJJ: 'America/New_York',
  BJK: 'Asia/Jayapura',
  BJL: 'Africa/Banjul',
  BJM: 'Africa/Bujumbura',
  BJN: 'Africa/Maputo',
  BJO: 'America/La_Paz',
  BJP: 'America/Sao_Paulo',
  BJQ: 'Asia/Muscat',
  BJR: 'Africa/Addis_Ababa',
  BJS: 'Asia/Shanghai',
  BJT: 'Asia/Colombo',
  BJU: 'Asia/Kathmandu',
  BJV: 'Europe/Istanbul',
  BJW: 'Asia/Makassar',
  BJX: 'America/Mexico_City',
  BJY: 'Europe/Belgrade',
  BJZ: 'Europe/Madrid',
  BKB: 'Asia/Kolkata',
  BKC: 'America/Anchorage',
  BKD: 'America/Chicago',
  BKE: 'America/Los_Angeles',
  BKF: 'America/Anchorage',
  BKG: 'America/Chicago',
  BKH: 'Pacific/Honolulu',
  BKI: 'Asia/Kuching',
  BKJ: 'Africa/Conakry',
  BKK: 'Asia/Bangkok',
  BKL: 'America/New_York',
  BKM: 'Asia/Kuching',
  BKN: 'Asia/Ashgabat',
  BKO: 'Africa/Bamako',
  BKP: 'Australia/Brisbane',
  BKQ: 'Australia/Brisbane',
  BKR: 'Africa/Ndjamena',
  BKS: 'Asia/Jakarta',
  BKT: 'America/New_York',
  BKU: 'Indian/Antananarivo',
  BKV: 'Asia/Shanghai',
  BKW: 'America/New_York',
  BKX: 'America/Chicago',
  BKY: 'Africa/Lubumbashi',
  BKZ: 'Africa/Dar_es_Salaam',
  BLA: 'America/Caracas',
  BLB: 'America/Panama',
  BLC: 'Africa/Douala',
  BLD: 'America/Los_Angeles',
  BLE: 'Europe/Stockholm',
  BLF: 'America/New_York',
  BLG: 'Asia/Kuching',
  BLH: 'America/Los_Angeles',
  BLI: 'America/Los_Angeles',
  BLJ: 'Africa/Algiers',
  BLK: 'Europe/London',
  BLL: 'Europe/Copenhagen',
  BLM: 'America/New_York',
  BLN: 'Australia/Melbourne',
  BLO: 'Atlantic/Reykjavik',
  BLP: 'America/Lima',
  BLQ: 'Europe/Rome',
  BLR: 'Asia/Kolkata',
  BLS: 'Australia/Brisbane',
  BLT: 'Australia/Brisbane',
  BLU: 'America/Los_Angeles',
  BLV: 'America/Chicago',
  BLW: 'Africa/Mogadishu',
  BLX: 'Europe/Rome',
  BLY: 'Europe/Dublin',
  BLZ: 'Africa/Blantyre',
  BMA: 'Europe/Stockholm',
  BMB: 'Africa/Kinshasa',
  BMC: 'America/Denver',
  BMD: 'Indian/Antananarivo',
  BME: 'Australia/Perth',
  BMF: 'Africa/Bangui',
  BMG: 'America/Indiana/Indianapolis',
  BMH: 'Pacific/Port_Moresby',
  BMI: 'America/Chicago',
  BMJ: 'America/Guyana',
  BMK: 'Europe/Berlin',
  BML: 'America/New_York',
  BMM: 'Africa/Libreville',
  BMN: 'Asia/Baghdad',
  BMO: 'Asia/Yangon',
  BMP: 'Australia/Brisbane',
  BMQ: 'Africa/Nairobi',
  BMR: 'Europe/Berlin',
  BMS: 'America/Bahia',
  BMT: 'America/Chicago',
  BMU: 'Asia/Makassar',
  BMV: 'Asia/Ho_Chi_Minh',
  BMW: 'Africa/Algiers',
  BMX: 'America/Anchorage',
  BMY: 'Pacific/Noumea',
  BMZ: 'Pacific/Port_Moresby',
  BNA: 'America/Chicago',
  BNB: 'Africa/Kinshasa',
  BNC: 'Africa/Lubumbashi',
  BND: 'Asia/Tehran',
  BNE: 'Australia/Brisbane',
  BNF: 'America/Sitka',
  BNG: 'America/Los_Angeles',
  BNH: 'America/New_York',
  BNI: 'Africa/Lagos',
  BNK: 'Australia/Sydney',
  BNL: 'America/New_York',
  BNM: 'Pacific/Port_Moresby',
  BNN: 'Europe/Oslo',
  BNO: 'America/Los_Angeles',
  BNP: 'Asia/Karachi',
  BNQ: 'Asia/Manila',
  BNR: 'Africa/Ouagadougou',
  BNS: 'America/Caracas',
  BNT: 'Pacific/Port_Moresby',
  BNU: 'America/Sao_Paulo',
  BNV: 'Pacific/Port_Moresby',
  BNW: 'America/Chicago',
  BNX: 'Europe/Sarajevo',
  BNY: 'Pacific/Guadalcanal',
  BNZ: 'Pacific/Port_Moresby',
  BOA: 'Africa/Kinshasa',
  BOB: 'Pacific/Tahiti',
  BOC: 'America/Panama',
  BOD: 'Europe/Paris',
  BOE: 'Africa/Brazzaville',
  BOG: 'America/Bogota',
  BOH: 'Europe/London',
  BOI: 'America/Boise',
  BOJ: 'Europe/Sofia',
  BOK: 'America/Los_Angeles',
  BOL: 'Europe/London',
  BOM: 'Asia/Kolkata',
  BON: 'America/Kralendijk',
  BOO: 'Europe/Oslo',
  BOP: 'Africa/Bangui',
  BOQ: 'Pacific/Port_Moresby',
  BOS: 'America/New_York',
  BOT: 'Pacific/Port_Moresby',
  BOU: 'Europe/Paris',
  BOV: 'Pacific/Port_Moresby',
  BOW: 'America/New_York',
  BOX: 'Australia/Darwin',
  BOY: 'Africa/Ouagadougou',
  BOZ: 'Africa/Bangui',
  BPB: 'Pacific/Port_Moresby',
  BPC: 'Africa/Douala',
  BPD: 'Pacific/Port_Moresby',
  BPE: 'Asia/Shanghai',
  BPF: 'Pacific/Guadalcanal',
  BPG: 'America/Cuiaba',
  BPH: 'Asia/Manila',
  BPI: 'America/Denver',
  BPK: 'Pacific/Port_Moresby',
  BPL: 'Asia/Shanghai',
  BPM: 'Asia/Kolkata',
  BPN: 'Asia/Makassar',
  BPS: 'America/Bahia',
  BPT: 'America/Chicago',
  BPX: 'Asia/Urumqi',
  BPY: 'Indian/Antananarivo',
  BQA: 'Asia/Manila',
  BQB: 'Australia/Perth',
  BQE: 'Africa/Bissau',
  BQG: 'Asia/Vladivostok',
  BQH: 'Europe/London',
  BQI: 'Africa/Windhoek',
  BQJ: 'Asia/Vladivostok',
  BQK: 'America/New_York',
  BQL: 'Australia/Brisbane',
  BQN: 'America/Puerto_Rico',
  BQO: 'Africa/Abidjan',
  BQQ: 'America/Bahia',
  BQS: 'Asia/Yakutsk',
  BQT: 'Europe/Minsk',
  BQU: 'America/St_Vincent',
  BQV: 'America/Juneau',
  BQW: 'Australia/Perth',
  BRA: 'America/Bahia',
  BRB: 'America/Fortaleza',
  BRC: 'America/Argentina/Salta',
  BRD: 'America/Chicago',
  BRE: 'Europe/Berlin',
  BRH: 'Pacific/Port_Moresby',
  BRI: 'Europe/Rome',
  BRJ: 'Australia/Melbourne',
  BRK: 'Australia/Sydney',
  BRL: 'America/Chicago',
  BRM: 'America/Caracas',
  BRN: 'Europe/Zurich',
  BRO: 'America/Chicago',
  BRP: 'Pacific/Port_Moresby',
  BRQ: 'Europe/Prague',
  BRR: 'Europe/London',
  BRS: 'Europe/London',
  BRT: 'Australia/Darwin',
  BRU: 'Europe/Brussels',
  BRV: 'Europe/Berlin',
  BRW: 'America/Anchorage',
  BRX: 'America/Santo_Domingo',
  BRY: 'America/New_York',
  BSA: 'Africa/Mogadishu',
  BSB: 'America/Sao_Paulo',
  BSC: 'America/Bogota',
  BSD: 'Asia/Shanghai',
  BSE: 'Asia/Kuching',
  BSF: 'Pacific/Honolulu',
  BSG: 'Africa/Malabo',
  BSI: 'Asia/Manila',
  BSJ: 'Australia/Melbourne',
  BSK: 'Africa/Algiers',
  BSL: 'Europe/Paris',
  BSM: 'Asia/Tehran',
  BSN: 'Africa/Bangui',
  BSO: 'Asia/Manila',
  BSP: 'Pacific/Port_Moresby',
  BSQ: 'America/Phoenix',
  BSR: 'Asia/Baghdad',
  BSS: 'America/Fortaleza',
  BST: 'Asia/Kabul',
  BSU: 'Africa/Kinshasa',
  BSV: 'Indian/Antananarivo',
  BSW: 'America/Anchorage',
  BSX: 'Asia/Yangon',
  BSY: 'Africa/Mogadishu',
  BTA: 'Africa/Douala',
  BTB: 'Africa/Brazzaville',
  BTC: 'Asia/Colombo',
  BTD: 'Australia/Darwin',
  BTE: 'Africa/Freetown',
  BTF: 'America/Denver',
  BTG: 'Africa/Bangui',
  BTH: 'Asia/Jakarta',
  BTI: 'America/Anchorage',
  BTJ: 'Asia/Jakarta',
  BTK: 'Asia/Irkutsk',
  BTL: 'America/Detroit',
  BTM: 'America/Denver',
  BTN: 'America/New_York',
  BTO: 'America/Paramaribo',
  BTP: 'America/New_York',
  BTQ: 'Africa/Kigali',
  BTR: 'America/Chicago',
  BTS: 'Europe/Bratislava',
  BTT: 'America/Anchorage',
  BTU: 'Asia/Kuching',
  BTV: 'America/New_York',
  BTW: 'Asia/Jakarta',
  BTX: 'Australia/Brisbane',
  BTY: 'America/Los_Angeles',
  BUA: 'Pacific/Bougainville',
  BUB: 'America/Chicago',
  BUC: 'Australia/Brisbane',
  BUD: 'Europe/Budapest',
  BUE: 'America/Argentina/Buenos_Aires',
  BUF: 'America/New_York',
  BUG: 'Africa/Luanda',
  BUH: 'Europe/Bucharest',
  BUI: 'Asia/Jayapura',
  BUJ: 'Africa/Algiers',
  BUK: 'Asia/Aden',
  BUL: 'Pacific/Port_Moresby',
  BUM: 'America/Chicago',
  BUN: 'America/Bogota',
  BUO: 'Africa/Mogadishu',
  BUP: 'Asia/Kolkata',
  BUQ: 'Africa/Harare',
  BUR: 'America/Los_Angeles',
  BUS: 'Asia/Tbilisi',
  BUT: 'Asia/Thimphu',
  BUU: 'Asia/Jakarta',
  BUV: 'America/Montevideo',
  BUW: 'Asia/Makassar',
  BUX: 'Africa/Lubumbashi',
  BUY: 'Australia/Perth',
  BUZ: 'Asia/Tehran',
  BVA: 'Europe/Paris',
  BVB: 'America/Boa_Vista',
  BVC: 'Atlantic/Cape_Verde',
  BVE: 'Europe/Paris',
  BVF: 'Pacific/Fiji',
  BVG: 'Europe/Oslo',
  BVH: 'America/Cuiaba',
  BVI: 'Australia/Brisbane',
  BVK: 'America/La_Paz',
  BVL: 'America/La_Paz',
  BVM: 'America/Bahia',
  BVO: 'America/Chicago',
  BVP: 'Pacific/Port_Moresby',
  BVR: 'Atlantic/Cape_Verde',
  BVS: 'America/Santarem',
  BVU: 'America/Anchorage',
  BVV: 'Asia/Sakhalin',
  BVW: 'Australia/Brisbane',
  BVX: 'America/Chicago',
  BVY: 'America/New_York',
  BVZ: 'Australia/Perth',
  BWA: 'Asia/Kathmandu',
  BWB: 'Australia/Perth',
  BWC: 'America/Los_Angeles',
  BWD: 'America/Chicago',
  BWE: 'Europe/Berlin',
  BWF: 'Europe/London',
  BWG: 'America/Chicago',
  BWH: 'Asia/Kuala_Lumpur',
  BWI: 'America/New_York',
  BWJ: 'Pacific/Port_Moresby',
  BWK: 'Europe/Zagreb',
  BWL: 'America/Chicago',
  BWM: 'America/Denver',
  BWN: 'Asia/Brunei',
  BWO: 'Europe/Saratov',
  BWP: 'Pacific/Port_Moresby',
  BWQ: 'Australia/Sydney',
  BWT: 'Australia/Hobart',
  BWU: 'Australia/Sydney',
  BWW: 'America/Havana',
  BWX: 'Asia/Jakarta',
  BXA: 'America/Chicago',
  BXB: 'Asia/Jayapura',
  BXD: 'Asia/Jayapura',
  BXE: 'Africa/Dakar',
  BXF: 'Australia/Perth',
  BXG: 'Australia/Melbourne',
  BXH: 'Asia/Almaty',
  BXI: 'Africa/Abidjan',
  BXJ: 'Asia/Almaty',
  BXK: 'America/Phoenix',
  BXL: 'Pacific/Fiji',
  BXM: 'Asia/Jayapura',
  BXN: 'Europe/Istanbul',
  BXO: 'Europe/Zurich',
  BXP: 'Europe/Warsaw',
  BXR: 'Asia/Tehran',
  BXS: 'America/Los_Angeles',
  BXT: 'Asia/Makassar',
  BXU: 'Asia/Manila',
  BXV: 'Atlantic/Reykjavik',
  BXW: 'Asia/Jakarta',
  BXX: 'Africa/Mogadishu',
  BXY: 'Asia/Qostanay',
  BXZ: 'Pacific/Port_Moresby',
  BYA: 'America/Anchorage',
  BYB: 'Asia/Muscat',
  BYC: 'America/La_Paz',
  BYD: 'Asia/Aden',
  BYF: 'Europe/Paris',
  BYG: 'America/Denver',
  BYH: 'America/Chicago',
  BYI: 'America/Boise',
  BYJ: 'Europe/Lisbon',
  BYK: 'Africa/Abidjan',
  BYL: 'Africa/Monrovia',
  BYM: 'America/Havana',
  BYN: 'Asia/Ulaanbaatar',
  BYO: 'America/Campo_Grande',
  BYP: 'Australia/Perth',
  BYQ: 'Asia/Makassar',
  BYR: 'Europe/Copenhagen',
  BYS: 'America/Los_Angeles',
  BYT: 'Europe/Dublin',
  BYU: 'Europe/Berlin',
  BYV: 'Asia/Colombo',
  BYW: 'America/Los_Angeles',
  BYX: 'Australia/Darwin',
  BZA: 'America/Managua',
  BZB: 'Africa/Maputo',
  BZC: 'America/Sao_Paulo',
  BZD: 'Australia/Sydney',
  BZE: 'America/Belize',
  BZF: 'America/Los_Angeles',
  BZG: 'Europe/Warsaw',
  BZH: 'Africa/Harare',
  BZI: 'Europe/Istanbul',
  BZK: 'Europe/Moscow',
  BZL: 'Asia/Dhaka',
  BZM: 'Indian/Antananarivo',
  BZN: 'America/Denver',
  BZO: 'Europe/Rome',
  BZP: 'Australia/Brisbane',
  BZR: 'Europe/Paris',
  BZT: 'America/Chicago',
  BZU: 'Africa/Lubumbashi',
  BZV: 'Africa/Brazzaville',
  BZX: 'Asia/Shanghai',
  BZY: 'Europe/Chisinau',
  BZZ: 'Europe/London',
  CAA: 'America/Tegucigalpa',
  CAB: 'Africa/Luanda',
  CAC: 'America/Sao_Paulo',
  CAD: 'America/Detroit',
  CAE: 'America/New_York',
  CAF: 'America/Manaus',
  CAG: 'Europe/Rome',
  CAH: 'Asia/Ho_Chi_Minh',
  CAI: 'Africa/Cairo',
  CAJ: 'America/Caracas',
  CAK: 'America/New_York',
  CAL: 'Europe/London',
  CAM: 'America/La_Paz',
  CAN: 'Asia/Shanghai',
  CAO: 'America/Denver',
  CAP: 'America/Port-au-Prince',
  CAQ: 'America/Bogota',
  CAR: 'America/New_York',
  CAS: 'Africa/Casablanca',
  CAT: 'Europe/Lisbon',
  CAU: 'America/Recife',
  CAV: 'Africa/Luanda',
  CAW: 'America/Sao_Paulo',
  CAX: 'Europe/London',
  CAY: 'America/Cayenne',
  CAZ: 'Australia/Sydney',
  CBB: 'America/La_Paz',
  CBC: 'Australia/Perth',
  CBD: 'Asia/Kolkata',
  CBE: 'America/New_York',
  CBF: 'America/Chicago',
  CBG: 'Europe/London',
  CBH: 'Africa/Algiers',
  CBI: 'Australia/Hobart',
  CBJ: 'America/Santo_Domingo',
  CBK: 'America/Chicago',
  CBL: 'America/Caracas',
  CBM: 'America/Chicago',
  CBN: 'Asia/Jakarta',
  CBO: 'Asia/Manila',
  CBP: 'Europe/Lisbon',
  CBQ: 'Africa/Lagos',
  CBR: 'Australia/Sydney',
  CBS: 'America/Caracas',
  CBT: 'Africa/Luanda',
  CBU: 'Europe/Berlin',
  CBV: 'America/Guatemala',
  CBW: 'America/Sao_Paulo',
  CBX: 'Australia/Sydney',
  CBY: 'Australia/Brisbane',
  CCA: 'America/La_Paz',
  CCB: 'America/Los_Angeles',
  CCC: 'America/Havana',
  CCE: 'Africa/Cairo',
  CCF: 'Europe/Paris',
  CCG: 'America/Chicago',
  CCH: 'America/Santiago',
  CCI: 'America/Sao_Paulo',
  CCJ: 'Asia/Kolkata',
  CCK: 'Indian/Cocos',
  CCL: 'Australia/Brisbane',
  CCM: 'America/Sao_Paulo',
  CCN: 'Asia/Kabul',
  CCO: 'America/Bogota',
  CCP: 'America/Santiago',
  CCR: 'America/Los_Angeles',
  CCS: 'America/Caracas',
  CCT: 'America/Argentina/Salta',
  CCU: 'Asia/Kolkata',
  CCV: 'Pacific/Efate',
  CCW: 'Australia/Adelaide',
  CCX: 'America/Cuiaba',
  CCY: 'America/Chicago',
  CCZ: 'America/Nassau',
  CDA: 'Australia/Darwin',
  CDB: 'America/Nome',
  CDC: 'America/Denver',
  CDD: 'America/Tegucigalpa',
  CDE: 'Asia/Shanghai',
  CDG: 'Europe/Paris',
  CDH: 'America/Chicago',
  CDI: 'America/Sao_Paulo',
  CDJ: 'America/Araguaina',
  CDK: 'America/New_York',
  CDL: 'America/Anchorage',
  CDN: 'America/New_York',
  CDO: 'Africa/Johannesburg',
  CDP: 'Asia/Kolkata',
  CDQ: 'Australia/Brisbane',
  CDR: 'America/Denver',
  CDS: 'America/Chicago',
  CDT: 'Europe/Madrid',
  CDU: 'Australia/Sydney',
  CDV: 'America/Anchorage',
  CDW: 'America/New_York',
  CDY: 'Asia/Manila',
  CEA: 'America/Chicago',
  CEB: 'Asia/Manila',
  CEC: 'America/Los_Angeles',
  CED: 'Australia/Adelaide',
  CEE: 'Europe/Moscow',
  CEF: 'America/New_York',
  CEG: 'Europe/London',
  CEH: 'Africa/Blantyre',
  CEI: 'Asia/Bangkok',
  CEK: 'Asia/Yekaterinburg',
  CEM: 'America/Anchorage',
  CEN: 'America/Hermosillo',
  CEO: 'Africa/Luanda',
  CEP: 'America/La_Paz',
  CEQ: 'Europe/Paris',
  CER: 'Europe/Paris',
  CES: 'Australia/Sydney',
  CET: 'Europe/Paris',
  CEU: 'America/New_York',
  CEV: 'America/Indiana/Indianapolis',
  CEW: 'America/Chicago',
  CEX: 'America/Anchorage',
  CEY: 'America/Chicago',
  CEZ: 'America/Denver',
  CFB: 'America/Sao_Paulo',
  CFC: 'America/Sao_Paulo',
  CFD: 'America/Chicago',
  CFE: 'Europe/Paris',
  CFF: 'Africa/Luanda',
  CFG: 'America/Havana',
  CFH: 'Australia/Adelaide',
  CFI: 'Australia/Darwin',
  CFK: 'Africa/Algiers',
  CFM: 'America/Edmonton',
  CFN: 'Europe/Dublin',
  CFO: 'America/Cuiaba',
  CFP: 'Australia/Brisbane',
  CFQ: 'America/Creston',
  CFR: 'Europe/Paris',
  CFS: 'Australia/Sydney',
  CFT: 'America/Phoenix',
  CFU: 'Europe/Athens',
  CFV: 'America/Chicago',
  CGA: 'America/Juneau',
  CGB: 'America/Cuiaba',
  CGC: 'Pacific/Port_Moresby',
  CGD: 'Asia/Shanghai',
  CGE: 'America/New_York',
  CGF: 'America/New_York',
  CGG: 'Asia/Manila',
  CGH: 'America/Sao_Paulo',
  CGI: 'America/Chicago',
  CGJ: 'Africa/Lusaka',
  CGK: 'Asia/Jakarta',
  CGM: 'Asia/Manila',
  CGN: 'Europe/Berlin',
  CGO: 'Asia/Shanghai',
  CGP: 'Asia/Dhaka',
  CGQ: 'Asia/Shanghai',
  CGR: 'America/Campo_Grande',
  CGS: 'America/New_York',
  CGT: 'Africa/Nouakchott',
  CGV: 'Australia/Perth',
  CGY: 'Asia/Manila',
  CGZ: 'America/Phoenix',
  CHA: 'America/New_York',
  CHB: 'Asia/Karachi',
  CHC: 'Pacific/Auckland',
  CHF: 'Asia/Seoul',
  CHG: 'Asia/Shanghai',
  CHH: 'America/Lima',
  CHI: 'America/Chicago',
  CHJ: 'Africa/Harare',
  CHK: 'America/Chicago',
  CHL: 'America/Boise',
  CHM: 'America/Lima',
  CHN: 'Asia/Seoul',
  CHO: 'America/New_York',
  CHP: 'America/Anchorage',
  CHQ: 'Europe/Athens',
  CHR: 'Europe/Paris',
  CHS: 'America/New_York',
  CHT: 'Pacific/Chatham',
  CHU: 'America/Anchorage',
  CHV: 'Europe/Lisbon',
  CHX: 'America/Panama',
  CHY: 'Pacific/Guadalcanal',
  CHZ: 'America/Los_Angeles',
  CIA: 'Europe/Rome',
  CIC: 'America/Los_Angeles',
  CID: 'America/Chicago',
  CIE: 'Australia/Perth',
  CIF: 'Asia/Shanghai',
  CIG: 'America/Denver',
  CIH: 'Asia/Shanghai',
  CII: 'Europe/Istanbul',
  CIJ: 'America/La_Paz',
  CIK: 'America/Anchorage',
  CIL: 'America/Nome',
  CIM: 'America/Bogota',
  CIN: 'America/Chicago',
  CIO: 'America/Asuncion',
  CIP: 'Africa/Lusaka',
  CIQ: 'America/Guatemala',
  CIR: 'America/Chicago',
  CIS: 'Pacific/Enderbury',
  CIT: 'Asia/Almaty',
  CIU: 'America/Detroit',
  CIW: 'America/St_Vincent',
  CIX: 'America/Lima',
  CIY: 'Europe/Rome',
  CIZ: 'America/Manaus',
  CJA: 'America/Lima',
  CJB: 'Asia/Kolkata',
  CJC: 'America/Santiago',
  CJD: 'America/Bogota',
  CJF: 'Australia/Perth',
  CJH: 'America/Vancouver',
  CJJ: 'Asia/Seoul',
  CJL: 'Asia/Karachi',
  CJM: 'Asia/Bangkok',
  CJN: 'Asia/Jakarta',
  CJS: 'America/Ojinaga',
  CJT: 'America/Mexico_City',
  CJU: 'Asia/Seoul',
  CKA: 'America/Chicago',
  CKB: 'America/New_York',
  CKC: 'Europe/Kiev',
  CKD: 'America/Anchorage',
  CKE: 'America/Los_Angeles',
  CKG: 'Asia/Shanghai',
  CKH: 'Asia/Magadan',
  CKI: 'Australia/Darwin',
  CKK: 'America/Chicago',
  CKL: 'Europe/Moscow',
  CKM: 'America/Chicago',
  CKN: 'America/Chicago',
  CKO: 'America/Sao_Paulo',
  CKR: 'America/Los_Angeles',
  CKS: 'America/Araguaina',
  CKT: 'Asia/Tehran',
  CKU: 'America/Anchorage',
  CKV: 'America/Chicago',
  CKW: 'Australia/Perth',
  CKX: 'America/Anchorage',
  CKY: 'Africa/Conakry',
  CKZ: 'Europe/Istanbul',
  CLA: 'Asia/Dhaka',
  CLD: 'America/Los_Angeles',
  CLE: 'America/New_York',
  CLG: 'America/Los_Angeles',
  CLH: 'Australia/Sydney',
  CLI: 'America/Chicago',
  CLJ: 'Europe/Bucharest',
  CLK: 'America/Chicago',
  CLL: 'America/Chicago',
  CLM: 'America/Los_Angeles',
  CLN: 'America/Araguaina',
  CLO: 'America/Bogota',
  CLP: 'America/Anchorage',
  CLQ: 'America/Mexico_City',
  CLR: 'America/Los_Angeles',
  CLS: 'America/Los_Angeles',
  CLT: 'America/New_York',
  CLU: 'America/Indiana/Indianapolis',
  CLV: 'America/Sao_Paulo',
  CLW: 'America/New_York',
  CLX: 'America/Argentina/Cordoba',
  CLY: 'Europe/Paris',
  CLZ: 'America/Caracas',
  CMA: 'Australia/Brisbane',
  CMB: 'Asia/Colombo',
  CMC: 'America/Fortaleza',
  CMD: 'Australia/Sydney',
  CME: 'America/Merida',
  CMF: 'Europe/Paris',
  CMG: 'America/Campo_Grande',
  CMH: 'America/New_York',
  CMI: 'America/Chicago',
  CMJ: 'Asia/Taipei',
  CMK: 'Africa/Blantyre',
  CML: 'Australia/Brisbane',
  CMM: 'America/Guatemala',
  CMN: 'Africa/Casablanca',
  CMO: 'Africa/Mogadishu',
  CMP: 'America/Santarem',
  CMQ: 'Australia/Brisbane',
  CMR: 'Europe/Paris',
  CMS: 'Africa/Mogadishu',
  CMT: 'America/Belem',
  CMU: 'Pacific/Port_Moresby',
  CMV: 'Pacific/Auckland',
  CMW: 'America/Havana',
  CMX: 'America/Detroit',
  CMY: 'America/Chicago',
  CMZ: 'Africa/Maputo',
  CNA: 'America/Hermosillo',
  CNB: 'Australia/Sydney',
  CNC: 'Australia/Brisbane',
  CND: 'Europe/Bucharest',
  CNE: 'America/Denver',
  CNF: 'America/Sao_Paulo',
  CNG: 'Europe/Paris',
  CNH: 'America/New_York',
  CNI: 'Asia/Shanghai',
  CNJ: 'Australia/Brisbane',
  CNK: 'America/Chicago',
  CNL: 'Europe/Copenhagen',
  CNM: 'America/Denver',
  CNN: 'Asia/Kolkata',
  CNO: 'America/Los_Angeles',
  CNP: 'America/Scoresbysund',
  CNQ: 'America/Argentina/Cordoba',
  CNR: 'America/Santiago',
  CNS: 'Australia/Brisbane',
  CNT: 'America/Argentina/Cordoba',
  CNU: 'America/Chicago',
  CNV: 'America/Bahia',
  CNW: 'America/Chicago',
  CNX: 'Asia/Bangkok',
  CNY: 'America/Denver',
  CNZ: 'Africa/Luanda',
  COA: 'America/Los_Angeles',
  COB: 'Australia/Darwin',
  COC: 'America/Argentina/Cordoba',
  COD: 'America/Denver',
  COE: 'America/Los_Angeles',
  COF: 'America/New_York',
  COG: 'America/Bogota',
  COH: 'Asia/Kolkata',
  COI: 'America/New_York',
  COJ: 'Australia/Sydney',
  COK: 'Asia/Kolkata',
  COL: 'Europe/London',
  COM: 'America/Chicago',
  CON: 'America/New_York',
  COO: 'Africa/Porto-Novo',
  COP: 'America/New_York',
  COQ: 'Asia/Choibalsan',
  COR: 'America/Argentina/Cordoba',
  COS: 'America/Denver',
  COT: 'America/Chicago',
  COU: 'America/Chicago',
  COW: 'America/Santiago',
  COY: 'Australia/Perth',
  COZ: 'America/Santo_Domingo',
  CPA: 'Africa/Monrovia',
  CPB: 'America/Bogota',
  CPC: 'America/Argentina/Salta',
  CPD: 'Australia/Adelaide',
  CPE: 'America/Merida',
  CPF: 'Asia/Jakarta',
  CPG: 'America/Argentina/Buenos_Aires',
  CPH: 'Europe/Copenhagen',
  CPI: 'Pacific/Port_Moresby',
  CPL: 'America/Bogota',
  CPM: 'America/Los_Angeles',
  CPN: 'Pacific/Port_Moresby',
  CPO: 'America/Santiago',
  CPP: 'America/Santiago',
  CPR: 'America/Denver',
  CPS: 'America/Chicago',
  CPT: 'Africa/Johannesburg',
  CPU: 'America/Fortaleza',
  CPV: 'America/Recife',
  CPX: 'America/Puerto_Rico',
  CQA: 'America/Cuiaba',
  CQD: 'Asia/Tehran',
  CQF: 'Europe/Paris',
  CQM: 'Europe/Madrid',
  CQP: 'Australia/Brisbane',
  CQS: 'America/Porto_Velho',
  CQT: 'America/Bogota',
  CRA: 'Europe/Bucharest',
  CRB: 'Australia/Sydney',
  CRC: 'America/Bogota',
  CRD: 'America/Argentina/Catamarca',
  CRE: 'America/New_York',
  CRF: 'Africa/Bangui',
  CRG: 'America/New_York',
  CRH: 'Australia/Brisbane',
  CRI: 'America/Nassau',
  CRJ: 'Australia/Adelaide',
  CRK: 'Asia/Manila',
  CRL: 'Europe/Brussels',
  CRM: 'Asia/Manila',
  CRO: 'America/Los_Angeles',
  CRP: 'America/Chicago',
  CRQ: 'America/Bahia',
  CRR: 'America/Argentina/Cordoba',
  CRS: 'America/Chicago',
  CRT: 'America/Chicago',
  CRU: 'America/Grenada',
  CRV: 'Europe/Rome',
  CRW: 'America/New_York',
  CRX: 'America/Chicago',
  CRY: 'Australia/Perth',
  CRZ: 'Asia/Ashgabat',
  CSA: 'Europe/London',
  CSB: 'Europe/Bucharest',
  CSC: 'America/Costa_Rica',
  CSD: 'Australia/Darwin',
  CSE: 'America/Denver',
  CSF: 'Europe/Paris',
  CSG: 'America/New_York',
  CSH: 'Europe/Moscow',
  CSI: 'Australia/Sydney',
  CSK: 'Africa/Dakar',
  CSM: 'America/Chicago',
  CSN: 'America/Los_Angeles',
  CSO: 'Europe/Berlin',
  CSQ: 'America/Chicago',
  CSR: 'America/Bogota',
  CSS: 'America/Campo_Grande',
  CST: 'Pacific/Fiji',
  CSU: 'America/Sao_Paulo',
  CSV: 'America/Chicago',
  CSX: 'Asia/Shanghai',
  CSY: 'Europe/Moscow',
  CSZ: 'America/Argentina/Buenos_Aires',
  CTA: 'Europe/Rome',
  CTB: 'America/Denver',
  CTC: 'America/Argentina/Catamarca',
  CTD: 'America/Panama',
  CTE: 'America/Panama',
  CTF: 'America/Guatemala',
  CTG: 'America/Bogota',
  CTH: 'America/New_York',
  CTI: 'Africa/Luanda',
  CTK: 'America/Chicago',
  CTL: 'Australia/Brisbane',
  CTM: 'America/Merida',
  CTN: 'Australia/Brisbane',
  CTO: 'America/New_York',
  CTP: 'America/Fortaleza',
  CTQ: 'America/Sao_Paulo',
  CTR: 'Australia/Darwin',
  CTS: 'Asia/Tokyo',
  CTT: 'Europe/Paris',
  CTU: 'Asia/Shanghai',
  CTW: 'America/Phoenix',
  CTX: 'America/New_York',
  CTY: 'America/New_York',
  CTZ: 'America/New_York',
  CUA: 'America/Mazatlan',
  CUB: 'America/New_York',
  CUC: 'America/Bogota',
  CUD: 'Australia/Brisbane',
  CUE: 'America/Guayaquil',
  CUF: 'Europe/Rome',
  CUG: 'Australia/Sydney',
  CUH: 'America/Chicago',
  CUI: 'America/Bogota',
  CUJ: 'Asia/Manila',
  CUK: 'America/Belize',
  CUL: 'America/Mazatlan',
  CUM: 'America/Caracas',
  CUN: 'America/Cancun',
  CUO: 'America/Bogota',
  CUP: 'America/Caracas',
  CUQ: 'Australia/Brisbane',
  CUR: 'America/Curacao',
  CUS: 'America/Denver',
  CUT: 'America/Argentina/Salta',
  CUU: 'America/Chihuahua',
  CUV: 'America/Caracas',
  CUX: 'America/Chicago',
  CUY: 'Australia/Perth',
  CUZ: 'America/Lima',
  CVB: 'Pacific/Port_Moresby',
  CVC: 'Australia/Adelaide',
  CVE: 'America/Bogota',
  CVF: 'Europe/Paris',
  CVG: 'America/New_York',
  CVH: 'America/Argentina/Salta',
  CVI: 'America/Argentina/Rio_Gallegos',
  CVJ: 'America/Mexico_City',
  CVL: 'Pacific/Port_Moresby',
  CVM: 'America/Monterrey',
  CVN: 'America/Denver',
  CVO: 'America/Los_Angeles',
  CVQ: 'Australia/Perth',
  CVS: 'America/Denver',
  CVT: 'Europe/London',
  CVU: 'Atlantic/Azores',
  CWA: 'America/Chicago',
  CWB: 'America/Sao_Paulo',
  CWC: 'Europe/Uzhgorod',
  CWF: 'America/Chicago',
  CWI: 'America/Chicago',
  CWJ: 'Asia/Shanghai',
  CWL: 'Europe/London',
  CWR: 'Australia/Adelaide',
  CWS: 'America/Los_Angeles',
  CWT: 'Australia/Sydney',
  CWW: 'Australia/Sydney',
  CWX: 'America/Phoenix',
  CXA: 'America/Caracas',
  CXB: 'Asia/Dhaka',
  CXC: 'America/Anchorage',
  CXF: 'America/Anchorage',
  CXH: 'America/Vancouver',
  CXI: 'Pacific/Kiritimati',
  CXJ: 'America/Sao_Paulo',
  CXL: 'America/Los_Angeles',
  CXN: 'Africa/Mogadishu',
  CXO: 'America/Chicago',
  CXP: 'Asia/Jakarta',
  CXQ: 'Australia/Perth',
  CXR: 'Asia/Ho_Chi_Minh',
  CXT: 'Australia/Brisbane',
  CXY: 'America/Nassau',
  CYA: 'America/Port-au-Prince',
  CYB: 'America/Cayman',
  CYC: 'America/Belize',
  CYD: 'America/Belize',
  CYF: 'America/Nome',
  CYG: 'Australia/Melbourne',
  CYI: 'Asia/Taipei',
  CYL: 'America/Tegucigalpa',
  CYM: 'America/Sitka',
  CYO: 'America/Havana',
  CYP: 'Asia/Manila',
  CYR: 'America/Montevideo',
  CYS: 'America/Denver',
  CYT: 'America/Anchorage',
  CYU: 'Asia/Manila',
  CYW: 'America/Mexico_City',
  CYX: 'Asia/Magadan',
  CYZ: 'Asia/Manila',
  CZA: 'America/Merida',
  CZB: 'America/Sao_Paulo',
  CZC: 'America/Anchorage',
  CZE: 'America/Caracas',
  CZF: 'America/Nome',
  CZH: 'America/Belize',
  CZJ: 'America/Panama',
  CZK: 'America/Los_Angeles',
  CZL: 'Africa/Algiers',
  CZM: 'America/Cancun',
  CZN: 'America/Anchorage',
  CZO: 'America/Anchorage',
  CZP: 'America/Juneau',
  CZS: 'America/Eirunepe',
  CZT: 'America/Chicago',
  CZU: 'America/Bogota',
  CZW: 'Europe/Warsaw',
  CZX: 'Asia/Shanghai',
  CZY: 'Australia/Brisbane',
  DAA: 'America/New_York',
  DAB: 'America/New_York',
  DAC: 'Asia/Dhaka',
  DAD: 'Asia/Ho_Chi_Minh',
  DAF: 'Pacific/Port_Moresby',
  DAG: 'America/Los_Angeles',
  DAH: 'Asia/Aden',
  DAK: 'Africa/Cairo',
  DAL: 'America/Chicago',
  DAM: 'Asia/Damascus',
  DAN: 'America/New_York',
  DAO: 'Pacific/Port_Moresby',
  DAP: 'Asia/Kathmandu',
  DAR: 'Africa/Dar_es_Salaam',
  DAS: 'America/Yellowknife',
  DAT: 'Asia/Shanghai',
  DAU: 'Pacific/Port_Moresby',
  DAV: 'America/Panama',
  DAX: 'Asia/Shanghai',
  DAY: 'America/New_York',
  DAZ: 'Asia/Kabul',
  DBA: 'Asia/Karachi',
  DBB: 'Africa/Cairo',
  DBC: 'Asia/Shanghai',
  DBD: 'Asia/Kolkata',
  DBK: 'Asia/Colombo',
  DBM: 'Africa/Addis_Ababa',
  DBN: 'America/New_York',
  DBO: 'Australia/Sydney',
  DBP: 'Pacific/Port_Moresby',
  DBQ: 'America/Chicago',
  DBS: 'America/Boise',
  DBT: 'Africa/Addis_Ababa',
  DBU: 'Asia/Colombo',
  DBV: 'Europe/Zagreb',
  DBY: 'Australia/Brisbane',
  DCA: 'America/New_York',
  DCF: 'America/Dominica',
  DCG: 'Asia/Dubai',
  DCI: 'Europe/Rome',
  DCK: 'America/Anchorage',
  DCM: 'Europe/Paris',
  DCN: 'Australia/Perth',
  DCT: 'America/Nassau',
  DCU: 'America/Chicago',
  DCY: 'Asia/Shanghai',
  DDC: 'America/Chicago',
  DDD: 'Indian/Maldives',
  DDG: 'Asia/Shanghai',
  DDM: 'Pacific/Port_Moresby',
  DDN: 'Australia/Brisbane',
  DDU: 'Asia/Karachi',
  DEA: 'Asia/Karachi',
  DEB: 'Europe/Budapest',
  DEC: 'America/Chicago',
  DED: 'Asia/Kolkata',
  DEE: 'Asia/Sakhalin',
  DEF: 'Asia/Tehran',
  DEH: 'America/Chicago',
  DEI: 'Indian/Mahe',
  DEL: 'Asia/Kolkata',
  DEM: 'Africa/Addis_Ababa',
  DEN: 'America/Denver',
  DEP: 'Asia/Kolkata',
  DER: 'Pacific/Port_Moresby',
  DES: 'Indian/Mahe',
  DET: 'America/Detroit',
  DEX: 'Asia/Jayapura',
  DEZ: 'Asia/Damascus',
  DFI: 'America/New_York',
  DFP: 'Australia/Brisbane',
  DFW: 'America/Chicago',
  DGA: 'America/Belize',
  DGC: 'Africa/Addis_Ababa',
  DGD: 'Australia/Perth',
  DGE: 'Australia/Sydney',
  DGF: 'America/Vancouver',
  DGK: 'Africa/Maputo',
  DGL: 'America/Phoenix',
  DGM: 'Asia/Colombo',
  DGN: 'America/New_York',
  DGO: 'America/Monterrey',
  DGP: 'Europe/Riga',
  DGR: 'Pacific/Auckland',
  DGT: 'Asia/Manila',
  DGU: 'Africa/Ouagadougou',
  DGW: 'America/Denver',
  DHA: 'Asia/Riyadh',
  DHB: 'America/Los_Angeles',
  DHD: 'Australia/Brisbane',
  DHF: 'Asia/Dubai',
  DHG: 'Asia/Vladivostok',
  DHI: 'Asia/Kathmandu',
  DHL: 'Asia/Aden',
  DHM: 'Asia/Kolkata',
  DHN: 'America/Chicago',
  DHR: 'Europe/Amsterdam',
  DHT: 'America/Chicago',
  DIA: 'Asia/Qatar',
  DIB: 'Asia/Kolkata',
  DIE: 'Indian/Antananarivo',
  DIG: 'Asia/Shanghai',
  DIJ: 'Europe/Paris',
  DIK: 'America/Denver',
  DIL: 'Asia/Dili',
  DIM: 'Africa/Abidjan',
  DIN: 'Asia/Ho_Chi_Minh',
  DIP: 'Africa/Ouagadougou',
  DIQ: 'America/Sao_Paulo',
  DIR: 'Africa/Addis_Ababa',
  DIS: 'Africa/Brazzaville',
  DIU: 'Asia/Kolkata',
  DIV: 'Africa/Abidjan',
  DIW: 'Asia/Colombo',
  DIY: 'Europe/Istanbul',
  DJA: 'Africa/Porto-Novo',
  DJB: 'Asia/Jakarta',
  DJE: 'Africa/Tunis',
  DJG: 'Africa/Algiers',
  DJH: 'Asia/Dubai',
  DJJ: 'Asia/Jayapura',
  DJM: 'Africa/Brazzaville',
  DJN: 'America/Anchorage',
  DJO: 'Africa/Abidjan',
  DJU: 'Atlantic/Reykjavik',
  DKA: 'Africa/Lagos',
  DKI: 'Australia/Lindeman',
  DKK: 'America/New_York',
  DKR: 'Africa/Dakar',
  DKS: 'Asia/Krasnoyarsk',
  DKV: 'Australia/Darwin',
  DLA: 'Africa/Douala',
  DLB: 'Pacific/Port_Moresby',
  DLC: 'Asia/Shanghai',
  DLE: 'Europe/Paris',
  DLF: 'America/Chicago',
  DLG: 'America/Anchorage',
  DLH: 'America/Chicago',
  DLI: 'Asia/Ho_Chi_Minh',
  DLK: 'Australia/Adelaide',
  DLL: 'America/New_York',
  DLM: 'Europe/Istanbul',
  DLN: 'America/Denver',
  DLR: 'Asia/Vladivostok',
  DLS: 'America/Los_Angeles',
  DLU: 'Asia/Shanghai',
  DLV: 'Australia/Darwin',
  DLY: 'Pacific/Efate',
  DLZ: 'Asia/Ulaanbaatar',
  DMA: 'America/Phoenix',
  DMB: 'Asia/Almaty',
  DMD: 'Australia/Brisbane',
  DME: 'Europe/Moscow',
  DMK: 'Asia/Bangkok',
  DMM: 'Asia/Riyadh',
  DMN: 'America/Denver',
  DMO: 'America/Chicago',
  DMR: 'Asia/Aden',
  DMT: 'America/Cuiaba',
  DMU: 'Asia/Kolkata',
  DNA: 'Asia/Tokyo',
  DNB: 'Australia/Brisbane',
  DND: 'Europe/London',
  DNF: 'Africa/Tripoli',
  DNG: 'Australia/Perth',
  DNH: 'Asia/Urumqi',
  DNI: 'Africa/Khartoum',
  DNK: 'Europe/Zaporozhye',
  DNL: 'America/New_York',
  DNM: 'Australia/Perth',
  DNN: 'America/New_York',
  DNO: 'America/Araguaina',
  DNP: 'Asia/Kathmandu',
  DNQ: 'Australia/Sydney',
  DNR: 'Europe/Paris',
  DNS: 'America/Chicago',
  DNU: 'Pacific/Port_Moresby',
  DNV: 'America/Chicago',
  DNX: 'Africa/Khartoum',
  DNZ: 'Europe/Istanbul',
  DOA: 'Indian/Antananarivo',
  DOB: 'Asia/Jayapura',
  DOC: 'Europe/London',
  DOD: 'Africa/Dar_es_Salaam',
  DOE: 'America/Paramaribo',
  DOG: 'Africa/Khartoum',
  DOH: 'Asia/Qatar',
  DOI: 'Pacific/Port_Moresby',
  DOK: 'Europe/Zaporozhye',
  DOL: 'Europe/Paris',
  DOM: 'America/Dominica',
  DON: 'America/Guatemala',
  DOO: 'Pacific/Port_Moresby',
  DOP: 'Asia/Kathmandu',
  DOR: 'Africa/Ouagadougou',
  DOS: 'Pacific/Bougainville',
  DOU: 'America/Campo_Grande',
  DOV: 'America/Indiana/Indianapolis',
  DOX: 'Australia/Perth',
  DOY: 'Asia/Shanghai',
  DPA: 'America/Chicago',
  DPB: 'America/Punta_Arenas',
  DPE: 'Europe/Paris',
  DPG: 'America/Denver',
  DPL: 'Asia/Manila',
  DPO: 'Australia/Hobart',
  DPS: 'Asia/Makassar',
  DPT: 'Asia/Vladivostok',
  DPU: 'Pacific/Port_Moresby',
  DQA: 'Asia/Shanghai',
  DQM: 'Asia/Muscat',
  DRA: 'America/Los_Angeles',
  DRB: 'Australia/Perth',
  DRC: 'Africa/Luanda',
  DRD: 'Australia/Brisbane',
  DRE: 'America/Detroit',
  DRF: 'America/Anchorage',
  DRG: 'America/Nome',
  DRH: 'Asia/Jayapura',
  DRI: 'America/Chicago',
  DRJ: 'America/Paramaribo',
  DRK: 'America/Costa_Rica',
  DRN: 'Australia/Brisbane',
  DRO: 'America/Denver',
  DRR: 'Australia/Brisbane',
  DRS: 'Europe/Berlin',
  DRT: 'America/Chicago',
  DRU: 'America/Denver',
  DRV: 'Indian/Maldives',
  DRW: 'Australia/Darwin',
  DRY: 'Australia/Perth',
  DSA: 'Europe/London',
  DSC: 'Africa/Douala',
  DSD: 'America/Guadeloupe',
  DSE: 'Africa/Addis_Ababa',
  DSG: 'Asia/Manila',
  DSI: 'America/Chicago',
  DSK: 'Asia/Karachi',
  DSM: 'America/Chicago',
  DSN: 'Asia/Shanghai',
  DSO: 'Asia/Pyongyang',
  DSS: 'Africa/Dakar',
  DSV: 'America/New_York',
  DSX: 'Asia/Taipei',
  DTA: 'America/Denver',
  DTB: 'Asia/Jakarta',
  DTD: 'Asia/Makassar',
  DTE: 'Asia/Manila',
  DTH: 'America/Los_Angeles',
  DTI: 'America/Sao_Paulo',
  DTL: 'America/Chicago',
  DTM: 'Europe/Berlin',
  DTN: 'America/Chicago',
  DTR: 'America/Los_Angeles',
  DTT: 'America/Detroit',
  DTU: 'Asia/Shanghai',
  DTW: 'America/Detroit',
  DUA: 'America/Chicago',
  DUB: 'Europe/Dublin',
  DUC: 'America/Chicago',
  DUD: 'Pacific/Auckland',
  DUE: 'Africa/Luanda',
  DUF: 'America/New_York',
  DUG: 'America/Phoenix',
  DUJ: 'America/New_York',
  DUK: 'Africa/Johannesburg',
  DUM: 'Asia/Jakarta',
  DUQ: 'America/Vancouver',
  DUR: 'Africa/Johannesburg',
  DUS: 'Europe/Berlin',
  DUT: 'America/Anchorage',
  DVD: 'Indian/Antananarivo',
  DVK: 'America/Yellowknife',
  DVL: 'America/Chicago',
  DVN: 'America/Chicago',
  DVO: 'Asia/Manila',
  DVP: 'Australia/Brisbane',
  DVR: 'Australia/Darwin',
  DVT: 'America/Phoenix',
  DWA: 'Africa/Blantyre',
  DWB: 'Indian/Antananarivo',
  DWC: 'Asia/Dubai',
  DWD: 'Asia/Riyadh',
  DWH: 'America/Chicago',
  DWO: 'Asia/Colombo',
  DWR: 'Asia/Kabul',
  DXB: 'Asia/Dubai',
  DXD: 'Australia/Brisbane',
  DXE: 'America/Chicago',
  DXR: 'America/New_York',
  DYA: 'Australia/Brisbane',
  DYG: 'Asia/Shanghai',
  DYL: 'America/New_York',
  DYM: 'Australia/Brisbane',
  DYR: 'Asia/Anadyr',
  DYS: 'America/Chicago',
  DYU: 'Asia/Dushanbe',
  DYW: 'Australia/Darwin',
  DZA: 'Indian/Mayotte',
  DZI: 'America/Bogota',
  DZN: 'Asia/Almaty',
  DZO: 'America/Montevideo',
  DZU: 'Asia/Shanghai',
  EAA: 'America/Anchorage',
  EAB: 'Asia/Aden',
  EAE: 'Pacific/Efate',
  EAL: 'Pacific/Kwajalein',
  EAM: 'Asia/Riyadh',
  EAN: 'America/Denver',
  EAP: 'Europe/Zurich',
  EAR: 'America/Chicago',
  EAS: 'Europe/Madrid',
  EAT: 'America/Los_Angeles',
  EAU: 'America/Chicago',
  EBA: 'Europe/Rome',
  EBB: 'Africa/Kampala',
  EBD: 'Africa/Khartoum',
  EBG: 'America/Bogota',
  EBH: 'Africa/Algiers',
  EBJ: 'Europe/Copenhagen',
  EBL: 'Asia/Baghdad',
  EBM: 'Africa/Tunis',
  EBN: 'Pacific/Kwajalein',
  EBO: 'Pacific/Majuro',
  EBS: 'America/Chicago',
  EBU: 'Europe/Paris',
  EBW: 'Africa/Douala',
  ECA: 'America/Detroit',
  ECG: 'America/New_York',
  ECH: 'Australia/Melbourne',
  ECI: 'America/Managua',
  ECN: 'Asia/Nicosia',
  ECO: 'America/Bogota',
  ECP: 'America/Chicago',
  ECR: 'America/Bogota',
  ECS: 'America/Denver',
  EDA: 'America/Sitka',
  EDB: 'Africa/Khartoum',
  EDD: 'Australia/Darwin',
  EDE: 'America/New_York',
  EDF: 'America/Anchorage',
  EDI: 'Europe/London',
  EDK: 'America/Chicago',
  EDL: 'Africa/Nairobi',
  EDM: 'Europe/Paris',
  EDN: 'Asia/Vladivostok',
  EDO: 'Europe/Istanbul',
  EDQ: 'America/Tegucigalpa',
  EDR: 'Australia/Brisbane',
  EDW: 'America/Los_Angeles',
  EED: 'America/Los_Angeles',
  EEK: 'America/Nome',
  EEN: 'America/New_York',
  EFD: 'America/Chicago',
  EFG: 'Pacific/Port_Moresby',
  EFK: 'America/New_York',
  EFL: 'Europe/Athens',
  EFW: 'America/Chicago',
  EGA: 'Pacific/Port_Moresby',
  EGC: 'Europe/Paris',
  EGE: 'America/Denver',
  EGI: 'America/Chicago',
  EGL: 'Africa/Addis_Ababa',
  EGM: 'Pacific/Guadalcanal',
  EGN: 'Africa/Khartoum',
  EGO: 'Europe/Moscow',
  EGP: 'America/Chicago',
  EGS: 'Atlantic/Reykjavik',
  EGV: 'America/Chicago',
  EGX: 'America/Anchorage',
  EHL: 'America/Argentina/Salta',
  EHM: 'America/Nome',
  EIA: 'Pacific/Port_Moresby',
  EIB: 'Europe/Berlin',
  EIE: 'Asia/Krasnoyarsk',
  EIH: 'Australia/Brisbane',
  EIK: 'Europe/Moscow',
  EIL: 'America/Anchorage',
  EIN: 'Europe/Amsterdam',
  EIS: 'America/Tortola',
  EIY: 'Asia/Jerusalem',
  EJA: 'America/Bogota',
  EJH: 'Asia/Riyadh',
  EJN: 'Asia/Shanghai',
  EJT: 'Pacific/Majuro',
  EKA: 'America/Los_Angeles',
  EKB: 'Asia/Almaty',
  EKD: 'Australia/Darwin',
  EKE: 'America/Guyana',
  EKI: 'America/Indiana/Indianapolis',
  EKN: 'America/New_York',
  EKO: 'America/Los_Angeles',
  EKS: 'Asia/Sakhalin',
  EKT: 'Europe/Stockholm',
  EKX: 'America/New_York',
  ELA: 'America/Chicago',
  ELB: 'America/Bogota',
  ELC: 'Australia/Darwin',
  ELD: 'America/Chicago',
  ELE: 'America/Panama',
  ELF: 'Africa/Khartoum',
  ELG: 'Africa/Algiers',
  ELH: 'America/Nassau',
  ELI: 'America/Nome',
  ELJ: 'America/Bogota',
  ELK: 'America/Chicago',
  ELL: 'Africa/Johannesburg',
  ELM: 'America/New_York',
  ELN: 'America/Los_Angeles',
  ELO: 'America/Argentina/Cordoba',
  ELP: 'America/Denver',
  ELQ: 'Asia/Riyadh',
  ELR: 'Asia/Jayapura',
  ELS: 'Africa/Johannesburg',
  ELT: 'Africa/Cairo',
  ELU: 'Africa/Algiers',
  ELV: 'America/Juneau',
  ELW: 'America/Anchorage',
  ELX: 'America/Caracas',
  ELY: 'America/Los_Angeles',
  ELZ: 'America/New_York',
  EMA: 'Europe/London',
  EMD: 'Australia/Brisbane',
  EME: 'Europe/Berlin',
  EMG: 'Africa/Johannesburg',
  EMI: 'Pacific/Port_Moresby',
  EMK: 'America/Nome',
  EML: 'Europe/Zurich',
  EMM: 'America/Denver',
  EMN: 'Africa/Nouakchott',
  EMO: 'Pacific/Port_Moresby',
  EMP: 'America/Chicago',
  EMS: 'Pacific/Port_Moresby',
  EMT: 'America/Los_Angeles',
  EMX: 'America/Argentina/Catamarca',
  EMY: 'Africa/Cairo',
  ENA: 'America/Anchorage',
  ENB: 'Australia/Perth',
  ENC: 'Europe/Paris',
  END: 'America/Chicago',
  ENE: 'Asia/Makassar',
  ENF: 'Europe/Helsinki',
  ENH: 'Asia/Shanghai',
  ENI: 'Asia/Manila',
  ENJ: 'America/Guatemala',
  ENK: 'Europe/London',
  ENL: 'America/Chicago',
  ENN: 'America/Anchorage',
  ENO: 'America/Asuncion',
  ENS: 'Europe/Amsterdam',
  ENT: 'Pacific/Kwajalein',
  ENU: 'Africa/Lagos',
  ENV: 'America/Denver',
  ENW: 'America/Chicago',
  ENY: 'Asia/Shanghai',
  EOH: 'America/Bogota',
  EOI: 'Europe/London',
  EOK: 'America/Chicago',
  EOR: 'America/Caracas',
  EOS: 'America/Chicago',
  EOZ: 'America/Caracas',
  EPA: 'America/Argentina/Buenos_Aires',
  EPG: 'America/Chicago',
  EPH: 'America/Los_Angeles',
  EPL: 'Europe/Paris',
  EPN: 'Africa/Brazzaville',
  EPR: 'Australia/Perth',
  EPS: 'America/Santo_Domingo',
  EPT: 'Pacific/Port_Moresby',
  EPU: 'Europe/Tallinn',
  EQS: 'America/Argentina/Catamarca',
  ERA: 'Africa/Mogadishu',
  ERB: 'Australia/Adelaide',
  ERC: 'Europe/Istanbul',
  ERD: 'Europe/Zaporozhye',
  ERE: 'Pacific/Port_Moresby',
  ERF: 'Europe/Berlin',
  ERG: 'Asia/Irkutsk',
  ERH: 'Africa/Casablanca',
  ERI: 'America/New_York',
  ERL: 'Asia/Shanghai',
  ERM: 'America/Sao_Paulo',
  ERN: 'America/Manaus',
  ERQ: 'Australia/Brisbane',
  ERR: 'America/New_York',
  ERS: 'Africa/Windhoek',
  ERT: 'Asia/Ulaanbaatar',
  ERU: 'Pacific/Port_Moresby',
  ERV: 'America/Chicago',
  ERZ: 'Europe/Istanbul',
  ESA: 'Pacific/Port_Moresby',
  ESB: 'Europe/Istanbul',
  ESC: 'America/Detroit',
  ESD: 'America/Los_Angeles',
  ESE: 'America/Tijuana',
  ESF: 'America/Chicago',
  ESG: 'America/Asuncion',
  ESH: 'Europe/London',
  ESI: 'America/Sao_Paulo',
  ESK: 'Europe/Istanbul',
  ESL: 'Europe/Moscow',
  ESM: 'America/Guayaquil',
  ESN: 'America/New_York',
  ESO: 'America/Denver',
  ESP: 'America/New_York',
  ESR: 'America/Santiago',
  ESS: 'Europe/Berlin',
  EST: 'America/Chicago',
  ESU: 'Africa/Casablanca',
  ESW: 'America/Los_Angeles',
  ETB: 'America/Chicago',
  ETD: 'Australia/Adelaide',
  ETE: 'Africa/Addis_Ababa',
  ETH: 'Asia/Jerusalem',
  ETL: 'Asia/Vladivostok',
  ETM: 'Asia/Jerusalem',
  ETN: 'America/Chicago',
  ETR: 'America/Guayaquil',
  ETS: 'America/Chicago',
  ETZ: 'Europe/Paris',
  EUA: 'Pacific/Tongatapu',
  EUC: 'Australia/Eucla',
  EUE: 'America/Los_Angeles',
  EUF: 'America/Chicago',
  EUG: 'America/Los_Angeles',
  EUM: 'Europe/Berlin',
  EUN: 'Africa/Casablanca',
  EUO: 'America/Bogota',
  EUQ: 'Asia/Manila',
  EUX: 'America/Kralendijk',
  EVA: 'America/Chicago',
  EVD: 'Australia/Darwin',
  EVE: 'Europe/Oslo',
  EVG: 'Europe/Stockholm',
  EVH: 'Australia/Sydney',
  EVM: 'America/Chicago',
  EVN: 'Asia/Yerevan',
  EVV: 'America/Chicago',
  EVW: 'America/Denver',
  EVX: 'Europe/Paris',
  EWB: 'America/New_York',
  EWE: 'Asia/Jayapura',
  EWI: 'Asia/Jayapura',
  EWK: 'America/Chicago',
  EWN: 'America/New_York',
  EWO: 'Africa/Brazzaville',
  EWR: 'America/New_York',
  EXI: 'America/Juneau',
  EXM: 'Australia/Perth',
  EXT: 'Europe/London',
  EYK: 'Asia/Yekaterinburg',
  EYL: 'Africa/Bamako',
  EYP: 'America/Bogota',
  EYR: 'America/Los_Angeles',
  EYS: 'Africa/Nairobi',
  EYW: 'America/New_York',
  EZE: 'America/Argentina/Buenos_Aires',
  EZS: 'Europe/Istanbul',
  EZV: 'Asia/Yekaterinburg',
  FAA: 'Africa/Conakry',
  FAB: 'Europe/London',
  FAC: 'Pacific/Tahiti',
  FAE: 'Atlantic/Faroe',
  FAF: 'America/New_York',
  FAG: 'Atlantic/Reykjavik',
  FAH: 'Asia/Kabul',
  FAI: 'America/Anchorage',
  FAJ: 'America/Puerto_Rico',
  FAK: 'America/Sitka',
  FAM: 'America/Chicago',
  FAO: 'Europe/Lisbon',
  FAQ: 'Pacific/Port_Moresby',
  FAR: 'America/Chicago',
  FAS: 'Atlantic/Reykjavik',
  FAT: 'America/Los_Angeles',
  FAU: 'Asia/Muscat',
  FAV: 'Pacific/Tahiti',
  FAY: 'America/New_York',
  FAZ: 'Asia/Tehran',
  FBA: 'America/Manaus',
  FBD: 'Asia/Kabul',
  FBE: 'America/Sao_Paulo',
  FBG: 'America/New_York',
  FBK: 'America/Anchorage',
  FBL: 'America/Chicago',
  FBM: 'Africa/Lubumbashi',
  FBR: 'America/Denver',
  FBS: 'America/Los_Angeles',
  FBY: 'America/Chicago',
  FCA: 'America/Denver',
  FCB: 'Africa/Johannesburg',
  FCH: 'America/Los_Angeles',
  FCM: 'America/Chicago',
  FCN: 'Europe/Berlin',
  FCO: 'Europe/Rome',
  FCS: 'America/Denver',
  FCY: 'America/Chicago',
  FDE: 'Europe/Oslo',
  FDF: 'America/Martinique',
  FDH: 'Europe/Berlin',
  FDK: 'America/New_York',
  FDR: 'America/Chicago',
  FDU: 'Africa/Kinshasa',
  FDY: 'America/New_York',
  FEA: 'Europe/London',
  FEB: 'Asia/Kathmandu',
  FEC: 'America/Bahia',
  FEG: 'Asia/Tashkent',
  FEJ: 'America/Rio_Branco',
  FEK: 'Africa/Abidjan',
  FEL: 'Europe/Berlin',
  FEN: 'America/Noronha',
  FEP: 'America/Chicago',
  FET: 'America/Chicago',
  FEZ: 'Africa/Casablanca',
  FFA: 'America/New_York',
  FFD: 'Europe/London',
  FFL: 'America/Chicago',
  FFM: 'America/Chicago',
  FFO: 'America/New_York',
  FFT: 'America/New_York',
  FFU: 'America/Santiago',
  FGD: 'Africa/Nouakchott',
  FGI: 'Pacific/Apia',
  FGU: 'Pacific/Tahiti',
  FHU: 'America/Phoenix',
  FHZ: 'Pacific/Tahiti',
  FID: 'America/New_York',
  FIE: 'Europe/London',
  FIG: 'Africa/Conakry',
  FIH: 'Africa/Kinshasa',
  FIK: 'Australia/Darwin',
  FIL: 'America/Denver',
  FIN: 'Pacific/Port_Moresby',
  FIZ: 'Australia/Perth',
  FJR: 'Asia/Dubai',
  FKB: 'Europe/Berlin',
  FKI: 'Africa/Lubumbashi',
  FKJ: 'Asia/Tokyo',
  FKL: 'America/New_York',
  FKN: 'America/New_York',
  FKQ: 'Asia/Jayapura',
  FKS: 'Asia/Tokyo',
  FLA: 'America/Bogota',
  FLB: 'America/Fortaleza',
  FLD: 'America/Chicago',
  FLF: 'Europe/Berlin',
  FLG: 'America/Phoenix',
  FLH: 'Europe/London',
  FLI: 'Atlantic/Reykjavik',
  FLL: 'America/New_York',
  FLM: 'America/Asuncion',
  FLN: 'America/Sao_Paulo',
  FLO: 'America/New_York',
  FLP: 'America/Chicago',
  FLR: 'Europe/Rome',
  FLS: 'Australia/Hobart',
  FLT: 'America/Anchorage',
  FLV: 'America/Chicago',
  FLW: 'Atlantic/Azores',
  FLX: 'America/Los_Angeles',
  FLY: 'Australia/Sydney',
  FLZ: 'Asia/Jakarta',
  FMA: 'America/Argentina/Cordoba',
  FME: 'America/New_York',
  FMH: 'America/New_York',
  FMI: 'Africa/Lubumbashi',
  FMM: 'Europe/Berlin',
  FMN: 'America/Denver',
  FMO: 'Europe/Berlin',
  FMS: 'America/Chicago',
  FMU: 'America/Los_Angeles',
  FMY: 'America/New_York',
  FNA: 'Africa/Freetown',
  FNB: 'Europe/Berlin',
  FNC: 'Atlantic/Madeira',
  FNE: 'Pacific/Port_Moresby',
  FNG: 'Africa/Ouagadougou',
  FNH: 'Africa/Addis_Ababa',
  FNI: 'Europe/Paris',
  FNJ: 'Asia/Pyongyang',
  FNL: 'America/Denver',
  FNR: 'America/Juneau',
  FNT: 'America/Detroit',
  FNU: 'Europe/Rome',
  FOA: 'Europe/London',
  FOB: 'America/Los_Angeles',
  FOC: 'Asia/Shanghai',
  FOD: 'America/Chicago',
  FOE: 'America/Chicago',
  FOG: 'Europe/Rome',
  FOK: 'America/New_York',
  FOM: 'Africa/Douala',
  FON: 'America/Costa_Rica',
  FOO: 'Asia/Jayapura',
  FOR: 'America/Fortaleza',
  FOS: 'Australia/Perth',
  FOT: 'Australia/Sydney',
  FOU: 'Africa/Libreville',
  FOY: 'Africa/Monrovia',
  FPO: 'America/Nassau',
  FPR: 'America/New_York',
  FPY: 'America/New_York',
  FRA: 'Europe/Berlin',
  FRB: 'Australia/Sydney',
  FRC: 'America/Sao_Paulo',
  FRD: 'America/Los_Angeles',
  FRE: 'Pacific/Guadalcanal',
  FRG: 'America/New_York',
  FRH: 'America/Indiana/Indianapolis',
  FRI: 'America/Chicago',
  FRK: 'Indian/Mahe',
  FRL: 'Europe/Rome',
  FRM: 'America/Chicago',
  FRN: 'America/Anchorage',
  FRO: 'Europe/Oslo',
  FRQ: 'Pacific/Port_Moresby',
  FRR: 'America/New_York',
  FRS: 'America/Guatemala',
  FRT: 'America/Santiago',
  FRU: 'Asia/Bishkek',
  FRW: 'Africa/Gaborone',
  FRY: 'America/New_York',
  FRZ: 'Europe/Berlin',
  FSC: 'Europe/Paris',
  FSD: 'America/Chicago',
  FSI: 'America/Chicago',
  FSK: 'America/Chicago',
  FSL: 'Australia/Perth',
  FSM: 'America/Chicago',
  FSP: 'America/Miquelon',
  FSS: 'Europe/London',
  FST: 'America/Chicago',
  FSU: 'America/Denver',
  FSZ: 'Asia/Tokyo',
  FTA: 'Pacific/Efate',
  FTE: 'America/Argentina/Rio_Gallegos',
  FTI: 'Pacific/Pago_Pago',
  FTK: 'America/New_York',
  FTU: 'Indian/Antananarivo',
  FTW: 'America/Chicago',
  FTX: 'Africa/Brazzaville',
  FTY: 'America/New_York',
  FUB: 'Pacific/Port_Moresby',
  FUE: 'Atlantic/Canary',
  FUG: 'Asia/Shanghai',
  FUJ: 'Asia/Tokyo',
  FUK: 'Asia/Tokyo',
  FUL: 'America/Los_Angeles',
  FUM: 'Pacific/Port_Moresby',
  FUN: 'Pacific/Funafuti',
  FUO: 'Asia/Shanghai',
  FUT: 'Pacific/Wallis',
  FVL: 'Australia/Perth',
  FVM: 'Indian/Maldives',
  FVR: 'Australia/Perth',
  FWA: 'America/Indiana/Indianapolis',
  FWH: 'America/Chicago',
  FWL: 'America/Anchorage',
  FXE: 'America/New_York',
  FXO: 'Africa/Maputo',
  FXY: 'America/Chicago',
  FYJ: 'Asia/Shanghai',
  FYM: 'America/Chicago',
  FYN: 'Asia/Urumqi',
  FYT: 'Africa/Ndjamena',
  FYU: 'America/Anchorage',
  FYV: 'America/Chicago',
  FZO: 'Europe/London',
  GAA: 'America/Bogota',
  GAB: 'America/Los_Angeles',
  GAC: 'America/Tegucigalpa',
  GAD: 'America/Chicago',
  GAE: 'Africa/Tunis',
  GAF: 'Africa/Tunis',
  GAG: 'America/Chicago',
  GAH: 'Australia/Brisbane',
  GAI: 'America/New_York',
  GAJ: 'Asia/Tokyo',
  GAL: 'America/Anchorage',
  GAM: 'America/Nome',
  GAN: 'Indian/Maldives',
  GAO: 'America/Havana',
  GAP: 'Pacific/Port_Moresby',
  GAQ: 'Africa/Bamako',
  GAR: 'Pacific/Port_Moresby',
  GAS: 'Africa/Nairobi',
  GAT: 'Europe/Paris',
  GAU: 'Asia/Kolkata',
  GAV: 'Asia/Jayapura',
  GAW: 'Asia/Yangon',
  GAX: 'Africa/Libreville',
  GAY: 'Asia/Kolkata',
  GAZ: 'Pacific/Port_Moresby',
  GBA: 'Europe/London',
  GBB: 'Asia/Baku',
  GBC: 'Pacific/Port_Moresby',
  GBD: 'America/Chicago',
  GBE: 'Africa/Gaborone',
  GBF: 'Pacific/Port_Moresby',
  GBG: 'America/Chicago',
  GBH: 'America/Anchorage',
  GBJ: 'America/Guadeloupe',
  GBK: 'Africa/Freetown',
  GBL: 'Australia/Darwin',
  GBM: 'Africa/Mogadishu',
  GBP: 'Australia/Brisbane',
  GBR: 'America/New_York',
  GBT: 'Asia/Tehran',
  GBU: 'Africa/Khartoum',
  GBV: 'Australia/Perth',
  GBW: 'Australia/Perth',
  GBZ: 'Pacific/Auckland',
  GCA: 'America/Bogota',
  GCC: 'America/Denver',
  GCD: 'America/Los_Angeles',
  GCH: 'Asia/Tehran',
  GCI: 'Europe/Guernsey',
  GCJ: 'Africa/Johannesburg',
  GCK: 'America/Chicago',
  GCM: 'America/Cayman',
  GCN: 'America/Phoenix',
  GCT: 'America/Phoenix',
  GCW: 'America/Phoenix',
  GCY: 'America/New_York',
  GDA: 'Africa/Bangui',
  GDC: 'America/New_York',
  GDD: 'Australia/Perth',
  GDE: 'Africa/Addis_Ababa',
  GDG: 'Asia/Yakutsk',
  GDH: 'America/Anchorage',
  GDI: 'Africa/Bangui',
  GDJ: 'Africa/Lubumbashi',
  GDL: 'America/Mexico_City',
  GDM: 'America/New_York',
  GDN: 'Europe/Warsaw',
  GDO: 'America/Caracas',
  GDP: 'America/Fortaleza',
  GDQ: 'Africa/Addis_Ababa',
  GDT: 'America/Grand_Turk',
  GDV: 'America/Denver',
  GDW: 'America/Detroit',
  GDX: 'Asia/Magadan',
  GDZ: 'Europe/Moscow',
  GEA: 'Pacific/Noumea',
  GEB: 'Asia/Jayapura',
  GEC: 'Asia/Famagusta',
  GED: 'America/New_York',
  GEE: 'Australia/Hobart',
  GEF: 'Pacific/Guadalcanal',
  GEG: 'America/Los_Angeles',
  GEL: 'America/Sao_Paulo',
  GEM: 'Africa/Malabo',
  GEO: 'America/Guyana',
  GER: 'America/Havana',
  GES: 'Asia/Manila',
  GET: 'Australia/Perth',
  GEV: 'Europe/Stockholm',
  GEW: 'Pacific/Port_Moresby',
  GEX: 'Australia/Melbourne',
  GEY: 'America/Denver',
  GFD: 'America/Indiana/Indianapolis',
  GFE: 'Australia/Sydney',
  GFF: 'Australia/Sydney',
  GFK: 'America/Chicago',
  GFL: 'America/New_York',
  GFN: 'Australia/Sydney',
  GFO: 'America/Guyana',
  GFR: 'Europe/Paris',
  GFY: 'Africa/Windhoek',
  GGC: 'Africa/Luanda',
  GGD: 'Australia/Brisbane',
  GGE: 'America/New_York',
  GGG: 'America/Chicago',
  GGL: 'America/Bogota',
  GGM: 'Africa/Nairobi',
  GGN: 'Africa/Abidjan',
  GGO: 'Africa/Abidjan',
  GGR: 'Africa/Mogadishu',
  GGS: 'America/Argentina/Rio_Gallegos',
  GGT: 'America/Nassau',
  GGW: 'America/Denver',
  GHA: 'Africa/Algiers',
  GHB: 'America/Nassau',
  GHC: 'America/Nassau',
  GHE: 'America/Panama',
  GHF: 'Europe/Berlin',
  GHK: 'America/Yellowknife',
  GHM: 'America/Chicago',
  GHN: 'Asia/Shanghai',
  GHS: 'Asia/Makassar',
  GHT: 'Africa/Tripoli',
  GHU: 'America/Argentina/Cordoba',
  GIB: 'Europe/Gibraltar',
  GIC: 'Pacific/Port_Moresby',
  GID: 'Africa/Bujumbura',
  GIF: 'America/New_York',
  GIG: 'America/Sao_Paulo',
  GII: 'Africa/Conakry',
  GIL: 'Asia/Karachi',
  GIM: 'Africa/Libreville',
  GIR: 'America/Bogota',
  GIS: 'Pacific/Auckland',
  GIT: 'Africa/Dar_es_Salaam',
  GIU: 'Asia/Colombo',
  GIY: 'Africa/Johannesburg',
  GIZ: 'Asia/Riyadh',
  GJA: 'America/Tegucigalpa',
  GJL: 'Africa/Algiers',
  GJM: 'America/Porto_Velho',
  GJR: 'Atlantic/Reykjavik',
  GJT: 'America/Denver',
  GKA: 'Pacific/Port_Moresby',
  GKD: 'Europe/Istanbul',
  GKE: 'Europe/Berlin',
  GKH: 'Asia/Kathmandu',
  GKK: 'Indian/Maldives',
  GKL: 'Australia/Brisbane',
  GKN: 'America/Anchorage',
  GKO: 'Africa/Libreville',
  GKT: 'America/New_York',
  GLA: 'Europe/London',
  GLB: 'America/Phoenix',
  GLC: 'Africa/Addis_Ababa',
  GLD: 'America/Denver',
  GLE: 'America/Chicago',
  GLF: 'America/Costa_Rica',
  GLG: 'Australia/Brisbane',
  GLH: 'America/Chicago',
  GLI: 'Australia/Sydney',
  GLK: 'Africa/Mogadishu',
  GLL: 'Europe/Oslo',
  GLM: 'Australia/Brisbane',
  GLN: 'Africa/Casablanca',
  GLO: 'Europe/London',
  GLP: 'Pacific/Port_Moresby',
  GLR: 'America/Detroit',
  GLS: 'America/Chicago',
  GLT: 'Australia/Brisbane',
  GLU: 'Asia/Thimphu',
  GLV: 'America/Nome',
  GLW: 'America/Chicago',
  GLX: 'Asia/Jayapura',
  GLY: 'Australia/Perth',
  GLZ: 'Europe/Amsterdam',
  GMA: 'Africa/Kinshasa',
  GMB: 'Africa/Addis_Ababa',
  GMC: 'America/Bogota',
  GMD: 'Africa/Casablanca',
  GME: 'Europe/Minsk',
  GMI: 'Pacific/Port_Moresby',
  GMM: 'Africa/Brazzaville',
  GMN: 'Pacific/Auckland',
  GMO: 'Africa/Lagos',
  GMP: 'Asia/Seoul',
  GMQ: 'Asia/Shanghai',
  GMR: 'Pacific/Gambier',
  GMS: 'America/Fortaleza',
  GMT: 'America/Anchorage',
  GMU: 'America/New_York',
  GMV: 'America/Denver',
  GMZ: 'Atlantic/Canary',
  GNA: 'Europe/Minsk',
  GNB: 'Europe/Paris',
  GND: 'America/Grenada',
  GNF: 'America/Los_Angeles',
  GNG: 'America/Boise',
  GNI: 'Asia/Taipei',
  GNM: 'America/Bahia',
  GNN: 'Africa/Addis_Ababa',
  GNR: 'America/Argentina/Salta',
  GNS: 'Asia/Jakarta',
  GNT: 'America/Denver',
  GNU: 'America/Anchorage',
  GNV: 'America/New_York',
  GNY: 'Europe/Istanbul',
  GNZ: 'Africa/Gaborone',
  GOA: 'Europe/Rome',
  GOB: 'Africa/Addis_Ababa',
  GOC: 'Pacific/Port_Moresby',
  GOE: 'Pacific/Port_Moresby',
  GOG: 'Africa/Windhoek',
  GOH: 'America/Godthab',
  GOI: 'Asia/Kolkata',
  GOJ: 'Europe/Moscow',
  GOK: 'America/Chicago',
  GOL: 'America/Los_Angeles',
  GOM: 'Africa/Lubumbashi',
  GON: 'America/New_York',
  GOO: 'Australia/Brisbane',
  GOP: 'Asia/Kolkata',
  GOQ: 'Asia/Urumqi',
  GOR: 'Africa/Addis_Ababa',
  GOS: 'Australia/Sydney',
  GOT: 'Europe/Stockholm',
  GOU: 'Africa/Douala',
  GOV: 'Australia/Darwin',
  GOZ: 'Europe/Sofia',
  GPA: 'Europe/Athens',
  GPB: 'America/Sao_Paulo',
  GPD: 'Australia/Brisbane',
  GPI: 'America/Bogota',
  GPL: 'America/Costa_Rica',
  GPN: 'Australia/Darwin',
  GPO: 'America/Argentina/Salta',
  GPS: 'Pacific/Galapagos',
  GPT: 'America/Chicago',
  GPZ: 'America/Chicago',
  GQQ: 'America/New_York',
  GRA: 'America/Bogota',
  GRB: 'America/Chicago',
  GRC: 'Africa/Monrovia',
  GRD: 'America/New_York',
  GRE: 'America/Chicago',
  GRF: 'America/Los_Angeles',
  GRG: 'Asia/Kabul',
  GRH: 'Pacific/Port_Moresby',
  GRI: 'America/Chicago',
  GRJ: 'Africa/Johannesburg',
  GRK: 'America/Chicago',
  GRL: 'Pacific/Port_Moresby',
  GRM: 'America/Chicago',
  GRN: 'America/Denver',
  GRO: 'Europe/Madrid',
  GRP: 'America/Araguaina',
  GRQ: 'Europe/Amsterdam',
  GRR: 'America/Detroit',
  GRS: 'Europe/Rome',
  GRT: 'Asia/Karachi',
  GRU: 'America/Sao_Paulo',
  GRV: 'Europe/Moscow',
  GRW: 'Atlantic/Azores',
  GRX: 'Europe/Madrid',
  GRY: 'Atlantic/Reykjavik',
  GRZ: 'Europe/Vienna',
  GSA: 'Asia/Kuching',
  GSB: 'America/New_York',
  GSC: 'Australia/Perth',
  GSE: 'Europe/Stockholm',
  GSH: 'America/Indiana/Indianapolis',
  GSI: 'America/Cayenne',
  GSJ: 'America/Guatemala',
  GSL: 'America/Yellowknife',
  GSM: 'Asia/Tehran',
  GSN: 'Australia/Adelaide',
  GSO: 'America/New_York',
  GSP: 'America/New_York',
  GSQ: 'Africa/Cairo',
  GSR: 'Africa/Mogadishu',
  GSS: 'Africa/Johannesburg',
  GST: 'America/Juneau',
  GSU: 'Africa/Khartoum',
  GTA: 'Pacific/Guadalcanal',
  GTE: 'Australia/Darwin',
  GTF: 'America/Denver',
  GTG: 'America/Chicago',
  GTI: 'Europe/Berlin',
  GTN: 'Pacific/Auckland',
  GTO: 'Asia/Makassar',
  GTP: 'America/Los_Angeles',
  GTR: 'America/Chicago',
  GTS: 'Australia/Darwin',
  GTT: 'Australia/Brisbane',
  GTW: 'Europe/Prague',
  GTY: 'America/New_York',
  GTZ: 'Africa/Dar_es_Salaam',
  GUA: 'America/Guatemala',
  GUB: 'America/Tijuana',
  GUC: 'America/Denver',
  GUD: 'Africa/Bamako',
  GUE: 'Pacific/Port_Moresby',
  GUF: 'America/Chicago',
  GUG: 'Pacific/Port_Moresby',
  GUH: 'Australia/Sydney',
  GUI: 'America/Caracas',
  GUJ: 'America/Sao_Paulo',
  GUL: 'Australia/Sydney',
  GUM: 'Pacific/Guam',
  GUO: 'Africa/Mogadishu',
  GUP: 'America/Denver',
  GUQ: 'America/Caracas',
  GUR: 'Pacific/Port_Moresby',
  GUS: 'America/Indiana/Indianapolis',
  GUT: 'Europe/Berlin',
  GUU: 'Atlantic/Reykjavik',
  GUV: 'Pacific/Port_Moresby',
  GUW: 'Asia/Atyrau',
  GUX: 'Asia/Kolkata',
  GUY: 'America/Chicago',
  GUZ: 'America/Sao_Paulo',
  GVA: 'Europe/Zurich',
  GVE: 'America/New_York',
  GVI: 'Pacific/Port_Moresby',
  GVL: 'America/New_York',
  GVN: 'Asia/Vladivostok',
  GVP: 'Australia/Brisbane',
  GVR: 'America/Sao_Paulo',
  GVT: 'America/Chicago',
  GVX: 'Europe/Stockholm',
  GWA: 'Asia/Yangon',
  GWD: 'Asia/Karachi',
  GWE: 'Africa/Harare',
  GWL: 'Asia/Kolkata',
  GWN: 'Pacific/Port_Moresby',
  GWO: 'America/Chicago',
  GWS: 'America/Denver',
  GWT: 'Europe/Berlin',
  GWV: 'America/New_York',
  GWY: 'Europe/Dublin',
  GXA: 'Asia/Pontianak',
  GXF: 'Asia/Aden',
  GXG: 'Africa/Luanda',
  GXH: 'Asia/Shanghai',
  GXQ: 'America/Santiago',
  GXX: 'Africa/Douala',
  GXY: 'America/Denver',
  GYA: 'America/La_Paz',
  GYD: 'Asia/Baku',
  GYE: 'America/Guayaquil',
  GYG: 'Asia/Yakutsk',
  GYI: 'Africa/Kigali',
  GYL: 'Australia/Perth',
  GYM: 'America/Hermosillo',
  GYN: 'America/Sao_Paulo',
  GYP: 'Australia/Brisbane',
  GYR: 'America/Phoenix',
  GYS: 'Asia/Shanghai',
  GYU: 'Asia/Shanghai',
  GZA: 'Asia/Gaza',
  GZI: 'Asia/Kabul',
  GZO: 'Pacific/Guadalcanal',
  GZP: 'Europe/Istanbul',
  GZT: 'Europe/Istanbul',
  GZW: 'Asia/Tehran',
  HAA: 'Europe/Oslo',
  HAB: 'America/Chicago',
  HAC: 'Asia/Tokyo',
  HAD: 'Europe/Stockholm',
  HAF: 'America/Los_Angeles',
  HAH: 'Indian/Comoro',
  HAI: 'America/Detroit',
  HAJ: 'Europe/Berlin',
  HAK: 'Asia/Shanghai',
  HAL: 'Africa/Windhoek',
  HAM: 'Europe/Berlin',
  HAN: 'Asia/Ho_Chi_Minh',
  HAO: 'America/New_York',
  HAQ: 'Indian/Maldives',
  HAR: 'America/New_York',
  HAS: 'Asia/Riyadh',
  HAT: 'Australia/Brisbane',
  HAU: 'Europe/Oslo',
  HAV: 'America/Havana',
  HAW: 'Europe/London',
  HAZ: 'Pacific/Port_Moresby',
  HBA: 'Australia/Hobart',
  HBB: 'America/Denver',
  HBD: 'Pacific/Port_Moresby',
  HBE: 'Africa/Cairo',
  HBG: 'America/Chicago',
  HBH: 'America/Juneau',
  HBK: 'America/Phoenix',
  HBQ: 'Asia/Shanghai',
  HBR: 'America/Chicago',
  HBT: 'Asia/Colombo',
  HBU: 'Asia/Hovd',
  HBX: 'Asia/Kolkata',
  HCA: 'America/Chicago',
  HCC: 'America/New_York',
  HCJ: 'Asia/Shanghai',
  HCM: 'Africa/Mogadishu',
  HCN: 'Asia/Taipei',
  HCQ: 'Australia/Perth',
  HCR: 'America/Anchorage',
  HCW: 'America/New_York',
  HDB: 'Europe/Berlin',
  HDD: 'Asia/Karachi',
  HDE: 'America/Chicago',
  HDF: 'Europe/Berlin',
  HDG: 'Asia/Shanghai',
  HDH: 'Pacific/Honolulu',
  HDM: 'Asia/Tehran',
  HDN: 'America/Denver',
  HDR: 'Asia/Tehran',
  HDS: 'Africa/Johannesburg',
  HDY: 'Asia/Bangkok',
  HEA: 'Asia/Kabul',
  HEB: 'Asia/Yangon',
  HED: 'America/Anchorage',
  HEE: 'America/Chicago',
  HEH: 'Asia/Yangon',
  HEI: 'Europe/Berlin',
  HEK: 'Asia/Shanghai',
  HEL: 'Europe/Helsinki',
  HEM: 'Europe/Helsinki',
  HEO: 'Pacific/Port_Moresby',
  HER: 'Europe/Athens',
  HES: 'America/Los_Angeles',
  HET: 'Asia/Shanghai',
  HEZ: 'America/Chicago',
  HFA: 'Asia/Jerusalem',
  HFD: 'America/New_York',
  HFE: 'Asia/Shanghai',
  HFF: 'America/New_York',
  HFN: 'Atlantic/Reykjavik',
  HFS: 'Europe/Stockholm',
  HFT: 'Europe/Oslo',
  HGA: 'Africa/Mogadishu',
  HGD: 'Australia/Brisbane',
  HGE: 'America/Caracas',
  HGH: 'Asia/Shanghai',
  HGI: 'Africa/Khartoum',
  HGL: 'Europe/Berlin',
  HGN: 'Asia/Bangkok',
  HGO: 'Africa/Abidjan',
  HGR: 'America/New_York',
  HGS: 'Africa/Freetown',
  HGU: 'Pacific/Port_Moresby',
  HGZ: 'America/Anchorage',
  HHE: 'Asia/Tokyo',
  HHH: 'America/New_York',
  HHI: 'Pacific/Honolulu',
  HHN: 'Europe/Berlin',
  HHQ: 'Asia/Bangkok',
  HHR: 'America/Los_Angeles',
  HHZ: 'Pacific/Tahiti',
  HIA: 'Asia/Shanghai',
  HIB: 'America/Chicago',
  HID: 'Australia/Brisbane',
  HIE: 'America/New_York',
  HIF: 'America/Denver',
  HIG: 'Australia/Brisbane',
  HII: 'America/Phoenix',
  HIJ: 'Asia/Tokyo',
  HIL: 'Africa/Addis_Ababa',
  HIM: 'Asia/Colombo',
  HIN: 'Asia/Seoul',
  HIO: 'America/Los_Angeles',
  HIP: 'Australia/Brisbane',
  HIR: 'Pacific/Guadalcanal',
  HIT: 'Pacific/Port_Moresby',
  HIW: 'Asia/Tokyo',
  HJJ: 'Asia/Shanghai',
  HJR: 'Asia/Kolkata',
  HJT: 'Asia/Ulaanbaatar',
  HKA: 'America/Chicago',
  HKB: 'America/Anchorage',
  HKD: 'Asia/Tokyo',
  HKG: 'Asia/Hong_Kong',
  HKK: 'Pacific/Auckland',
  HKN: 'Pacific/Port_Moresby',
  HKR: 'Africa/Nairobi',
  HKS: 'America/Chicago',
  HKT: 'Asia/Bangkok',
  HKV: 'Europe/Sofia',
  HKY: 'America/New_York',
  HLA: 'Africa/Johannesburg',
  HLB: 'America/Indiana/Indianapolis',
  HLC: 'America/Chicago',
  HLD: 'Asia/Shanghai',
  HLE: 'Atlantic/St_Helena',
  HLF: 'Europe/Stockholm',
  HLG: 'America/New_York',
  HLH: 'Asia/Shanghai',
  HLI: 'America/Los_Angeles',
  HLL: 'Australia/Perth',
  HLM: 'America/Detroit',
  HLN: 'America/Denver',
  HLP: 'Asia/Jakarta',
  HLR: 'America/Chicago',
  HLS: 'Australia/Hobart',
  HLT: 'Australia/Melbourne',
  HLV: 'Australia/Brisbane',
  HLW: 'Africa/Johannesburg',
  HLZ: 'Pacific/Auckland',
  HMA: 'Asia/Yekaterinburg',
  HMB: 'Africa/Cairo',
  HME: 'Africa/Algiers',
  HMG: 'Australia/Darwin',
  HMI: 'Asia/Urumqi',
  HMJ: 'Europe/Kiev',
  HMN: 'America/Denver',
  HMO: 'America/Hermosillo',
  HMR: 'Europe/Oslo',
  HMT: 'America/Los_Angeles',
  HMV: 'Europe/Stockholm',
  HMY: 'Asia/Seoul',
  HNA: 'Asia/Tokyo',
  HNB: 'America/Indiana/Vincennes',
  HNC: 'America/New_York',
  HND: 'Asia/Tokyo',
  HNH: 'America/Juneau',
  HNI: 'Pacific/Port_Moresby',
  HNL: 'Pacific/Honolulu',
  HNM: 'Pacific/Honolulu',
  HNN: 'Pacific/Port_Moresby',
  HNS: 'America/Juneau',
  HNY: 'Asia/Shanghai',
  HOA: 'Africa/Nairobi',
  HOB: 'America/Denver',
  HOC: 'Pacific/Port_Moresby',
  HOD: 'Asia/Aden',
  HOE: 'Asia/Vientiane',
  HOF: 'Asia/Riyadh',
  HOG: 'America/Havana',
  HOH: 'Europe/Vienna',
  HOI: 'Pacific/Tahiti',
  HOK: 'Australia/Darwin',
  HOM: 'America/Anchorage',
  HON: 'America/Chicago',
  HOO: 'Asia/Ho_Chi_Minh',
  HOP: 'America/Chicago',
  HOQ: 'Europe/Berlin',
  HOR: 'Atlantic/Azores',
  HOS: 'America/Argentina/Salta',
  HOT: 'America/Chicago',
  HOU: 'America/Chicago',
  HOV: 'Europe/Oslo',
  HOX: 'Asia/Yangon',
  HOY: 'Europe/London',
  HPA: 'Pacific/Tongatapu',
  HPB: 'America/Nome',
  HPE: 'Australia/Brisbane',
  HPG: 'Asia/Shanghai',
  HPH: 'Asia/Ho_Chi_Minh',
  HPN: 'America/New_York',
  HPT: 'America/Chicago',
  HPV: 'Pacific/Honolulu',
  HPY: 'America/Chicago',
  HQM: 'America/Los_Angeles',
  HRB: 'Asia/Shanghai',
  HRC: 'Asia/Almaty',
  HRE: 'Africa/Harare',
  HRG: 'Africa/Cairo',
  HRI: 'Asia/Colombo',
  HRK: 'Europe/Zaporozhye',
  HRL: 'America/Chicago',
  HRM: 'Africa/Algiers',
  HRO: 'America/Chicago',
  HRR: 'America/Bogota',
  HRS: 'Africa/Johannesburg',
  HRT: 'Europe/London',
  HRY: 'Australia/Darwin',
  HRZ: 'America/Sao_Paulo',
  HSB: 'America/Chicago',
  HSC: 'Asia/Shanghai',
  HSG: 'Asia/Tokyo',
  HSH: 'America/Los_Angeles',
  HSI: 'America/Chicago',
  HSJ: 'Asia/Shanghai',
  HSK: 'Europe/Madrid',
  HSL: 'America/Anchorage',
  HSM: 'Australia/Melbourne',
  HSN: 'Asia/Shanghai',
  HSP: 'America/New_York',
  HSS: 'Asia/Kolkata',
  HST: 'America/New_York',
  HSV: 'America/Chicago',
  HSZ: 'Asia/Taipei',
  HTA: 'Asia/Yakutsk',
  HTG: 'Asia/Krasnoyarsk',
  HTH: 'America/Los_Angeles',
  HTI: 'Australia/Lindeman',
  HTL: 'America/Detroit',
  HTM: 'Asia/Ulaanbaatar',
  HTN: 'Asia/Shanghai',
  HTO: 'America/New_York',
  HTR: 'Asia/Tokyo',
  HTS: 'America/New_York',
  HTT: 'Asia/Urumqi',
  HTU: 'Australia/Melbourne',
  HTV: 'America/Chicago',
  HTW: 'America/New_York',
  HTY: 'Europe/Istanbul',
  HTZ: 'America/Bogota',
  HUA: 'America/Chicago',
  HUB: 'Australia/Darwin',
  HUC: 'America/Puerto_Rico',
  HUD: 'America/Chicago',
  HUE: 'Africa/Addis_Ababa',
  HUF: 'America/Indiana/Indianapolis',
  HUG: 'America/Guatemala',
  HUH: 'Pacific/Tahiti',
  HUI: 'Asia/Ho_Chi_Minh',
  HUJ: 'America/Chicago',
  HUK: 'Africa/Gaborone',
  HUL: 'America/New_York',
  HUM: 'America/Chicago',
  HUN: 'Asia/Taipei',
  HUO: 'Asia/Shanghai',
  HUQ: 'Africa/Tripoli',
  HUS: 'America/Anchorage',
  HUT: 'America/Chicago',
  HUU: 'America/Lima',
  HUV: 'Europe/Stockholm',
  HUW: 'America/Manaus',
  HUX: 'America/Mexico_City',
  HUY: 'Europe/London',
  HUZ: 'Asia/Shanghai',
  HVA: 'Indian/Antananarivo',
  HVB: 'Australia/Brisbane',
  HVD: 'Asia/Hovd',
  HVE: 'America/Denver',
  HVG: 'Europe/Oslo',
  HVK: 'Atlantic/Reykjavik',
  HVN: 'America/New_York',
  HVR: 'America/Denver',
  HVS: 'America/New_York',
  HWA: 'Pacific/Port_Moresby',
  HWD: 'America/Los_Angeles',
  HWI: 'America/Juneau',
  HWK: 'Australia/Adelaide',
  HWN: 'Africa/Harare',
  HWO: 'America/New_York',
  HXD: 'Asia/Urumqi',
  HXX: 'Australia/Sydney',
  HYA: 'America/New_York',
  HYC: 'Europe/London',
  HYD: 'Asia/Kolkata',
  HYF: 'Pacific/Port_Moresby',
  HYG: 'America/Metlakatla',
  HYL: 'America/Juneau',
  HYN: 'Asia/Shanghai',
  HYR: 'America/Chicago',
  HYS: 'America/Chicago',
  HYV: 'Europe/Helsinki',
  HZB: 'Europe/Paris',
  HZG: 'Asia/Shanghai',
  HZH: 'Asia/Shanghai',
  HZK: 'Atlantic/Reykjavik',
  HZL: 'America/New_York',
  HZP: 'America/Edmonton',
  HZV: 'Africa/Johannesburg',
  IAA: 'Asia/Krasnoyarsk',
  IAB: 'America/Chicago',
  IAD: 'America/New_York',
  IAG: 'America/New_York',
  IAH: 'America/Chicago',
  IAM: 'Africa/Algiers',
  IAN: 'America/Anchorage',
  IAO: 'Asia/Manila',
  IAQ: 'Asia/Tehran',
  IAR: 'Europe/Moscow',
  IAS: 'Europe/Bucharest',
  IAU: 'Pacific/Port_Moresby',
  IBA: 'Africa/Lagos',
  IBB: 'Pacific/Galapagos',
  IBE: 'America/Bogota',
  IBI: 'Pacific/Port_Moresby',
  IBL: 'Africa/Maputo',
  IBO: 'Africa/Maputo',
  IBP: 'America/Lima',
  IBR: 'Asia/Tokyo',
  IBZ: 'Europe/Madrid',
  ICA: 'America/Caracas',
  ICC: 'America/Caracas',
  ICI: 'Pacific/Fiji',
  ICK: 'America/Paramaribo',
  ICL: 'America/Chicago',
  ICN: 'Asia/Seoul',
  ICO: 'Asia/Manila',
  ICS: 'America/Boise',
  ICT: 'America/Chicago',
  ICY: 'America/Anchorage',
  IDA: 'America/Boise',
  IDB: 'Europe/Stockholm',
  IDC: 'Africa/Maputo',
  IDF: 'Africa/Kinshasa',
  IDG: 'America/Chicago',
  IDH: 'America/Los_Angeles',
  IDI: 'America/New_York',
  IDK: 'Australia/Adelaide',
  IDN: 'Pacific/Port_Moresby',
  IDO: 'America/Araguaina',
  IDP: 'America/Chicago',
  IDR: 'Asia/Kolkata',
  IDY: 'Europe/Paris',
  IEG: 'Europe/Warsaw',
  IEJ: 'Asia/Tokyo',
  IES: 'Europe/Berlin',
  IEV: 'Europe/Kiev',
  IFA: 'America/Chicago',
  IFF: 'Australia/Brisbane',
  IFH: 'Asia/Tehran',
  IFJ: 'Atlantic/Reykjavik',
  IFL: 'Australia/Brisbane',
  IFN: 'Asia/Tehran',
  IFO: 'Europe/Uzhgorod',
  IFP: 'America/Phoenix',
  IFU: 'Indian/Maldives',
  IGA: 'America/Nassau',
  IGB: 'America/Argentina/Salta',
  IGD: 'Europe/Istanbul',
  IGE: 'Africa/Libreville',
  IGG: 'America/Anchorage',
  IGH: 'Australia/Brisbane',
  IGL: 'Europe/Istanbul',
  IGM: 'America/Phoenix',
  IGN: 'Asia/Manila',
  IGO: 'America/Bogota',
  IGR: 'America/Argentina/Cordoba',
  IGS: 'Europe/Berlin',
  IGT: 'Europe/Moscow',
  IGU: 'America/Sao_Paulo',
  IHC: 'Africa/Maputo',
  IHN: 'Asia/Aden',
  IHO: 'Indian/Antananarivo',
  IHR: 'Asia/Tehran',
  IHU: 'Pacific/Port_Moresby',
  IIA: 'Europe/Dublin',
  IIL: 'Asia/Tehran',
  IIS: 'Pacific/Bougainville',
  IJK: 'Europe/Samara',
  IJU: 'America/Sao_Paulo',
  IJX: 'America/Chicago',
  IKA: 'Asia/Tehran',
  IKB: 'America/New_York',
  IKI: 'Asia/Tokyo',
  IKK: 'America/Chicago',
  IKL: 'Africa/Kinshasa',
  IKO: 'America/Adak',
  IKP: 'Australia/Brisbane',
  IKS: 'Asia/Yakutsk',
  IKT: 'Asia/Irkutsk',
  IKU: 'Asia/Bishkek',
  ILA: 'Asia/Jayapura',
  ILD: 'Europe/Madrid',
  ILE: 'America/Chicago',
  ILF: 'America/Winnipeg',
  ILG: 'America/New_York',
  ILH: 'Europe/Berlin',
  ILI: 'America/Anchorage',
  ILK: 'Indian/Antananarivo',
  ILL: 'America/Chicago',
  ILM: 'America/New_York',
  ILN: 'America/New_York',
  ILO: 'Asia/Manila',
  ILP: 'Pacific/Noumea',
  ILQ: 'America/Lima',
  ILR: 'Africa/Lagos',
  ILS: 'America/El_Salvador',
  ILU: 'Africa/Nairobi',
  ILX: 'Pacific/Port_Moresby',
  ILY: 'Europe/London',
  ILZ: 'Europe/Bratislava',
  IMA: 'Pacific/Port_Moresby',
  IMB: 'America/Guyana',
  IMD: 'Pacific/Port_Moresby',
  IMF: 'Asia/Kolkata',
  IMG: 'Africa/Maputo',
  IMI: 'Pacific/Majuro',
  IMK: 'Asia/Kathmandu',
  IML: 'America/Denver',
  IMM: 'America/New_York',
  IMN: 'Pacific/Port_Moresby',
  IMO: 'Africa/Bangui',
  IMP: 'America/Araguaina',
  IMQ: 'Asia/Tehran',
  IMT: 'America/Menominee',
  INA: 'Europe/Moscow',
  INB: 'America/Belize',
  INC: 'Asia/Shanghai',
  IND: 'America/Indiana/Indianapolis',
  INE: 'Africa/Maputo',
  INF: 'Africa/Algiers',
  INH: 'Africa/Maputo',
  INI: 'Europe/Belgrade',
  INJ: 'Australia/Brisbane',
  INK: 'America/Chicago',
  INL: 'America/Chicago',
  INM: 'Australia/Adelaide',
  INN: 'Europe/Vienna',
  INO: 'Africa/Kinshasa',
  INQ: 'Europe/Dublin',
  INS: 'America/Los_Angeles',
  INT: 'America/New_York',
  INU: 'Pacific/Nauru',
  INV: 'Europe/London',
  INW: 'America/Phoenix',
  INX: 'Asia/Jayapura',
  INY: 'Africa/Johannesburg',
  INZ: 'Africa/Algiers',
  IOA: 'Europe/Athens',
  IOK: 'Pacific/Port_Moresby',
  IOM: 'Europe/Isle_of_Man',
  ION: 'Africa/Brazzaville',
  IOP: 'Pacific/Port_Moresby',
  IOR: 'Europe/Dublin',
  IOS: 'America/Bahia',
  IOW: 'America/Chicago',
  IPA: 'Pacific/Efate',
  IPC: 'Pacific/Easter',
  IPE: 'Asia/Manila',
  IPG: 'America/Manaus',
  IPH: 'Asia/Kuala_Lumpur',
  IPI: 'America/Bogota',
  IPL: 'America/Los_Angeles',
  IPN: 'America/Sao_Paulo',
  IPT: 'America/New_York',
  IPU: 'America/Bahia',
  IPZ: 'America/Costa_Rica',
  IQA: 'Asia/Baghdad',
  IQM: 'Asia/Urumqi',
  IQN: 'Asia/Shanghai',
  IQQ: 'America/Santiago',
  IQT: 'America/Lima',
  IRA: 'Pacific/Guadalcanal',
  IRB: 'America/Chicago',
  IRC: 'America/Anchorage',
  IRD: 'Asia/Dhaka',
  IRE: 'America/Bahia',
  IRG: 'Australia/Brisbane',
  IRI: 'Africa/Dar_es_Salaam',
  IRJ: 'America/Argentina/La_Rioja',
  IRK: 'America/Chicago',
  IRM: 'Asia/Yekaterinburg',
  IRN: 'America/Tegucigalpa',
  IRO: 'Africa/Bangui',
  IRP: 'Africa/Lubumbashi',
  IRS: 'America/Detroit',
  IRU: 'Asia/Colombo',
  IRZ: 'America/Manaus',
  ISA: 'Australia/Brisbane',
  ISB: 'Asia/Karachi',
  ISC: 'Europe/London',
  ISD: 'America/Bogota',
  ISE: 'Europe/Istanbul',
  ISG: 'Asia/Tokyo',
  ISI: 'Australia/Brisbane',
  ISJ: 'America/Cancun',
  ISK: 'Asia/Kolkata',
  ISL: 'Europe/Istanbul',
  ISM: 'America/New_York',
  ISN: 'America/Chicago',
  ISO: 'America/New_York',
  ISP: 'America/New_York',
  ISQ: 'America/Detroit',
  ISS: 'America/New_York',
  IST: 'Europe/Istanbul',
  ISU: 'Asia/Baghdad',
  ISW: 'America/Chicago',
  ITA: 'America/Manaus',
  ITB: 'America/Manaus',
  ITE: 'America/Bahia',
  ITH: 'America/New_York',
  ITI: 'America/Sao_Paulo',
  ITK: 'Pacific/Port_Moresby',
  ITM: 'Asia/Tokyo',
  ITN: 'America/Bahia',
  ITO: 'Pacific/Honolulu',
  ITP: 'America/Sao_Paulo',
  ITQ: 'America/Sao_Paulo',
  ITR: 'America/Sao_Paulo',
  ITU: 'Asia/Magadan',
  IUE: 'Pacific/Niue',
  IUL: 'Asia/Jayapura',
  IUS: 'Pacific/Port_Moresby',
  IVA: 'Indian/Antananarivo',
  IVC: 'Pacific/Auckland',
  IVG: 'Europe/Podgorica',
  IVI: 'America/Panama',
  IVL: 'Europe/Helsinki',
  IVO: 'America/Bogota',
  IVR: 'Australia/Sydney',
  IVW: 'Australia/Darwin',
  IWA: 'Europe/Moscow',
  IWD: 'America/Menominee',
  IWJ: 'Asia/Tokyo',
  IWK: 'Asia/Tokyo',
  IWO: 'Asia/Tokyo',
  IWS: 'America/Chicago',
  IXA: 'Asia/Kolkata',
  IXB: 'Asia/Kolkata',
  IXC: 'Asia/Kolkata',
  IXD: 'Asia/Kolkata',
  IXE: 'Asia/Kolkata',
  IXG: 'Asia/Kolkata',
  IXH: 'Asia/Kolkata',
  IXI: 'Asia/Kolkata',
  IXJ: 'Asia/Kolkata',
  IXK: 'Asia/Kolkata',
  IXL: 'Asia/Kolkata',
  IXM: 'Asia/Kolkata',
  IXN: 'Asia/Kolkata',
  IXP: 'Asia/Kolkata',
  IXQ: 'Asia/Kolkata',
  IXR: 'Asia/Kolkata',
  IXS: 'Asia/Kolkata',
  IXT: 'Asia/Kolkata',
  IXU: 'Asia/Kolkata',
  IXV: 'Asia/Kolkata',
  IXW: 'Asia/Kolkata',
  IXY: 'Asia/Kolkata',
  IXZ: 'Asia/Kolkata',
  IYK: 'America/Los_Angeles',
  IZA: 'America/Sao_Paulo',
  IZM: 'Europe/Istanbul',
  IZO: 'Asia/Tokyo',
  IZT: 'America/Mexico_City',
  JAA: 'Asia/Kabul',
  JAB: 'Australia/Darwin',
  JAC: 'America/Denver',
  JAD: 'Australia/Perth',
  JAE: 'America/Lima',
  JAF: 'Asia/Colombo',
  JAG: 'Asia/Karachi',
  JAI: 'Asia/Kolkata',
  JAK: 'America/Port-au-Prince',
  JAL: 'America/Mexico_City',
  JAM: 'Europe/Sofia',
  JAN: 'America/Chicago',
  JAP: 'America/Costa_Rica',
  JAQ: 'Pacific/Port_Moresby',
  JAR: 'Asia/Tehran',
  JAS: 'America/Chicago',
  JAT: 'Pacific/Majuro',
  JAU: 'America/Lima',
  JAV: 'America/Godthab',
  JAX: 'America/New_York',
  JBB: 'Asia/Jakarta',
  JBQ: 'America/Santo_Domingo',
  JBR: 'America/Chicago',
  JBT: 'America/Anchorage',
  JCB: 'America/Sao_Paulo',
  JCH: 'America/Godthab',
  JCI: 'America/Chicago',
  JCK: 'Australia/Brisbane',
  JCM: 'America/Bahia',
  JCR: 'America/Manaus',
  JCT: 'America/Chicago',
  JCY: 'America/Chicago',
  JDA: 'America/Los_Angeles',
  JDF: 'America/Sao_Paulo',
  JDG: 'Asia/Seoul',
  JDH: 'Asia/Kolkata',
  JDN: 'America/Denver',
  JDO: 'America/Fortaleza',
  JDR: 'America/Sao_Paulo',
  JDZ: 'Asia/Shanghai',
  JED: 'Asia/Riyadh',
  JEE: 'America/Port-au-Prince',
  JEF: 'America/Chicago',
  JEG: 'America/Godthab',
  JEJ: 'Pacific/Majuro',
  JEK: 'Africa/Lusaka',
  JEQ: 'America/Bahia',
  JER: 'Europe/Jersey',
  JFK: 'America/New_York',
  JFN: 'America/New_York',
  JFR: 'America/Godthab',
  JGA: 'Asia/Kolkata',
  JGB: 'Asia/Kolkata',
  JGD: 'Asia/Shanghai',
  JGN: 'Asia/Shanghai',
  JGS: 'Asia/Shanghai',
  JHB: 'Asia/Kuala_Lumpur',
  JHG: 'Asia/Shanghai',
  JHL: 'America/Edmonton',
  JHM: 'Pacific/Honolulu',
  JHS: 'America/Godthab',
  JHW: 'America/New_York',
  JIA: 'America/Cuiaba',
  JIB: 'Africa/Djibouti',
  JIC: 'Asia/Shanghai',
  JIJ: 'Africa/Addis_Ababa',
  JIK: 'Europe/Athens',
  JIL: 'Asia/Shanghai',
  JIM: 'Africa/Addis_Ababa',
  JIN: 'Africa/Kampala',
  JIO: 'Asia/Jayapura',
  JIP: 'America/Guayaquil',
  JIQ: 'Asia/Shanghai',
  JIR: 'Asia/Kathmandu',
  JIU: 'Asia/Shanghai',
  JIW: 'Asia/Karachi',
  JJA: 'Pacific/Guadalcanal',
  JJD: 'America/Fortaleza',
  JJG: 'America/Sao_Paulo',
  JJI: 'America/Lima',
  JJM: 'Africa/Nairobi',
  JJN: 'Asia/Shanghai',
  JKG: 'Europe/Stockholm',
  JKH: 'Europe/Athens',
  JKL: 'Europe/Athens',
  JKR: 'Asia/Kathmandu',
  JKT: 'Asia/Jakarta',
  JKV: 'America/Chicago',
  JLA: 'America/Anchorage',
  JLN: 'America/Chicago',
  JLR: 'Asia/Kolkata',
  JLS: 'America/Sao_Paulo',
  JMB: 'Africa/Luanda',
  JMJ: 'Asia/Shanghai',
  JMK: 'Europe/Athens',
  JMO: 'Asia/Kathmandu',
  JMS: 'America/Chicago',
  JMU: 'Asia/Shanghai',
  JNA: 'America/Sao_Paulo',
  JNB: 'Africa/Johannesburg',
  JNG: 'Asia/Shanghai',
  JNI: 'America/Argentina/Buenos_Aires',
  JNJ: 'Asia/Muscat',
  JNU: 'America/Juneau',
  JNX: 'Europe/Athens',
  JNZ: 'Asia/Shanghai',
  JOE: 'Europe/Helsinki',
  JOG: 'Asia/Jakarta',
  JOH: 'Africa/Johannesburg',
  JOI: 'America/Sao_Paulo',
  JOJ: 'America/Cambridge_Bay',
  JOK: 'Europe/Moscow',
  JOL: 'Asia/Manila',
  JOM: 'Africa/Dar_es_Salaam',
  JOP: 'Pacific/Port_Moresby',
  JOS: 'Africa/Lagos',
  JOT: 'America/Chicago',
  JPA: 'America/Fortaleza',
  JPR: 'America/Porto_Velho',
  JQA: 'America/Godthab',
  JQE: 'America/Panama',
  JRF: 'Pacific/Honolulu',
  JRG: 'Asia/Kolkata',
  JRH: 'Asia/Kolkata',
  JRN: 'America/Cuiaba',
  JRO: 'Africa/Dar_es_Salaam',
  JRS: 'Asia/Hebron',
  JSA: 'Asia/Kolkata',
  JSH: 'Europe/Athens',
  JSI: 'Europe/Athens',
  JSJ: 'Asia/Shanghai',
  JSK: 'Asia/Tehran',
  JSM: 'America/Argentina/Catamarca',
  JSR: 'Asia/Dhaka',
  JST: 'America/New_York',
  JSU: 'America/Godthab',
  JSY: 'Europe/Athens',
  JTC: 'America/Sao_Paulo',
  JTI: 'America/Sao_Paulo',
  JTR: 'Europe/Athens',
  JTY: 'Europe/Athens',
  JUA: 'America/Cuiaba',
  JUB: 'Africa/Juba',
  JUH: 'Asia/Shanghai',
  JUI: 'Europe/Berlin',
  JUJ: 'America/Argentina/Jujuy',
  JUL: 'America/Lima',
  JUM: 'Asia/Kathmandu',
  JUN: 'Australia/Brisbane',
  JUO: 'America/Bogota',
  JUR: 'Australia/Perth',
  JUT: 'America/Tegucigalpa',
  JUV: 'America/Godthab',
  JUZ: 'Asia/Shanghai',
  JVA: 'Indian/Antananarivo',
  JVI: 'America/New_York',
  JVL: 'America/Chicago',
  JWA: 'Africa/Gaborone',
  JWN: 'Asia/Tehran',
  JWO: 'Asia/Seoul',
  JXA: 'Asia/Shanghai',
  JXN: 'America/Detroit',
  JYR: 'Asia/Tehran',
  JYV: 'Europe/Helsinki',
  JZH: 'Asia/Shanghai',
  KAA: 'Africa/Lusaka',
  KAB: 'Africa/Harare',
  KAC: 'Asia/Damascus',
  KAD: 'Africa/Lagos',
  KAE: 'America/Sitka',
  KAF: 'Pacific/Bougainville',
  KAG: 'Asia/Seoul',
  KAI: 'America/Guyana',
  KAJ: 'Europe/Helsinki',
  KAK: 'Pacific/Port_Moresby',
  KAL: 'America/Anchorage',
  KAM: 'Asia/Aden',
  KAN: 'Africa/Lagos',
  KAO: 'Europe/Helsinki',
  KAP: 'Africa/Lubumbashi',
  KAQ: 'Pacific/Port_Moresby',
  KAR: 'America/Guyana',
  KAS: 'Africa/Windhoek',
  KAT: 'Pacific/Auckland',
  KAU: 'Europe/Helsinki',
  KAV: 'America/Caracas',
  KAW: 'Asia/Yangon',
  KAX: 'Australia/Perth',
  KAY: 'Pacific/Fiji',
  KAZ: 'Asia/Jayapura',
  KBA: 'Africa/Freetown',
  KBB: 'Australia/Darwin',
  KBC: 'America/Anchorage',
  KBD: 'Australia/Perth',
  KBE: 'America/Sitka',
  KBF: 'Asia/Jayapura',
  KBG: 'Africa/Kampala',
  KBH: 'Africa/Dar_es_Salaam',
  KBI: 'Africa/Douala',
  KBJ: 'Australia/Darwin',
  KBL: 'Asia/Kabul',
  KBM: 'Pacific/Port_Moresby',
  KBN: 'Africa/Lubumbashi',
  KBO: 'Africa/Lubumbashi',
  KBP: 'Europe/Kiev',
  KBQ: 'Africa/Blantyre',
  KBR: 'Asia/Kuala_Lumpur',
  KBS: 'Africa/Freetown',
  KBT: 'Pacific/Majuro',
  KBU: 'Asia/Makassar',
  KBV: 'Asia/Bangkok',
  KBW: 'America/Anchorage',
  KBX: 'Asia/Jayapura',
  KBY: 'Australia/Adelaide',
  KBZ: 'Pacific/Auckland',
  KCA: 'Asia/Urumqi',
  KCB: 'America/Paramaribo',
  KCC: 'America/Metlakatla',
  KCD: 'Asia/Jayapura',
  KCE: 'Australia/Brisbane',
  KCF: 'Asia/Karachi',
  KCG: 'America/Anchorage',
  KCH: 'Asia/Kuching',
  KCJ: 'Pacific/Port_Moresby',
  KCK: 'Asia/Irkutsk',
  KCL: 'America/Anchorage',
  KCM: 'Europe/Istanbul',
  KCN: 'America/Adak',
  KCO: 'Europe/Istanbul',
  KCP: 'Europe/Kiev',
  KCQ: 'America/Anchorage',
  KCR: 'America/Anchorage',
  KCS: 'Australia/Darwin',
  KCT: 'Asia/Colombo',
  KCU: 'Africa/Kampala',
  KCZ: 'Asia/Tokyo',
  KDA: 'Africa/Dakar',
  KDB: 'Australia/Perth',
  KDC: 'Africa/Porto-Novo',
  KDD: 'Asia/Karachi',
  KDE: 'Pacific/Port_Moresby',
  KDH: 'Asia/Kabul',
  KDI: 'Asia/Makassar',
  KDJ: 'Africa/Libreville',
  KDK: 'America/Anchorage',
  KDL: 'Europe/Tallinn',
  KDM: 'Indian/Maldives',
  KDN: 'Africa/Libreville',
  KDO: 'Indian/Maldives',
  KDP: 'Pacific/Port_Moresby',
  KDQ: 'Pacific/Port_Moresby',
  KDR: 'Pacific/Port_Moresby',
  KDS: 'Australia/Brisbane',
  KDT: 'Asia/Bangkok',
  KDU: 'Asia/Karachi',
  KDV: 'Pacific/Fiji',
  KDW: 'Asia/Colombo',
  KDX: 'Africa/Khartoum',
  KDY: 'Asia/Vladivostok',
  KDZ: 'Asia/Colombo',
  KEB: 'America/Anchorage',
  KEC: 'Africa/Lubumbashi',
  KED: 'Africa/Nouakchott',
  KEE: 'Africa/Brazzaville',
  KEF: 'Atlantic/Reykjavik',
  KEG: 'Pacific/Port_Moresby',
  KEH: 'America/Los_Angeles',
  KEI: 'Asia/Jayapura',
  KEJ: 'Asia/Novokuznetsk',
  KEK: 'America/Anchorage',
  KEL: 'Europe/Berlin',
  KEM: 'Europe/Helsinki',
  KEN: 'Africa/Freetown',
  KEO: 'Africa/Abidjan',
  KEP: 'Asia/Kathmandu',
  KEQ: 'Asia/Jayapura',
  KER: 'Asia/Tehran',
  KES: 'America/Winnipeg',
  KET: 'Asia/Yangon',
  KEU: 'Africa/Nairobi',
  KEV: 'Europe/Helsinki',
  KEW: 'America/Winnipeg',
  KEX: 'Pacific/Port_Moresby',
  KEY: 'Africa/Nairobi',
  KEZ: 'Asia/Colombo',
  KFA: 'Africa/Nouakchott',
  KFE: 'Australia/Perth',
  KFG: 'Australia/Darwin',
  KFP: 'America/Nome',
  KFS: 'Europe/Istanbul',
  KGA: 'Africa/Lubumbashi',
  KGB: 'Pacific/Port_Moresby',
  KGC: 'Australia/Adelaide',
  KGD: 'Europe/Kaliningrad',
  KGE: 'Pacific/Guadalcanal',
  KGF: 'Asia/Almaty',
  KGG: 'Africa/Dakar',
  KGH: 'Pacific/Port_Moresby',
  KGI: 'Australia/Perth',
  KGJ: 'Africa/Blantyre',
  KGK: 'America/Anchorage',
  KGL: 'Africa/Kigali',
  KGM: 'Pacific/Port_Moresby',
  KGN: 'Africa/Kinshasa',
  KGO: 'Europe/Zaporozhye',
  KGP: 'Asia/Yekaterinburg',
  KGR: 'Australia/Darwin',
  KGS: 'Europe/Athens',
  KGT: 'Asia/Shanghai',
  KGU: 'Asia/Kuching',
  KGW: 'Pacific/Port_Moresby',
  KGX: 'America/Anchorage',
  KGY: 'Australia/Brisbane',
  KGZ: 'America/Anchorage',
  KHA: 'Asia/Tehran',
  KHC: 'Europe/Simferopol',
  KHD: 'Asia/Tehran',
  KHE: 'Europe/Simferopol',
  KHG: 'Asia/Shanghai',
  KHH: 'Asia/Taipei',
  KHI: 'Asia/Karachi',
  KHJ: 'Europe/Helsinki',
  KHK: 'Asia/Tehran',
  KHM: 'Asia/Yangon',
  KHN: 'Asia/Shanghai',
  KHO: 'Africa/Johannesburg',
  KHR: 'Asia/Ulaanbaatar',
  KHS: 'Asia/Muscat',
  KHT: 'Asia/Kabul',
  KHU: 'Europe/Kiev',
  KHV: 'Asia/Vladivostok',
  KHW: 'Africa/Gaborone',
  KHX: 'Africa/Kampala',
  KHY: 'Asia/Tehran',
  KHZ: 'Pacific/Tahiti',
  KIA: 'Pacific/Port_Moresby',
  KIB: 'America/Anchorage',
  KIC: 'America/Los_Angeles',
  KID: 'Europe/Stockholm',
  KIE: 'Pacific/Bougainville',
  KIF: 'America/Winnipeg',
  KIG: 'Africa/Johannesburg',
  KIH: 'Asia/Tehran',
  KII: 'Pacific/Port_Moresby',
  KIJ: 'Asia/Tokyo',
  KIK: 'Asia/Baghdad',
  KIL: 'Africa/Lubumbashi',
  KIM: 'Africa/Johannesburg',
  KIN: 'America/Jamaica',
  KIO: 'Pacific/Majuro',
  KIP: 'America/Chicago',
  KIQ: 'Pacific/Port_Moresby',
  KIR: 'Europe/Dublin',
  KIS: 'Africa/Nairobi',
  KIT: 'Europe/Athens',
  KIU: 'Africa/Nairobi',
  KIV: 'Europe/Chisinau',
  KIW: 'Africa/Lusaka',
  KIX: 'Asia/Tokyo',
  KIY: 'Africa/Dar_es_Salaam',
  KIZ: 'Pacific/Port_Moresby',
  KJA: 'Asia/Krasnoyarsk',
  KJH: 'Asia/Shanghai',
  KJI: 'Asia/Urumqi',
  KJK: 'Europe/Brussels',
  KJP: 'Asia/Tokyo',
  KJT: 'Asia/Jakarta',
  KJU: 'Pacific/Port_Moresby',
  KJX: 'Asia/Jakarta',
  KKA: 'America/Nome',
  KKB: 'America/Anchorage',
  KKC: 'Asia/Bangkok',
  KKD: 'Pacific/Port_Moresby',
  KKE: 'Pacific/Auckland',
  KKG: 'America/Guyana',
  KKH: 'America/Nome',
  KKI: 'America/Anchorage',
  KKJ: 'Asia/Tokyo',
  KKK: 'America/Anchorage',
  KKL: 'America/Anchorage',
  KKM: 'Asia/Bangkok',
  KKN: 'Europe/Oslo',
  KKO: 'Pacific/Auckland',
  KKP: 'Australia/Brisbane',
  KKQ: 'Asia/Yekaterinburg',
  KKR: 'Pacific/Tahiti',
  KKS: 'Asia/Tehran',
  KKT: 'America/Chicago',
  KKU: 'America/Anchorage',
  KKW: 'Africa/Kinshasa',
  KKX: 'Asia/Tokyo',
  KKY: 'Europe/Dublin',
  KKZ: 'Asia/Phnom_Penh',
  KLB: 'Africa/Lusaka',
  KLC: 'Africa/Dakar',
  KLD: 'Europe/Moscow',
  KLE: 'Africa/Douala',
  KLF: 'Europe/Moscow',
  KLG: 'America/Anchorage',
  KLH: 'Asia/Kolkata',
  KLI: 'Africa/Kinshasa',
  KLK: 'Africa/Nairobi',
  KLL: 'America/Anchorage',
  KLM: 'Asia/Tehran',
  KLN: 'America/Anchorage',
  KLO: 'Asia/Manila',
  KLP: 'Asia/Pontianak',
  KLQ: 'Asia/Pontianak',
  KLR: 'Europe/Stockholm',
  KLS: 'America/Los_Angeles',
  KLU: 'Europe/Vienna',
  KLV: 'Europe/Prague',
  KLW: 'America/Juneau',
  KLX: 'Europe/Athens',
  KLY: 'Africa/Lubumbashi',
  KLZ: 'Africa/Johannesburg',
  KMA: 'Pacific/Port_Moresby',
  KMB: 'Pacific/Port_Moresby',
  KMC: 'Asia/Riyadh',
  KME: 'Africa/Kigali',
  KMF: 'Pacific/Port_Moresby',
  KMG: 'Asia/Shanghai',
  KMH: 'Africa/Johannesburg',
  KMI: 'Asia/Tokyo',
  KMJ: 'Asia/Tokyo',
  KMK: 'Africa/Brazzaville',
  KML: 'Australia/Brisbane',
  KMM: 'Asia/Jayapura',
  KMN: 'Africa/Lubumbashi',
  KMO: 'America/Anchorage',
  KMP: 'Africa/Windhoek',
  KMQ: 'Asia/Tokyo',
  KMR: 'Pacific/Port_Moresby',
  KMS: 'Africa/Accra',
  KMT: 'Asia/Phnom_Penh',
  KMU: 'Africa/Mogadishu',
  KMV: 'Asia/Yangon',
  KMW: 'Europe/Moscow',
  KMX: 'Asia/Riyadh',
  KMY: 'America/Anchorage',
  KMZ: 'Africa/Lusaka',
  KNA: 'America/Santiago',
  KNB: 'America/Denver',
  KND: 'Africa/Lubumbashi',
  KNE: 'Pacific/Port_Moresby',
  KNF: 'Europe/London',
  KNG: 'Asia/Jayapura',
  KNH: 'Asia/Taipei',
  KNI: 'Australia/Perth',
  KNJ: 'Africa/Brazzaville',
  KNK: 'America/Anchorage',
  KNL: 'Pacific/Port_Moresby',
  KNM: 'Africa/Lubumbashi',
  KNN: 'Africa/Conakry',
  KNO: 'Asia/Jakarta',
  KNP: 'Africa/Luanda',
  KNQ: 'Pacific/Noumea',
  KNR: 'Asia/Tehran',
  KNS: 'Australia/Currie',
  KNT: 'America/Chicago',
  KNU: 'Asia/Kolkata',
  KNW: 'America/Anchorage',
  KNX: 'Australia/Perth',
  KNZ: 'Africa/Bamako',
  KOA: 'Pacific/Honolulu',
  KOC: 'Pacific/Noumea',
  KOD: 'Asia/Makassar',
  KOE: 'Asia/Makassar',
  KOF: 'Africa/Johannesburg',
  KOG: 'Asia/Vientiane',
  KOH: 'Australia/Brisbane',
  KOI: 'Europe/London',
  KOJ: 'Asia/Tokyo',
  KOK: 'Europe/Helsinki',
  KOL: 'Africa/Bangui',
  KOM: 'Pacific/Port_Moresby',
  KON: 'Asia/Ho_Chi_Minh',
  KOO: 'Africa/Lubumbashi',
  KOP: 'Asia/Bangkok',
  KOQ: 'Europe/Berlin',
  KOR: 'Pacific/Port_Moresby',
  KOS: 'Asia/Phnom_Penh',
  KOT: 'America/Nome',
  KOU: 'Africa/Libreville',
  KOV: 'Asia/Almaty',
  KOW: 'Asia/Shanghai',
  KOX: 'Asia/Jayapura',
  KOY: 'America/Anchorage',
  KOZ: 'America/Anchorage',
  KPA: 'Pacific/Port_Moresby',
  KPB: 'America/Metlakatla',
  KPC: 'America/Nome',
  KPE: 'Pacific/Port_Moresby',
  KPF: 'Pacific/Port_Moresby',
  KPG: 'America/Guyana',
  KPI: 'Asia/Kuching',
  KPL: 'Pacific/Port_Moresby',
  KPM: 'Pacific/Port_Moresby',
  KPN: 'America/Nome',
  KPO: 'Asia/Seoul',
  KPP: 'Australia/Brisbane',
  KPR: 'America/Anchorage',
  KPS: 'Australia/Sydney',
  KPT: 'America/Los_Angeles',
  KPV: 'America/Anchorage',
  KPW: 'Asia/Anadyr',
  KPY: 'America/Anchorage',
  KQA: 'America/Anchorage',
  KQH: 'Asia/Kolkata',
  KQL: 'Pacific/Port_Moresby',
  KQR: 'Australia/Perth',
  KQT: 'Asia/Dushanbe',
  KRA: 'Australia/Melbourne',
  KRB: 'Australia/Brisbane',
  KRC: 'Asia/Jakarta',
  KRD: 'Australia/Darwin',
  KRE: 'Africa/Bujumbura',
  KRF: 'Europe/Stockholm',
  KRG: 'America/Guyana',
  KRI: 'Pacific/Port_Moresby',
  KRJ: 'Pacific/Port_Moresby',
  KRK: 'Europe/Warsaw',
  KRL: 'Asia/Urumqi',
  KRM: 'America/Guyana',
  KRN: 'Europe/Stockholm',
  KRO: 'Asia/Yekaterinburg',
  KRP: 'Europe/Copenhagen',
  KRQ: 'Europe/Zaporozhye',
  KRR: 'Europe/Moscow',
  KRS: 'Europe/Oslo',
  KRT: 'Africa/Khartoum',
  KRU: 'Pacific/Port_Moresby',
  KRV: 'Africa/Nairobi',
  KRW: 'Asia/Ashgabat',
  KRX: 'Pacific/Port_Moresby',
  KRY: 'Asia/Urumqi',
  KRZ: 'Africa/Kinshasa',
  KSA: 'Pacific/Kosrae',
  KSB: 'Pacific/Port_Moresby',
  KSC: 'Europe/Bratislava',
  KSD: 'Europe/Stockholm',
  KSE: 'Africa/Kampala',
  KSF: 'Europe/Berlin',
  KSG: 'Pacific/Port_Moresby',
  KSH: 'Asia/Tehran',
  KSI: 'Africa/Conakry',
  KSJ: 'Europe/Athens',
  KSK: 'Europe/Stockholm',
  KSL: 'Africa/Khartoum',
  KSM: 'America/Nome',
  KSN: 'Asia/Qostanay',
  KSO: 'Europe/Athens',
  KSP: 'Pacific/Port_Moresby',
  KSQ: 'Asia/Samarkand',
  KSR: 'Asia/Makassar',
  KSS: 'Africa/Bamako',
  KST: 'Africa/Khartoum',
  KSU: 'Europe/Oslo',
  KSV: 'Australia/Brisbane',
  KSW: 'Asia/Jerusalem',
  KSX: 'Pacific/Port_Moresby',
  KSY: 'Europe/Istanbul',
  KSZ: 'Europe/Moscow',
  KTA: 'Australia/Perth',
  KTB: 'America/Metlakatla',
  KTC: 'Africa/Abidjan',
  KTD: 'Asia/Tokyo',
  KTE: 'Asia/Kuala_Lumpur',
  KTF: 'Pacific/Auckland',
  KTG: 'Asia/Pontianak',
  KTH: 'America/Anchorage',
  KTI: 'Asia/Phnom_Penh',
  KTJ: 'Africa/Nairobi',
  KTK: 'Pacific/Bougainville',
  KTL: 'Africa/Nairobi',
  KTM: 'Asia/Kathmandu',
  KTN: 'America/Sitka',
  KTO: 'America/Guyana',
  KTP: 'America/Jamaica',
  KTQ: 'Europe/Helsinki',
  KTR: 'Australia/Darwin',
  KTS: 'America/Nome',
  KTT: 'Europe/Helsinki',
  KTU: 'Asia/Kolkata',
  KTV: 'America/Caracas',
  KTW: 'Europe/Warsaw',
  KTX: 'Africa/Bamako',
  KTY: 'Asia/Colombo',
  KUA: 'Asia/Kuala_Lumpur',
  KUC: 'Pacific/Tarawa',
  KUD: 'Asia/Kuching',
  KUE: 'Pacific/Guadalcanal',
  KUF: 'Europe/Samara',
  KUG: 'Australia/Brisbane',
  KUH: 'Asia/Tokyo',
  KUK: 'America/Nome',
  KUL: 'Asia/Kuala_Lumpur',
  KUM: 'Asia/Tokyo',
  KUN: 'Europe/Vilnius',
  KUO: 'Europe/Helsinki',
  KUP: 'Pacific/Port_Moresby',
  KUQ: 'Pacific/Port_Moresby',
  KUR: 'Asia/Kabul',
  KUS: 'America/Godthab',
  KUT: 'Asia/Tbilisi',
  KUU: 'Asia/Kolkata',
  KUV: 'Asia/Seoul',
  KUX: 'Pacific/Port_Moresby',
  KUY: 'Pacific/Port_Moresby',
  KVA: 'Europe/Athens',
  KVB: 'Europe/Stockholm',
  KVC: 'America/Nome',
  KVD: 'Asia/Baku',
  KVE: 'Pacific/Port_Moresby',
  KVG: 'Pacific/Port_Moresby',
  KVK: 'Europe/Moscow',
  KVL: 'America/Nome',
  KVM: 'Asia/Anadyr',
  KVR: 'Asia/Vladivostok',
  KVU: 'Pacific/Fiji',
  KVX: 'Europe/Kirov',
  KWA: 'Pacific/Kwajalein',
  KWB: 'Asia/Jakarta',
  KWD: 'Africa/Bangui',
  KWE: 'Asia/Shanghai',
  KWF: 'America/Juneau',
  KWG: 'Europe/Zaporozhye',
  KWH: 'Asia/Kabul',
  KWI: 'Asia/Kuwait',
  KWJ: 'Asia/Seoul',
  KWK: 'America/Nome',
  KWL: 'Asia/Shanghai',
  KWM: 'Australia/Brisbane',
  KWN: 'America/Anchorage',
  KWO: 'Pacific/Port_Moresby',
  KWP: 'America/Anchorage',
  KWR: 'Pacific/Guadalcanal',
  KWS: 'Pacific/Guadalcanal',
  KWT: 'America/Anchorage',
  KWV: 'Pacific/Port_Moresby',
  KWX: 'Pacific/Port_Moresby',
  KWY: 'Africa/Nairobi',
  KWZ: 'Africa/Lubumbashi',
  KXA: 'America/Juneau',
  KXD: 'Asia/Yekaterinburg',
  KXE: 'Africa/Johannesburg',
  KXF: 'Pacific/Fiji',
  KXK: 'Asia/Vladivostok',
  KXR: 'Pacific/Bougainville',
  KXU: 'Pacific/Tahiti',
  KYA: 'Europe/Istanbul',
  KYD: 'Asia/Taipei',
  KYE: 'Asia/Beirut',
  KYF: 'Australia/Perth',
  KYI: 'Australia/Adelaide',
  KYK: 'America/Anchorage',
  KYO: 'America/New_York',
  KYP: 'Asia/Yangon',
  KYS: 'Africa/Bamako',
  KYT: 'Asia/Yangon',
  KYU: 'America/Anchorage',
  KYX: 'Pacific/Port_Moresby',
  KYZ: 'Asia/Krasnoyarsk',
  KZB: 'America/Anchorage',
  KZC: 'Asia/Phnom_Penh',
  KZD: 'Asia/Phnom_Penh',
  KZF: 'Pacific/Port_Moresby',
  KZG: 'Europe/Berlin',
  KZI: 'Europe/Athens',
  KZN: 'Europe/Moscow',
  KZO: 'Asia/Qyzylorda',
  KZR: 'Europe/Istanbul',
  KZS: 'Europe/Athens',
  LAA: 'America/Denver',
  LAB: 'Pacific/Port_Moresby',
  LAC: 'Asia/Kuching',
  LAD: 'Africa/Luanda',
  LAE: 'Pacific/Port_Moresby',
  LAF: 'America/Indiana/Indianapolis',
  LAH: 'Asia/Jayapura',
  LAI: 'Europe/Paris',
  LAJ: 'America/Sao_Paulo',
  LAK: 'America/Yellowknife',
  LAL: 'America/New_York',
  LAM: 'America/Denver',
  LAN: 'America/Detroit',
  LAO: 'Asia/Manila',
  LAP: 'America/Mazatlan',
  LAQ: 'Africa/Tripoli',
  LAR: 'America/Denver',
  LAS: 'America/Los_Angeles',
  LAU: 'Africa/Nairobi',
  LAW: 'America/Chicago',
  LAX: 'America/Los_Angeles',
  LAY: 'Africa/Johannesburg',
  LAZ: 'America/Bahia',
  LBA: 'Europe/London',
  LBB: 'America/Chicago',
  LBC: 'Europe/Berlin',
  LBD: 'Asia/Dushanbe',
  LBE: 'America/New_York',
  LBF: 'America/Chicago',
  LBG: 'Europe/Paris',
  LBH: 'Australia/Sydney',
  LBI: 'Europe/Paris',
  LBJ: 'Asia/Makassar',
  LBK: 'Africa/Nairobi',
  LBL: 'America/Chicago',
  LBM: 'Africa/Maputo',
  LBN: 'Africa/Nairobi',
  LBO: 'Africa/Lubumbashi',
  LBP: 'Asia/Kuching',
  LBQ: 'Africa/Libreville',
  LBR: 'America/Manaus',
  LBS: 'Pacific/Fiji',
  LBT: 'America/New_York',
  LBU: 'Asia/Kuching',
  LBV: 'Africa/Libreville',
  LBW: 'Asia/Makassar',
  LBX: 'Asia/Manila',
  LBY: 'Europe/Paris',
  LBZ: 'Africa/Luanda',
  LCA: 'Asia/Nicosia',
  LCB: 'America/Cuiaba',
  LCC: 'Europe/Rome',
  LCD: 'Africa/Johannesburg',
  LCE: 'America/Tegucigalpa',
  LCF: 'America/Guatemala',
  LCG: 'Europe/Madrid',
  LCH: 'America/Chicago',
  LCI: 'America/New_York',
  LCJ: 'Europe/Warsaw',
  LCK: 'America/New_York',
  LCL: 'America/Havana',
  LCM: 'America/Argentina/Cordoba',
  LCN: 'Australia/Adelaide',
  LCO: 'Africa/Brazzaville',
  LCP: 'America/Argentina/Salta',
  LCQ: 'America/New_York',
  LCR: 'America/Bogota',
  LCV: 'Europe/Rome',
  LCX: 'Asia/Shanghai',
  LCY: 'Europe/London',
  LDA: 'Asia/Kolkata',
  LDB: 'America/Sao_Paulo',
  LDC: 'Australia/Lindeman',
  LDE: 'Europe/Paris',
  LDG: 'Europe/Moscow',
  LDH: 'Australia/Lord_Howe',
  LDI: 'Africa/Dar_es_Salaam',
  LDJ: 'America/New_York',
  LDK: 'Europe/Stockholm',
  LDM: 'America/Detroit',
  LDN: 'Asia/Kathmandu',
  LDO: 'America/Paramaribo',
  LDR: 'Asia/Aden',
  LDS: 'Asia/Shanghai',
  LDU: 'Asia/Kuching',
  LDV: 'Europe/Paris',
  LDW: 'Australia/Perth',
  LDX: 'America/Cayenne',
  LDY: 'Europe/London',
  LDZ: 'Africa/Johannesburg',
  LEA: 'Australia/Perth',
  LEB: 'America/New_York',
  LEC: 'America/Bahia',
  LED: 'Europe/Moscow',
  LEE: 'America/New_York',
  LEF: 'Africa/Maseru',
  LEG: 'Africa/Nouakchott',
  LEH: 'Europe/Paris',
  LEI: 'Europe/Madrid',
  LEJ: 'Europe/Berlin',
  LEK: 'Africa/Conakry',
  LEL: 'Australia/Darwin',
  LEM: 'America/Denver',
  LEN: 'Europe/Madrid',
  LEO: 'Africa/Libreville',
  LEP: 'America/Sao_Paulo',
  LEQ: 'Europe/London',
  LER: 'Australia/Perth',
  LES: 'Africa/Maseru',
  LET: 'America/Bogota',
  LEU: 'Europe/Madrid',
  LEV: 'Pacific/Fiji',
  LEW: 'America/New_York',
  LEX: 'America/New_York',
  LEY: 'Europe/Amsterdam',
  LEZ: 'America/Tegucigalpa',
  LFB: 'Africa/Maputo',
  LFI: 'America/New_York',
  LFK: 'America/Chicago',
  LFM: 'Asia/Tehran',
  LFN: 'America/New_York',
  LFO: 'Africa/Addis_Ababa',
  LFP: 'Australia/Brisbane',
  LFQ: 'Asia/Shanghai',
  LFR: 'America/Caracas',
  LFT: 'America/Chicago',
  LFW: 'Africa/Lome',
  LGA: 'America/New_York',
  LGB: 'America/Los_Angeles',
  LGC: 'America/New_York',
  LGD: 'America/Los_Angeles',
  LGE: 'Australia/Perth',
  LGF: 'America/Phoenix',
  LGG: 'Europe/Brussels',
  LGH: 'Australia/Adelaide',
  LGI: 'America/Nassau',
  LGK: 'Asia/Kuala_Lumpur',
  LGL: 'Asia/Kuching',
  LGM: 'Pacific/Port_Moresby',
  LGN: 'Pacific/Port_Moresby',
  LGO: 'Europe/Berlin',
  LGP: 'Asia/Manila',
  LGQ: 'America/Guayaquil',
  LGR: 'America/Santiago',
  LGS: 'America/Argentina/Mendoza',
  LGT: 'America/Bogota',
  LGU: 'America/Denver',
  LGW: 'Europe/London',
  LGX: 'Africa/Mogadishu',
  LGY: 'America/Caracas',
  LHA: 'Europe/Berlin',
  LHE: 'Asia/Karachi',
  LHG: 'Australia/Sydney',
  LHI: 'Asia/Jayapura',
  LHK: 'Asia/Shanghai',
  LHP: 'Pacific/Bougainville',
  LHR: 'Europe/London',
  LHS: 'America/Argentina/Rio_Gallegos',
  LHU: 'Africa/Windhoek',
  LHV: 'America/New_York',
  LHW: 'Asia/Shanghai',
  LIA: 'Asia/Shanghai',
  LIB: 'Australia/Darwin',
  LIC: 'America/Denver',
  LIE: 'Africa/Kinshasa',
  LIF: 'Pacific/Noumea',
  LIG: 'Europe/Paris',
  LIH: 'Pacific/Honolulu',
  LII: 'Asia/Jayapura',
  LIK: 'Pacific/Kwajalein',
  LIL: 'Europe/Paris',
  LIM: 'America/Lima',
  LIN: 'Europe/Rome',
  LIO: 'America/Costa_Rica',
  LIP: 'America/Sao_Paulo',
  LIQ: 'Africa/Kinshasa',
  LIR: 'America/Costa_Rica',
  LIS: 'Europe/Lisbon',
  LIT: 'America/Chicago',
  LIV: 'America/Anchorage',
  LIW: 'Asia/Yangon',
  LIX: 'Africa/Blantyre',
  LIY: 'America/New_York',
  LIZ: 'America/New_York',
  LJA: 'Africa/Lubumbashi',
  LJG: 'Asia/Shanghai',
  LJN: 'America/Chicago',
  LJU: 'Europe/Ljubljana',
  LKA: 'Asia/Makassar',
  LKB: 'Pacific/Fiji',
  LKC: 'Africa/Brazzaville',
  LKD: 'Australia/Brisbane',
  LKE: 'America/Los_Angeles',
  LKG: 'Africa/Nairobi',
  LKH: 'Asia/Kuching',
  LKK: 'America/Anchorage',
  LKL: 'Europe/Oslo',
  LKN: 'Europe/Oslo',
  LKO: 'Asia/Kolkata',
  LKP: 'America/New_York',
  LKR: 'Africa/Mogadishu',
  LKU: 'Africa/Nairobi',
  LKV: 'America/Los_Angeles',
  LKW: 'Asia/Muscat',
  LKY: 'Africa/Dar_es_Salaam',
  LKZ: 'Europe/London',
  LLA: 'Europe/Stockholm',
  LLB: 'Asia/Shanghai',
  LLC: 'Asia/Manila',
  LLE: 'Africa/Johannesburg',
  LLF: 'Asia/Shanghai',
  LLG: 'Australia/Brisbane',
  LLH: 'America/Tegucigalpa',
  LLI: 'Africa/Addis_Ababa',
  LLJ: 'Asia/Dhaka',
  LLK: 'Asia/Baku',
  LLL: 'Australia/Perth',
  LLM: 'Pacific/Guadalcanal',
  LLN: 'Asia/Jayapura',
  LLO: 'Asia/Makassar',
  LLP: 'Australia/Brisbane',
  LLS: 'America/Argentina/Cordoba',
  LLT: 'Africa/Luanda',
  LLV: 'Asia/Shanghai',
  LLW: 'Africa/Blantyre',
  LLX: 'America/New_York',
  LLY: 'America/New_York',
  LMA: 'America/Anchorage',
  LMB: 'Africa/Blantyre',
  LMC: 'America/Bogota',
  LMD: 'America/Argentina/Salta',
  LME: 'Europe/Paris',
  LMG: 'Pacific/Port_Moresby',
  LMH: 'America/Tegucigalpa',
  LMI: 'Pacific/Port_Moresby',
  LML: 'Pacific/Kwajalein',
  LMM: 'America/Mazatlan',
  LMN: 'Asia/Kuching',
  LMO: 'Europe/London',
  LMP: 'Europe/Rome',
  LMQ: 'Africa/Tripoli',
  LMR: 'Africa/Johannesburg',
  LMS: 'America/Chicago',
  LMT: 'America/Los_Angeles',
  LMU: 'Asia/Pontianak',
  LMX: 'America/Bogota',
  LMY: 'Pacific/Port_Moresby',
  LMZ: 'Africa/Maputo',
  LNA: 'America/New_York',
  LNB: 'Pacific/Efate',
  LNC: 'Pacific/Port_Moresby',
  LND: 'America/Denver',
  LNE: 'Pacific/Efate',
  LNF: 'Pacific/Port_Moresby',
  LNG: 'Pacific/Port_Moresby',
  LNH: 'Australia/Darwin',
  LNI: 'America/Anchorage',
  LNJ: 'Asia/Shanghai',
  LNK: 'America/Chicago',
  LNL: 'Asia/Shanghai',
  LNM: 'Pacific/Port_Moresby',
  LNN: 'America/New_York',
  LNO: 'Australia/Perth',
  LNP: 'America/New_York',
  LNQ: 'Pacific/Port_Moresby',
  LNR: 'America/Chicago',
  LNS: 'America/New_York',
  LNU: 'Asia/Makassar',
  LNV: 'Pacific/Port_Moresby',
  LNX: 'Europe/Moscow',
  LNY: 'Pacific/Honolulu',
  LNZ: 'Europe/Vienna',
  LOA: 'Australia/Brisbane',
  LOB: 'America/Santiago',
  LOC: 'Australia/Adelaide',
  LOD: 'Pacific/Efate',
  LOE: 'Asia/Bangkok',
  LOF: 'Pacific/Kwajalein',
  LOH: 'America/Guayaquil',
  LOI: 'America/Sao_Paulo',
  LOK: 'Africa/Nairobi',
  LOL: 'America/Los_Angeles',
  LOM: 'America/Mexico_City',
  LON: 'Europe/London',
  LOO: 'Africa/Algiers',
  LOP: 'Asia/Makassar',
  LOQ: 'Africa/Gaborone',
  LOS: 'Africa/Lagos',
  LOT: 'America/Chicago',
  LOU: 'America/Kentucky/Louisville',
  LOV: 'America/Monterrey',
  LOW: 'America/New_York',
  LOX: 'America/Guatemala',
  LOY: 'Africa/Nairobi',
  LOZ: 'America/New_York',
  LPA: 'Atlantic/Canary',
  LPB: 'America/La_Paz',
  LPC: 'America/Los_Angeles',
  LPD: 'America/Bogota',
  LPE: 'America/Bogota',
  LPF: 'Asia/Shanghai',
  LPG: 'America/Argentina/Buenos_Aires',
  LPI: 'Europe/Stockholm',
  LPJ: 'America/Caracas',
  LPK: 'Europe/Moscow',
  LPL: 'Europe/London',
  LPM: 'Pacific/Efate',
  LPN: 'Pacific/Port_Moresby',
  LPO: 'America/Chicago',
  LPP: 'Europe/Helsinki',
  LPQ: 'Asia/Vientiane',
  LPS: 'America/Los_Angeles',
  LPT: 'Asia/Bangkok',
  LPU: 'Asia/Makassar',
  LPX: 'Europe/Riga',
  LPY: 'Europe/Paris',
  LQK: 'America/New_York',
  LQM: 'America/Bogota',
  LQN: 'Asia/Kabul',
  LRA: 'Europe/Athens',
  LRB: 'Africa/Maseru',
  LRD: 'America/Chicago',
  LRE: 'Australia/Brisbane',
  LRF: 'America/Chicago',
  LRG: 'Asia/Karachi',
  LRH: 'Europe/Paris',
  LRI: 'America/Bogota',
  LRJ: 'America/Chicago',
  LRL: 'Africa/Lome',
  LRM: 'America/Santo_Domingo',
  LRQ: 'America/Winnipeg',
  LRR: 'Asia/Tehran',
  LRS: 'Europe/Athens',
  LRT: 'Europe/Paris',
  LRU: 'America/Denver',
  LRV: 'America/Caracas',
  LSA: 'Pacific/Port_Moresby',
  LSB: 'America/Denver',
  LSC: 'America/Santiago',
  LSE: 'America/Chicago',
  LSF: 'America/New_York',
  LSH: 'Asia/Yangon',
  LSI: 'Europe/London',
  LSJ: 'Pacific/Port_Moresby',
  LSK: 'America/Denver',
  LSL: 'America/Costa_Rica',
  LSM: 'Asia/Kuching',
  LSN: 'America/Los_Angeles',
  LSO: 'Europe/Paris',
  LSP: 'America/Caracas',
  LSQ: 'America/Santiago',
  LSS: 'America/Guadeloupe',
  LST: 'Australia/Hobart',
  LSU: 'Asia/Kuching',
  LSV: 'America/Los_Angeles',
  LSW: 'Asia/Jakarta',
  LSX: 'Asia/Jakarta',
  LSY: 'Australia/Sydney',
  LSZ: 'Europe/Zagreb',
  LTA: 'Africa/Johannesburg',
  LTB: 'Australia/Hobart',
  LTC: 'Africa/Ndjamena',
  LTD: 'Africa/Tripoli',
  LTF: 'Pacific/Port_Moresby',
  LTG: 'Asia/Kathmandu',
  LTI: 'Asia/Hovd',
  LTK: 'Asia/Damascus',
  LTL: 'Africa/Libreville',
  LTM: 'America/Guyana',
  LTN: 'Europe/London',
  LTO: 'America/Hermosillo',
  LTP: 'Australia/Brisbane',
  LTQ: 'Europe/Paris',
  LTR: 'Europe/Dublin',
  LTS: 'America/Chicago',
  LTT: 'Europe/Paris',
  LTU: 'Asia/Kolkata',
  LTV: 'Australia/Brisbane',
  LTW: 'America/New_York',
  LTX: 'America/Guayaquil',
  LUA: 'Asia/Kathmandu',
  LUB: 'America/Guyana',
  LUC: 'Pacific/Fiji',
  LUD: 'Africa/Windhoek',
  LUE: 'Europe/Bratislava',
  LUF: 'America/Phoenix',
  LUG: 'Europe/Zurich',
  LUH: 'Asia/Kolkata',
  LUI: 'America/Tegucigalpa',
  LUK: 'America/New_York',
  LUL: 'America/Chicago',
  LUM: 'Asia/Shanghai',
  LUN: 'Africa/Lusaka',
  LUO: 'Africa/Luanda',
  LUP: 'Pacific/Honolulu',
  LUQ: 'America/Argentina/San_Luis',
  LUR: 'America/Nome',
  LUS: 'Africa/Kinshasa',
  LUT: 'Australia/Brisbane',
  LUU: 'Australia/Brisbane',
  LUV: 'Asia/Jayapura',
  LUW: 'Asia/Makassar',
  LUX: 'Europe/Luxembourg',
  LUZ: 'Europe/Warsaw',
  LVA: 'Europe/Paris',
  LVB: 'America/Sao_Paulo',
  LVD: 'America/Anchorage',
  LVI: 'Africa/Lusaka',
  LVK: 'America/Los_Angeles',
  LVL: 'America/New_York',
  LVM: 'America/Denver',
  LVO: 'Australia/Perth',
  LVP: 'Asia/Tehran',
  LVR: 'America/Cuiaba',
  LVS: 'America/Denver',
  LWA: 'Asia/Manila',
  LWB: 'America/New_York',
  LWC: 'America/Chicago',
  LWE: 'Asia/Makassar',
  LWH: 'Australia/Brisbane',
  LWI: 'Pacific/Port_Moresby',
  LWK: 'Europe/London',
  LWL: 'America/Los_Angeles',
  LWM: 'America/New_York',
  LWN: 'Asia/Yerevan',
  LWO: 'Europe/Uzhgorod',
  LWR: 'Europe/Amsterdam',
  LWS: 'America/Los_Angeles',
  LWT: 'America/Denver',
  LWV: 'America/Chicago',
  LWY: 'Asia/Kuching',
  LXA: 'Asia/Urumqi',
  LXG: 'Asia/Vientiane',
  LXN: 'America/Chicago',
  LXR: 'Africa/Cairo',
  LXS: 'Europe/Athens',
  LXU: 'Africa/Lusaka',
  LXV: 'America/Denver',
  LYA: 'Asia/Shanghai',
  LYB: 'America/Cayman',
  LYC: 'Europe/Stockholm',
  LYE: 'Europe/London',
  LYG: 'Asia/Shanghai',
  LYH: 'America/New_York',
  LYI: 'Asia/Shanghai',
  LYK: 'Asia/Makassar',
  LYN: 'Europe/Paris',
  LYO: 'America/Chicago',
  LYP: 'Asia/Karachi',
  LYR: 'Arctic/Longyearbyen',
  LYS: 'Europe/Paris',
  LYT: 'Australia/Brisbane',
  LYU: 'America/Chicago',
  LYX: 'Europe/London',
  LZA: 'Africa/Lubumbashi',
  LZC: 'America/Mexico_City',
  LZH: 'Asia/Shanghai',
  LZI: 'Africa/Kinshasa',
  LZM: 'Africa/Luanda',
  LZN: 'Asia/Taipei',
  LZO: 'Asia/Shanghai',
  LZR: 'Australia/Lindeman',
  LZU: 'America/New_York',
  LZY: 'Asia/Urumqi',
  MAA: 'Asia/Kolkata',
  MAB: 'America/Araguaina',
  MAC: 'America/New_York',
  MAD: 'Europe/Madrid',
  MAE: 'America/Los_Angeles',
  MAF: 'America/Chicago',
  MAG: 'Pacific/Port_Moresby',
  MAH: 'Europe/Madrid',
  MAI: 'Africa/Blantyre',
  MAJ: 'Pacific/Majuro',
  MAK: 'Africa/Juba',
  MAL: 'Asia/Jayapura',
  MAM: 'America/Matamoros',
  MAN: 'Europe/London',
  MAO: 'America/Manaus',
  MAP: 'Pacific/Port_Moresby',
  MAQ: 'Asia/Bangkok',
  MAR: 'America/Caracas',
  MAS: 'Pacific/Port_Moresby',
  MAT: 'Africa/Kinshasa',
  MAU: 'Pacific/Tahiti',
  MAV: 'Pacific/Majuro',
  MAW: 'America/Chicago',
  MAX: 'Africa/Dakar',
  MAY: 'America/Nassau',
  MAZ: 'America/Puerto_Rico',
  MBA: 'Africa/Nairobi',
  MBB: 'Australia/Perth',
  MBC: 'Africa/Libreville',
  MBD: 'Africa/Johannesburg',
  MBE: 'Asia/Tokyo',
  MBF: 'Australia/Melbourne',
  MBG: 'America/Chicago',
  MBH: 'Australia/Brisbane',
  MBI: 'Africa/Dar_es_Salaam',
  MBJ: 'America/Jamaica',
  MBK: 'America/Cuiaba',
  MBL: 'America/Detroit',
  MBM: 'Africa/Johannesburg',
  MBN: 'Australia/Perth',
  MBO: 'Asia/Manila',
  MBP: 'America/Lima',
  MBQ: 'Africa/Kampala',
  MBR: 'Africa/Nouakchott',
  MBS: 'America/Detroit',
  MBT: 'Asia/Manila',
  MBU: 'Pacific/Guadalcanal',
  MBV: 'Pacific/Port_Moresby',
  MBW: 'Australia/Melbourne',
  MBX: 'Europe/Ljubljana',
  MBY: 'America/Chicago',
  MBZ: 'America/Manaus',
  MCA: 'Africa/Conakry',
  MCB: 'America/Chicago',
  MCC: 'America/Los_Angeles',
  MCD: 'America/Detroit',
  MCE: 'America/Los_Angeles',
  MCF: 'America/New_York',
  MCG: 'America/Anchorage',
  MCH: 'America/Guayaquil',
  MCI: 'America/Chicago',
  MCJ: 'America/Bogota',
  MCK: 'America/Chicago',
  MCL: 'America/Anchorage',
  MCN: 'America/New_York',
  MCO: 'America/New_York',
  MCP: 'America/Belem',
  MCQ: 'Europe/Budapest',
  MCS: 'America/Argentina/Cordoba',
  MCT: 'Asia/Muscat',
  MCU: 'Europe/Paris',
  MCV: 'Australia/Darwin',
  MCW: 'America/Chicago',
  MCX: 'Europe/Moscow',
  MCY: 'Australia/Brisbane',
  MCZ: 'America/Maceio',
  MDB: 'America/Belize',
  MDC: 'Asia/Makassar',
  MDD: 'America/Chicago',
  MDE: 'America/Bogota',
  MDF: 'America/Chicago',
  MDG: 'Asia/Shanghai',
  MDH: 'America/Chicago',
  MDI: 'Africa/Lagos',
  MDJ: 'America/Los_Angeles',
  MDK: 'Africa/Kinshasa',
  MDL: 'Asia/Yangon',
  MDM: 'Pacific/Port_Moresby',
  MDN: 'America/Indiana/Indianapolis',
  MDO: 'America/Anchorage',
  MDP: 'Asia/Jayapura',
  MDQ: 'America/Argentina/Buenos_Aires',
  MDR: 'America/Anchorage',
  MDS: 'America/Grand_Turk',
  MDT: 'America/New_York',
  MDU: 'Pacific/Port_Moresby',
  MDV: 'Africa/Libreville',
  MDW: 'America/Chicago',
  MDX: 'America/Argentina/Cordoba',
  MDY: 'Pacific/Midway',
  MDZ: 'America/Argentina/Mendoza',
  MEA: 'America/Sao_Paulo',
  MEB: 'Australia/Melbourne',
  MEC: 'America/Guayaquil',
  MED: 'Asia/Riyadh',
  MEE: 'Pacific/Noumea',
  MEF: 'Africa/Ndjamena',
  MEG: 'Africa/Luanda',
  MEH: 'Europe/Oslo',
  MEI: 'America/Chicago',
  MEJ: 'America/New_York',
  MEK: 'Africa/Casablanca',
  MEL: 'Australia/Melbourne',
  MEM: 'America/Chicago',
  MEN: 'Europe/Paris',
  MEO: 'America/New_York',
  MEP: 'Asia/Kuala_Lumpur',
  MEQ: 'Asia/Jakarta',
  MER: 'America/Los_Angeles',
  MES: 'Asia/Jakarta',
  MET: 'Australia/Brisbane',
  MEU: 'America/Santarem',
  MEV: 'America/Los_Angeles',
  MEW: 'Africa/Lubumbashi',
  MEX: 'America/Mexico_City',
  MEY: 'Asia/Kathmandu',
  MEZ: 'Africa/Johannesburg',
  MFA: 'Africa/Dar_es_Salaam',
  MFB: 'America/Bogota',
  MFC: 'Africa/Maseru',
  MFD: 'America/New_York',
  MFE: 'America/Chicago',
  MFF: 'Africa/Libreville',
  MFG: 'Asia/Karachi',
  MFH: 'America/Los_Angeles',
  MFI: 'America/Chicago',
  MFJ: 'Pacific/Fiji',
  MFK: 'Asia/Taipei',
  MFL: 'Australia/Brisbane',
  MFM: 'Asia/Macau',
  MFN: 'Pacific/Auckland',
  MFO: 'Pacific/Port_Moresby',
  MFP: 'Australia/Darwin',
  MFQ: 'Africa/Niamey',
  MFR: 'America/Los_Angeles',
  MFS: 'America/Bogota',
  MFU: 'Africa/Lusaka',
  MFV: 'America/New_York',
  MFW: 'Africa/Maputo',
  MFX: 'Europe/Paris',
  MFY: 'Asia/Aden',
  MFZ: 'Pacific/Port_Moresby',
  MGA: 'America/Managua',
  MGB: 'Australia/Adelaide',
  MGC: 'America/Chicago',
  MGD: 'America/La_Paz',
  MGE: 'America/New_York',
  MGF: 'America/Sao_Paulo',
  MGG: 'Pacific/Port_Moresby',
  MGH: 'Africa/Johannesburg',
  MGI: 'America/Chicago',
  MGJ: 'America/New_York',
  MGK: 'Asia/Yangon',
  MGL: 'Europe/Berlin',
  MGM: 'America/Chicago',
  MGN: 'America/Bogota',
  MGO: 'Africa/Libreville',
  MGP: 'Pacific/Port_Moresby',
  MGQ: 'Africa/Mogadishu',
  MGR: 'America/New_York',
  MGS: 'Pacific/Rarotonga',
  MGT: 'Australia/Darwin',
  MGU: 'Asia/Yangon',
  MGV: 'Australia/Perth',
  MGW: 'America/New_York',
  MGX: 'Africa/Libreville',
  MGY: 'America/New_York',
  MGZ: 'Asia/Yangon',
  MHA: 'America/Guyana',
  MHC: 'America/Santiago',
  MHD: 'Asia/Tehran',
  MHE: 'America/Chicago',
  MHF: 'America/Bogota',
  MHG: 'Europe/Berlin',
  MHH: 'America/Nassau',
  MHI: 'Africa/Djibouti',
  MHK: 'America/Chicago',
  MHL: 'America/Chicago',
  MHM: 'America/Anchorage',
  MHN: 'America/Denver',
  MHO: 'Australia/Perth',
  MHP: 'Europe/Minsk',
  MHQ: 'Europe/Mariehamn',
  MHR: 'America/Los_Angeles',
  MHS: 'America/Los_Angeles',
  MHT: 'America/New_York',
  MHU: 'Australia/Melbourne',
  MHV: 'America/Los_Angeles',
  MHW: 'America/La_Paz',
  MHX: 'Pacific/Rarotonga',
  MHY: 'Pacific/Port_Moresby',
  MHZ: 'Europe/London',
  MIA: 'America/New_York',
  MIB: 'America/Chicago',
  MIC: 'America/Chicago',
  MID: 'America/Merida',
  MIE: 'America/Indiana/Indianapolis',
  MIF: 'America/Chicago',
  MIG: 'Asia/Shanghai',
  MIH: 'Australia/Perth',
  MII: 'America/Sao_Paulo',
  MIJ: 'Pacific/Majuro',
  MIK: 'Europe/Helsinki',
  MIL: 'Europe/Rome',
  MIM: 'Australia/Sydney',
  MIN: 'Australia/Adelaide',
  MIO: 'America/Chicago',
  MIP: 'Asia/Jerusalem',
  MIQ: 'America/Chicago',
  MIR: 'Africa/Tunis',
  MIS: 'Pacific/Port_Moresby',
  MIT: 'America/Los_Angeles',
  MIU: 'Africa/Lagos',
  MIV: 'America/New_York',
  MIW: 'America/Chicago',
  MIX: 'America/Bogota',
  MIY: 'Australia/Darwin',
  MIZ: 'Australia/Darwin',
  MJA: 'Indian/Antananarivo',
  MJB: 'Pacific/Majuro',
  MJC: 'Africa/Abidjan',
  MJD: 'Asia/Karachi',
  MJE: 'Pacific/Majuro',
  MJF: 'Europe/Oslo',
  MJI: 'Africa/Tripoli',
  MJJ: 'Pacific/Port_Moresby',
  MJK: 'Australia/Perth',
  MJL: 'Africa/Libreville',
  MJM: 'Africa/Lubumbashi',
  MJN: 'Indian/Antananarivo',
  MJO: 'Africa/Windhoek',
  MJP: 'Australia/Perth',
  MJQ: 'America/Chicago',
  MJR: 'America/Argentina/Buenos_Aires',
  MJS: 'Africa/Maputo',
  MJT: 'Europe/Athens',
  MJU: 'Asia/Makassar',
  MJV: 'Europe/Madrid',
  MJW: 'Africa/Harare',
  MJX: 'America/New_York',
  MJZ: 'Asia/Yakutsk',
  MKA: 'Europe/Prague',
  MKB: 'Africa/Libreville',
  MKC: 'America/Chicago',
  MKE: 'America/Chicago',
  MKG: 'America/Detroit',
  MKH: 'Africa/Maseru',
  MKI: 'Africa/Bangui',
  MKJ: 'Africa/Brazzaville',
  MKK: 'Pacific/Honolulu',
  MKL: 'America/Chicago',
  MKM: 'Asia/Kuching',
  MKN: 'Pacific/Port_Moresby',
  MKO: 'America/Chicago',
  MKP: 'Pacific/Tahiti',
  MKQ: 'Asia/Jayapura',
  MKR: 'Australia/Perth',
  MKS: 'Africa/Addis_Ababa',
  MKT: 'America/Chicago',
  MKU: 'Africa/Libreville',
  MKV: 'Australia/Darwin',
  MKW: 'Asia/Jayapura',
  MKY: 'Australia/Brisbane',
  MKZ: 'Asia/Kuala_Lumpur',
  MLA: 'Europe/Malta',
  MLB: 'America/New_York',
  MLC: 'America/Chicago',
  MLD: 'America/Boise',
  MLE: 'Indian/Maldives',
  MLF: 'America/Denver',
  MLG: 'Asia/Jakarta',
  MLH: 'Europe/Paris',
  MLI: 'America/Chicago',
  MLJ: 'America/New_York',
  MLK: 'America/Denver',
  MLL: 'America/Nome',
  MLM: 'America/Mexico_City',
  MLN: 'Africa/Ceuta',
  MLO: 'Europe/Athens',
  MLP: 'Asia/Manila',
  MLQ: 'Pacific/Port_Moresby',
  MLR: 'Australia/Adelaide',
  MLS: 'America/Denver',
  MLT: 'America/New_York',
  MLU: 'America/Chicago',
  MLV: 'Australia/Brisbane',
  MLW: 'Africa/Monrovia',
  MLX: 'Europe/Istanbul',
  MLY: 'America/Anchorage',
  MLZ: 'America/Montevideo',
  MMA: 'Europe/Stockholm',
  MMB: 'Asia/Tokyo',
  MMC: 'America/Monterrey',
  MMD: 'Asia/Tokyo',
  MME: 'Europe/London',
  MMF: 'Africa/Douala',
  MMG: 'Australia/Perth',
  MMH: 'America/Los_Angeles',
  MMI: 'America/New_York',
  MMJ: 'Asia/Tokyo',
  MMK: 'Europe/Moscow',
  MML: 'America/Chicago',
  MMM: 'Australia/Brisbane',
  MMN: 'America/New_York',
  MMO: 'Atlantic/Cape_Verde',
  MMP: 'America/Bogota',
  MMQ: 'Africa/Lusaka',
  MMS: 'America/Chicago',
  MMT: 'America/New_York',
  MMU: 'America/New_York',
  MMV: 'Pacific/Port_Moresby',
  MMW: 'Africa/Maputo',
  MMX: 'Europe/Stockholm',
  MMY: 'Asia/Tokyo',
  MMZ: 'Asia/Kabul',
  MNA: 'Asia/Makassar',
  MNB: 'Africa/Kinshasa',
  MNC: 'Africa/Maputo',
  MND: 'America/Bogota',
  MNE: 'Australia/Adelaide',
  MNF: 'Pacific/Fiji',
  MNG: 'Australia/Darwin',
  MNH: 'Asia/Muscat',
  MNI: 'America/Montserrat',
  MNJ: 'Indian/Antananarivo',
  MNK: 'Pacific/Tarawa',
  MNL: 'Asia/Manila',
  MNM: 'America/Menominee',
  MNN: 'America/New_York',
  MNO: 'Africa/Lubumbashi',
  MNP: 'Pacific/Port_Moresby',
  MNQ: 'Australia/Brisbane',
  MNR: 'Africa/Lusaka',
  MNS: 'Africa/Lusaka',
  MNT: 'America/Anchorage',
  MNU: 'Asia/Yangon',
  MNV: 'Australia/Darwin',
  MNW: 'Australia/Darwin',
  MNX: 'America/Manaus',
  MNY: 'Pacific/Guadalcanal',
  MNZ: 'America/New_York',
  MOA: 'America/Havana',
  MOB: 'America/Chicago',
  MOC: 'America/Bahia',
  MOD: 'America/Los_Angeles',
  MOE: 'Asia/Yangon',
  MOF: 'Asia/Makassar',
  MOG: 'Asia/Yangon',
  MOH: 'Asia/Makassar',
  MOI: 'Pacific/Rarotonga',
  MOJ: 'America/Paramaribo',
  MOL: 'Europe/Oslo',
  MOM: 'Africa/Nouakchott',
  MON: 'Pacific/Auckland',
  MOO: 'Australia/Adelaide',
  MOP: 'America/Detroit',
  MOQ: 'Indian/Antananarivo',
  MOR: 'America/New_York',
  MOS: 'America/Nome',
  MOT: 'America/Chicago',
  MOU: 'America/Nome',
  MOV: 'Australia/Brisbane',
  MOW: 'Europe/Moscow',
  MOX: 'America/Chicago',
  MOY: 'America/Bogota',
  MOZ: 'Pacific/Tahiti',
  MPA: 'Africa/Windhoek',
  MPB: 'America/New_York',
  MPC: 'Asia/Jakarta',
  MPD: 'Asia/Karachi',
  MPF: 'Pacific/Port_Moresby',
  MPG: 'Pacific/Port_Moresby',
  MPH: 'Asia/Manila',
  MPI: 'America/Panama',
  MPJ: 'America/Chicago',
  MPL: 'Europe/Paris',
  MPM: 'Africa/Maputo',
  MPN: 'Atlantic/Stanley',
  MPO: 'America/New_York',
  MPP: 'America/Panama',
  MPQ: 'Asia/Amman',
  MPR: 'America/Chicago',
  MPS: 'America/Chicago',
  MPT: 'Asia/Dili',
  MPU: 'Pacific/Port_Moresby',
  MPV: 'America/New_York',
  MPW: 'Europe/Zaporozhye',
  MPX: 'Pacific/Port_Moresby',
  MPY: 'America/Cayenne',
  MPZ: 'America/Chicago',
  MQA: 'Australia/Perth',
  MQB: 'America/Chicago',
  MQC: 'America/Miquelon',
  MQD: 'America/Argentina/Salta',
  MQE: 'Australia/Darwin',
  MQF: 'Asia/Yekaterinburg',
  MQG: 'Africa/Windhoek',
  MQH: 'America/Sao_Paulo',
  MQJ: 'Asia/Magadan',
  MQK: 'America/La_Paz',
  MQL: 'Australia/Melbourne',
  MQM: 'Europe/Istanbul',
  MQN: 'Europe/Oslo',
  MQO: 'Pacific/Port_Moresby',
  MQP: 'Africa/Johannesburg',
  MQQ: 'Africa/Ndjamena',
  MQR: 'America/Bogota',
  MQS: 'America/St_Vincent',
  MQT: 'America/Detroit',
  MQU: 'America/Bogota',
  MQV: 'Africa/Algiers',
  MQW: 'America/New_York',
  MQX: 'Africa/Addis_Ababa',
  MQY: 'America/Chicago',
  MQZ: 'Australia/Perth',
  MRA: 'Africa/Tripoli',
  MRB: 'America/New_York',
  MRC: 'America/Chicago',
  MRD: 'America/Caracas',
  MRE: 'Africa/Nairobi',
  MRF: 'America/Chicago',
  MRG: 'Australia/Brisbane',
  MRH: 'Pacific/Port_Moresby',
  MRI: 'America/Anchorage',
  MRJ: 'America/Tegucigalpa',
  MRK: 'America/New_York',
  MRL: 'Australia/Brisbane',
  MRM: 'Pacific/Port_Moresby',
  MRN: 'America/New_York',
  MRO: 'Pacific/Auckland',
  MRP: 'Australia/Adelaide',
  MRQ: 'Asia/Manila',
  MRR: 'America/Guayaquil',
  MRS: 'Europe/Paris',
  MRT: 'Australia/Darwin',
  MRU: 'Indian/Mauritius',
  MRV: 'Europe/Moscow',
  MRW: 'Europe/Copenhagen',
  MRX: 'Asia/Tehran',
  MRY: 'America/Los_Angeles',
  MRZ: 'Australia/Sydney',
  MSA: 'America/Winnipeg',
  MSC: 'America/Phoenix',
  MSD: 'America/Denver',
  MSE: 'Europe/London',
  MSF: 'Australia/Darwin',
  MSG: 'Africa/Maseru',
  MSH: 'Asia/Muscat',
  MSI: 'Asia/Pontianak',
  MSJ: 'Asia/Tokyo',
  MSL: 'America/Chicago',
  MSM: 'Africa/Kinshasa',
  MSN: 'America/Chicago',
  MSO: 'America/Denver',
  MSP: 'America/Chicago',
  MSQ: 'Europe/Minsk',
  MSR: 'Europe/Istanbul',
  MSS: 'America/New_York',
  MST: 'Europe/Amsterdam',
  MSU: 'Africa/Maseru',
  MSV: 'America/New_York',
  MSW: 'Africa/Asmara',
  MSX: 'Africa/Brazzaville',
  MSY: 'America/Chicago',
  MSZ: 'Africa/Luanda',
  MTA: 'Pacific/Auckland',
  MTB: 'America/Bogota',
  MTC: 'America/Detroit',
  MTD: 'Australia/Darwin',
  MTE: 'America/Santarem',
  MTF: 'Africa/Addis_Ababa',
  MTG: 'America/Cuiaba',
  MTH: 'America/New_York',
  MTI: 'Atlantic/Cape_Verde',
  MTJ: 'America/Denver',
  MTK: 'Pacific/Tarawa',
  MTL: 'Australia/Sydney',
  MTM: 'America/Metlakatla',
  MTN: 'America/New_York',
  MTO: 'America/Chicago',
  MTP: 'America/New_York',
  MTQ: 'Australia/Brisbane',
  MTR: 'America/Bogota',
  MTS: 'Africa/Mbabane',
  MTT: 'America/Mexico_City',
  MTU: 'Africa/Maputo',
  MTV: 'Pacific/Efate',
  MTW: 'America/Chicago',
  MTX: 'America/Anchorage',
  MTY: 'America/Monterrey',
  MTZ: 'Asia/Jerusalem',
  MUA: 'Pacific/Guadalcanal',
  MUB: 'Africa/Gaborone',
  MUC: 'Europe/Berlin',
  MUD: 'Africa/Maputo',
  MUE: 'Pacific/Honolulu',
  MUF: 'Asia/Jayapura',
  MUG: 'America/Mazatlan',
  MUH: 'Africa/Cairo',
  MUI: 'America/New_York',
  MUJ: 'Africa/Addis_Ababa',
  MUK: 'Pacific/Rarotonga',
  MUL: 'America/New_York',
  MUN: 'America/Caracas',
  MUO: 'America/Boise',
  MUP: 'Australia/Darwin',
  MUQ: 'Australia/Perth',
  MUR: 'Asia/Kuching',
  MUS: 'Asia/Tokyo',
  MUT: 'America/Chicago',
  MUW: 'Africa/Algiers',
  MUX: 'Asia/Karachi',
  MUY: 'Africa/Brazzaville',
  MUZ: 'Africa/Dar_es_Salaam',
  MVA: 'Atlantic/Reykjavik',
  MVB: 'Africa/Libreville',
  MVC: 'America/Chicago',
  MVD: 'America/Montevideo',
  MVE: 'America/Chicago',
  MVF: 'America/Fortaleza',
  MVG: 'Africa/Libreville',
  MVH: 'Australia/Sydney',
  MVI: 'Pacific/Port_Moresby',
  MVK: 'Australia/Adelaide',
  MVL: 'America/New_York',
  MVM: 'America/Denver',
  MVN: 'America/Chicago',
  MVO: 'Africa/Ndjamena',
  MVP: 'America/Bogota',
  MVQ: 'Europe/Minsk',
  MVR: 'Africa/Douala',
  MVS: 'America/Bahia',
  MVT: 'Pacific/Tahiti',
  MVU: 'Australia/Brisbane',
  MVV: 'Europe/Paris',
  MVW: 'America/Los_Angeles',
  MVX: 'Africa/Libreville',
  MVY: 'America/New_York',
  MVZ: 'Africa/Harare',
  MWA: 'America/Chicago',
  MWB: 'Australia/Perth',
  MWC: 'America/Chicago',
  MWD: 'Asia/Karachi',
  MWE: 'Africa/Khartoum',
  MWF: 'Pacific/Efate',
  MWG: 'Pacific/Port_Moresby',
  MWH: 'America/Los_Angeles',
  MWI: 'Pacific/Port_Moresby',
  MWJ: 'America/Guyana',
  MWK: 'Asia/Jakarta',
  MWL: 'America/Chicago',
  MWM: 'America/Chicago',
  MWN: 'Africa/Dar_es_Salaam',
  MWO: 'America/New_York',
  MWQ: 'Asia/Yangon',
  MWR: 'Africa/Johannesburg',
  MWT: 'Australia/Adelaide',
  MWU: 'Pacific/Port_Moresby',
  MWV: 'Asia/Phnom_Penh',
  MWX: 'Asia/Seoul',
  MWY: 'Australia/Brisbane',
  MWZ: 'Africa/Dar_es_Salaam',
  MXA: 'America/Chicago',
  MXB: 'Asia/Makassar',
  MXC: 'America/Denver',
  MXD: 'Australia/Brisbane',
  MXE: 'America/New_York',
  MXF: 'America/Chicago',
  MXG: 'America/New_York',
  MXH: 'Pacific/Port_Moresby',
  MXI: 'Asia/Manila',
  MXJ: 'Africa/Lagos',
  MXK: 'Pacific/Port_Moresby',
  MXL: 'America/Tijuana',
  MXM: 'Indian/Antananarivo',
  MXN: 'Europe/Paris',
  MXO: 'America/Chicago',
  MXP: 'Europe/Rome',
  MXR: 'Europe/Kiev',
  MXS: 'Pacific/Apia',
  MXT: 'Indian/Antananarivo',
  MXU: 'Australia/Perth',
  MXV: 'Asia/Ulaanbaatar',
  MXW: 'Asia/Ulaanbaatar',
  MXX: 'Europe/Stockholm',
  MXY: 'America/Anchorage',
  MXZ: 'Asia/Shanghai',
  MYA: 'Australia/Sydney',
  MYB: 'Africa/Libreville',
  MYC: 'America/Caracas',
  MYD: 'Africa/Nairobi',
  MYE: 'Asia/Tokyo',
  MYF: 'America/Los_Angeles',
  MYG: 'America/Nassau',
  MYH: 'America/Phoenix',
  MYI: 'Australia/Lindeman',
  MYJ: 'Asia/Tokyo',
  MYK: 'America/Anchorage',
  MYL: 'America/Boise',
  MYM: 'America/Guyana',
  MYN: 'Asia/Aden',
  MYO: 'Australia/Perth',
  MYP: 'Asia/Ashgabat',
  MYQ: 'Asia/Kolkata',
  MYR: 'America/New_York',
  MYT: 'Asia/Yangon',
  MYU: 'America/Nome',
  MYV: 'America/Los_Angeles',
  MYW: 'Africa/Dar_es_Salaam',
  MYX: 'Pacific/Port_Moresby',
  MYY: 'Asia/Kuching',
  MYZ: 'Africa/Blantyre',
  MZA: 'America/Lima',
  MZB: 'Africa/Maputo',
  MZC: 'Africa/Libreville',
  MZD: 'America/Guayaquil',
  MZE: 'America/New_York',
  MZF: 'Africa/Johannesburg',
  MZG: 'Asia/Taipei',
  MZH: 'Europe/Istanbul',
  MZI: 'Africa/Bamako',
  MZJ: 'America/Phoenix',
  MZK: 'Pacific/Tarawa',
  MZL: 'America/Bogota',
  MZM: 'Europe/Paris',
  MZN: 'Pacific/Port_Moresby',
  MZO: 'America/Havana',
  MZP: 'Pacific/Auckland',
  MZQ: 'Africa/Johannesburg',
  MZR: 'Asia/Kabul',
  MZT: 'America/Mazatlan',
  MZU: 'Asia/Kolkata',
  MZV: 'Asia/Kuching',
  MZW: 'Africa/Algiers',
  MZX: 'Africa/Addis_Ababa',
  MZY: 'Africa/Johannesburg',
  MZZ: 'America/Indiana/Indianapolis',
  NAA: 'Australia/Sydney',
  NAC: 'Australia/Adelaide',
  NAD: 'America/Bogota',
  NAE: 'Africa/Porto-Novo',
  NAF: 'Asia/Makassar',
  NAG: 'Asia/Kolkata',
  NAH: 'Asia/Makassar',
  NAI: 'America/Guyana',
  NAJ: 'Asia/Baku',
  NAK: 'Asia/Bangkok',
  NAL: 'Europe/Moscow',
  NAM: 'Asia/Jayapura',
  NAN: 'Pacific/Fiji',
  NAO: 'Asia/Shanghai',
  NAP: 'Europe/Rome',
  NAQ: 'America/Thule',
  NAR: 'America/Bogota',
  NAS: 'America/Nassau',
  NAT: 'America/Fortaleza',
  NAU: 'Pacific/Tahiti',
  NAV: 'Europe/Istanbul',
  NAW: 'Asia/Bangkok',
  NAY: 'Asia/Shanghai',
  NAZ: 'Pacific/Guadalcanal',
  NBA: 'Pacific/Port_Moresby',
  NBB: 'America/Bogota',
  NBC: 'Europe/Moscow',
  NBE: 'Africa/Tunis',
  NBG: 'America/Chicago',
  NBH: 'Australia/Sydney',
  NBL: 'America/Panama',
  NBN: 'Africa/Malabo',
  NBO: 'Africa/Nairobi',
  NBS: 'Asia/Shanghai',
  NBW: 'America/Havana',
  NBX: 'Asia/Jayapura',
  NCA: 'America/Grand_Turk',
  NCE: 'Europe/Paris',
  NCG: 'America/Chihuahua',
  NCH: 'Africa/Dar_es_Salaam',
  NCI: 'America/Bogota',
  NCJ: 'America/Argentina/Cordoba',
  NCL: 'Europe/London',
  NCN: 'America/Anchorage',
  NCO: 'America/New_York',
  NCR: 'America/Managua',
  NCS: 'Africa/Johannesburg',
  NCT: 'America/Costa_Rica',
  NCU: 'Asia/Samarkand',
  NCY: 'Europe/Paris',
  NDA: 'Asia/Jayapura',
  NDB: 'Africa/Nouakchott',
  NDC: 'Asia/Kolkata',
  NDD: 'Africa/Luanda',
  NDE: 'Africa/Nairobi',
  NDF: 'Africa/Luanda',
  NDG: 'Asia/Shanghai',
  NDI: 'Pacific/Port_Moresby',
  NDJ: 'Africa/Ndjamena',
  NDK: 'Pacific/Majuro',
  NDL: 'Africa/Bangui',
  NDM: 'Africa/Addis_Ababa',
  NDN: 'Pacific/Port_Moresby',
  NDR: 'Africa/Casablanca',
  NDS: 'Australia/Perth',
  NDU: 'Africa/Windhoek',
  NDY: 'Europe/London',
  NEC: 'America/Argentina/Buenos_Aires',
  NEF: 'Asia/Yekaterinburg',
  NEG: 'America/Jamaica',
  NEI: 'Asia/Vladivostok',
  NEJ: 'Africa/Addis_Ababa',
  NEK: 'Africa/Addis_Ababa',
  NEL: 'America/New_York',
  NEN: 'America/New_York',
  NER: 'Asia/Yakutsk',
  NEU: 'Asia/Vientiane',
  NEV: 'America/St_Kitts',
  NEW: 'America/Chicago',
  NFG: 'Asia/Yekaterinburg',
  NFL: 'America/Los_Angeles',
  NFO: 'Pacific/Tongatapu',
  NFR: 'Africa/Tripoli',
  NGA: 'Australia/Sydney',
  NGB: 'Asia/Shanghai',
  NGD: 'America/Tortola',
  NGE: 'Africa/Douala',
  NGF: 'Pacific/Honolulu',
  NGI: 'Pacific/Fiji',
  NGK: 'Asia/Sakhalin',
  NGL: 'Africa/Johannesburg',
  NGN: 'America/Panama',
  NGO: 'Asia/Tokyo',
  NGP: 'America/Chicago',
  NGQ: 'Asia/Shanghai',
  NGR: 'Pacific/Port_Moresby',
  NGS: 'Asia/Tokyo',
  NGU: 'America/New_York',
  NGW: 'America/Chicago',
  NGX: 'Asia/Kathmandu',
  NHA: 'Asia/Ho_Chi_Minh',
  NHD: 'Asia/Dubai',
  NHF: 'Africa/Khartoum',
  NHK: 'America/New_York',
  NHS: 'Asia/Karachi',
  NHT: 'Europe/London',
  NHV: 'Pacific/Tahiti',
  NHX: 'America/Chicago',
  NHZ: 'America/New_York',
  NIA: 'Africa/Monrovia',
  NIB: 'America/Anchorage',
  NIC: 'Asia/Nicosia',
  NIF: 'Australia/Perth',
  NIG: 'Pacific/Tarawa',
  NIK: 'Africa/Dakar',
  NIM: 'Africa/Niamey',
  NIN: 'America/Anchorage',
  NIO: 'Africa/Kinshasa',
  NIP: 'America/New_York',
  NIR: 'America/Chicago',
  NIS: 'Pacific/Port_Moresby',
  NIT: 'Europe/Paris',
  NIU: 'Pacific/Tahiti',
  NIX: 'Africa/Bamako',
  NJA: 'Asia/Tokyo',
  NJC: 'Asia/Yekaterinburg',
  NJF: 'Asia/Baghdad',
  NJJ: 'Asia/Shanghai',
  NJK: 'America/Los_Angeles',
  NKA: 'Africa/Libreville',
  NKB: 'Australia/Perth',
  NKC: 'Africa/Nouakchott',
  NKD: 'Asia/Jayapura',
  NKG: 'Asia/Shanghai',
  NKI: 'America/Juneau',
  NKL: 'Africa/Kinshasa',
  NKM: 'Asia/Tokyo',
  NKN: 'Pacific/Port_Moresby',
  NKO: 'Indian/Antananarivo',
  NKP: 'Pacific/Tahiti',
  NKS: 'Africa/Douala',
  NKT: 'Europe/Istanbul',
  NKU: 'Africa/Maseru',
  NKX: 'America/Los_Angeles',
  NKY: 'Africa/Brazzaville',
  NLA: 'Africa/Lusaka',
  NLC: 'America/Los_Angeles',
  NLD: 'America/Matamoros',
  NLE: 'America/Detroit',
  NLF: 'Pacific/Port_Moresby',
  NLG: 'America/Anchorage',
  NLH: 'Asia/Shanghai',
  NLI: 'Asia/Vladivostok',
  NLK: 'Pacific/Norfolk',
  NLL: 'Australia/Perth',
  NLN: 'America/Los_Angeles',
  NLO: 'Africa/Kinshasa',
  NLP: 'Africa/Johannesburg',
  NLS: 'Australia/Perth',
  NLT: 'Asia/Urumqi',
  NLU: 'America/Mexico_City',
  NLV: 'Europe/Kiev',
  NMA: 'Asia/Tashkent',
  NMB: 'Asia/Kolkata',
  NMC: 'America/Nassau',
  NME: 'America/Nome',
  NMG: 'America/Panama',
  NML: 'America/Edmonton',
  NMN: 'Pacific/Port_Moresby',
  NMP: 'Australia/Brisbane',
  NMR: 'Australia/Brisbane',
  NMS: 'Asia/Yangon',
  NMT: 'Asia/Yangon',
  NNA: 'Africa/Casablanca',
  NNB: 'Pacific/Guadalcanal',
  NND: 'Africa/Maputo',
  NNG: 'Asia/Shanghai',
  NNI: 'Africa/Windhoek',
  NNK: 'America/Anchorage',
  NNL: 'America/Anchorage',
  NNM: 'Europe/Moscow',
  NNR: 'Europe/Dublin',
  NNT: 'Asia/Bangkok',
  NNU: 'America/Sao_Paulo',
  NNX: 'Asia/Makassar',
  NNY: 'Asia/Shanghai',
  NOA: 'Australia/Sydney',
  NOB: 'America/Costa_Rica',
  NOC: 'Europe/Dublin',
  NOD: 'Europe/Berlin',
  NOG: 'America/Hermosillo',
  NOI: 'Europe/Moscow',
  NOJ: 'Asia/Yekaterinburg',
  NOK: 'America/Cuiaba',
  NOM: 'Pacific/Port_Moresby',
  NON: 'Pacific/Tarawa',
  NOO: 'Pacific/Port_Moresby',
  NOP: 'Europe/Istanbul',
  NOR: 'Atlantic/Reykjavik',
  NOS: 'Indian/Antananarivo',
  NOT: 'America/Los_Angeles',
  NOU: 'Pacific/Noumea',
  NOV: 'Africa/Luanda',
  NOZ: 'Asia/Novokuznetsk',
  NPA: 'America/Chicago',
  NPE: 'Pacific/Auckland',
  NPG: 'Pacific/Port_Moresby',
  NPH: 'America/Denver',
  NPL: 'Pacific/Auckland',
  NPO: 'Asia/Pontianak',
  NPP: 'Australia/Darwin',
  NPR: 'America/Santarem',
  NPT: 'America/New_York',
  NPU: 'America/Bogota',
  NQA: 'America/Chicago',
  NQI: 'America/Chicago',
  NQL: 'America/Sao_Paulo',
  NQN: 'America/Argentina/Salta',
  NQT: 'Europe/London',
  NQU: 'America/Bogota',
  NQX: 'America/New_York',
  NQY: 'Europe/London',
  NRA: 'Australia/Sydney',
  NRB: 'America/New_York',
  NRC: 'America/Los_Angeles',
  NRD: 'Europe/Berlin',
  NRE: 'Asia/Jayapura',
  NRG: 'Australia/Perth',
  NRI: 'America/Chicago',
  NRK: 'Europe/Stockholm',
  NRL: 'Europe/London',
  NRM: 'Africa/Bamako',
  NRN: 'Europe/Berlin',
  NRR: 'America/Puerto_Rico',
  NRS: 'America/Los_Angeles',
  NRT: 'Asia/Tokyo',
  NRY: 'Australia/Darwin',
  NSB: 'America/Nassau',
  NSE: 'America/Chicago',
  NSH: 'Asia/Tehran',
  NSI: 'Africa/Douala',
  NSK: 'Asia/Krasnoyarsk',
  NSL: 'America/Chicago',
  NSM: 'Australia/Perth',
  NSN: 'Pacific/Auckland',
  NSO: 'Australia/Sydney',
  NST: 'Asia/Bangkok',
  NSV: 'Australia/Brisbane',
  NSY: 'Europe/Rome',
  NTA: 'Pacific/Fiji',
  NTB: 'Europe/Oslo',
  NTC: 'Africa/Maputo',
  NTD: 'America/Los_Angeles',
  NTE: 'Europe/Paris',
  NTG: 'Asia/Shanghai',
  NTI: 'Asia/Jayapura',
  NTJ: 'America/Denver',
  NTL: 'Australia/Sydney',
  NTM: 'America/Araguaina',
  NTN: 'Australia/Brisbane',
  NTO: 'Atlantic/Cape_Verde',
  NTQ: 'Asia/Tokyo',
  NTR: 'America/Monterrey',
  NTT: 'Pacific/Tongatapu',
  NTU: 'America/New_York',
  NTX: 'Asia/Jakarta',
  NTY: 'Africa/Johannesburg',
  NUA: 'Asia/Colombo',
  NUB: 'Australia/Darwin',
  NUD: 'Africa/Khartoum',
  NUE: 'Europe/Berlin',
  NUF: 'Asia/Colombo',
  NUG: 'Pacific/Bougainville',
  NUH: 'America/Bogota',
  NUI: 'America/Anchorage',
  NUJ: 'Asia/Tehran',
  NUK: 'Pacific/Tahiti',
  NUL: 'America/Anchorage',
  NUN: 'America/Chicago',
  NUP: 'America/Nome',
  NUQ: 'America/Los_Angeles',
  NUR: 'Australia/Adelaide',
  NUS: 'Pacific/Efate',
  NUT: 'Pacific/Port_Moresby',
  NUU: 'Africa/Nairobi',
  NUW: 'America/Los_Angeles',
  NUX: 'Asia/Yekaterinburg',
  NVA: 'America/Bogota',
  NVD: 'America/Chicago',
  NVG: 'America/Managua',
  NVI: 'Asia/Samarkand',
  NVK: 'Europe/Oslo',
  NVN: 'America/Los_Angeles',
  NVP: 'America/Manaus',
  NVR: 'Europe/Moscow',
  NVS: 'Europe/Paris',
  NVT: 'America/Sao_Paulo',
  NVY: 'Asia/Kolkata',
  NWA: 'Indian/Comoro',
  NWH: 'America/New_York',
  NWI: 'Europe/London',
  NWT: 'Pacific/Port_Moresby',
  NYA: 'Asia/Yekaterinburg',
  NYC: 'America/New_York',
  NYE: 'Africa/Nairobi',
  NYG: 'America/New_York',
  NYI: 'Africa/Accra',
  NYK: 'Africa/Nairobi',
  NYM: 'Asia/Yekaterinburg',
  NYN: 'Australia/Sydney',
  NYO: 'Europe/Stockholm',
  NYR: 'Asia/Yakutsk',
  NYS: 'America/New_York',
  NYT: 'Asia/Yangon',
  NYU: 'Asia/Yangon',
  NYW: 'Asia/Yangon',
  NZA: 'Africa/Luanda',
  NZC: 'America/Lima',
  NZE: 'Africa/Conakry',
  NZH: 'Asia/Shanghai',
  NZL: 'Asia/Shanghai',
  NZY: 'America/Los_Angeles',
  OAA: 'Asia/Kabul',
  OAG: 'Australia/Sydney',
  OAH: 'Asia/Kabul',
  OAI: 'Asia/Kabul',
  OAJ: 'America/New_York',
  OAK: 'America/Los_Angeles',
  OAL: 'America/Porto_Velho',
  OAM: 'Pacific/Auckland',
  OAN: 'America/Tegucigalpa',
  OAR: 'America/Los_Angeles',
  OAS: 'Asia/Kabul',
  OAX: 'America/Mexico_City',
  OAZ: 'Asia/Kabul',
  OBA: 'Australia/Sydney',
  OBC: 'Africa/Djibouti',
  OBD: 'Asia/Jayapura',
  OBE: 'America/New_York',
  OBF: 'Europe/Berlin',
  OBI: 'America/Santarem',
  OBL: 'Europe/Brussels',
  OBM: 'Pacific/Port_Moresby',
  OBN: 'Europe/London',
  OBO: 'Asia/Tokyo',
  OBS: 'Europe/Paris',
  OBU: 'America/Anchorage',
  OBX: 'Pacific/Port_Moresby',
  OCA: 'America/New_York',
  OCC: 'America/Guayaquil',
  OCE: 'America/New_York',
  OCF: 'America/New_York',
  OCH: 'America/Chicago',
  OCJ: 'America/Jamaica',
  OCM: 'Australia/Perth',
  OCN: 'America/Los_Angeles',
  OCS: 'Africa/Malabo',
  OCV: 'America/Bogota',
  OCW: 'America/New_York',
  ODA: 'Africa/Bangui',
  ODB: 'Europe/Madrid',
  ODC: 'America/Los_Angeles',
  ODD: 'Australia/Adelaide',
  ODE: 'Europe/Copenhagen',
  ODH: 'Europe/London',
  ODJ: 'Africa/Bangui',
  ODL: 'Australia/Adelaide',
  ODM: 'America/New_York',
  ODN: 'Asia/Kuching',
  ODO: 'Asia/Irkutsk',
  ODR: 'Australia/Perth',
  ODS: 'Europe/Simferopol',
  ODW: 'America/Los_Angeles',
  ODY: 'Asia/Vientiane',
  OEC: 'Asia/Dili',
  OEL: 'Europe/Moscow',
  OEM: 'America/Paramaribo',
  OEO: 'America/Chicago',
  OER: 'Europe/Stockholm',
  OES: 'America/Argentina/Salta',
  OFF: 'America/Chicago',
  OFI: 'Africa/Abidjan',
  OFJ: 'Atlantic/Reykjavik',
  OFK: 'America/Chicago',
  OFU: 'Pacific/Pago_Pago',
  OGA: 'America/Denver',
  OGB: 'America/New_York',
  OGD: 'America/Denver',
  OGE: 'Pacific/Port_Moresby',
  OGG: 'Pacific/Honolulu',
  OGL: 'America/Guyana',
  OGM: 'America/Panama',
  OGN: 'Asia/Tokyo',
  OGO: 'Africa/Abidjan',
  OGR: 'Africa/Ndjamena',
  OGS: 'America/New_York',
  OGU: 'Europe/Istanbul',
  OGV: 'Africa/Windhoek',
  OGX: 'Africa/Algiers',
  OGZ: 'Europe/Moscow',
  OHA: 'Pacific/Auckland',
  OHB: 'Indian/Antananarivo',
  OHD: 'Europe/Skopje',
  OHE: 'Asia/Shanghai',
  OHH: 'Asia/Sakhalin',
  OHI: 'Africa/Windhoek',
  OHO: 'Asia/Vladivostok',
  OHR: 'Europe/Berlin',
  OHS: 'Asia/Muscat',
  OHT: 'Asia/Karachi',
  OIA: 'America/Santarem',
  OIC: 'America/New_York',
  OIM: 'Asia/Tokyo',
  OIR: 'Asia/Tokyo',
  OIT: 'Asia/Tokyo',
  OJC: 'America/Chicago',
  OJU: 'Asia/Makassar',
  OKA: 'Asia/Tokyo',
  OKB: 'Australia/Brisbane',
  OKC: 'America/Chicago',
  OKD: 'Asia/Tokyo',
  OKE: 'Asia/Tokyo',
  OKF: 'Africa/Windhoek',
  OKG: 'Africa/Brazzaville',
  OKH: 'Europe/London',
  OKI: 'Asia/Tokyo',
  OKJ: 'Asia/Tokyo',
  OKK: 'America/Indiana/Indianapolis',
  OKL: 'Asia/Jayapura',
  OKM: 'America/Chicago',
  OKN: 'Africa/Libreville',
  OKO: 'Asia/Tokyo',
  OKP: 'Pacific/Port_Moresby',
  OKQ: 'Asia/Jayapura',
  OKR: 'Australia/Brisbane',
  OKS: 'America/Denver',
  OKT: 'Asia/Yekaterinburg',
  OKU: 'Africa/Windhoek',
  OKV: 'Pacific/Port_Moresby',
  OKY: 'Australia/Brisbane',
  OLA: 'Europe/Oslo',
  OLB: 'Europe/Rome',
  OLC: 'America/Manaus',
  OLD: 'America/New_York',
  OLE: 'America/New_York',
  OLF: 'America/Denver',
  OLG: 'Africa/Nairobi',
  OLH: 'America/Anchorage',
  OLI: 'Atlantic/Reykjavik',
  OLJ: 'Pacific/Efate',
  OLK: 'America/Asuncion',
  OLL: 'Africa/Brazzaville',
  OLM: 'America/Los_Angeles',
  OLN: 'America/Argentina/Catamarca',
  OLO: 'Europe/Prague',
  OLP: 'Australia/Adelaide',
  OLQ: 'Pacific/Port_Moresby',
  OLR: 'Asia/Kabul',
  OLS: 'America/Phoenix',
  OLU: 'America/Chicago',
  OLV: 'America/Chicago',
  OLX: 'Africa/Nairobi',
  OLY: 'America/Chicago',
  OLZ: 'Asia/Yakutsk',
  OMA: 'America/Chicago',
  OMB: 'Africa/Libreville',
  OMC: 'Asia/Manila',
  OMD: 'Africa/Windhoek',
  OME: 'America/Nome',
  OMF: 'Asia/Amman',
  OMG: 'Africa/Windhoek',
  OMH: 'Asia/Tehran',
  OMI: 'Asia/Tehran',
  OMJ: 'Asia/Tokyo',
  OMK: 'America/Los_Angeles',
  OML: 'Pacific/Port_Moresby',
  OMM: 'Asia/Muscat',
  OMO: 'Europe/Sarajevo',
  OMR: 'Europe/Bucharest',
  OMS: 'Asia/Omsk',
  OMY: 'Asia/Phnom_Penh',
  ONA: 'America/Chicago',
  ONB: 'Pacific/Port_Moresby',
  OND: 'Africa/Windhoek',
  ONE: 'Pacific/Guadalcanal',
  ONG: 'Australia/Brisbane',
  ONH: 'America/New_York',
  ONI: 'Asia/Jayapura',
  ONJ: 'Asia/Tokyo',
  ONK: 'Asia/Yakutsk',
  ONL: 'America/Chicago',
  ONM: 'America/Denver',
  ONO: 'America/Boise',
  ONP: 'America/Los_Angeles',
  ONQ: 'Europe/Istanbul',
  ONR: 'Australia/Brisbane',
  ONS: 'Australia/Perth',
  ONT: 'America/Los_Angeles',
  ONU: 'Pacific/Fiji',
  ONX: 'America/Panama',
  ONY: 'America/Chicago',
  OOA: 'America/Chicago',
  OOK: 'America/Nome',
  OOL: 'Australia/Brisbane',
  OOM: 'Australia/Sydney',
  OOR: 'Australia/Brisbane',
  OOT: 'Pacific/Tarawa',
  OPA: 'Atlantic/Reykjavik',
  OPB: 'Pacific/Port_Moresby',
  OPF: 'America/New_York',
  OPI: 'Australia/Darwin',
  OPL: 'America/Chicago',
  OPO: 'Europe/Lisbon',
  OPS: 'America/Cuiaba',
  OPU: 'Pacific/Port_Moresby',
  OPW: 'Africa/Windhoek',
  ORA: 'America/Argentina/Salta',
  ORB: 'Europe/Stockholm',
  ORC: 'America/Bogota',
  ORD: 'America/Chicago',
  ORE: 'Europe/Paris',
  ORF: 'America/New_York',
  ORG: 'America/Paramaribo',
  ORH: 'America/New_York',
  ORI: 'America/Anchorage',
  ORJ: 'America/Guyana',
  ORK: 'Europe/Dublin',
  ORL: 'America/New_York',
  ORM: 'Europe/London',
  ORN: 'Africa/Algiers',
  ORO: 'America/Tegucigalpa',
  ORP: 'Africa/Gaborone',
  ORR: 'Australia/Adelaide',
  ORS: 'Australia/Lindeman',
  ORT: 'America/Anchorage',
  ORU: 'America/La_Paz',
  ORV: 'America/Anchorage',
  ORW: 'Asia/Karachi',
  ORX: 'America/Santarem',
  ORY: 'Europe/Paris',
  ORZ: 'America/Belize',
  OSA: 'Asia/Tokyo',
  OSB: 'America/Chicago',
  OSC: 'America/Detroit',
  OSD: 'Europe/Stockholm',
  OSE: 'Pacific/Port_Moresby',
  OSF: 'Europe/Moscow',
  OSG: 'Pacific/Port_Moresby',
  OSH: 'America/Chicago',
  OSI: 'Europe/Zagreb',
  OSJ: 'Africa/Nairobi',
  OSK: 'Europe/Stockholm',
  OSL: 'Europe/Oslo',
  OSM: 'Asia/Baghdad',
  OSN: 'Asia/Seoul',
  OSO: 'Australia/Brisbane',
  OSR: 'Europe/Prague',
  OSS: 'Asia/Bishkek',
  OST: 'Europe/Brussels',
  OSU: 'America/New_York',
  OSW: 'Asia/Yekaterinburg',
  OSX: 'America/Chicago',
  OSY: 'Europe/Oslo',
  OSZ: 'Europe/Warsaw',
  OTA: 'Africa/Addis_Ababa',
  OTC: 'Africa/Ndjamena',
  OTD: 'America/Panama',
  OTG: 'America/Chicago',
  OTH: 'America/Los_Angeles',
  OTI: 'Asia/Jayapura',
  OTJ: 'Africa/Windhoek',
  OTK: 'America/Los_Angeles',
  OTL: 'Africa/Nouakchott',
  OTM: 'America/Chicago',
  OTN: 'America/Indiana/Vincennes',
  OTP: 'Europe/Bucharest',
  OTR: 'America/Costa_Rica',
  OTS: 'America/Los_Angeles',
  OTT: 'America/Cuiaba',
  OTU: 'America/Bogota',
  OTV: 'Pacific/Guadalcanal',
  OTY: 'Pacific/Bougainville',
  OTZ: 'America/Nome',
  OUA: 'Africa/Ouagadougou',
  OUD: 'Africa/Casablanca',
  OUE: 'Africa/Brazzaville',
  OUG: 'Africa/Ouagadougou',
  OUH: 'Africa/Johannesburg',
  OUK: 'Europe/London',
  OUL: 'Europe/Helsinki',
  OUM: 'Africa/Ndjamena',
  OUN: 'America/Chicago',
  OUR: 'Africa/Douala',
  OUS: 'America/Sao_Paulo',
  OUT: 'Africa/Ndjamena',
  OUU: 'Africa/Libreville',
  OUZ: 'Africa/Nouakchott',
  OVA: 'Indian/Antananarivo',
  OVB: 'Asia/Novosibirsk',
  OVD: 'Europe/Madrid',
  OVE: 'America/Los_Angeles',
  OVG: 'Africa/Johannesburg',
  OVL: 'America/Santiago',
  OVR: 'America/Argentina/Buenos_Aires',
  OVS: 'Asia/Yekaterinburg',
  OWA: 'America/Chicago',
  OWB: 'America/Chicago',
  OWD: 'America/New_York',
  OWE: 'Africa/Libreville',
  OWK: 'America/New_York',
  OXB: 'Africa/Bissau',
  OXC: 'America/New_York',
  OXD: 'America/New_York',
  OXF: 'Europe/London',
  OXO: 'Australia/Brisbane',
  OXR: 'America/Los_Angeles',
  OXY: 'Australia/Brisbane',
  OYA: 'America/Argentina/Cordoba',
  OYE: 'Africa/Libreville',
  OYG: 'Africa/Kampala',
  OYK: 'America/Belem',
  OYL: 'Africa/Nairobi',
  OYN: 'Australia/Sydney',
  OYO: 'America/Argentina/Buenos_Aires',
  OYP: 'America/Cayenne',
  OZA: 'America/Chicago',
  OZC: 'Asia/Manila',
  OZG: 'Africa/Casablanca',
  OZH: 'Europe/Zaporozhye',
  OZP: 'Europe/Madrid',
  OZR: 'America/Chicago',
  OZZ: 'Africa/Casablanca',
  PAA: 'Asia/Yangon',
  PAB: 'Asia/Kolkata',
  PAC: 'America/Panama',
  PAD: 'Europe/Berlin',
  PAE: 'America/Los_Angeles',
  PAF: 'Africa/Kampala',
  PAG: 'Asia/Manila',
  PAH: 'America/Chicago',
  PAJ: 'Asia/Karachi',
  PAK: 'Pacific/Honolulu',
  PAL: 'America/Bogota',
  PAM: 'America/Chicago',
  PAN: 'Asia/Bangkok',
  PAO: 'America/Los_Angeles',
  PAP: 'America/Port-au-Prince',
  PAQ: 'America/Anchorage',
  PAR: 'Europe/Paris',
  PAS: 'Europe/Athens',
  PAT: 'Asia/Kolkata',
  PAU: 'Asia/Yangon',
  PAV: 'America/Maceio',
  PAW: 'Pacific/Port_Moresby',
  PAX: 'America/Port-au-Prince',
  PAY: 'Asia/Kuching',
  PAZ: 'America/Mexico_City',
  PBB: 'America/Campo_Grande',
  PBC: 'America/Mexico_City',
  PBD: 'Asia/Kolkata',
  PBE: 'America/Bogota',
  PBF: 'America/Chicago',
  PBG: 'America/New_York',
  PBH: 'Asia/Thimphu',
  PBI: 'America/New_York',
  PBJ: 'Pacific/Efate',
  PBL: 'America/Caracas',
  PBM: 'America/Paramaribo',
  PBN: 'Africa/Luanda',
  PBO: 'Australia/Perth',
  PBP: 'America/Costa_Rica',
  PBQ: 'America/Porto_Velho',
  PBR: 'America/Guatemala',
  PBT: 'America/Asuncion',
  PBU: 'Asia/Yangon',
  PBV: 'America/Cuiaba',
  PBX: 'America/Cuiaba',
  PBZ: 'Africa/Johannesburg',
  PCA: 'America/Anchorage',
  PCB: 'Asia/Jakarta',
  PCC: 'America/Bogota',
  PCD: 'America/Chicago',
  PCF: 'Africa/Johannesburg',
  PCH: 'America/Tegucigalpa',
  PCJ: 'America/Asuncion',
  PCL: 'America/Lima',
  PCM: 'America/Cancun',
  PCN: 'Pacific/Auckland',
  PCO: 'America/Mazatlan',
  PCP: 'Africa/Sao_Tome',
  PCQ: 'Asia/Vientiane',
  PCR: 'America/Bogota',
  PCS: 'America/Fortaleza',
  PCT: 'America/New_York',
  PCU: 'America/Chicago',
  PCV: 'America/Hermosillo',
  PDA: 'America/Bogota',
  PDB: 'America/Anchorage',
  PDC: 'Pacific/Noumea',
  PDD: 'Africa/Maputo',
  PDE: 'Australia/Adelaide',
  PDF: 'America/Bahia',
  PDG: 'Asia/Jakarta',
  PDI: 'Pacific/Port_Moresby',
  PDK: 'America/New_York',
  PDL: 'Atlantic/Azores',
  PDM: 'America/Panama',
  PDN: 'Australia/Adelaide',
  PDO: 'Asia/Jakarta',
  PDP: 'America/Montevideo',
  PDR: 'America/Fortaleza',
  PDS: 'America/Matamoros',
  PDT: 'America/Los_Angeles',
  PDU: 'America/Montevideo',
  PDV: 'Europe/Sofia',
  PDX: 'America/Los_Angeles',
  PDZ: 'America/Caracas',
  PEA: 'Australia/Adelaide',
  PEB: 'Africa/Maputo',
  PEC: 'America/Juneau',
  PED: 'Europe/Prague',
  PEE: 'Asia/Yekaterinburg',
  PEF: 'Europe/Berlin',
  PEG: 'Europe/Rome',
  PEH: 'America/Argentina/Buenos_Aires',
  PEI: 'America/Bogota',
  PEK: 'Asia/Shanghai',
  PEL: 'Africa/Maseru',
  PEM: 'America/Lima',
  PEN: 'Asia/Kuala_Lumpur',
  PEP: 'Australia/Darwin',
  PEQ: 'America/Chicago',
  PER: 'Australia/Perth',
  PES: 'Europe/Moscow',
  PET: 'America/Sao_Paulo',
  PEU: 'America/Tegucigalpa',
  PEV: 'Europe/Budapest',
  PEW: 'Asia/Karachi',
  PEX: 'Europe/Moscow',
  PEY: 'Australia/Adelaide',
  PEZ: 'Europe/Moscow',
  PFB: 'America/Sao_Paulo',
  PFC: 'America/Los_Angeles',
  PFJ: 'Atlantic/Reykjavik',
  PFM: 'America/Edmonton',
  PFO: 'Asia/Nicosia',
  PFQ: 'Asia/Tehran',
  PFR: 'Africa/Lubumbashi',
  PGA: 'America/Phoenix',
  PGB: 'Pacific/Port_Moresby',
  PGC: 'America/New_York',
  PGD: 'America/New_York',
  PGE: 'Pacific/Port_Moresby',
  PGF: 'Europe/Paris',
  PGH: 'Asia/Kolkata',
  PGI: 'Africa/Luanda',
  PGK: 'Asia/Jakarta',
  PGL: 'America/Chicago',
  PGM: 'America/Anchorage',
  PGN: 'Pacific/Port_Moresby',
  PGO: 'America/Denver',
  PGQ: 'Asia/Jayapura',
  PGR: 'America/Chicago',
  PGS: 'America/Phoenix',
  PGU: 'Asia/Tehran',
  PGV: 'America/New_York',
  PGX: 'Europe/Paris',
  PGZ: 'America/Sao_Paulo',
  PHA: 'Asia/Ho_Chi_Minh',
  PHB: 'America/Fortaleza',
  PHC: 'Africa/Lagos',
  PHD: 'America/New_York',
  PHE: 'Australia/Perth',
  PHF: 'America/New_York',
  PHG: 'Africa/Lagos',
  PHI: 'America/Fortaleza',
  PHK: 'America/New_York',
  PHL: 'America/New_York',
  PHN: 'America/Detroit',
  PHO: 'America/Nome',
  PHP: 'America/Denver',
  PHQ: 'Australia/Brisbane',
  PHR: 'Pacific/Fiji',
  PHS: 'Asia/Bangkok',
  PHT: 'America/Chicago',
  PHU: 'Asia/Ho_Chi_Minh',
  PHW: 'Africa/Johannesburg',
  PHX: 'America/Phoenix',
  PHY: 'Asia/Bangkok',
  PIA: 'America/Chicago',
  PIB: 'America/Chicago',
  PIC: 'America/Grand_Turk',
  PIE: 'America/New_York',
  PIF: 'Asia/Taipei',
  PIH: 'America/Boise',
  PIK: 'Europe/London',
  PIL: 'America/Asuncion',
  PIM: 'America/New_York',
  PIN: 'America/Manaus',
  PIO: 'America/Lima',
  PIP: 'America/Anchorage',
  PIQ: 'America/Guyana',
  PIR: 'America/Chicago',
  PIS: 'Europe/Paris',
  PIT: 'America/New_York',
  PIU: 'America/Lima',
  PIV: 'America/Sao_Paulo',
  PIW: 'America/Winnipeg',
  PIX: 'Atlantic/Azores',
  PIZ: 'America/Nome',
  PJA: 'Europe/Stockholm',
  PJB: 'America/Phoenix',
  PJC: 'America/Asuncion',
  PJG: 'Asia/Karachi',
  PJM: 'America/Costa_Rica',
  PKA: 'America/Anchorage',
  PKB: 'America/New_York',
  PKC: 'Asia/Kamchatka',
  PKD: 'America/Chicago',
  PKE: 'Australia/Sydney',
  PKF: 'America/Chicago',
  PKG: 'Asia/Kuala_Lumpur',
  PKH: 'Europe/Athens',
  PKJ: 'America/Guatemala',
  PKK: 'Asia/Yangon',
  PKM: 'America/Guyana',
  PKN: 'Asia/Pontianak',
  PKO: 'Africa/Porto-Novo',
  PKP: 'Pacific/Tahiti',
  PKR: 'Asia/Kathmandu',
  PKT: 'Australia/Darwin',
  PKU: 'Asia/Jakarta',
  PKV: 'Europe/Moscow',
  PKW: 'Africa/Gaborone',
  PKX: 'Asia/Shanghai',
  PKY: 'Asia/Pontianak',
  PKZ: 'Asia/Vientiane',
  PLA: 'America/Bogota',
  PLC: 'America/Bogota',
  PLD: 'America/Costa_Rica',
  PLE: 'Pacific/Port_Moresby',
  PLF: 'Africa/Ndjamena',
  PLH: 'Europe/London',
  PLI: 'America/St_Vincent',
  PLJ: 'America/Belize',
  PLK: 'America/Chicago',
  PLL: 'America/Manaus',
  PLM: 'Asia/Jakarta',
  PLN: 'America/Detroit',
  PLO: 'Australia/Adelaide',
  PLP: 'America/Panama',
  PLQ: 'Europe/Vilnius',
  PLR: 'America/Chicago',
  PLS: 'America/Grand_Turk',
  PLT: 'America/Bogota',
  PLU: 'America/Sao_Paulo',
  PLV: 'Europe/Zaporozhye',
  PLW: 'Asia/Makassar',
  PLX: 'Asia/Almaty',
  PLY: 'America/Indiana/Indianapolis',
  PLZ: 'Africa/Johannesburg',
  PMA: 'Africa/Dar_es_Salaam',
  PMB: 'America/Chicago',
  PMC: 'America/Santiago',
  PMD: 'America/Los_Angeles',
  PMF: 'Europe/Rome',
  PMG: 'America/Campo_Grande',
  PMH: 'America/New_York',
  PMI: 'Europe/Madrid',
  PMK: 'Australia/Lindeman',
  PML: 'America/Anchorage',
  PMN: 'Pacific/Port_Moresby',
  PMO: 'Europe/Rome',
  PMP: 'Pacific/Port_Moresby',
  PMQ: 'America/Argentina/Rio_Gallegos',
  PMR: 'Pacific/Auckland',
  PMS: 'Asia/Damascus',
  PMT: 'America/Guyana',
  PMV: 'America/Caracas',
  PMW: 'America/Araguaina',
  PMX: 'America/New_York',
  PMY: 'America/Argentina/Catamarca',
  PMZ: 'America/Costa_Rica',
  PNA: 'Europe/Madrid',
  PNB: 'America/Araguaina',
  PNC: 'America/Chicago',
  PND: 'America/Belize',
  PNE: 'America/New_York',
  PNG: 'America/Sao_Paulo',
  PNH: 'Asia/Phnom_Penh',
  PNI: 'Pacific/Pohnpei',
  PNJ: 'Asia/Shanghai',
  PNK: 'Asia/Pontianak',
  PNL: 'Europe/Rome',
  PNN: 'America/New_York',
  PNP: 'Pacific/Port_Moresby',
  PNQ: 'Asia/Kolkata',
  PNR: 'Africa/Brazzaville',
  PNS: 'America/Chicago',
  PNT: 'America/Punta_Arenas',
  PNU: 'America/Denver',
  PNV: 'Europe/Vilnius',
  PNX: 'America/Chicago',
  PNY: 'Asia/Kolkata',
  PNZ: 'America/Bahia',
  POA: 'America/Sao_Paulo',
  POB: 'America/New_York',
  POC: 'America/Los_Angeles',
  POD: 'Africa/Dakar',
  POE: 'America/Chicago',
  POF: 'America/Chicago',
  POG: 'Africa/Libreville',
  POH: 'America/Chicago',
  POI: 'America/La_Paz',
  POJ: 'America/Sao_Paulo',
  POL: 'Africa/Maputo',
  POM: 'Pacific/Port_Moresby',
  PON: 'America/Guatemala',
  POO: 'America/Sao_Paulo',
  POP: 'America/Santo_Domingo',
  POR: 'Europe/Helsinki',
  POS: 'America/Port_of_Spain',
  POT: 'America/Jamaica',
  POU: 'America/New_York',
  POV: 'Europe/Bratislava',
  POW: 'Europe/Ljubljana',
  POX: 'Europe/Paris',
  POY: 'America/Denver',
  POZ: 'Europe/Warsaw',
  PPA: 'America/Chicago',
  PPB: 'America/Sao_Paulo',
  PPC: 'America/Anchorage',
  PPE: 'America/Hermosillo',
  PPF: 'America/Chicago',
  PPG: 'Pacific/Pago_Pago',
  PPH: 'America/Caracas',
  PPI: 'Australia/Adelaide',
  PPJ: 'Asia/Jakarta',
  PPK: 'Asia/Almaty',
  PPL: 'Asia/Kathmandu',
  PPM: 'America/New_York',
  PPN: 'America/Bogota',
  PPP: 'Australia/Brisbane',
  PPQ: 'Pacific/Auckland',
  PPR: 'Asia/Jakarta',
  PPS: 'Asia/Manila',
  PPT: 'Pacific/Tahiti',
  PPU: 'Asia/Yangon',
  PPV: 'America/Metlakatla',
  PPW: 'Europe/London',
  PPX: 'Pacific/Port_Moresby',
  PPY: 'America/Sao_Paulo',
  PPZ: 'America/Caracas',
  PQC: 'Asia/Ho_Chi_Minh',
  PQD: 'Asia/Colombo',
  PQI: 'America/New_York',
  PQM: 'America/Mexico_City',
  PQQ: 'Australia/Sydney',
  PQS: 'America/Nome',
  PRA: 'America/Argentina/Cordoba',
  PRB: 'America/Los_Angeles',
  PRC: 'America/Phoenix',
  PRD: 'Australia/Perth',
  PRE: 'America/Bogota',
  PRG: 'Europe/Prague',
  PRH: 'Asia/Bangkok',
  PRI: 'Indian/Mahe',
  PRK: 'Africa/Johannesburg',
  PRM: 'Europe/Lisbon',
  PRN: 'Europe/Belgrade',
  PRO: 'America/Chicago',
  PRP: 'Europe/Paris',
  PRQ: 'America/Argentina/Cordoba',
  PRR: 'America/Guyana',
  PRS: 'Pacific/Guadalcanal',
  PRU: 'Asia/Yangon',
  PRV: 'Europe/Prague',
  PRW: 'America/Chicago',
  PRX: 'America/Chicago',
  PRY: 'Africa/Johannesburg',
  PRZ: 'America/Los_Angeles',
  PSA: 'Europe/Rome',
  PSB: 'America/New_York',
  PSC: 'America/Los_Angeles',
  PSD: 'Africa/Cairo',
  PSE: 'America/Puerto_Rico',
  PSF: 'America/New_York',
  PSG: 'America/Juneau',
  PSH: 'Europe/Berlin',
  PSI: 'Asia/Karachi',
  PSJ: 'Asia/Makassar',
  PSK: 'America/New_York',
  PSL: 'Europe/London',
  PSM: 'America/New_York',
  PSN: 'America/Chicago',
  PSO: 'America/Bogota',
  PSP: 'America/Los_Angeles',
  PSQ: 'America/New_York',
  PSR: 'Europe/Rome',
  PSS: 'America/Argentina/Cordoba',
  PSU: 'Asia/Pontianak',
  PSV: 'Europe/London',
  PSW: 'America/Sao_Paulo',
  PSX: 'America/Chicago',
  PSY: 'Atlantic/Stanley',
  PSZ: 'America/La_Paz',
  PTA: 'America/Anchorage',
  PTB: 'America/New_York',
  PTC: 'America/Metlakatla',
  PTD: 'America/Sitka',
  PTF: 'Pacific/Fiji',
  PTG: 'Africa/Johannesburg',
  PTH: 'America/Anchorage',
  PTJ: 'Australia/Melbourne',
  PTK: 'America/Detroit',
  PTM: 'America/Caracas',
  PTN: 'America/Chicago',
  PTO: 'America/Sao_Paulo',
  PTP: 'America/Guadeloupe',
  PTQ: 'America/Santarem',
  PTS: 'America/Chicago',
  PTT: 'America/Chicago',
  PTU: 'America/Nome',
  PTV: 'America/Los_Angeles',
  PTW: 'America/New_York',
  PTX: 'America/Bogota',
  PTY: 'America/Panama',
  PTZ: 'America/Guayaquil',
  PUA: 'Pacific/Port_Moresby',
  PUB: 'America/Denver',
  PUC: 'America/Denver',
  PUD: 'America/Argentina/Rio_Gallegos',
  PUE: 'America/Panama',
  PUF: 'Europe/Paris',
  PUG: 'Australia/Adelaide',
  PUI: 'Pacific/Port_Moresby',
  PUJ: 'America/Santo_Domingo',
  PUK: 'Pacific/Tahiti',
  PUL: 'America/Los_Angeles',
  PUM: 'Asia/Makassar',
  PUN: 'Africa/Lubumbashi',
  PUP: 'Africa/Ouagadougou',
  PUQ: 'America/Punta_Arenas',
  PUR: 'America/La_Paz',
  PUS: 'Asia/Seoul',
  PUT: 'Asia/Kolkata',
  PUU: 'America/Bogota',
  PUV: 'Pacific/Noumea',
  PUW: 'America/Los_Angeles',
  PUX: 'America/Santiago',
  PUY: 'Europe/Zagreb',
  PUZ: 'America/Managua',
  PVA: 'America/Bogota',
  PVC: 'America/New_York',
  PVD: 'America/New_York',
  PVE: 'America/Panama',
  PVF: 'America/Los_Angeles',
  PVG: 'Asia/Shanghai',
  PVH: 'America/Porto_Velho',
  PVI: 'America/Sao_Paulo',
  PVK: 'Europe/Athens',
  PVL: 'America/New_York',
  PVO: 'America/Guayaquil',
  PVR: 'America/Mexico_City',
  PVS: 'Asia/Anadyr',
  PVU: 'America/Denver',
  PVW: 'America/Chicago',
  PWA: 'America/Chicago',
  PWD: 'America/Denver',
  PWE: 'Asia/Anadyr',
  PWI: 'Africa/Addis_Ababa',
  PWK: 'America/Chicago',
  PWL: 'Asia/Jakarta',
  PWM: 'America/New_York',
  PWN: 'America/Nassau',
  PWO: 'Africa/Lubumbashi',
  PWQ: 'Asia/Almaty',
  PWR: 'America/Sitka',
  PWT: 'America/Los_Angeles',
  PWY: 'America/Denver',
  PXA: 'Asia/Jakarta',
  PXH: 'Australia/Adelaide',
  PXL: 'America/Phoenix',
  PXM: 'America/Mexico_City',
  PXO: 'Atlantic/Madeira',
  PXR: 'Asia/Bangkok',
  PXU: 'Asia/Ho_Chi_Minh',
  PYA: 'America/Bogota',
  PYB: 'Asia/Kolkata',
  PYC: 'America/Panama',
  PYE: 'Pacific/Rarotonga',
  PYG: 'Asia/Kolkata',
  PYH: 'America/Caracas',
  PYJ: 'Asia/Yakutsk',
  PYK: 'Asia/Tehran',
  PYL: 'America/Anchorage',
  PYM: 'America/New_York',
  PYN: 'America/Bogota',
  PYO: 'America/Guayaquil',
  PYR: 'Europe/Athens',
  PYS: 'America/Los_Angeles',
  PYV: 'America/Panama',
  PYY: 'Asia/Bangkok',
  PZA: 'America/Bogota',
  PZB: 'Africa/Johannesburg',
  PZH: 'Asia/Karachi',
  PZI: 'Asia/Shanghai',
  PZK: 'Pacific/Rarotonga',
  PZL: 'Africa/Johannesburg',
  PZO: 'America/Caracas',
  PZS: 'America/Santiago',
  PZU: 'Africa/Khartoum',
  PZY: 'Europe/Bratislava',
  QAQ: 'Europe/Rome',
  QBC: 'America/Vancouver',
  QCY: 'Europe/London',
  QHU: 'Europe/Berlin',
  QLR: 'Europe/Lisbon',
  QOW: 'Africa/Lagos',
  QPG: 'Asia/Singapore',
  QRA: 'Africa/Johannesburg',
  QRO: 'America/Mexico_City',
  QRW: 'Africa/Lagos',
  QSC: 'America/Sao_Paulo',
  QSF: 'Africa/Algiers',
  QSR: 'Europe/Rome',
  QSZ: 'Asia/Shanghai',
  QUB: 'Africa/Tripoli',
  QUG: 'Europe/London',
  QUO: 'Africa/Lagos',
  QUY: 'Europe/London',
  QWG: 'America/New_York',
  RAA: 'Pacific/Port_Moresby',
  RAB: 'Pacific/Port_Moresby',
  RAC: 'America/Chicago',
  RAD: 'America/Tortola',
  RAE: 'Asia/Riyadh',
  RAF: 'America/Argentina/Cordoba',
  RAG: 'Pacific/Auckland',
  RAH: 'Asia/Riyadh',
  RAI: 'Atlantic/Cape_Verde',
  RAJ: 'Asia/Kolkata',
  RAK: 'Africa/Casablanca',
  RAL: 'America/Los_Angeles',
  RAM: 'Australia/Darwin',
  RAN: 'Europe/Rome',
  RAO: 'America/Sao_Paulo',
  RAP: 'America/Denver',
  RAQ: 'Asia/Makassar',
  RAR: 'Pacific/Rarotonga',
  RAS: 'Asia/Tehran',
  RAT: 'Asia/Yekaterinburg',
  RAV: 'America/Bogota',
  RAW: 'Pacific/Port_Moresby',
  RAX: 'Pacific/Port_Moresby',
  RAZ: 'Asia/Karachi',
  RBA: 'Africa/Casablanca',
  RBB: 'America/Manaus',
  RBC: 'Australia/Melbourne',
  RBD: 'America/Chicago',
  RBE: 'Asia/Phnom_Penh',
  RBF: 'America/Los_Angeles',
  RBG: 'America/Los_Angeles',
  RBI: 'Pacific/Fiji',
  RBJ: 'Asia/Tokyo',
  RBK: 'America/Los_Angeles',
  RBL: 'America/Los_Angeles',
  RBM: 'Europe/Berlin',
  RBO: 'America/La_Paz',
  RBP: 'Pacific/Port_Moresby',
  RBQ: 'America/La_Paz',
  RBR: 'America/Rio_Branco',
  RBS: 'Australia/Melbourne',
  RBT: 'Africa/Nairobi',
  RBU: 'Australia/Perth',
  RBV: 'Pacific/Guadalcanal',
  RBW: 'America/New_York',
  RBX: 'Africa/Juba',
  RBY: 'America/Anchorage',
  RCA: 'America/Denver',
  RCB: 'Africa/Johannesburg',
  RCE: 'America/Los_Angeles',
  RCH: 'America/Bogota',
  RCK: 'America/Chicago',
  RCL: 'Pacific/Efate',
  RCM: 'Australia/Brisbane',
  RCN: 'Australia/Adelaide',
  RCO: 'Europe/Paris',
  RCQ: 'America/Argentina/Cordoba',
  RCR: 'America/Indiana/Indianapolis',
  RCS: 'Europe/London',
  RCT: 'America/Detroit',
  RCU: 'America/Argentina/Cordoba',
  RCY: 'America/Nassau',
  RDA: 'Australia/Darwin',
  RDB: 'America/Nome',
  RDC: 'America/Santarem',
  RDD: 'America/Los_Angeles',
  RDE: 'Asia/Jayapura',
  RDG: 'America/New_York',
  RDM: 'America/Los_Angeles',
  RDN: 'Asia/Kuala_Lumpur',
  RDO: 'Europe/Warsaw',
  RDP: 'Asia/Kolkata',
  RDR: 'America/Chicago',
  RDS: 'America/Argentina/Salta',
  RDT: 'Africa/Dakar',
  RDU: 'America/New_York',
  RDV: 'America/Anchorage',
  RDZ: 'Europe/Paris',
  REA: 'Pacific/Tahiti',
  REB: 'Europe/Berlin',
  REC: 'America/Recife',
  RED: 'America/New_York',
  REE: 'America/Chicago',
  REG: 'Europe/Rome',
  REI: 'America/Cayenne',
  REK: 'Atlantic/Reykjavik',
  REL: 'America/Argentina/Catamarca',
  REN: 'Asia/Yekaterinburg',
  REO: 'America/Boise',
  REP: 'Asia/Phnom_Penh',
  REQ: 'Asia/Karachi',
  RER: 'America/Guatemala',
  RES: 'America/Argentina/Cordoba',
  RET: 'Europe/Oslo',
  REU: 'Europe/Madrid',
  REW: 'Asia/Kolkata',
  REX: 'America/Matamoros',
  REY: 'America/La_Paz',
  REZ: 'America/Sao_Paulo',
  RFA: 'Africa/Bangui',
  RFD: 'America/Chicago',
  RFG: 'America/Chicago',
  RFK: 'America/Chicago',
  RFN: 'Atlantic/Reykjavik',
  RFP: 'Pacific/Tahiti',
  RFR: 'America/Costa_Rica',
  RFS: 'America/Managua',
  RGA: 'America/Argentina/Ushuaia',
  RGE: 'Pacific/Port_Moresby',
  RGH: 'Asia/Kolkata',
  RGI: 'Pacific/Tahiti',
  RGK: 'Asia/Omsk',
  RGL: 'America/Argentina/Rio_Gallegos',
  RGN: 'Asia/Yangon',
  RGO: 'Asia/Pyongyang',
  RGR: 'America/Chicago',
  RGS: 'Europe/Madrid',
  RGT: 'Asia/Jakarta',
  RHA: 'Atlantic/Reykjavik',
  RHD: 'America/Argentina/Cordoba',
  RHE: 'Europe/Paris',
  RHG: 'Africa/Kigali',
  RHI: 'America/Chicago',
  RHL: 'Australia/Perth',
  RHN: 'Africa/Windhoek',
  RHO: 'Europe/Athens',
  RHP: 'Asia/Kathmandu',
  RHR: 'Asia/Dubai',
  RHT: 'Asia/Shanghai',
  RHV: 'America/Los_Angeles',
  RIA: 'America/Sao_Paulo',
  RIB: 'America/La_Paz',
  RIC: 'America/New_York',
  RID: 'America/Indiana/Indianapolis',
  RIE: 'America/Chicago',
  RIF: 'America/Denver',
  RIG: 'America/Sao_Paulo',
  RIH: 'America/Panama',
  RIJ: 'America/Lima',
  RIL: 'America/Denver',
  RIM: 'America/Lima',
  RIN: 'Pacific/Guadalcanal',
  RIO: 'America/Sao_Paulo',
  RIR: 'America/Los_Angeles',
  RIS: 'Asia/Tokyo',
  RIV: 'America/Los_Angeles',
  RIW: 'America/Denver',
  RIX: 'Europe/Riga',
  RIY: 'Asia/Aden',
  RIZ: 'Asia/Shanghai',
  RJA: 'Asia/Kolkata',
  RJB: 'Asia/Kathmandu',
  RJH: 'Asia/Dhaka',
  RJI: 'Asia/Kolkata',
  RJK: 'Europe/Zagreb',
  RJL: 'Europe/Madrid',
  RJM: 'Asia/Jayapura',
  RJN: 'Asia/Tehran',
  RKA: 'Pacific/Tahiti',
  RKD: 'America/New_York',
  RKE: 'Europe/Copenhagen',
  RKH: 'America/New_York',
  RKI: 'Asia/Jakarta',
  RKP: 'America/Chicago',
  RKR: 'America/Chicago',
  RKS: 'America/Denver',
  RKT: 'Asia/Dubai',
  RKU: 'Pacific/Port_Moresby',
  RKV: 'Atlantic/Reykjavik',
  RKW: 'America/Chicago',
  RKY: 'Australia/Brisbane',
  RKZ: 'Asia/Urumqi',
  RLD: 'America/Los_Angeles',
  RLG: 'Europe/Berlin',
  RLK: 'Asia/Shanghai',
  RLO: 'America/Argentina/San_Luis',
  RLP: 'Australia/Brisbane',
  RLR: 'Indian/Antananarivo',
  RLT: 'Africa/Niamey',
  RMA: 'Australia/Brisbane',
  RMB: 'Asia/Muscat',
  RMD: 'Asia/Kolkata',
  RME: 'America/New_York',
  RMF: 'Africa/Cairo',
  RMG: 'America/New_York',
  RMI: 'Europe/Rome',
  RMK: 'Australia/Adelaide',
  RML: 'Asia/Colombo',
  RMN: 'Pacific/Port_Moresby',
  RMP: 'America/Anchorage',
  RMQ: 'Asia/Taipei',
  RMS: 'Europe/Berlin',
  RMT: 'Pacific/Tahiti',
  RMU: 'Europe/Madrid',
  RMY: 'America/Los_Angeles',
  RNA: 'Pacific/Guadalcanal',
  RNB: 'Europe/Stockholm',
  RNC: 'America/Chicago',
  RND: 'America/Chicago',
  RNE: 'Europe/Paris',
  RNG: 'America/Denver',
  RNH: 'America/Chicago',
  RNI: 'America/Managua',
  RNJ: 'Asia/Tokyo',
  RNL: 'Pacific/Guadalcanal',
  RNM: 'Asia/Muscat',
  RNN: 'Europe/Copenhagen',
  RNO: 'America/Los_Angeles',
  RNP: 'Pacific/Kwajalein',
  RNR: 'Pacific/Port_Moresby',
  RNS: 'Europe/Paris',
  RNT: 'America/Los_Angeles',
  RNU: 'Asia/Kuching',
  RNZ: 'America/Chicago',
  ROA: 'America/New_York',
  ROB: 'Africa/Monrovia',
  ROC: 'America/New_York',
  ROD: 'Africa/Johannesburg',
  ROF: 'America/Los_Angeles',
  ROG: 'America/Chicago',
  ROH: 'Australia/Brisbane',
  ROI: 'Asia/Bangkok',
  ROK: 'Australia/Brisbane',
  ROL: 'America/Denver',
  ROM: 'Europe/Rome',
  RON: 'America/Bogota',
  ROO: 'America/Cuiaba',
  ROP: 'Pacific/Saipan',
  ROR: 'Pacific/Palau',
  ROS: 'America/Argentina/Cordoba',
  ROT: 'Pacific/Auckland',
  ROU: 'Europe/Sofia',
  ROV: 'Europe/Moscow',
  ROW: 'America/Denver',
  ROX: 'America/Chicago',
  ROY: 'America/Argentina/Catamarca',
  ROZ: 'Europe/Madrid',
  RPA: 'Asia/Kathmandu',
  RPB: 'Australia/Darwin',
  RPM: 'Australia/Darwin',
  RPN: 'Asia/Jerusalem',
  RPR: 'Asia/Kolkata',
  RPV: 'Australia/Darwin',
  RPX: 'America/Denver',
  RQA: 'Asia/Urumqi',
  RQW: 'Asia/Baghdad',
  RRE: 'Australia/Adelaide',
  RRG: 'Indian/Mauritius',
  RRI: 'Pacific/Guadalcanal',
  RRK: 'Asia/Kolkata',
  RRL: 'America/Chicago',
  RRM: 'Africa/Maputo',
  RRR: 'Pacific/Tahiti',
  RRS: 'Europe/Oslo',
  RRT: 'America/Chicago',
  RRV: 'Australia/Darwin',
  RSA: 'America/Argentina/Salta',
  RSB: 'Australia/Brisbane',
  RSD: 'America/Nassau',
  RSE: 'Australia/Sydney',
  RSH: 'America/Anchorage',
  RSJ: 'America/Los_Angeles',
  RSK: 'Asia/Jayapura',
  RSL: 'America/Chicago',
  RSN: 'America/Chicago',
  RSS: 'Africa/Khartoum',
  RST: 'America/Chicago',
  RSU: 'Asia/Seoul',
  RSW: 'America/New_York',
  RSX: 'America/New_York',
  RTA: 'Pacific/Fiji',
  RTB: 'America/Tegucigalpa',
  RTC: 'Asia/Kolkata',
  RTG: 'Asia/Makassar',
  RTI: 'Asia/Makassar',
  RTL: 'America/Chicago',
  RTM: 'Europe/Amsterdam',
  RTN: 'America/Denver',
  RTP: 'Australia/Brisbane',
  RTS: 'Australia/Perth',
  RTW: 'Europe/Volgograd',
  RTY: 'Australia/Adelaide',
  RUA: 'Africa/Kampala',
  RUD: 'Asia/Tehran',
  RUE: 'Africa/Lubumbashi',
  RUF: 'Asia/Jayapura',
  RUG: 'Asia/Shanghai',
  RUH: 'Asia/Riyadh',
  RUI: 'America/Denver',
  RUK: 'Asia/Kathmandu',
  RUM: 'Asia/Kathmandu',
  RUN: 'Indian/Reunion',
  RUP: 'Asia/Kolkata',
  RUR: 'Pacific/Tahiti',
  RUS: 'Pacific/Guadalcanal',
  RUT: 'America/New_York',
  RUU: 'Pacific/Port_Moresby',
  RUV: 'America/Guatemala',
  RUY: 'America/Tegucigalpa',
  RVA: 'Indian/Antananarivo',
  RVC: 'Africa/Monrovia',
  RVD: 'America/Sao_Paulo',
  RVE: 'America/Bogota',
  RVH: 'Europe/Moscow',
  RVK: 'Europe/Oslo',
  RVN: 'Europe/Helsinki',
  RVO: 'Africa/Johannesburg',
  RVR: 'America/Denver',
  RVS: 'America/Chicago',
  RVT: 'Australia/Perth',
  RVV: 'Pacific/Tahiti',
  RVY: 'America/Montevideo',
  RWF: 'America/Chicago',
  RWI: 'America/New_York',
  RWL: 'America/Denver',
  RWN: 'Europe/Kiev',
  RXA: 'Asia/Aden',
  RXE: 'America/Boise',
  RXS: 'Asia/Manila',
  RYB: 'Europe/Moscow',
  RYG: 'Europe/Oslo',
  RYK: 'Asia/Karachi',
  RYL: 'Africa/Lusaka',
  RYN: 'Europe/Paris',
  RYO: 'America/Argentina/Rio_Gallegos',
  RZA: 'America/Argentina/Rio_Gallegos',
  RZE: 'Europe/Warsaw',
  RZH: 'Asia/Vladivostok',
  RZN: 'Europe/Moscow',
  RZP: 'Asia/Manila',
  RZR: 'Asia/Tehran',
  RZS: 'Asia/Karachi',
  RZZ: 'America/New_York',
  SAA: 'America/Denver',
  SAB: 'America/Kralendijk',
  SAC: 'America/Los_Angeles',
  SAD: 'America/Phoenix',
  SAF: 'America/Denver',
  SAG: 'Asia/Kolkata',
  SAH: 'Asia/Aden',
  SAK: 'Atlantic/Reykjavik',
  SAL: 'America/El_Salvador',
  SAM: 'Pacific/Port_Moresby',
  SAN: 'America/Los_Angeles',
  SAO: 'America/Sao_Paulo',
  SAP: 'America/Tegucigalpa',
  SAQ: 'America/Nassau',
  SAR: 'America/Chicago',
  SAS: 'America/Los_Angeles',
  SAT: 'America/Chicago',
  SAU: 'Asia/Makassar',
  SAV: 'America/New_York',
  SAW: 'Europe/Istanbul',
  SAX: 'America/Panama',
  SAY: 'Europe/Rome',
  SAZ: 'Africa/Monrovia',
  SBA: 'America/Los_Angeles',
  SBB: 'America/Caracas',
  SBC: 'Pacific/Port_Moresby',
  SBD: 'America/Los_Angeles',
  SBE: 'Pacific/Port_Moresby',
  SBF: 'Asia/Kabul',
  SBG: 'Asia/Jakarta',
  SBH: 'America/St_Barthelemy',
  SBI: 'Africa/Conakry',
  SBJ: 'America/Sao_Paulo',
  SBK: 'Europe/Paris',
  SBL: 'America/La_Paz',
  SBM: 'America/Chicago',
  SBN: 'America/Indiana/Indianapolis',
  SBO: 'America/Denver',
  SBP: 'America/Los_Angeles',
  SBQ: 'Asia/Karachi',
  SBR: 'Australia/Brisbane',
  SBS: 'America/Denver',
  SBT: 'Asia/Yekaterinburg',
  SBU: 'Africa/Johannesburg',
  SBV: 'Pacific/Bougainville',
  SBW: 'Asia/Kuching',
  SBX: 'America/Denver',
  SBY: 'America/New_York',
  SBZ: 'Europe/Bucharest',
  SCA: 'America/Bogota',
  SCB: 'America/Chicago',
  SCC: 'America/Anchorage',
  SCD: 'America/Tegucigalpa',
  SCE: 'America/New_York',
  SCF: 'America/Phoenix',
  SCG: 'Australia/Brisbane',
  SCH: 'America/New_York',
  SCI: 'America/Caracas',
  SCK: 'America/Los_Angeles',
  SCL: 'America/Santiago',
  SCM: 'America/Nome',
  SCN: 'Europe/Berlin',
  SCO: 'Asia/Aqtau',
  SCP: 'Europe/Paris',
  SCQ: 'Europe/Madrid',
  SCS: 'Europe/London',
  SCT: 'Asia/Aden',
  SCU: 'America/Havana',
  SCV: 'Europe/Bucharest',
  SCW: 'Europe/Moscow',
  SCX: 'America/Mexico_City',
  SCY: 'Pacific/Galapagos',
  SCZ: 'Pacific/Guadalcanal',
  SDB: 'Africa/Johannesburg',
  SDC: 'America/Guyana',
  SDD: 'Africa/Luanda',
  SDE: 'America/Argentina/Cordoba',
  SDF: 'America/Kentucky/Louisville',
  SDG: 'Asia/Tehran',
  SDH: 'America/Tegucigalpa',
  SDI: 'Pacific/Port_Moresby',
  SDJ: 'Asia/Tokyo',
  SDK: 'Asia/Kuching',
  SDL: 'Europe/Stockholm',
  SDM: 'America/Los_Angeles',
  SDN: 'Europe/Oslo',
  SDP: 'America/Anchorage',
  SDQ: 'America/Santo_Domingo',
  SDR: 'Europe/Madrid',
  SDS: 'Asia/Tokyo',
  SDT: 'Asia/Karachi',
  SDU: 'America/Sao_Paulo',
  SDV: 'Asia/Jerusalem',
  SDX: 'America/Phoenix',
  SDY: 'America/Denver',
  SDZ: 'Europe/London',
  SEA: 'America/Los_Angeles',
  SEB: 'Africa/Tripoli',
  SED: 'Asia/Jerusalem',
  SEE: 'America/Los_Angeles',
  SEF: 'America/New_York',
  SEG: 'America/New_York',
  SEH: 'Asia/Jayapura',
  SEK: 'Asia/Magadan',
  SEL: 'Asia/Seoul',
  SEM: 'America/Chicago',
  SEN: 'Europe/London',
  SEO: 'Africa/Abidjan',
  SEP: 'America/Chicago',
  SEQ: 'Asia/Pontianak',
  SER: 'America/Indiana/Indianapolis',
  SET: 'America/Recife',
  SEU: 'Africa/Dar_es_Salaam',
  SEV: 'Europe/Zaporozhye',
  SEW: 'Africa/Cairo',
  SEY: 'Africa/Nouakchott',
  SEZ: 'Indian/Mahe',
  SFA: 'Africa/Tunis',
  SFB: 'America/New_York',
  SFC: 'America/Guadeloupe',
  SFD: 'America/Caracas',
  SFE: 'Asia/Manila',
  SFF: 'America/Los_Angeles',
  SFG: 'America/Marigot',
  SFH: 'America/Tijuana',
  SFJ: 'America/Godthab',
  SFK: 'America/Belem',
  SFL: 'Atlantic/Cape_Verde',
  SFM: 'America/New_York',
  SFN: 'America/Argentina/Cordoba',
  SFO: 'America/Los_Angeles',
  SFQ: 'Europe/Istanbul',
  SFS: 'Asia/Manila',
  SFT: 'Europe/Stockholm',
  SFU: 'Pacific/Port_Moresby',
  SFV: 'America/Sao_Paulo',
  SFX: 'America/Caracas',
  SFY: 'America/New_York',
  SFZ: 'America/New_York',
  SGA: 'Asia/Kabul',
  SGB: 'Pacific/Port_Moresby',
  SGC: 'Asia/Yekaterinburg',
  SGD: 'Europe/Copenhagen',
  SGE: 'Europe/Berlin',
  SGF: 'America/Chicago',
  SGH: 'America/New_York',
  SGI: 'Asia/Karachi',
  SGJ: 'Pacific/Port_Moresby',
  SGK: 'Pacific/Port_Moresby',
  SGL: 'Asia/Manila',
  SGM: 'America/Mazatlan',
  SGN: 'Asia/Ho_Chi_Minh',
  SGO: 'Australia/Brisbane',
  SGP: 'Australia/Perth',
  SGQ: 'Asia/Makassar',
  SGR: 'America/Chicago',
  SGT: 'America/Chicago',
  SGU: 'America/Denver',
  SGV: 'America/Argentina/Salta',
  SGW: 'America/Juneau',
  SGX: 'Africa/Dar_es_Salaam',
  SGY: 'America/Juneau',
  SGZ: 'Asia/Bangkok',
  SHA: 'Asia/Shanghai',
  SHB: 'Asia/Tokyo',
  SHC: 'Africa/Addis_Ababa',
  SHD: 'America/New_York',
  SHE: 'Asia/Shanghai',
  SHF: 'Asia/Urumqi',
  SHG: 'America/Anchorage',
  SHH: 'America/Nome',
  SHI: 'Asia/Tokyo',
  SHJ: 'Asia/Dubai',
  SHK: 'Africa/Maseru',
  SHL: 'Asia/Kolkata',
  SHM: 'Asia/Tokyo',
  SHN: 'America/Los_Angeles',
  SHO: 'Africa/Mbabane',
  SHQ: 'Australia/Brisbane',
  SHR: 'America/Denver',
  SHS: 'Asia/Shanghai',
  SHT: 'Australia/Melbourne',
  SHU: 'Australia/Darwin',
  SHV: 'America/Chicago',
  SHW: 'Asia/Riyadh',
  SHX: 'America/Anchorage',
  SHY: 'Africa/Dar_es_Salaam',
  SHZ: 'Africa/Maseru',
  SIA: 'Asia/Shanghai',
  SIB: 'Africa/Brazzaville',
  SIC: 'America/Panama',
  SID: 'Atlantic/Cape_Verde',
  SIE: 'Europe/Lisbon',
  SIF: 'Asia/Kathmandu',
  SIG: 'America/Puerto_Rico',
  SIH: 'Asia/Kathmandu',
  SII: 'Africa/Casablanca',
  SIJ: 'Atlantic/Reykjavik',
  SIK: 'America/Chicago',
  SIL: 'Pacific/Port_Moresby',
  SIM: 'Pacific/Port_Moresby',
  SIN: 'Asia/Singapore',
  SIO: 'Australia/Hobart',
  SIP: 'Europe/Simferopol',
  SIQ: 'Asia/Pontianak',
  SIR: 'Europe/Zurich',
  SIS: 'Africa/Johannesburg',
  SIT: 'America/Sitka',
  SIU: 'America/Managua',
  SIV: 'America/Indiana/Indianapolis',
  SIW: 'Asia/Jakarta',
  SIX: 'Australia/Sydney',
  SIY: 'America/Los_Angeles',
  SIZ: 'Pacific/Port_Moresby',
  SJA: 'America/Lima',
  SJB: 'America/La_Paz',
  SJC: 'America/Los_Angeles',
  SJD: 'America/Mazatlan',
  SJE: 'America/Bogota',
  SJF: 'America/St_Thomas',
  SJG: 'America/Bogota',
  SJH: 'America/Bogota',
  SJI: 'Asia/Manila',
  SJJ: 'Europe/Sarajevo',
  SJK: 'America/Sao_Paulo',
  SJL: 'America/Boa_Vista',
  SJN: 'America/Phoenix',
  SJO: 'America/Costa_Rica',
  SJP: 'America/Sao_Paulo',
  SJQ: 'Africa/Lusaka',
  SJR: 'America/Bogota',
  SJS: 'America/La_Paz',
  SJT: 'America/Chicago',
  SJU: 'America/Puerto_Rico',
  SJV: 'America/La_Paz',
  SJW: 'Asia/Shanghai',
  SJX: 'America/Belize',
  SJY: 'Europe/Helsinki',
  SJZ: 'Atlantic/Azores',
  SKA: 'America/Los_Angeles',
  SKB: 'America/St_Kitts',
  SKC: 'Pacific/Port_Moresby',
  SKD: 'Asia/Samarkand',
  SKE: 'Europe/Oslo',
  SKF: 'America/Chicago',
  SKG: 'Europe/Athens',
  SKH: 'Asia/Kathmandu',
  SKI: 'Africa/Algiers',
  SKK: 'America/Anchorage',
  SKL: 'Europe/London',
  SKM: 'America/Paramaribo',
  SKN: 'Europe/Oslo',
  SKO: 'Africa/Lagos',
  SKP: 'Europe/Skopje',
  SKQ: 'Africa/Maseru',
  SKR: 'Africa/Addis_Ababa',
  SKS: 'Europe/Copenhagen',
  SKT: 'Asia/Karachi',
  SKU: 'Europe/Athens',
  SKV: 'Africa/Cairo',
  SKW: 'America/Anchorage',
  SKX: 'Europe/Moscow',
  SKY: 'America/New_York',
  SKZ: 'Asia/Karachi',
  SLA: 'America/Argentina/Salta',
  SLB: 'America/Chicago',
  SLC: 'America/Denver',
  SLD: 'Europe/Bratislava',
  SLE: 'America/Los_Angeles',
  SLF: 'Asia/Riyadh',
  SLG: 'America/Chicago',
  SLH: 'Pacific/Efate',
  SLI: 'Africa/Lusaka',
  SLJ: 'Australia/Perth',
  SLK: 'America/New_York',
  SLL: 'Asia/Muscat',
  SLM: 'Europe/Madrid',
  SLN: 'America/Chicago',
  SLO: 'America/Chicago',
  SLP: 'America/Mexico_City',
  SLQ: 'America/Anchorage',
  SLR: 'America/Chicago',
  SLS: 'Europe/Sofia',
  SLT: 'America/Denver',
  SLU: 'America/St_Lucia',
  SLV: 'Asia/Kolkata',
  SLW: 'America/Monterrey',
  SLX: 'America/Grand_Turk',
  SLY: 'Asia/Yekaterinburg',
  SLZ: 'America/Belem',
  SMA: 'Atlantic/Azores',
  SMB: 'America/Punta_Arenas',
  SMC: 'America/Bogota',
  SMD: 'America/Indiana/Indianapolis',
  SME: 'America/New_York',
  SMF: 'America/Los_Angeles',
  SMG: 'America/Lima',
  SMH: 'Pacific/Port_Moresby',
  SMI: 'Europe/Athens',
  SMJ: 'Pacific/Port_Moresby',
  SMK: 'America/Nome',
  SML: 'America/Nassau',
  SMM: 'Asia/Kuching',
  SMN: 'America/Boise',
  SMO: 'America/Los_Angeles',
  SMP: 'Pacific/Port_Moresby',
  SMQ: 'Asia/Pontianak',
  SMR: 'America/Bogota',
  SMS: 'Indian/Antananarivo',
  SMT: 'America/Cuiaba',
  SMU: 'America/Anchorage',
  SMV: 'Europe/Zurich',
  SMW: 'Africa/Casablanca',
  SMX: 'America/Los_Angeles',
  SMY: 'Africa/Dakar',
  SMZ: 'America/Paramaribo',
  SNA: 'America/Los_Angeles',
  SNB: 'Australia/Darwin',
  SNC: 'America/Guayaquil',
  SNE: 'Atlantic/Cape_Verde',
  SNF: 'America/Caracas',
  SNG: 'America/La_Paz',
  SNH: 'Australia/Brisbane',
  SNI: 'Africa/Monrovia',
  SNJ: 'America/Havana',
  SNK: 'America/Chicago',
  SNL: 'America/Chicago',
  SNM: 'America/La_Paz',
  SNN: 'Europe/Dublin',
  SNO: 'Asia/Bangkok',
  SNP: 'America/Adak',
  SNQ: 'America/Tijuana',
  SNR: 'Europe/Paris',
  SNS: 'America/Los_Angeles',
  SNT: 'America/Bogota',
  SNU: 'America/Havana',
  SNV: 'America/Caracas',
  SNW: 'Asia/Yangon',
  SNX: 'Asia/Tehran',
  SNY: 'America/Denver',
  SNZ: 'America/Sao_Paulo',
  SOA: 'Asia/Ho_Chi_Minh',
  SOB: 'Europe/Budapest',
  SOC: 'Asia/Jakarta',
  SOD: 'America/Sao_Paulo',
  SOE: 'Africa/Brazzaville',
  SOF: 'Europe/Sofia',
  SOG: 'Europe/Oslo',
  SOH: 'America/Bogota',
  SOJ: 'Europe/Oslo',
  SOK: 'Africa/Maseru',
  SOL: 'America/Nome',
  SOM: 'America/Caracas',
  SON: 'Pacific/Efate',
  SOO: 'Europe/Stockholm',
  SOP: 'America/New_York',
  SOQ: 'Asia/Jayapura',
  SOR: 'Asia/Damascus',
  SOT: 'Europe/Helsinki',
  SOU: 'Europe/London',
  SOV: 'America/Anchorage',
  SOW: 'America/Phoenix',
  SOX: 'America/Bogota',
  SOY: 'Europe/London',
  SOZ: 'Europe/Paris',
  SPA: 'America/New_York',
  SPB: 'America/St_Thomas',
  SPC: 'Atlantic/Canary',
  SPD: 'Asia/Dhaka',
  SPE: 'Asia/Kuching',
  SPF: 'America/Denver',
  SPG: 'America/New_York',
  SPH: 'Pacific/Port_Moresby',
  SPI: 'America/Chicago',
  SPJ: 'Europe/Athens',
  SPK: 'Asia/Tokyo',
  SPM: 'Europe/Berlin',
  SPN: 'Pacific/Saipan',
  SPP: 'Africa/Luanda',
  SPR: 'America/Belize',
  SPS: 'America/Chicago',
  SPT: 'Asia/Kuching',
  SPU: 'Europe/Zagreb',
  SPV: 'Pacific/Port_Moresby',
  SPW: 'America/Chicago',
  SPX: 'Africa/Cairo',
  SPY: 'Africa/Abidjan',
  SPZ: 'America/Chicago',
  SQA: 'America/Los_Angeles',
  SQB: 'America/Bogota',
  SQC: 'Australia/Perth',
  SQD: 'Asia/Shanghai',
  SQE: 'America/Bogota',
  SQF: 'America/Bogota',
  SQG: 'Asia/Pontianak',
  SQH: 'Asia/Ho_Chi_Minh',
  SQI: 'America/Chicago',
  SQJ: 'Asia/Shanghai',
  SQK: 'Africa/Cairo',
  SQL: 'America/Los_Angeles',
  SQM: 'America/Sao_Paulo',
  SQN: 'Asia/Jayapura',
  SQO: 'Europe/Stockholm',
  SQQ: 'Europe/Vilnius',
  SQR: 'Asia/Makassar',
  SQS: 'America/Belize',
  SQT: 'Pacific/Port_Moresby',
  SQU: 'America/Lima',
  SQV: 'America/Los_Angeles',
  SQW: 'Europe/Copenhagen',
  SQX: 'America/Sao_Paulo',
  SQY: 'America/Sao_Paulo',
  SQZ: 'Europe/London',
  SRA: 'America/Sao_Paulo',
  SRB: 'America/La_Paz',
  SRC: 'America/Chicago',
  SRD: 'America/La_Paz',
  SRE: 'America/La_Paz',
  SRF: 'America/Los_Angeles',
  SRG: 'Asia/Jakarta',
  SRH: 'Africa/Ndjamena',
  SRI: 'Asia/Makassar',
  SRJ: 'America/La_Paz',
  SRL: 'America/Mazatlan',
  SRM: 'Australia/Brisbane',
  SRN: 'Australia/Hobart',
  SRO: 'America/Bogota',
  SRP: 'Europe/Oslo',
  SRQ: 'America/New_York',
  SRR: 'Australia/Brisbane',
  SRS: 'America/Bogota',
  SRT: 'Africa/Kampala',
  SRV: 'America/Anchorage',
  SRW: 'America/New_York',
  SRX: 'Africa/Tripoli',
  SRY: 'Asia/Tehran',
  SRZ: 'America/La_Paz',
  SSA: 'America/Bahia',
  SSB: 'America/St_Thomas',
  SSC: 'America/New_York',
  SSD: 'America/Bogota',
  SSE: 'Asia/Kolkata',
  SSF: 'America/Chicago',
  SSG: 'Africa/Malabo',
  SSH: 'Africa/Cairo',
  SSI: 'America/New_York',
  SSJ: 'Europe/Oslo',
  SSK: 'Australia/Perth',
  SSL: 'America/Bogota',
  SSM: 'America/Detroit',
  SSN: 'Asia/Seoul',
  SSO: 'America/Sao_Paulo',
  SSP: 'Australia/Brisbane',
  SSQ: 'America/Toronto',
  SSR: 'Pacific/Efate',
  SSS: 'Pacific/Port_Moresby',
  SST: 'America/Argentina/Buenos_Aires',
  SSV: 'Asia/Manila',
  SSW: 'America/Los_Angeles',
  SSX: 'Africa/Johannesburg',
  SSY: 'Africa/Luanda',
  SSZ: 'America/Sao_Paulo',
  STA: 'Europe/Copenhagen',
  STB: 'America/Caracas',
  STC: 'America/Chicago',
  STD: 'America/Caracas',
  STE: 'America/Chicago',
  STF: 'Australia/Brisbane',
  STG: 'America/Adak',
  STH: 'Australia/Brisbane',
  STI: 'America/Santo_Domingo',
  STJ: 'America/Chicago',
  STK: 'America/Denver',
  STL: 'America/Chicago',
  STM: 'America/Manaus',
  STN: 'Europe/London',
  STO: 'Europe/Stockholm',
  STP: 'America/Chicago',
  STQ: 'America/New_York',
  STR: 'Europe/Berlin',
  STS: 'America/Los_Angeles',
  STT: 'America/St_Thomas',
  STV: 'Asia/Kolkata',
  STW: 'Europe/Moscow',
  STX: 'America/St_Thomas',
  STY: 'America/Montevideo',
  STZ: 'America/Cuiaba',
  SUA: 'America/New_York',
  SUB: 'Asia/Jakarta',
  SUD: 'America/Chicago',
  SUE: 'America/Chicago',
  SUF: 'Europe/Rome',
  SUG: 'Asia/Manila',
  SUH: 'Asia/Muscat',
  SUI: 'Asia/Tbilisi',
  SUJ: 'Europe/Bucharest',
  SUK: 'Asia/Yakutsk',
  SUL: 'Asia/Karachi',
  SUM: 'America/New_York',
  SUN: 'America/Boise',
  SUO: 'America/Los_Angeles',
  SUP: 'Asia/Jakarta',
  SUQ: 'America/Guayaquil',
  SUR: 'America/Toronto',
  SUS: 'America/Chicago',
  SUT: 'Africa/Dar_es_Salaam',
  SUU: 'America/Los_Angeles',
  SUV: 'Pacific/Fiji',
  SUW: 'America/Chicago',
  SUX: 'America/Chicago',
  SUY: 'Asia/Yakutsk',
  SUZ: 'Pacific/Port_Moresby',
  SVA: 'America/Nome',
  SVB: 'Indian/Antananarivo',
  SVC: 'America/Denver',
  SVD: 'America/St_Vincent',
  SVE: 'America/Los_Angeles',
  SVF: 'Africa/Porto-Novo',
  SVG: 'Europe/Oslo',
  SVH: 'America/New_York',
  SVI: 'America/Bogota',
  SVJ: 'Europe/Oslo',
  SVK: 'America/Belize',
  SVL: 'Europe/Helsinki',
  SVM: 'Australia/Brisbane',
  SVN: 'America/New_York',
  SVO: 'Europe/Moscow',
  SVP: 'Africa/Luanda',
  SVQ: 'Europe/Madrid',
  SVS: 'America/Anchorage',
  SVT: 'Africa/Gaborone',
  SVU: 'Pacific/Fiji',
  SVV: 'America/Caracas',
  SVW: 'America/Anchorage',
  SVX: 'Asia/Yekaterinburg',
  SVY: 'Pacific/Guadalcanal',
  SVZ: 'America/Caracas',
  SWA: 'Asia/Shanghai',
  SWB: 'Australia/Perth',
  SWC: 'Australia/Melbourne',
  SWD: 'America/Anchorage',
  SWE: 'Pacific/Port_Moresby',
  SWF: 'America/New_York',
  SWG: 'Pacific/Port_Moresby',
  SWH: 'Australia/Melbourne',
  SWJ: 'Pacific/Efate',
  SWL: 'Asia/Manila',
  SWM: 'America/Cuiaba',
  SWN: 'Asia/Karachi',
  SWO: 'America/Chicago',
  SWP: 'Africa/Windhoek',
  SWQ: 'Asia/Makassar',
  SWR: 'Pacific/Port_Moresby',
  SWS: 'Europe/London',
  SWT: 'Asia/Tomsk',
  SWU: 'Asia/Seoul',
  SWV: 'Asia/Magadan',
  SWW: 'America/Chicago',
  SWX: 'Africa/Gaborone',
  SWY: 'Asia/Kuala_Lumpur',
  SXA: 'Pacific/Port_Moresby',
  SXB: 'Europe/Paris',
  SXE: 'Australia/Melbourne',
  SXF: 'Europe/Berlin',
  SXG: 'Africa/Lusaka',
  SXH: 'Pacific/Port_Moresby',
  SXI: 'Asia/Tehran',
  SXJ: 'Asia/Urumqi',
  SXK: 'Asia/Jayapura',
  SXL: 'Europe/Dublin',
  SXM: 'America/Lower_Princes',
  SXN: 'Africa/Gaborone',
  SXO: 'America/Cuiaba',
  SXP: 'America/Nome',
  SXQ: 'America/Anchorage',
  SXR: 'Asia/Kolkata',
  SXS: 'Asia/Kuching',
  SXT: 'Asia/Kuala_Lumpur',
  SXU: 'Africa/Addis_Ababa',
  SXV: 'Asia/Kolkata',
  SXW: 'Pacific/Port_Moresby',
  SXX: 'America/Santarem',
  SXY: 'America/New_York',
  SXZ: 'Europe/Istanbul',
  SYA: 'America/Adak',
  SYB: 'America/Anchorage',
  SYC: 'America/Lima',
  SYD: 'Australia/Sydney',
  SYE: 'Asia/Aden',
  SYF: 'America/Vancouver',
  SYH: 'Asia/Kathmandu',
  SYI: 'America/Chicago',
  SYJ: 'Asia/Tehran',
  SYK: 'Atlantic/Reykjavik',
  SYM: 'Asia/Shanghai',
  SYN: 'America/Chicago',
  SYO: 'Asia/Tokyo',
  SYP: 'America/Panama',
  SYQ: 'America/Costa_Rica',
  SYR: 'America/New_York',
  SYS: 'Asia/Yakutsk',
  SYT: 'Europe/Paris',
  SYU: 'Australia/Brisbane',
  SYV: 'America/New_York',
  SYW: 'Asia/Karachi',
  SYX: 'Asia/Shanghai',
  SYY: 'Europe/London',
  SYZ: 'Asia/Tehran',
  SZA: 'Africa/Luanda',
  SZB: 'Asia/Kuala_Lumpur',
  SZE: 'Africa/Addis_Ababa',
  SZF: 'Europe/Istanbul',
  SZG: 'Europe/Vienna',
  SZI: 'Asia/Almaty',
  SZJ: 'America/Havana',
  SZK: 'Africa/Johannesburg',
  SZL: 'America/Chicago',
  SZM: 'Africa/Windhoek',
  SZN: 'America/Los_Angeles',
  SZP: 'America/Los_Angeles',
  SZR: 'Europe/Sofia',
  SZS: 'Pacific/Auckland',
  SZU: 'Africa/Bamako',
  SZV: 'Asia/Shanghai',
  SZW: 'Europe/Berlin',
  SZX: 'Asia/Shanghai',
  SZY: 'Europe/Warsaw',
  SZZ: 'Europe/Warsaw',
  TAA: 'Pacific/Guadalcanal',
  TAB: 'America/Port_of_Spain',
  TAC: 'Asia/Manila',
  TAD: 'America/Denver',
  TAE: 'Asia/Seoul',
  TAF: 'Africa/Algiers',
  TAG: 'Asia/Manila',
  TAH: 'Pacific/Efate',
  TAI: 'Asia/Aden',
  TAJ: 'Pacific/Port_Moresby',
  TAK: 'Asia/Tokyo',
  TAL: 'America/Anchorage',
  TAM: 'America/Monterrey',
  TAN: 'Australia/Brisbane',
  TAO: 'Asia/Shanghai',
  TAP: 'America/Merida',
  TAQ: 'Australia/Adelaide',
  TAR: 'Europe/Rome',
  TAS: 'Asia/Tashkent',
  TAT: 'Europe/Bratislava',
  TAU: 'America/Bogota',
  TAV: 'Pacific/Pago_Pago',
  TAW: 'America/Montevideo',
  TAX: 'Asia/Jayapura',
  TAY: 'Europe/Tallinn',
  TAZ: 'Asia/Ashgabat',
  TBA: 'Pacific/Port_Moresby',
  TBB: 'Asia/Ho_Chi_Minh',
  TBC: 'America/Denver',
  TBD: 'America/Bogota',
  TBE: 'Pacific/Port_Moresby',
  TBF: 'Pacific/Tarawa',
  TBG: 'Pacific/Port_Moresby',
  TBH: 'Asia/Manila',
  TBI: 'America/Nassau',
  TBJ: 'Africa/Tunis',
  TBK: 'Australia/Darwin',
  TBL: 'Australia/Perth',
  TBM: 'Asia/Pontianak',
  TBN: 'America/Chicago',
  TBO: 'Africa/Dar_es_Salaam',
  TBP: 'America/Lima',
  TBQ: 'Pacific/Port_Moresby',
  TBR: 'America/New_York',
  TBS: 'Asia/Tbilisi',
  TBT: 'America/Porto_Velho',
  TBU: 'Pacific/Tongatapu',
  TBV: 'Pacific/Majuro',
  TBW: 'Europe/Moscow',
  TBY: 'Africa/Gaborone',
  TBZ: 'Asia/Tehran',
  TCA: 'Australia/Darwin',
  TCB: 'America/Nassau',
  TCC: 'America/Denver',
  TCD: 'America/Bogota',
  TCE: 'Europe/Bucharest',
  TCF: 'America/Tegucigalpa',
  TCG: 'Asia/Urumqi',
  TCH: 'Africa/Libreville',
  TCI: 'Atlantic/Canary',
  TCJ: 'Pacific/Port_Moresby',
  TCK: 'Pacific/Port_Moresby',
  TCL: 'America/Chicago',
  TCM: 'America/Los_Angeles',
  TCN: 'America/Mexico_City',
  TCO: 'America/Bogota',
  TCP: 'Africa/Cairo',
  TCQ: 'America/Lima',
  TCR: 'Asia/Kolkata',
  TCS: 'America/Denver',
  TCT: 'America/Anchorage',
  TCU: 'Africa/Johannesburg',
  TCW: 'Australia/Sydney',
  TCX: 'Asia/Tehran',
  TCY: 'Africa/Windhoek',
  TCZ: 'Asia/Shanghai',
  TDA: 'America/Bogota',
  TDB: 'Pacific/Port_Moresby',
  TDD: 'America/La_Paz',
  TDG: 'Asia/Manila',
  TDJ: 'Africa/Djibouti',
  TDK: 'Asia/Almaty',
  TDL: 'America/Argentina/Buenos_Aires',
  TDN: 'Australia/Perth',
  TDO: 'America/Los_Angeles',
  TDP: 'America/Lima',
  TDR: 'Australia/Brisbane',
  TDS: 'Pacific/Port_Moresby',
  TDT: 'Africa/Johannesburg',
  TDV: 'Indian/Antananarivo',
  TDW: 'America/Chicago',
  TDX: 'Asia/Bangkok',
  TDZ: 'America/New_York',
  TEA: 'America/Tegucigalpa',
  TEB: 'America/New_York',
  TEC: 'America/Sao_Paulo',
  TED: 'Europe/Copenhagen',
  TEE: 'Africa/Algiers',
  TEF: 'Australia/Perth',
  TEG: 'Africa/Ouagadougou',
  TEH: 'America/Anchorage',
  TEI: 'Asia/Kolkata',
  TEK: 'America/Anchorage',
  TEL: 'Asia/Kuching',
  TEM: 'Australia/Sydney',
  TEN: 'Asia/Shanghai',
  TEO: 'Pacific/Port_Moresby',
  TEP: 'Pacific/Port_Moresby',
  TEQ: 'Europe/Istanbul',
  TER: 'Atlantic/Azores',
  TES: 'Africa/Asmara',
  TET: 'Africa/Maputo',
  TEU: 'Pacific/Auckland',
  TEV: 'Europe/Madrid',
  TEX: 'America/Denver',
  TEY: 'Atlantic/Reykjavik',
  TEZ: 'Asia/Kolkata',
  TFB: 'Pacific/Port_Moresby',
  TFF: 'America/Manaus',
  TFI: 'Pacific/Port_Moresby',
  TFL: 'America/Sao_Paulo',
  TFM: 'Pacific/Port_Moresby',
  TFN: 'Atlantic/Canary',
  TFR: 'Africa/Cairo',
  TFS: 'Atlantic/Canary',
  TFT: 'Asia/Karachi',
  TFY: 'Asia/Jakarta',
  TGA: 'Asia/Singapore',
  TGB: 'Asia/Manila',
  TGC: 'Asia/Kuching',
  TGD: 'Europe/Podgorica',
  TGE: 'America/Chicago',
  TGG: 'Asia/Kuala_Lumpur',
  TGH: 'Pacific/Efate',
  TGI: 'America/Lima',
  TGJ: 'Pacific/Noumea',
  TGK: 'Europe/Moscow',
  TGL: 'Pacific/Port_Moresby',
  TGM: 'Europe/Bucharest',
  TGN: 'Australia/Melbourne',
  TGO: 'Asia/Shanghai',
  TGP: 'Asia/Krasnoyarsk',
  TGQ: 'America/Cuiaba',
  TGR: 'Africa/Algiers',
  TGS: 'Africa/Maputo',
  TGT: 'Africa/Dar_es_Salaam',
  TGU: 'America/Tegucigalpa',
  TGV: 'Europe/Sofia',
  TGZ: 'America/Mexico_City',
  THA: 'America/Chicago',
  THB: 'Africa/Maseru',
  THC: 'Africa/Monrovia',
  THD: 'Asia/Ho_Chi_Minh',
  THE: 'America/Fortaleza',
  THG: 'Australia/Brisbane',
  THH: 'Pacific/Auckland',
  THI: 'Africa/Nouakchott',
  THK: 'Asia/Vientiane',
  THL: 'Asia/Yangon',
  THM: 'America/Denver',
  THN: 'Europe/Stockholm',
  THO: 'Atlantic/Reykjavik',
  THP: 'America/Denver',
  THQ: 'Asia/Shanghai',
  THR: 'Asia/Tehran',
  THS: 'Asia/Bangkok',
  THT: 'Africa/Nouakchott',
  THU: 'America/Thule',
  THV: 'America/New_York',
  THW: 'Asia/Colombo',
  THX: 'Asia/Krasnoyarsk',
  THY: 'Africa/Johannesburg',
  THZ: 'Africa/Niamey',
  TIA: 'Europe/Tirane',
  TIB: 'America/Bogota',
  TIC: 'Pacific/Majuro',
  TID: 'Africa/Algiers',
  TIE: 'Africa/Addis_Ababa',
  TIF: 'Asia/Riyadh',
  TIG: 'Pacific/Port_Moresby',
  TIH: 'Pacific/Tahiti',
  TII: 'Asia/Kabul',
  TIJ: 'America/Tijuana',
  TIK: 'America/Chicago',
  TIL: 'America/Edmonton',
  TIM: 'Asia/Jayapura',
  TIN: 'Africa/Algiers',
  TIO: 'Asia/Yangon',
  TIP: 'Africa/Tripoli',
  TIQ: 'Pacific/Saipan',
  TIR: 'Asia/Kolkata',
  TIU: 'Pacific/Auckland',
  TIV: 'Europe/Podgorica',
  TIW: 'America/Los_Angeles',
  TIX: 'America/New_York',
  TIY: 'Africa/Nouakchott',
  TIZ: 'Pacific/Port_Moresby',
  TJA: 'America/La_Paz',
  TJB: 'Asia/Jakarta',
  TJC: 'America/Panama',
  TJG: 'Asia/Jakarta',
  TJH: 'Asia/Tokyo',
  TJI: 'America/Tegucigalpa',
  TJK: 'Europe/Istanbul',
  TJL: 'America/Campo_Grande',
  TJM: 'Asia/Yekaterinburg',
  TJN: 'Pacific/Tahiti',
  TJQ: 'Asia/Jakarta',
  TJS: 'Asia/Makassar',
  TJU: 'Asia/Dushanbe',
  TJV: 'Asia/Kolkata',
  TKA: 'America/Anchorage',
  TKB: 'Pacific/Port_Moresby',
  TKC: 'Africa/Douala',
  TKD: 'Africa/Accra',
  TKE: 'America/Juneau',
  TKF: 'America/Los_Angeles',
  TKG: 'Asia/Jakarta',
  TKH: 'Asia/Bangkok',
  TKI: 'America/Metlakatla',
  TKJ: 'America/Anchorage',
  TKK: 'Pacific/Chuuk',
  TKL: 'America/Juneau',
  TKN: 'Asia/Tokyo',
  TKO: 'Africa/Maseru',
  TKP: 'Pacific/Tahiti',
  TKQ: 'Africa/Dar_es_Salaam',
  TKR: 'Asia/Dhaka',
  TKS: 'Asia/Tokyo',
  TKT: 'Asia/Bangkok',
  TKU: 'Europe/Helsinki',
  TKV: 'Pacific/Tahiti',
  TKW: 'Pacific/Port_Moresby',
  TKX: 'Pacific/Tahiti',
  TKY: 'Australia/Perth',
  TKZ: 'Pacific/Auckland',
  TLA: 'America/Nome',
  TLB: 'Asia/Karachi',
  TLC: 'America/Mexico_City',
  TLD: 'Africa/Gaborone',
  TLE: 'Indian/Antananarivo',
  TLF: 'America/Anchorage',
  TLG: 'Pacific/Guadalcanal',
  TLH: 'America/New_York',
  TLI: 'Asia/Makassar',
  TLJ: 'America/Anchorage',
  TLK: 'Asia/Yakutsk',
  TLL: 'Europe/Tallinn',
  TLM: 'Africa/Algiers',
  TLN: 'Europe/Paris',
  TLO: 'Pacific/Port_Moresby',
  TLP: 'Pacific/Port_Moresby',
  TLQ: 'Asia/Urumqi',
  TLR: 'America/Los_Angeles',
  TLS: 'Europe/Paris',
  TLT: 'America/Anchorage',
  TLU: 'America/Bogota',
  TLV: 'Asia/Jerusalem',
  TLW: 'Pacific/Port_Moresby',
  TLX: 'America/Santiago',
  TLY: 'Asia/Vladivostok',
  TLZ: 'America/Sao_Paulo',
  TMA: 'America/New_York',
  TMB: 'America/New_York',
  TMC: 'Asia/Makassar',
  TMD: 'Africa/Nouakchott',
  TME: 'America/Bogota',
  TMF: 'Indian/Maldives',
  TMG: 'Asia/Kuching',
  TMH: 'Asia/Jayapura',
  TMI: 'Asia/Kathmandu',
  TMJ: 'Asia/Samarkand',
  TMK: 'Asia/Ho_Chi_Minh',
  TML: 'Africa/Accra',
  TMM: 'Indian/Antananarivo',
  TMN: 'Pacific/Tarawa',
  TMO: 'America/Caracas',
  TMP: 'Europe/Helsinki',
  TMQ: 'Africa/Ouagadougou',
  TMR: 'Africa/Algiers',
  TMS: 'Africa/Sao_Tome',
  TMT: 'America/Santarem',
  TMU: 'America/Costa_Rica',
  TMW: 'Australia/Sydney',
  TMX: 'Africa/Algiers',
  TMY: 'Asia/Jayapura',
  TMZ: 'Pacific/Auckland',
  TNA: 'Asia/Shanghai',
  TNB: 'Asia/Makassar',
  TNC: 'America/Nome',
  TND: 'America/Havana',
  TNE: 'Asia/Tokyo',
  TNF: 'Europe/Paris',
  TNG: 'Africa/Casablanca',
  TNH: 'Asia/Shanghai',
  TNI: 'Asia/Kolkata',
  TNJ: 'Asia/Pontianak',
  TNK: 'America/Nome',
  TNL: 'Europe/Uzhgorod',
  TNM: 'Antarctica/Palmer',
  TNN: 'Asia/Taipei',
  TNO: 'America/Costa_Rica',
  TNP: 'America/Los_Angeles',
  TNQ: 'Pacific/Kiritimati',
  TNR: 'Indian/Antananarivo',
  TNS: 'America/Yellowknife',
  TNT: 'America/New_York',
  TNU: 'America/Chicago',
  TNV: 'Pacific/Kiritimati',
  TNW: 'America/Guayaquil',
  TNX: 'Asia/Phnom_Penh',
  TNZ: 'Asia/Hovd',
  TOA: 'America/Los_Angeles',
  TOB: 'Africa/Tripoli',
  TOC: 'America/New_York',
  TOD: 'Asia/Kuala_Lumpur',
  TOE: 'Africa/Tunis',
  TOF: 'Asia/Tomsk',
  TOG: 'America/Anchorage',
  TOH: 'Pacific/Efate',
  TOI: 'America/Chicago',
  TOJ: 'Europe/Madrid',
  TOK: 'Pacific/Port_Moresby',
  TOL: 'America/New_York',
  TOM: 'Africa/Bamako',
  TON: 'Pacific/Bougainville',
  TOO: 'America/Costa_Rica',
  TOP: 'America/Chicago',
  TOQ: 'America/Santiago',
  TOR: 'America/Denver',
  TOS: 'Europe/Oslo',
  TOT: 'America/Paramaribo',
  TOU: 'Pacific/Noumea',
  TOV: 'America/Tortola',
  TOW: 'America/Sao_Paulo',
  TOX: 'Asia/Yekaterinburg',
  TOY: 'Asia/Tokyo',
  TOZ: 'Africa/Abidjan',
  TPA: 'America/New_York',
  TPC: 'America/Guayaquil',
  TPE: 'Asia/Taipei',
  TPF: 'America/New_York',
  TPG: 'Asia/Kuala_Lumpur',
  TPH: 'America/Los_Angeles',
  TPI: 'Pacific/Port_Moresby',
  TPJ: 'Asia/Kathmandu',
  TPK: 'Asia/Jakarta',
  TPL: 'America/Chicago',
  TPN: 'America/Guayaquil',
  TPP: 'America/Lima',
  TPQ: 'America/Mazatlan',
  TPR: 'Australia/Perth',
  TPS: 'Europe/Rome',
  TPT: 'Africa/Monrovia',
  TPU: 'Asia/Kathmandu',
  TPX: 'Pacific/Tahiti',
  TQD: 'Asia/Baghdad',
  TQL: 'Asia/Yekaterinburg',
  TQN: 'Asia/Kabul',
  TQP: 'Australia/Brisbane',
  TQQ: 'Asia/Makassar',
  TQS: 'America/Bogota',
  TRA: 'Asia/Tokyo',
  TRB: 'America/Bogota',
  TRC: 'America/Monterrey',
  TRD: 'Europe/Oslo',
  TRE: 'Europe/London',
  TRF: 'Europe/Oslo',
  TRG: 'Pacific/Auckland',
  TRH: 'America/Los_Angeles',
  TRI: 'America/New_York',
  TRJ: 'Pacific/Port_Moresby',
  TRK: 'Asia/Makassar',
  TRL: 'America/Chicago',
  TRM: 'America/Los_Angeles',
  TRN: 'Europe/Rome',
  TRO: 'Australia/Sydney',
  TRQ: 'America/Eirunepe',
  TRR: 'Asia/Colombo',
  TRS: 'Europe/Rome',
  TRU: 'America/Lima',
  TRV: 'Asia/Kolkata',
  TRW: 'Pacific/Tarawa',
  TRX: 'America/Chicago',
  TRY: 'Africa/Kampala',
  TRZ: 'Asia/Kolkata',
  TSA: 'Asia/Taipei',
  TSB: 'Africa/Windhoek',
  TSC: 'America/Guayaquil',
  TSD: 'Africa/Johannesburg',
  TSE: 'Asia/Almaty',
  TSF: 'Europe/Rome',
  TSG: 'America/Anchorage',
  TSH: 'Africa/Lubumbashi',
  TSI: 'Pacific/Port_Moresby',
  TSJ: 'Asia/Tokyo',
  TSK: 'Pacific/Port_Moresby',
  TSL: 'America/Mexico_City',
  TSM: 'America/Denver',
  TSN: 'Asia/Shanghai',
  TSP: 'America/Los_Angeles',
  TSQ: 'America/Sao_Paulo',
  TSR: 'Europe/Bucharest',
  TST: 'Asia/Bangkok',
  TSU: 'Pacific/Tarawa',
  TSV: 'Australia/Brisbane',
  TSW: 'Pacific/Port_Moresby',
  TSX: 'Asia/Makassar',
  TSY: 'Asia/Jakarta',
  TSZ: 'Asia/Ulaanbaatar',
  TTA: 'Africa/Casablanca',
  TTB: 'Europe/Rome',
  TTC: 'America/Santiago',
  TTD: 'America/Los_Angeles',
  TTE: 'Asia/Jayapura',
  TTG: 'America/Argentina/Salta',
  TTH: 'Asia/Muscat',
  TTI: 'Pacific/Tahiti',
  TTJ: 'Asia/Tokyo',
  TTL: 'Pacific/Fiji',
  TTM: 'America/Bogota',
  TTN: 'America/New_York',
  TTO: 'America/Chicago',
  TTQ: 'America/Costa_Rica',
  TTR: 'Asia/Makassar',
  TTS: 'Indian/Antananarivo',
  TTT: 'Asia/Taipei',
  TTU: 'Africa/Casablanca',
  TTW: 'Asia/Colombo',
  TTX: 'Australia/Perth',
  TUA: 'America/Guayaquil',
  TUB: 'Pacific/Tahiti',
  TUC: 'America/Argentina/Tucuman',
  TUD: 'Africa/Dakar',
  TUF: 'Europe/Paris',
  TUG: 'Asia/Manila',
  TUI: 'Asia/Riyadh',
  TUJ: 'Africa/Addis_Ababa',
  TUK: 'Asia/Karachi',
  TUL: 'America/Chicago',
  TUM: 'Australia/Sydney',
  TUN: 'Africa/Tunis',
  TUO: 'Pacific/Auckland',
  TUP: 'America/Chicago',
  TUQ: 'Africa/Ouagadougou',
  TUR: 'America/Belem',
  TUS: 'America/Phoenix',
  TUT: 'Pacific/Port_Moresby',
  TUU: 'Asia/Riyadh',
  TUV: 'America/Caracas',
  TUW: 'America/Panama',
  TUX: 'America/Dawson_Creek',
  TUY: 'America/Cancun',
  TUZ: 'America/Santarem',
  TVA: 'Indian/Antananarivo',
  TVC: 'America/Detroit',
  TVF: 'America/Chicago',
  TVI: 'America/New_York',
  TVL: 'America/Los_Angeles',
  TVS: 'Asia/Shanghai',
  TVU: 'Pacific/Fiji',
  TVY: 'Asia/Yangon',
  TWA: 'America/Anchorage',
  TWB: 'Australia/Brisbane',
  TWC: 'Asia/Shanghai',
  TWD: 'America/Los_Angeles',
  TWE: 'America/Nome',
  TWF: 'America/Boise',
  TWN: 'Australia/Brisbane',
  TWP: 'Australia/Brisbane',
  TWT: 'Asia/Manila',
  TWU: 'Asia/Kuching',
  TWY: 'Pacific/Port_Moresby',
  TWZ: 'Pacific/Auckland',
  TXE: 'Asia/Jakarta',
  TXF: 'America/Bahia',
  TXK: 'America/Chicago',
  TXL: 'Europe/Berlin',
  TXM: 'Asia/Jayapura',
  TXN: 'Asia/Shanghai',
  TXR: 'Australia/Brisbane',
  TXU: 'Africa/Abidjan',
  TYA: 'Europe/Moscow',
  TYB: 'Australia/Sydney',
  TYD: 'Asia/Yakutsk',
  TYE: 'America/Anchorage',
  TYF: 'Europe/Stockholm',
  TYG: 'Australia/Brisbane',
  TYL: 'America/Lima',
  TYM: 'America/Nassau',
  TYN: 'Asia/Shanghai',
  TYO: 'Asia/Tokyo',
  TYP: 'Australia/Darwin',
  TYR: 'America/Chicago',
  TYS: 'America/New_York',
  TYT: 'America/Montevideo',
  TYZ: 'America/Phoenix',
  TZA: 'America/Belize',
  TZC: 'America/Detroit',
  TZL: 'Europe/Sarajevo',
  TZM: 'America/Merida',
  TZN: 'America/Nassau',
  TZO: 'Indian/Antananarivo',
  TZX: 'Europe/Istanbul',
  UAB: 'Europe/Istanbul',
  UAC: 'America/Hermosillo',
  UAE: 'Pacific/Port_Moresby',
  UAH: 'Pacific/Marquesas',
  UAI: 'Asia/Dili',
  UAK: 'America/Godthab',
  UAL: 'Africa/Luanda',
  UAM: 'Pacific/Guam',
  UAP: 'Pacific/Marquesas',
  UAQ: 'America/Argentina/San_Juan',
  UAR: 'Africa/Casablanca',
  UAS: 'Africa/Nairobi',
  UAX: 'America/Guatemala',
  UBA: 'America/Sao_Paulo',
  UBB: 'Australia/Brisbane',
  UBI: 'Pacific/Bougainville',
  UBJ: 'Asia/Tokyo',
  UBN: 'Asia/Ulaanbaatar',
  UBP: 'Asia/Bangkok',
  UBR: 'Asia/Jayapura',
  UBS: 'America/Chicago',
  UBT: 'America/Sao_Paulo',
  UBU: 'Australia/Perth',
  UCB: 'Asia/Shanghai',
  UCC: 'America/Los_Angeles',
  UCE: 'America/Chicago',
  UCK: 'Europe/Uzhgorod',
  UCN: 'Africa/Monrovia',
  UCT: 'Europe/Moscow',
  UCY: 'America/Chicago',
  UDA: 'Australia/Brisbane',
  UDD: 'America/Los_Angeles',
  UDE: 'Europe/Amsterdam',
  UDI: 'America/Sao_Paulo',
  UDJ: 'Europe/Uzhgorod',
  UDN: 'Europe/Rome',
  UDR: 'Asia/Kolkata',
  UEE: 'Australia/Hobart',
  UEL: 'Africa/Maputo',
  UEN: 'Asia/Yekaterinburg',
  UEO: 'Asia/Tokyo',
  UES: 'America/Chicago',
  UET: 'Asia/Karachi',
  UFA: 'Asia/Yekaterinburg',
  UGA: 'Asia/Ulaanbaatar',
  UGB: 'America/Anchorage',
  UGC: 'Asia/Samarkand',
  UGI: 'America/Anchorage',
  UGN: 'America/Chicago',
  UGO: 'Africa/Luanda',
  UGS: 'America/Anchorage',
  UGT: 'Asia/Ulaanbaatar',
  UGU: 'Asia/Jayapura',
  UHE: 'Europe/Prague',
  UIB: 'America/Bogota',
  UIH: 'Asia/Ho_Chi_Minh',
  UII: 'America/Tegucigalpa',
  UIK: 'Asia/Irkutsk',
  UIL: 'America/Los_Angeles',
  UIN: 'America/Chicago',
  UIO: 'America/Guayaquil',
  UIP: 'Europe/Paris',
  UIQ: 'Pacific/Efate',
  UIR: 'Australia/Sydney',
  UIT: 'Pacific/Majuro',
  UJE: 'Pacific/Kwajalein',
  UJN: 'Asia/Seoul',
  UJU: 'Asia/Pyongyang',
  UKA: 'Africa/Nairobi',
  UKB: 'Asia/Tokyo',
  UKG: 'Asia/Vladivostok',
  UKH: 'Asia/Muscat',
  UKI: 'America/Los_Angeles',
  UKK: 'Asia/Almaty',
  UKN: 'America/Chicago',
  UKR: 'Asia/Aden',
  UKS: 'Europe/Simferopol',
  UKT: 'America/New_York',
  UKU: 'Pacific/Port_Moresby',
  UKX: 'Asia/Irkutsk',
  ULA: 'America/Argentina/Rio_Gallegos',
  ULB: 'Pacific/Efate',
  ULD: 'Africa/Johannesburg',
  ULE: 'Pacific/Port_Moresby',
  ULG: 'Asia/Hovd',
  ULH: 'Asia/Riyadh',
  ULI: 'Pacific/Chuuk',
  ULK: 'Asia/Yakutsk',
  ULL: 'Europe/London',
  ULM: 'America/Chicago',
  ULN: 'Asia/Ulaanbaatar',
  ULO: 'Asia/Hovd',
  ULP: 'Australia/Brisbane',
  ULQ: 'America/Bogota',
  ULS: 'America/Bogota',
  ULU: 'Africa/Kampala',
  ULV: 'Europe/Ulyanovsk',
  ULX: 'Africa/Johannesburg',
  ULY: 'Europe/Ulyanovsk',
  ULZ: 'Asia/Hovd',
  UMA: 'America/Havana',
  UMC: 'Pacific/Port_Moresby',
  UME: 'Europe/Stockholm',
  UMI: 'America/Lima',
  UMM: 'America/Anchorage',
  UMR: 'Australia/Adelaide',
  UMS: 'Asia/Vladivostok',
  UMT: 'America/Anchorage',
  UMU: 'America/Sao_Paulo',
  UMY: 'Europe/Kiev',
  UMZ: 'America/Chicago',
  UNA: 'America/Bahia',
  UNC: 'America/Bogota',
  UND: 'Asia/Kabul',
  UNE: 'Africa/Maseru',
  UNG: 'Pacific/Port_Moresby',
  UNI: 'America/St_Vincent',
  UNK: 'America/Anchorage',
  UNN: 'Asia/Bangkok',
  UNR: 'Asia/Ulaanbaatar',
  UNT: 'Europe/London',
  UNU: 'America/Chicago',
  UOA: 'Pacific/Tahiti',
  UOL: 'Asia/Makassar',
  UOS: 'America/Chicago',
  UOX: 'America/Chicago',
  UPB: 'America/Havana',
  UPG: 'Asia/Makassar',
  UPL: 'America/Costa_Rica',
  UPN: 'America/Mexico_City',
  UPP: 'Pacific/Honolulu',
  UPR: 'Pacific/Port_Moresby',
  UPV: 'Europe/London',
  URA: 'Asia/Oral',
  URB: 'America/Sao_Paulo',
  URC: 'Asia/Urumqi',
  URD: 'Europe/Berlin',
  URE: 'Europe/Tallinn',
  URG: 'America/Sao_Paulo',
  URI: 'America/Bogota',
  URJ: 'Asia/Yekaterinburg',
  URM: 'America/Caracas',
  URN: 'Asia/Kabul',
  URO: 'Europe/Paris',
  URR: 'America/Bogota',
  URS: 'Europe/Moscow',
  URT: 'Asia/Bangkok',
  URU: 'Pacific/Port_Moresby',
  URY: 'Asia/Riyadh',
  URZ: 'Asia/Kabul',
  USA: 'America/New_York',
  USC: 'America/New_York',
  USH: 'America/Argentina/Ushuaia',
  USI: 'America/Guyana',
  USJ: 'Asia/Almaty',
  USK: 'Europe/Moscow',
  USL: 'Australia/Perth',
  USM: 'Asia/Bangkok',
  USN: 'Asia/Seoul',
  USO: 'Pacific/Port_Moresby',
  USQ: 'Europe/Istanbul',
  USR: 'Asia/Magadan',
  USS: 'America/Havana',
  UST: 'America/New_York',
  USU: 'Asia/Manila',
  UTA: 'Africa/Harare',
  UTB: 'Australia/Brisbane',
  UTC: 'Europe/Amsterdam',
  UTD: 'Australia/Darwin',
  UTG: 'Africa/Maseru',
  UTH: 'Asia/Bangkok',
  UTI: 'Europe/Helsinki',
  UTK: 'Pacific/Kwajalein',
  UTM: 'America/Chicago',
  UTN: 'Africa/Johannesburg',
  UTO: 'America/Anchorage',
  UTP: 'Asia/Bangkok',
  UTS: 'Europe/Moscow',
  UTT: 'Africa/Johannesburg',
  UTU: 'America/Panama',
  UTW: 'Africa/Johannesburg',
  UUA: 'Europe/Moscow',
  UUD: 'Asia/Irkutsk',
  UUK: 'America/Anchorage',
  UUN: 'Asia/Choibalsan',
  UUS: 'Asia/Sakhalin',
  UUU: 'Pacific/Port_Moresby',
  UVA: 'America/Chicago',
  UVE: 'Pacific/Noumea',
  UVF: 'America/St_Lucia',
  UVL: 'Africa/Cairo',
  UVO: 'Pacific/Port_Moresby',
  UWA: 'America/New_York',
  UYL: 'Africa/Khartoum',
  UYN: 'Asia/Shanghai',
  UYU: 'America/La_Paz',
  UZC: 'Europe/Belgrade',
  UZM: 'America/Cambridge_Bay',
  UZR: 'Asia/Almaty',
  UZU: 'America/Argentina/Cordoba',
  VAA: 'Europe/Helsinki',
  VAB: 'America/Bogota',
  VAC: 'Europe/Berlin',
  VAD: 'America/New_York',
  VAF: 'Europe/Paris',
  VAG: 'America/Sao_Paulo',
  VAH: 'America/La_Paz',
  VAI: 'Pacific/Port_Moresby',
  VAK: 'America/Nome',
  VAL: 'America/Bahia',
  VAM: 'Indian/Maldives',
  VAN: 'Europe/Istanbul',
  VAO: 'Pacific/Guadalcanal',
  VAP: 'America/Santiago',
  VAR: 'Europe/Sofia',
  VAS: 'Europe/Istanbul',
  VAT: 'Indian/Antananarivo',
  VAU: 'Pacific/Fiji',
  VAV: 'Pacific/Tongatapu',
  VAW: 'Europe/Oslo',
  VBA: 'Asia/Yangon',
  VBC: 'Asia/Yangon',
  VBG: 'America/Los_Angeles',
  VBP: 'Asia/Yangon',
  VBS: 'Europe/Rome',
  VBV: 'Pacific/Fiji',
  VBY: 'Europe/Stockholm',
  VCA: 'Asia/Ho_Chi_Minh',
  VCD: 'Australia/Darwin',
  VCE: 'Europe/Rome',
  VCF: 'America/Argentina/Salta',
  VCH: 'America/Montevideo',
  VCL: 'Asia/Ho_Chi_Minh',
  VCP: 'America/Sao_Paulo',
  VCR: 'America/Caracas',
  VCS: 'Asia/Ho_Chi_Minh',
  VCT: 'America/Chicago',
  VCV: 'America/Los_Angeles',
  VDA: 'Asia/Jerusalem',
  VDB: 'Europe/Oslo',
  VDC: 'America/Bahia',
  VDE: 'Atlantic/Canary',
  VDH: 'Asia/Ho_Chi_Minh',
  VDI: 'America/New_York',
  VDM: 'America/Argentina/Salta',
  VDO: 'Asia/Ho_Chi_Minh',
  VDP: 'America/Caracas',
  VDR: 'America/Argentina/Cordoba',
  VDS: 'Europe/Oslo',
  VDY: 'Asia/Kolkata',
  VDZ: 'America/Anchorage',
  VEE: 'America/Anchorage',
  VEG: 'America/Guyana',
  VEL: 'America/Denver',
  VER: 'America/Mexico_City',
  VEV: 'Pacific/Guadalcanal',
  VEX: 'America/Chicago',
  VEY: 'Atlantic/Reykjavik',
  VFA: 'Africa/Harare',
  VGA: 'Asia/Kolkata',
  VGD: 'Europe/Moscow',
  VGO: 'Europe/Madrid',
  VGS: 'America/Argentina/Buenos_Aires',
  VGT: 'America/Los_Angeles',
  VGZ: 'America/Bogota',
  VHC: 'Africa/Luanda',
  VHM: 'Europe/Stockholm',
  VHN: 'America/Chicago',
  VHV: 'Asia/Yakutsk',
  VHY: 'Europe/Paris',
  VHZ: 'Pacific/Tahiti',
  VIA: 'America/Sao_Paulo',
  VIB: 'America/Mazatlan',
  VIC: 'Europe/Rome',
  VIE: 'Europe/Vienna',
  VIG: 'America/Caracas',
  VIH: 'America/Chicago',
  VII: 'Asia/Ho_Chi_Minh',
  VIJ: 'America/Tortola',
  VIL: 'Africa/Casablanca',
  VIN: 'Europe/Kiev',
  VIQ: 'Asia/Dili',
  VIR: 'Africa/Johannesburg',
  VIS: 'America/Los_Angeles',
  VIT: 'Europe/Madrid',
  VIU: 'Pacific/Guadalcanal',
  VIV: 'Pacific/Port_Moresby',
  VIX: 'America/Sao_Paulo',
  VIY: 'Europe/Paris',
  VJB: 'Africa/Maputo',
  VJI: 'America/New_York',
  VJQ: 'Africa/Maputo',
  VKG: 'Asia/Ho_Chi_Minh',
  VKO: 'Europe/Moscow',
  VKS: 'America/Chicago',
  VKT: 'Europe/Moscow',
  VLA: 'America/Chicago',
  VLC: 'Europe/Madrid',
  VLD: 'America/New_York',
  VLE: 'America/Phoenix',
  VLG: 'America/Argentina/Buenos_Aires',
  VLI: 'Pacific/Efate',
  VLK: 'Europe/Moscow',
  VLL: 'Europe/Madrid',
  VLM: 'America/La_Paz',
  VLN: 'America/Caracas',
  VLP: 'America/Cuiaba',
  VLR: 'America/Santiago',
  VLS: 'Pacific/Efate',
  VLU: 'Europe/Moscow',
  VLV: 'America/Caracas',
  VLY: 'Europe/London',
  VME: 'America/Argentina/San_Luis',
  VMI: 'America/Asuncion',
  VMU: 'Pacific/Port_Moresby',
  VNA: 'Asia/Vientiane',
  VNC: 'America/New_York',
  VND: 'Indian/Antananarivo',
  VNE: 'Europe/Paris',
  VNO: 'Europe/Vilnius',
  VNR: 'Australia/Brisbane',
  VNS: 'Asia/Kolkata',
  VNT: 'Europe/Riga',
  VNX: 'Africa/Maputo',
  VNY: 'America/Los_Angeles',
  VOD: 'Europe/Prague',
  VOG: 'Europe/Volgograd',
  VOH: 'Indian/Antananarivo',
  VOI: 'Africa/Monrovia',
  VOK: 'America/Chicago',
  VOL: 'Europe/Athens',
  VOT: 'America/Sao_Paulo',
  VOZ: 'Europe/Moscow',
  VPE: 'Africa/Luanda',
  VPG: 'Africa/Nairobi',
  VPN: 'Atlantic/Reykjavik',
  VPS: 'America/Chicago',
  VPY: 'Africa/Maputo',
  VPZ: 'America/Chicago',
  VQQ: 'America/New_York',
  VQS: 'America/Puerto_Rico',
  VRA: 'America/Havana',
  VRB: 'America/New_York',
  VRC: 'Asia/Manila',
  VRE: 'Africa/Johannesburg',
  VRI: 'Europe/Moscow',
  VRK: 'Europe/Helsinki',
  VRL: 'Europe/Lisbon',
  VRN: 'Europe/Rome',
  VRO: 'America/Havana',
  VRS: 'America/Chicago',
  VRU: 'Africa/Johannesburg',
  VSA: 'America/Merida',
  VSE: 'Europe/Lisbon',
  VSF: 'America/New_York',
  VSG: 'Europe/Zaporozhye',
  VSO: 'Asia/Ho_Chi_Minh',
  VST: 'Europe/Stockholm',
  VTB: 'Europe/Minsk',
  VTE: 'Asia/Vientiane',
  VTF: 'Pacific/Fiji',
  VTG: 'Asia/Ho_Chi_Minh',
  VTL: 'Europe/Paris',
  VTM: 'Asia/Jerusalem',
  VTN: 'America/Chicago',
  VTU: 'America/Havana',
  VTZ: 'Asia/Kolkata',
  VUP: 'America/Bogota',
  VUS: 'Europe/Moscow',
  VUU: 'Africa/Blantyre',
  VVB: 'Indian/Antananarivo',
  VVC: 'America/Bogota',
  VVI: 'America/La_Paz',
  VVK: 'Europe/Stockholm',
  VVN: 'America/Lima',
  VVO: 'Asia/Vladivostok',
  VVZ: 'Africa/Algiers',
  VXC: 'Africa/Maputo',
  VXE: 'Atlantic/Cape_Verde',
  VXO: 'Europe/Stockholm',
  VYD: 'Africa/Johannesburg',
  VYI: 'Asia/Yakutsk',
  VYS: 'America/Chicago',
  WAA: 'America/Nome',
  WAB: 'Pacific/Port_Moresby',
  WAC: 'Africa/Addis_Ababa',
  WAD: 'Indian/Antananarivo',
  WAE: 'Asia/Riyadh',
  WAF: 'Asia/Karachi',
  WAG: 'Pacific/Auckland',
  WAH: 'America/Chicago',
  WAI: 'Indian/Antananarivo',
  WAJ: 'Pacific/Port_Moresby',
  WAK: 'Indian/Antananarivo',
  WAL: 'America/New_York',
  WAM: 'Indian/Antananarivo',
  WAO: 'Pacific/Port_Moresby',
  WAP: 'America/Santiago',
  WAQ: 'Indian/Antananarivo',
  WAR: 'Asia/Jayapura',
  WAS: 'America/New_York',
  WAT: 'Europe/Dublin',
  WAU: 'Australia/Sydney',
  WAV: 'Australia/Darwin',
  WAW: 'Europe/Warsaw',
  WAX: 'Africa/Tripoli',
  WAY: 'America/New_York',
  WAZ: 'Australia/Brisbane',
  WBA: 'Asia/Jayapura',
  WBB: 'America/Nome',
  WBC: 'Pacific/Port_Moresby',
  WBD: 'Indian/Antananarivo',
  WBE: 'Indian/Antananarivo',
  WBG: 'Europe/Berlin',
  WBK: 'America/Detroit',
  WBM: 'Pacific/Port_Moresby',
  WBO: 'Indian/Antananarivo',
  WBQ: 'America/Anchorage',
  WBR: 'America/Detroit',
  WBU: 'America/Denver',
  WBW: 'America/New_York',
  WCA: 'America/Santiago',
  WCD: 'Australia/Perth',
  WCH: 'America/Santiago',
  WCR: 'America/Anchorage',
  WDA: 'Asia/Aden',
  WDG: 'America/Chicago',
  WDH: 'Africa/Windhoek',
  WDI: 'Australia/Brisbane',
  WDN: 'America/Los_Angeles',
  WDR: 'America/New_York',
  WDS: 'Asia/Shanghai',
  WEA: 'America/Chicago',
  WED: 'Pacific/Port_Moresby',
  WEF: 'Asia/Shanghai',
  WEH: 'Asia/Shanghai',
  WEI: 'Australia/Brisbane',
  WEL: 'Africa/Johannesburg',
  WEP: 'Pacific/Port_Moresby',
  WES: 'Africa/Monrovia',
  WET: 'Asia/Jayapura',
  WEW: 'Australia/Sydney',
  WFB: 'America/Sitka',
  WFD: 'Europe/London',
  WFI: 'Indian/Antananarivo',
  WFK: 'America/New_York',
  WGA: 'Australia/Sydney',
  WGC: 'Asia/Kolkata',
  WGE: 'Australia/Sydney',
  WGN: 'Asia/Shanghai',
  WGO: 'America/New_York',
  WGP: 'Asia/Makassar',
  WGT: 'Australia/Melbourne',
  WGU: 'Pacific/Port_Moresby',
  WGY: 'Africa/Libreville',
  WHD: 'America/Metlakatla',
  WHF: 'Africa/Khartoum',
  WHK: 'Pacific/Auckland',
  WHL: 'Australia/Melbourne',
  WHO: 'Pacific/Auckland',
  WHP: 'America/Los_Angeles',
  WHS: 'Europe/London',
  WHT: 'America/Chicago',
  WHU: 'Asia/Shanghai',
  WIB: 'America/Chicago',
  WIC: 'Europe/London',
  WIE: 'Europe/Berlin',
  WIK: 'Pacific/Auckland',
  WIL: 'Africa/Nairobi',
  WIN: 'Australia/Brisbane',
  WIO: 'Australia/Sydney',
  WIR: 'Pacific/Auckland',
  WIT: 'Australia/Perth',
  WIU: 'Pacific/Port_Moresby',
  WJA: 'Pacific/Kwajalein',
  WJF: 'America/Los_Angeles',
  WJR: 'Africa/Nairobi',
  WJU: 'Asia/Seoul',
  WKA: 'Pacific/Auckland',
  WKB: 'Australia/Melbourne',
  WKF: 'Africa/Johannesburg',
  WKI: 'Africa/Harare',
  WKJ: 'Asia/Tokyo',
  WKK: 'America/Anchorage',
  WKN: 'Pacific/Port_Moresby',
  WKR: 'America/Nassau',
  WLA: 'Australia/Perth',
  WLC: 'Australia/Sydney',
  WLD: 'America/Chicago',
  WLE: 'Australia/Brisbane',
  WLG: 'Pacific/Auckland',
  WLH: 'Pacific/Efate',
  WLK: 'America/Anchorage',
  WLL: 'Australia/Darwin',
  WLO: 'Australia/Darwin',
  WLP: 'Australia/Perth',
  WLR: 'America/Sitka',
  WLS: 'Pacific/Wallis',
  WLW: 'America/Los_Angeles',
  WMA: 'Indian/Antananarivo',
  WMB: 'Australia/Melbourne',
  WMC: 'America/Los_Angeles',
  WMD: 'Indian/Antananarivo',
  WME: 'Australia/Perth',
  WMH: 'America/Chicago',
  WMI: 'Europe/Warsaw',
  WMK: 'America/Juneau',
  WML: 'Indian/Antananarivo',
  WMN: 'Indian/Antananarivo',
  WMO: 'America/Nome',
  WMP: 'Indian/Antananarivo',
  WMR: 'Indian/Antananarivo',
  WMT: 'Asia/Shanghai',
  WMV: 'Indian/Antananarivo',
  WMX: 'Asia/Jayapura',
  WNA: 'America/Anchorage',
  WND: 'Australia/Perth',
  WNE: 'Africa/Libreville',
  WNH: 'Asia/Shanghai',
  WNI: 'Asia/Makassar',
  WNN: 'America/Winnipeg',
  WNP: 'Asia/Manila',
  WNR: 'Australia/Brisbane',
  WNS: 'Asia/Karachi',
  WNU: 'Pacific/Port_Moresby',
  WNZ: 'Asia/Shanghai',
  WOA: 'Pacific/Port_Moresby',
  WOE: 'Europe/Amsterdam',
  WOG: 'Australia/Darwin',
  WOK: 'America/Caracas',
  WOL: 'Australia/Sydney',
  WON: 'Australia/Brisbane',
  WOR: 'Indian/Antananarivo',
  WOS: 'Asia/Pyongyang',
  WOT: 'Asia/Taipei',
  WOW: 'America/Anchorage',
  WPA: 'America/Santiago',
  WPB: 'Indian/Antananarivo',
  WPC: 'America/Edmonton',
  WPK: 'Australia/Brisbane',
  WPL: 'America/Vancouver',
  WPM: 'Pacific/Port_Moresby',
  WPO: 'America/Denver',
  WPR: 'America/Punta_Arenas',
  WPU: 'America/Punta_Arenas',
  WRA: 'Africa/Addis_Ababa',
  WRB: 'America/New_York',
  WRE: 'Pacific/Auckland',
  WRG: 'America/Sitka',
  WRI: 'America/New_York',
  WRL: 'America/Denver',
  WRN: 'Australia/Perth',
  WRO: 'Europe/Warsaw',
  WRT: 'Europe/London',
  WRW: 'Australia/Perth',
  WRY: 'Europe/London',
  WRZ: 'Asia/Colombo',
  WSA: 'Pacific/Port_Moresby',
  WSB: 'America/Metlakatla',
  WSD: 'America/Denver',
  WSF: 'America/Anchorage',
  WSG: 'America/New_York',
  WSH: 'America/New_York',
  WSK: 'Asia/Shanghai',
  WSM: 'America/Anchorage',
  WSN: 'America/Anchorage',
  WSO: 'America/Paramaribo',
  WSP: 'America/Managua',
  WSR: 'Asia/Jayapura',
  WST: 'America/New_York',
  WSU: 'Pacific/Port_Moresby',
  WSX: 'America/Los_Angeles',
  WSY: 'Australia/Brisbane',
  WSZ: 'Pacific/Auckland',
  WTA: 'Indian/Antananarivo',
  WTB: 'Australia/Brisbane',
  WTD: 'America/Nassau',
  WTE: 'Pacific/Majuro',
  WTK: 'America/Nome',
  WTL: 'America/Nome',
  WTN: 'Europe/London',
  WTO: 'Pacific/Kwajalein',
  WTP: 'Pacific/Port_Moresby',
  WTR: 'America/Phoenix',
  WTS: 'Indian/Antananarivo',
  WTT: 'Pacific/Port_Moresby',
  WTZ: 'Pacific/Auckland',
  WUA: 'Asia/Shanghai',
  WUD: 'Australia/Adelaide',
  WUG: 'Pacific/Port_Moresby',
  WUH: 'Asia/Shanghai',
  WUI: 'Australia/Perth',
  WUM: 'Pacific/Port_Moresby',
  WUN: 'Australia/Perth',
  WUS: 'Asia/Shanghai',
  WUT: 'Asia/Shanghai',
  WUU: 'Africa/Juba',
  WUV: 'Pacific/Port_Moresby',
  WUX: 'Asia/Shanghai',
  WUZ: 'Asia/Shanghai',
  WVB: 'Africa/Windhoek',
  WVI: 'America/Los_Angeles',
  WVK: 'Indian/Antananarivo',
  WVL: 'America/New_York',
  WVN: 'Europe/Berlin',
  WWA: 'America/Anchorage',
  WWD: 'America/New_York',
  WWI: 'Australia/Perth',
  WWK: 'Pacific/Port_Moresby',
  WWP: 'America/Metlakatla',
  WWR: 'America/Chicago',
  WWT: 'America/Nome',
  WWY: 'Australia/Sydney',
  WXN: 'Asia/Shanghai',
  WYA: 'Australia/Adelaide',
  WYB: 'America/Sitka',
  WYE: 'Africa/Freetown',
  WYN: 'Australia/Perth',
  WYS: 'America/Denver',
  WZQ: 'Asia/Shanghai',
  XAI: 'Asia/Shanghai',
  XAL: 'America/Hermosillo',
  XAP: 'America/Sao_Paulo',
  XAR: 'Africa/Ouagadougou',
  XAU: 'America/Cayenne',
  XBB: 'America/Vancouver',
  XBE: 'America/Winnipeg',
  XBG: 'Africa/Ouagadougou',
  XBJ: 'Asia/Tehran',
  XBL: 'Africa/Addis_Ababa',
  XBN: 'Pacific/Port_Moresby',
  XBO: 'Africa/Ouagadougou',
  XBR: 'America/Toronto',
  XCH: 'Indian/Christmas',
  XCL: 'America/Regina',
  XCM: 'America/Toronto',
  XCN: 'Asia/Manila',
  XCO: 'Australia/Melbourne',
  XCR: 'Europe/Paris',
  XDE: 'Africa/Ouagadougou',
  XDJ: 'Africa/Ouagadougou',
  XEN: 'Asia/Shanghai',
  XES: 'America/Chicago',
  XFN: 'Asia/Shanghai',
  XFW: 'Europe/Berlin',
  XGA: 'Africa/Ouagadougou',
  XGG: 'Africa/Ouagadougou',
  XGN: 'Africa/Luanda',
  XGR: 'America/Iqaluit',
  XIC: 'Asia/Shanghai',
  XIE: 'Asia/Vientiane',
  XIG: 'America/Belem',
  XIJ: 'Asia/Kuwait',
  XIL: 'Asia/Shanghai',
  XIN: 'Asia/Shanghai',
  XIY: 'Asia/Shanghai',
  XJD: 'Asia/Qatar',
  XJM: 'Asia/Karachi',
  XKA: 'Africa/Ouagadougou',
  XKH: 'Asia/Vientiane',
  XKS: 'America/Winnipeg',
  XKY: 'Africa/Ouagadougou',
  XLB: 'America/Winnipeg',
  XLO: 'Asia/Ho_Chi_Minh',
  XLS: 'Africa/Dakar',
  XLU: 'Africa/Ouagadougou',
  XLW: 'Europe/Berlin',
  XMA: 'Asia/Manila',
  XMC: 'Australia/Melbourne',
  XMD: 'America/Chicago',
  XMG: 'Asia/Kathmandu',
  XMH: 'Pacific/Tahiti',
  XMI: 'Africa/Dar_es_Salaam',
  XML: 'Australia/Adelaide',
  XMN: 'Asia/Shanghai',
  XMP: 'America/Whitehorse',
  XMS: 'America/Guayaquil',
  XMY: 'Australia/Lindeman',
  XNA: 'America/Chicago',
  XNB: 'Asia/Dubai',
  XNG: 'Asia/Ho_Chi_Minh',
  XNH: 'Asia/Baghdad',
  XNN: 'Asia/Shanghai',
  XNT: 'Asia/Shanghai',
  XNU: 'Africa/Ouagadougou',
  XPA: 'Africa/Ouagadougou',
  XPK: 'America/Winnipeg',
  XPL: 'America/Tegucigalpa',
  XPP: 'America/Winnipeg',
  XPR: 'America/Denver',
  XQP: 'America/Costa_Rica',
  XQU: 'America/Vancouver',
  XRH: 'Australia/Sydney',
  XRQ: 'Asia/Shanghai',
  XRR: 'America/Whitehorse',
  XRY: 'Europe/Madrid',
  XSB: 'Asia/Dubai',
  XSC: 'America/Grand_Turk',
  XSD: 'America/Los_Angeles',
  XSE: 'Africa/Ouagadougou',
  XSI: 'America/Winnipeg',
  XSO: 'Asia/Manila',
  XSP: 'Asia/Singapore',
  XTG: 'Australia/Brisbane',
  XTL: 'America/Winnipeg',
  XTO: 'Australia/Brisbane',
  XTR: 'Australia/Brisbane',
  XUZ: 'Asia/Shanghai',
  XVL: 'Asia/Ho_Chi_Minh',
  XYA: 'Pacific/Guadalcanal',
  XYE: 'Asia/Yangon',
  XYR: 'Pacific/Port_Moresby',
  XZA: 'Africa/Ouagadougou',
  YAA: 'America/Vancouver',
  YAB: 'America/Rankin_Inlet',
  YAC: 'America/Winnipeg',
  YAD: 'America/Winnipeg',
  YAG: 'America/Winnipeg',
  YAH: 'America/Toronto',
  YAI: 'America/Santiago',
  YAJ: 'America/Vancouver',
  YAK: 'America/Yakutat',
  YAL: 'America/Vancouver',
  YAM: 'America/Toronto',
  YAN: 'Africa/Lubumbashi',
  YAO: 'Africa/Douala',
  YAP: 'Pacific/Chuuk',
  YAQ: 'America/Vancouver',
  YAR: 'America/Toronto',
  YAS: 'Pacific/Fiji',
  YAT: 'America/Toronto',
  YAU: 'America/Toronto',
  YAV: 'America/Vancouver',
  YAX: 'America/Winnipeg',
  YAY: 'America/St_Johns',
  YAZ: 'America/Vancouver',
  YBA: 'America/Edmonton',
  YBB: 'America/Cambridge_Bay',
  YBC: 'America/Toronto',
  YBE: 'America/Regina',
  YBF: 'America/Vancouver',
  YBG: 'America/Toronto',
  YBH: 'America/Vancouver',
  YBI: 'America/St_Johns',
  YBJ: 'America/Toronto',
  YBK: 'America/Rankin_Inlet',
  YBL: 'America/Vancouver',
  YBO: 'America/Vancouver',
  YBP: 'Asia/Shanghai',
  YBQ: 'America/Vancouver',
  YBR: 'America/Winnipeg',
  YBS: 'America/Winnipeg',
  YBT: 'America/Winnipeg',
  YBV: 'America/Winnipeg',
  YBW: 'America/Vancouver',
  YBX: 'America/Blanc-Sablon',
  YBY: 'America/Edmonton',
  YCA: 'America/Vancouver',
  YCB: 'America/Cambridge_Bay',
  YCC: 'America/Toronto',
  YCD: 'America/Vancouver',
  YCE: 'America/Toronto',
  YCF: 'America/Vancouver',
  YCG: 'America/Vancouver',
  YCH: 'America/Moncton',
  YCK: 'America/Yellowknife',
  YCL: 'America/Moncton',
  YCM: 'America/Toronto',
  YCN: 'America/Toronto',
  YCO: 'America/Cambridge_Bay',
  YCQ: 'America/Dawson_Creek',
  YCR: 'America/Winnipeg',
  YCS: 'America/Rankin_Inlet',
  YCT: 'America/Edmonton',
  YCU: 'Asia/Shanghai',
  YCW: 'America/Vancouver',
  YCY: 'America/Iqaluit',
  YCZ: 'America/Edmonton',
  YDA: 'America/Whitehorse',
  YDB: 'America/Whitehorse',
  YDC: 'America/Edmonton',
  YDE: 'America/St_Johns',
  YDF: 'America/St_Johns',
  YDG: 'America/Halifax',
  YDJ: 'America/Regina',
  YDL: 'America/Vancouver',
  YDN: 'America/Winnipeg',
  YDO: 'America/Toronto',
  YDP: 'America/Goose_Bay',
  YDQ: 'America/Dawson_Creek',
  YDT: 'America/Vancouver',
  YDU: 'America/Yellowknife',
  YDV: 'America/Winnipeg',
  YDW: 'America/Yellowknife',
  YEA: 'America/Edmonton',
  YEB: 'America/Toronto',
  YEC: 'Asia/Seoul',
  YEG: 'America/Edmonton',
  YEH: 'Asia/Shanghai',
  YEI: 'Europe/Istanbul',
  YEK: 'America/Rankin_Inlet',
  YEL: 'America/Toronto',
  YEM: 'America/Toronto',
  YEN: 'America/Regina',
  YEO: 'Europe/London',
  YEQ: 'Pacific/Port_Moresby',
  YER: 'America/Toronto',
  YES: 'Asia/Tehran',
  YET: 'America/Edmonton',
  YEU: 'America/Rankin_Inlet',
  YEV: 'America/Inuvik',
  YEY: 'America/Toronto',
  YFA: 'America/Toronto',
  YFB: 'America/Iqaluit',
  YFC: 'America/Moncton',
  YFE: 'America/Toronto',
  YFG: 'America/Toronto',
  YFH: 'America/Toronto',
  YFI: 'America/Edmonton',
  YFJ: 'America/Yellowknife',
  YFL: 'America/Yellowknife',
  YFO: 'America/Winnipeg',
  YFR: 'America/Yellowknife',
  YFS: 'America/Yellowknife',
  YFX: 'America/St_Johns',
  YGB: 'America/Vancouver',
  YGC: 'America/Vancouver',
  YGE: 'America/Vancouver',
  YGG: 'America/Vancouver',
  YGH: 'America/Yellowknife',
  YGJ: 'Asia/Tokyo',
  YGK: 'America/Toronto',
  YGL: 'America/Toronto',
  YGM: 'America/Winnipeg',
  YGN: 'America/Vancouver',
  YGO: 'America/Winnipeg',
  YGP: 'America/Toronto',
  YGQ: 'America/Toronto',
  YGR: 'America/Halifax',
  YGT: 'America/Iqaluit',
  YGV: 'America/Toronto',
  YGW: 'America/Toronto',
  YGX: 'America/Winnipeg',
  YGZ: 'America/Iqaluit',
  YHA: 'America/St_Johns',
  YHB: 'America/Regina',
  YHC: 'America/Vancouver',
  YHD: 'America/Winnipeg',
  YHE: 'America/Vancouver',
  YHF: 'America/Toronto',
  YHG: 'America/St_Johns',
  YHH: 'America/Vancouver',
  YHI: 'America/Yellowknife',
  YHK: 'America/Cambridge_Bay',
  YHM: 'America/Toronto',
  YHN: 'America/Toronto',
  YHO: 'America/Goose_Bay',
  YHP: 'America/Winnipeg',
  YHR: 'America/Blanc-Sablon',
  YHS: 'America/Vancouver',
  YHT: 'America/Whitehorse',
  YHU: 'America/Toronto',
  YHY: 'America/Yellowknife',
  YHZ: 'America/Halifax',
  YIA: 'Asia/Jakarta',
  YIB: 'America/Atikokan',
  YIC: 'Asia/Shanghai',
  YIE: 'Asia/Shanghai',
  YIF: 'America/Blanc-Sablon',
  YIG: 'America/Vancouver',
  YIH: 'Asia/Shanghai',
  YIK: 'America/Toronto',
  YIN: 'Asia/Shanghai',
  YIO: 'America/Iqaluit',
  YIP: 'America/Detroit',
  YIV: 'America/Winnipeg',
  YIW: 'Asia/Shanghai',
  YJA: 'America/Edmonton',
  YJF: 'America/Yellowknife',
  YJN: 'America/Toronto',
  YJP: 'America/Edmonton',
  YJS: 'Asia/Pyongyang',
  YJT: 'America/St_Johns',
  YKA: 'America/Vancouver',
  YKC: 'America/Regina',
  YKD: 'America/Toronto',
  YKE: 'America/Winnipeg',
  YKF: 'America/Toronto',
  YKG: 'America/Toronto',
  YKH: 'Asia/Shanghai',
  YKJ: 'America/Regina',
  YKK: 'America/Vancouver',
  YKL: 'America/Toronto',
  YKM: 'America/Los_Angeles',
  YKN: 'America/Chicago',
  YKO: 'Europe/Istanbul',
  YKQ: 'America/Toronto',
  YKS: 'Asia/Yakutsk',
  YKT: 'America/Vancouver',
  YKU: 'America/Toronto',
  YKX: 'America/Toronto',
  YKY: 'America/Regina',
  YKZ: 'America/Toronto',
  YLB: 'America/Edmonton',
  YLC: 'America/Iqaluit',
  YLD: 'America/Toronto',
  YLE: 'America/Yellowknife',
  YLG: 'Australia/Perth',
  YLH: 'America/Toronto',
  YLI: 'Europe/Helsinki',
  YLJ: 'America/Regina',
  YLK: 'America/Toronto',
  YLL: 'America/Edmonton',
  YLM: 'America/Whitehorse',
  YLN: 'Asia/Shanghai',
  YLP: 'America/Toronto',
  YLQ: 'America/Toronto',
  YLR: 'America/Winnipeg',
  YLS: 'America/Toronto',
  YLT: 'America/Pangnirtung',
  YLV: 'Asia/Baku',
  YLW: 'America/Vancouver',
  YLY: 'America/Vancouver',
  YMA: 'America/Whitehorse',
  YMB: 'America/Vancouver',
  YMD: 'America/Yellowknife',
  YME: 'America/Toronto',
  YMF: 'America/Vancouver',
  YMG: 'America/Toronto',
  YMH: 'America/St_Johns',
  YMJ: 'America/Regina',
  YMK: 'Asia/Yekaterinburg',
  YML: 'America/Toronto',
  YMM: 'America/Edmonton',
  YMN: 'America/Goose_Bay',
  YMO: 'America/Toronto',
  YMP: 'America/Vancouver',
  YMQ: 'America/Toronto',
  YMS: 'America/Lima',
  YMT: 'America/Toronto',
  YMU: 'America/Vancouver',
  YMV: 'America/Iqaluit',
  YMW: 'America/Toronto',
  YMX: 'America/Toronto',
  YNA: 'America/Toronto',
  YNB: 'Asia/Riyadh',
  YNC: 'America/Toronto',
  YND: 'America/Toronto',
  YNE: 'America/Winnipeg',
  YNG: 'America/New_York',
  YNH: 'America/Dawson_Creek',
  YNJ: 'Asia/Shanghai',
  YNL: 'America/Regina',
  YNM: 'America/Toronto',
  YNN: 'Australia/Perth',
  YNO: 'America/Winnipeg',
  YNP: 'America/Goose_Bay',
  YNS: 'America/Toronto',
  YNT: 'Asia/Shanghai',
  YNX: 'America/Yellowknife',
  YNY: 'Asia/Seoul',
  YNZ: 'Asia/Shanghai',
  YOA: 'America/Yellowknife',
  YOC: 'America/Whitehorse',
  YOD: 'America/Edmonton',
  YOE: 'America/Edmonton',
  YOG: 'America/Toronto',
  YOH: 'America/Winnipeg',
  YOI: 'America/Toronto',
  YOJ: 'America/Edmonton',
  YOL: 'Africa/Lagos',
  YON: 'Asia/Thimphu',
  YOO: 'America/Toronto',
  YOP: 'America/Edmonton',
  YOS: 'America/Toronto',
  YOT: 'Asia/Jerusalem',
  YOW: 'America/Toronto',
  YPA: 'America/Regina',
  YPB: 'America/Vancouver',
  YPC: 'America/Yellowknife',
  YPD: 'America/Toronto',
  YPE: 'America/Edmonton',
  YPG: 'America/Winnipeg',
  YPH: 'America/Toronto',
  YPI: 'America/Vancouver',
  YPJ: 'America/Toronto',
  YPL: 'America/Toronto',
  YPM: 'America/Winnipeg',
  YPN: 'America/Toronto',
  YPO: 'America/Toronto',
  YPQ: 'America/Toronto',
  YPR: 'America/Vancouver',
  YPS: 'America/Halifax',
  YPT: 'America/Vancouver',
  YPW: 'America/Vancouver',
  YPX: 'America/Toronto',
  YPY: 'America/Edmonton',
  YPZ: 'America/Vancouver',
  YQA: 'America/Toronto',
  YQB: 'America/Toronto',
  YQC: 'America/Toronto',
  YQD: 'America/Winnipeg',
  YQF: 'America/Edmonton',
  YQG: 'America/Toronto',
  YQH: 'America/Whitehorse',
  YQI: 'America/Halifax',
  YQJ: 'America/Vancouver',
  YQK: 'America/Winnipeg',
  YQL: 'America/Edmonton',
  YQM: 'America/Moncton',
  YQN: 'America/Toronto',
  YQQ: 'America/Vancouver',
  YQR: 'America/Regina',
  YQS: 'America/Toronto',
  YQT: 'America/Thunder_Bay',
  YQU: 'America/Edmonton',
  YQV: 'America/Regina',
  YQW: 'America/Regina',
  YQX: 'America/St_Johns',
  YQY: 'America/Glace_Bay',
  YQZ: 'America/Vancouver',
  YRA: 'America/Yellowknife',
  YRB: 'America/Resolute',
  YRC: 'America/Vancouver',
  YRD: 'America/Vancouver',
  YRF: 'America/Goose_Bay',
  YRG: 'America/Goose_Bay',
  YRI: 'America/Toronto',
  YRJ: 'America/Toronto',
  YRL: 'America/Winnipeg',
  YRM: 'America/Edmonton',
  YRN: 'America/Vancouver',
  YRO: 'America/Toronto',
  YRQ: 'America/Toronto',
  YRR: 'America/Vancouver',
  YRS: 'America/Winnipeg',
  YRT: 'America/Rankin_Inlet',
  YRV: 'America/Vancouver',
  YSA: 'America/Halifax',
  YSB: 'America/Toronto',
  YSC: 'America/Toronto',
  YSE: 'America/Vancouver',
  YSF: 'America/Regina',
  YSG: 'America/Yellowknife',
  YSH: 'America/Toronto',
  YSI: 'America/Toronto',
  YSJ: 'America/Moncton',
  YSK: 'America/Iqaluit',
  YSL: 'America/Moncton',
  YSM: 'America/Yellowknife',
  YSN: 'America/Vancouver',
  YSO: 'America/Goose_Bay',
  YSP: 'America/Toronto',
  YSQ: 'Asia/Shanghai',
  YST: 'America/Winnipeg',
  YSU: 'America/Halifax',
  YSX: 'America/Vancouver',
  YSY: 'America/Yellowknife',
  YTA: 'America/Toronto',
  YTB: 'America/Vancouver',
  YTD: 'America/Winnipeg',
  YTE: 'America/Iqaluit',
  YTF: 'America/Toronto',
  YTG: 'America/Vancouver',
  YTH: 'America/Winnipeg',
  YTL: 'America/Winnipeg',
  YTM: 'America/Toronto',
  YTO: 'America/Toronto',
  YTP: 'America/Vancouver',
  YTQ: 'America/Toronto',
  YTR: 'America/Toronto',
  YTS: 'America/Toronto',
  YTT: 'America/Regina',
  YTU: 'America/Vancouver',
  YTX: 'America/Vancouver',
  YTY: 'Asia/Shanghai',
  YTZ: 'America/Toronto',
  YUA: 'Asia/Shanghai',
  YUB: 'America/Yellowknife',
  YUD: 'America/Toronto',
  YUE: 'Australia/Darwin',
  YUL: 'America/Toronto',
  YUM: 'America/Phoenix',
  YUS: 'Asia/Urumqi',
  YUT: 'America/Rankin_Inlet',
  YUX: 'America/Iqaluit',
  YUY: 'America/Toronto',
  YVA: 'Indian/Comoro',
  YVB: 'America/Toronto',
  YVC: 'America/Regina',
  YVD: 'Pacific/Port_Moresby',
  YVE: 'America/Vancouver',
  YVG: 'America/Edmonton',
  YVM: 'America/Pangnirtung',
  YVN: 'America/Pangnirtung',
  YVO: 'America/Toronto',
  YVP: 'America/Toronto',
  YVQ: 'America/Yellowknife',
  YVR: 'America/Vancouver',
  YVT: 'America/Regina',
  YVV: 'America/Toronto',
  YVZ: 'America/Winnipeg',
  YWA: 'America/Toronto',
  YWB: 'America/Toronto',
  YWG: 'America/Winnipeg',
  YWH: 'America/Vancouver',
  YWJ: 'America/Yellowknife',
  YWK: 'America/Goose_Bay',
  YWL: 'America/Vancouver',
  YWM: 'America/St_Johns',
  YWP: 'America/Toronto',
  YWQ: 'America/Toronto',
  YWR: 'America/Toronto',
  YWS: 'America/Vancouver',
  YWY: 'America/Yellowknife',
  YXC: 'America/Edmonton',
  YXD: 'America/Edmonton',
  YXE: 'America/Regina',
  YXH: 'America/Edmonton',
  YXJ: 'America/Dawson_Creek',
  YXK: 'America/Toronto',
  YXL: 'America/Winnipeg',
  YXN: 'America/Rankin_Inlet',
  YXP: 'America/Pangnirtung',
  YXQ: 'America/Whitehorse',
  YXR: 'America/Toronto',
  YXS: 'America/Vancouver',
  YXT: 'America/Vancouver',
  YXU: 'America/Toronto',
  YXX: 'America/Vancouver',
  YXY: 'America/Whitehorse',
  YXZ: 'America/Toronto',
  YYA: 'Asia/Shanghai',
  YYB: 'America/Toronto',
  YYC: 'America/Edmonton',
  YYD: 'America/Vancouver',
  YYE: 'America/Fort_Nelson',
  YYF: 'America/Vancouver',
  YYG: 'America/Halifax',
  YYH: 'America/Cambridge_Bay',
  YYI: 'America/Winnipeg',
  YYJ: 'America/Vancouver',
  YYL: 'America/Winnipeg',
  YYM: 'America/Edmonton',
  YYN: 'America/Regina',
  YYQ: 'America/Winnipeg',
  YYR: 'America/Goose_Bay',
  YYT: 'America/St_Johns',
  YYU: 'America/Toronto',
  YYW: 'America/Toronto',
  YYY: 'America/Toronto',
  YYZ: 'America/Toronto',
  YZA: 'America/Vancouver',
  YZE: 'America/Toronto',
  YZF: 'America/Yellowknife',
  YZG: 'America/Toronto',
  YZH: 'America/Edmonton',
  YZP: 'America/Vancouver',
  YZR: 'America/Toronto',
  YZS: 'America/Atikokan',
  YZT: 'America/Vancouver',
  YZU: 'America/Edmonton',
  YZV: 'America/Toronto',
  YZW: 'America/Whitehorse',
  YZX: 'America/Halifax',
  YZY: 'Asia/Shanghai',
  YZZ: 'America/Vancouver',
  ZAA: 'America/Vancouver',
  ZAC: 'America/Winnipeg',
  ZAD: 'Europe/Zagreb',
  ZAG: 'Europe/Zagreb',
  ZAH: 'Asia/Tehran',
  ZAJ: 'Asia/Kabul',
  ZAL: 'America/Santiago',
  ZAM: 'Asia/Manila',
  ZAO: 'Europe/Paris',
  ZAR: 'Africa/Lagos',
  ZAT: 'Asia/Shanghai',
  ZAZ: 'Europe/Madrid',
  ZBE: 'Europe/Prague',
  ZBF: 'America/Moncton',
  ZBK: 'Europe/Podgorica',
  ZBL: 'Australia/Brisbane',
  ZBM: 'America/Toronto',
  ZBO: 'Australia/Brisbane',
  ZBR: 'Asia/Tehran',
  ZBY: 'Asia/Vientiane',
  ZCL: 'America/Monterrey',
  ZCO: 'America/Santiago',
  ZDY: 'Asia/Dubai',
  ZEC: 'Africa/Johannesburg',
  ZEG: 'Asia/Jayapura',
  ZEL: 'America/Vancouver',
  ZEM: 'America/Toronto',
  ZEN: 'Pacific/Port_Moresby',
  ZER: 'Asia/Kolkata',
  ZFA: 'America/Whitehorse',
  ZFD: 'America/Regina',
  ZFM: 'America/Yellowknife',
  ZFN: 'America/Yellowknife',
  ZFW: 'America/Edmonton',
  ZGF: 'America/Vancouver',
  ZGI: 'America/Winnipeg',
  ZGL: 'Australia/Brisbane',
  ZGM: 'Africa/Lusaka',
  ZGR: 'America/Winnipeg',
  ZGS: 'America/Blanc-Sablon',
  ZGU: 'Pacific/Efate',
  ZHA: 'Asia/Urumqi',
  ZHM: 'Asia/Dhaka',
  ZHP: 'America/Edmonton',
  ZHY: 'Asia/Shanghai',
  ZIA: 'Europe/Moscow',
  ZIC: 'America/Santiago',
  ZIG: 'Africa/Dakar',
  ZIH: 'America/Mexico_City',
  ZIS: 'Africa/Tripoli',
  ZIX: 'Asia/Yakutsk',
  ZIZ: 'Asia/Karachi',
  ZJG: 'America/Winnipeg',
  ZJN: 'America/Winnipeg',
  ZJT: 'Asia/Kuala_Lumpur',
  ZKB: 'Africa/Lusaka',
  ZKE: 'America/Toronto',
  ZKG: 'America/Blanc-Sablon',
  ZKL: 'Asia/Shanghai',
  ZKP: 'Asia/Magadan',
  ZLO: 'America/Mexico_City',
  ZLT: 'America/Blanc-Sablon',
  ZLW: 'Asia/Kuala_Lumpur',
  ZLX: 'Africa/Khartoum',
  ZMD: 'America/Rio_Branco',
  ZMH: 'America/Vancouver',
  ZMM: 'America/Mexico_City',
  ZMT: 'America/Vancouver',
  ZNA: 'America/Vancouver',
  ZNC: 'America/Anchorage',
  ZND: 'Africa/Niamey',
  ZNE: 'Australia/Perth',
  ZNU: 'America/Vancouver',
  ZNZ: 'Africa/Dar_es_Salaam',
  ZOF: 'America/Vancouver',
  ZOS: 'America/Santiago',
  ZPB: 'America/Winnipeg',
  ZPC: 'America/Santiago',
  ZPH: 'America/New_York',
  ZPO: 'America/Regina',
  ZQN: 'Pacific/Auckland',
  ZQS: 'America/Vancouver',
  ZQW: 'Europe/Berlin',
  ZQZ: 'Asia/Shanghai',
  ZRH: 'Europe/Zurich',
  ZRI: 'Asia/Jayapura',
  ZRJ: 'America/Winnipeg',
  ZRM: 'Asia/Jayapura',
  ZSA: 'America/Nassau',
  ZSE: 'Indian/Reunion',
  ZSJ: 'America/Winnipeg',
  ZSS: 'Africa/Abidjan',
  ZST: 'America/Vancouver',
  ZSW: 'America/Vancouver',
  ZTA: 'Pacific/Tahiti',
  ZTB: 'America/Blanc-Sablon',
  ZTH: 'Europe/Athens',
  ZTM: 'America/Winnipeg',
  ZTR: 'Europe/Kiev',
  ZTS: 'America/Vancouver',
  ZTU: 'Asia/Baku',
  ZUC: 'America/Winnipeg',
  ZUD: 'America/Santiago',
  ZUH: 'Asia/Shanghai',
  ZUL: 'Asia/Riyadh',
  ZUM: 'America/Goose_Bay',
  ZVA: 'Indian/Antananarivo',
  ZVG: 'Australia/Perth',
  ZVK: 'Asia/Vientiane',
  ZWA: 'Indian/Antananarivo',
  ZWL: 'America/Regina',
  ZXT: 'Asia/Baku',
  ZYI: 'Asia/Shanghai',
  ZYL: 'Asia/Dhaka',
  ZZO: 'Asia/Sakhalin',
  ZZU: 'Africa/Blantyre',
  ZZV: 'America/New_York',
};
