export const airlineNames = {
  Q5: '40-Mile Air',
  M3: 'ABSA - Aerolinhas Brasileiras',
  GB: 'ABX Air',
  VX: 'ACES Colombia',
  Z7: 'ADC Airlines',
  IW: 'AOM French Airlines',
  TZ: 'ATA Airlines',
  V8: 'ATRAN Cargo Airlines',
  J6: 'AVCOM',
  '1B': 'Abacus International',
  K5: 'Aban Air',
  W9: 'Abelag Aviation',
  MO: 'Abu Dhabi Amiri Flight',
  ZA: 'Access Air',
  ZY: 'Ada Air',
  KI: 'Adam Air',
  JP: 'Adria Airways',
  '4G': 'Advance Leasing Company',
  A3: 'Aegean Airlines',
  RE: 'Aer Arann',
  EI: 'Aer Lingus',
  '4M': 'Aero 2000',
  EE: 'Aero Airlines',
  E4: 'Aero Asia International',
  EM: 'Aero Benin',
  JR: 'Aero California',
  Q6: 'Aero Condor Peru',
  AJ: 'Aero Contractors',
  GV: 'Aero Flight',
  QL: 'Aero Lanka',
  W4: 'Aero Services Executive',
  '2D': 'Aero VIP',
  DW: 'Aero-Charter Ukraine',
  BF: 'Aero-Service',
  HC: 'Aero-Tropics Air Services',
  P5: 'AeroRep�blica',
  '2B': 'Aerocondor',
  SU: 'Aeroflot Russian Airlines',
  D9: 'Aeroflot-Don',
  '5N': 'Aeroflot-Nord',
  '3S': 'Aeroland Airways',
  XL: 'Aerolane',
  '7E': 'Aeroline GmbH',
  AR: 'Aerolineas Argentinas',
  '2K': 'Aerolineas Galapagos (Aerogal)',
  '5D': 'Aerolitoral',
  BQ: 'Aeromar',
  VW: 'Aeromar',
  QO: 'Aeromexpress',
  HT: 'Aeromist-Kharkiv',
  AM: 'Aerom�xico',
  OT: 'Aeropelican Air Services',
  PL: 'Aeroper�',
  '5L': 'Aerosur',
  VV: 'Aerosvit Airlines',
  FK: 'Africa West',
  QS: 'African Safari Airways',
  ML: 'African Transport Trading and Investment Company',
  Q9: 'Afrinat International Airlines',
  '8U': 'Afriqiyah Airways',
  ZI: 'Aigle Azur',
  RK: 'Air Afrique',
  AH: 'Air Alg�rie',
  GD: 'Air Alpha Greenland',
  A6: 'Air Alps Aviation',
  G9: 'Air Arabia',
  QN: 'Air Armenia',
  KC: 'Air Astana',
  CC: 'Air Atlanta Icelandic',
  UU: 'Air Austral',
  BT: 'Air Baltic',
  B9: 'Air Bangladesh',
  AB: 'Air Berlin',
  JA: 'Air Bosna',
  BP: 'Air Botswana',
  ZB: 'Air Bourbon',
  '2J': 'Air Burkina',
  '8Y': 'Air Burundi',
  SB: 'Air Caledonie International',
  TY: 'Air Cal�donie',
  QK: 'Air Canada Jazz',
  AC: 'Air Canada',
  TX: 'Air Cara�bes',
  '2Q': 'Air Cargo Carriers',
  NV: 'Air Central',
  CV: 'Air Chathams',
  CA: 'Air China',
  '4F': 'Air City',
  QD: 'Air Class Lineas Aereas',
  GG: 'Air Comores International',
  AG: 'Air Contractors',
  QC: 'Air Corridor',
  H9: "Air D'Ayiti",
  EN: 'Air Dolomiti',
  UX: 'Air Europa',
  PE: 'Air Europe',
  XT: 'Air Exel',
  PC: 'Air Fiji',
  OF: 'Air Finland',
  QH: 'Air Florida',
  GS: 'Air Foyle',
  AF: 'Air France',
  GN: 'Air Gabon',
  DA: 'Air Georgia',
  ZX: 'Air Georgian',
  '7T': 'Air Glaciers',
  GL: 'Air Greenland',
  '2U': 'Air Guinee Express',
  LD: 'Air Hong Kong',
  '8C': 'Air Horizon',
  NY: 'Air Iceland',
  IX: 'Air India Express',
  AI: 'Air India Limited',
  I9: 'Air Italy',
  VU: 'Air Ivoire',
  JM: 'Air Jamaica',
  NQ: 'Air Japan',
  '9Y': 'Air Kazakhstan',
  '4A': 'Air Kiribati',
  JS: 'Air Koryo',
  VD: 'Air Libert�',
  TT: 'Air Lithuania',
  L8: 'Air Luxor GB',
  LK: 'Air Luxor',
  NX: 'Air Macau',
  MD: 'Air Madagascar',
  NM: 'Air Madrid',
  QM: 'Air Malawi',
  KM: 'Air Malta',
  '6T': 'Air Mandalay',
  CW: 'Air Marshall Islands',
  MR: 'Air Mauritanie',
  MK: 'Air Mauritius',
  DR: 'Air Mediterranee',
  ZV: 'Air Midwest',
  MC: 'Air Mobility Command',
  '9U': 'Air Moldova',
  SW: 'Air Namibia',
  NZ: 'Air New Zealand',
  EH: 'Air Nippon Network Co. Ltd.',
  EL: 'Air Nippon',
  PX: 'Air Niugini',
  '4N': 'Air North Charter - Canada',
  YW: 'Air Nostrum',
  AP: 'Air One',
  FJ: 'Air Pacific',
  AD: 'Air Paradise International',
  '2P': 'Air Philippines',
  A7: 'Air Plus Comet',
  S2: 'Air Sahara',
  PJ: 'Air Saint Pierre',

  '2O': 'Air Salone',
  EX: 'Air Santo Domingo',
  V7: 'Air Senegal International',
  G8: 'Air Service Gabon',
  HM: 'Air Seychelles',
  BM: 'Air Sicilia',
  '4D': 'Air Sinai',
  GM: 'Air Slovakia',
  RB: 'Air Srpska',
  KY: 'Air S�o Tom� and Pr�ncipe',
  TN: 'Air Tahiti Nui',
  VT: 'Air Tahiti',
  TC: 'Air Tanzania',
  '8T': 'Air Tindi',
  YT: 'Air Togo',
  TS: 'Air Transat',
  JY: 'Air Turks and Caicos',
  '6U': 'Air Ukraine',
  '3N': 'Air Urga',
  VL: 'Air VIA',
  NF: 'Air Vanuatu',
  '6V': 'Air Vegas',
  '6G': 'Air Wales',
  ZW: 'Air Wisconsin',
  UM: 'Air Zimbabwe',
  AK: 'AirAsia',
  RU: 'AirBridge Cargo',
  FL: 'AirTran Airways',
  ED: 'Airblue',
  '4Y': 'Airbus France',
  R3: 'Aircompany Yakutia',
  '4C': 'Aires',
  A5: 'Airlinair',
  FO: 'Airlines Of Tasmania',
  CG: 'Airlines PNG',
  K8: 'Airlink Zambia',
  TL: 'Airnorth',
  FF: 'Airshop',
  AS: 'Alaska Airlines',
  KO: 'Alaska Central Express',
  LV: 'Albanian Airlines',
  F4: 'Albarka Air',
  D4: 'Alidaunia',
  AZ: 'Alitalia',
  NH: 'All Nippon Airways',
  G4: 'Allegiant Air',
  LL: 'Allegro',
  CD: 'Alliance Air',
  QQ: 'Alliance Airlines',
  AQ: 'Aloha Airlines',
  E8: 'Alpi Eagles',
  '5A': 'Alpine Air Express',
  '6R': 'Alrosa Mirny Air Enterprise',
  '1A': 'Amadeus Global Travel Distribution',
  Z8: 'Amaszonas',
  '0A': 'Amber Air',
  HP: 'America West Airlines',
  AA: 'American Airlines',
  MQ: 'American Eagle Airlines',
  WK: 'American Falcon',
  M6: 'Amerijet International',
  '2V': 'Amtrak',
  AN: 'Ansett Australia',
  HO: 'Antinea Airlines',
  '04': 'Antrak Air',
  '5F': 'Arctic Circle Air Service',
  ZE: 'Arcus-Air Logistic',
  FG: 'Ariana Afghan Airlines',
  OR: 'Arkefly',
  IZ: 'Arkia Israel Airlines',
  U8: 'Armavia',
  MV: 'Armenian International Airways',
  JW: 'Arrow Air',
  R7: 'Aserca Airlines',
  OE: 'Asia Overnight Express',
  HJ: 'Asian Express Airlines',
  '6K': 'Asian Spirit',
  OZ: 'Asiana Airlines',
  '4K': 'Askari Aviation',
  '8D': 'Astair',
  ER: 'Astar Air Cargo',
  '5W': 'Astraeus',
  OB: 'Astrakhan Airlines',
  '8V': 'Astral Aviation',
  '3G': 'Atlant-Soyuz Airlines',
  RC: 'Atlantic Airways',
  EV: 'Atlantic Southeast Airlines',
  TD: 'Atlantis European Airways',
  '5Y': 'Atlas Air',
  '8A': 'Atlas Blue',
  KK: 'Atlasjet',
  IP: 'Atyrau Air Ways',
  IQ: 'Augsburg Airways',
  GR: 'Aurigny Air Services',
  NO: 'Aus-Air',
  AU: 'Austral Lineas Aereas',
  AO: 'Australian Airlines',
  OS: 'Austrian Airlines',
  VO: 'Austrian Arrows',
  VE: 'Avensa',
  RX: 'Aviaexpress',
  EC: 'Avialeasing Aviation Company',
  AV: 'Avianca - Aerovias Nacionales de Colombia',
  Z3: 'Avient Aviation',
  U3: 'Avies',
  G2: 'Avirex',
  V5: 'Avolar Aerolineas',
  J2: 'Azerbaijan Airlines',
  '7A': 'Aztec Worldwide Airlines',
  ZS: 'Azzurra Air',
  TH: 'BA Connect',
  B4: 'BACH Flugbetriebsges',
  V9: 'BAL Bashkirian Airlines',
  '8W': 'BAX Global',
  '7R': 'BRA-Transportes Aereos',
  UP: 'Bahamasair',
  '8Q': 'Baker Aviation',
  LZ: 'Balkan Bulgarian Airlines',
  PG: 'Bangkok Airways',
  '8N': 'Barents AirLink',
  JV: 'Bearskin Lake Air Service',
  '4T': 'Belair Airlines',
  B2: 'Belavia Belarusian Airlines',
  B3: 'Bellview Airlines',
  CH: 'Bemidji Airlines',
  A8: 'Benin Golf Air',
  '8E': 'Bering Air',
  J8: 'Berjaya Air',
  GQ: 'Big Sky Airlines',
  BG: 'Biman Bangladesh Airlines',
  NT: 'Binter Canarias',
  '5Z': 'Bismillah Airlines',
  '0B': 'Blue Air',
  BZ: 'Blue Dart Aviation',
  BV: 'Blue Panorama Airlines',
  QW: 'Blue Wings',
  KF: 'Blue1',
  E9: 'Boston-Maine Airways',
  BO: 'Bouraq Indonesia Airlines',
  BU: 'Braathens',
  BN: 'Braniff International Airways',
  K6: 'Bravo Air Congo',
  E6: 'Bringer Air Cargo Taxi Aereo',
  DB: 'Brit Air',
  BA: 'British Airways',
  BS: 'British International Helicopters',
  KJ: 'British Mediterranean Airways',
  SN: 'Brussels Airlines',
  J4: 'Buffalo Airways',
  FB: 'Bulgaria Air',
  '5C': 'CAL Cargo Air Lines',
  AW: 'CHC Airways',
  XG: 'Calima Aviacion',
  R9: 'Camai Air',
  UY: 'Cameroon Airlines',
  C6: 'CanJet',
  CP: 'Canadian Airlines',
  '5T': 'Canadian North',
  W2: 'Canadian Western Airlines',
  '9K': 'Cape Air',
  PT: 'Capital Cargo International Airlines',
  '8L': 'Cargo Plus Aviation',
  '2G': 'Cargoitalia',
  W8: 'Cargojet Airways',
  BW: 'Caribbean Airlines',
  '8B': 'Caribbean Star Airlines',
  V3: 'Carpatair',
  RV: 'Caspian Airlines',
  CX: 'Cathay Pacific',
  KX: 'Cayman Airways',
  '5J': 'Cebu Pacific',
  '7N': 'Centavia',
  C0: 'Centralwings',
  J7: 'Centre-Avia',
  WE: 'Centurion Air Cargo',
  OP: "Chalk's Ocean Airways",
  MG: 'Champion Air',
  '2Z': 'Changan Airlines',
  S8: 'Chari Aviation Services',
  RP: 'Chautauqua Airlines',
  C8: 'Chicago Express',
  CI: 'China Airlines',
  CK: 'China Cargo Airlines',
  MU: 'China Eastern Airlines',
  CJ: 'China Northern Airlines',
  WH: 'China Northwest Airlines',
  CZ: 'China Southern Airlines',
  HR: 'China United Airlines',
  XO: 'China Xinhua Airlines',
  X7: 'Chitaavia',
  A2: 'Cielos Airlines',
  QI: 'Cimber Air',
  C9: 'Cirrus Airlines',
  CF: 'City Airline',
  G3: 'City Connexion Airlines',
  WX: 'CityJet',
  CT: 'Civil Air Transport',
  '6P': 'Club Air',
  BX: 'Coast Air',
  DQ: 'Coastal Air',
  '9L': 'Colgan Air',
  MN: 'Comair',
  OH: 'Comair',
  C5: 'CommutAir',
  KR: 'Comores Airlines',
  GJ: 'Compania Mexicargo',
  DE: 'Condor Flugdienst',
  '6A': 'Consorcio Aviaxsa',
  CO: 'Continental Airlines',
  CS: 'Continental Micronesia',
  V0: 'Conviasa',
  CM: 'Copa Airlines',
  SS: 'Corsairfly',
  XK: 'Corse-Mediterranee',
  F5: 'Cosmic Air',
  '7C': 'Coyne Aviation',
  OU: 'Croatia Airlines',
  QE: 'Crossair Europe',
  CU: 'Cubana de Aviaci�n',
  CY: 'Cyprus Airways',
  YK: 'Cyprus Turkish Airlines',
  OK: 'Czech Airlines',
  WD: 'DAS Air Cargo',
  DX: 'DAT Danish Air Transport',
  ES: 'DHL International',
  L3: 'DHL de Guatemala',
  D3: 'Daallo Airlines',
  N2: 'Dagestan Airlines',
  H8: 'Dalavia',
  '0D': 'Darwin Airline',
  D5: 'Dauair',
  DL: 'Delta Air Lines',
  '2A': 'Deutsche Bahn',
  '1I': 'Deutsche Rettungsflugwacht',
  D7: 'Dinar',
  DH: 'Discovery Airways',
  D8: 'Djibouti Airlines',
  DO: 'Dominicana de Aviaci�n',
  E3: 'Domodedovo Airlines',
  KA: 'Dragonair',
  KB: 'Druk Air',
  BR: 'EVA Air',
  H7: 'Eagle Air',
  S9: 'East African Safari Air',
  QU: 'East African',
  T3: 'Eastern Airways',
  DK: 'Eastland Air',
  MS: 'Egyptair',
  LY: 'El Al Israel Airlines',
  UZ: 'El-Buraq Air Transport',
  '1C': 'Electronic Data Systems',
  EK: 'Emirates Airline',
  EU: 'Empresa Ecuatoriana De Aviacion',
  E0: 'Eos Airlines',
  B8: 'Eritrean Airlines',
  E7: 'Estafeta Carga Aerea',
  OV: 'Estonian Air',
  ET: 'Ethiopian Airlines',
  EY: 'Etihad Airways',
  RZ: 'Euro Exec Express',
  MM: 'EuroAtlantic Airways',
  UI: 'Eurocypria Airlines',
  K2: 'Eurolot',
  '3W': 'Euromanx Airways',
  EA: 'European Air Express',
  QY: 'European Air Transport',
  EW: 'Eurowings',
  EZ: 'Evergreen International Airlines',
  JN: 'Excel Airways',
  MB: 'Execair Aviation',
  OW: 'Executive Airlines',
  EO: 'Express One International',
  XE: 'ExpressJet',
  IH: 'Falcon Aviation',
  EF: 'Far Eastern Air Transport',
  F6: 'Faroejet',
  F3: 'Faso Airways',
  FX: 'Federal Express',
  N8: 'Fika Salaama Airlines',
  '4S': 'Finalair Congo',
  AY: 'Finnair',
  FC: 'Finncomm Airlines',
  FY: 'Firefly',
  '7F': 'First Air',
  DP: 'First Choice Airways',
  '8F': 'Fischer Air',
  B5: 'Flightline',
  PA: 'Florida Coastal Airlines',
  RF: 'Florida West International Airways',
  F2: 'Fly Air',
  SH: 'Fly Me Sweden',
  TE: 'FlyLal',
  LF: 'FlyNordic',
  F7: 'Flybaboo',
  BE: 'Flybe',
  W3: 'Flyhy Cargo Airlines',
  VY: 'Formosa Airlines',
  HK: 'Four Star Aviation / Four Star Cargo',
  FP: 'Freedom Air',
  SJ: 'Freedom Air',
  F9: 'Frontier Airlines',
  '2F': 'Frontier Flying Service',
  FH: 'Futura International Airways',
  GT: 'GB Airways',
  '7O': 'Galaxy Air',
  '1G': 'Galileo International',
  GC: 'Gambia International Airlines',
  G7: 'Gandalf Airlines',
  GA: 'Garuda Indonesia',
  A9: 'Georgian Airways',
  QB: 'Georgian National Airlines',
  ST: 'Germania',
  '4U': 'Germanwings',
  GP: 'Gestair',
  GH: 'Ghana Airways',
  G0: 'Ghana International Airlines',
  GK: 'Go One Airways',
  DC: 'Golden Air',
  G1: 'Gorkha Airlines',
  ZK: 'Great Lakes Airlines',
  IJ: 'Great Wall Airlines',
  TA: 'Grupo TACA',
  G6: 'Guine Bissaur Airlines',
  J9: 'Guinee Airlines',
  GF: 'Gulf Air Bahrain',
  GY: 'Guyana Airways 2000',
  H6: 'Hageland Aviation Services',
  HU: 'Hainan Airlines',
  '1R': 'Hainan Phoenix Information Systems',
  '2T': 'Haiti Ambassador Airlines',
  '4R': 'Hamburg International',
  X3: 'Hapag-Lloyd Express (TUIfly)',
  HF: 'Hapagfly',
  HB: 'Harbor Airlines',
  HQ: 'Harmony Airways',
  HA: 'Hawaiian Airlines',
  BH: 'Hawkair',
  HN: 'Heavylift Cargo Airlines',
  '8H': 'Heli France',
  JB: 'Helijet',
  ZU: 'Helios Airways',
  T4: 'Hellas Jet',
  HW: 'Hello',
  '2L': 'Helvetic Airways',
  DU: 'Hemus Air',
  UD: "Hex'Air",
  '5K': 'Hi Fly',
  HD: 'Hokkaido International Airlines',
  H5: 'Hola Airlines',
  UO: 'Hong Kong Express Airways',
  HH: 'Hope Air',
  QX: 'Horizon Air',
  H4: 'Héli Sécurité Helicopter Airlines',
  II: 'IBC Airways',
  C3: 'ICAR Airlines',
  '1F': 'INFINI Travel Information',
  '1U': 'ITA Software',
  IB: 'Iberia Airlines',
  FW: 'Ibex Airlines',
  FI: 'Icelandair',
  IK: 'Imair Airlines',
  '6E': 'IndiGo Airlines',
  IC: 'Indian Airlines',
  QZ: 'Indonesia AirAsia',
  IO: 'Indonesian Airlines',
  D6: 'Interair South Africa',
  RS: 'Intercontinental de Aviaci�n',
  ID: 'Interlink Airlines',
  '6I': 'International Business Air',
  '3L': 'Intersky',
  I4: 'Interstate Airline',
  IR: 'Iran Air',
  EP: 'Iran Aseman Airlines',
  IA: 'Iraqi Airways',
  IS: 'Island Airlines',
  '2S': 'Island Express',
  CN: 'Islands Nationair',
  IF: 'Islas Airways',
  WC: 'Islena De Inversiones',
  '6H': 'Israir',
  '9X': 'Itali Airlines',
  GI: 'Itek Air',
  XM: 'J-Air',
  JC: 'JAL Express',
  JO: 'JALways',
  '1M': 'JSC Transport Automated Information Systems',
  JL: 'Japan Airlines Domestic',
  EG: 'Japan Asia Airways',
  NU: 'Japan Transocean Air',
  JU: 'Jat Airways',
  VJ: 'Jatayu Airlines',
  '9W': 'Jet Airways',
  QJ: 'Jet Airways',
  LS: 'Jet2.com',
  B6: 'JetBlue Airways',
  JF: 'Jetairfly',
  '0J': 'Jetclub',
  SG: 'JetsGo',
  JQ: 'Jetstar Airways',
  '3K': 'Jetstar Asia Airways',
  JX: 'Jett8 Airlines Cargo',
  GX: 'Jetx Airlines',
  R5: 'Jordan Aviation',
  KD: 'KD Avia',
  WA: 'KLM Cityhopper',
  KL: 'KLM Royal Dutch Airlines',
  K4: 'Kalitta Air',
  RQ: 'Kam Air',
  E2: 'Kampuchea Airlines',
  V2: 'Karat',
  KV: 'Kavminvodyavia',
  M5: 'Kenmore Air',
  KQ: 'Kenya Airways',
  IT: 'Kingfisher Airlines',
  Y9: 'Kish Air',
  KP: 'Kiwi International Air Lines',
  '7K': 'Kogalymavia Air Company',
  '8J': 'Komiinteravia',
  KE: 'Korean Air',
  '7B': 'Krasnojarsky Airlines',
  K9: 'Krylo Airlines',
  GW: 'Kuban Airlines',
  KU: 'Kuwait Airways',
  GO: 'Kuzu Airlines Cargo',
  N5: 'Kyrgyz Airlines',
  R8: 'Kyrgyzstan Airlines',
  LR: 'LACSA',
  KG: 'LAI - Linea Aerea IAACA',
  LA: 'LAN Airlines',
  LU: 'LAN Express',
  LP: 'LAN Peru',
  LO: 'LOT Polish Airlines',
  LT: 'LTU International',
  N6: 'Lagun Air',
  QV: 'Lao Airlines',
  L7: 'Laoag International Airlines',
  NG: 'Lauda Air',
  LQ: 'Lebanese Air Transport',
  LI: 'Leeward Islands Air Transport',
  LN: 'Libyan Arab Airlines',
  '8z': 'Linea Aerea de Servicio Ejecutivo Regional',
  LM: 'Linhas A�reas de Mo�ambique',
  JT: 'Lion Mentari Airlines',
  LB: 'Lloyd Aereo Boliviano',
  HE: 'Luftfahrtgesellschaft Walter',
  LH: 'Lufthansa Cargo',
  CL: 'Lufthansa CityLine',
  L1: 'Lufthansa Systems',
  DV: 'Lufttaxi Fluggesellschaft',
  L5: 'Lufttransport',
  LG: 'Luxair',
  '5V': 'Lviv Airlines',
  L2: 'Lynden Air Cargo',
  MJ: 'L�neas A�reas Privadas Argentinas',
  IN: 'MAT Macedonian Airlines',
  OM: 'MIAT Mongolian Airlines',
  DM: 'Maersk',
  W5: 'Mahan Air',
  M2: 'Mahfooz Aviation',
  MH: 'Malaysia Airlines',
  TF: 'Malmö Aviation',
  MA: 'Mal�v Hungarian Airlines',
  RI: 'Mandala Airlines',
  AE: 'Mandarin Airlines',
  JE: 'Mango',
  M7: 'Marsland Aviation',
  MP: 'Martinair',
  Q4: 'Mastertop Linhas Aereas',
  '8M': 'Maxair',
  MY: 'Maxjet Airways',
  MW: 'Maya Island Air',
  IM: 'Menajet',
  IG: 'Meridiana',
  MZ: 'Merpati Nusantara Airlines',
  YV: 'Mesa Airlines',
  XJ: 'Mesaba Airlines',
  '7P': 'Metro Batavia',
  MX: 'Mexicana de Aviaci�n',
  ME: 'Middle East Airlines',
  JI: 'Midway Airlines',
  YX: 'Midwest Airlines',
  '2M': 'Moldavian Airlines',
  YM: 'Montenegro Airlines',
  '3R': 'Moskovia Airlines',
  M9: 'Motor Sich',
  N4: 'Mountain Air Company',
  VZ: 'MyTravel Airways',
  UB: 'Myanma Airways',
  '8I': 'Myway Airlines',
  P9: 'Nas Air',
  XY: 'Nas Air',
  UE: 'Nasair',
  N7: 'National Airlines',
  NA: 'National Airlines',
  '9O': 'National Airways Cameroon',
  NC: 'National Jet Systems',
  CE: 'Nationwide Airlines',
  ON: 'Nauru Air Corporation',
  '1N': 'Navitaire',
  RA: 'Nepal Airlines',
  EJ: 'New England Airlines',
  HG: 'Niki',
  KZ: 'Nippon Cargo Airlines',
  DD: 'Nok Air',
  JH: 'Nordeste Linhas Aereas Regionais',
  '6N': 'Nordic Regional',
  N9: 'North Coast Aviation',
  U7: 'Northern Dene Airways',
  NW: 'Northwest Airlines',
  J3: 'Northwestern Air',
  DY: 'Norwegian Air Shuttle',
  BJ: 'Nouvel Air Tunisie',
  M4: 'Nova Airline',
  UQ: "O'Connor Airlines",
  CR: 'OAG',
  O8: 'Oasis Hong Kong Airlines',
  VC: 'Ocean Airlines',
  O6: 'Oceanair',
  O2: 'Oceanic Airlines',
  OA: 'Olympic Airlines',
  WY: 'Oman Air',
  OY: 'Omni Air International',
  N3: 'Omskavia Airline',
  '1L': 'Open Skies Consultative Commission',
  R2: 'Orenburg Airlines',
  OX: 'Orient Thai Airlines',
  OL: 'Ostfriesische Lufttransport',
  OJ: 'Overland Airways',
  O7: 'Ozjet Airlines',
  PV: 'PAN Air',
  '9Q': 'PB Air',
  PU: 'PLUNA',
  U4: 'PMTair',
  Y5: 'Pace Airlines',
  BL: 'Pacific Airlines',
  DJ: 'Pacific Blue',
  '8P': 'Pacific Coastal Airline',
  Q8: 'Pacific East Asia Cargo Airlines',
  PS: 'Pacific Southwest Airlines',
  LW: 'Pacific Wings',
  PK: 'Pakistan International Airlines',
  PF: 'Palestinian Airlines',
  NR: 'Pamir Airways',
  PN: 'Pan American Airways',
  PQ: 'Panafrican Airways',
  P8: 'Pantanal Linhas Aéreas',
  I7: 'Paramount Airways',
  KS: 'Peninsula Airways',
  PR: 'Philippine Airlines',
  '9R': 'Phuket Air',
  PI: 'Piedmont Airlines (1948-1989)',
  '9E': 'Pinnacle Airlines',
  PO: 'Polar Air Cargo',
  PH: 'Polynesian Airlines',
  PD: 'Porter Airlines',
  NI: 'Portugalia',
  BK: 'Potomac Air',
  PW: 'Precision Air',
  TO: 'President Airlines',
  FE: 'Primaris Airlines',
  P0: 'Proflight Commuter Services',
  FV: 'Pulkovo Aviation Enterprise',
  QF: 'Qantas',
  QR: 'Qatar Airways',
  R6: 'RACSA',
  '1D': 'Radixx Solutions International',
  V4: 'Reem Air',
  FN: 'Regional Airlines',
  ZL: 'Regional Express',
  '3C': 'RegionsAir',
  RW: 'Republic Airlines',
  RH: 'Republic Express Airlines',
  C7: 'Rico Linhas A�reas',
  SL: 'Rio Sul Servi�os A�reos Regionais',
  R4: 'Rossiya',
  GZ: 'Rotation International Air Services',
  RR: 'Royal Air Force',
  AT: 'Royal Air Maroc',
  R0: 'Royal Airlines',
  BI: 'Royal Brunei Airlines',
  RJ: 'Royal Jordanian',
  RL: 'Royal Phnom Penh Airways',
  WR: 'Royal Tongan Airlines',
  P7: 'Russian Sky Airlines',
  WB: 'Rwandair Express',
  RD: 'Ryan International Airlines',
  FR: 'Ryanair',
  YS: 'R�gional',
  S7: 'S7 Airlines',
  SP: 'SATA Air Acores',
  S4: 'SATA International',
  XS: 'SITA',
  '2C': 'SNCF',
  '1Z': 'Sabre Pacific',
  '1S': 'Sabre',
  FA: 'Safair',
  E5: 'Samara Airlines',
  S3: 'Santa Barbara Airlines',
  '6W': 'Saratov Aviation Division',
  HZ: 'Sat Airlines',
  SV: 'Saudi Arabian Airlines',
  SK: 'Scandinavian Airlines System',
  '8S': 'Scorpio Aviation',
  BB: 'Seaborne Airlines',
  FS: 'Servicios de Transportes A�reos Fueguinos',
  NL: 'Shaheen Air International',
  SC: 'Shandong Airlines',
  FM: 'Shanghai Airlines',
  ZH: 'Shenzhen Airlines',
  S5: 'Shuttle America',
  '5M': 'Sibaviatrans',
  '3U': 'Sichuan Airlines',
  FT: 'Siem Reap Airways',
  LJ: 'Sierra National Airlines',
  ZP: 'Silk Way Airlines',
  MI: 'SilkAir',
  SQ: 'Singapore Airlines Cargo',
  '1H': 'Siren-Travel',
  '1Q': 'Sirena',
  R1: 'Sirin',
  H2: 'Sky Airline',
  XW: 'Sky Express',
  I6: 'Sky Eyes',
  NE: 'SkyEurope',
  OO: 'SkyWest',
  SX: 'Skybus Airlines',
  BC: 'Skymark Airlines',
  SI: 'Skynet Airlines',
  '6J': 'Skynet Asia Airways',
  '5G': 'Skyservice Airlines',
  AL: 'Skywalk Airlines',
  JZ: 'Skyways Express',
  S0: 'Slok Air Gambia',
  '6Q': 'Slovak Airlines',
  IE: 'Solomon Airlines',
  SO: 'Sosoliso Airlines',
  SA: 'South African Airways',
  DG: 'South East Asian Airlines',
  '1K': 'Southern Cross Distribution',
  A4: 'Southern Winds Airlines',
  WN: 'Southwest Airlines',
  JK: 'Spanair',
  NK: 'Spirit Airlines',
  '9S': 'Spring Airlines',
  UL: 'SriLankan Airlines',
  S6: 'Star Air',
  '7G': 'Star Flyer',
  NB: 'Sterling Airlines',
  SD: 'Sudan Airways',
  SY: 'Sun Country Airlines',
  '7L': "Sun D'Or",
  XQ: 'SunExpress',
  CQ: 'Sunshine Express Airlines',
  WG: 'Sunwing Airlines',
  PY: 'Surinam Airways',
  WV: 'Swe Fly',
  SM: 'Swedline Express',
  LX: 'Swiss International Air Lines',
  SR: 'Swissair',
  DT: 'TAAG Angola Airlines',
  VR: 'TACV',
  JJ: 'TAM Brazilian Airlines',
  PZ: 'TAM Mercosur',
  EQ: 'TAME',
  QT: 'TAMPA',
  TP: 'TAP Portugal',
  '3V': 'TNT Airways',
  '8R': 'TRIP Linhas A�reas',
  TB: 'TUI Airlines Belgium',
  '6B': 'TUIfly Nordic',
  TQ: 'Tandem Aero',
  RO: 'Tarom',
  SF: 'Tassili Airlines',
  U9: 'Tatarstan Airlines',
  T6: 'Tavrey Airlines',
  L6: 'Tbilaviamsheni',
  L9: 'Teamline Air',
  T2: 'Thai Air Cargo',
  FD: 'Thai AirAsia',
  TG: 'Thai Airways International',
  '9I': 'Thai Sky Airlines',
  T9: 'Thai Star Airlines',
  '2H': 'Thalys',
  FQ: 'Thomas Cook Airlines',
  MT: 'Thomas Cook Airlines',
  BY: 'Thomsonfly',
  '3P': 'Tiara Air',
  TR: 'Tiger Airways',
  ZT: 'Titan Airways',
  TI: 'Tol-Air Services',
  AX: 'Trans States Airlines',
  TW: 'Trans World Airlines',
  GE: 'TransAsia Airways',
  UN: 'Transaero Airlines',
  HV: 'Transavia Holland',
  '9T': 'Transwest Air',
  '1E': 'Travelsky Technology',
  PM: 'Tropic Air',
  UG: 'Tuninter',
  TU: 'Tunisair',
  '3T': 'Turan Air',
  TK: 'Turkish Airlines',
  T5: 'Turkmenistan Airlines',
  T7: 'Twin Jet',
  UF: 'UM Airlines',
  US: 'US Airways',
  UH: 'US Helicopter Corporation',
  U5: 'USA3000 Airlines',
  UT: 'UTair Aviation',
  '6Z': 'Ukrainian Cargo Airways',
  UA: 'United Airlines',
  U2: 'United Feeder Service',
  '5X': 'United Parcel Service',
  U6: 'Ural Airlines',
  HY: 'Uzbekistan Airways',
  VA: 'V Australia Airlines',
  VP: 'VASP',
  '2R': 'VIA Rail Canada',
  NN: 'VIM Airlines',
  VG: 'VLM Airlines',
  RG: 'VRG Linhas Aereas',
  VF: 'Valuair',
  LC: 'Varig Log�stica',
  VM: 'Viaggio Air',
  VN: 'Vietnam Airlines',
  VS: 'Virgin Atlantic Airways',
  TV: 'Virgin Express',
  VK: 'Virgin Nigeria Airways',
  ZG: 'Viva Macau',
  XF: 'Vladivostok Air',
  Y4: 'Volaris',
  VI: 'Volga-Dnepr Airlines',
  '7W': 'Wayraper�',
  WJ: 'WebJet Linhas A�reas',
  '2W': 'Welcome Air',
  '8O': 'West Coast Air',
  WS: 'WestJet',
  WF: 'Wider�e',
  IV: 'Wind Jet',
  '1W': 'Wings Air',
  W6: 'Wizz Air',
  WO: 'World Airways',
  '1P': 'Worldspan',
  SE: 'XL Airways France',
  MF: 'Xiamen Airlines',
  XP: 'Xtra Airways',
  YL: 'Yamal Airlines',
  Y8: 'Yangtze River Express',
  Y0: 'Yellow Air Taxi',
  IY: 'Yemenia',
  '3Q': 'Yunnan Airlines',
  '2N': 'Yuzhmashavia',
  Q3: 'Zambian Airways',
  C4: 'Zimex Aviation',
  '3J': 'Zip',
  Z4: 'Zoom Airlines',
  BD: 'bmi',
  WW: 'bmibaby',
  UK: 'buzz',
  DI: 'dba',
};
