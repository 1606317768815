export const formatDate = (date: Date | string) => {
  const currDate: Date = new Date(date);

  const day = currDate.getDate();
  const month = currDate.getMonth() + 1;

  const year = currDate.getFullYear();

  return `${day < 10 ? `0${day.toString()}` : day.toString()}-${
    month < 10 ? `0${month.toString()}` : month.toString()
  }-${year}`;
};
