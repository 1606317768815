const fromatDurationString = (durationString: string | undefined) => {
  if (durationString) {
    const newData = durationString.split(' ');

    return `${newData[2]} ${newData[3]} ${newData[0]} ${newData[1]}`;
  }

  return '';
};

export default fromatDurationString;
