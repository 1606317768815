import React, { JSXElementConstructor, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FilterHeader from './filterHeader';

interface IProps {
  title: string;
  name: string;
  isFlightPage?: boolean;
  children: ReactElement<React.FC, string | JSXElementConstructor<React.FC>>;
  responsiveMode: boolean;
}

const SearchBarHomePage: React.FC<IProps> = ({
  title,
  name,
  children,
  responsiveMode,
  isFlightPage,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <FilterHeader
          title={title}
          value={value}
          responsiveMode={responsiveMode}
          isFlightPage={isFlightPage}
        >
          {children &&
            React.cloneElement(children, {
              value,
              onChange,
              name,
            })}
        </FilterHeader>
      )}
    />
  );
};

export default SearchBarHomePage;
