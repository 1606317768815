import { ISelectorOption } from 'types/ISelectorOption';

export type familyOptionsType = {
  adults: (string | undefined)[];
  children: (string | undefined)[];
}[];

interface IProps {
  roomList: familyOptionsType;
  adultOptions: ISelectorOption<string>[];
  childOptions: ISelectorOption<string>[];
}

const getRemainingAdultsChildren = ({ roomList, adultOptions, childOptions }: IProps) => {
  const getSelectedTravellers = (
    travellerType: 'adults' | 'children',
    roomList: familyOptionsType | null,
  ) =>
    roomList?.reduce<(string | undefined)[]>((prev, curr) => {
      if (curr[travellerType]) prev.push(...curr[travellerType]);

      return prev;
    }, []);

  const remainingOptions = () => {
    const selectedAdults = getSelectedTravellers('adults', roomList);
    const selectedChildren = getSelectedTravellers('children', roomList);

    const remainingAdults = adultOptions.filter((el) => !selectedAdults?.includes(el.value));
    const remainingChildren = childOptions.filter(
      (el) => !selectedChildren?.includes(el.value),
    );

    return { remainingAdults, remainingChildren };
  };
  const { remainingAdults, remainingChildren } = remainingOptions();

  return { remainingAdults, remainingChildren };
};

export default getRemainingAdultsChildren;
