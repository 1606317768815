export const outputUrlStepOne = ({
  pathname,
  search,
  adultCount,
  childCount,
  email,
}: {
  pathname: string;
  search: string;
  adultCount: number;
  childCount: number;
  email: string;
}) =>
  `${
    pathname.split('/step-1')[0]
  }/quotation${search}&adult-count=${adultCount}&child-count=${childCount}&email=${email}`;
