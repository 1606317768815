import { clientApi } from 'api';
import { PaginatedResponse } from 'client/models/utils';
import { IArticle } from 'dashboard/_redux/reducers/blog';

export type SearchQuery<T extends unknown> = Partial<
  T & {
    keyword?: string;
    page?: number;
    limit?: number;
  }
>;

export const getArticles = (isServer = false) => async (
  searchQuery?: SearchQuery<IArticle>,
) => {
  const { data } = await clientApi.get<PaginatedResponse<IArticle>>('/blog', {
    params: searchQuery,
  });

  return data;
};
