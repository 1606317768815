import { useFormContext } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';

export const useFilterAttributesSelection = (
  rawOptions: ISelectorOption<string>[],
  filterIndex: number,
  attributeIndex: number,
) => {
  const { watch } = useFormContext();

  const attributes = watch('filters')?.[filterIndex]?.attributes;
  const selectedAttribute = rawOptions?.find(
    (attribute) => attribute.value === attributes?.[attributeIndex],
  );
  const options = rawOptions?.filter(
    (option) =>
      !(attributes as string[])?.find(
        (attribute) => attribute === option.value && attribute !== selectedAttribute?.value,
      ),
  );

  return { options };
};
