import { clientApi } from 'api';
import { IHomeProduct } from 'server/models/HomeProducts/types';
import { IPaginatedResult } from 'types/IPaginatedResult';
import { IProductsFilterParams } from 'types/IProductsFilterParams';

export const getAllProducts = (isServer = false) => async (
  page: number,
  filter?: IProductsFilterParams,
) => {
  const response = await clientApi.get<IPaginatedResult<IHomeProduct>>(
    '/home-products/all-products',
    {
      params: {
        page,
        ...filter,
      },
    },
  );

  return response;
};
