import FlightDetailsCard from 'client/components/FlightDetailsCard';
import Modal from 'client/components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlightMajorObject,
  FlightSingleDetail,
} from 'server/helpers/soap/formatCreateBookingSoapResponse/types';
import FlightDetailCollapsible from './FlightDetailCollapsible';
import FlightDetailModalCollapsible from './FlightDetailModalCollapsible';
import useFlightDetailsContainer from './useFlightDetailsContainer';
interface IFlightDetailsContainerProps {
  isReservationDetailsPage?: boolean;
  flightDetails?: FlightMajorObject;
  selected?: boolean;
}
const FlightDetailsContainer: React.FC<IFlightDetailsContainerProps> = ({
  flightDetails,
  isReservationDetailsPage,
  selected = false,
}) => {
  const [t] = useTranslation();

  const {
    tripTransportation,
    flightIndex,
    handleChange,
    open,
    setOpen,
    handleClose,
    handleValidate,
    transportationId,
  } = useFlightDetailsContainer(selected);

  return (
    <div className="border rounded">
      {!selected && (
        <div className="container m-3 w-100">
          <h3>{t('FlightDetailsCard.chooseYourFlightOptions')}</h3>
          <Modal
            closeModal={() => handleClose(transportationId)}
            isOpen={open}
            modalHead={t('FlightDetailsCard.chooseYourFlightOptions')}
            flightOptions
            footer={
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-success m-2 "
                  onClick={handleValidate}
                >
                  {t('FlightDetailsCard.submit')}
                </button>
              </div>
            }
          >
            {tripTransportation &&
              tripTransportation.map((data, index) => (
                <div
                  className={`mb-4 p-2 rounded ${
                    index === flightIndex ? 'border border-primary' : 'border'
                  }`}
                  onClick={() => handleChange(data.transportationId)}
                  style={{ cursor: 'pointer' }}
                  key={data.transportationId}
                >
                  <div className="d-flex justify-content-end">
                    <span className="h4">
                      {data.price.totalPrice} {data.price.currency}
                    </span>
                  </div>
                  <FlightDetailModalCollapsible
                    data={data}
                    index={index}
                    flightIndex={flightIndex}
                  />
                </div>
              ))}
          </Modal>
        </div>
      )}
      <div className="extra-info-flight-details">
        <span>
          {t('FlightDetailsCard.extraInfo')}
          <i className="fas fa-info-circle" />
        </span>
      </div>
      {!isReservationDetailsPage
        ? tripTransportation &&
          tripTransportation[flightIndex as number].details.map((tripDetail) => (
            <div style={{ cursor: 'pointer' }} key={JSON.stringify(tripDetail)}>
              <FlightDetailCollapsible tripDetail={tripDetail} />
            </div>
          ))
        : (flightDetails?.details as FlightSingleDetail[]).map((el) => (
            <FlightDetailsCard
              flightDetails={{
                ...el,
                to: { title: el.to, code: '' },
                from: { title: el.from, code: '' },
                direction: flightDetails?.direction as string,
                carrier: { title: el.carrierName, code: el.carrierCode },
                start: el.begin,
              }}
              key={`${el.begin}${el.end}${el.flightNumber}`}
            />
          ))}
      <div className="d-flex justify-content-end">
        <button type="button" className="btn btn-primary m-2" onClick={() => setOpen(true)}>
          {t('FlightDetailsCard.options')}
        </button>
      </div>
    </div>
  );
};

export default FlightDetailsContainer;
