import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { format, addDays, getYear } from 'date-fns';
import { fr } from 'date-fns/locale';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import { removeDuplicatesObjectFromArray } from 'client/helpers/removeDuplicatesObjectFromArray';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { IFormattedCataloguePrices } from 'server/models/FormattedCataloguePrices/types';
interface IProps {
  options?: { date?: Date; value?: string | number }[];

  handleSelect: (e: string) => void;

  handleClose?: () => void;
  value?: string;
  setWatchPrice: React.Dispatch<React.SetStateAction<string | number | undefined>>;
}
export const useSelectListModal = ({
  handleSelect,
  handleClose,
  value,
  setWatchPrice,
  options,
}: IProps) => {
  const { watch } = useFormContext();
  const tripDuration = useAppSelector(
    ({ productReducer }) => productReducer.cataloguePrices?.duration,
  );
  const onSelect = (o: IFormattedCataloguePrices['prices'][0]) => {
    value = format(new Date(o?.date || ''), 'yyyy-MM-dd');
    setWatchPrice(o?.value);
    handleSelect(value);
    handleClose?.();
  };

  useEffect(() => {
    if (options !== undefined) {
      const lowestPrice = options
        .reduce(
          (prev, curr) => (Number(curr.value) < prev ? Number(curr.value) : prev),
          Infinity,
        )
        .toString();
      const lowestPriceDate = options.find((e) => e.value === lowestPrice)?.date;

      handleSelect(format(new Date(lowestPriceDate || 0), 'yyyy-MM-dd'));
      setWatchPrice(lowestPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  const formatedOptions = useMemo(
    () =>
      options &&
      options?.map((o) => {
        const day = getFrenchDay(new Date(String(o?.date)));
        const dayInMonth = getDayInMonth(new Date(String(o?.date)));
        const month = format(new Date(o?.date || ''), 'MMMM').toUpperCase();
        const year = getYear(new Date(String(o?.date)));
        const duration = tripDuration;
        const dateDeRetour = addDays(new Date(String(o?.date)), Number(duration));
        const dayRetour = getFrenchDay(dateDeRetour);
        const dayInMonthRetour = getDayInMonth(dateDeRetour);

        return {
          day,
          dayInMonth,
          dayRetour,
          dayInMonthRetour,
          price: o.value,
          option: o,
          month,
          year,
        };
      }),
    [options, tripDuration],
  );
  const selectYearsAndMonth = removeDuplicatesObjectFromArray(
    formatedOptions?.map((e) => ({ year: e.year, month: e.month })),
  );

  const selectOptions = selectYearsAndMonth?.map((e) => ({
    month: e.month,
    year: e.year,
    days: formatedOptions?.filter((el) => el.month === e.month && el.year === e.year),
  }));

  return { watch, onSelect, selectOptions };
};

const getFrenchDay = (date: Date) =>
  upperCaseFirstLetter(format(date, 'EEEE', { locale: fr }));
const getDayInMonth = (date: Date) => format(date, 'dd');
