import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICreateAdminFormValues } from '.';

const useAdminSignup = (onSubmit: SubmitHandler<ICreateAdminFormValues>) => {
  const [t] = useTranslation();
  const formMethods = useForm<ICreateAdminFormValues>({ shouldFocusError: true });
  const { handleSubmit, watch } = formMethods;
  const password = watch('password') || '';
  const _onSubmit: SubmitHandler<ICreateAdminFormValues> = (data) => {
    onSubmit(data);
  };

  return { t, formMethods, handleSubmit, password, _onSubmit };
};

export default useAdminSignup;
