import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCountry, getCountries } from 'dashboard/_redux/actions/countries';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const useCountriesCardsContainer = () => {
  const dispatch = useDispatch();
  const { countries } = useAppSelector((state) => state.dashboardCountries);
  let titleIcon = '';

  titleIcon = 'fas fa-flag';

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  const handleDelete = (id?: string) => {
    dispatch(deleteCountry(id));
  };

  return { countries, titleIcon, handleDelete };
};
