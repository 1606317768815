import * as redux from 'redux';
import * as dashboard from 'dashboard/_redux/reducers';
import auth from './auth';
import loading from './loading';
import quotedAvailabilityReducer from './quotedAvailability';
import productReducer from './product';
import productListReducer from './productList';
import productListQuery from './productList/query';
import userReducer from './user';
import init from './init';
import countriesReducer from './countries';
import selectedTripReducer from './selectedTrip';
import searchBarSelectedFilter from './searchBarFilter';
import transactionsReducer from './transactions';
import bookingFormReducer from './bookingForm';
import productFilterReducer from './productFilter';
import promotionReducer from './promotion';
import flightsReducer from './flight';
import blog from './blogArticles';
import blogCoverAndQuery from './blogArticles/query';

const rootReducer = redux.combineReducers({
  loading,
  init,
  countriesReducer,
  auth,
  productReducer,
  productListReducer,
  quotedAvailabilityReducer,
  userReducer,
  selectedTripReducer,
  searchBarSelectedFilter,
  transactionsReducer,
  productFilterReducer,
  bookingFormReducer,
  promotionReducer,
  productListQuery,
  flightsReducer,
  blog,
  blogCoverAndQuery,
  ...dashboard,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
