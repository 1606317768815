import React from 'react';
interface IProps {
  info: string;
  phone: string | number;
}

const NavbarInfo: React.FC<IProps> = ({ info, phone }) => (
  <>
   <div style={{ minHeight: '35px' }} className="d-flex border border-primary">
      <div className="py-1 col-12 col-lg-12 bg-primary text-white text-center font-weight-bold responsive-d-none-down">
        <span >{info}</span>

        <a className="text-secondary" href={`tel:${phone}`} style={{marginLeft:'10px'}}>
        <i className="fas fa-phone-alt mr-2" />
            {phone}
          </a>
          <span style={{margin:'10px'}}>ou</span>
          <a href="/contact-us" className="text-secondary">
          <i className="fas fa-envelope mr-2" />
          Contactez Nous</a>
          
      </div>
    </div>
  </>
);

export default NavbarInfo;
