import { getFromData } from 'client/helpers/getFromData';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Filter, IExhibitedProduct } from 'server/models/ExhibitedProduct/types';

type Attributes = {
  attribute: string;
  filterId: string;
};
export interface IProductEditingValues {
  name: string;
  shortDescription: string;
  mainDescription: { text: string }[];
  icons: { icon: string; title: string }[];
  imageIndex?: number;
  attributes: Attributes[];
  filters?: Filter[];
}
export const useProductEditor = (
  onSubmit: SubmitHandler<IProductEditingValues>,
  selectedEditingProduct?: IExhibitedProduct,
) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [image, setImage] = useState(
    selectedEditingProduct?.mainMedia || selectedEditingProduct?.media?.[0] || '',
  );

  const formMethods = useForm<IProductEditingValues>({
    shouldFocusError: true,
    defaultValues: selectedEditingProduct,
  });
  const formData = new FormData();

  getFromData(formData, selectedEditingProduct);
  const { handleSubmit, watch, register, reset } = formMethods;

  const _onSubmit: SubmitHandler<IProductEditingValues> = (data) => {
    const filters = data.filters ? data.filters : [];
    const filtersWithNoAttributes = data.filters?.find(
      (filter) => filter.attributes === undefined,
    );

    if (filtersWithNoAttributes)
      toast('Veuillez sélectionner au moins un attribut par filtre choisi', { type: 'error' });
    else onSubmit({ ...data, imageIndex, filters });
  };
  const handleChangeMainImage = (index: number) => {
    setImage(selectedEditingProduct?.media?.[index] || '');
    setImageIndex(index);
  };

  useEffect(() => {
    reset(selectedEditingProduct);

    return () => {
      reset(selectedEditingProduct);
    };
  }, [selectedEditingProduct, reset]);

  return {
    handleSubmit,
    formMethods,
    _onSubmit,
    watch,
    handleChangeMainImage,
    imageIndex,
    register,
    image,
  };
};
