import React from 'react';
import { ISelectorOption } from 'types/ISelectorOption';
import { FormProvider } from 'react-hook-form';
import SelectListModalPrices from 'client/components/Form/SelectListModalPrices';
import SelectListModalVoyageur from 'client/components/Form/SelectListModalVoyageur';
import { ICustomCatalogue } from 'types/ICatalogue';
import { useSelectListModalConatiner } from './useSelectListModalConatiner';
import SelectListModal from '../../components/Form/SelectListModal';

export interface ProductPriceFilterIProps {
  product: ICustomCatalogue;
  from: ISelectorOption[];
  fromAvailability?: ISelectorOption[];
}

const SelectListModalConatiner = ({ from }: ProductPriceFilterIProps) => {
  const {
    formMethods,
    t,
    fromAvailability,
    nightAvailability,
    minimumPrice,
    pricesList,
    handleSubmit,
    _onSubmit,
    watchPrice,
    setWatchPrice,
    minPrice,
  } = useSelectListModalConatiner(from);

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatinerindex.tsx:28 pricesList',
  //   'color: #007acc;',
  //   pricesList,
  // );

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatinerindex.tsx:28 minimumPrice',
  //   'color: #007acc;',
  //   minimumPrice,
  // );

  return (
    <div className="card px-4 border pb-3">
      <div className="text-center py-3 mb-3 border-bottom border-primary h3">
        {t('searchBar.hotelSearchForm.reservezCeSéjour')}
      </div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <SelectListModal
            options={fromAvailability || []}
            label={t('searchBar.hotelSearchForm.lieuDeDepart').toUpperCase()}
            name="from"
            classNameIcon="fas fa-map-marker-alt"
          />
          <SelectListModal
            options={nightAvailability?.nights || []}
            label={t('searchBar.hotelSearchForm.choixDeLapériodeDeSejour').toUpperCase()}
            name="numberOfNights"
            classNameIcon="far fa-calendar-alt "
          />
          <SelectListModalPrices
            isMinimumPrice={(Number(watchPrice) || 0) as number}
            options={pricesList || []}
            label={t('productPrices.DateDeDépart').toUpperCase()}
            classNameIcon="fas fa-calendar-day"
            name="start-date"
            setWatchPrice={setWatchPrice}
          />
          <SelectListModalVoyageur
            label={'voyageurs'.toUpperCase()}
            classNameIcon="fas fa-user-friends"
          />
          <div className="text-center">
            <div className="d-flex w-100 justify-content-center">
              <h2 className="h1 text-secondary">{Math.round(Number(minPrice || 0))}€</h2>
              <div
                className="mt-2 mb-auto text-secondary ml-1 ttc_font_size"
                style={{
                  fontSize: '12px',
                  lineHeight: '10px',
                  position: 'relative',
                }}
              >
                TTC/<span className="d-block text-secondary font-weight-bold">pers</span>
              </div>
            </div>
            <button type="submit" className="font-size-2 btn btn-primary mt-2 w-100">
              {t('ProductDetailsPriceStepOne.Book')}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SelectListModalConatiner;
