import { useAppSelector } from 'client/hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SelectedTripTypes } from 'client/_redux/types/selectedTripTypes';
import { formatTransportationDetails } from 'client/helpers/formatTransportationDetails';

const useFlightDetailsContainer = (selected: boolean) => {
  const dispatch = useDispatch();
  const [flightIndex, setFlightIndex] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [transportationId, setTransportationId] = useState<number>(0);
  const handleClose = (id: number) => {
    setFlightIndex(transportationId);
    setOpen(!open);
  };
  const { tripTransportation, selectedTransportationRef } = useAppSelector(
    ({ quotedAvailabilityReducer: { trip }, selectedTripReducer: { transportation } }) => ({
      tripTransportation: trip?.details?.transportation,
      selectedTransportationRef: transportation?.ref,
    }),
  );
  const handleChange = (e: string) => {
    const index = tripTransportation?.findIndex((el) => el.transportationId === e);

    if (index !== undefined) setFlightIndex(index);
  };
  const handleValidate = () => {
    setTransportationId(flightIndex);
    setOpen(!open);
  };

  useEffect(() => {
    const index = tripTransportation?.findIndex(
      (el) => el.transportationId === selectedTransportationRef,
    );

    if (index !== undefined && index >= 0) setFlightIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !selected &&
      tripTransportation &&
      dispatch(
        dispatch({
          type: SelectedTripTypes.SET_TRANSPORTATION,
          payload: formatTransportationDetails(tripTransportation[flightIndex]),
        }),
      );
  }, [dispatch, flightIndex, selected, tripTransportation]);
  if (!tripTransportation)
    return {
      start: '',
      end: '',
      from: 0,
      to: 0,
      flightNumber: '',
      carrier: 0,
      direction: '',
      handleChange,
      open,
      setOpen,
      handleClose,
      handleValidate,
      transportationId,
    };

  return {
    tripTransportation,
    flightIndex,
    handleChange,
    open,
    setOpen,
    handleClose,
    handleValidate,
    transportationId,
  };
};

export default useFlightDetailsContainer;
