import React from 'react';

const Loader: React.FC = () => (
  <svg
    role="img"
    width={100}
    height={20}
    aria-labelledby="loading-ariaKeywords"
    viewBox="0 0 auto auto"
    preserveAspectRatio="none"
  >
    <title id="loading-ariaKeywords">Loading...</title>
    <rect
      x={0}
      y={0}
      width="100%"
      height="100%"
      clipPath="url(#clip-pathKeywords)"
      style={{
        fill: 'url("#fillKeywords")',
      }}
    />
    <defs>
      <clipPath id="clip-pathKeywords">
        <rect x={0} y={0} rx={5} ry={5} width={80} height={20} />
      </clipPath>
      <linearGradient id="fillKeywords">
        <stop offset={0.599964} stopColor="#d6d6d6" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={1.59996} stopColor="#fcfcff" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={2.59996} stopColor="#d6d6d6" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export default Loader;
