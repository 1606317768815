import Input from 'client/components/Form/Input';
import SectionTitle from 'client/components/SectionTitle';
import { ICON_LIST_OPTIONS } from 'client/constants/iconsList/iconListOption';
import Validators from 'client/helpers/formValidator';
import { IProductDetailsEditingValues } from 'dashboard/containers/ProductDetailsEditor/useProductDetailsEditor';
import React from 'react';
import { ArrayField, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormIconSelect from '../Forms/FormIconSelect';

type ServicesIconsProps = {
  fields: Partial<ArrayField<Record<string, string>, 'id'>>[];
  removeSection: (index?: number | number[] | undefined) => void;
  addSection: (
    value: Partial<Record<string, string>> | Partial<Record<string, string>>[],
  ) => void;
  formMethods: UseFormMethods<IProductDetailsEditingValues>;
};

const ServicesIcons: React.FC<ServicesIconsProps> = ({
  fields,
  removeSection,
  addSection,
  formMethods,
}) => {
  const [t] = useTranslation();
  const { watch } = formMethods;

  return (
    <div>
      <SectionTitle title={t('productDetails.SelectServicesIcons')} />

      <div className="row mb-5">
        {fields.map((item, index) => (
          <div key={item.id} className="col-md-3 border  ">
            <div className="p-3 mb-2 ">
              {watch(`services.${index}.icon`) && (
                <div className="d-flex flex-column align-items-center w-100">
                  <img width="45px" src={watch(`services.${index}.icon`)} alt="grat" />
                </div>
              )}
              <Input
                name={`services.${index}.title`}
                placeholder={t('productDetails.SelectServicesIconsTitle')}
                validate={Validators([{ validation: 'required' }])}
              />

              <FormIconSelect
                isSearchable
                isSearchBar
                isClearable
                options={ICON_LIST_OPTIONS}
                name={`services.${index}.icon`}
              />
            </div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => removeSection(index)}
            >
              {t('productDetails.deleteField')}
            </button>
            <hr />
          </div>
        ))}
      </div>
      <div className=" text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => addSection({ title: '', content: '', day: '' })}
        >
          {t('productDetails.addField')}
        </button>
      </div>
    </div>
  );
};

export default ServicesIcons;
