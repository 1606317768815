import ProductEditor from 'dashboard/containers/ProductEditor';
import React from 'react';
import { useEditProductDashboard } from './useEditProductDashboard';

const EditProductDashboard = () => {
  const {
    exhibitedProduct,
    handleUpdateExhibitedProduct,
    loading,
  } = useEditProductDashboard();

  if (!exhibitedProduct) return null;

  return (
    <ProductEditor
      loading={loading}
      selectedEditingProduct={exhibitedProduct}
      onSubmit={handleUpdateExhibitedProduct}
    />
  );
};

export default EditProductDashboard;
