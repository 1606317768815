import React from 'react';
import ProductDetailsEditor from 'dashboard/containers/ProductDetailsEditor';
import { IProductInfo } from 'server/models/ProductInfo/types';
import { useEditProductDetailsDashboard } from './useEditProductDetailsDashboard';

const EditProductDetailsDashboard = () => {
  const {
    productInfo,
    id,
    handleUpdateProductDetails,
    permalink,
    loading,
  } = useEditProductDetailsDashboard();

  return loading ? (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  ) : (
    <ProductDetailsEditor
      id={id}
      permalink={permalink}
      selectedEditingProduct={productInfo as IProductInfo}
      onSubmit={handleUpdateProductDetails}
    />
  );
};

export default EditProductDetailsDashboard;
