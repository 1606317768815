import { clientApi } from 'api';
import { IFormattedQuotedAvailability } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { IQuotedAvailabilityParams } from 'types/IQuotedAvailabilityRequest';

export const getQuotation = (isServer = false) => async (
  params: IQuotedAvailabilityParams,
) => {
  const response = await clientApi.get<IFormattedQuotedAvailability>(`/quoted-availability`, {
    params,
  });

  return response.data;
};
