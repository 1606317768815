import { IReduxAction } from 'types/IReduxAction';
import { HomeProductsTypes } from 'dashboard/_redux/types/homePageProductsTypes';
import {
  ExhibitedProductDashboardTypes,
  ProductTypes,
} from 'dashboard/_redux/types/productTypes';
import { PaginatedResponse } from 'client/models/utils';
import { IHomeProduct } from 'server/models/HomeProducts/types';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
import { IPaginatedResult } from 'types/IPaginatedResult';

const initialState = {
  loading: true,
  homeProducts: [],
  products: [],
};

export interface IProductListInterface extends Partial<PaginatedResponse<IHomeProduct>> {
  homeProducts: IHomeProduct[];
  products: IHomeProduct[];
  loading?: boolean;
  exhibitedProducts?: IPaginatedResult<IExhibitedProduct>;
}

export default (
  state: IProductListInterface = initialState,
  { type, payload }: IReduxAction,
): IProductListInterface => {
  switch (type) {
    case ProductTypes.GET_PRODUCTS_START:
      return { ...state, loading: true };
    case ProductTypes.GET_PRODUCTS_FAILED:
      return { ...state, loading: false };
    case ProductTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload.items,
      };
    case ExhibitedProductDashboardTypes.GET_EXHIBITED_PRODUCTS_SUCCESS:
      return { ...state, exhibitedProducts: payload, loading: false };
    case HomeProductsTypes.GET_HOME_PRODUCTS_START:
      return { ...state, loading: true };
    case HomeProductsTypes.GET_HOME_PRODUCTS_FAILED:
      return { ...state, loading: false };
    case HomeProductsTypes.GET_HOME_PRODUCTS_SUCCESS:
      return { ...state, homeProducts: payload, loading: false };
    case HomeProductsTypes.ADD_HOME_PRODUCTS_START:
      return { ...state, loading: true };
    case HomeProductsTypes.ADD_HOME_PRODUCTS_FAILED:
      return { ...state, loading: false };
    case HomeProductsTypes.ADD_HOME_PRODUCTS_SUCCESS:
      return { ...state, homeProducts: [...state.homeProducts, payload], loading: false };
    case HomeProductsTypes.DELETE_HOME_PRODUCTS_START:
      return { ...state, loading: true };
    case HomeProductsTypes.DELETE_HOME_PRODUCTS_FAILED:
      return { ...state, loading: false };
    case HomeProductsTypes.DELETE_HOME_PRODUCTS_SUCCESS:
      return {
        ...state,
        homeProducts: [...state.homeProducts.filter((product) => product._$id !== payload)],
        loading: false,
      };

    default:
      return state;
  }
};
