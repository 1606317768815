import { Switch } from 'react-router-dom';
import * as React from 'react';
import LoginDashboard from 'dashboard/pages/LoginDashboard';
import CreateAdmin from 'dashboard/pages/SignupDashboard';
import UserListDashboard from 'dashboard/pages/UserListDashboard';
import { SidebarSafeAreaView } from '@edonec/sidebar';
import Sidebar from 'dashboard/containers/Sidebar';
import EditProductDashboard from 'dashboard/pages/EditProductDashboard';
import EditProductDetailsDashboard from 'dashboard/pages/EditProductDetailsDashboard';
import EditListDashboard from 'dashboard/pages/EditListDashboard';
import UpdateProductFilter from 'dashboard/pages/UpdateProductFilter';
import AddProductFilter from 'dashboard/pages/AddProductFilter';
import ProductFilterDashboard from 'dashboard/pages/ProductFilterDashboard';
import CountriesPage from 'dashboard/pages/CountriesPageDashboard';
import PromotionsPage from 'dashboard/pages/PromotionsPage';
import BlogDashboard from 'dashboard/pages/BlogDashboard';
import AddArticlePage from 'dashboard/pages/AddArticlePage';
import AddBlogCoverPage from 'dashboard/pages/AddBlogCoverPage';
import { useDashboardRouter } from './useDashboardRouter';
import LoggedOutRoute from './LoggedOutRoute';
import PrivateRoute from './PrivateRoute';
import AddHomeProducts from '../AddHomeProducts';
import UpdateArticle from '../UpdateArticle';
const DashboardRoot = () => {
  const { initiated } = useDashboardRouter();

  return initiated ? (
    <SidebarSafeAreaView>
      <Sidebar />
      <div className="container container-xxl">
        <Switch>
          <PrivateRoute exact path="/dashboard/home" component={AddHomeProducts} />
          <PrivateRoute exact path="/dashboard/promotion" component={PromotionsPage} />
          <PrivateRoute exact path="/dashboard/destination/add" component={CountriesPage} />
          <LoggedOutRoute exact path="/dashboard/login" component={LoginDashboard} />
          <PrivateRoute exact path="/dashboard/admin/add" component={CreateAdmin} />
          <PrivateRoute exact path="/dashboard/admin/list" component={UserListDashboard} />
          <PrivateRoute
            exact
            path="/dashboard/filter-products"
            component={ProductFilterDashboard}
          />
          <PrivateRoute
            exact
            path="/dashboard/filter-products/add"
            component={AddProductFilter}
          />
          <PrivateRoute
            exact
            path="/dashboard/filter-products/edit/:id"
            component={UpdateProductFilter}
          />
          <PrivateRoute exact path="/dashboard/product/edit" component={EditListDashboard} />
          <PrivateRoute
            exact
            path="/dashboard/product-details/edit"
            component={EditListDashboard}
          />
          <PrivateRoute
            exact
            path="/dashboard/product/edit/:permalink"
            component={EditProductDashboard}
          />
          <PrivateRoute
            exact
            path="/dashboard/product-details/edit/:id/:permalink"
            component={EditProductDetailsDashboard}
          />
          <PrivateRoute exact path="/dashboard/blog/" component={BlogDashboard} />
          <PrivateRoute exact path="/dashboard/blog/add" component={AddArticlePage} />
          <PrivateRoute exact path="/dashboard/blog/add-cover" component={AddBlogCoverPage} />
          <PrivateRoute exact path="/dashboard/blog/edit/:id" component={UpdateArticle} />
        </Switch>
      </div>
    </SidebarSafeAreaView>
  ) : (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  );
};

export default DashboardRoot;
