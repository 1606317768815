import { clientApi } from 'api';
import { IFilter } from 'server/models/Filter/types';

export const updateProductFilter = (isServer = false) => async (
  id: string,
  value: IFilter,
) => {
  const ProductFilter = await clientApi.put<IFilter>(`/filter-products/${id}`, value);

  return ProductFilter;
};
