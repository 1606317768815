import Validators from 'client/helpers/formValidator';
import React from 'react';
import { ISelectorOption } from 'types/ISelectorOption';
import FilterSelect from '../Forms/FilterSelect';
import { useFilterAttributesSelection } from './useFilterAttributeSelection';

type FilterAttributesSelectionProps = {
  attributeIndex: number;
  rawOptions: ISelectorOption<string>[];
  filterIndex: number;
  remove: (index?: number | number[] | undefined) => void;
};
const FilterAttributesSelection: React.FC<FilterAttributesSelectionProps> = ({
  rawOptions,
  filterIndex,
  attributeIndex,
  remove,
}) => {
  const { options } = useFilterAttributesSelection(rawOptions, filterIndex, attributeIndex);

  return (
    <div>
      <i
        onClick={() => remove(attributeIndex)}
        className="fas fa-times-circle pl-2 close-icon"
      />
      <div className="pt-3">
        <FilterSelect
          validate={Validators([{ validation: 'required' }])}
          isSearchable
          isSearchBar
          isClearable
          options={options}
          name={`filters[${filterIndex}].attributes[${attributeIndex}].`}
        />
      </div>
    </div>
  );
};

export default FilterAttributesSelection;
