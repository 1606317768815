import EditProductList from 'dashboard/containers/EditProductList';
import React from 'react';
import { useLocation } from 'react-router';

const EditListDashboard = () => {
  const { pathname } = useLocation();

  return <EditProductList pathName={pathname} />;
};

export default EditListDashboard;
