import * as React from 'react';
import SEO from 'client/components/SEO';
import ProductDetailsPriceStepOne from 'client/containers/ProductDetailsPriceStepOne';
import ProductListCardContainer from 'client/containers/ProductListCardContainer';
import Loader from 'client/containers/ProductListCardContainer/Loader';
import { useStepOne } from './useStepOne';

const StepOne: React.FC = () => {
  const { product, loading, from, numberOfNight, startDate, fromQuery } = useStepOne();

  return (
    <>
      <SEO title="Step One" description="Product Details Price Step One" />

      <div className="rounded shadow m-lg-11 m-5  p-5">
        {product && !loading ? (
          <>
            <div className="mb-3">
              <ProductListCardContainer fromQuery={fromQuery} product={product} hiddenPrice />
            </div>
            <div>
              <ProductDetailsPriceStepOne
                startDate={startDate}
                from={from}
                numberOfNight={numberOfNight}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default StepOne;
