/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
import formattedHomeFilterValue from 'client/helpers/formattedHomeFilterValue';
import { useState, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getProductfilters } from 'client/_redux/actions/productFilter';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { SearchFilterDefaultValueEnum } from 'client/constants/SearchFilterDefaultValueEnum/SearchFilterDefaultValueEnum';

export interface IfilterSearch {
  tripType: string;
  destination: string;
  startDate: string;
  from: string;
  duration: string;
}

const useFilterHomePage = (isFlightPage?: boolean) => {
  const history = useHistory();
  const [responsiveMode, setResponsiveMode] = useState(false);
  const dispatch = useDispatch();
  const { productsFilter, flightsData } = useAppSelector(
    ({ productFilterReducer, flightsReducer }) => ({
      productsFilter: productFilterReducer.productsFilter,
      flightsData: {
        departureDate: flightsReducer.departureDate,
        returningDate: flightsReducer.returningDate,
        destinationCountry: flightsReducer.destinationCountry,
        departureAerport: flightsReducer.departureAerport,
        pax: flightsReducer.pax,
      },
    }),
  );
  const formMethods = useForm<IfilterSearch>({
    defaultValues: {
      tripType: SearchFilterDefaultValueEnum.SÉJOUR,
      destination: flightsData.destinationCountry || SearchFilterDefaultValueEnum.PeuImporte,
      from: flightsData.departureAerport || SearchFilterDefaultValueEnum.TousLesAéroports,
      startDate: SearchFilterDefaultValueEnum.NImporteQuand,
      duration: SearchFilterDefaultValueEnum.PeuImporte,
    },
  });
  const { handleSubmit, watch } = formMethods;
  const flightTabActivated = useMemo(() => watch('tripType') === 'VOLS', [watch]);

  useEffect(() => {
    dispatch(getProductfilters());
  }, [dispatch]);

  const productSearchCriteriaValue = productsFilter?.productsCriteria;
  const onSubmit: SubmitHandler<IfilterSearch> = (values) => {
    const formattedValues = formattedHomeFilterValue(values, productSearchCriteriaValue);

    history.push(
      `/products?${formattedValues.from ? `from=${formattedValues.from}&` : ''}${
        formattedValues['product-type']
          ? `product-type=${formattedValues['product-type']}&`
          : ''
      }${formattedValues.duration ? `duration=${formattedValues.duration}&` : ''}${
        formattedValues.to ? `country=${formattedValues.to}` : ''
      }`.replace(/&+$/, ''),
    );
  };
  const titleSelected = useAppSelector(
    ({ searchBarSelectedFilter }) => searchBarSelectedFilter.filterSelected.title,
  );

  useEffect(() => {
    if (window == undefined) return;
    setResponsiveMode(window.matchMedia('(max-width: 992px)').matches);
    window
      ?.matchMedia?.('(max-width: 992px)')
      ?.addEventListener?.('change', (e) => setResponsiveMode(e.matches));

    return () => {
      if (window == undefined) return;
      window
        .matchMedia?.('(max-width: 992px)')
        ?.removeEventListener?.('change', (e) => setResponsiveMode(e.matches));
    };
  }, []);

  return {
    formMethods,
    handleSubmit,
    onSubmit,
    responsiveMode,
    titleSelected,
    productSearchCriteriaValue,
    flightTabActivated,
  };
};

export default useFilterHomePage;
