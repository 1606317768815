import FlightClientCredential from 'client/components/FlightClientCredential';
import FligthExpandableCard from 'client/components/FligthExpandableCard';
import SEO from 'client/components/SEO';
import React from 'react';
import { TFlightResponse } from 'server/services/FlightsService';
import FlightsReservationDetailsFooter from './FlightsReservationDetailsFooter';
import { useFlightReservationDetails } from './useFlightReservationDetails';

const FlightsReservationDetails = () => {
  const { flights, t, passengers } = useFlightReservationDetails();

  return flights && Object.keys(flights) ? (
    <>
      <SEO title="Flights reservation details page" />
      <div className="container my-5">
        <div className="card reservation-details-card-1 mb-5">
          <div className="card-header bg-white">
            <div className="media flex-sm-row flex-column-reverse justify-content-between ">
              {!!passengers && (
                <h4 className="mb-0">
                  {t('reservationDetails.thanks')}
                  <span className="reservation-details-change-color">
                    {' '}
                    {passengers[0].last_name} {passengers[0].first_name}{' '}
                  </span>{' '}
                  !
                </h4>
              )}
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-between mb-3">
              <div className="col-auto">
                <h3 className="color-1 mb-0 reservation-details-change-color">
                  {t('reservationDetails.receipt')} N°: {flights._id}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="container pl-5 mb-5 ml-2">
                <div className="row pt-5 pb-3">
                  <p className="mb-1 text-dark">
                    <b>{t('reservationDetails.orderDetails')}</b>
                  </p>
                </div>
                {flights.flights[1] ? (
                  <>
                    <p className="row mb-1">
                      <span className="font-weight-bolder">Departure date : </span>
                      {flights.flights[0].departure_date}
                    </p>
                    <p className="row mb-1">
                      <span className="font-weight-bolder">Arrival date : </span>
                      {flights.flights[1].departure_date}
                    </p>
                  </>
                ) : (
                  <p className="row mb-1">
                    <span className="font-weight-bolder">Departure date : </span>
                    {flights.flights[0].departure_date}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-5">
              <div className="px-lg-2 px-md-2 pt-2 pb-3">
                <p className="mb-1 text-dark">
                  <b>{`${t('clientCredentials.credentials')} :`}</b>
                </p>
              </div>
              {passengers &&
                passengers.map((client, index) => (
                  <>
                    <div key={client.email} className="px-lg-2 px-md-2 pt-2 pb-3">
                      <p className="mb-1 text-dark">
                        <b>{`${t('clientCredentials.passenger')} ${index + 1} :`}</b>
                      </p>
                    </div>
                    <FlightClientCredential
                      clientCredentials={{
                        ...client,
                        firstName: client.first_name,
                        lastName: client.last_name,
                        email: client.email,
                        dateOfBirth: client.date_of_birth,
                        birthCountry: client.birth_country,
                        passeportNumber: client.passeport_number,
                        PassportExpiryDate: client.Passport_expiry_date,
                      }}
                    />
                  </>
                ))}
            </div>
            {!flights.flights[1] ? (
              <FligthExpandableCard
                openByDefault
                flight={flights.flights[0].originalFLightId as TFlightResponse}
              />
            ) : (
              <>
                <FligthExpandableCard
                  openByDefault
                  flight={flights.flights[0].originalFLightId as TFlightResponse}
                  isRoundTrip
                />
                <FligthExpandableCard
                  openByDefault
                  flight={flights.flights[1].originalFLightId as TFlightResponse}
                  isRoundTrip
                  isReturn
                />
              </>
            )}
          </div>
          <FlightsReservationDetailsFooter flights={flights} />
        </div>
      </div>
    </>
  ) : (
    <>
      <SEO title="Reservation details error page" image="/assets/images/logoKepler.png" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">{t('transactionNotFound.sorryMessage')}</div>
              <div className="error-actions">
                <a href="/" className="btn btn-primary btn-lg">
                  <span className="fas fa-home" />
                  {t('transactionNotFound.home')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightsReservationDetails;
