import Collapsible from 'client/components/Collapsible';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IProps {
  navList: IData[];
}

interface IData {
  title: string;
  link: string;
  list?: IData[];
}

const NavbarCollapse: React.FC<IProps> = ({ navList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isOpen) setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg text-primary align-items-center justify-content-end ">

      <button
        className="navbar-toggler bg-transparent position-relative"
        type="button"
        onClick={handleCollapse}
      >
        <i className="fas fa-bars" />
      </button>
      <nav
        className={`bg-white border ${isOpen ? 'd-block' : 'd-none'}`}
        style={{
          zIndex: 1000,
          position: 'absolute',
          top: '60px',
          width: '100vw',
          right: 0,
        }}
      >
        <div className=" d-flex justify-content-end">
          <i className="fas fa-times pr-4 pt-4" onClick={handleCollapse} />
        </div>
        <ul style={{ listStyle: 'none' }} className=" align-content-center mb-0 pl-0 w-100">
          {navList.map((e) => (
            <li key={e.title} className="h5  font-weight-bold text-left mx-5">
              {e.list ? (
                <Collapsible className="p-0 m-0" title={e.title}>
                  {e.list && (
                    <ul className="h6 px-2 rounded" style={{ listStyle: 'none' }}>
                      {e.list.map((el) => (
                        <Link to={el.link}>
                          <li className="py-2">{el.title}</li>
                        </Link>
                      ))}

                      <Link to="/checking-reservation-details">
                        <li className="fas fa-user">user</li>
                      </Link>
                    </ul>
                  )}
                </Collapsible>
              ) : (
                <Link to={e.link}>
                  <Collapsible title={e.title} />
                </Link>
              )}
            </li>
          ))}
          <li title="details de la réservation" className="h3 mx-5 my-auto" >
            <a href="https://kepler.mypgi.fr" target="__blank">
              <button
                className=" btn rounded  btn-primary border-0 w-100 text-left my-3"
                type="button"
              >
                Espace B2B
              </button>
            </a>
          </li>
          <li title="details de la réservation" className="h4 mx-5 my-auto">
          <Link to="/checking-reservation-details">
          <button  className="btn btn-primary border-0 border-0 w-100 text-left my-3"  type="button">
            Ma réservation
          </button>
          </Link>
        </li>
        </ul>
      </nav>
    </nav>
  );
};

export default NavbarCollapse;
