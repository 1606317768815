import * as React from 'react';
import { Controller, ValidationRules, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { TinyMc, TinyMcProps } from '../TinyMc';

interface IProps extends Omit<TinyMcProps, 'errors' | 'onChange' | 'value'> {
  name: string;
  validate?: ValidationRules;
  label?: string;
  placeholder?: string;
}

const TextEditor: React.FC<IProps> = ({
  label,
  name,
  validate,
  placeholder,
  ...tinyMcProps
}) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <TinyMc
          label={label}
          name={name}
          onChange={onChange}
          value={value}
          errors={get(errors, name)}
          {...tinyMcProps}
        />
      )}
    />
  );
};

export default TextEditor;
