/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCustomProducts } from 'client/_redux/actions/productsList/getProducts';
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { useLocation, useHistory } from 'react-router-dom';
import { ISearchCriteriaRouteQueryObject } from 'types/ISearchCriteriaQuery';
import { SubmitHandler, FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'client/hooks/useQuery';
import { getProductfilters } from 'client/_redux/actions/productFilter';
import { formatCategoryAttributeFilterQueryString } from 'client/helpers/format/formatCategoryAttributesFillterQueryString';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useRouteQuery } from 'client/hooks/useRouteQuery';
import { AscDescOptions } from 'client/constants/AscDescOptions';
import { formatSelectOptions } from 'client/helpers/format/formatSelectOptions';
import { ProductTypes } from 'client/_redux/types/productTypes';
import { filterValues } from '.';

export interface ICountriesOptions {
  cityOptions: { label: string; value: string }[] | [];
  countryOptions: { label: string; value: string }[] | [];
}
interface IFilterValues extends filterValues {
  duration: string[];
  'product-type': string[];
}
export const useProductListPage = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const formMethods = useForm();
  const fromQuery = useQuery('from');
  const { setQuery } = useRouteQuery();
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [showSideFilter, setShowSideFilter] = useState(false);
  const [showSideProductFilters, setShowSideProductFilters] = useState(false);
  const [countriesOptions, setCountriesOptions] = useState<ICountriesOptions>({
    cityOptions: [],
    countryOptions: [],
  });
  const { handleSubmit } = formMethods;
  const { watch, setValue } = formMethods;

  const productFilterReducer = useAppSelector(
    ({ productFilterReducer }) => productFilterReducer,
  );
  const { productsFilter } = productFilterReducer;
  const productsCriteria = productsFilter?.productsCriteria;
  const filterProducts = productsFilter?.filterProducts;
  const location = useLocation();

  useEffect(() => {
    if (showSideFilter) setShowSideFilter(false);
    if (showSideProductFilters) setShowSideProductFilters(false);
  }, [location.pathname, location.search]);

  useEffect(() => {
    dispatch({ type: ProductTypes.CLEAR_PRODUCT });
    dispatch(getProductfilters());

    dispatch(
      getCustomProducts(location.search, {
        ...searchObjectFilter,
        from: productsCriteria?.from.find((el) => el.value === searchObjectFilter.from)
          ?.value as string,
      }),
    );
  }, [dispatch, location.search]);

  const { loading, customProducts } = useAppSelector(
    ({ productListReducer }) => productListReducer,
  );

  const history = useHistory();

  useEffect(
    () =>
      history.listen((location) => {
        if (history.action === 'POP') history.replace('/');
      }),
    [],
  );

  const searchObjectFilter: ISearchCriteriaRouteQueryObject = useMemo(() => {
    const data = queryStringToObject<ISearchCriteriaRouteQueryObject>(
      decodeURI(location.search),
    );

    if (data.from)
      data.from =
        productsCriteria?.from.find((e) => e.value === data?.from)?.label || data.from;

    return data;
  }, [location.search, JSON.stringify(productsCriteria)]);

  const onSubmit: SubmitHandler<Partial<IFilterValues>> = useCallback(
    (data) => {
      data.attributes = formatCategoryAttributeFilterQueryString(data.custom);
      data.custom && delete data.custom;
      const newQuery = { ...searchObjectFilter, ...data };

      setQuery(newQuery as Partial<ISearchCriteriaRouteQueryObject>);
    },
    [productsCriteria?.from, searchObjectFilter],
  );
  const handleSubmitProductCustomFilters = (values: Record<string, string[]>) => {
    const searchUrl = formatCategoryAttributeFilterQueryString(values);

    history.push({
      pathname: '/products',
      search: `${'?'}${searchUrl}`,
    });
  };

  const totalItems = customProducts?.totalItems;
  const totalPages = customProducts?.totalPages;
  const page = customProducts?.page;

  const handleChange = useCallback(
    (value) => {
      setSortDirection(value);
    },

    [],
  );

  useEffect(() => {
    setQuery({ ...searchObjectFilter, sort: sortDirection });
  }, [sortDirection]);

  const AscDesc = AscDescOptions();

  const countryWatch = watch('country');

  useEffect(() => {
    const countryOptions = formatSelectOptions(productsCriteria?.country, 'label');

    let cityOptions: {
      label: string;
      value: string;
    }[] = [];

    if (countryWatch !== undefined) {
      setValue('city', undefined);
      const matchCountryCities = productsCriteria?.country?.filter(
        (o) => o.label === countryWatch,
      )[0]?.city;

      cityOptions = formatSelectOptions(matchCountryCities, 'title');
    } else cityOptions = formatSelectOptions(productsCriteria?.city, 'label');
    setCountriesOptions({
      countryOptions,
      cityOptions,
    });
  }, [countryWatch]);

  return {
    t,
    productsCriteria,
    products: customProducts?.items,
    loading,
    onSubmit,
    searchObjectFilter,
    FormProvider,
    formMethods,
    handleSubmit,
    fromQuery,
    filterProducts,
    showSideFilter,
    setShowSideFilter,
    showSideProductFilters,
    setShowSideProductFilters,
    handleSubmitProductCustomFilters,
    totalPages,
    totalItems,
    page,
    handleChange,
    AscDesc,
    countriesOptions,
    sortDirection,
  };
};
