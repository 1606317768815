import React from 'react';

const ReinsuranceSection = () => (
  <div
    style={{ backgroundImage: 'url(/assets/svg/components/home-paragraph.svg)' }}
    className="w-100 bg-img-hero reinsurance-background-color text-dark position-relative"
  >
    <img src="/assets/svg/logos/logo-white.svg" alt="kepler" />
    <div className="my-auto ">
      <h3 className="text-center pt-4">Kepler, votre partenaire voyage !</h3>
      <div className="container d-flex flex-column flex-md-row flex-wrap my-3">
        <div className="col-sm-6 col-md-4 mb-3 mb-sm-7">
          <div className="d-flex align-items-center mb-2">
            <div className="flex-shrink-0">
              <span className="svg-icon svg-icon-sm text-primary pr-3">
                <img src="/assets/images/home/plane.png" width={25} height={25} alt="plane" />
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <h4 className="mb-0">Des voyages d’exception</h4>
            </div>
          </div>
          <p>Des offres de voyage haut de gamme rigoureusement sélectionnées</p>
        </div>
        <div className="col-sm-6 col-md-4 mb-3 mb-sm-7">
          <div className="d-flex align-items-center mb-2">
            <div className="flex-shrink-0">
              <span className="svg-icon svg-icon-sm text-primary pr-3">
                <img src="/assets/images/home/euro.png" width={25} height={25} alt="plane" />
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <h4 className="mb-0">Les meilleures réductions</h4>
            </div>
          </div>
          <p>Des prix exclusifs et des réductions exceptionnelles.</p>
        </div>
        <div className="col-sm-6 col-md-4 mb-3 mb-sm-7">
          <div className="d-flex align-items-center mb-2">
            <div className="flex-shrink-0">
              <span className="svg-icon svg-icon-sm text-primary pr-3">
                <img
                  src="/assets/images/home/support.png"
                  width={25}
                  height={25}
                  alt="plane"
                />
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <h4 className="mb-0">Nos experts à votre service</h4>
            </div>
          </div>
          <p>Nos équipes sont spécialement formées pour répondre à toutes vos questions</p>
        </div>
        <div className="col-sm-6 col-md-4 mb-3 mb-sm-7">
          <div className="d-flex align-items-center mb-2">
            <div className="flex-shrink-0">
              <span className="svg-icon svg-icon-sm text-primary pr-3">
                <img src="/assets/images/home/team.png" width={25} height={25} alt="plane" />
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <h4 className="mb-0">95% de clients satisfaits</h4>
            </div>
          </div>
          <p>Votre satisfaction est notre priorité !</p>
        </div>
        <div className="col-sm-6 col-md-4 mb-3 mb-sm-7">
          <div className="d-flex align-items-center mb-2">
            <div className="flex-shrink-0">
              <span className="svg-icon svg-icon-sm text-primary pr-3">
                <img
                  src="/assets/images/home/credit-card-payment.png"
                  width={25}
                  height={25}
                  alt="plane"
                />
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <h4 className="mb-0">Facilité de paiement</h4>
            </div>
          </div>
          <p>Des moyens de paiement adaptés et la possibilité de payer en plusieurs fois</p>
        </div>
        <div className="col-sm-6 col-md-4 mb-3 mb-sm-7">
          <div className="d-flex align-items-center mb-2">
            <div className="flex-shrink-0">
              <span className="svg-icon svg-icon-sm text-primary pr-3">
                <img
                  src="/assets/images/home/secured.png"
                  width={25}
                  height={25}
                  alt="plane"
                />
              </span>
            </div>
            <div className="flex-grow-1 ms-3">
              <h4 className="mb-0">Paiement sécurisé</h4>
            </div>
          </div>
          <p>Une réservation simple et un paiement en ligne 100% sécurisé</p>
        </div>
      </div>
    </div>
    <div className="d-flex flex-row justify-content-center footer-msg col-md-12 col-10 offset-md-0 offset-1">
      <img src="/assets/images/home/treasure.png" width={45} height={45} alt="plane" />
      <div className="pl-3">
        <strong>Soyez prêt à vivre votre plus belle évasion…</strong>
      </div>
    </div>
  </div>
);

export default ReinsuranceSection;
