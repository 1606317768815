/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { errorHandler } from 'client/helpers/errorHandler';
import formatFinalRservationData from 'client/helpers/formatFinalReservationData';
import { confirmBooking, createBooking } from 'client/_redux/services/bookingPayment';
import { BookingFormTypes } from 'client/_redux/types/bookingFormTypes';
import { reservationFormDetails } from 'client/_redux/types/reservationForm';
import { PaymentTypes } from 'types/IPayment';
import { IThunkAction } from 'types/IThunkAction';
import { startLoading, stopLoading } from '../loading';

export const confirmBookingPayment = (
  value: PaymentTypes = 'creditcard',
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const {
      selectedTripReducer: {
        selectedRooms,
        selectedAddons,
        supplement,
        tax,
        transportation,
        reservationFormDetails,
      },
      quotedAvailabilityReducer: trip,
    } = getState();
    const formattedData = formatFinalRservationData(
      reservationFormDetails!,
      transportation!,
      trip,
      value,
      selectedRooms,
      selectedAddons,
      supplement,
      tax,
      transportation,
    );
    const res = await confirmBooking()(formattedData);

    dispatch({
      type: BookingFormTypes.BOOKING_FORM_SUCCESS,
      payload: {
        payment: res,
        reservationFormDetails,
        transportation,
        trip,
        value,
        selectedRooms,
        selectedAddons,
        supplement,
        tax,
        formattedData,
      },
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: reservationFormDetails.SET_BOOKING_DETAILS_FAILED });
  }
  dispatch(stopLoading());
};
export const createReservation = (value: string, price: number): IThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  try {
    const {
      bookingFormReducer: {
        booking: { formattedData },
      },
    } = getState();

    formattedData.payment.paymentMode = value as PaymentTypes;
    formattedData.payment.amount = price;
    const res = await createBooking()(formattedData);

    window.location.replace(res.redirectionUrl);
  } catch (error) {
    errorHandler(error);
    dispatch({ type: reservationFormDetails.SET_BOOKING_DETAILS_FAILED });
  }
  dispatch(stopLoading());
};
