import { IEntitiesTypes } from 'client/_redux/types/entitiesTypes';
import { IThunkAction } from 'types/IThunkAction';
import * as EntityService from 'client/_redux/services/entities';
import * as CountryService from 'client/_redux/services/countries';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { CountriesTypes } from 'client/_redux/types/countriesTypes';
export const getCountriesNames = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: IEntitiesTypes.GET_COUNTRIES_LIST_START });
  try {
    const res = await EntityService.getEntities()(['Country']);

    dispatch({
      type: IEntitiesTypes.GET_COUNTRIES_LIST_SUCCESS,
      payload: res.data
        .find((el) => (el._id = 'Country'))
        ?.entity.map((el) => ({ name: el.title, code: el._$ID })),
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: IEntitiesTypes.GET_COUNTRIES_LIST_FAILED });
  }

  dispatch(stopLoading());
};
export const getCountries = (): IThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { isPreloaded } = state.countriesReducer;

  if (isPreloaded) return;

  dispatch(startLoading());
  dispatch({ type: CountriesTypes.GET_COUNTRIES_START });
  try {
    const res = await CountryService.getCountries()();

    dispatch({
      type: CountriesTypes.GET_COUNTRIES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CountriesTypes.GET_COUNTRIES_FAILED });
  }

  dispatch(stopLoading());
};
