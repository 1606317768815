import { isArray } from 'lodash';
import { useLocation } from 'react-router';

export const useQuery = (queryToGet: string | string[]) => {
  const query = new URLSearchParams(useLocation().search);
  const result: Record<string, string | null> = {};

  if (isArray(queryToGet)) {
    queryToGet.map((el) => (result[el] = query.get(el)));

    return result;
  }

  return query.get(queryToGet);
};
