import Validators from 'client/helpers/formValidator';
import { DBDocument } from 'client/models/utils';
import React from 'react';
import { IFilter } from 'server/models/Filter/types';
import { ISelectorOption } from 'types/ISelectorOption';
import FilterAttributesSelection from '../FilterAttributesSelection';
import FilterSelect from '../Forms/FilterSelect';
import { useFilterSelection } from './useFilterSelection';

type FilterSelectProps = {
  rawOptions: ISelectorOption<string>[];
  filterIndex: number;
  filterRemove: (index?: number | number[] | undefined) => void;
  productsFilter?: (IFilter & DBDocument)[];
};

const FilterSelection: React.FC<FilterSelectProps> = ({
  rawOptions,
  filterIndex,
  productsFilter,
  filterRemove,
}) => {
  const {
    options,
    append,
    remove,
    fields,
    attributeOptions,
    productFilter,
    t,
  } = useFilterSelection(filterIndex, rawOptions, productsFilter);

  return (
    <div className="col-md-4 border p-0">
      <div className="p-1 mb-2">
        <i
          onClick={() => filterRemove(filterIndex)}
          className="fas fa-times-circle close-icon"
        />
      </div>

      <div className="p-1 mb-2">
        <FilterSelect
          validate={Validators([{ validation: 'required' }])}
          isSearchable
          isSearchBar
          isClearable
          options={options}
          name={`filters[${filterIndex}].filterId`}
        />
        <div>
          {fields?.map((field, index) => (
            <FilterAttributesSelection
              key={field.id}
              rawOptions={attributeOptions}
              attributeIndex={index}
              remove={remove}
              filterIndex={filterIndex}
            />
          ))}

          {productFilter && (
            <div className="d-flex justify-content-between pt-3">
              {productFilter?.attributes.length > fields.length && (
                <button
                  style={{ fontSize: 12, fontWeight: 600 }}
                  className="btn btn-success btn-block mr-1"
                  type="button"
                  onClick={() => append([{}])}
                >
                  {t('FilterProducts.AddAnAttribute')}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterSelection;
