/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectorOption } from 'types/ISelectorOption';
import { AddonFormValues } from '../QuotedAvailability/ProductAddons/useProductAddons';
import AddonsFormSelect from './AddonsFormSelect';

const SelectedAddonsFormOptions = ({
  selectedAddons,
  familyOptions,
}: {
  selectedAddons: AddonFormValues[];
  familyOptions: ISelectorOption<string>[];
}) => {
  const [t] = useTranslation();

  return (
    <>
      {selectedAddons.length > 0 && (
        <>
          <h2 className="w-100 text-success py-2">
            {t('quotedAvailabilityPage.optionelServices')}
          </h2>

          <div className="p-3 m-2 d-flex flex-wrap border">
            {selectedAddons.map((addon, addonIndex) => (
              <div
                className="d-flex flex-row align-items-center col-12 col-md-6 "
                key={`addon-${addonIndex}`}
              >
                <div className="p-1 m-1 w-100 ">
                  <h4>{addon.description} </h4>

                  <AddonsFormSelect
                    addonIndex={addonIndex}
                    familyOptions={familyOptions}
                    nbrPersons={addon.nbrPersons}
                    addonId={addon.id}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SelectedAddonsFormOptions;
