import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFlightClient } from 'types/IFlightData';
type IFlightClientCredentialProps = {
  clientCredentials: IFlightClient;
};
const FlightClientCredential: React.FC<IFlightClientCredentialProps> = ({
  clientCredentials: {
    birthCountry,
    dateOfBirth,
    firstName,
    lastName,
    email,
    passeportNumber,
    PassportExpiryDate,
    nationality,
  },
}) => {
  const [t] = useTranslation();
  const notProvided = (
    <span className="badge-danger">{t('clientCredentials.notProvided')}</span>
  );

  return (
    <div className="d-flex flex-column">
      <div className="pl-lg-3 pl-md-3">
        <div className="row mb-1 justify-content-between">
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">Nom : </span>
            {`${firstName} ${lastName}`}
          </span>
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder"> {t('clientCredentials.nationality')} </span>
            {nationality || notProvided}
          </span>
        </div>
        <div className="row mb-1 justify-content-between">
          <span className="col-lg-6 col-md-6 col-sm-12 ">
            <span className="font-weight-bolder">{t('clientCredentials.birthCountry')}</span>
            {birthCountry || notProvided}
          </span>
          <span className="col-lg-6 col-md-6 col-sm-12 ">
            <span className="font-weight-bolder">{t('reservationDetails.birthDate')}</span>
            {dateOfBirth || notProvided}
          </span>
        </div>
        <div className="row mb-1 justify-content-between">
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">{t('clientCredentials.passport')}</span>
            {passeportNumber || notProvided}
          </span>
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">
              {t('clientCredentials.PassportExpiryDate')}
            </span>
            {PassportExpiryDate || notProvided}
          </span>
        </div>
        <div className="row mb-1 justify-content-between">
          <span className="col-lg-6 col-md-6 col-sm-12">
            <span className="font-weight-bolder">Email : </span>
            {email || notProvided}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FlightClientCredential;
