import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { ReactText } from 'react';

export const isAxiosError = (error: unknown): error is AxiosError =>
  (error as AxiosError).isAxiosError === true;
export const errorHandler = (error: AxiosError | Error): ReactText =>
  toast(
    isAxiosError(error) ? error.response?.data.message || error.response?.data : error.message,
    {
      type: 'error',
    },
  );
export const successHandler = (message: string): ReactText =>
  toast(message, {
    type: 'success',
  });
