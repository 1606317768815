import {
  addCountry,
  getCountriesNames,
  updateCountry,
} from 'dashboard/_redux/actions/countries';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from 'dashboard/helpers/getImageUrl';
import { ICountry } from 'dashboard/_redux/reducers/countries';
import { useAppSelector } from 'client/hooks/useAppSelector';
export interface IDestinationFormValues {
  title: string | { label: string; value: string };
  subtitle: string;
  countryImage: File | string;
}

export const useAddProductForm = (
  openModal?: React.Dispatch<React.SetStateAction<boolean>>,
  editCountry?: ICountry,
) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { countriesNames } = useAppSelector((state) => state.dashboardCountries);
  const countriesList = countriesNames?.map((country) => ({
    label: country.name,
    value: country.code,
  }));
  const formMethods = useForm<IDestinationFormValues>({
    shouldFocusError: true,
  });
  const { handleSubmit, reset } = formMethods;

  const onSubmit: SubmitHandler<IDestinationFormValues> = (data) => {
    data.title =
      countriesList?.find((countryName) => countryName.value === data.title)?.label || '';
    if (editCountry) dispatch(updateCountry(data, editCountry._id));
    else dispatch(addCountry(data));

    openModal && openModal(false);
    reset({ countryImage: '', title: '', subtitle: '' });
  };
  const getCountryCode = (countryName: string) =>
    countriesList?.find((country) => (country.label = countryName))?.value;

  useEffect(() => {
    dispatch(getCountriesNames());
    reset({ countryImage: '', title: '', subtitle: '' });
    if (editCountry)
      reset({
        subtitle: editCountry?.subtitle,
        countryImage: [getImageUrl(editCountry?.countryImage as string)],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reset]);

  return { countriesList, handleSubmit, onSubmit, formMethods, t, getCountryCode };
};
