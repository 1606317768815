import React from 'react';
import Loader from './Loader';
import { useProductTitle } from './useProductTitle';

const ProductTitle: React.FC = () => {
  const { title, loading } = useProductTitle();

  return (
    <div
      data-testid="ProductTitle"
      className="text-left h2 font-weight-bold"
      style={{ padding: '10px 10px 10px 0px' }}
    >
      {loading ? <Loader /> : title}
    </div>
  );
};

export default ProductTitle;
