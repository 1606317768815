import { formatDayMonth, formatHourAndMinutes } from 'client/helpers/format/timeFormat';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlightDetails } from '.';
import ResponsiveWrapper from '../ResponsiveWrapper';
import LangindingPlaneSVG from '../SVG/plane/LandingPlaneSVG';
import PlaneDestinationSVG from '../SVG/plane/PlaneDestinationSVG';
import TakeOffPlaneSVG from '../SVG/plane/TakeOffPlane';
import FlightDetailItem from './FlightDetailItem';

interface IProps {
  flightDetails: FlightDetails;
  bgClassName?: string;
}
const FlightDetailsMobile: React.FC<IProps> = ({ flightDetails, bgClassName = '' }) => {
  const {
    direction,
    start,
    from,
    durationAndType = '02h00',
    end,
    to,
    flightNumber,
    carrier,
  } = flightDetails;
  const [t] = useTranslation();

  return (
    <ResponsiveWrapper
      className={`d-flex mobile-details-flight-container ${bgClassName}`}
      isMobile
    >
      <div className="d-flex flex-column justify-content-around flight-icons-container pl-md-5 w-100">
        <div>
          <img
            src={`https://images.kiwi.com/airlines/64/${carrier.code}.png`}
            alt={carrier.code}
          />
          <h3 className={`${bgClassName}`}>{flightNumber}</h3>
        </div>

        <div className="icon-flight-details-container mb-4">
          <TakeOffPlaneSVG height="28" width="28" />
        </div>
        <div className="icon-flight-details-container mb-4">
          <PlaneDestinationSVG height="28" width="28" />
        </div>
        <div className="icon-flight-details-container mb-4">
          <LangindingPlaneSVG height="28" width="28" />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-around py-2 w-100">
        <div className="direction-flight-info">
          <span>{direction}</span>
        </div>

        <FlightDetailItem isMobile className="w-100 border-bottom">
          <div className="d-flex w-100">
            <div className="col">
              <h6 className={`${bgClassName}`}>{from.title}</h6>
              <span>{from.code}</span>
            </div>
            <div className="col">
              <h6 className={`${bgClassName}`}> {formatHourAndMinutes(start)}</h6>
              <span>{formatDayMonth(start)}</span>
            </div>
          </div>
        </FlightDetailItem>
        <FlightDetailItem isMobile className="w-100 border-bottom">
          <div className="d-flex w-100 ">
            <span className="col">{t('FlightDetailsCard.duration')}</span>
            <h6 className={`${bgClassName} col`}>{durationAndType}</h6>
          </div>
        </FlightDetailItem>
        <FlightDetailItem isMobile className="w-100">
          <div className="d-flex w-100">
            <div className="col">
              <h6 className={`${bgClassName}`}>{to.title}</h6>
              <span>{to.code}</span>
            </div>
            <div className="col">
              <h6 className={`${bgClassName}`}>{formatHourAndMinutes(end)}</h6>
              <span>{formatDayMonth(end)}</span>
            </div>
          </div>
        </FlightDetailItem>
      </div>
    </ResponsiveWrapper>
  );
};

export default FlightDetailsMobile;
