import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { getQuotedAvailability, reserveRoom } from 'client/_redux/actions/quotedAvailability';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { IQuotedAvailabilityParams } from 'types/IQuotedAvailabilityRequest';
import { queryStringToObject } from 'client/helpers/format/queryStringToObject';
import { FormattedRoom } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const useQuotedAvailability = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch<{ permalink: string }>();
  const [t] = useTranslation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  const quotedAvailabilityParams: IQuotedAvailabilityParams = useMemo(
    () => ({
      permalink: match.params.permalink,

      ...queryStringToObject<Omit<IQuotedAvailabilityParams, 'permalink'>>(location.search),
    }),
    [match.params.permalink, location.search],
  );
  const onSubmit = useCallback(
    (selectedRoom: FormattedRoom[]) => {
      dispatch(reserveRoom(selectedRoom));
    },
    [dispatch],
  );
  const history = useHistory();
  const checkAvailability = async () => {
    const res = await dispatch(getQuotedAvailability(quotedAvailabilityParams));

    if (!res) history.goBack();
  };

  useEffect(() => {
    checkAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, quotedAvailabilityParams]);

  const {
    addons,
    title,
    startDate,
    childCount,
    adultCount,
    endDate,
    recommended,
    loading,
    alternatives,
    selectedAddons,
    supplement,
    tripTransportation,
  } = useAppSelector(
    ({
      quotedAvailabilityReducer: { trip, loading, childCount, adultCount },
      selectedTripReducer: { selectedRooms, selectedAddons, supplement },
    }) => ({
      addons: trip?.addons,
      title: trip?.details.description,
      startDate: trip?.startDate,
      childCount,
      adultCount,
      endDate: trip?.details.endDate,
      recommended: trip?.details.rooms.recommended,
      alternatives: trip?.details.rooms.alternatives,
      quotedPriceDetails: trip?.priceDetails,
      loading,
      selectedRooms,
      selectedAddons,
      supplement,
      tripTransportation: trip?.details?.transportation,
    }),
  );

  const { permalink } = quotedAvailabilityParams;

  return {
    onSubmit,
    addons,
    title,
    adultCount,
    childCount,
    startDate,
    endDate,
    recommended,
    alternatives,
    selectedAddons,
    supplement,
    t,
    loading,
    permalink,
    tripTransportation,
  };
};
