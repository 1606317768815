import React from 'react';

const VideoBackground = () => <div />;
// (
//   <section
//     className="vw-100 d-flex position-absolute top-0 left-0 justify-content-center"
//     style={{ zIndex: -1 }}
//   >
//     <video autoPlay loop muted>
//       <source src="/assets/videos/Ocean-Background-short.mp4" type="video/mp4" />
//     </video>
//   </section>
// );

export default VideoBackground;
