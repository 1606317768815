import { useAppSelector } from 'client/hooks/useAppSelector';

export const useKeywordsTab = () => {
  const { product, loading } = useAppSelector(({ productReducer }) => productReducer);

  const keywords = product?.keywords;

  return {
    keywords,
    loading,
  };
};
