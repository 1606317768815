import React from 'react';

interface IProps {
  type: string;
  description?: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  currency: string;
}

const PriceDetailsTable: React.FC<IProps> = ({
  type,
  description,
  quantity,
  unitPrice,
  totalPrice,
  currency,
}) => (
  <tr>
    <td className="col-4">
      <span>
        <b>{type}</b>
      </span>
      <p className="m-0">{description}</p>
    </td>
    <td className="text-center">{quantity}</td>
    <td className="text-center">
      {unitPrice.toLocaleString('en-US', {
        style: 'currency',
        currency,
      })}
    </td>
    <td className="text-center">
      {totalPrice.toLocaleString('en-US', {
        style: 'currency',
        currency,
      })}
    </td>
  </tr>
);

export default PriceDetailsTable;
