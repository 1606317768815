import { IReduxAction } from 'types/IReduxAction';
import { FilterProductTypes } from 'dashboard/_redux/types/FilterProductTypes';
import { DBDocument } from 'client/models/utils';
import { IProductSearchCriteriaValueResponse } from 'dashboard/_redux/services/productsFilter';

const initialState = {
  loading: true,
  isPreloaded: false,
};

export interface IProductFilterInterface {
  productsFilter?: IProductSearchCriteriaValueResponse & DBDocument;
  loading?: boolean;
  isPreloaded: boolean;
}

export default (
  state: IProductFilterInterface = initialState,
  { type, payload }: IReduxAction,
): IProductFilterInterface => {
  switch (type) {
    case FilterProductTypes.GET_FILTER_PRODUCTS:
      return {
        ...state,
        productsFilter: payload,
      };
    case FilterProductTypes.GET_FILTER_SEARCH_CRITERIA:
      return {
        ...state,
        productsFilter: payload,
      };

    default:
      return state;
  }
};
