import { AddonFormValues } from 'client/containers/QuotedAvailability/ProductAddons/useProductAddons';
import {
  FormattedRoom,
  Price,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';

export const getTotalPrice = (
  selectedRooms: FormattedRoom[],
  selectedAddons: AddonFormValues[],
  tax: Price | undefined,
  transportation: Price | undefined,
  supplement: Price | undefined,
) => {
  let totalePrice = 0;

  if (tax) {
    totalePrice += tax.totalPrice;
  }
  if (transportation) {
    totalePrice += transportation.totalPrice;
  }
  if (supplement) {
    totalePrice += supplement.totalPrice;
  }
  selectedAddons.forEach((el) => (totalePrice += (el.price * el.nbrPersons) / 100));
  selectedRooms.forEach((el) => (totalePrice += el.price));

  return totalePrice;
};
