import { PriceDetails } from 'client/containers/QuotedAvailability/PriceDetails/usePriceDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PriceDetailsRow from './PriceDetailsRow';
type IPriceDetailsTableProps = {
  priceDetails: PriceDetails[];
};

const PriceDetailsTable: React.FC<IPriceDetailsTableProps> = ({ priceDetails }) => {
  const [t] = useTranslation();

  return (
    <div className="table-responsive{-sm|-md|-lg|-xl} overflow-auto ">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>{t('priceDetails.type')}</th>
            <th>{t('priceDetails.quantity')}</th>
            <th>{t('priceDetails.unitPrice')}</th>
            <th>{t('priceDetails.totalPrice')}</th>
          </tr>
        </thead>
        <tbody>
          {priceDetails?.map((priceDetail) => (
            <PriceDetailsRow
              key={priceDetail.type}
              type={priceDetail.type}
              description={priceDetail.price.description}
              quantity={priceDetail.price.quantity}
              unitPrice={priceDetail.price.unitPrice}
              totalPrice={priceDetail.price.totalPrice}
              currency={priceDetail.price.currency}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceDetailsTable;
