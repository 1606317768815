import ClientCredentials from 'client/components/ClientCredentials';
import SEO from 'client/components/SEO';
import ReservationDetailsCards from 'client/containers/ReservationDetailsCards';
import { formatDate } from 'client/helpers/format/formatDate';
import React from 'react';
import { useReservationDetails } from './useReservationDetails';

const ReservationDetails = () => {
  const { transaction, t } = useReservationDetails();

  return transaction.payment ? (
    <>
      <SEO title="Reservation details page" />

      <div className="container">
        <div className="card reservation-details-card-1 mt-2">
          <div className="card-header bg-white">
            <div className="media flex-sm-row flex-column-reverse justify-content-between ">
              <div className="col my-auto">
                <h4 className="mb-0">
                  {t('reservationDetails.thanks')}
                  <span className="reservation-details-change-color">
                    {' '}
                    {transaction.clientCredentials?.civility}{' '}
                    {transaction.clientCredentials?.firstName}{' '}
                    {transaction.clientCredentials?.lastName}
                  </span>
                  !
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="justify-content-between mb-3">
            <div className="col-auto">
              <h3 className="color-1 mb-0 reservation-details-change-color">
                {t('reservationDetails.receipt')}
              </h3>
              <span className="text-capitalize">
                {transaction.bookingParams.permalink?.replace(/-/g, ' ')}
              </span>
            </div>
            <div className="col-auto ">
              {t('reservationDetails.bookingCode')}
              {transaction.bookingId}
            </div>
            <div className="row">
              <div className="container pl-5 mb-5 col-lg-6 col-md-6 col-sm-12">
                <div className="row pt-5 pb-3">
                  <p className="mb-1 text-dark">
                    <b>{t('reservationDetails.orderDetails')}</b>
                  </p>
                </div>
                <p className="row mb-1">
                  <span className="font-weight-bolder">{t('reservationDetails.Status')}</span>
                  {` ${transaction.updates[transaction.updates.length - 1].status}`}
                </p>
                <p className="row mb-1">
                  <span className="font-weight-bolder">
                    {t('reservationDetails.paymentCode')}
                  </span>
                  {transaction?.paymentOrderId}
                </p>
                <p className="row mb-1">
                  <span className="font-weight-bolder">Date de départ : </span>
                  {formatDate(transaction?.bookingParams.startDate)}
                  {transaction?.bookingParams.startDate}
                </p>
                <p className="row mb-1">
                  <span className="font-weight-bolder">
                    {t('reservationDetails.numberOfNights')}
                  </span>
                  {transaction?.bookingParams.numberOfNights}
                </p>
              </div>
              <div className="container col-sm-12 col-lg-5 col-md-5 ml-4">
                <img
                  alt={transaction.bookingResponse?.description}
                  className="w-75 rounded ml-md-5 ml-lg-5 ml-sm-2 mb-3"
                  src={transaction.bookingResponse?.coverPicture}
                />
              </div>
            </div>
            <ClientCredentials clientCredentials={transaction.clientCredentials} />
            <ReservationDetailsCards transaction={transaction} />
          </div>
          <div className="reservation-details-card-footer">
            <div className="row justify-content-between my-3 ">
              <div className="col-sm-auto col-auto my-auto" />
              <div className="col-auto my-auto ">
                <h2 className="font-weight-bold">{t('reservationDetails.totalPaid')}</h2>
              </div>
              <div className="col-auto my-auto ml-auto">
                <h1 className="display-3 ">&euro; {transaction.payment.amount.toFixed(2)}</h1>
              </div>
            </div>
            <div className="row mb-3 mt-3 mt-md-0">
              <div className="col-auto border-line">
                <small className="text-white">BRAND:{transaction.paymentMethod?.Brand}</small>
              </div>
              <div className="col-auto border-line">
                <small className="text-white">CODE:{transaction.paymentMethod?.Ref} </small>
              </div>
              <div className="col-auto ">
                <small className="text-white">TYPE:{transaction.paymentMethod?.Type} </small>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <SEO title="Reservation details error page" image="/assets/images/logoKepler.png" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">{t('transactionNotFound.sorryMessage')}</div>
              <div className="error-actions">
                <a href="/" className="btn btn-primary btn-lg">
                  <span className="fas fa-home" />
                  {t('transactionNotFound.home')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationDetails;
