import { useAppSelector } from 'client/hooks/useAppSelector';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const useArticle = () => {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation();
  const loading = useAppSelector((state) => state.loading.loading);

  const articles = useAppSelector(({ blog }) => ({
    articles: blog.articles,
  }));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  const selectedArticle = articles?.articles.items.filter((el) => el?._id === id)[0];

  return {
    loading,
    selectedArticle,
    t,
  };
};
