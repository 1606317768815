/* eslint-disable max-lines */
import SEO from 'client/components/SEO';
import React from 'react';

const PrivacyPolicy = () => (
  <>
    <SEO title="Terms and Conditions page" image="/assets/images/logoKepler.png" />
    <div className="container text-dark">
      <p
        className="MsoNormal"
        style={{ marginBottom: '0in', textAlign: 'center', lineHeight: '150%' }}
      >
        <b>
          <span lang="FR">POLITIQUE DE CONFIDENTIALITÉ</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: '0in', textAlign: 'center', lineHeight: '150%' }}
      >
        <span lang="FR">
          <a href="http://www.keplertravel.com">
            <b>www.keplertravel.com</b>
          </a>
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: '0in', textAlign: 'center', lineHeight: '150%' }}
      >
        <b>
          <span lang="FR">société KEPLER</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}
      >
        <b>
          <span lang="FR">&nbsp;</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}
      >
        <b>
          <span lang="FR">&nbsp;</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Le but de cette politique de confidentialité</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Chez Kepler Travel, accessible depuis https://www.keplertravel.com, l&apos;une de nos
          principales priorités est le respect de la vie privée de nos visiteurs. Ce document
          de politique de confidentialité contient les types d&apos;informations qui sont
          collectées et enregistrées par Kepler Travel et la manière dont nous les utilisons.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Si vous avez des questions supplémentaires ou si vous souhaitez obtenir plus
          d&apos;informations sur notre politique de confidentialité, n&apos;hésitez pas à nous
          contacter.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Cette politique de confidentialité s&apos;applique uniquement à nos activités en
          ligne et est valable pour les visiteurs de notre site web en ce qui concerne les
          informations qu&apos;ils ont partagé et/ou collecté à Kepler Travel. Cette politique
          n&apos;est pas applicable aux informations collectées hors ligne ou via des canaux
          autres que ce site web.{' '}
          <i>
            Notre politique de confidentialité a été créée avec l&apos;aide du générateur
            gratuit de politique de confidentialité.
          </i>
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">&nbsp;</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Consentement</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          En utilisant notre site web, vous consentez à notre politique de confidentialité et
          en acceptez les termes.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">&nbsp;</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Informations que nous recueillons</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Les informations personnelles que l&apos;on vous demande de fournir, et les raisons
          pour lesquelles on vous demande de les fournir, vous seront expliquées clairement au
          moment où nous vous demanderons de fournir vos informations personnelles.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Si vous nous contactez directement, nous pouvons recevoir des informations
          supplémentaires vous concernant, telles que votre nom, votre adresse électronique,
          votre numéro de téléphone, le contenu du message et/ou des pièces jointes que vous
          pouvez nous envoyer, et toute autre information que vous pouvez choisir de fournir.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Lorsque vous vous inscrivez à un compte, nous pouvons vous demander vos coordonnées,
          notamment votre nom, le nom de votre société, votre adresse, votre adresse
          électronique et votre numéro de téléphone.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Comment nous utilisons vos informations</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Nous utilisons les informations que nous recueillons de diverses manières, notamment
          pour:
        </span>
      </p>
      <ul>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{ marginBottom: '0in', lineHeight: 'normal' }}
        >
          <li lang="FR">Fournir, exploiter et maintenir notre site web</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: '0in', lineHeight: 'normal' }}
        >
          <li lang="FR">Améliorer, personnaliser et développer notre site web</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: '0in', lineHeight: 'normal' }}
        >
          <li lang="FR">Comprendre et analyser la façon dont vous utilisez notre site web</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: '0in', lineHeight: 'normal' }}
        >
          <li lang="FR">
            Développer de nouveaux produits, services, caractéristiques et fonctionnalités
          </li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: '0in', lineHeight: 'normal' }}
        >
          <li lang="FR">
            Communiquer avec vous, soit directement, soit par l&apos;intermédiaire de l&apos;un
            de nos partenaires, notamment dans le cadre du service clientèle, pour vous fournir
            des mises à jour et d&apos;autres informations relatives au site Web, ainsi
            qu&apos;à des fins de marketing et de promotion.
          </li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: '0in', lineHeight: 'normal' }}
        >
          <li lang="FR">Vous envoyer des courriers électroniques</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: '0in', lineHeight: 'normal' }}
        >
          <li lang="FR">Trouver et prévenir les fraudes.</li>
        </p>
      </ul>
      <p
        className="MsoListParagraphCxSpLast"
        style={{ marginBottom: '0in', lineHeight: 'normal' }}
      >
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Fichiers journaux</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Kepler Travel suit une procédure standard d&apos;utilisation de fichiers journaux.
          Ces fichiers enregistrent les visiteurs lorsqu&apos;ils visitent des sites web.
          Toutes les sociétés d&apos;hébergement le font et cela fait partie de l&apos;analyse
          des services d&apos;hébergement. Les informations recueillies par les fichiers
          journaux comprennent les adresses de protocole Internet (IP), le type de navigateur,
          le fournisseur d&apos;accès Internet (FAI), la date et l&apos;heure, les pages de
          renvoi et de sortie, et éventuellement le nombre de clics. Ces données ne sont pas
          liées à des informations permettant d&apos;identifier une personne. Elles servent à
          analyser les tendances, à administrer le site, à suivre les déplacements des
          utilisateurs sur le site et à recueillir des informations démographiques.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Cookies et balises Web</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Comme tout autre site web, Kepler Travel utilise des &quot;cookies&quot;. Ces cookies
          sont utilisés pour stocker des informations, notamment les préférences des visiteurs
          et les pages du site web auxquelles le visiteur a accédé ou qu&apos;il a visitées.
          Ces informations sont utilisées pour optimiser l&apos;expérience des utilisateurs en
          personnalisant le contenu de nos pages web en fonction du type de navigateur des
          visiteurs et/ou d&apos;autres informations.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Pour des informations plus générales sur les cookies, veuillez lire l&apos;article
          Cookies sur le site Web Generate Privacy Policy.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Politiques de confidentialité des partenaires publicitaires</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Vous pouvez consulter cette liste pour trouver la politique de confidentialité de
          chacun des partenaires publicitaires de Kepler Travel.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Les serveurs publicitaires tiers ou les réseaux publicitaires utilisent des
          technologies telles que les cookies, le JavaScript ou les balises Web qui sont
          utilisées dans leurs publicités et liens respectifs qui apparaissent sur Kepler
          Travel, qui sont envoyés directement au navigateur des utilisateurs. Ils reçoivent
          automatiquement votre adresse IP lorsque cela se produit. Ces technologies sont
          utilisées pour mesurer l&apos;efficacité de leurs campagnes publicitaires et/ou pour
          personnaliser le contenu publicitaire que vous voyez sur les sites web que vous
          visitez.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Notez que Kepler Travel n&apos;a aucun accès ou contrôle sur ces cookies qui sont
          utilisés par des annonceurs tiers.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Politiques de confidentialité des tiers</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          La politique de confidentialité de Kepler Travel ne s&apos;applique pas aux autres
          annonceurs ou sites web. Nous vous conseillons donc de consulter les politiques de
          confidentialité respectives de ces serveurs publicitaires tiers pour obtenir des
          informations plus détaillées. Il peut s&apos;agir de leurs pratiques et
          d&apos;instructions sur la manière de refuser certaines options.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Vous pouvez choisir de désactiver les cookies par le biais des options de votre
          navigateur individuel. Pour connaître des informations plus détaillées sur la gestion
          des cookies avec des navigateurs web spécifiques, vous pouvez consulter les sites web
          respectifs de ces navigateurs.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">
            Droits à la vie privée de la CCPA (Ne vendez pas mes informations personnelles)
          </span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          En vertu de la CCPA, entre autres droits, les consommateurs ont le droit de :
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Demander à une entreprise qui recueille les données personnelles d&apos;un
          consommateur de divulguer les catégories et les éléments spécifiques de données
          personnelles qu&apos;elle a recueillies sur les consommateurs.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Demander qu&apos;une entreprise supprime toutes les données personnelles qu&apos;elle
          a recueillies sur le consommateur.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Demander qu&apos;une entreprise qui vend les données personnelles d&apos;un
          consommateur ne vende pas ces données.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez
          exercer l&apos;un de ces droits, veuillez nous contacter.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Droits de protection des données RGPD</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Nous tenons à nous assurer que vous êtes pleinement conscient de tous vos droits en
          matière de protection des données. Chaque utilisateur a les droits suivants :
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Le droit d&apos;accès - Vous avez le droit de demander des copies de vos données
          personnelles. Il se peut que nous vous facturions une petite somme pour ce service.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Le droit de rectification - Vous avez le droit de nous demander de corriger toute
          information que vous jugez inexacte. Vous avez également le droit de nous demander de
          compléter les informations qui, selon vous, sont incomplètes.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Le droit à l&apos;effacement - Vous avez le droit de nous demander d&apos;effacer vos
          données personnelles, sous certaines conditions.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Le droit de restreindre le traitement - Vous avez le droit de nous demander de
          restreindre le traitement de vos données personnelles, sous certaines conditions.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Le droit de s&apos;opposer au traitement - Vous avez le droit de vous opposer au
          traitement de vos données personnelles, sous certaines conditions.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Le droit à la portabilité des données - Vous avez le droit de nous demander de
          transférer les données que nous avons recueillies à une autre organisation ou
          directement à vous, sous certaines conditions.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez
          exercer l&apos;un de ces droits, veuillez nous contacter.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <b>
          <span lang="FR">Informations relatives aux enfants</span>
        </b>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">
          Une autre partie de notre priorité est d&apos;ajouter la protection des enfants
          lorsqu&apos;ils utilisent l&apos;internet. Nous encourageons les parents et les
          tuteurs à observer, participer et/ou surveiller et guider leur activité en ligne.
        </span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}>
        <span lang="FR">&nbsp;</span>
      </p>
      <p className="MsoNormal" style={{ marginBottom: '5rem', lineHeight: 'normal' }}>
        <span lang="FR">
          Kepler Travel ne collecte pas sciemment d&apos;informations personnelles
          identifiables auprès d&apos;enfants de moins de 13 ans. Si vous pensez que votre
          enfant a fourni ce type d&apos;informations sur notre site web, nous vous
          encourageons vivement à nous contacter immédiatement et nous ferons tout notre
          possible pour supprimer rapidement ces informations de nos dossiers.
        </span>
      </p>
    </div>
  </>
);

export default PrivacyPolicy;
