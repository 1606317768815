/* eslint-disable max-len */
import React from 'react';
interface IProps {
  height: string;
  width: string;
}
const TakeOffPlaneSVG: React.FC<IProps> = ({ height, width }) => (
  <svg
    fill="#677788"
    height={height}
    width={width}
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512.005 512.005"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <rect x={16.539} y={425.626} width={479.767} height={50.502} />
          <path d="M510.7,189.151c-5.429-20.201-26.135-32.195-46.335-26.766l-134.209,35.982L155.924,35.878l-48.734,13.13     l104.539,181.175L86.232,263.767l-49.618-39.013L0,234.603l45.957,79.667l19.317,33.457l40.528-10.858l134.209-35.983     l109.715-29.417l134.209-35.983C504.134,230.057,516.129,209.352,510.7,189.151z" />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default TakeOffPlaneSVG;
