import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDuration } from './useDuration';
import Loader from './Loader';

const Duration = () => {
  const { information, loading } = useDuration();
  const [t] = useTranslation();

  return (
    <div data-testid="ProductPage-DurationInformation ">
      {loading ? (
        <div className=" mt-2">
          <Loader />
        </div>
      ) : (
        <div className="d-flex align-items-end flex-column">
          <div className=" d-flex flex-wrap badge rounded-pill justify-content-center duration">
            <div className="my-auto">
              <i className="far fa-calendar-alt m-1 " />
              {`${t('productPageInfos.Durée')}${information?.ConditionDeparture ? '*' : ''}`}:
            </div>
            <div className="my-auto">
              <i className="fas fa-sun m-1" />
              {`${information?.Day} ${t('productPageInfos.Jours')}`} /
              <i className="fas fa-moon m-1" />
              {`${information?.Night} ${t('productPageInfos.Nuits')}`}
            </div>
          </div>
          <div className="text-end font-size-1">
            {information?.ConditionDeparture && (
              <p data-testid="ProductPage-ExtraInformation">
                (*){' '}
                {`${t('productPageInfos.InformationSupplémentaires')}: ${
                  information?.ConditionDeparture
                }`}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Duration;
