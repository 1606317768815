import React from 'react';

const ResponsiveWrapper: React.FC<{
  isMobile?: boolean;
  className?: string;
  screenSize?: string;
}> = ({ isMobile = false, className, children, screenSize = 'md' }) => (
  <>
    <div
      data-testid="ResponsiveWrapperDisplay"
      className={`${className} ${
        isMobile ? `d-${screenSize}-none d-block` : `d-${screenSize}-block d-none`
      }`}
    >
      {children}
    </div>
  </>
);

export default ResponsiveWrapper;
