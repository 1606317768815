import { AxiosError } from 'axios';
import {
  DeleteAdminTypes,
  GetAllAdminsTypes,
} from 'dashboard/_redux/types/administratorTypes';
import { IUser } from 'server/models/User';
import { IReduxAction } from 'types/IReduxAction';
import { IPaginatedResponse } from 'types/IPaginatedResponse';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
export interface AdminsState {
  error?: AxiosError | Error;
  admins?: IPaginatedResponse<IUser>;

  loading: boolean;
}

const initialState: AdminsState = { loading: false, admins: emptyPaginationPayload };

export default (
  state: AdminsState = initialState,
  { type, payload, error }: IReduxAction,
): AdminsState => {
  switch (type) {
    case GetAllAdminsTypes.ADMINS_START:
      return { ...state, loading: true };
    case GetAllAdminsTypes.ADMINS_SUCCESS:
      return { ...state, admins: payload, loading: false };
    case GetAllAdminsTypes.ADMINS_FAILURE:
      return { ...state, error, loading: false };
    case DeleteAdminTypes.DELETE_ADMIN_SUCCESS:
      return { ...state, admins: payload };
    case DeleteAdminTypes.DELETE_ADMIN_FAILURE:
      return { ...state, error };

    default:
      return state;
  }
};
