import React from 'react';

import { FormProvider } from 'react-hook-form';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import FormImagePicker from 'dashboard/components/Forms/FormImagePicker';
import { getImageUrl } from 'dashboard/helpers/getImageUrl';
import useAddBlogCoverPage from './useAddBlogCoverPage';

const AddBlogCoverPage = () => {
  const {
    onSubmit,
    formMethods,
    handleSubmit,
    t,
    cover: { metaCover, metaDescription, metaTitle },
  } = useAddBlogCoverPage();

  return (
    <div className="m-5">
      <h4 className="text-center">Aperçu</h4>
      <div
        className="container w-100 background-image rounded mb-5"
        style={{
          padding: ' 12rem 0',
          backgroundImage: `url(${
            metaCover &&
            (metaCover[0].includes('https') ? metaCover[0] : getImageUrl(metaCover[0]))
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          WebkitBackgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className=" text-center mx-md-auto overflow-hidden text-shadow-article">
          <h1 className="display-4 text-white">{metaTitle}</h1>
          <p className="lead text-white">{metaDescription}</p>
        </div>
      </div>
      <div
        className="border p-2"
        style={{
          borderRadius: '8px',
          background: 'white',
          boxShadow: '0px 0.375rem 1.5rem 0px rgb(140 152 164 / 13%) !important',
        }}
      >
        <FormProvider {...formMethods}>
          <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-between">
              <h1 style={{ textAlign: 'center' }}>title</h1>
              <button className="btn btn-primary" type="submit">
                <i className="fa fa-save mr-2" />
                {t('productDetails.save')}
              </button>
            </div>

            <Input
              name="metaTitle"
              label="Meta-title"
              validate={Validators([{ validation: 'required' }])}
            />
            <Input
              name="metaDescription"
              type="textarea"
              maxLength={160}
              label="Meta-description"
              validate={Validators([{ validation: 'required' }])}
            />
            <FormImagePicker
              name="metaCover"
              label="Meta-Cover"
              maxFiles={1}
              validate={Validators([{ validation: 'required' }])}
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default AddBlogCoverPage;
