import ImageGalleryWrapper from 'client/components/ImageGalleryContainer/ImageGalleryWrapper';
import React from 'react';
import { useProductImageGallery } from './useProductImageGallery';

const ProductImageGallery = () => {
  const { loading, images, youtubeEmbedID } = useProductImageGallery();

  return (
    <ImageGalleryWrapper isLoading={loading} images={images} youtubeEmbedID={youtubeEmbedID} />
  );
};

export default ProductImageGallery;
