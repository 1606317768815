export enum BlogArticlesTypes {
  ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS',

  UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS',

  GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS',

  DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS',
  BLOG_COVER_SUCCESS = 'BLOG_COVER_SUCCESS',
  GET_BLOG_COVER_SUCCESS = 'GET_BLOG_COVER_SUCCESS',
}
