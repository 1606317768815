import * as DashboardAuthServices from 'dashboard/_redux/services/auth';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { ILoginFormValues } from 'client/containers/LoginForm/useLoginForm';
import { AuthTypes } from 'dashboard/_redux/types/authTypes';
import { toast } from 'react-toastify';
import { refreshUserToken, saveUserRefreshToken } from 'client/_redux/actions/auth';
import { UserTypes } from 'client/_redux/types/userTypes';
import { LoadingTypes } from 'client/_redux/types/loadingTypes';

export const loginDashboard: (values: ILoginFormValues) => IThunkAction = ({
  email,
  password,
}) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const signInData = await DashboardAuthServices.loginDashboard()({ email, password });

    const { token, ...userData } = signInData.data;

    saveUserRefreshToken(signInData.data);

    delete userData.emailActivationToken;
    delete userData.password;
    dispatch({
      type: AuthTypes.SIGNIN_ADMIN_SUCCESS,
      payload: token,
    });
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const checkLoggingStatus = (): IThunkAction => async (dispatch): Promise<void> => {
  dispatch(startLoading());

  try {
    await refreshUserToken();

    const { user } = await DashboardAuthServices.userByToken()();

    dispatch({
      type: UserTypes.SIGNIN_USER_SUCCESS,
      payload: user,
    });
  } catch (error) {
    dispatch({ type: AuthTypes.LOGOUT_ADMIN });
    dispatch({ type: UserTypes.USER_LOGGED_OUT });
  }
  dispatch(stopLoading());
  dispatch({ type: LoadingTypes.INITIATE });
};
