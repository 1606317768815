import React from 'react';
import ImagesSlider from '../ImagesSlider';
interface IProps {
  images?: string[];
  currentIndex: number;

  closeSlider: () => void;
}
const FullScreenSlider: React.FC<IProps> = ({ closeSlider, currentIndex, images }) => (
  <div className="bg-dark fancybox-silder-container">
    <ImagesSlider
      thumbnailsHeight="16vh"
      fullHight="82vh"
      currentIndex={currentIndex}
      images={images}
    />

    <div className="position-absolute top-0 ml-2 mt-2 ">
      <button type="submit" onClick={closeSlider} className="btn-icon btn-round btn btn-light">
        <i className="fas fa-times" />
      </button>
    </div>
  </div>
);

export default FullScreenSlider;
