import { ProductTypes } from 'client/_redux/types/productTypes';
import { ExhibitedProductDashboardTypes } from 'dashboard/_redux/types/productTypes';
import { ICatalogue } from 'server/models/Catalogue/types';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
import { IReduxAction } from 'types/IReduxAction';

const initialState = {
  loading: true,
  isPreloaded: false,
};

export interface IProductInterface {
  isPreloaded: boolean;
  product?: ICatalogue;
  loading: boolean;
  exhibitedProduct?: IExhibitedProduct;
}

export default (state = initialState, { type, payload }: IReduxAction): IProductInterface => {
  switch (type) {
    case ProductTypes.GET_PRODUCT_START:
      return { ...initialState };
    case ProductTypes.GET_PRODUCT_FAILED:
      return { ...state, loading: false };
    case ProductTypes.GET_PRODUCT_SUCCESS:
      return { ...state, product: payload, loading: false };

    case ExhibitedProductDashboardTypes.GET_ONE_EXHIBITED_PRODUCTS_SUCCESS:
      return { ...state, exhibitedProduct: payload, loading: false };
    case ExhibitedProductDashboardTypes.UPDATE_EXHIBITED_PRODUCT_SUCCESS:
      return { ...state, exhibitedProduct: payload, loading: false };

    default:
      return state;
  }
};
