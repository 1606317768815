import { format, intervalToDuration } from 'date-fns';

export const formatHourAndMinutes = (date: string) => format(new Date(date), 'HH:mm');

export const formatDayMonth = (date: string) => format(new Date(date), 'MMMM d');
export const formatDayMonthYear = (date: string) => format(new Date(date), 'MMMM d, yyyy');

export const calculateDiffenceHourMinutes = (startTime: string, endTime: string) => {
  const diff = intervalToDuration({ start: new Date(startTime), end: new Date(endTime) });

  return `${diff.hours}h${diff.minutes}`;
};
