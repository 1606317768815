import SEO from 'client/components/SEO';
import FlightReservationConfirmation from 'client/containers/FlightReservationConfirmation';
import HeaderStyle from 'client/containers/ReservationFormContainer/HeaderStyle';
import { useAppSelector } from 'client/hooks/useAppSelector';
import React, { useEffect } from 'react';

const FlightsConfirmation = () => {
  const { selectedFlights, passengers } = useAppSelector(({ flightsReducer }) => ({
    selectedFlights: flightsReducer.selectedFlights,
    passengers: flightsReducer.confirmerdPassengers,
  }));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <SEO title="Flights reservation confirmation page" />
      {!!Object.keys(selectedFlights).length && (
        <div className="container">
          <HeaderStyle targetNumber={3} />
          {!!passengers && (
            <FlightReservationConfirmation
              selectedFlights={selectedFlights}
              passengers={passengers}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FlightsConfirmation;
