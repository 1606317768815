import React from 'react';

const Loader = () => (
  <svg
    role="img"
    aria-labelledby="loading-aria"
    viewBox="0 0 500 300"
    preserveAspectRatio="true"
    className="d-flex justify-content-center container mt-2"
  >
    <title id="loading-aria">Loading...</title>
    <rect
      x={0}
      y={0}
      width="100%"
      height="100%"
      clipPath="url(#clip-path)"
      style={{
        fill: 'url("#fill")',
      }}
    />
    <defs>
      <clipPath id="clip-path">
        <rect x="6" y="11" rx="3" ry="3" width="292" height="29" />
        <rect x="6" y="44" rx="3" ry="3" width="292" height="29" />
        <rect x="6" y="77" rx="3" ry="3" width="292" height="29" />
        <rect x="6" y="109" rx="3" ry="3" width="292" height="29" />
      </clipPath>
      <linearGradient id="fill">
        <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export default Loader;
