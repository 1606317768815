import { useAppSelector } from 'client/hooks/useAppSelector';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedRoomsToPriceRooms } from 'server/helpers/FormattedRoomsToPriceRooms';
type Price = {
  description: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  currency: string;
};
export type PriceDetails = {
  type: string;
  price: Price;
};
export const usePriceDetails = () => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleViewMore = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const { selectedAddons, selectedRooms, tax, transportation, supplement } = useAppSelector(
    ({
      selectedTripReducer: { selectedAddons, selectedRooms, tax, transportation, supplement },
    }) => ({
      selectedRooms,
      selectedAddons,
      tax,
      transportation,
      supplement,
    }),
  );

  const newQuotedPriceDetails = useMemo(
    () => FormattedRoomsToPriceRooms(selectedRooms, tax, transportation),
    [selectedRooms, tax, transportation],
  );

  let totalPriceRef = 0;

  selectedAddons.map((el) => (totalPriceRef += (el.nbrPersons * el.price) / 100));

  newQuotedPriceDetails?.map((el) => (totalPriceRef += el.price.totalPrice));
  if (supplement) {
    totalPriceRef += supplement.totalPrice;
  }

  return {
    handleViewMore,
    isOpen,
    setIsOpen,
    t,
    selectedAddons,
    newQuotedPriceDetails,
    totalPriceRef,
    supplement,
  };
};
