import { clientApi } from 'api';

export const loginDashboard = (isServer = false) => async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = await clientApi.post('/users/sign-in-dashboard', { email, password });

  return response;
};
