import getRemainingAddonsFamilyOptions from 'client/helpers/getRemainingAddonsFamilyOptions';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';

const useAddonsFormSelect = ({
  name,
  familyOptions,
}: {
  name: string;
  familyOptions: ISelectorOption<string>[];
}) => {
  const { watch } = useFormContext();

  let watchSelectedOptions = watch(name);

  watchSelectedOptions = watchSelectedOptions?.family;

  const { remainingFamily } = useMemo(
    () => getRemainingAddonsFamilyOptions({ watchSelectedOptions, familyOptions }),
    [familyOptions, watchSelectedOptions],
  );

  return { remainingFamily };
};

export default useAddonsFormSelect;
