import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { TFlightResponse } from 'server/services/FlightsService';
import COUNTRIES from 'server/constants/airports.json';
import SidebarTitle from './SidebarTitle';
interface IProps {
  flights:
    | {
        goingFlight: TFlightResponse;
        returningFlight: TFlightResponse;
      }
    | {
        oneWayFlights: TFlightResponse;
      };
  adults: number;
  kids: number;
  babies: number;
  pax: number;
}
const FinancialRecap: React.FC<IProps> = ({ flights, adults, kids, babies, pax }) => {
  const goingFlight =
    'returningFlight' in flights ? flights.goingFlight : flights.oneWayFlights;
  const returningFlight = 'returningFlight' in flights ? flights.returningFlight : undefined;
  const findByAirport = useCallback((value) => COUNTRIES.find((el) => el.code === value), []);
  const destinationCity = findByAirport(goingFlight.destinationAirport.code)?.city;
  const totalPricePerPerson = Math.round(goingFlight.price + (returningFlight?.price || 0));

  return (
    <>
      <h3>
        Votre voyage à <strong className="display-4">{destinationCity}</strong>
      </h3>
      <hr
        style={{
          borderTop: '1px solid #677788',
        }}
      />
      <div className="">
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <strong>{format(new Date(goingFlight.dateLocalTakeoff), 'PPP')}</strong>
            </div>
            <div>
              <strong>{goingFlight.originalHoursLocalTakeOff}</strong>
            </div>
          </div>
          <div className="text-right">
            {goingFlight.originAirport.code} - {goingFlight.destinationAirport.code}
          </div>
        </div>
        {returningFlight && (
          <div>
            <div className="d-flex justify-content-between">
              <div>
                <strong>{format(new Date(returningFlight.dateLocalTakeoff), 'PPP')}</strong>
              </div>
              <div>
                <strong>{returningFlight.originalHoursLocalTakeOff}</strong>
              </div>
            </div>
            <div className="text-right">
              {returningFlight.originAirport.code} - {returningFlight.destinationAirport.code}
            </div>
          </div>
        )}
      </div>
      <SidebarTitle title="Voyageurs" />
      <ul className="list-group list-group-flush">
        {adults > 0 && (
          <li className="list-group-item border-0 p-0 d-flex justify-content-between">
            <div>{adults}× Adulte(s)</div>
            <div>{adults * totalPricePerPerson}€</div>
          </li>
        )}
        {+kids > 0 && (
          <li className="list-group-item border-0 p-0 d-flex justify-content-between">
            <div>{kids}× Enfant(s)</div>
            <div>{+kids * totalPricePerPerson}€</div>
          </li>
        )}
        {+babies > 0 && (
          <li className="list-group-item border-0 p-0 d-flex justify-content-between">
            <div>{babies}× Bébé(s)</div>
            <div>{+babies * totalPricePerPerson}€</div>
          </li>
        )}
      </ul>
      <SidebarTitle title="Bagage en soute:" />

      <div className="d-flex justify-content-between">
        <div>Départ:</div>
        <div className="font-italic">
          {goingFlight.luggageOptions.price
            ? 'Pas de bagages'
            : `Bagages inclus (${
                goingFlight.luggageOptions.quantity > 1
                  ? `${goingFlight.luggageOptions.quantity} × `
                  : ''
              }${goingFlight.luggageOptions.weight} kg)`}
        </div>
      </div>
      {returningFlight && (
        <div className="d-flex justify-content-between">
          <div>Retour:</div>
          <div className="font-italic">
            {returningFlight.luggageOptions.price
              ? 'Pas de bagages'
              : `Bagages inclus (${
                  returningFlight.luggageOptions.quantity > 1
                    ? `${returningFlight.luggageOptions.quantity} × `
                    : ''
                }${returningFlight.luggageOptions.weight} kg)`}
          </div>
        </div>
      )}
      <SidebarTitle title="Prix final:" />

      <div className="d-flex h4 justify-content-between">
        <div>
          <strong>Total:</strong>
        </div>
        <div>
          <strong>{totalPricePerPerson * pax}€</strong>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>Total Par Personne:</div>
        <div>{totalPricePerPerson}€</div>
      </div>
      <hr
        style={{
          borderTop: '1px solid #677788',
        }}
      />
    </>
  );
};

export default FinancialRecap;
