import React from 'react';
import { IFilter } from 'server/models/Filter/types';
import { DBDocument } from 'client/models/utils';
import { Link } from 'react-router-dom';
import Modal from 'client/components/Modal';
import useGetProductFilter from './useGetProductFilter';

const GetProductFilter = () => {
  const {
    productsFilter,
    handleDelete,
    closeConfirmationModal,
    confirmationModalIsOpen,
    confirmationModal,
    filterToDelete,
    t,
  } = useGetProductFilter();

  return (
    <div className="container-fluid d-flex flex-row vw-100 m-2">
      {productsFilter && productsFilter?.length > 0 && (
        <div className="w-100">
          <h2>{t('FilterProducts.Title')}</h2>
          <ul>
            <div className="d-flex flex-wrap">
              {productsFilter?.map((el) => (
                <div className="card m-2 p-2" key={el._id}>
                  <div className="card-header container ">
                    <h4>{el?.title}:</h4>
                    <Link
                      type="button"
                      className="btn btn-success py-1 px-2 mx-2 align-self-center"
                      to={`/dashboard/filter-products/edit/${el._id}`}
                    >
                      <i className="fas fa-pen" />
                    </Link>
                    <button
                      type="button"
                      className="btn p-0 "
                      style={{ top: '0', left: '20rem' }}
                      onClick={() => confirmationModal(el as IFilter & DBDocument)}
                    >
                      <i className="fas fa-times" />
                    </button>
                  </div>
                  <div className="container">
                    {el?.attributes?.map((e, i) => (
                      <div key={el.title + e + +i} className="d-flex flex-row my-3">
                        <label htmlFor={`${e.title}`} className="m-0 ml-3 align-self-center">
                          <li style={{ listStyle: 'square' }} className="w-100">
                            {e.title}
                          </li>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </ul>
        </div>
      )}
      <div className="d-flex justify-content-end align-self-end ">
        <div className="add_country_btn">
          <Link
            type="button"
            className="btn kc_fab_main_btn p-0 mb-5 ml-5"
            to="/dashboard/filter-products/add"
          >
            +{' '}
          </Link>
        </div>
        <Modal isOpen={confirmationModalIsOpen} closeModal={closeConfirmationModal}>
          <div className="text-center">
            <h3 className="mb-5">
              {` ${t('FilterProducts.ConfirmationMsg')} "${filterToDelete?.title}" ${t(
                'FilterProducts.Filter',
              )} `}
            </h3>
            <button type="button" className="btn btn-success mr-4" onClick={handleDelete}>
              {t('FilterProducts.Yes')}
            </button>
            <button
              type="button"
              className="btn btn-warning ml-4"
              onClick={closeConfirmationModal}
            >
              {t('FilterProducts.No')}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GetProductFilter;
