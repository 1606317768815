import PromotionForm from 'dashboard/containers/PromotionForm';
import React from 'react';
import { usePromotionsPage } from './usePromotionsPage';

const PromotionsPage = () => {
  const { onSubmit, promotion, loading } = usePromotionsPage();

  return (
    <div>
      {!loading ? (
        <PromotionForm initialValues={promotion} onSubmit={onSubmit} />
      ) : (
        <div className="p-3 d-flex justify-content-center align-items-center vh-100">
          <div className="lds-ripple">
            <div />
            <div />
          </div>
        </div>
      )}
    </div>
  );
};

export default PromotionsPage;
