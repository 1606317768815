/* eslint-disable max-lines */
import { removeTimeFromDate } from 'client/helpers/removeTimeFromDate';
import {
  IFlightWithData,
  IRoundedFlightWithData,
  IRoundFlightDetails,
} from 'client/_redux/services/flights';
import { flightsTypes } from 'client/_redux/types/flightsTypes';
import { isAfter, isEqual } from 'date-fns';
import { TFlightResponse } from 'server/services/FlightsService';
import { IFlightClient, ISelectedFlights } from 'types/IFlightData';
import { IReduxAction } from 'types/IReduxAction';
const initialState = {
  calendarFlights: [] as IFlightWithData[],
  flights: {} as IRoundFlightDetails | { oneWayFlights: TFlightResponse[] },
  selectedInitialDatePrice: 0,
  selectedFlights: {} as ISelectedFlights,
  pax: 1,
  isRoundFlight: true,
};

export interface IFlightInterface {
  loading?: boolean;
  calendarFlights: IFlightWithData[];
  selectedFlights: ISelectedFlights;
  selectedInitialDatePrice: number;
  departureAerport?: string;
  destinationCountry?: string;
  flights: IRoundFlightDetails | { oneWayFlights: TFlightResponse[] };
  departureDate?: Date;
  returningDate?: Date;
  pax: number;
  confirmerdPassengers?: IFlightClient[];
  isRoundFlight: boolean;
}

export default (state = initialState, { type, payload }: IReduxAction): IFlightInterface => {
  switch (type) {
    case flightsTypes.CLEAR_DETAILED_FLIGHTS:
      return {
        ...state,
        flights: {} as IRoundFlightDetails | { oneWayFlights: TFlightResponse[] },
      };
    case flightsTypes.GET_CALENDAR_FLIGHTS_START:
      return { ...state, loading: true };
    case flightsTypes.SET_PAX_SUCCESS:
      return { ...state, loading: false, pax: payload };
    case flightsTypes.GET_CALENDAR_FLIGHTS_FAILED:
      return { ...state, loading: false };
    case flightsTypes.GET_CALENDAR_FLIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        calendarFlights: payload.data.flights,
        pax: payload.pax || state.pax,
      };
    case flightsTypes.GET_INITIAL_ROUNDED_CALENDAR_FLIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        calendarFlights: payload.data,
        pax: payload.pax || state.pax,
      };
    case flightsTypes.GET_CALENDAR_ROUND_FLIGHTS_SUCCESS:
      return {
        ...state,
        pax: payload.pax || state.pax,
        loading: false,
        selectedInitialDatePrice: payload.data.selectedInitialDatePrice,
        calendarFlights: (payload.data as IRoundedFlightWithData).flights.map(
          (singleMonth) => ({
            ...singleMonth,
            data: singleMonth.data.filter(
              (flight) =>
                isEqual(
                  removeTimeFromDate(new Date(flight.dateLocalTakeoff)),
                  removeTimeFromDate(payload.selectedDate),
                ) ||
                isAfter(
                  removeTimeFromDate(new Date(flight.dateLocalTakeoff)),
                  removeTimeFromDate(payload.selectedDate),
                ),
            ),
          }),
        ),
      };
    case flightsTypes.SET_DEPARTURE_AERPORT:
      return { ...state, departureAerport: payload };
    case flightsTypes.SET_DESTINATION_COUNTRY:
      return { ...state, destinationCountry: payload };
    case flightsTypes.SET_FUTURE_PRICE:
      return {
        ...state,
        calendarFlights: [
          ...state.calendarFlights.map((flight) => ({
            ...flight,
            data: flight.data.map((singleData) => ({
              ...singleData,
              price: isAfter(new Date(singleData.dateLocalTakeoff), payload.date)
                ? singleData.price + state.selectedInitialDatePrice
                : singleData.price,
            })),
          })),
        ],
      };
    case flightsTypes.SAVE_DATES_SUCCESS:
      return {
        ...state,
        departureDate: payload.departureDate,
        returningDate: payload.returningDate,
      };
    case flightsTypes.GET_DETAILED_FLIGHTS_SUCCESS:
      return {
        ...state,
        flights: payload,
        selectedFlights: {} as ISelectedFlights,
      };
    case flightsTypes.RESET_CALENDAR:
      return {
        ...state,
        calendarFlights: [],
      };
    case flightsTypes.SAVE_PASSENGER:
      return {
        ...state,
        confirmerdPassengers: payload,
      };
    case flightsTypes.CHANGE_FLIGHT_TYPE:
      return {
        ...state,
        isRoundFlight: payload,
      };
    case flightsTypes.SELECT_FLIGHTS: {
      const selectedFlights = () =>
        (state.flights as { oneWayFlights: TFlightResponse[] }).oneWayFlights.filter(
          (el) => el._id === payload._id,
        );

      const selectedGoingFlights = () =>
        (state.flights as IRoundFlightDetails).goingFlights.filter(
          (el) => el._id === payload._id,
        );
      const selectedReturningFlights = () =>
        (state.flights as IRoundFlightDetails).returningFlights.filter(
          (el) => el._id === payload._id,
        );

      if (payload.typeOfFlight === 'Single')
        return {
          ...state,
          flights: { oneWayFlights: selectedFlights() },
          selectedFlights: { ...state.selectedFlights, singleFlight: selectedFlights()[0] },
        };
      if (payload.typeOfFlight === 'Going')
        return {
          ...state,
          flights: {
            ...state.flights,
            goingFlights: selectedGoingFlights(),
          },
          selectedFlights: {
            ...state.selectedFlights,
            goingFlight: selectedGoingFlights()[0],
          },
        };

      return {
        ...state,
        flights: {
          ...state.flights,
          returningFlights: selectedReturningFlights(),
        },
        selectedFlights: {
          ...state.selectedFlights,
          returningFlight: selectedReturningFlights()[0],
        },
      };
    }
    default:
      return state;
  }
};
