import {
  FormattedRoom,
  Price,
  PriceDetails,
} from './soap/formatQuotedAvailabilitySoapResponse/types';

export const FormattedRoomsToPriceRooms: (
  rooms: FormattedRoom[],
  tax: Price | undefined,
  transportation: Price | undefined,
) => PriceDetails[] | null = (rooms, tax, transportaion) => {
  if (!tax || !transportaion) return rooms.map(formatRoom);

  const newTax: PriceDetails = { type: 'Tax', price: tax };
  const newTransportaion: PriceDetails = { type: 'Transportaion', price: transportaion };

  const newPriceDetailsRooms: PriceDetails[] = [newTax, newTransportaion];

  newPriceDetailsRooms.unshift(...rooms.map(formatRoom));

  return newPriceDetailsRooms;
};

const formatRoom = ({ description, price, currency }: FormattedRoom, index: number) => ({
  type: `Room ${index + 1}`,
  price: {
    quantity: 1,
    ref: 'STAY',
    unitPrice: price,
    description,
    totalPrice: price,
    currency,
  },
});
