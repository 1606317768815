import ClientCredentials from 'client/components/ClientCredentials';
import React from 'react';
import { IBookingConfirmation } from 'types/IBookingConfirmation';
import { ICustomCatalogue } from 'types/ICatalogue';
import { formatDate } from 'client/helpers/format/formatDate';
import PriceDetails from './PriceDetails';
import ReservationConfirmationCards from '../ReservationConfirmationCards';
import { useReservationConfirmation } from './useReservationConfirmation';
interface IReservationConfirmationProps {
  booking: IBookingConfirmation;
  customCatalogue: ICustomCatalogue | undefined;
}
const ReservationConfirmation: React.FC<IReservationConfirmationProps> = ({
  booking,
  customCatalogue,
}) => {
  const { t, handleCreatePayment } = useReservationConfirmation(booking);

  return (
    <div className="container">
      <div className="card reservation-details-card-1">
        <div className="card-header bg-white">
          <div className="media flex-sm-row flex-column-reverse justify-content-between ">
            <div className="col my-auto">
              <h4 className="mb-0">
                {t('reservationDetails.thanks')}
                <span className="reservation-details-change-color">
                  {' '}
                  {booking.reservationFormDetails.agent.civility}{' '}
                  {booking.reservationFormDetails.agent.firstName}{' '}
                  {booking.reservationFormDetails.agent.lastName}
                </span>
                !
              </h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row justify-content-between mb-3">
            <div className="col-auto">
              <h3 className="color-1 mb-0 reservation-details-change-color">
                {t('reservationDetails.quotation')}
              </h3>
              <span className="text-capitalize">
                {booking.trip.permalink?.replaceAll('-', ' ')}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="container pl-5 mb-5 col-lg-6 col-md-6 col-sm-12">
              <div className="row pt-5 pb-3">
                <p className="mb-1 text-dark">
                  <b>{t('reservationDetails.orderDetails')}</b>
                </p>
              </div>
              <p className="row mb-1">
                <span className="font-weight-bolder">Date de départ : </span>
                {formatDate(booking.formattedData.startDate)}
              </p>
              <p className="row mb-1">
                <span className="font-weight-bolder">
                  {t('reservationDetails.numberOfNights')}
                </span>
                {booking.formattedData.numberOfNights}
              </p>
            </div>
            <div className="container col-sm-12 col-lg-5 col-md-5 ml-4">
              <img
                alt={booking.trip.permalink}
                className="w-75 rounded ml-md-5 ml-lg-5 ml-sm-2 mb-3"
                src={customCatalogue?.media[0]}
              />
            </div>
          </div>
          <ClientCredentials clientCredentials={booking.reservationFormDetails.agent} />
          <ReservationConfirmationCards booking={booking} />
        </div>
        <PriceDetails />

        <div className="reservation-details-card-footer">
          <div className="justify-content-between my-3 ">
            <div className="col-sm-auto col-auto my-auto" />
            <div className="col-auto my-auto text-center">
              <h2 className="font-weight-bold">{t('reservationDetails.totalPrice')}</h2>
            </div>
            <div className="col-auto my-auto ml-auto text-center">
              <h1 className="display-3 ">&euro; {booking.payment?.totalPrice?.toFixed(2)}</h1>
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn btn-primary d-flex justify-content-center mx-auto my-3"
        type="button"
        onClick={handleCreatePayment}
      >
        {t('reservationDetails.confirm')}
      </button>
    </div>
  );
};

export default ReservationConfirmation;
