import { PaginatedResponse } from 'client/models/utils';
import { ExhibitedProductTypes } from 'client/_redux/types/exhibitedProductTypes';
import { HomeProductsTypes } from 'client/_redux/types/homePageProductsTypes';
import { ProductTypes } from 'client/_redux/types/productTypes';
import { ICatalogue } from 'server/models/Catalogue/types';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
import { IHomeProduct } from 'server/models/HomeProducts/types';
import { ICustomCatalogue } from 'types/ICatalogue';
import { IReduxAction } from 'types/IReduxAction';

const initialState = {
  loading: true,
  homeProducts: [],
  isPreloaded: false,
  isHomeProductsPreloaded: false,
};

export interface IProductListInterface extends Partial<PaginatedResponse<ICatalogue>> {
  isPreloaded?: boolean;
  isHomeProductsPreloaded?: boolean;
  loading?: boolean;
  homeProducts?: IHomeProduct[];
  selectedEditingProduct?: IHomeProduct;

  exhibitedProduct?: IExhibitedProduct[];
  customProducts?: PaginatedResponse<ICustomCatalogue>;
}

export default (
  state: IProductListInterface = initialState,
  { type, payload }: IReduxAction,
): IProductListInterface => {
  const { items, page, ...rest } = payload || {};

  switch (type) {
    case ProductTypes.GET_PRODUCTS_START:
      return { ...state, loading: true };
    case ProductTypes.GET_PRODUCTS_FAILED:
    case ProductTypes.GET_NEW_PRODUCTS_FAILED:
      return { ...state, loading: false };
    case ProductTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        items: page === 1 ? items : [...(state.items || []), ...items],
        page,
        loading: false,
        ...rest,
      };
    case ExhibitedProductTypes.GET__EXHIBITED_PRODUCTS_START:
      return { ...initialState };
    case HomeProductsTypes.GET_HOME_PRODUCTS_START:
      return { ...state, loading: true };
    case HomeProductsTypes.GET_HOME_PRODUCTS_FAILED:
    case ExhibitedProductTypes.GET__EXHIBITED_PRODUCTS_FAILURE:
      return { ...state, loading: false };
    case HomeProductsTypes.GET_HOME_PRODUCTS_SUCCESS:
      return { ...state, homeProducts: payload, loading: false };
    case ExhibitedProductTypes.GET__EXHIBITED_PRODUCTS_SUCCESS:
      return { ...state, loading: false, exhibitedProduct: payload };

    case ProductTypes.GET_CUSTOM_PRODUCTS:
      return {
        ...state,
        customProducts: {
          items,
          page,
          ...rest,
        },
        page,
        loading: false,
        ...rest,
      };
    default:
      return state;
  }
};
