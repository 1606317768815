import { getCompressedImageUrl } from 'client/helpers/getCompressedImageUrl';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import LazyImage from '../ImageCarousel/LazyImage';
export interface ICardProps {
  isDashboardCard?: boolean;
  isPlus?: boolean;
  title?: string;
  category?: string;
  image?: string;
  price: number;
  stars?: number;
  badgeText?: string;
  badgeColor?: 'success' | 'danger' | 'warning';
  id?: number;
  travelPeriod?: string;
  travelFrom?: string;
}

const ProductCard: React.FC<ICardProps> = ({
  isDashboardCard,
  title,
  category,
  image,
  price,
  stars,
  badgeText,
  badgeColor,
  isPlus,
}) => {
  const [t] = useTranslation();

  return (
    <div data-testid="homePageCard" className="pt-3">
      <div
        className="card position-relative card-stretched-vertical card-transition bg-img-start gradient-y-overlay-lg-dark"
        style={{
          height: '15rem',
        }}
      >
        <LazyImage
          src={`${getCompressedImageUrl(encodeURI(image || ''))}?tr=w-400,h-300`}
          alt={title}
          className="position-absolute top-0 h-100 w-100 rounded"
          style={{ objectFit: 'cover', zIndex: -1 }}
          width="450px"
        />
        <div
          className="card-body p-0 h-100 position-relative rounded"
          style={{
            background: `linear-gradient(
              0deg, 
              rgba(0,0,0,0.45) 0%, 
              rgba(0,0,0,0.2) 42%, 
              rgba(255,255,255,0.15) 100%
              )`,
          }}
        >
          <div
            style={{
              background: 'rgba(0,0,0,0.45)',
            }}
            className="card-footer bottom-0 p-2 position-absolute mb-5"
          >
            <h3 className="text-white ">{title}</h3>
            <span className="d-block text-white h4">
              {`${t('productCard.APartirDe')} ${Math.ceil(price).toFixed(0)} €`}
            </span>
          </div>
          <div className="mb-1 position-absolute bottom-0 right-0 bg-secondary p-2 m-3">
            <span className="text-white">Nos offres</span>
          </div>
        </div>
      </div>

      {isDashboardCard && (
        <div className="card-footer text-center">
          <i
            className={`far fa-${
              isPlus ? 'plus-square text-success' : 'minus-square text-danger'
            } fa-3x `}
          />
        </div>
      )}
    </div>
  );
};

export default ProductCard;
