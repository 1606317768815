import { swapArrayElements } from 'dashboard/helpers/swapElementArray';
import { IProductEditingValues } from 'dashboard/containers/ProductEditor/useProductEditor';
import { getOneExhibitedProduct } from 'dashboard/_redux/actions/product/getOneExhibitedProduct';
import { updateExhibitedProduct } from 'dashboard/_redux/actions/product/updateExhibitedProduct';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
import { useAppSelector } from 'client/hooks/useAppSelector';

export interface IRouterParams {
  permalink: string;
}
export const useEditProductDashboard = () => {
  const { exhibitedProduct } = useAppSelector((state) => state.dashboradProduct);
  const { loading } = useAppSelector((state) => state.loading);

  const dispatch = useDispatch();

  const { permalink } = useParams<IRouterParams>();
  const history = useHistory();

  useEffect(() => {
    dispatch(getOneExhibitedProduct(permalink, history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleUpdateExhibitedProduct = (data: IProductEditingValues) => {
    const newMedia = swapArrayElements(exhibitedProduct?.media, 0, data.imageIndex);

    const updatedExhibitedProduct: Partial<IExhibitedProduct> = {
      ...data,
      media: newMedia,
    };

    dispatch(updateExhibitedProduct(exhibitedProduct?._$id, updatedExhibitedProduct));
  };

  return { exhibitedProduct, handleUpdateExhibitedProduct, loading };
};
