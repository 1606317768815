import { differenceInHours, differenceInMinutes } from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';
import React from 'react';
import { TFlightResponse } from 'server/services/FlightsService';
import { airlineNames } from './airlineNames';
import AirplaneSVG from './AirplaneSVG';
import { airportTimezone } from './airportTimezone';
import LaugageSVG from './LaugageSVG';

const FlightLine = ({
  flightDetails,
  isReturn,
}: {
  isReturn?: boolean;
  flightDetails: TFlightResponse;
}) => {
  const dateFormat = 'YYYY-MM-DD HH:mm';

  const goingFlightLandingDate = parseFromTimeZone(
    `${flightDetails.dateLocalLanding} ${flightDetails.hoursLocalLanding}`,
    dateFormat,
    {
      timeZone:
        airportTimezone[flightDetails.destinationAirport.code as keyof typeof airportTimezone],
    },
  );
  const goingFlightTakeOffDate = parseFromTimeZone(
    `${flightDetails.originalDateLocalTakeOff} ${flightDetails.originalHoursLocalTakeOff}`,
    dateFormat,
    {
      timeZone:
        airportTimezone[flightDetails.originAirport.code as keyof typeof airportTimezone],
    },
  );

  return (
    <div>
      <div className="d-md-flex mb-3 justify-content-between ">
        <span>
          <strong>{isReturn ? 'RETOUR' : 'ALLER'} · </strong>
          {airlineNames[flightDetails.airline as keyof typeof airlineNames] ||
            flightDetails.airline}
        </span>
        <div
          className={`d-md-flex d-none align-items-center ${
            flightDetails.luggageOptions.price ? '' : 'text-success'
          }`}
        >
          <LaugageSVG
            width={16}
            height={16}
            fill={flightDetails.luggageOptions.price ? '#677788' : 'teal'}
          />
          {flightDetails.luggageOptions.price
            ? 'Bagages à main'
            : `Bagages à enregistrer inclus (${
                flightDetails.luggageOptions.quantity > 1
                  ? `${flightDetails.luggageOptions.quantity} x `
                  : ''
              }${flightDetails.luggageOptions.weight} kg) !`}
        </div>
      </div>
      <div className="row">
        <div className="col-md-1">
          <img
            className=""
            src={`https://www.govoyages.com/images/onefront/airlines/sm${flightDetails.airline}.gif`}
            alt={flightDetails.airline}
            height={35}
          />
        </div>
        <div className="col-md-2">
          <strong className="h3">{flightDetails.originalHoursLocalTakeOff}</strong>
          <p className="mt-2">
            {flightDetails.originAirport.name} ({flightDetails.originAirport.code})
          </p>
        </div>
        <div className="col-md-5 d-flex  flex-column align-items-center justify-content-center">
          <div>
            <div
              style={{
                gap: '10px',
                width: 'fit-content',
                margin: 'auto',
                background: '#eAEAEA',
              }}
              className="d-flex badge p-2 px-3 badge-pill align-items-center "
            >
              <div>
                {differenceInHours(
                  new Date(goingFlightLandingDate),
                  new Date(goingFlightTakeOffDate),
                )}{' '}
                h{' '}
                {differenceInMinutes(
                  new Date(goingFlightLandingDate),
                  new Date(goingFlightTakeOffDate),
                ) % 60}{' '}
                min
              </div>
              <div>
                <AirplaneSVG width={16} height={16} />
              </div>
              <div>{flightDetails.viaAirport ? ' avec escale(s)' : ' direct'}</div>
            </div>
          </div>
          <div className="text-muted">
            <p>
              <small>
                (N° {flightDetails.airline}
                {flightDetails.flightNumber})
              </small>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <strong className="h3">
            {flightDetails.hoursLocalLanding}
            {/* {format(new Date(goingFlight.dateLocalLanding), 'hh:mm')} */}
          </strong>
          <p className="mt-2">
            {flightDetails.destinationAirport.name} ({flightDetails.destinationAirport.code})
          </p>
        </div>
      </div>
      <div
        className={`d-flex d-md-none align-items-center ${
          flightDetails.luggageOptions.price ? '' : 'text-success'
        }`}
      >
        <LaugageSVG
          width={16}
          height={16}
          fill={flightDetails.luggageOptions.price ? '#677788' : 'teal'}
        />
        {flightDetails.luggageOptions.price
          ? 'Bagages à main'
          : `Bagages à enregistrer inclus (${
              flightDetails.luggageOptions.quantity > 1
                ? `${flightDetails.luggageOptions.quantity} x `
                : ''
            }${flightDetails.luggageOptions.weight} kg) !`}
      </div>
    </div>
  );
};

export default FlightLine;
