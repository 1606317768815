import ImageCarousel from 'client/components/ImageCarousel';
import { formatDate } from 'client/helpers/format/formatDate';
import React from 'react';
import { useProductSummary } from './useProductSummary';

interface IProps {
  title?: string;
  adultCount: string;
  childCount: string;
  startDate?: string;
  endDate?: string;
}
const ProductSummary: React.FC<IProps> = ({
  title,
  adultCount,
  childCount,
  startDate,
  endDate,
}) => {
  const { t, media } = useProductSummary();

  return (
    <div className="card m-2 p-2">
      <div className="d-flex flex-column card-heder">
        <h3 className="text-center pl-2 card-body ">
          {`${title?.replace('*', ' ')}`}
          {title?.includes('*') ? <i className="fas fa-star" /> : ''}{' '}
        </h3>
        {media && <ImageCarousel media={media} autoPlay />}

        <div className="pt-1 px-4 mt-3 font-size-1 text-start text-dark ">
          <li className="my-1">
            {Number(adultCount) > 1
              ? `${t('room.for')} ${adultCount} ${t('room.Adults')}`
              : `${t('room.for')} ${adultCount} ${t('room.Adult')}`}
            {+childCount > 0 && ` ${t('room.and')} ${childCount} ${t('room.child')} `}
          </li>
          <p>
            <li className="my-1">
              {t('room.startDate')}: {startDate && formatDate(startDate)}
            </li>
            <li className="my-1">
              {t('room.endDate')}: {endDate && formatDate(endDate)}
            </li>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductSummary;
