/* eslint-disable react/button-has-type */
import React from 'react';

const UnstyledButton = ({
  style,
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    style={{
      border: 'none',
      margin: 0,
      padding: 0,
      width: 'auto',
      overflow: 'visible',
      background: 'transparent',
      color: 'inherit',
      font: 'inherit',
      lineHeight: 'normal',
      WebkitFontSmoothing: 'inherit',
      MozOsxFontSmoothing: 'inherit',
      WebkitAppearance: 'none',
      ...style,
    }}
    {...props}
  >
    {children}
  </button>
);

export default UnstyledButton;
