import React from 'react';
import Loader from './Loader';

import { useKeywordsTab } from './useKeywordsTab';

const KeywordsTab = () => {
  const { keywords, loading } = useKeywordsTab();

  if (loading)
    return (
      <>
        <Loader />
        <Loader />
        <Loader />
      </>
    );

  return (
    <div data-testid="KeywordsTab">
      <div className="h3">
        {keywords?.map(({ title, id }) => (
          <span key={id} className="text-white badge badge-dark p-2 mr-1 mb-1 mb-md-0">
            #{title}
          </span>
        ))}
      </div>
    </div>
  );
};

export default KeywordsTab;
