import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { fr } from 'date-fns/locale';
import format from 'date-fns/format';
import { useFilterContentFlight } from './useFilterContentFlight';
import 'moment/locale/fr';
moment.locale('fr');
setDefaultOptions({ locale: fr });

const FlightsPageCalendar: React.FC = () => {
  const {
    date,
    dates,
    windowSize,
    isRoundTrip,
    handleReset,
    focusedInput,
    initialMonth,
    initialCalendarFetch,
    setFocusedInput,
    renderDayContents,
    handleMonthChange,
    handleBlockedDays,
    handleDatesChange,
    queryReturningDate,
    queryDepartureDate,
    focusedSingleDateInput,
    roundTripStartDateClick,
    handleSingleDatesChange,
    setFocusedSingleDateInput,
  } = useFilterContentFlight();

  return (
    <>
      {isRoundTrip && initialMonth ? (
        <div className="row align-items-center">
          <DateRangePicker
            startDatePlaceholderText={format(
              queryDepartureDate ? new Date(queryDepartureDate) : new Date(),
              'P',
            )}
            endDatePlaceholderText={format(
              queryReturningDate ? new Date(queryReturningDate) : new Date(),
              'P',
            )}
            startDateId=""
            endDateId=""
            onClose={() => initialCalendarFetch}
            transitionDuration={15}
            numberOfMonths={2}
            endDate={dates.endDate}
            focusedInput={focusedInput || null}
            onDatesChange={handleDatesChange}
            startDate={dates.startDate}
            displayFormat="P"
            onFocusChange={setFocusedInput}
            initialVisibleMonth={() => initialMonth}
            renderDayContents={renderDayContents}
            onNextMonthClick={handleMonthChange}
            onPrevMonthClick={handleMonthChange}
            isDayBlocked={handleBlockedDays}
            withFullScreenPortal={windowSize.width < 992}
            daySize={60}
            orientation={windowSize.width > 992 ? 'horizontal' : 'vertical'}
          />
          <button
            className="btn btn-primary ml-sm-1 px-5 px-sm-3 refresh-callendar-button "
            type="button"
            onClick={handleReset}
            disabled={!!roundTripStartDateClick}
          >
            <i className="fas fa-redo" />
          </button>
        </div>
      ) : (
        <>
          <SingleDatePicker
            id=""
            css={`
              width: 100%;
            `}
            placeholder={
              format(new Date(queryDepartureDate), 'P') || new Date().toLocaleDateString('fr')
            }
            transitionDuration={15}
            numberOfMonths={2}
            initialVisibleMonth={() => new Date()}
            orientation={windowSize.width > 992 ? 'horizontal' : 'vertical'}
            renderDayContents={renderDayContents}
            onDateChange={handleSingleDatesChange}
            focused={focusedSingleDateInput.focused}
            onNextMonthClick={handleMonthChange}
            onPrevMonthClick={handleMonthChange}
            daySize={60}
            withFullScreenPortal={windowSize.width < 992}
            onFocusChange={setFocusedSingleDateInput}
            date={date}
            isDayBlocked={handleBlockedDays}
          />
        </>
      )}
    </>
  );
};

export default FlightsPageCalendar;
