import { DBDocument } from 'client/models/utils';
import { TransactionTypes } from 'client/_redux/types/transactionsTypes';
import { ITransaction } from 'server/models/Transaction/types';
import { IReduxAction } from 'types/IReduxAction';

const initialState = {
  transaction: {} as ITransaction & DBDocument,
  isPreloaded: false,
};

export interface ITransactionInterface {
  isPreloaded: boolean;
  transaction: ITransaction & DBDocument;
  loading?: boolean;
}

export default (
  state: ITransactionInterface = initialState,
  { type, payload }: IReduxAction,
): ITransactionInterface => {
  switch (type) {
    case TransactionTypes.GET_TRANSACTIONS_SUCCESS:
      return { ...state, transaction: payload, loading: false };
    default:
      return state;
  }
};
