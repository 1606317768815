import * as DashboardAuthServices from 'dashboard/_redux/services/administrator';
import { AdministratorTypes } from 'dashboard/_redux/types/administratorTypes';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { errorHandler } from 'client/helpers/errorHandler';
import { IThunkAction } from 'types/IThunkAction';
import { ICreateAdminFormValues } from 'dashboard/containers/CreateAdminForm';

export const addAdmin: (
  values: ICreateAdminFormValues,
  onSuccess?: () => void,
) => IThunkAction = ({ email, password, firstName, lastName }, onSuccess) => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const response = await DashboardAuthServices.addAdmin()({
      email,
      password,
      firstName,
      lastName,
    });
    const { ...userData } = response.data;

    dispatch({ type: AdministratorTypes.ADD_ADMIN, payload: userData });
    onSuccess?.();
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
