import { clientApi } from 'api';
import { IWithDBDocument } from 'client/models/utils';
import { PromotionState } from 'dashboard/_redux/reducers/promotion';
import { getFromData } from 'dashboard/helpers/getFromData';

export const getPromotion = (isServer = false) => async () => {
  const response = await clientApi.get<IWithDBDocument<PromotionState>>(`/promotions`);

  return response.data;
};
export const setPromotion = (isServer = false) => async (data: PromotionState) => {
  const formData = new FormData();

  getFromData(formData, data);
  const response = await clientApi.post<IWithDBDocument<PromotionState>>(
    `/promotions`,
    formData,
  );

  return response.data;
};
