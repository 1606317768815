import React, { useEffect, useRef, useState } from 'react';
interface IProps {
  open?: boolean;
  title: string | JSX.Element;

  scrollable?: boolean;

  className?: string;
  isClicked?: boolean;
}

const Collapsible: React.FC<IProps> = ({
  open,
  children,
  title,
  scrollable: isOverFlow,
  className,
  isClicked,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserverCallback: ResizeObserverCallback = (el: ResizeObserverEntry[]) => {
      setHeight(el[0].contentRect.height);
    };
    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <div className={`card ${className || ''}`}>
        <div
          onClick={
            isClicked
              ? handleFilterOpening
              : () => {
                  ('');
                }
          }
        >
          <div className="p-3 border-bottom">
            <h2 className="h5 font-weight-bold text-left">{title}</h2>
            {children && (
              <button
                type="button"
                data-testid="collapse-button-test"
                className="btn position-absolute top-0 right-0"
                onClick={
                  isClicked
                    ? () => {
                        ('');
                      }
                    : handleFilterOpening
                }
              >
                <i className={`fas fa-chevron-down ${isOpen ? 'fa-flip-vertical ' : ''}`} />
              </button>
            )}
          </div>
        </div>

        <div
          data-testid="collapse-test"
          className="border-bottom my-collapse"
          style={{ height, overflow: isOverFlow ? 'auto' : '' }}
        >
          <div ref={ref}>
            <div className="p-3">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
