import React from 'react';
import ImageGalleryContainer from '.';
import LoaderLayout from './LoaderLayout/LoaderLayout';

interface IProps {
  isLoading: boolean;
  images?: string[];
  youtubeEmbedID?: string;
}

const ImageGalleryWrapper: React.FC<IProps> = ({ isLoading, images, youtubeEmbedID }) => {
  if (!isLoading)
    return <ImageGalleryContainer images={images} youtubeEmbedID={youtubeEmbedID} />;

  return <LoaderLayout />;
};

export default ImageGalleryWrapper;
