import i18n from 'locales';

export type productType = 'hotel' | 'vol' | 'séjour' | 'hotel&vol' | 'transfert' | '';
export const getProductIcon = (str: string) => {
  let icon = '';
  let type = '';

  switch (str) {
    case 'hotel':
      icon = 'fas fa-hotel';
      type = i18n.t('productCard.SejourA');
      break;
    case 'vol':
      icon = 'fas fa-plane';
      type = i18n.t('productCard.VolVers');
      break;
    case 'séjour':
      icon = 'fas fa-umbrella-beach';
      type = i18n.t('productCard.SejourA');
      break;
    case 'hotel&vol':
      icon = 'fas fa-suitcase';
      type = i18n.t('productCard.SejourA');
      break;
    case 'transfert':
      icon = 'fas fa-car-side';
      type = i18n.t('productCard.ExcursionA');
      break;
    default:
      break;
  }

  return { icon, type };
};
