/* eslint-disable max-lines */
import SEO from 'client/components/SEO';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import HeaderStyle from 'client/containers/ReservationFormContainer/HeaderStyle';
import { useFlightsForm } from './useFlightsForm';
import ClientData from './ClientData';
import FligthSummary from './FligthSummary';
import FinancialRecap from './FinancialRecap';

const FLightsForm = () => {
  const { formMethods, onSubmit, kids, babies, selectedFlights, pax } = useFlightsForm();
  const adults = Number(pax || '0') - Number(kids || '0') - Number(babies || '0');

  return (
    <>
      <SEO title="Reservation de vols" />
      <div className="container container-xxl">
        <HeaderStyle targetNumber={2} />
      </div>
      <div className="container container-xxl px-4 pb-4 mt-2">
        <FormProvider {...formMethods}>
          {selectedFlights && Object.keys(selectedFlights).length ? (
            !!pax && (
              <div className="row">
                <form
                  id="reservation-form"
                  className="col-12 col-md-9"
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                >
                  <FligthSummary flights={selectedFlights} />
                  <div className="row my-3 border">
                    {[...Array(adults)].map((el, i) => (
                      <div
                        key={el + i.toString()}
                        className="col-12 col-md-6 d-flex flex-column justify-content-center flex-start my-4"
                      >
                        <h2 className=" m-2 py-2 text-primary">Passager {i + 1}</h2>
                        <ClientData isEmailRequired={i === 0} passengerNumber={i} />
                      </div>
                    ))}
                    {[...Array(+kids)].map((el, i) => (
                      <div
                        key={el + i.toString()}
                        className="col-12 col-md-6 d-flex flex-column justify-content-center flex-start my-4"
                      >
                        <h2 className=" m-2 py-2 text-primary">Enfant {i + 1}</h2>
                        <ClientData passengerNumber={i + adults} />
                      </div>
                    ))}
                    {[...Array(+babies)].map((el, i) => (
                      <div
                        key={el + i.toString()}
                        className="col-12 col-md-6 d-flex flex-column justify-content-center flex-start my-4"
                      >
                        <h2 className=" m-2 py-2 text-primary">Bebe {i + 1}</h2>
                        <ClientData passengerNumber={i + adults + Number(kids || '0')} />
                      </div>
                    ))}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Valider
                    </button>
                  </div>
                </form>
                <div className="col">
                  <div style={{ top: '120px' }} className="my-3 border p-3 sticky-md-top">
                    <FinancialRecap
                      flights={selectedFlights}
                      adults={adults}
                      kids={+kids}
                      babies={+babies}
                      pax={+pax}
                    />
                    <button
                      type="submit"
                      form="reservation-form"
                      style={{ width: '100%' }}
                      className="btn btn-primary"
                    >
                      Continuer
                    </button>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="text-center text-danger">Flight not found</div>
          )}
        </FormProvider>
      </div>
    </>
  );
};

export default FLightsForm;
