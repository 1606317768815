export enum flightsTypes {
  GET_CALENDAR_FLIGHTS_START = 'GET_CALENDAR_FLIGHTS_START',
  CLEAR_DETAILED_FLIGHTS = 'CLEAR_DETAILED_FLIGHTS',
  GET_CALENDAR_FLIGHTS_FAILED = 'GET_CALENDAR_FLIGHTS_FAILED',
  GET_CALENDAR_FLIGHTS_SUCCESS = 'GET_CALENDAR_FLIGHTS_SUCCESS',
  GET_INITIAL_ROUNDED_CALENDAR_FLIGHTS_SUCCESS = 'GET_INITIAL_ROUNDED_CALENDAR_FLIGHTS_SUCCESS',
  GET_CALENDAR_ROUND_FLIGHTS_SUCCESS = 'GET_CALENDAR_ROUND_FLIGHTS_SUCCESS',
  SET_DEPARTURE_AERPORT = 'SET_DEPARTURE_AERPORT',
  SET_DESTINATION_COUNTRY = 'SET_DESTINATION_COUNTRY',
  SET_FUTURE_PRICE = 'SET_FUTURE_PRICE',
  GET_DETAILED_FLIGHTS_SUCCESS = 'GET_DETAILED_FLIGHTS_SUCCESS',
  SAVE_DATES_SUCCESS = 'SAVE_DATES_SUCCESS',
  SET_PAX_SUCCESS = 'SET_PAX_SUCCESS',
  SELECT_FLIGHTS = 'SELECT_FLIGHTS',
  RESET_CALENDAR = 'RESET_CALENDAR',
  SAVE_FLIGHTS = 'SAVE_FLIGHTS',
  SAVE_PASSENGER = 'SAVE_PASSENGER',
  CHANGE_FLIGHT_TYPE = 'CHANGE_FLIGHT_TYPE',
}
