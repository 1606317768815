import React from 'react';
import ResponsiveWrapper from '../ResponsiveWrapper';

const Loader = () => (
  <>
    <ResponsiveWrapper>
      <svg
        role="img"
        width="100%"
        height={50}
        aria-labelledby="loading-aria"
        viewBox="0 0 1300 50"
        preserveAspectRatio="none"
      >
        <title id="loading-aria">Loading...</title>
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          clipPath="url(#clip-path)"
          style={{
            fill: 'url("#fill")',
          }}
        />
        <defs>
          <clipPath id="clip-path">
            <rect x={25} y={16} rx={5} ry={5} width={660} height={10} />
            <rect x={1140} y={5} rx={10} ry={10} width={70} height={20} />
            <rect x={1230} y={5} rx={10} ry={10} width={70} height={20} />
          </clipPath>
          <linearGradient id="fill">
            <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </ResponsiveWrapper>
    <ResponsiveWrapper isMobile>
      <svg
        role="img"
        width="100%"
        height={60}
        aria-labelledby="loading-aria11"
        viewBox="0 0 auto auto"
        preserveAspectRatio="none"
      >
        <title id="loading-aria11">Loading...</title>
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          clipPath="url(#clip-path1)"
          style={{
            fill: 'url("#fill1")',
          }}
        />
        <defs>
          <clipPath id="clip-path1">
            <rect x={0} y={4} rx={10} ry={10} width="100%" height={12} />
            <rect x={13} y={36} rx={10} ry={10} width={60} height={20} />
            <rect x={110} y={37} rx={10} ry={10} width={60} height={20} />
          </clipPath>
          <linearGradient id="fill1">
            <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </ResponsiveWrapper>
  </>
);

export default Loader;
