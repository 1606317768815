import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addArticle } from 'dashboard/_redux/actions/blogArticles';
import { IArticle } from 'server/models/Article/types';

export const useAddArticle = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const formMethods = useForm<IArticle>();

  const { handleSubmit } = formMethods;

  const onSubmit = (data: IArticle) => {
    dispatch(addArticle(data));
  };

  return {
    handleSubmit,
    formMethods,
    onSubmit,
    t,
  };
};
