import React from 'react';

const Loader: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <>
    <div data-testid="Loader" className=" col-md-3 d-md-flex align-items-md flex-md-column">
      <svg
        role="img"
        width="100%"
        height="100%"
        aria-labelledby="loading-aria"
        viewBox="auto"
        preserveAspectRatio="none"
        {...props}
      >
        <title id="loading-aria">Loading...</title>
        <rect
          x={0}
          y={0}
          width="100%"
          height="100%"
          clipPath="url(#clip-path)"
          style={{
            fill: 'url("#fill")',
          }}
        />
        <defs>
          <clipPath id="clip-path">
            <rect x={0} y={0} rx={2} ry={2} width="100%" height="100%" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div className="col-md-9">
      <div className="row">
        <svg
          role="img"
          width="100%"
          height={100}
          aria-labelledby="loading-aria1"
          viewBox="auto"
          preserveAspectRatio="none"
          {...props}
        >
          <title id="loading-aria1">Loading...</title>
          <rect
            x={0}
            y={0}
            width="100%"
            height="100%"
            clipPath="url(#clip-path1)"
            style={{
              fill: 'url("#fill")',
            }}
          />
          <defs>
            <clipPath id="clip-path1">
              <rect x={14} y={16} rx={0} ry={5} width={180} height={15} />
              <rect x={14} y={50} rx={5} ry={5} width={160} height={10} />
              <rect x={14} y={70} rx={5} ry={5} width={160} height={10} />
            </clipPath>
            <linearGradient id="fill">
              <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
                <animate
                  attributeName="offset"
                  values="-2; -2; 1"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
                <animate
                  attributeName="offset"
                  values="-1; -1; 2"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
                <animate
                  attributeName="offset"
                  values="0; 0; 3"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  </>
);

export default Loader;
