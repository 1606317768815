import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { ISelectorOption } from 'types/ISelectorOption';
import useAddonsFormSelect from './useAddonsFormSelect';

interface IProps {
  nbrPersons: number;
  addonIndex: number;
  familyOptions: ISelectorOption<string>[];
  addonId: string;
}

const AddonsFormSelect = ({ nbrPersons, addonIndex, familyOptions, addonId }: IProps) => {
  const { remainingFamily } = useAddonsFormSelect({
    name: `addonList[${addonIndex}]`,
    familyOptions,
  });

  return (
    <>
      <Input
        name={`addonList[${addonIndex}].addonRef`}
        readOnly
        value={addonId}
        style={{ display: 'none' }}
      />
      {Array(nbrPersons)
        .fill(null)
        .map((member, familyIndex) => (
          <FormSelect
            isClearable
            label={`Person ${familyIndex + 1}`}
            options={remainingFamily}
            name={`addonList[${addonIndex}].family[${familyIndex}]`}
            key={`${addonIndex}-${familyIndex + 1}`}
            initialValue={[{ label: '', value: '' }]}
            validate={Validators([{ validation: 'required' }])}
          />
        ))}
    </>
  );
};

export default AddonsFormSelect;
