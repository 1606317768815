import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { IProductInfo } from 'server/models/ProductInfo/types';
import TextEditor from 'dashboard/components/Forms/TextEditor';
import TextFieldAndInput from 'dashboard/components/Forms/TextFieldAndInput';
import Input from 'client/components/Form/Input';
import TourProgram from 'dashboard/components/Forms/TourProgram';
import ServicesIcons from 'dashboard/components/ServicesIcons';
import Validators from 'client/helpers/formValidator';
import SectionTitle from 'client/components/SectionTitle';
import { useProductDetailsEditor } from './useProductDetailsEditor';

interface IProps {
  onSubmit: SubmitHandler<IProductInfo>;
  selectedEditingProduct?: IProductInfo;
  permalink: string;
  id: string;
}

const ProductDetailsEditor: React.FC<IProps> = ({
  onSubmit,
  selectedEditingProduct,
  permalink,
  id,
}) => {
  const {
    appendDescription,
    formalitiesFields,
    appendFormalities,
    descriptionFields,
    removeDescription,
    removeFormalities,
    servicesFields,
    appendServices,
    removeServices,
    handleSubmit,
    formMethods,
    tourFields,
    appendTour,
    removeTour,
    _onSubmit,
    t,
  } = useProductDetailsEditor(onSubmit, permalink, selectedEditingProduct);

  return (
    <div
      className="border p-2"
      style={{
        borderRadius: '8px',
        background: 'white',
        boxShadow: '0px 0.375rem 1.5rem 0px rgb(140 152 164 / 13%) !important',
      }}
    >
      <FormProvider {...formMethods}>
        <form className="mt-5" onSubmit={handleSubmit(_onSubmit)}>
          <div className="d-flex justify-content-between">
            <h1 style={{ textAlign: 'center' }}>{permalink.replaceAll('-', ' ')}</h1>
            <button className="btn btn-primary" type="submit">
              <i className="fa fa-save mr-2" />
              {t('productDetails.save')}
            </button>
          </div>

          <Input
            name="metaTitle"
            label="Meta-title"
            validate={Validators([{ validation: 'required' }])}
          />
          <Input
            name="metaDescription"
            type="textarea"
            maxLength={160}
            label="Meta-description"
            validate={Validators([{ validation: 'required' }])}
          />

          <TextFieldAndInput
            title={t('productDetails.stayDetails')}
            addSection={appendDescription}
            removeSection={removeDescription}
            fields={descriptionFields}
            type
          />
          <SectionTitle title={t('productDetails.pricingConditions')} />

          <TextEditor name="includes" label={t('productDetails.priceIncludes')} />
          <TextEditor name="excludes" label={t('productDetails.priceExcludes')} />
          <TextEditor name="extra" label={t('productDetails.mustKnow')} />
          <hr />

          <TextFieldAndInput
            title={t('productDetails.formalities')}
            addSection={appendFormalities}
            removeSection={removeFormalities}
            fields={formalitiesFields}
          />
          {id[0] === 'C' && (
            <TourProgram
              title={t('productDetails.tourProgram')}
              addSection={appendTour}
              removeSection={removeTour}
              fields={tourFields}
            />
          )}
          <ServicesIcons
            formMethods={formMethods}
            addSection={appendServices}
            fields={servicesFields}
            removeSection={removeServices}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default ProductDetailsEditor;
