import { clientApi } from 'api';
import { IProductInfo } from 'server/models/ProductInfo/types';
import IEntireProductDetails from 'types/IEntireProductDetails';

export const addProductInfo = (isServer = false) => async (product: IProductInfo) => {
  const response = await clientApi.post<IProductInfo>(`/product-info/`, product);

  return response;
};

export const getProductInfo = (isServer = false) => async (params: {
  from?: string | undefined;
  'number-of-nights'?: string | undefined;
  permalink: string;
}) => {
  const response = await clientApi.get<IEntireProductDetails>(
    `/product-info/?permalink=${params.permalink}${params.from ? `&from=${params.from}` : ''}${
      params['number-of-nights'] ? `&night=${params['number-of-nights']}` : ''
    }`,
  );

  return { ...response, params };
};
export const sendImageArray = (isServer = false) => async (images: FormData) =>
  await clientApi.post<string[]>(`/product-info/image-array/`, images);
