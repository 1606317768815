import { useAppSelector } from 'client/hooks/useAppSelector';
import { selectedFilter } from 'client/_redux/actions/searchBarFilter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const useFilterHeader = (title: string) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const titleSelected = useAppSelector(
    ({ searchBarSelectedFilter }) => searchBarSelectedFilter.filterSelected.title,
  );

  useEffect(() => {
    if (titleSelected !== '' && titleSelected !== title && isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen, title, titleSelected]);
  const handleClick = (title: string) => {
    setIsOpen(!isOpen);

    dispatch(selectedFilter(title, isOpen));
  };

  return { handleClick, titleSelected, isOpen };
};

export default useFilterHeader;
