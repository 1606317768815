import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import { FilterProductTypes } from 'dashboard/_redux/types/FilterProductTypes';
import * as ProductFilterServices from 'dashboard/_redux/services/productsFilter';
import { IThunkAction } from 'types/IThunkAction';

export const getProductfilters = (): IThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { isPreloaded } = state.productFilterReducer;

  if (isPreloaded) return;

  dispatch(startLoading());
  try {
    const productFiltres = await ProductFilterServices.getProductfilter()();

    dispatch({
      type: FilterProductTypes.GET_FILTER_SEARCH_CRITERIA,
      payload: productFiltres.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: FilterProductTypes.GET_FILTER_PRODUCTS_FAILED });
  }

  dispatch(stopLoading());
};
