import Select from 'client/components/Form/Select';
import { formatAddonsPersonsRange } from 'client/helpers/formatAddonsPersonsRange';
import { Addon } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { AddonFormValues } from './useProductAddons';

type AddonsListProps = {
  addon: Addon[];
  noResultText: string;
  onChange: (addon: AddonFormValues) => void;
};

const AddonsListItems = ({ addon, noResultText, onChange }: AddonsListProps) => (
  <div>
    <table className="table container">
      <tbody>
        {addon ? (
          addon
            .filter((el) => el.ref && el.price)
            .map(({ description, maxPersons, minPersons, price, ref }, index) => (
              <tr key={ref} className="row">
                <td className="col-sm-7">{description}</td>
                <td className="col-sm-3 ">
                  {price && ref && (
                    <Select
                      onChange={(value) =>
                        onChange({
                          nbrPersons: value as number,
                          id: ref,
                          price,
                          description,
                        })
                      }
                      options={
                        (maxPersons &&
                          formatAddonsPersonsRange(minPersons || 0, maxPersons)) ||
                        []
                      }
                      selectClassName="last-select-className"
                      isLastChild={
                        index === addon.filter((el) => el.ref && el.price).length - 1 ||
                        index === addon.filter((el) => el.ref && el.price).length - 2
                      }
                    />
                  )}
                </td>
                <td className="col-sm-2">
                  <div className="p-3 badge badge-success">
                    {price &&
                      (price / 100).toLocaleString('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                  </div>
                </td>
              </tr>
            ))
        ) : (
          <div className="d-flex justify-content-center">{noResultText}</div>
        )}
      </tbody>
    </table>
  </div>
);

export default memo(AddonsListItems, isEqual);
