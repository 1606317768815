import { IRoundFlightDetails } from 'client/_redux/services/flights';
import React from 'react';
import { TFlightResponse } from 'server/services/FlightsService';
import { isOneWayFlight, isRoundedFlight } from 'types/typesGuard';

import FlightLine from './FlightLine';
import FlightPrice from './FlightPrice';

const FlightList: React.FC<{
  flights:
    | IRoundFlightDetails
    | {
        oneWayFlights: TFlightResponse[];
      };
  onFlightClick: (
    flight: {
      typeOfFlight: 'Single' | 'Going' | 'Returning';
      flight: TFlightResponse;
    }[],
  ) => void;
}> = ({ flights, onFlightClick }) => {
  if (isRoundedFlight(flights))
    return (
      <div
        className="d-flex flex-column"
        style={{
          gap: '1rem',
        }}
      >
        {flights.twoWayFlights.map(({ goingFlight, returningFlight, totalPrice }, index) => (
          <div
            key={`${goingFlight._id}${returningFlight._id}`}
            className={`shadow-serious rounded ${index === 0 ? 'border border-success' : ''}`}
          >
            {index === 0 && (
              <div className="p-3 bg-success">
                <span className="text-white h3 font-weight-bold">Meilleure option</span>
              </div>
            )}
            <div className="row justify-content-around p-3">
              <div className="col-md-9">
                <FlightLine flightDetails={goingFlight} />
                <hr
                  style={{
                    borderTop: '1px solid #677788',
                  }}
                />
                <FlightLine isReturn flightDetails={returningFlight} />
                <hr
                  className="d-md-none d-block"
                  style={{
                    borderTop: '1px solid #677788',
                  }}
                />
              </div>
              <div className="col-md-3 dashed-border d-flex flex-column">
                <FlightPrice
                  price={totalPrice}
                  availableSeats={Math.min(
                    goingFlight.availableSeats,
                    returningFlight.availableSeats,
                  )}
                />
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() =>
                    onFlightClick([
                      { flight: goingFlight, typeOfFlight: 'Going' },
                      { flight: returningFlight, typeOfFlight: 'Returning' },
                    ])
                  }
                >
                  Selectionner
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );

  if (isOneWayFlight(flights))
    return (
      <div
        className="d-flex flex-column"
        style={{
          gap: '1rem',
        }}
      >
        {flights.oneWayFlights.map((flight, index) => (
          <div
            key={`${flight._id}`}
            className={`shadow-serious rounded ${index === 0 ? 'border border-success' : ''}`}
          >
            {index === 0 && (
              <div className="p-3 bg-success">
                <span className="text-white h3 font-weight-bold">Meilleure option</span>
              </div>
            )}
            <div className="row justify-content-around p-3">
              <div className="col-md-9">
                <FlightLine flightDetails={flight} />
                <hr
                  className="d-md-none d-block"
                  style={{
                    borderTop: '1px solid #677788',
                  }}
                />
              </div>
              <div className="col-md-3 dashed-border d-flex flex-column">
                <FlightPrice price={flight.price} availableSeats={flight.availableSeats} />
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() => onFlightClick([{ flight, typeOfFlight: 'Single' }])}
                >
                  Selectionner
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );

  return <div>Loading</div>;
};

// isRoundedFlight(rawData) ? rawData.twoWayFlights : rawData.oneWayFlights,
export default FlightList;
