import { useAppSelector } from 'client/hooks/useAppSelector';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
export interface ILoginFormValues {
  email: string;
  password: string;
}

export const useLoginForm = (onSubmit: SubmitHandler<ILoginFormValues>) => {
  const formMethods = useForm<ILoginFormValues>({ shouldFocusError: true });
  const { handleSubmit } = formMethods;
  const _onSubmit: SubmitHandler<ILoginFormValues> = (data) => {
    onSubmit(data);
  };
  const { loading } = useAppSelector((state) => state.loading);
  const [t] = useTranslation();

  return {
    formMethods,
    handleSubmit,
    _onSubmit,
    loading,
    t,
  };
};
