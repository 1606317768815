import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderStyle = ({ targetNumber }: { targetNumber: number }) => {
  const [t] = useTranslation();

  return (
    <div className="row my-2 text-center  ">
      <div
        className={` border col-md-4 rounded  w-100 h-25 py-2 ${
          targetNumber === 1 ? ' text-white bg-primary ' : ' text-black bg-light '
        }`}
      >
        {t('quotedAvailabilityPage.quotationAndSetting')}
      </div>
      <div
        className={` border col-md-4 rounded w-100 h-25 py-2 ${
          targetNumber === 2 ? ' text-white bg-primary ' : ' text-black bg-light '
        }`}
      >
        {t('quotedAvailabilityPage.ParticipantsAndRegulations')}
      </div>
      <div
        className={` border col-md-4 rounded  w-100 h-25 py-2 ${
          targetNumber === 3 ? ' text-white bg-primary ' : ' text-black bg-light '
        }`}
      >
        {t('quotedAvailabilityPage.Confirmation')}
      </div>
    </div>
  );
};

export default HeaderStyle;
