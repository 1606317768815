export enum ProductTypes {
  GET_PRODUCTS_START = 'GET_PRODUCTS_START',
  GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED',
  GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
}
export enum ExhibitedProductDashboardTypes {
  GET_EXHIBITED_PRODUCTS_SUCCESS = 'GET_EXHIBITED_PRODUCTS_SUCCESS',
  GET_ONE_EXHIBITED_PRODUCTS_SUCCESS = 'GET_ONE_EXHIBITED_PRODUCTS_SUCCESS',
  UPDATE_EXHIBITED_PRODUCT_SUCCESS = 'UPDATE_EXHIBITED_PRODUCT_SUCCESS',
}
