import * as React from 'react';
import SEO from 'client/components/SEO';
// import ProductPresentation from 'client/containers/ProductContainer/ProductPresentation';
import LocationBar from 'client/containers/LocationBar';
import Duration from 'client/components/Duration';
import ProductTitle from 'client/containers/ProductContainer/ProductTitle';
import KeywordsTab from 'client/components/KeywordsTab/KeywordsTab';
import ProductImageGallery from 'client/containers/ProductContainer/ProductImageGallery';
import ProductBreadcrumbs from 'client/containers/ProductContainer/ProductBreadcrumbs';
import ProductCategory from 'client/containers/ProductContainer/ProductCategory';
import { useProductCategory } from 'client/containers/ProductContainer/ProductCategory/useProductCategory';
import ProductDescriptionCardContainer from 'client/containers/ProductDescriptionCardContainer';
import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import SelectListModalConatiner from 'client/containers/SelectListModalConatiner';
import { useProductPage } from './useProductPage';
import Description from './Description';

const Product: React.FC = () => {
  const {
    from,
    loading,
    customCatalogue,
    product,
    productInfo,
    image,
    handleClick,
    productDesc,
    navigatorRef,
    navigatorRefResponsive,
  } = useProductPage();
  const { category } = useProductCategory();

  return (
    <>
      <SEO
        title={productInfo?.metaTitle}
        description={productInfo?.metaDescription}
        image={image}
      />
      <div className="product-page-container container container-xxl">
        <ProductBreadcrumbs />
        <ProductTitle />
        <div className="d-flex flex-wrap justify-content-between">
          <LocationBar />
          <Duration />
        </div>
        <div className="d-flex flex-wrap no-gutters ">
          <div className="position-relative col-md-8 ">
            <ProductImageGallery />
            {category && (
              <div className="position-absolute top-0 right-0 m-5 h5 p-2 rounded-pill badge-success">
                <ProductCategory />
              </div>
            )}

            <div className="mt-2 px-1">
              <KeywordsTab />
            </div>
          </div>

          <div className="col-md-4 mt-2">
            {customCatalogue && (
              <ProductDescriptionCardContainer
                product={customCatalogue}
                loading={loading}
                productDetails={product}
                handleClick={handleClick}
              />
            )}
          </div>
        </div>
        <div className="row">
          <Description product={productDesc} />
          <div className="col-md-4">
            <ResponsiveWrapper>
              <div ref={navigatorRef}>
                {!!from?.length && <SelectListModalConatiner from={from} />}
              </div>
            </ResponsiveWrapper>
            <ResponsiveWrapper isMobile>
              <div className="space-bottom-5 space-top-2 " ref={navigatorRefResponsive}>
                {!!from?.length && <SelectListModalConatiner from={from} />}
              </div>
            </ResponsiveWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
