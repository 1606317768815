import SEO from 'client/components/SEO';
import ReservationConfirmation from 'client/containers/ReservationConfirmation';
import HeaderStyle from 'client/containers/ReservationFormContainer/HeaderStyle';
import React from 'react';
import { useReservationConfirmation } from './useReservationConfirmation';

const ReservationForm = () => {
  const { booking, customCatalogue } = useReservationConfirmation();

  return (
    <div className="min-vh-100">
      <SEO title="Reservation confirmation page" />

      {Object.keys(booking).length > 1 && (
        <div className="container">
          <HeaderStyle targetNumber={3} />
          <ReservationConfirmation booking={booking} customCatalogue={customCatalogue} />
        </div>
      )}
    </div>
  );
};

export default ReservationForm;
