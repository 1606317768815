import { IReduxAction } from 'types/IReduxAction';
import { FilterProductTypes } from 'dashboard/_redux/types/FilterProductTypes';
import { IFilter } from 'server/models/Filter/types';
import { DBDocument } from 'client/models/utils';

const initialState = {
  loading: true,
};

export interface IProductFilterInterface {
  productsFilter?: (IFilter & DBDocument)[];
  productsFilterToUpdate?: IFilter & DBDocument;
  loading?: boolean;
}

export default (
  state: IProductFilterInterface = initialState,
  { type, payload }: IReduxAction,
): IProductFilterInterface => {
  switch (type) {
    case FilterProductTypes.ADD_FILTER_PRODUCTS:
      return {
        ...state,
        productsFilter: payload,
      };

    case FilterProductTypes.GET_FILTER_PRODUCTS:
      return {
        ...state,
        productsFilter: payload,
      };

    case FilterProductTypes.DELETE_FILTER_PRODUCTS:
      return {
        ...state,
        productsFilter: state.productsFilter?.filter((filter) => filter._id !== payload),
      };

    case FilterProductTypes.GET_FILTER_PRODUCTS_By_ID:
      return {
        ...state,
        productsFilterToUpdate: payload,
      };

    default:
      return state;
  }
};
