import React from 'react';
import { FormProvider } from 'react-hook-form';
import HomePageFilter from 'types/enum/HomePageFilter';

import FilterContentDuration from './FilterContentDuration';
import SearchBarHomePage from '../../components/SearchBarHomePage';
import FilterContentTripType from './FilterContentTripType';
import FilterContentFrom from './FilterContentFrom';
import FilterContentDestination from './FilterContentDestination';
import useFilterHomePage from './useFilterHomePage';
import SearchSubmitButton from './SearchSubmitButton';
interface IFilterHomePage {
  isFlightPage?: boolean;
}

const FilterHomePage: React.FC<IFilterHomePage> = ({ isFlightPage }) => {
  const {
    formMethods,
    handleSubmit,
    onSubmit,
    responsiveMode,
    titleSelected,
    productSearchCriteriaValue,
    flightTabActivated,
  } = useFilterHomePage(isFlightPage);

  return (
    <FormProvider {...formMethods}>
      <form
        className={`container ${isFlightPage && !responsiveMode && 'w-75'} ${
          flightTabActivated ? 'w-50' : ''
        }`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={`${
            responsiveMode &&
            `all-rounded ${
              isFlightPage ? 'mt-5 pt-5' : 'responsive-home-filter'
            }  d-flex flex-column justify-content-center`
          }`}
        >
          <div
            className={`${
              responsiveMode
                ? 'container px-0 d-flex flex-column overflow-auto'
                : 'responsive-d-none-down d-flex flex-row justify-content-between all-rounded'
            }   w-100 bg-transparent`}
          >
            {!isFlightPage && (
              <SearchBarHomePage
                isFlightPage
                title={HomePageFilter.typeDeVoyage}
                name="tripType"
                responsiveMode={responsiveMode}
              >
                <FilterContentTripType responsiveMode={responsiveMode} />
              </SearchBarHomePage>
            )}
            {!flightTabActivated && (
              <>
                <SearchBarHomePage
                  isFlightPage
                  title={HomePageFilter.destination}
                  name="destination"
                  responsiveMode={responsiveMode}
                >
                  <FilterContentDestination
                    productSearchCriteriaValue={productSearchCriteriaValue}
                  />
                </SearchBarHomePage>
                <SearchBarHomePage
                  isFlightPage
                  title={HomePageFilter.from}
                  name="from"
                  responsiveMode={responsiveMode}
                >
                  <FilterContentFrom productSearchCriteriaValue={productSearchCriteriaValue} />
                </SearchBarHomePage>

                <SearchBarHomePage
                  isFlightPage
                  title={HomePageFilter.duration}
                  name="duration"
                  responsiveMode={responsiveMode}
                >
                  <FilterContentDuration responsiveMode={responsiveMode} />
                </SearchBarHomePage>
                <SearchSubmitButton value={titleSelected} />
              </>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default FilterHomePage;
