/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useEsc = (escFunction: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    let isMounted = true;

    if (isMounted) document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
      isMounted = false;
    };
  }, []);
};
