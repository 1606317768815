/* eslint-disable max-lines */
import Pagination from 'client/components/Pagination';
import SEO from 'client/components/SEO';
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { Link } from 'react-router-dom';
import { useBlog } from './useBlog';

const Blog = () => {
  const {
    loading,
    articles: { items, totalItems, page, totalPages },
    t,
    cover: { metaCover, metaDescription, metaTitle },
  } = useBlog();

  return loading ? (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  ) : (
    <>
      {metaCover && (
        <SEO
          title={metaTitle}
          description={metaDescription}
          image={getImageUrl(metaCover[0])}
        />
      )}
      <div className="h-100">
        <div className="w-100 " style={{ margin: '6rem 0 0 0', padding: '16.1rem 0px' }} />
        <div
          className="container-fluid content-space-t-3 w-100 background-image position-absolute top-0 left-0 text-shadow-article"
          style={{
            padding: ' 12rem 0',
            backgroundImage: `url("${
              metaCover &&
              metaCover[0] &&
              (metaCover[0].includes('https') ? metaCover[0] : getImageUrl(metaCover[0]))
            }")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            WebkitBackgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '38rem',
            filter: 'brightness(0.7)',
          }}
        />
        <div
          className="w-100 text-center overflow-hidden text-shadow-article position-absolute top-0 left-0 "
          style={{
            paddingTop: '7.5rem',
            margin: '8rem auto',
          }}
        >
          <h1
            className="display-4 text-white container-xxl mx-auto"
            style={{
              zIndex: 100,
            }}
          >
            {metaTitle}
          </h1>
          <p className="lead text-white container-xxl mx-auto">{metaDescription}</p>
        </div>
        <div className="h-100 shadow-less-serious" style={{ padding: '3rem 0rem' }}>
          <div className="container content-space-1">
            {items && items.length > 0 ? (
              <div className="col-12 mb-3 mb-sm-7">
                <div className="card śard-flush">
                  <div className="row align-items-md-center">
                    <div
                      className="col-md-8"
                      style={{ maxHeight: '29rem', overflow: 'hidden' }}
                    >
                      <img
                        className="card-img rounded-2"
                        src={`${items[0]?.metaImage}`}
                        alt="ImageDescription"
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="card-body">
                        <span className="card-subtitle">{items[0]?.metaDescription}</span>
                        <h2 className="card-title">
                          <Link className="text-dark" to={`/blog/${items[0]?._id}`}>
                            {items[0]?.metaTitle}
                          </Link>
                        </h2>
                        <p className="card-text avant-gout">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: items[0]?.article?.replace(
                                /<p/g,
                                "<p style='overflow: hidden; text-overflow: ellipsis; padding: 0 1rem;'",
                              ),
                            }}
                          />
                        </p>
                        <Link className="card-link" to={`/blog/${items[0]?._id}`}>
                          {t('blog.readMore')} <i className="bi-chevron-right small ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className="text-center min-vh-100">{t('blog.commingSoon')} </h3>
            )}
            <div className="row">
              {items &&
                items?.map((el, index: number) => (
                  <React.Fragment key={el._id}>
                    {index > 0 && (
                      <div
                        className={`order-lg-${index} ${
                          index % 4 === 0 ? 'col-12' : 'col-sm-6 col-lg-4'
                        } mb-3 mb-sm-7`}
                      >
                        <Link
                          className="card card-stretched-vertical card-transition bg-img-start gradient-y-overlay-sm-gray-900"
                          href="./blog-article.html"
                          style={{
                            backgroundImage: `url(${getImageUrl(el?.metaImage[0])})`,
                            minHeight: '25rem',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                          }}
                          to={`/blog/${el._id}`}
                        >
                          <div
                            className="w-100 h-100"
                            style={{
                              background:
                                'linear-gradient(0deg, rgba(236, 236, 236, 0.1) 0%, rgba(0, 0, 0, 0.1) 35%, rgba(0, 0, 0, 0.2) 100%)',
                              minHeight: 'inherit',
                            }}
                          >
                            <div className="card-header pb-4">
                              <span
                                className="card-subtitle text-white"
                                style={{
                                  textShadow:
                                    'rgb(255 255 255 / 50%) 9px 5px 24px, rgb(0 0 0 / 50%) 1px 1px 7px',
                                }}
                              />
                            </div>

                            <div className="card-footer">
                              <h3
                                className="card-title text-white"
                                style={{
                                  textShadow: '9px 5px 24px #ffffff80, 1px 1px 7px #000000',
                                }}
                              >
                                {el.metaTitle}
                              </h3>
                              <p
                                className="card-subtitle text-white"
                                style={{
                                  textShadow:
                                    'rgb(255 255 255 / 50%) 9px 5px 24px, rgb(0 0 0 / 50%) 1px 1px 7px',
                                }}
                              >
                                {el.metaDescription.slice(0, 40)}...
                              </p>

                              <p className="card-link link-light text-white">
                                {t('blog.readMore')}
                                <i className="bi-chevron-right small ms-1" />
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </div>
          <div className="mt-5">
            {(totalItems || 0) > 0 && page && totalPages && (
              <Pagination
                totalPages={totalPages}
                page={page}
                next={t('pagination.next')}
                previous={t('pagination.previous')}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
