import NotFoundHeader from 'client/components/NotFoundHeader';
import NotFoundSVG from 'client/components/SVG/NotFoundSVG';
import * as React from 'react';
import SEO from 'client/components/SEO';

const NotFound: React.FC = () => (
  <>
    <SEO
      title="Not Found page"
      description="la page d'acceuil du site"
      // eslint-disable-next-line max-len
      image="https://images.unsplash.com/photo-1578328819058-b69f3a3b0f6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
    />
    <NotFoundHeader />
    <NotFoundSVG className="not-found-svg z-index-2" />
  </>
);

export default NotFound;
