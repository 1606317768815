import React from 'react';

const Loader: React.FC = () => (
  <svg
    data-testid="Duration-Loader"
    role="img"
    width={231}
    height={28}
    aria-labelledby="loading-ariaDuration"
    viewBox="0 0 auto auto"
    preserveAspectRatio="none"
  >
    <title id="loading-ariaDuration">Loading...</title>
    <rect
      x={0}
      y={0}
      width="100%"
      height="100%"
      clipPath="url(#clip-pathDuration)"
      style={{
        fill: 'url("#fillDuration")',
      }}
    />
    <defs>
      <clipPath id="clip-pathDuration">
        <rect x={0} y={0} rx={15} ry={15} width="100%" height="100%" />
      </clipPath>
      <linearGradient id="fillDuration">
        <stop offset={0.599964} stopColor="#f3f3f3" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={1.59996} stopColor="#ecebeb" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset={2.59996} stopColor="#f3f3f3" stopOpacity={1}>
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export default Loader;
