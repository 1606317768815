import React from 'react';
import { IAirport, IFlightDocument } from 'server/models/Flights/types';

const ExpandedRow = ({
  originalHoursLocalTakeOff,
  originAirport,
  hoursLocalLanding,
  destinationAirport,
  luggageOptions,
  isFlightSelected,
}: Partial<IFlightDocument> & { isFlightSelected: boolean }) => (
  <>
    <div className={`expanded-card ${isFlightSelected ? 'border-success border-top-0' : ''} `}>
      <div className="row mb-5 justify-content-between py-3">
        <span className="row py-2">
          <div className="d-flex flex-column align-items-center justify-content-between px-3">
            <span className="circle" />
            {[...Array(7)].map((e, i) => (
              <span key={i.toString()} className="small-circle" />
            ))}
            <span className="circle" />
          </div>
          <div className="d-flex flex-column px-3 justify-content-between w-75 ">
            <div className="text-dark">
              {originalHoursLocalTakeOff} {(originAirport as IAirport).name}(
              {(originAirport as IAirport).code})
            </div>
            <div className="text-dark">
              {hoursLocalLanding} {(destinationAirport as IAirport).name}(
              {(destinationAirport as IAirport).code})
            </div>
          </div>
        </span>
        <div className="col-6 col-sm-4 d-flex flex-column py-4 p-sm-2">
          <span>
            Prix des bagages:{' '}
            <span className={`${!luggageOptions?.price && 'text-success '} font-weight-bold`}>
              {luggageOptions?.price || 'inclus'}
              {luggageOptions?.price ? '€' : ''}
            </span>
          </span>
          <span>
            Poids maximum autorisé:{' '}
            <span className="font-weight-bold">{luggageOptions?.weight}Kg</span>
          </span>
          <span>
            Quantité de bagages:{' '}
            <span className="font-weight-bold">{luggageOptions?.quantity}</span>
          </span>
        </div>
      </div>
      <span className="mt-4">
        Une fois votre réservation effectuée, vous recevrez une confirmation de réservation.
      </span>
    </div>
  </>
);

export default ExpandedRow;
