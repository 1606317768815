import { deletefilter } from 'dashboard/_redux/actions/FilterProducts';
import { useDispatch } from 'react-redux';
import { useState, useCallback } from 'react';
import { IFilter } from 'server/models/Filter/types';
import { DBDocument } from 'client/models/utils';
import { useTranslation } from 'react-i18next';
import { useProductsFilter } from 'dashboard/hooks/useProductsFilter';

const useGetProductFilter = () => {
  const [t] = useTranslation();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<(IFilter & DBDocument) | undefined>(
    undefined,
  );
  const { productsFilter } = useProductsFilter();
  const dispatch = useDispatch();

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(false);
    setFilterToDelete(undefined);
  }, []);

  const confirmationModal = useCallback((value: IFilter & DBDocument) => {
    setFilterToDelete(value);
    setConfirmationModalIsOpen(true);
  }, []);

  const handleDelete = useCallback(() => {
    if (filterToDelete !== undefined) dispatch(deletefilter(filterToDelete._id));
    setConfirmationModalIsOpen(false);
  }, [dispatch, filterToDelete]);

  return {
    productsFilter,
    handleDelete,
    closeConfirmationModal,
    confirmationModalIsOpen,
    confirmationModal,
    filterToDelete,
    t,
  };
};

export default useGetProductFilter;
