import FormSelect from 'client/components/Form/FormSelect';
import React from 'react';
import {
  ISearchCriteriaRouteQueryObject,
  ISearchCriteriaQuery,
} from 'types/ISearchCriteriaQuery';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import { ICountriesOptions } from 'client/pages/ProductList/useProductListPage';
import { formatSelectOptions } from 'client/helpers/format/formatSelectOptions';
import { useGeneralFilter } from './useGeneralFilter';

const arrify = (initial?: string | string[]) => {
  if (!initial) return [];

  return Array.isArray(initial) ? initial : [initial];
};

type IGeneralFilter = {
  searchCriteria?: ISearchCriteriaQuery;
  searchObjectFilter: ISearchCriteriaRouteQueryObject;
  setShowSideFilter?: (value: boolean) => void;
  showCloseButton?: boolean;
  countriesOptions: ICountriesOptions;
};

const GeneralFilter: React.FC<IGeneralFilter> = ({
  searchCriteria,
  searchObjectFilter,
  setShowSideFilter,
  showCloseButton = false,
  countriesOptions: { cityOptions, countryOptions },
}) => {
  const { formattedDuration, formattedPrices, t } = useGeneralFilter(searchCriteria);

  const sortedCountry = countryOptions.sort((a, b) => a.label.localeCompare(b.label));

  const sortedCity = cityOptions.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="max-w-100">
      <div className="card shadow-serious text-dark border mx-md-0 mb-3 m-3 ">
        <div className="card-header justify-content-between">
          <h3> {t('searchFilters.yourSearch').toUpperCase()}</h3>
          {showCloseButton && setShowSideFilter && (
            <i
              onClick={() => setShowSideFilter(false)}
              className="fas fa-times text-primary d-md-none d-block"
            />
          )}
        </div>

        <div className="card-body">
          <div className="mb-3 ">
            <label className="form-label ">{t('searchFilters.country')} :</label>
            <FormSelect
              id="country"
              options={[{ label: 'Tout', value: undefined }, ...countryOptions]}
              name="country"
              isSearchBar
              initialValue={searchObjectFilter?.country}
            />
          </div>

          <div className="mb-3">
            <label className="form-label ">{t('searchFilters.city')} :</label>
            <FormSelect
              id="city"
              options={[{ label: 'Tout', value: undefined }, ...cityOptions]}
              initialValue={searchObjectFilter?.city}
              name="city"
              isSearchBar
            />
          </div>

          <div className="mb-3">
            <label className="form-label ">{t('searchFilters.from')} :</label>
            <FormSelect
              id="from"
              options={[
                { label: 'Tout', value: undefined },
                ...formatSelectOptions(searchCriteria?.from, 'label'),
              ]}
              name="from"
              isSearchBar
              initialValue={searchObjectFilter?.from}
            />
          </div>

          <div className="mb-3">
            <label className="form-label ">{t('searchFilters.type')} :</label>
            <FormSelect
              multi
              isSearchBar
              name="product-type"
              options={
                searchCriteria?.['product-type'].map((o) => ({
                  label: o.label === 'tour' ? 'Circuits' : upperCaseFirstLetter(o.label),
                  value: o.value,
                })) || []
              }
              initialValue={arrify(searchObjectFilter?.['product-type'])}
            />
          </div>

          <div className="mb-3">
            <label className="form-label ">{t('searchFilters.duration')} :</label>
            <FormSelect
              multi
              isSearchBar
              name="duration"
              options={
                formattedDuration.map((el) => ({
                  label: `${el.label} nuitées`,
                  value: `${el.value}`,
                })) || []
              }
              initialValue={arrify(searchObjectFilter?.duration)}
            />
          </div>
          <div>
            <label className="form-label ">{t('searchFilters.pricePerPerson')} :</label>
            <FormSelect
              multi
              id="prices"
              name="prices"
              options={formattedPrices}
              initialValue={arrify(searchObjectFilter?.prices)}
            />
          </div>
        </div>

        <div className="card-footer">
          <button className="btn btn-primary mx-auto d-block w-100" type="submit">
            Rechercher
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneralFilter;
