import { IThunkAction } from 'types/IThunkAction';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import { HomeProductsTypes } from 'dashboard/_redux/types/homePageProductsTypes';
import * as ProductServices from 'dashboard/_redux/services/products';
import { IProductsFilterParams } from 'types/IProductsFilterParams';
import {
  ExhibitedProductDashboardTypes,
  ProductTypes,
} from 'dashboard/_redux/types/productTypes';
import { AxiosError } from 'axios';
import { getCustomProducts } from 'client/_redux/services/product';
export const addHomeProduct = (id: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: HomeProductsTypes.ADD_HOME_PRODUCTS_START });
  try {
    const homeProduct = await ProductServices.addHomeProduct()(id);

    dispatch({
      type: HomeProductsTypes.ADD_HOME_PRODUCTS_SUCCESS,
      payload: homeProduct.data,
    });
  } catch (error) {
    errorHandler(error as AxiosError);
    dispatch({ type: HomeProductsTypes.ADD_HOME_PRODUCTS_FAILED });
  }

  dispatch(stopLoading());
};
export const deleteHomeProducts = (id: string): IThunkAction => async (dispatch) => {
  try {
    await ProductServices.deleteHomeProduct()(id);

    dispatch({
      type: HomeProductsTypes.DELETE_HOME_PRODUCTS_SUCCESS,
      payload: id,
    });
  } catch (error) {
    errorHandler(error as AxiosError);
    dispatch({ type: HomeProductsTypes.DELETE_HOME_PRODUCTS_FAILED });
  }

  dispatch(stopLoading());
};
export const getDashboardProducts = (filter?: IProductsFilterParams): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  dispatch({ type: ProductTypes.GET_PRODUCTS_START });
  dispatch({ type: HomeProductsTypes.GET_HOME_PRODUCTS_START });
  try {
    const allProducts = await ProductServices.getAllProducts()(1, filter);
    const homeProducts = await ProductServices.getHomeProducts()();

    allProducts.data = {
      ...allProducts.data,
      items: allProducts?.data.items.filter(
        (item) => !homeProducts.data.find((el) => item._$id === el._$id),
      ),
    };

    dispatch({ type: ProductTypes.GET_PRODUCTS_SUCCESS, payload: allProducts.data });
    dispatch({
      type: HomeProductsTypes.GET_HOME_PRODUCTS_SUCCESS,
      payload: homeProducts.data,
    });
  } catch (error) {
    errorHandler(error as AxiosError);
    dispatch({ type: ProductTypes.GET_PRODUCTS_FAILED });
    dispatch({ type: HomeProductsTypes.GET_HOME_PRODUCTS_FAILED });
  }

  dispatch(stopLoading());
};

export const getDashboardExhibitedProducts = (filter?: string): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const res = await getCustomProducts()(filter || '');

    dispatch({
      type: ExhibitedProductDashboardTypes.GET_EXHIBITED_PRODUCTS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    errorHandler(error as AxiosError);
  }

  dispatch(stopLoading());
};
