import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import { searchBarFilter } from 'client/_redux/types/searchBarFilter';
import { IThunkAction } from 'types/IThunkAction';

export const selectedFilter = (title: string, isOpen: boolean): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const filterSelected = { title, isOpen };

    dispatch({
      type: searchBarFilter.FILTER_SELECTED,
      payload: filterSelected,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
