import { roomDetails, quote } from 'client/_redux/types/availability';
import {
  FormattedRoom,
  Price,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { IReduxAction } from 'types/IReduxAction';
import { AddonFormValues } from 'client/containers/QuotedAvailability/ProductAddons/useProductAddons';
import { SelectedTripTypes } from 'client/_redux/types/selectedTripTypes';
import { reservationFormDetails } from 'client/_redux/types/reservationForm';
import { IReservationForm } from 'types/IReservationForm';

export interface IQuotedAvailabilityState {
  selectedRooms: FormattedRoom[];
  selectedAddons: AddonFormValues[];
  tax?: Price;
  transportation?: Price;
  supplement?: Price;
  loading: boolean;
  reservationFormDetails?: IReservationForm;
}
const initialState: IQuotedAvailabilityState = {
  selectedRooms: [],
  loading: true,
  selectedAddons: [],
};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): IQuotedAvailabilityState => {
  switch (type) {
    case quote.SET_ADDONS_FAILED:
      return { ...state, loading: false };
    case roomDetails.SET_ROOMS:
      return {
        ...state,
        selectedRooms: payload,
      };
    case SelectedTripTypes.SET_TAX_AND_TRANSPORTATION_AND_SUPPLEMENT:
      return { ...state, ...payload };
    case SelectedTripTypes.SET_TRANSPORTATION:
      return { ...state, transportation: payload };
    case reservationFormDetails.SET_RESERVATION_FORM_DETAILS:
      return { ...state, reservationFormDetails: payload };

    case quote.SET_ADDONS:
      const { nbrPersons, id } = payload as AddonFormValues;

      const selectedAddonToUpdate = state.selectedAddons.findIndex((el) => el.id === id);

      if (selectedAddonToUpdate === -1 && nbrPersons === 0) {
        return { ...state };
      }
      if (selectedAddonToUpdate !== -1) {
        const prev = [...state.selectedAddons];

        if (nbrPersons === 0) prev.splice(selectedAddonToUpdate, 1);
        else prev[selectedAddonToUpdate].nbrPersons = nbrPersons;

        return { ...state, selectedAddons: prev };
      }

      return {
        ...state,
        selectedAddons: [...state.selectedAddons, payload],
      };

    default:
      return state;
  }
};
