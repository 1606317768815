import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useEffect } from 'react';
import { getfilterById, updatefilter } from 'dashboard/_redux/actions/FilterProducts';
import { IFilter } from 'server/models/Filter/types';
import { useAppSelector } from 'client/hooks/useAppSelector';

const useUpdateProductFilter = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getfilterById(id));
  }, [dispatch, id]);

  const { filterToUpdate } = useAppSelector(
    ({ productsFilter: { productsFilterToUpdate } }) => ({
      filterToUpdate: productsFilterToUpdate,
    }),
  );

  const history = useHistory();

  const onSubmit = (value: IFilter) => {
    dispatch(updatefilter(id, value, history));
  };

  return { id, filterToUpdate, onSubmit };
};

export default useUpdateProductFilter;
