import SEO from 'client/components/SEO';
import CheckingReservationForm from 'client/containers/CheckingReservationForm/CheckingReservationForm';
import React from 'react';
import useCheckingReservationDetails from './useCheckingReservationDetails';

const CheckingReservationDetails = () => {
  const { onSubmit } = useCheckingReservationDetails();

  return (
    <>
      <SEO
        title="Kepler Travel | formulaire de réservation"
        description="formulaire de réservation"
        // eslint-disable-next-line max-len
        image="https://images.unsplash.com/photo-1596431749951-1bbb4e396436?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
      />
      <CheckingReservationForm onSubmit={onSubmit} />
    </>
  );
};

export default CheckingReservationDetails;
