import { IThunkAction } from 'types/IThunkAction';
import * as ProductService from 'client/_redux/services/product';
import { errorHandler } from 'client/helpers/errorHandler';
import { RouteComponentProps } from 'react-router';
import { AxiosError } from 'axios';
import { ExhibitedProductDashboardTypes } from 'dashboard/_redux/types/productTypes';
import { startLoading, stopLoading } from '../loading';
export const getOneExhibitedProduct = (
  id: string,
  history: RouteComponentProps['history'],
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());

  try {
    const res = await ProductService.getOneExhibitedProduct()(id);

    dispatch({
      type: ExhibitedProductDashboardTypes.GET_ONE_EXHIBITED_PRODUCTS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    errorHandler(error as AxiosError);
    history.push('/404-not-found');
  }

  dispatch(stopLoading());
};
