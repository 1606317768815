import { useEsc } from 'client/hooks/useKeybord/useEsc';
import { useState } from 'react';

export const useImageGalleryContainer = () => {
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [showSlider, setShowSlider] = useState(false);

  const escFunction = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setShowSlider(false);
    }
  };

  useEsc(escFunction);
  const getIndexCallBack = (value: number) => {
    setCurrentIndex(value);
  };

  return { getIndexCallBack, currentIndex, setShowSlider, showSlider };
};
