import React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import { ISelectorOption } from 'types/ISelectorOption';
import SelectList from './SelectList';

interface Iprops<T extends unknown> {
  options: ISelectorOption<T>[];

  name: string;
  validate?: ValidationRules;

  handleClose?: () => void;
}

const FormSelectList = <T extends unknown = unknown>({
  options,
  name,
  validate,
  handleClose,
}: Iprops<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <SelectList
          handleClose={handleClose}
          handleSelect={onChange}
          options={options}
          value={value}
        />
      )}
    />
  );
};

export default FormSelectList;
