import React from 'react';
import { usePriceDetails } from './usePriceDetails';
import PriceDetailsTable from './PriceDetailsTable';

const PriceDetailsList: React.FC = () => {
  const { priceDetails, t } = usePriceDetails();

  return (
    <>
      <div className="card border rounded mt-2">
        <div className="card-header">
          <h2 className="text-center w-100">{t('priceDetails.title')}</h2>
        </div>
        <div className="text-center pt-1" />
      </div>
      <PriceDetailsTable priceDetails={priceDetails} />
    </>
  );
};

export default PriceDetailsList;
