import React from 'react';

type SectionTitleProps = {
  title: string;
};
const SectionTitle: React.FC<SectionTitleProps> = ({ title = 'Title' }) => (
  <div className="title-container mb-3">
    <h3 className="p-1 ml-4">{title}</h3>
  </div>
);

export default SectionTitle;
