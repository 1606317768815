import React from 'react';
import FilterSelection from '../FilterSelection';
import { useFilterSelectionContainer } from './useFilterSelectionContainer';

const FilterSelectionContainer: React.FC = () => {
  const { append, fields, productsFilter, remove, t, options } = useFilterSelectionContainer();

  return (
    <div>
      <div className="d-flex align-items-center">
        <h3>{t('FilterProducts.selectFilters')}</h3>

        {productsFilter && productsFilter.length > fields?.length && (
          <button
            className="btn btn-success ml-2"
            type="button"
            onClick={() => append([{ filters: [{ filterId: '' }] }])}
          >
            {t('FilterProducts.AddFilter')}
          </button>
        )}
      </div>
      <br />
      <div className="row mb-5">
        {fields?.map((field, index) => (
          <FilterSelection
            key={field.id}
            filterIndex={index}
            rawOptions={options}
            filterRemove={remove}
            productsFilter={productsFilter}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterSelectionContainer;
