import { AuthTypes } from 'dashboard/_redux/types/authTypes';
import { IToken } from 'types/Auth';
import { IReduxAction } from 'types/IReduxAction';

export interface IAuthState {
  isLoggedIn: boolean;
  loading: boolean;
  loaded: boolean;
  error: boolean;

  token?: IToken;
}

const initialState: IAuthState = {
  isLoggedIn: false,
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, { type, payload }: IReduxAction): IAuthState => {
  switch (type) {
    case AuthTypes.LOGOUT_ADMIN:
      return initialState;
    case AuthTypes.SIGNIN_ADMIN_SUCCESS:
      return { ...state, isLoggedIn: true, loaded: true, token: payload };
    case AuthTypes.SIGNIN_ANONYMOUS:
      return { ...state, isLoggedIn: false, loaded: true, token: payload };

    default:
      return state;
  }
};
