import React from 'react';
import {
  AlternativeRoom,
  BaseRoom,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  room: BaseRoom | AlternativeRoom;
  priceDetails: string;
  htmlFor: string;
  defaultChecked?: boolean;
}

const RoomSelectorInput: React.FC<IProps> = ({
  room,
  priceDetails,
  htmlFor,
  defaultChecked,
  ...props
}) => (
  <div className="text-start m-2 py-1 d-flex flex-sm-row flex-column w-100">
    <input
      defaultChecked={defaultChecked}
      type="radio"
      className="btn pl-1 align-self-center  rooms"
      {...props}
    />
    <label
      htmlFor={htmlFor}
      className="m-0 pl-1 col-sm-6 col-12 d-flex align-self-center justify-content-sm-start justify-content-center "
    >
      <span className="pl-2 ">{room.description} </span>
    </label>
    <span className="col-12 col-sm-3 offset-sm-3 text-success d-flex justify-content-center justify-content-sm-end">
      {priceDetails}
    </span>
  </div>
);

export default RoomSelectorInput;
