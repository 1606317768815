import AddArticle from 'dashboard/containers/AddArticle';
import React from 'react';
import { useAddArticlePage } from './useAddArticlePage';

const AddArticlePage = () => {
  const { loading } = useAddArticlePage();

  return loading ? (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  ) : (
    <AddArticle />
  );
};

export default AddArticlePage;
