import ProductCard from 'client/components/ProductCard';
import { getCompressedImageUrl } from 'client/helpers/getCompressedImageUrl';
import React from 'react';
import useAddHomeproducts from './useAddHomeProducts';

const AddHomeProducts = () => {
  const {
    allProducts,
    homeProducts,
    handleAddToHome,
    handleDeleteFromHome,
  } = useAddHomeproducts();

  return (
    <div className=" ">
      <div className="row">
        <div className="col-lg-6 ">
          <h2 className="text-center">Produits non affiché</h2>
          <div className="row align-items-center">
            {allProducts
              ?.filter((o) => o.permalink !== 'circuit-test')
              .map((product) => (
                <div
                  key={product._$id}
                  className="col-lg-4 "
                  onClick={handleAddToHome(product._$id)}
                >
                  <ProductCard
                    isDashboardCard
                    isPlus
                    image={product?.media}
                    title={product?.name}
                    price={product?.price}
                    category={product?.productType}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="col-lg-6 border border-left border-primary">
          <h2 className="text-center">Produits affiché</h2>
          <div className="row align-items-center">
            {homeProducts?.map((product) => (
              <div
                key={product._$id}
                className="col-lg-4"
                onClick={handleDeleteFromHome(product._$id)}
              >
                <ProductCard
                  isDashboardCard
                  image={getCompressedImageUrl(product?.media)}
                  title={product?.name}
                  price={product?.price}
                  category={product?.productType}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHomeProducts;
