/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import IconSelect from 'client/components/IconSelect';
import { PAYMENT_METHODS_LIST_OPTIONS } from 'client/constants/paymentIconsList/paymentListIcons';
import { IFlightClient, ISelectedFlights } from 'types/IFlightData';
import FlightClientCredential from 'client/components/FlightClientCredential';
import format from 'date-fns/format';
import FligthSummary from 'client/pages/FlightsForm/FligthSummary';
import FinancialRecap from 'client/pages/FlightsForm/FinancialRecap';
import { useFlightReservationConfirmation } from './useFlightReservationConfirmation';
interface IFlightReservationConfirmationProps {
  selectedFlights: ISelectedFlights;
  passengers: IFlightClient[];
}
const FlightReservationConfirmation: React.FC<IFlightReservationConfirmationProps> = ({
  selectedFlights,
  passengers,
}) => {
  const {
    t,
    handleCreatePayment,
    setPaymentMethod,
    totalPrice,
    kids,
    babies,
    pax,
    adults,
    formattedSelectedFlights,
  } = useFlightReservationConfirmation(selectedFlights, passengers);

  return (
    <div className="my-5 row">
      <div className="col-12 pl-0 col-md-9">
        <div className="card reservation-details-card-1 mb-5">
          <div className="card-header bg-white">
            <div className="media flex-sm-row flex-column-reverse justify-content-between ">
              <h4 className="mb-0">
                {t('reservationDetails.thanks')}
                <span className="reservation-details-change-color">
                  {' '}
                  {passengers[0].lastName} {passengers[0].firstName}{' '}
                </span>{' '}
                !
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-between mb-3">
              <div className="col-auto">
                <h3 className="color-1 mb-0 reservation-details-change-color">
                  {t('reservationDetails.quotation')}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="container pl-5 mb-5 ml-2">
                <div className="row pt-5 pb-3">
                  <p className="mb-1 text-dark">
                    <b>{t('reservationDetails.orderDetails')}</b>
                  </p>
                </div>
                {selectedFlights.goingFlight ? (
                  <>
                    <p className="row mb-1">
                      <span className="font-weight-bolder">Date de départ : </span>
                      {format(
                        new Date(selectedFlights.goingFlight.originalDateLocalTakeOff),
                        'PPP',
                      )}
                    </p>
                    <p className="row mb-1">
                      <span className="font-weight-bolder">Date de retour : </span>
                      {format(
                        new Date(selectedFlights.returningFlight.originalDateLocalTakeOff),
                        'PPP',
                      )}
                    </p>
                  </>
                ) : (
                  <p className="row mb-1">
                    <span className="font-weight-bolder">Date de départ : </span>
                    {format(
                      new Date(selectedFlights.singleFlight.originalDateLocalTakeOff),
                      'PPP',
                    )}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-5">
              <div className="px-lg-2 px-md-2 pt-2 pb-3">
                <p className="mb-1 text-dark">
                  <b>{`${t('clientCredentials.credentials')} :`}</b>
                </p>
              </div>
              {passengers.map((client, index) => (
                <>
                  <div key={client.email} className="px-lg-2 px-md-2 pt-2 pb-3">
                    <p className="mb-1 text-dark">
                      <b>{`${t('clientCredentials.passenger')} ${index + 1} :`}</b>
                    </p>
                  </div>
                  <FlightClientCredential clientCredentials={client} />
                </>
              ))}
            </div>
            {selectedFlights.singleFlight ? (
              <FligthSummary flights={{ oneWayFlights: selectedFlights.singleFlight }} />
            ) : (
              <FligthSummary
                flights={{
                  goingFlight: selectedFlights.goingFlight,
                  returningFlight: selectedFlights.returningFlight,
                }}
              />
            )}
          </div>
          <h3 className="color-1 mb-0 reservation-details-change-color ml-5 my-3">
            {t('reservationDetails.paymentMethod')}
          </h3>
          <div className="col-10 col-lg-4 ml-3">
            <IconSelect
              onChange={(value) => setPaymentMethod(value as string)}
              options={PAYMENT_METHODS_LIST_OPTIONS}
            />
          </div>
          <div className="reservation-details-card-footer">
            <div className="justify-content-between my-3 ">
              <div className="col-sm-auto col-auto my-auto" />
              <div className="col-auto my-auto text-center">
                <h2 className="font-weight-bold">{t('reservationDetails.totalPrice')}</h2>
              </div>
              <div className="col-auto my-auto ml-auto text-center">
                <h1 className="display-3 ">&euro; {totalPrice}</h1>
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary d-flex justify-content-center m-auto"
          type="button"
          onClick={handleCreatePayment}
        >
          {t('reservationDetails.confirm')}
        </button>
      </div>
      {formattedSelectedFlights && (
        <div className="col pr-0">
          <div style={{ top: '120px' }} className="my-3 border p-3 sticky-md-top">
            <FinancialRecap
              flights={formattedSelectedFlights}
              adults={adults}
              kids={+kids}
              babies={+babies}
              pax={+pax}
            />
            <button
              type="submit"
              form="reservation-form"
              style={{ width: '100%' }}
              onClick={handleCreatePayment}
              className="btn btn-primary"
            >
              {t('reservationDetails.confirm')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlightReservationConfirmation;
