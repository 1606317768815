import { useAppSelector } from 'client/hooks/useAppSelector';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getOne } from 'client/_redux/actions/product/getOne';

export const useProductSummary = () => {
  const media = useAppSelector(({ productReducer }) => productReducer.customCatalogue?.media);
  const history = useHistory();
  const { permalink } = useParams<{ permalink: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO : remove link to product replace by c
    dispatch(getOne(permalink, history));
  }, [dispatch, permalink, history]);
  const [t] = useTranslation();

  return { media, t };
};
