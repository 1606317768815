import { useAppSelector } from 'client/hooks/useAppSelector';
import { getfilter } from 'dashboard/_redux/actions/FilterProducts';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useProductsFilter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getfilter());
  }, [dispatch]);

  const { productsFilter } = useAppSelector((productsFilter) => productsFilter);

  return productsFilter;
};
