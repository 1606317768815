import CheckboxInput from 'client/components/Form/CheckboxInput/CheckboxInput';
import { DBDocument } from 'client/models/utils';
import React from 'react';
import { IFilter } from 'server/models/Filter/types';
import { useCustomFilter } from './useCustomFilter';

interface IProps {
  productFilers?: (IFilter & DBDocument)[];
  setShowSideProductFilters?: (value: boolean) => void;
  showCloseButton?: boolean;
}

const CustomFilter: React.FC<IProps> = ({
  productFilers,
  setShowSideProductFilters,
  showCloseButton = false,
}) => {
  const { t, formattedProductFilersOptions } = useCustomFilter(productFilers);

  return (
    <div className=" max-w-100">
      <div className="card shadow-serious text-dark border mx-md-0 mb-3 m-3">
        <div className="card-header justify-content-between">
          <h3> {t('searchFilters.refineSearch').toUpperCase()}</h3>
          {showCloseButton && setShowSideProductFilters && (
            <i
              onClick={() => setShowSideProductFilters(false)}
              className="fas fa-times text-primary d-md-none d-block"
            />
          )}
        </div>
        <div className="card-body ">
          {formattedProductFilersOptions?.map((productFiler) => (
            <div key={productFiler._id} className="pt-3 pb-3">
              <h5>{productFiler.title}</h5>

              <CheckboxInput
                options={productFiler.attributes}
                name={`custom.${productFiler.title}`}
              />
            </div>
          ))}
        </div>
        <div className="card-footer">
          <button className="btn btn-primary mx-auto d-block w-100" type="submit">
            {t('searchFilters.search')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomFilter;
