import { addFilter } from 'dashboard/_redux/actions/FilterProducts';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IFilter } from 'server/models/Filter/types';

const useAddProductFilter = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const formMethods = useForm<IFilter>({
    defaultValues: {
      attributes: [{ title: '', slug: '' }],
    },
  });

  const history = useHistory();

  const onSubmit = (value: IFilter) => {
    dispatch(addFilter(value, history));
  };

  return { onSubmit, formMethods, t };
};

export default useAddProductFilter;
