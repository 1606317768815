import * as React from 'react';
import SlickSlider, { Settings } from 'react-slick';

interface IProps extends Settings {
  autoPlay?: boolean;
}
interface SliderTypes {
  className?: string;
  style?: StyleSheet;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Slider: React.FC<IProps> = ({ autoPlay, children, ...props }) => (
  <SlickSlider {...settings} autoplay={autoPlay} autoplaySpeed={5000} {...props}>
    {children}
  </SlickSlider>
);

export default Slider;
const SampleNextArrow: React.FC<SliderTypes> = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      id="nextArrow"
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'none',
        right: '-15px',
        zIndex: '100',
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: React.FC<SliderTypes> = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      id="prevArrow"
      style={{
        ...style,
        display: 'block',
        background: 'none',
        left: '-15px',
        zIndex: '100',
      }}
      onClick={onClick}
    />
  );
};
const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1500,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1199,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 768,
      settings: { slidesToShow: 1 },
    },
  ],
};
