/* eslint-disable react-hooks/exhaustive-deps */
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ISelectorOption } from 'types/ISelectorOption';
import { format } from 'date-fns';
import fromatDurationString from 'client/helpers/fromatDurationString';
interface IProductPriceFilterValues {
  price?: string | number;
  ['start-date']: string;
  from: ISelectorOption | string;
  fromAvailability: ISelectorOption;
  numberOfNights?: ISelectorOption | string;
}
export const useSelectListModalConatiner = (froms: ISelectorOption[]) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [token, setToken] = useState('');

  const { cataloguePricesAvailability } = useAppSelector(
    ({ productReducer }) => productReducer,
  );

  const { permalink } = useParams<{ permalink: string }>();

  const urlParams: { from: string; 'number-of-nights': string } = useMemo(
    () =>
      queryStringToObject<{ from: string; 'number-of-nights': string }>(
        decodeURI(location.search),
      ),
    [location.search],
  );

  const { cataloguePrices, product } = useAppSelector(({ productReducer }) => productReducer);

  // console.log('%csrcclientcontainers cataloguesPrices', 'color: #007acc;', cataloguePrices);
  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner',
  //   'color: #007acc;',
  //   cataloguePricesAvailability,
  // );
  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner product',
  //   'color: #007acc;',
  //   product?.bestPrice.startsAt,
  // );
  const productCode = product?._$id.split('-').pop() as string;

  const pricesList = cataloguePrices && cataloguePrices.prices;

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner.ts:56 pricesList',
  //   'color: #007acc;',
  //   pricesList,
  // );

  let bestPriceValue = 0;
  let valeurDiscount = 0;
  const valeurTax = product?.bestPrice.taxes.Tax[0]._$Value;
  const chaineTax = valeurTax.toString(); // Convertir en chaîne de caractères
  const resultatTax = `${chaineTax.slice(0, -2)}.${chaineTax.slice(-2)}`;

  Array.isArray(product?.bestPrice.discounts.Discount)
    ? (valeurDiscount = product?.bestPrice.discounts.Discount[1]._$Value)
    : 0;
  const chaineDiscount = valeurDiscount.toString();
  const resultatTDiscount = `${chaineDiscount.slice(0, -2)}.${chaineDiscount.slice(-2)}`;

  product?.bestPrice.discounts.Discount.length !== undefined
    ? (bestPriceValue =
        product?.bestPrice.value - Number(resultatTax) + Number(resultatTDiscount))
    : (bestPriceValue = product?.bestPrice.value - Number(resultatTax));

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner.ts:79 bestPriceValue',
  //   'color: #007acc;',
  //   bestPriceValue,
  // );
  const minPrice = Number(bestPriceValue.toFixed(2)) + Number(resultatTax);

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner.ts:80 minPrice.toSting(',
  //   'color: #007acc;',
  //   minPrice.toString(),
  // );
  const minimumPrice =
    cataloguePrices &&
    cataloguePrices.prices
      .reduce(
        (prev, curr) => (Number(curr.value) < prev ? Number(curr.value) : prev),
        Infinity,
      )
      .toString();

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner.ts:87 minimumPrice',
  //   'color: #007acc;',
  //   minimumPrice,
  // );
  const initialValues = useMemo(() => {
    const from =
      (urlParams.from &&
        cataloguePricesAvailability?.find((el) => el.from?.value === urlParams.from)?.from) ||
      cataloguePricesAvailability?.find((el) => el.from?.value === cataloguePrices?.from.value)
        ?.from ||
      cataloguePricesAvailability?.[0].from;

    const numberOfNights =
      (urlParams['number-of-nights'] &&
        cataloguePricesAvailability
          ?.find((el) => el.from?.value === from?.value)
          ?.nights.find((e) => e.value === urlParams['number-of-nights'])) ||
      cataloguePricesAvailability?.find((el) => el.from?.value === cataloguePrices?.from.value)
        ?.nights?.[0] ||
      cataloguePricesAvailability?.find((el) => el.from?.value === from?.value)?.nights?.[0];

    const startDate =
      pricesList?.length !== 0 &&
      format(
        new Date(
          pricesList?.filter((e) => e.value === minPrice.toString() || minimumPrice)?.[0]
            .date || 0,
        ),
        'yyyy-MM-dd',
      );

    const price =
      pricesList && pricesList.filter((e) => e.value === minPrice.toString())?.[0]?.value;

    // console.log(
    //   '%csrcclientcontainersSelectListModalConatiner.ts:132 price',
    //   'color: #007acc;',
    //   price,
    // );

    return { from, numberOfNights, startDate, price };
  }, [urlParams, cataloguePricesAvailability, pricesList, minimumPrice, minPrice]);

  const formMethods = useForm<IProductPriceFilterValues>({
    defaultValues: {
      from: initialValues.from && initialValues.from.label,
      numberOfNights: initialValues.numberOfNights && initialValues.numberOfNights?.label,
      'start-date': product?.bestPrice.startsAt
        ? product?.bestPrice.startsAt
        : initialValues.startDate,
    },
  });

  const { watch, handleSubmit } = formMethods;

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner.ts:153 watch',
  //   'color: #007acc;',
  //   initialValues.startDate,
  // );

  const fromAvailability = cataloguePricesAvailability?.map((e) => e.from);

  const nightAvailability = cataloguePricesAvailability?.find(
    (e) => e.from.label === watch('from'),
  );

  useEffect(() => {
    async function fetchToken() {
      const res = await fetch(`${process.env.RAZZLE_HOST}/api/v1/product-info/token-soap`);
      const data = await res.json();

      setToken(data);
    }
    fetchToken();
  }, []);

  // console.log(
  //   '%csrcclientcontainersSelectListModalConatiner\ts:103 token',
  //   'color: #007acc;',
  //   token,
  // );

  // -------------------
  const urlFrom = fromAvailability?.find((e) => e?.label === watch('from'))?.value;
  const urlNumberOfNights =
    nightAvailability?.nights?.find(
      (e) =>
        fromatDurationString(e?.label)
          .replace(/Night/g, t('productPageInfos.Nuits'))
          .replace(/Day/g, t('productPageInfos.Jours')) === watch('numberOfNights'),
    )?.value || initialValues.numberOfNights?.value;
  const urlStartDate = watch('start-date');
  // -------------------

  const _onSubmit = () => {
    // eslint-disable-next-line max-len
    const url = `https://kepler.mypgi.fr/extranet/sso?token=${token}&produit_id=${productCode}&begin=${urlStartDate}&from=${urlFrom}`;
    // const url = `/product/${permalink}/quotation?start-date=${urlStartDate}&from=${urlFrom}&number-of-nights=${urlNumberOfNights}&adult-count=${watch(
    //   'adultCount',
    // )}&child-count=${watch('childCount')}`;

    window.location.replace(url);
  };

  useEffect(() => {
    const url = `/product/${permalink}?from=${urlFrom}&number-of-nights=${
      nightAvailability?.nights?.find(
        (e) =>
          fromatDurationString(e?.label)
            .replace(/Night/g, t('productPageInfos.Nuits'))
            .replace(/Day/g, t('productPageInfos.Jours')) === watch('numberOfNights'),
      )?.value
    }`;

    history.replace(url);
  }, [permalink, urlNumberOfNights, urlFrom]);
  const [watchPrice, setWatchPrice] = useState(initialValues.price);

  const nightAvailabilityNewLabel = {
    ...nightAvailability,
    nights: nightAvailability?.nights.map((el) => ({
      ...el,
      label: fromatDurationString(el?.label)
        .replace(/Night/g, t('productPageInfos.Nuits'))
        .replace(/Day/g, t('productPageInfos.Jours')),
    })),
  };

  return {
    formMethods,
    initialValues,
    fromAvailability,
    nightAvailability: nightAvailabilityNewLabel,
    minimumPrice,
    pricesList,
    permalink,
    _onSubmit,
    handleSubmit,
    t,
    watchPrice,
    setWatchPrice,
    minPrice,
  };
};
