import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import useAdminSignup from './useAdminSignup';

export interface ICreateAdminFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface IProps {
  onSubmit: SubmitHandler<ICreateAdminFormValues>;
}

const CreateAdminForm: React.FC<IProps> = ({ onSubmit }) => {
  const { t, formMethods, handleSubmit, password, _onSubmit } = useAdminSignup(onSubmit);

  return (
    <FormProvider {...formMethods}>
      <form className=" container mt-5" onSubmit={handleSubmit(_onSubmit)}>
        <div className="mb-7">
          <h1 className="h3 text-dark font-weight-normal mb-0">
            Veuillez entrer les informations Relatives aux nouvel Administrateur
          </h1>
          <hr />
        </div>
        <Input
          type="lastName"
          name="lastName"
          label={t('form.signUpLastnameInput')}
          placeholder={t('form.signUpLastnameInput')}
          validate={Validators([{ validation: 'required' }, { validation: 'string' }])}
        />
        <Input
          type="firstName"
          name="firstName"
          label={t('form.signUpFirestnameInput')}
          placeholder={t('form.signUpFirestnameInput')}
          validate={Validators([{ validation: 'required' }, { validation: 'string' }])}
        />
        <Input
          type="email"
          name="email"
          label={t('form.signUpEmailInput')}
          placeholder={t('form.signUpEmailInput')}
          validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
        />
        <Input
          type="password"
          name="password"
          label={t('form.password')}
          placeholder={t('form.password')}
          validate={Validators([
            { validation: 'required' },
            { minParams: 8, validation: 'minLength' },
          ])}
        />
        <Input
          type="password"
          name="passwordConfirmation"
          label={t('form.confirmPassword')}
          placeholder={t('form.confirmPassword')}
          validate={Validators([
            { validation: 'required' },
            { minParams: 8, validation: 'passwordConfirm', passwordConfirm: password },
          ])}
        />

        <div className="row align-items-center mb-5">
          <div className="col text-center">
            <button data-testid="submitButton" className="btn btn-dark mb-1" type="submit">
              Ajouter Administrateur
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateAdminForm;
