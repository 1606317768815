import { getCountries } from 'client/_redux/actions/countries';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getHomeProducts } from 'client/_redux/actions/productsList/getProducts';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { getPromotion } from 'client/_redux/actions/promotion';

export const useHome = () => {
  const dispatch = useDispatch();
  const { countries } = useAppSelector((state) => state.countriesReducer);
  const { homeProducts } = useAppSelector((state) => state.productListReducer);
  const promotion = useAppSelector((state) => state.promotionReducer);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getHomeProducts());
    dispatch(getPromotion());
  }, [dispatch]);

  return { countries, homeProducts, promotion };
};
