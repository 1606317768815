import FlightsFilter from 'client/components/FlightsFilter';
import FlightsTopFilter from 'client/components/FlightsTopFilter';
import SEO from 'client/components/SEO';
import HeaderStyle from 'client/containers/ReservationFormContainer/HeaderStyle';
import { format } from 'date-fns';
import React from 'react';
import { isOneWayFlight, isRoundedFlight } from 'types/typesGuard';
import FlightList from './FlightList';
import { useFlights } from './useFlights';

const FLights = () => {
  const {
    handleSubmitFlights,
    handleFlightSelection,
    flights,
    isLoading,
    seats,
    departure,
    destination,
    departureDate,
    returningDate,
    isRoundTrip,
  } = useFlights();

  return (
    <>
      <SEO title="Réservation des vols" description="Réservation des vols" />
      <div className="container container-xxl mt-5 mt-lg-0">
        <HeaderStyle targetNumber={1} />
      </div>
      <FlightsTopFilter />
      <div className="container container-xxl pt-3">
        <div className=" row justify-content-center">
          <div className=" col-lg-3 col-md-8 col-11 pl-0 ">
            <FlightsFilter showCloseButton />
          </div>
          {isLoading ? (
            <div className="col-lg-9 col-md-8 col-11">
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : null}
          {!isRoundedFlight(flights) && !isOneWayFlight(flights) ? (
            <span className="mt-5">
              Aucun produit ne correspand à votre recherche! Veuillez modifier ou réduir le
              nombre de filtre que vous avez utilisé
            </span>
          ) : (
            <div className="col-lg-9 col-md-8 col-11">
              <div>
                <p>
                  Et voilà ! Vos résultats de recherche pour {seats} voyageurs partant de{' '}
                  {departure} vers {destination} le {format(new Date(departureDate), 'PPPP')}{' '}
                  {!isRoundTrip
                    ? ''
                    : `et retournant le ${format(new Date(returningDate), 'PPPP')}`}
                  .
                </p>
              </div>
              <FlightList
                flights={flights}
                onFlightClick={(resrvations) => {
                  resrvations.forEach((re) => {
                    handleFlightSelection({
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      _id: re.flight._id!,
                      typeOfFlight: re.typeOfFlight,
                    });
                  });
                  handleSubmitFlights();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FLights;
