import { useProductsFilter } from 'dashboard/hooks/useProductsFilter';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const useFilterSelectionContainer = () => {
  const [t] = useTranslation();
  const { productsFilter } = useProductsFilter();
  const { control } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'filters',
  });

  const options = useMemo(
    () =>
      productsFilter
        ? productsFilter?.map((filters) => ({
            label: filters.title,
            value: filters._id,
          }))
        : [
            {
              label: '',
              value: '',
            },
          ],
    [productsFilter],
  );

  return { t, productsFilter, append, remove, fields, options };
};
