import { useAppSelector } from 'client/hooks/useAppSelector';
export const useLocationBar = () => {
  const { product, loading } = useAppSelector(({ productReducer }) => productReducer);
  const city = product?.location.city?.title;
  const country = product?.location.country?.title;

  return {
    city,
    country,
    loading,
  };
};
