import Modal from 'dashboard/components/Modal';
import AddProductForm from 'dashboard/containers/AddProductForm';
import { getImageUrl } from 'dashboard/helpers/getImageUrl';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useCountryCard } from './useCountryCard';
export interface ICardProps {
  deleteCountry: (id?: string) => void;
  id?: string;
  title: string;
  subtitle: string;
  image: string;
}

const CountryCard: React.FC<ICardProps> = ({ title, image, subtitle, deleteCountry, id }) => {
  const {
    t,
    handleDelete,
    openDeleteModal,
    setOpenDeleteModal,
    openEditModal,
    setOpenEditModal,
  } = useCountryCard(deleteCountry);

  return (
    <>
      <div data-testid="homePageCard" className="px-2 pt-3 col-lg-4 col-md-4 col-sm-12">
        <div className="card card-with-image zoom-wrapper overflow-hidden transition-3d-hover card-hover-shadow">
          <div
            className=" zoom-image card-image col-sm-12"
            style={{
              backgroundImage: `${`url(${getImageUrl(image)})`}`,
            }}
          />

          <div className="position-absolute top-0 right-0">
            <button
              onClick={() => setOpenDeleteModal(true)}
              type="button"
              className="btn btn-xs btn-danger"
            >
              X
            </button>
          </div>
          <div className="position-absolute top-0 left-0">
            <button
              onClick={() => setOpenEditModal(true)}
              type="button"
              className="btn btn-xs btn-success"
            >
              <i className="fas fa-pen" />
            </button>
          </div>

          <div className="card-body" style={{ maxHeight: '140px', padding: '15px' }}>
            <div className="d-flex align-items-baseline mr-1">
              <p data-testid="homePageCard-icon" className={` mb-0`} />
              <p className="ml-1 mb-0" />
            </div>
            <h3 className="font-weight-bold">{title}</h3>

            <div className="justify-content-start ">
              <p className="mb-0 mr-1 font-weight-lighter">{subtitle}</p>
            </div>

            <div className="d-flex align-items-center justify-content-end small font-weight-bold text-font-primary">
              <Link to={`/products/?country=${title}`}>{t('productCard.PlusDeDetails')}</Link>
              <i className="fas fa-angle-right fa-sm ml-1" />
            </div>
          </div>
        </div>
      </div>
      <Modal
        closeModal={() => setOpenDeleteModal(false)}
        modalHead={`Delete ${subtitle} ?`}
        isOpen={openDeleteModal}
      >
        <div className="text-center">
          <button type="button" className="btn btn-danger" onClick={() => handleDelete(id)}>
            Confirm{' '}
          </button>
          <button
            type="button"
            className="btn btn-success ml-5"
            onClick={() => setOpenDeleteModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal
        closeModal={() => setOpenEditModal(false)}
        isOpen={openEditModal}
        modalHead={t('dashboard.addDestination.addNewDestination')}
      >
        <AddProductForm
          openModal={setOpenEditModal}
          editCountry={{ _id: id, title, subtitle, countryImage: image }}
        />
      </Modal>
    </>
  );
};

export default CountryCard;
