import Breadcrumbs from 'client/components/Breadcrumbs';
import React from 'react';
import { useProductBreadcrumbs } from './useProductBreadcrumbs';

const ProductBreadcrumbs = () => {
  const { list, loading } = useProductBreadcrumbs();

  return <Breadcrumbs isLoading={loading} breadcrumbs={list} />;
};

export default ProductBreadcrumbs;
