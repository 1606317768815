import Validators from 'client/helpers/formValidator';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Input, { IInputProps } from '../Input';

interface IProps extends IInputProps {
  name: string;
  min: number;
  max: number;
  defaultValue?: number;
  isFlightsPages?: boolean;
  handleCountChange?: (value: number) => void;
}

const InputPlusMinusButtons: React.FC<IProps> = ({
  min,
  max,
  name,
  defaultValue,
  isFlightsPages,
  handleCountChange,
  ...props
}) => {
  const [count, setCount] = useState(Math.min(Math.max(defaultValue ?? min, min), max));
  const formContext = useFormContext();

  useEffect(() => {
    formContext.setValue(name, count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeCount = (value: number) => {
    if (count + value >= min && count + value <= max) {
      setCount((prev) => prev + value);
      formContext.setValue(name, count + value);
      if (handleCountChange) {
        handleCountChange(count + value);
      }
    }
  };

  return (
    <div className=" row align-items-start no-gutters mt-2">
      <button
        className={` btn ${
          isFlightsPages ? 'rounded' : 'rounded-0'
        }  btn-primary border-0 col-4 `}
        type="button"
        onClick={() => changeCount(-1)}
      >
        <i className="fas fa-minus" />
      </button>
      <div className="col-4 p-0">
        <Input
          className="rounded-0 text-center d-flex  p-0"
          type="text"
          name={name}
          validate={Validators([{ validation: 'required' }, { validation: 'integer' }])}
          style={{ cursor: 'pointer' }}
          readOnly
          {...props}
        />
      </div>

      <button
        className={` btn ${
          isFlightsPages ? 'rounded' : 'rounded-0'
        }  btn-primary border-0 col-4 `}
        type="button"
        onClick={() => changeCount(+1)}
      >
        <i className="fas fa-plus" />
      </button>
    </div>
  );
};

export default InputPlusMinusButtons;
