import { useAppSelector } from 'client/hooks/useAppSelector';
import { setAddons } from 'client/_redux/actions/quotedAvailability';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export type AddonFormValues = {
  id: string;
  nbrPersons: number;
  price: number;
  description: string | undefined;
};
export const useProductAddons = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const selectedAddons = useAppSelector(
    ({ selectedTripReducer }) => selectedTripReducer.selectedAddons,
  );
  const onChange = useCallback(
    (values: AddonFormValues) => {
      dispatch(setAddons(values));
    },
    [dispatch],
  );

  const addonsTotalPrice = useMemo(() => {
    let total = 0;

    if (selectedAddons.length > 0)
      selectedAddons.forEach((a) => (total += a.nbrPersons * a.price));

    return total;
  }, [selectedAddons]);

  return { onChange, addonsTotalPrice, selectedAddons, t };
};
