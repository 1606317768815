import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateArticle } from 'dashboard/_redux/actions/blogArticles';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { IArticle } from 'server/models/Article/types';

export const useUpdateArticle = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const loading = useAppSelector((state) => state.loading.loading);

  const articles = useAppSelector(({ dashboradBlog }) => ({
    articles: dashboradBlog.articles,
  }));

  const selectedArticle = articles?.articles.items.filter((el) => el._id === id)[0];

  const formMethods = useForm<IArticle>({
    shouldFocusError: true,
    defaultValues: selectedArticle,
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (data: IArticle) => {
    dispatch(updateArticle(data, id));
  };

  return {
    handleSubmit,
    formMethods,
    onSubmit,
    t,
    loading,
  };
};
