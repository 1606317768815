import { formatDate } from 'client/helpers/format/formatDate';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import React from 'react';
import { ICatalogue } from 'server/models/Catalogue/types';
import { ICustomCatalogue } from 'types/ICatalogue';
import { dataIcon, mainDescriptio } from '../ProductListCardContainer';
import { useProductDescriptionCardContainer } from './useProductDescriptionCardContainer';

interface IProps {
  product: ICustomCatalogue;
  productDetails?: ICatalogue;
  loading?: boolean;
  className?: string;
  handleClick?: () => void;
  selectedPrice?: {
    price: number;
    departureDate: string;
    numberOfNights: string;
    departure?: string;
  };
}

const ProductDescriptionCardContainer: React.FC<IProps> = ({
  product: { bestPrice, mainDescription = mainDescriptio, icons = dataIcon, shortDescription },
  className,
  handleClick,
}) => {
  const { t, initialValues } = useProductDescriptionCardContainer();

  let price = 0;
  let valeurDiscount = 0;
  const valeurTax = bestPrice.taxes.Tax[0]._$Value;
  const chaineTax = valeurTax.toString(); // Convertir en chaîne de caractères
  const resultatTax = `${chaineTax.slice(0, -2)}.${chaineTax.slice(-2)}`;

  Array.isArray(bestPrice.discounts.Discount)
    ? (valeurDiscount = bestPrice.discounts.Discount[1]._$Value)
    : 0;
  const chaineDiscount = valeurDiscount.toString();
  const resultatTDiscount = `${chaineDiscount.slice(0, -2)}.${chaineDiscount.slice(-2)}`;

  bestPrice.discounts.Discount.length !== undefined
    ? (price = bestPrice.value - Number(resultatTax) + Number(resultatTDiscount))
    : (price = bestPrice.value - Number(resultatTax));

  return (
    <div
      className={`row px-md-3 border-top border-2 no-gutters type-product-type mb-2 ${className}`}
    >
      <div className="mt-1 w-100 mt-md-3 px-1 ">
        {bestPrice && (
          <div className="d-flex flex-row mb-4 justify-content-start mt-1 border-bottom first-section">
            <div className="div_width flex-column">
              <div className="mt-2" style={{ fontSize: '13px', marginTop: '10px' }}>
                Prochain départ à ce tarif :
              </div>
              {initialValues.from && (
                <div className="h4 m-0">
                  {initialValues.from.label === 'paris cdg'
                    ? 'Paris'
                    : upperCaseFirstLetter(initialValues.from.label)}
                </div>
              )}
              <div className="h5 m-0">pour {initialValues.numberOfNights} nuitées,</div>
              <div className="h6 text-primary m-0">
                {' '}
                le {bestPrice.startsAt && formatDate(bestPrice.startsAt)}
              </div>
              <h6 className="mr-2 mt-auto mb-auto">{t('productCard.APartirDe')}</h6>
              <div className="d-flex flex-row">
                <div data-testid="price" className="h2 mt-auto mb-auto ">
                  <span className="text-secondary text-size">
                    {Math.round(Number(price + Number(resultatTax)) || 0)}
                    <span className="font-weight-light">€</span>
                  </span>
                </div>

                <div
                  className="mt-2 mb-auto text-secondary ml-1 ttc_font_size"
                  style={{
                    fontSize: '12px',
                    lineHeight: '10px',
                    position: 'relative',
                  }}
                >
                  TTC/<span className="d-block text-secondary font-weight-bold">pers</span>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center align-self-lg-start align-self-center">
              <div
                onClick={handleClick}
                className="btn bg-primary text-white p-1 font-smaller font-weight-light "
              >
                voir les disponibilités <i className="fas fa-chevron-down" />
              </div>
            </div>
          </div>
        )}

        <div className="h3">{upperCaseFirstLetter(shortDescription || '')}</div>
        <div>
          <ul className="mt-2">
            {mainDescription?.map((e, i) => (
              <li key={`${e.text}${i + 1} `}>{e.text}</li>
            ))}
          </ul>
        </div>
        <div className="row  flex-nowrap overflow-hidden justify-content-center no-gutters ">
          {(icons[0] === undefined && icons.length === 0 ? dataIcon : icons).map((e, i) => (
            <div
              key={`${e.title}`}
              className={`${
                i !== icons.length - 1 && 'border-right border-3'
              } px-md-3 py-md-2 px-2 py-1 text-center text-success`}
            >
              <img height="30px" width="auto" src={e.icon} alt="grat" />
              <div className="d-block font-smaller text-primary">{e.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionCardContainer;
