import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormattedRoom,
  GroupedRooms,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { sectionRoomsOptionsQuotedAvailibility } from 'client/helpers/sectionRoomsOptionsQuotedAvailibity';

interface IProps {
  alternatives: GroupedRooms[] | undefined;
  recommended: GroupedRooms | undefined;
  onSubmit: (selectedRoom: FormattedRoom[]) => void;
}

export const useRoomDetails = ({ alternatives, recommended, onSubmit }: IProps) => {
  const [t] = useTranslation();
  const [selectedRooms, setSelectedRooms] = useState<FormattedRoom[]>([]);
  const [selectedPlaceSection, setSelectedPlaceSection] = useState<GroupedRooms | undefined>(
    recommended,
  );

  useEffect(() => {
    setSelectedPlaceSection(recommended);
  }, [recommended]);

  useEffect(() => {
    if (!selectedPlaceSection) return;
    const _selectedRooms = selectedPlaceSection?.rooms.map(
      ({ alternativeRooms, ...el }) => el,
    );

    setSelectedRooms(_selectedRooms);
  }, [onSubmit, selectedPlaceSection]);

  useEffect(() => {
    onSubmit(selectedRooms);
  }, [selectedRooms, onSubmit]);

  const sectionPlaceOptions = sectionRoomsOptionsQuotedAvailibility({
    recommended,
    alternatives,
    t,
  });

  const handleRoomSelected = (room: FormattedRoom, index: number) => () => {
    setSelectedRooms((prev) => {
      const _prev = [...prev];

      _prev[index] = room;

      return _prev;
    });
  };

  return {
    sectionPlaceOptions,
    selectedRooms,
    selectedPlaceSection,
    handleRoomSelected,
    setSelectedPlaceSection,
    t,
  };
};
