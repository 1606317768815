import { IThunkAction } from 'types/IThunkAction';
import * as TransactionService from 'client/_redux/services/transaction';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import { TransactionTypes } from 'client/_redux/types/transactionsTypes';

export const getTransaction = (transactionId: string): IThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  try {
    const { transactionsReducer } = getState();

    if (
      transactionsReducer.isPreloaded &&
      transactionId === transactionsReducer.transaction._id
    )
      return;

    const res = await TransactionService.getTransaction()(transactionId);

    dispatch({
      type: TransactionTypes.GET_TRANSACTIONS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
