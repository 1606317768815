import React from 'react';
import { FormProvider } from 'react-hook-form';
import TextEditor from 'dashboard/components/Forms/TextEditor';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import FormImagePicker from 'dashboard/components/Forms/FormImagePicker';
import { useAddArticle } from './useAddArticle';

const AddArticle: React.FC = () => {
  const { handleSubmit, formMethods, onSubmit, t } = useAddArticle();

  return (
    <div
      className="border p-2"
      style={{
        borderRadius: '8px',
        background: 'white',
        boxShadow: '0px 0.375rem 1.5rem 0px rgb(140 152 164 / 13%) !important',
      }}
    >
      <FormProvider {...formMethods}>
        <form
          className="mt-5"
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingBottom: '17rem' }}
        >
          <div className="d-flex justify-content-between">
            <h1 style={{ textAlign: 'center' }}>title</h1>
            <button className="btn btn-primary" type="submit">
              <i className="fa fa-save mr-2" />
              {t('productDetails.save')}
            </button>
          </div>

          <Input
            name="metaTitle"
            label="Meta-title"
            validate={Validators([{ validation: 'required' }])}
          />
          <Input
            name="metaDescription"
            type="textarea"
            maxLength={160}
            label="Meta-description"
            validate={Validators([{ validation: 'required' }])}
          />
          <FormImagePicker
            name="metaImage"
            label="Meta-Image"
            maxFiles={1}
            validate={Validators([{ validation: 'required' }])}
          />

          <TextEditor name="article" label={t('productDetails.priceIncludes')} />
        </form>
      </FormProvider>
    </div>
  );
};

export default AddArticle;
