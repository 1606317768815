import { ISelectorOption } from 'types/ISelectorOption';

interface IProps {
  watchSelectedOptions: (string | undefined)[];
  familyOptions: ISelectorOption<string>[];
}

const getRemainingAddonsFamilyOptions = ({ watchSelectedOptions, familyOptions }: IProps) => {
  const getSelectedTravellers = (watchSelectedOptions: (string | undefined)[]) =>
    watchSelectedOptions?.reduce<(string | undefined)[]>((prev, curr, currentIndex) => {
      if (curr) prev.push(curr);

      return prev;
    }, []);

  const remainingOptions = () => {
    const selectedMember = getSelectedTravellers(watchSelectedOptions);

    const remainingFamily = familyOptions.filter((el) => !selectedMember?.includes(el.value));

    return { remainingFamily };
  };
  const { remainingFamily } = remainingOptions();

  return { remainingFamily };
};

export default getRemainingAddonsFamilyOptions;
