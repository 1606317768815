import { clientApi } from 'api';
import { IBookedFlightDocument } from 'server/models/BookedFlights/types';
import { IFlightDocument } from 'server/models/Flights/types';
import { TFlightResponse } from 'server/services/FlightsService';

export type ICalendarFLight = {
  price: number;
  dateLocalTakeoff: Date;
};
export interface IFlightWithData {
  _id: {
    year: number;
    month: number;
  };
  data: ICalendarFLight[];
}
export interface IRoundedFlightWithData {
  flights: IFlightWithData[];
  selectedInitialDatePrice: number;
}
export interface IRoundFlightDetails {
  goingFlights: TFlightResponse[];
  returningFlights: TFlightResponse[];
  twoWayFlights: {
    goingFlight: TFlightResponse;
    returningFlight: TFlightResponse;
    totalPrice: number;
  }[];
}
export const getCalendarFlights = (isServer = false) => async (
  year: number,
  month: number,
  departure: string,
  destination: string,
  isRoundTrip?: boolean,
  selectedDate?: Date,
  pax?: number,
) => {
  const response = await clientApi.get<IRoundedFlightWithData[]>('/flights/calendar', {
    params: { month, year, departure, destination, isRoundTrip, selectedDate, pax },
  });

  return response;
};
export const getInitialRoundedCalendarFlights = (isServer = false) => async (
  year: number,
  month: number,
  departure: string,
  destination: string,
  pax?: number,
) => {
  const response = await clientApi.get<IFlightWithData[]>('/flights/initial-calendar', {
    params: { month, year, departure, destination, pax },
  });

  return response;
};
export const getFlightsDetails = (isServer = false) => async (data: {
  departureDate: string;
  departure: string;
  destination: string;
  luggage: string;
  viaAirports: string;
  isRoundTrip?: boolean;
  returningDate?: string;
  seats?: string;
}) => {
  const {
    departureDate,
    departure,
    destination,
    isRoundTrip,
    returningDate,
    luggage,
    viaAirports,
    seats,
  } = data;

  const response = await clientApi.get<IRoundFlightDetails | IFlightDocument[]>('/flights', {
    params: {
      departureDate,
      departure,
      destination,
      isRoundTrip,
      returningDate,
      luggage,
      viaAirports,
      seats,
    },
  });

  return response.data;
};
export const getBookedFLights = async (flightId: string) =>
  (await clientApi.get<IBookedFlightDocument>(`/flights/flights-details/${flightId}`)).data;
