import React from 'react';

const RoomSelector = ({
  description,
  isInclus,
  includedInfo,
  info,
  activeBtn,
  index,
}: {
  description: string;
  isInclus?: boolean;
  includedInfo?: string;
  info?: string;
  activeBtn: number;
  index: number;
}) => (
  <>
    <span className={`btn text-align-adjustement ${activeBtn === index ? 'text-white' : ''}`}>
      {description}
    </span>
    <span
      className={`col-12 col-sm-4 offset-sm-2 ${
        activeBtn === index ? 'text-white' : 'text-success'
      } d-flex justify-content-center justify-content-sm-end align-self-center`}
    >
      {isInclus ? includedInfo : info}
    </span>
  </>
);

export default RoomSelector;
