import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import { DBDocument } from 'client/models/utils';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IFilter } from 'server/models/Filter/types';
import useUpdateAddFilterProductsForm from './useAddAndUpdateFilterProductsForm';
interface IProps {
  updateDefaultValue?: (IFilter & DBDocument) | undefined;
  id?: string;
  onSubmit: (value: IFilter) => void;
}

const AddAndUpdateFilterProductsForm = ({ onSubmit, updateDefaultValue, id }: IProps) => {
  const {
    handleSubmit,
    append,
    remove,
    fields,
    formMethods,
    t,
  } = useUpdateAddFilterProductsForm(id as string, updateDefaultValue);

  return (
    <FormProvider {...formMethods}>
      <form
        className="mt-5 container justify-content-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="card ">
          <div className="card-header text-center">
            {updateDefaultValue
              ? t('FilterProducts.UpdateFilter')
              : t('FilterProducts.AddFilter')}
          </div>
          <div className="card-body">
            <Input
              label="Categorie"
              name="title"
              defaultValue={`${updateDefaultValue ? updateDefaultValue?.title : ''}`}
              validate={Validators([{ validation: 'required' }])}
            />
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <Input
                  label={`Attribut  #${index}`}
                  defaultValue={
                    `${updateDefaultValue?.attributes[index]}` !== 'undefined'
                      ? `${updateDefaultValue?.attributes[index].title}`
                      : ''
                  }
                  name={`attributes[${index}].title`}
                  validate={Validators([{ validation: 'required' }])}
                />
                <button
                  className="btn btn-warning col-3 offset-2 "
                  type="button"
                  onClick={() => remove(index)}
                >
                  {t('FilterProducts.deleteAnAttribute')}
                </button>
              </React.Fragment>
            ))}

            <button
              className="btn btn-success col-3 offset-2 "
              type="button"
              onClick={() => append([''])}
            >
              {t('FilterProducts.AddAnAttribute')}
            </button>
          </div>
          <div className="card-footer text-center">
            <button className="btn btn-primary col-3" type="submit">
              {t('dashboard.addDestination.Add')}
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddAndUpdateFilterProductsForm;
