import SEO from 'client/components/SEO';
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { useArticle } from './useArticle';

const Article = () => {
  const { selectedArticle } = useArticle();

  return (
    <>
      {selectedArticle && (
        <>
          <SEO
            title={selectedArticle.metaTitle}
            description={selectedArticle.metaDescription}
            image={getImageUrl(selectedArticle.metaImage[0])}
          />
          <div
            className="container-fluid w-100 background-image"
            style={{
              padding: '12rem 0',
              backgroundImage: `url(${getImageUrl(selectedArticle?.metaImage[0])})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              WebkitBackgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="text-center mx-md-auto overflow-hidden text-shadow-article container-xxl">
              <h1
                className="display-4 text-white"
                style={{
                  textShadow: 'rgb(94 81 81 / 65%) 9px 5px 24px, rgb(0 0 0) 1px 1px 7px',
                }}
              >
                {selectedArticle.metaTitle}
              </h1>
              <p
                className="lead text-white"
                style={{
                  textShadow: 'rgb(94 81 81 / 65%) 9px 5px 24px, rgb(0 0 0) 1px 1px 7px',
                }}
              >
                {selectedArticle.metaDescription}
              </p>
            </div>
          </div>
        </>
      )}
      <div className="shadow-less-serious min-vh-100 py-5">
        <div className="container h-100  rounded d-flex justify-content-center py-5">
          <div
            dangerouslySetInnerHTML={{
              __html: selectedArticle?.article?.replace(
                /<p/g,
                "<p style='overflow: hidden; text-overflow: ellipsis; padding: 0 1rem;'",
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Article;
