import { TFunction } from 'react-i18next';
import { GroupedRooms } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import { ISelectorOption } from 'types/ISelectorOption';
import { replaceSummary } from './replaceSummaryRoomDetails';

export const sectionRoomsOptionsQuotedAvailibility = ({
  recommended,
  alternatives,
  t,
}: {
  alternatives: GroupedRooms[] | undefined;
  recommended: GroupedRooms | undefined;
  t: TFunction<'translation'>;
}) => {
  const options: ISelectorOption<GroupedRooms | undefined>[] = [];

  if (!recommended) return null;
  const firstOption = {
    label: replaceSummary(`${recommended?.summary}`, t),
    value: recommended,
  };

  options.push(firstOption);
  if (alternatives) {
    options.push(
      ...alternatives.map((altern_room) => ({
        label: replaceSummary(`${altern_room.summary}`, t),
        value: altern_room,
      })),
    );
  }

  return options;
};
