import { useRouteQuery } from 'client/hooks/useRouteQuery';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  page: number | string;
  totalPages: number;
  previous?: string;
  next?: string;
}

const Pagination: React.FC<IProps> = ({
  page,
  totalPages,
  previous = 'Précedent',
  next = 'Suivant',
}) => {
  const { getNewQueryUrl } = useRouteQuery();

  return (
    <ul className="pagination justify-content-center">
      <li className={`page-item ${Number(page) === 1 && 'disabled'}`}>
        <Link
          className="page-link"
          to={getNewQueryUrl({ page: Math.min(Number(page) - 1, totalPages) })}
        >
          {previous}
        </Link>
      </li>

      <li className={`page-item ${Number(page) === 1 && 'active'}`}>
        <Link className="page-link" to={getNewQueryUrl({ page: 1 })}>
          1
        </Link>
      </li>
      {Number(totalPages) > 3 && Number(page) - 1 > 2 && <li>...</li>}

      {Number(totalPages) > 2 &&
        Array(Number(totalPages))
          .fill('')
          .map(
            (o, i) =>
              i !== 0 &&
              i !== Number(totalPages) - 1 &&
              (i + 1 === Number(page) || i === Number(page) || i + 2 === page) && (
                // eslint-disable-next-line react/no-array-index-key
                <li
                  key={getNewQueryUrl({ page: i + 1 })}
                  className={`page-item ${Number(page) === i + 1 && 'active'}`}
                >
                  <Link to={getNewQueryUrl({ page: i + 1 })} className="page-link">
                    {i + 1}
                  </Link>
                </li>
              ),
          )}
      {Number(totalPages) > 3 && Number(page) + 1 < Number(totalPages) - 1 && <li>...</li>}
      {Number(totalPages) > 1 && (
        <li className={`page-item ${Number(page) === Number(totalPages) && 'active'}`}>
          <Link to={getNewQueryUrl({ page: Number(totalPages) })} className="page-link">
            {Number(totalPages)}
          </Link>
        </li>
      )}
      <li className={`page-item ${Number(page) >= Number(totalPages) && 'disabled'}`}>
        <Link
          className="page-link"
          to={getNewQueryUrl({ page: Math.min(Number(page) + 1, totalPages) })}
        >
          {next}
        </Link>
      </li>
    </ul>
  );
};

export default Pagination;
