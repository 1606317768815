/* eslint-disable max-len */
import React from 'react';

interface IProps {
  height: string;
  width: string;
}
const PlaneDestinationSVG: React.FC<IProps> = ({ height, width }) => (
  <svg
    fill="#677788"
    id="Layer_1"
    enableBackground="new 0 0 510.213 510.213"
    height={height}
    viewBox="0 0 510.213 510.213"
    width={width}
  >
    <g>
      <path d="m75.106 196.213 10.606-10.606c6.591-6.591 64.394-65.69 64.394-110.606 0-41.355-33.645-75-75-75s-75 33.645-75 75c0 44.916 57.803 104.016 64.394 110.606zm0-166.213c24.813 0 45 20.187 45 45 0 20.786-24.46 55.03-45.007 78.109-20.545-23.052-44.993-57.27-44.993-78.109 0-24.813 20.187-45 45-45z" />
      <path d="m90.106 346.913c0-28.512 20.413-52.608 48.537-57.296l-4.932-29.592c-42.65 7.109-73.605 43.65-73.605 86.889 0 48.57 39.516 88.086 88.086 88.086h181.914v-30h-181.914c-32.028 0-58.086-26.058-58.086-58.087z" />
      <path d="m420.106 163.087c0 28.512-20.413 52.608-48.537 57.296l4.932 29.592c42.65-7.109 73.605-43.65 73.605-86.889 0-48.57-39.516-88.086-88.086-88.086h-181.914v30h181.914c32.029 0 58.086 26.058 58.086 58.087z" />
      <path d="m279.885 235.663-61.903-59.356-20.763 21.654 45.738 43.857-26.433 4.405-27.137-22.118-18.953 23.254 22.425 18.279-14.633 24.958 25.878 15.174 17.588-29.994 28.089-4.682-28.677 59.399 27.015 13.043 37.89-78.48 48.76-8.126-4.932-29.592z" />
      <path d="m60.106 61.218h30v28.782h-30z" />
      <path d="m435.106 314c-41.355 0-75 33.645-75 75 0 44.916 57.803 104.016 64.394 110.606l10.606 10.606 10.606-10.606c6.591-6.591 64.394-65.69 64.394-110.606 0-41.355-33.644-75-75-75zm-.006 153.109c-20.546-23.053-44.993-57.271-44.993-78.109 0-24.813 20.187-45 45-45s45 20.187 45 45c-.001 20.786-24.461 55.03-45.007 78.109z" />
      <path d="m420.106 375.218h30v28.782h-30z" />
    </g>
  </svg>
);

export default PlaneDestinationSVG;
