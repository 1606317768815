import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCountryCard = (deleteCountry: (id?: string) => void) => {
  const [t] = useTranslation();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDelete = (id?: string) => {
    deleteCountry(id);
    setOpenDeleteModal(false);
  };

  return {
    t,
    openDeleteModal,
    setOpenDeleteModal,
    handleDelete,
    openEditModal,
    setOpenEditModal,
  };
};
