import React from 'react';
import Loader from './Loader';
import { useProductCategory } from './useProductCategory';

const ProductCategory: React.FC = () => {
  const { category, loading } = useProductCategory();

  return (
    <div data-testid="ProductCategory" className="text-white">
      {loading ? <Loader /> : category}
    </div>
  );
};

export default ProductCategory;
