export enum ProductTypes {
  SET_PRODUCT_LIST_QUERY = 'SET_PRODUCT_LIST_QUERY',
  GET_PRODUCT_START = 'GET_PRODUCT_START',
  GET_NEW_PRODUCTS_FAILED = 'GET_NEW_PRODUCTS_FAILED',
  GET_NEW_PRODUCTS_SUCCESS = 'GET_NEW_PRODUCTS_SUCCESS',
  GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED',
  GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS',
  GET_PRODUCTS_START = 'GET_PRODUCTS_START',
  GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED',
  GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
  RESET_PRODUCT = 'RESET_PRODUCT',
  FETCH_PRODUCTS_PRICES_START = 'FETCH_PRODUCTS_PRICES_START',
  FETCH_PRODUCTS_PRICES_SUCCESS = 'FETCH_PRODUCTS_PRICES_SUCCESS',
  FETCH_PRODUCTS_PRICES_FAILED = 'FETCH_PRODUCTS_PRICES_FAILED',
  FETCH_PRODUCTS_PRICES_AVAILABILITY_START = 'FETCH_PRODUCTS_PRICES_AVAILABILITY_START',
  FETCH_PRODUCTS_PRICES_AVAILABILITY_SUCCESS = 'FETCH_PRODUCTS_PRICES_AVAILABILITY_SUCCESS',
  FETCH_PRODUCTS_PRICES_AVAILABILITY_FAILED = 'FETCH_PRODUCTS_PRICES_AVAILABILITY_FAILED',
  UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START',
  UPDATE_PRODUCTS_FAILED = 'UPDATE_PRODUCTS_FAILED',
  UPDATE_PRODUCTS_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS',
  GET_CUSTOM_PRODUCTS = 'GET_CUSTOM_PRODUCTS',
  CLEAR_PRODUCT = 'CLEAR_PRODUCT',
}
