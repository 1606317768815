import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { useLoginForm } from './useLoginForm';

export interface ILoginFormValues {
  email: string;
  password: string;
}

interface IPorps {
  onSubmit: SubmitHandler<ILoginFormValues>;
}

const LoginForm: React.FC<IPorps> = ({ onSubmit }) => {
  const { _onSubmit, handleSubmit, formMethods, t } = useLoginForm(onSubmit);

  return (
    <FormProvider {...formMethods}>
      <form className="mt-5" onSubmit={handleSubmit(_onSubmit)}>
        <div className="mb-7">
          <h1 className="h3 text-primary font-weight-normal mb-0">
            Bienvenue sur
            <span className="font-weight-semi-bold"> Moncef Jouets</span>
          </h1>
          <p>Remplissez le formulaire pour commencer</p>
        </div>
        <Input
          type="email"
          name="email"
          label={t('siteProtection.SignInEmailLable')}
          placeholder={t('siteProtection.SignInEmailLable')}
          validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
        />

        <Input
          type="password"
          name="password"
          label={t('siteProtection.SignInPasswordLable')}
          placeholder="********"
          forgotPasswordLink="/"
          forgotPasswordMessage={t('siteProtection.ForgetYourPassword')}
          validate={Validators([
            { validation: 'required' },
            { minParams: 8, validation: 'minLength' },
          ])}
        />

        <div className="row justify-content-center mb-5">
          <button
            name="Commencer"
            data-testid="submitButton"
            className="btn btn-primary mb-1"
            type="submit"
          >
            {t('siteProtection.Go!')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
