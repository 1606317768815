import { ProductTypes } from 'client/_redux/types/productTypes';
import { IThunkAction } from 'types/IThunkAction';
import * as ProductService from 'client/_redux/services/product';
import { errorHandler } from 'client/helpers/errorHandler';
import { HomeProductsTypes } from 'client/_redux/types/homePageProductsTypes';
import { ISearchCriteriaRouteQueryObject } from 'types/ISearchCriteriaQuery';
import { objectToQueryString } from 'client/helpers/objectToQueryString';
import { startLoading, stopLoading } from '../loading';

export const getHomeProducts = (): IThunkAction => async (dispatch, getState) => {
  const {
    productListReducer: { isHomeProductsPreloaded },
  } = getState();

  if (isHomeProductsPreloaded) return;
  dispatch(startLoading());
  dispatch({ type: HomeProductsTypes.GET_HOME_PRODUCTS_START });
  try {
    const res = await ProductService.getHomeProducts()();

    dispatch({
      type: HomeProductsTypes.GET_HOME_PRODUCTS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: HomeProductsTypes.GET_HOME_PRODUCTS_FAILED });
  }

  dispatch(stopLoading());
};
export const getCustomProducts = (
  query: string,
  filter?: {
    [K in keyof ISearchCriteriaRouteQueryObject]: ISearchCriteriaRouteQueryObject[K];
  },
): IThunkAction => async (dispatch, getState) => {
  const {
    productListQuery,
    productListReducer: { isPreloaded },
  } = getState();

  if (isPreloaded && productListQuery === query) return;

  dispatch(startLoading());
  try {
    const res = await ProductService.getCustomProducts()(objectToQueryString(filter));

    dispatch({ type: ProductTypes.GET_CUSTOM_PRODUCTS, payload: res.data });
    dispatch({ type: ProductTypes.SET_PRODUCT_LIST_QUERY, payload: query });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
