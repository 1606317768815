import { format } from 'date-fns';
import * as React from 'react';
import { useSelectListModal } from './useSelectListModal';

interface IProps {
  options: { date?: Date; value?: string | number }[];
  label?: string;
  name?: string;
  handleSelect: (e: string) => void;
  handleClose?: () => void;
  value?: string;
  isMinimumPrice?: number;
  setWatchPrice: React.Dispatch<React.SetStateAction<string | number | undefined>>;
}

const SelectListModal: React.FC<IProps> = ({
  options,
  handleSelect,
  handleClose,
  isMinimumPrice,
  value,
  setWatchPrice,
}) => {
  const { onSelect, watch, selectOptions } = useSelectListModal({
    handleSelect,
    handleClose,
    options,
    value,
    setWatchPrice,
  });

  return (
    <div
      className="d-flex list-group rounded-0 mb-0 py-2 w-100 "
      style={{ background: '#f9f8f8' }}
    >
      {options &&
        selectOptions?.map((e, j) => (
          <React.Fragment key={`${j + 1}`}>
            <div
              style={{ top: '50px' }}
              className="sticky-top d-flex justify-content-between align-items-center p-2 bg-secondary text-white mb-2 px-0"
            >
              <div>{`${e?.month} ${e?.year}`}</div>
              <div className="font-size-1">Prix ttc/pers*</div>
            </div>
            {e.days?.map((o, i) => (
              <div
                key={`${i + 1}`}
                style={{ cursor: 'pointer' }}
                className={`d-flex mt-2 justify-content-between align-items-center mx-2 p-2 bg-white  mb-2 ${
                  watch('start-date') === format(new Date(o.option.date || 0), 'yyyy-MM-dd') &&
                  ' border border-primary'
                }`}
                onClick={() => onSelect(o.option)}
              >
                <div className="d-flex flex-row justify-content-between align-items-center w-100">
                  <div className="d-flex flex-column  border py-1 bg-primary text-white col-5 rounded px-0">
                    <div className="text-center" style={{ fontSize: '12px' }}>
                      {o.day}
                    </div>
                    <div className="text-center" style={{ fontSize: '20px', fontWeight: 700 }}>
                      {o.dayInMonth}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center  font-size-2 px-1">
                    <i className="fas fa-long-arrow-alt-right" />
                  </div>
                  <div className="d-flex flex-column py-1 bg-primary text-white col-5 rounded px-0">
                    <div className="text-center" style={{ fontSize: '12px' }}>
                      {o.dayRetour}
                    </div>
                    <div className="text-center" style={{ fontSize: '20px', fontWeight: 700 }}>
                      {o.dayInMonthRetour}
                    </div>
                  </div>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center ">
                  <div
                    className={`font-size-2 font-weight-bold  ${
                      o.price === isMinimumPrice ? 'text-secondary' : 'text-primary'
                    }`}
                  >
                    {o.price}€
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
    </div>
  );
};

export default SelectListModal;
