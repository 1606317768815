import { clientApi } from 'api';
import { DBDocument } from 'client/models/utils';
import { ITransaction } from 'server/models/Transaction/types';

export const getTransaction = (isServer = false) => async (transactionId: string) => {
  const response = await clientApi.get<ITransaction & DBDocument>(
    `/transactions/${transactionId}`,
  );

  return response;
};
