import {
  getDashboardProducts,
  addHomeProduct,
  deleteHomeProducts,
} from 'dashboard/_redux/actions/products';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IHomeProduct } from 'server/models/HomeProducts/types';
import { useAppSelector } from 'client/hooks/useAppSelector';

const useAddHomeproducts = () => {
  const { homeProducts, products } = useAppSelector(
    ({ dashboardProducts }) => dashboardProducts,
  );

  const dispatch = useDispatch();
  const [allProducts, setAllProducts] = useState<IHomeProduct[]>([]);
  const handleAddToHome = useCallback(
    (id: string): React.MouseEventHandler => () => {
      const productToAdd = allProducts?.find((product) => product._$id === id);
      const tempAllProductsArray = allProducts?.slice();

      if (productToAdd) {
        dispatch(addHomeProduct(id));
        const newArray = tempAllProductsArray.filter((product) => product !== productToAdd);

        setAllProducts(newArray);
      }
    },
    [allProducts, dispatch],
  );
  const handleDeleteFromHome = useCallback(
    (id: string): React.MouseEventHandler => () => {
      const productToDelete = homeProducts?.find((product) => product._$id === id);
      const tempAllProductsArray = allProducts?.slice();

      if (productToDelete) {
        dispatch(deleteHomeProducts(id));
        tempAllProductsArray?.unshift(productToDelete);
        setAllProducts(tempAllProductsArray);
      }
    },
    [allProducts, dispatch, homeProducts],
  );
  const initializeProducts = useCallback(() => {
    setAllProducts(products || []);
  }, [products]);

  useEffect(() => {
    dispatch(getDashboardProducts());
  }, [dispatch]);
  useEffect(() => {
    initializeProducts();
  }, [initializeProducts]);

  return { allProducts, homeProducts, handleAddToHome, handleDeleteFromHome };
};

export default useAddHomeproducts;
