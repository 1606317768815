import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import { useAppSelector } from 'client/hooks/useAppSelector';
export const useProductTitle = () => {
  const { title, loading } = useAppSelector(({ productReducer }) => ({
    title: upperCaseFirstLetter(productReducer.product?.description.name || ''),
    loading: productReducer.loading,
  }));

  return {
    title,
    loading,
  };
};
