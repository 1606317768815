import React from 'react';
import FooterContact from './FooterContact';
import FooterInfo from './FooterInfo';
import FooterMentionLegales from './FooterMentionLegales';

const Footer = () => (
  <>
    <FooterContact />
    <FooterInfo />
    <FooterMentionLegales />
  </>
);

export default Footer;
