import React, { ReactNode } from 'react';

interface IProps {
  className: string;
  children?: ReactNode;
}

const CustomSkeleton: React.FC<IProps> = ({ className, children }) => (
  <div className={`${className} skeleton-box`} />
);

export default CustomSkeleton;
