import { useState, useCallback, useMemo, useEffect } from 'react';
import Fuse from 'fuse.js';
import { groupBy } from 'client/helpers/groupBy';
import { FlightFilterType } from 'client/components/FlightsTopFilter/useFlightsTopFilter';
import { ICountryAirports } from '.';
const fuseOptions = {
  shouldSort: true,
  distance: 0,
  threshold: 0.3,
  maxPatternLength: 1,
  minMatchCharLength: 1,
  keys: ['city', 'country', 'state', 'name', 'code'],
};
const FUSE_RESULTS_LENGTH = 10;

export const useAutoComplete = ({
  data,
  defaultValue,
  name,
  setOuterState,
  value,
  outerState,
}: {
  data: ICountryAirports[];
  defaultValue: string | undefined;
  setOuterState?: (data: FlightFilterType) => void;
  name?: 'from' | 'destination';
  value?: string;
  outerState?: FlightFilterType;
}) => {
  const [searchText, setSearchText] = useState<string>(defaultValue || '');
  const [suggestions, setSuggestions] = useState<
    Map<string, Fuse.FuseResult<ICountryAirports>[]>
  >(new Map([]));
  const [isComponentVisible, setIsComponentVisible] = useState(true);

  useEffect(() => {
    value && setSearchText(value);
  }, [value]);

  const fuse = useMemo(() => new Fuse(data, fuseOptions), [data]);
  const fuseSearch = useCallback(
    (text: string) => {
      if (!data) return setSuggestions(new Map([]));
      if (!text) {
        const groupedResult = groupBy<Fuse.FuseResult<ICountryAirports>>(
          data.slice(0, FUSE_RESULTS_LENGTH).map((el, i) => ({ item: el, refIndex: i })),
          (r) => r.item.city,
        );

        return setSuggestions(groupedResult);
      }
      const fuseResult = fuse.search(text);
      const groupedResult = groupBy<Fuse.FuseResult<ICountryAirports>>(
        fuseResult.slice(0, FUSE_RESULTS_LENGTH),
        (r) => r.item.city,
      );

      return setSuggestions(groupedResult);
    },
    [data, fuse],
  );
  const onTextChanged = (value: string) => {
    setSearchText(value);
    fuseSearch(value);
    setIsComponentVisible(true);
  };
  const suggestionSelected = (value: ICountryAirports) => {
    const textToDisplay = `${value.name} ${value.code}`;

    setIsComponentVisible(false);
    setSearchText(textToDisplay);
    setSuggestions(new Map([]));
    name &&
      setOuterState &&
      setOuterState({
        ...outerState,
        [name]: textToDisplay,
        city: {
          ...outerState?.city,
          [name]: { value: '', type: '' },
        } as FlightFilterType['city'],
      });
  };
  const suggestionHeaderSelected = (value: string) => {
    const findByCity = data.find((el) => el.city === value);

    const findByCountry = data.find((el) => el.country === value);
    let textToDisplay = '';

    if (findByCity) textToDisplay = `${findByCity.country} ${findByCity.city} `;
    else if (findByCountry) textToDisplay = `${findByCountry.country} ${findByCountry.city} `;
    const airportCode = (findByCity || findByCountry)?.city;

    setIsComponentVisible(false);
    setSearchText(textToDisplay);
    setSuggestions(new Map([]));
    name &&
      setOuterState &&
      airportCode &&
      setOuterState({
        ...outerState,
        city: {
          ...outerState?.city,
          [name]: { value: airportCode, type: name },
        } as FlightFilterType['city'],
        [name]: '',
      });
  };

  return {
    onTextChanged,
    suggestionSelected,
    suggestionHeaderSelected,
    isComponentVisible,
    setIsComponentVisible,
    searchText,
    suggestions,
  };
};
