import { IThunkAction } from 'types/IThunkAction';
import { errorHandler } from 'client/helpers/errorHandler';
import { startLoading, stopLoading } from 'dashboard/_redux/actions/loading';
import * as ProductInfoServices from 'dashboard/_redux/services/productInfo';
import { ProductInfoTypes } from 'dashboard/_redux/types/productInfoTypes';
import { IProductInfo } from 'server/models/ProductInfo/types';
import { getFromData } from 'client/helpers/getFromData';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { ProductTypes } from 'client/_redux/types/productTypes';

export const addProductInfo = (product: IProductInfo): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  dispatch({ type: ProductInfoTypes.ADD_PRODUCT_INFO_START });
  try {
    const { tourProgram } = product;

    if (tourProgram) {
      const formData = new FormData();

      const imagesArray = tourProgram?.map((tour) => tour.media);

      getFromData(formData, { imagesArray });
      const imagesLinks = await ProductInfoServices.sendImageArray()(formData);

      imagesArray?.forEach((image, index) => {
        image?.length
          ? ([tourProgram[index].media] = imagesLinks.data.splice(0, 1))
          : (tourProgram[index].media = '');
      });
    }
    const productInfo = await ProductInfoServices.addProductInfo()(product);

    toast('updated successfully');

    dispatch({
      type: ProductInfoTypes.ADD_PRODUCT_INFO_SUCCESS,
      payload: productInfo.data,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ProductInfoTypes.ADD_PRODUCT_INFO_FAILED });
  }

  dispatch(stopLoading());
};

export const getProductInfo = (
  params: {
    from?: string | undefined;
    'number-of-nights'?: string | undefined;
    permalink: string;
  },
  history?: RouteComponentProps['history'],
): IThunkAction => async (dispatch, getState) => {
  try {
    const { productInfo, productReducer } = getState();
    const stateIsTheSame =
      productInfo.isPreloaded &&
      productReducer.isPreloaded &&
      JSON.stringify(productReducer.params) === JSON.stringify(params);

    if (stateIsTheSame) {
      return;
    }
    const res = await ProductInfoServices.getProductInfo()(params);

    dispatch({
      type: ProductTypes.GET_PRODUCT_SUCCESS,
      payload: { catalogue: res.data.catalogue, customCatalogue: res.data.customCatalogue },
    });
    dispatch({
      type: ProductInfoTypes.GET_PRODUCT_INFO_SUCCESS,
      payload: res.data.productInfo,
    });
    dispatch({
      type: ProductTypes.FETCH_PRODUCTS_PRICES_SUCCESS,
      payload: res.data.cataloguePrices,
    });
    dispatch({
      type: ProductTypes.FETCH_PRODUCTS_PRICES_AVAILABILITY_SUCCESS,
      payload: res.data.cataloguePricesAvailability,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ProductInfoTypes.GET_PRODUCT_INFO_FAILED });
    history?.goBack();
  }

  dispatch(stopLoading());
};
