const FLIGHT_TYPES_ICONS = [
  {
    label: 'Aller simple',
    value: false,
  },
  {
    label: 'Aller et retour',
    value: true,
  },
];

export default FLIGHT_TYPES_ICONS;
