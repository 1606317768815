import { useTranslation } from 'react-i18next';
import { ITransaction } from 'server/models/Transaction/types';
import { ITraveller } from 'types/ICreateBookingRequest';

type TravellerElement = {
  id: string;
  elementName?: string;
  travellers?: ITraveller[];
};
export const useReservationDetailsCards = (transaction: ITransaction) => {
  const {
    bookingParams: { hotelsData, adults, addons },
    bookingResponse,
  } = transaction;
  const [t] = useTranslation();
  const roomsArray: TravellerElement[] = [];
  const data = bookingResponse?.bookingDetails?.productDetails[0]?.data;
  const roomElement = Array.isArray(data) ? data[0].room : data.room;
  const isRoomsArray = Array.isArray(roomElement);

  hotelsData.forEach((data) =>
    data.rooms?.forEach((room) =>
      roomsArray.push({
        travellers: adults.filter((ad) => room.travellersIds.find((tr) => tr === ad.id)),
        id: room.code,
        elementName: isRoomsArray
          ? roomElement?.find((el) => el.code === room.code)?.description
          : roomElement?.description,
      }),
    ),
  );
  const addonsArray: TravellerElement[] = [];

  addons?.forEach((addon) =>
    addonsArray.push({
      travellers: adults.filter((ad) => addon.travellersIds.find((tr) => tr === ad.id)),
      id: addon.code,
    }),
  );

  return { roomsArray, addonsArray, t };
};
