export enum HomeProductsTypes {
  ADD_HOME_PRODUCTS_START = 'ADD_HOME_PRODUCTS_START',
  ADD_HOME_PRODUCTS_FAILED = 'ADD_HOME_PRODUCTS_FAILED',
  ADD_HOME_PRODUCTS_SUCCESS = 'ADD_HOME_PRODUCTS_SUCCESS',
  GET_HOME_PRODUCTS_START = 'GET_HOME_PRODUCTS_START',
  GET_HOME_PRODUCTS_FAILED = 'GET_HOME_PRODUCTS_FAILED',
  GET_HOME_PRODUCTS_SUCCESS = 'GET_HOME_PRODUCTS_SUCCESS',
  DELETE_HOME_PRODUCTS_START = 'DELETE_HOME_PRODUCTS_START',
  DELETE_HOME_PRODUCTS_FAILED = 'DELETE_HOME_PRODUCTS_FAILED',
  DELETE_HOME_PRODUCTS_SUCCESS = 'DELETE_HOME_PRODUCTS_SUCCESS',
}
