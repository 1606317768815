import * as React from 'react';
import { useLocation } from 'react-router-dom';

const SafeAreaView: React.FC = ({ children }) => {
  const link = useLocation();

  return (
    <div
      className={` ${
        link.pathname.includes('blog')
          ? 'p-0'
          : 'space-top-3 mt-2 space-top-md-2 space-top-lg-3'
      }`}
    >
      {children}
    </div>
  );
};

export default SafeAreaView;
