import { PriceDetails } from 'client/containers/QuotedAvailability/PriceDetails/usePriceDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
type IProps = {
  priceDetail: PriceDetails;
};

const ListItemsDetails: React.FC<IProps> = ({ priceDetail }) => {
  const [t] = useTranslation();

  return (
    <li key={`${priceDetail.type}`}>
      <span className="span-list-items-details">
        {priceDetail.type.replace('Room', t('room.room'))} :
      </span>
      {priceDetail.price.totalPrice.toLocaleString('en-US', {
        style: 'currency',
        currency: priceDetail.price.currency,
      })}
    </li>
  );
};

export default ListItemsDetails;
