import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormSelect from '../Form/FormSelect';
import InputPlusMinusButtons from '../Form/InputPlusMinusButtons';
import UnstyledButton from './UnstyledButton';
import { useFlightFilter } from './useFlightFilter';

type IFlightFilter = {
  setShowSideFilter?: (value: boolean) => void;
  showCloseButton?: boolean;
};
const FlightsFilter: React.FC<IFlightFilter> = ({ showCloseButton, setShowSideFilter }) => {
  const [t] = useTranslation();
  const {
    onSubmit,
    formMethods,
    handleSubmit,
    searchCriteria,
    defaultBabies,
    defaultKids,
    defaultSeats,
  } = useFlightFilter();
  const [{ adults, kids, babies }, setPassengers] = useState({
    adults:
      Number(defaultSeats || '1') - Number(defaultKids || '0') - Number(defaultBabies || '0'),
    kids: Number(defaultKids || '0'),
    babies: Number(defaultBabies || '0'),
  });

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit}>
      <form onSubmit={handleSubmit(onSubmit)} className=" max-w-100">
        <div className="max-w-100">
          <div className="card shadow-serious text-dark border mx-md-0 m-3 ">
            <div className="card-header justify-content-between">
              <h3> {t('searchFilters.yourSearch').toUpperCase()}</h3>
              {showCloseButton && setShowSideFilter && (
                <i
                  onClick={() => setShowSideFilter(false)}
                  className="fas fa-times text-primary d-md-none d-block"
                />
              )}
            </div>

            <div className="card-body pt-0">
              <h5 className="form-label ">
                Nombre de voyageurs (
                {Number(adults || '0') + Number(kids || '0') + Number(babies || '0')}):
              </h5>
              <div>
                <label className="form-label ">Nombre d&apos;adultes :</label>
                <UnstyledButton
                  type="submit"
                  style={{
                    width: '100%',
                  }}
                >
                  <InputPlusMinusButtons
                    min={1}
                    max={6}
                    name="adults"
                    defaultValue={
                      Number(defaultSeats || '1') -
                      Number(defaultKids || '0') -
                      Number(defaultBabies || '0')
                    }
                    handleCountChange={(value) =>
                      setPassengers({ adults: value, kids, babies })
                    }
                    isFlightsPages
                  />
                </UnstyledButton>
              </div>
              <div>
                <label className="form-label ">Nombre d&apos;enfants (2 à 12 ans) :</label>
                <UnstyledButton
                  type="submit"
                  style={{
                    width: '100%',
                  }}
                >
                  <InputPlusMinusButtons
                    min={0}
                    max={6}
                    name="kids"
                    defaultValue={kids}
                    handleCountChange={(value) =>
                      setPassengers({ adults, kids: value, babies })
                    }
                    isFlightsPages
                  />
                </UnstyledButton>
              </div>
              <div>
                <label className="form-label ">Nombre de bébés (0 à 2 ans) :</label>
                <UnstyledButton
                  type="submit"
                  style={{
                    width: '100%',
                  }}
                >
                  <InputPlusMinusButtons
                    min={0}
                    max={6}
                    name="babies"
                    defaultValue={babies || 0}
                    handleCountChange={(value) =>
                      setPassengers({ adults, kids, babies: value })
                    }
                    isFlightsPages
                  />
                </UnstyledButton>
              </div>
              <div className="mb-3 ">
                <label className="form-label ">Bagages :</label>
                <FormSelect options={searchCriteria.luggageOptions} name="luggageOptions" />
              </div>
              <div className="mb-3">
                <label className="form-label ">Nombre d&apos;escales :</label>
                <FormSelect
                  options={searchCriteria.viaAirportOptions}
                  name="viaAirportOptions"
                />
              </div>
            </div>
            <div className="card-footer">
              <button className="btn btn-primary mx-auto d-block w-100" type="submit">
                {t('searchFilters.search')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default FlightsFilter;
