import { clientApi } from 'api';
import { ICheckingReservationForm } from 'client/containers/CheckingReservationForm/CheckingReservationForm';

export const sendCheckingReservationDetails = (isServer = false) => async (
  value: ICheckingReservationForm,
) => {
  const response = await clientApi.post<string>(
    `/transactions/get-reservation-details-link`,
    value,
  );

  return response;
};
