import { Addons } from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import React, { useMemo } from 'react';
import Collapsible from 'client/components/Collapsible';
import { AddonFormValues } from './useProductAddons';
import AddonsListItems from './AddonsListItems';

type AddonsListProps = {
  addons: Addons[];
  noResultText: string;
  onChange: (addon: AddonFormValues) => void;
};

const AddonsList = ({ addons, onChange, noResultText }: AddonsListProps) => {
  const AddonsList = useMemo(
    () =>
      addons
        .filter((el) => el.list)
        .map(({ list, description }) => (
          <>
            {list && list?.length > 0 && (
              <Collapsible
                key={description}
                className="mb-2 dropdown-up"
                title={`${description}` || ''}
              >
                <AddonsListItems
                  addon={list}
                  noResultText={noResultText}
                  onChange={onChange}
                />
              </Collapsible>
            )}
          </>
        )),
    [addons, noResultText, onChange],
  );

  return AddonsList;
};

export default AddonsList;
