import { createReservation } from 'client/_redux/actions/bookingPayment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IBookingConfirmation } from 'types/IBookingConfirmation';

export const useReservationConfirmation = (booking: IBookingConfirmation) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleCreatePayment = () => {
    dispatch(createReservation(paymentMethod, booking.payment.totalPrice));
  };

  return { t, handleCreatePayment, setPaymentMethod };
};
