import { useEffect } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IProductInfo } from 'server/models/ProductInfo/types';

export interface IProductDetailsEditingValues {
  description: { title: string; content: string }[];
  formalities: { title: string; content: string }[];
  tourProgram: { day: string; title: string; content: string; media?: string[] | string }[];
  includes: string;
  excludes: string;
  extra: string;
  metaDescription: string;
  metaTitle: string;
  services: { title: string; icon: string }[];
}
export const useProductDetailsEditor = (
  onSubmit: SubmitHandler<IProductInfo>,
  permalink: string,
  initialValues?: IProductInfo,
) => {
  const [t] = useTranslation();
  const formMethods = useForm<IProductDetailsEditingValues>();

  const { handleSubmit, control, reset } = formMethods;

  useEffect(() => {
    if (initialValues?.tourProgram)
      reset({
        description: initialValues?.description,
        formalities: initialValues?.formalities,
        includes: initialValues?.priceDetails?.includes,
        excludes: initialValues?.priceDetails?.excludes,
        extra: initialValues?.priceDetails?.extra,
        metaDescription: initialValues?.metaDescription,
        metaTitle: initialValues?.metaTitle,
        tourProgram: initialValues?.tourProgram,
        services: initialValues?.services,
      });

    return () => {
      reset({
        description: [{ title: '', content: '' }],
        formalities: [{ title: '', content: '' }],
        tourProgram: [{ day: '', title: '', content: '', media: '' }],
        includes: '',
        excludes: '',
        extra: '',
        metaDescription: '',
        metaTitle: '',
        services: [{ title: '', icon: '' }],
      });
    };
  }, [initialValues, reset]);
  const {
    fields: servicesFields,
    append: appendServices,
    remove: removeServices,
  } = useFieldArray({
    control,
    name: 'services',
  });

  const {
    fields: descriptionFields,
    append: appendDescription,
    remove: removeDescription,
  } = useFieldArray({
    control,
    name: 'description',
  });
  const {
    fields: formalitiesFields,
    append: appendFormalities,
    remove: removeFormalities,
  } = useFieldArray({
    control,
    name: 'formalities',
  });
  const { fields: tourFields, append: appendTour, remove: removeTour } = useFieldArray({
    control,
    name: 'tourProgram',
  });

  const _onSubmit = (data: IProductDetailsEditingValues) => {
    onSubmit({
      description: data.description,
      formalities: data.formalities,
      priceDetails: { includes: data.includes, excludes: data.excludes, extra: data.extra },
      permalink,
      metaDescription: data.metaDescription,
      metaTitle: data.metaTitle,
      tourProgram: data.tourProgram,
      services: data.services,
    });
  };

  return {
    descriptionFields,
    appendDescription,
    removeDescription,
    formalitiesFields,
    appendFormalities,
    removeFormalities,
    servicesFields,
    appendServices,
    removeServices,
    tourFields,
    appendTour,
    removeTour,
    handleSubmit,
    formMethods,
    _onSubmit,
    t,
  };
};
