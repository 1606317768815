import { useAppSelector } from 'client/hooks/useAppSelector';
import { getDashboardExhibitedProducts } from 'dashboard/_redux/actions/products';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

export const useEditProductList = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { homeProducts, products, exhibitedProducts } = useAppSelector(
    ({ dashboardProducts }) => dashboardProducts,
  );

  useEffect(() => {
    dispatch(getDashboardExhibitedProducts(search));
  }, [dispatch, search]);

  return {
    homeProducts,
    products,
    exhibitedProducts,
  };
};
