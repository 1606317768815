import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ITotalPriceProps {
  totalPrice: number;
}

const TotalPrice: React.FC<ITotalPriceProps> = ({ totalPrice }) => {
  const [t] = useTranslation();

  return (
    <div className="d-flex flex-row total-price pt-2 no-gutters">
      <div className="col-8 align-content-start p-0">
        <span> {t('Price.totalPrice')} </span> <small>EUR</small>
      </div>
      <div className="col-4">
        {totalPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
      </div>
    </div>
  );
};

export default TotalPrice;
