import { SearchFilterDefaultValueEnum } from 'client/constants/SearchFilterDefaultValueEnum/SearchFilterDefaultValueEnum';
import { IfilterSearch } from 'client/containers/FilterHomePage/useFilterHomePage';
import { ISearchCriteriaQuery } from 'types/ISearchCriteriaQuery';

const formattedHomeFilterValue = (
  value: IfilterSearch,
  productSearchCriteriaValue: ISearchCriteriaQuery | undefined,
) => {
  const formattedDuration = () => {
    switch (value.duration) {
      case 'Court séjour (1 à 4 nuits)':
        return '<4';
      case '1 semaine (4 à 8 nuits)':
        return '4-8';
      case '+ de 1 semaine (8 nuits et +)':
        return '>8';
      default:
        return '';
    }
  };

  const formattedTripType = () => {
    switch (value.tripType) {
      case 'CIRCUIT':
        return 'tour';
      case 'HÔTEL':
        return 'hotel';
      default:
        return '';
    }
  };

  let formattedTo = value.destination.toLowerCase();

  if (formattedTo === SearchFilterDefaultValueEnum.PeuImporte.toLowerCase()) {
    formattedTo = '';
  }

  const formattedFrom = productSearchCriteriaValue?.from.find(
    (el) => el.label === value.from.toLowerCase(),
  );

  const formattedValue = {
    to: formattedTo,
    from: formattedFrom?.value || '',
    duration: formattedDuration(),
    'product-type': formattedTripType(),
  };

  return formattedValue;
};

export default formattedHomeFilterValue;
