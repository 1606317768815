import { clientApi } from 'api';
import { IUser } from 'server/models/User';

export const addAdmin = (isServer = false) => async ({
  email,
  password,
  firstName,
  lastName,
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}) => {
  const response = await clientApi.post<IUser>('/users/add-administrator', {
    email,
    password,
    firstName,
    lastName,
  });

  return response;
};
