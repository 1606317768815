import Navbar from 'client/components/Navbar';
import NavbarCollapse from 'client/components/NavbarCollapse';
import NavbarInfo from 'client/components/NavbarInfo';
import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import React from 'react';
import { Link } from 'react-router-dom';

const data = [
  {
    title: 'SÉJOURS',
    link: '/products?sort=asc',
  },
  {
    title: 'CIRCUITS',
    link: '/products?product-type=tour',
  },
  {
    title: 'HÔTELS',
    link: '/products?product-type=hotel',
  },
];
const Header = () => (
  <div
    style={{ zIndex: 33 }}
    className="bg-white w-100 shadow-less-serious position-fixed top-0 left-0"
  >
    <NavbarInfo
      info="Pour toute demande d’information, merci de nous appeler sur le"
      phone="01 85 08 18 84"
    />
    <div className="d-flex align-items-center justify-content-between  container container-xxl ">
      <Link to="/">
        <img src="/assets/svg/logos/logo.svg" alt="KeplerLogo"  width= '158px' height="auto" />
      </Link>
      <ResponsiveWrapper screenSize="lg">
        <Navbar navList={data} />
      </ResponsiveWrapper>
      <ResponsiveWrapper screenSize="lg" isMobile>
        <NavbarCollapse navList={data} />
      </ResponsiveWrapper>
    </div>
  </div>
);

export default Header;
