import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { getProductInfo } from 'dashboard/_redux/actions/productInfo';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const useProductPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { permalink } = useParams<{ permalink: string }>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  const searchObjectFilter: { from: string; 'number-of-nights': string } = useMemo(
    () =>
      queryStringToObject<{ from: string; 'number-of-nights': string }>(
        decodeURI(location.search),
      ),
    [location.search],
  );

  const { loading, title, description } = useAppSelector(({ productReducer }) => ({
    product: productReducer.product,
    loading: productReducer.loading,
    title: productReducer.product?.description.name,
    description:
      productReducer.product?.description.introduction ||
      productReducer.product?.description.sales,
    from: productReducer.product?.froms,
  }));
  const { cataloguePricesAvailability, product: productDesc } = useAppSelector(
    ({ productReducer }) => productReducer,
  );

  const fromAvailability = cataloguePricesAvailability?.map((e) => e.from);

  useEffect(() => {
    dispatch(getProductInfo({ permalink, ...searchObjectFilter }, history));
  }, [dispatch, searchObjectFilter, permalink, history]);
  const formatedFroms = fromAvailability;
  const customCatalogue = useAppSelector(
    ({ productReducer }) => productReducer.customCatalogue,
  );
  const product = useAppSelector(({ productReducer }) => productReducer.product);
  const { productInfo } = useAppSelector((state) => state.productInfo);

  const image = customCatalogue?.mainMedia || customCatalogue?.media?.[0];

  const navigatorRef = React.useRef<HTMLDivElement>(null);
  const navigatorRefResponsive = React.useRef<HTMLDivElement>(null);
  const handleClick = () => {
    navigatorRef.current?.scrollIntoView({ behavior: 'smooth' });
    navigatorRefResponsive.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return {
    productInfo,
    title,
    description,
    loading,
    customCatalogue,
    product,
    from: formatedFroms,
    image,
    handleClick,
    navigatorRef,
    navigatorRefResponsive,
    productDesc,
  };
};
