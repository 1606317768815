import { formatProductImageUrl } from 'client/helpers/format/formatProductImageUrl';
import { useAppSelector } from 'client/hooks/useAppSelector';
export const useProductImageGallery = () => {
  const { media, loading, youtubeEmbedID } = useAppSelector(({ productReducer }) => ({
    media: productReducer.product?.media,
    loading: productReducer.loading,
    youtubeEmbedID: productReducer.customCatalogue?.youtubeEmbed,
  }));
  const images = formatProductImageUrl(media);

  return { loading, images, youtubeEmbedID };
};
