import CheckboxInput from 'client/components/Form/CheckboxInput/CheckboxInput';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AdditionalInformation = () => {
  const [t] = useTranslation();

  return (
    <>
      <div className="container mt-5 ">
        <Input
          name={`${t('room.formReservation.Comments')}`}
          type="textarea"
          label={`${t('room.formReservation.YourComments')}`}
          placeholder="Commentaires..."
          defaultValue=""
          labelClassName="w-100 d-flex flex-start font-size-2"
        />
      </div>
      <div className="d-flex flex-column  ">
        <div className="container-fluid w-100 d-flex flex-column p-3 col-11">
          <div className="d-flex flex-row py-2">
            <CheckboxInput
              name="firstCondition"
              options={[
                {
                  label: `${t('room.formReservation.firstReservationCondition')}`,
                  value: 'firstCondition',
                  checked: false,
                },
              ]}
              onChange={(e) => e.target.checked}
              validate={Validators([{ validation: 'required' }])}
            />
          </div>

          <div className="container d-flex flex-row pt-2 m-0 p-0">
            <CheckboxInput
              name="secondCondition"
              Link={
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  className="link-underline align-self-center w-100 w-sm-auto pb-md-1 pb-0"
                >
                  {t('room.formReservation.secondReservationCondition02')}
                </a>
              }
              options={[
                {
                  label: `${t('room.formReservation.secondReservationCondition01')}`,
                  value: 'secondCondition',
                  checked: false,
                },
              ]}
              validate={Validators([{ validation: 'required' }])}
              onChange={(e) => e.target.checked}
            />

            <span className="align-self-center" />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary d-flex justify-content-center m-auto "
        >
          {t('room.formReservation.Submit')}
        </button>
      </div>
    </>
  );
};

export default AdditionalInformation;
