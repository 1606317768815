import { customAlphabet } from 'nanoid';
import { ISelectorOption } from 'types/ISelectorOption';

export const formattedArrayOptionsForm = (adulteCount: number, childCount: number) => {
  const adultOptions: ISelectorOption<string>[] = [];
  const childOptions: ISelectorOption<string>[] = [];

  const nanoid = customAlphabet('1234567890', 3);

  for (let index = 0; index < adulteCount + childCount; index++) {
    const _id = nanoid();

    if (index < adulteCount) {
      const adultvalue = { label: `Adulte ${index + 1}`, value: `${_id}` };

      adultOptions.push(adultvalue);
    }
    if (index + 1 > adulteCount) {
      const childvalue = { label: `Child ${index + 1 - +adulteCount}`, value: `${_id}` };

      childOptions.push(childvalue);
    }
  }
  const familyOptions = [...adultOptions, ...childOptions];

  return { adultOptions, childOptions, familyOptions };
};
