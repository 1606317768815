import { useAppSelector } from 'client/hooks/useAppSelector';
export const useProductCategory = () => {
  const { category, loading } = useAppSelector(({ productReducer }) => ({
    category: productReducer.product?.description.category,
    loading: productReducer.loading,
  }));

  return {
    category,
    loading,
  };
};
