import ListItemsDetails from 'client/containers/QuotedAvailability/PriceDetails/ListItemsDetails';
import TotalPrice from 'client/components/TotalPrice';
import React from 'react';
import { usePriceDetails } from './usePriceDetails';
import ModalPriceDetails from './ModalPriceDetails';
interface IPriceDetailsListProps {
  isConfirmationPage?: boolean;
}

const PriceDetailsList: React.FC<IPriceDetailsListProps> = ({ isConfirmationPage }) => {
  const {
    handleViewMore,
    isOpen,
    setIsOpen,
    t,
    selectedAddons,
    newQuotedPriceDetails,
    totalPriceRef,
    supplement,
  } = usePriceDetails();

  return (
    <>
      <div className="card border rounded mt-2 border border-primary">
        <div className="card-header bg-primary">
          <h2 className="text-center w-100 text-white">{t('priceDetails.title')}</h2>
        </div>
        <div className="text-center pt-1 border-bottom border-primary">
          <button
            type="button"
            className="btn btn-sm btn-ghost-primary transition-3d-hover w-100"
            onClick={handleViewMore}
          >
            <i className="fas fa-receipt pr-1" />
            {t('priceDetails.moreDetails')}
          </button>
        </div>
        {!isConfirmationPage && (
          <div className="card-body">
            <ul className="list-items">
              {newQuotedPriceDetails &&
                newQuotedPriceDetails.map((priceDetail) => (
                  <ListItemsDetails key={priceDetail.type} priceDetail={priceDetail} />
                ))}
            </ul>
            <div className="card-footer p-0 ">
              {totalPriceRef > 0 && <TotalPrice totalPrice={totalPriceRef} />}
            </div>
          </div>
        )}
      </div>
      {newQuotedPriceDetails && (
        <ModalPriceDetails
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          quotedPriceDetails={newQuotedPriceDetails}
          selectedAddons={selectedAddons}
          supplement={supplement}
        />
      )}
    </>
  );
};

export default PriceDetailsList;
