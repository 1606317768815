import React from 'react';
import EdonecSidebar, { SidebarLink } from '@edonec/sidebar';

const Sidebar = () => (
  <EdonecSidebar isOpenBydefault>
    <SidebarLink icon={<i className="fas fa-home" />} title="Home" to="/dashboard/home" />
    <SidebarLink
      icon={<i className="fas fa-money-bill-alt" />}
      title="Promotion"
      to="/dashboard/promotion"
    />
    <SidebarLink
      icon={<i className="fas fa-flag" />}
      title="Ajouter une destination"
      to="/dashboard/destination/add"
    />
    <SidebarLink
      icon={<i className="fas fa-users" />}
      title="List des Admins"
      to="/dashboard/admin/list"
    />
    <SidebarLink
      icon={<i className="fas fa-users-cog" />}
      title="Ajouter un admin"
      to="/dashboard/admin/add"
    />
    <SidebarLink
      icon={<i className="fas fa-sort-amount-up" />}
      title="Ajouter un filter"
      to="/dashboard/filter-products"
    />
    <SidebarLink
      icon={<i className="fas fa-edit" />}
      title="Editer un produit"
      to="/dashboard/product/edit"
    />
    <SidebarLink
      icon={<i className="fas fa-pen" />}
      title="Details du produit"
      to="/dashboard/product-details/edit"
    />
    <SidebarLink icon={<i className="fas fa-blog" />} title="Blog" to="/dashboard/blog/" />
  </EdonecSidebar>
);

export default Sidebar;
