import { get } from 'lodash';
import * as React from 'react';
import { ValidationRules, useFormContext } from 'react-hook-form';
import { ICheckboxOption } from 'types/ICheckboxOption';

export interface ICheckboxInputProps {
  options: ICheckboxOption[];
  label?: string;
  name: string;
  validate?: ValidationRules;
  Link?: React.LinkHTMLAttributes<string>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}
const CheckboxInput: React.FC<ICheckboxInputProps> = ({
  Link,
  options,
  name,
  label,
  validate,
  onChange,
}) => {
  const { errors, register } = useFormContext();
  const fieldError = get(errors, name);

  return (
    <div className={`form-group p-0 m-0 ${fieldError ? 'is-invalid' : ''}`}>
      {label && <label>{label}</label>}
      <div className="input-group d-flex flex-column ">
        {options.map((o, i) => (
          <div
            key={`${o.value}${i + 1}`}
            className={`form-control p-0 pr-2 align-self-center h-100 ${
              fieldError ? 'is-invalid' : 'border-0'
            }`}
          >
            <div className="custom-control custom-checkbox">
              <input
                className="custom-control-input p-3"
                name={name}
                type="checkbox"
                id={o.label}
                ref={register(validate)}
                onChange={onChange}
                value={o.value}
                defaultChecked={o.checked}
              />
              <label className="custom-control-label" htmlFor={o.label}>
                {o.label} {Link}
              </label>
            </div>
          </div>
        ))}
      </div>
      {fieldError && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldError.message}
        </div>
      )}
    </div>
  );
};

export default CheckboxInput;
