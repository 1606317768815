/* eslint-disable max-lines */
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useQuery } from 'client/hooks/useQuery';
import {
  getCalendarFlights,
  getInitialRoundedCalendarFlights,
  resetCalendar,
  setDepartureAerport,
  setDestinationCountry,
  setFlightType,
} from 'client/_redux/actions/flights';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ICountryAirports } from '../Form/AutoComplete';
export type FlightFilterType = {
  destination?: string;
  from?: string;
  city: {
    from: { type: string; value: string };
    destination: { type: string; value: string };
  };
};
export const useFlightsTopFilter = (COUNTRIES: ICountryAirports[]) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { flightsData } = useAppSelector(({ flightsReducer }) => ({
    flightsData: {
      isRoundFlight: flightsReducer.isRoundFlight,
      departureDate: flightsReducer.departureDate,
      returningDate: flightsReducer.returningDate,
      destinationCountry: flightsReducer.destinationCountry,
      departureAerport: flightsReducer.departureAerport,
      pax: flightsReducer.pax,
    },
  }));
  const {
    seats,
    departure,
    destination,
    luggage,
    'via-airports': viaAirports,
    kids,
    babies,
  } = useQuery([
    'lugguage',
    'via-airports',
    'departure',
    'kids',
    'babies',
    'destination',
    'seats',
  ]) as Record<string, string>;
  const [topFilterData, setTopFilterData] = useState<FlightFilterType>({
    destination: '',
    from: '',
    city: { from: { type: '', value: '' }, destination: { type: '', value: '' } },
  });
  const [switched, setSwitched] = useState(false);
  const { departureDate, returningDate, pax } = flightsData;
  const findByCity = useCallback((value) => COUNTRIES.find((el) => el.city === value), [
    COUNTRIES,
  ]);
  const findByAirport = useCallback((value) => COUNTRIES.find((el) => el.code === value), [
    COUNTRIES,
  ]);
  const formatDestinationAndDeparture = useCallback(
    ({ destination, departure }: { destination?: string; departure?: string }) =>
      destination && departure
        ? {
            destination: findByCity(destination)
              ? `${findByCity(destination)?.city}`
              : `${
                  findByAirport(destination)?.name
                    ? `${findByAirport(destination)?.name} `
                    : ''
                }${findByAirport(destination)?.code || ''}`,
            from: findByCity(departure)
              ? `${findByCity(departure)?.city}`
              : `${
                  findByAirport(departure)?.name ? `${findByAirport(departure)?.name} ` : ''
                }${findByAirport(departure)?.code || ''}`,
          }
        : { destination: '', from: '' },
    [findByAirport, findByCity],
  );
  const switchInputs = () => {
    if (switched) return;
    if (flightsData.destinationCountry && flightsData.departureAerport) {
      setSwitched(true);
      dispatch(setDestinationCountry(flightsData.departureAerport));
      dispatch(setDepartureAerport(flightsData.destinationCountry));
      setTopFilterData({
        from: topFilterData.destination,
        destination: topFilterData.from,
        city: topFilterData.city && {
          destination: topFilterData.city?.from,
          from: topFilterData.city?.destination,
        },
      });

      dispatch(
        flightsData.isRoundFlight
          ? getInitialRoundedCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: topFilterData.from || '',
              destination: topFilterData.destination || '',
              pax,
            })
          : getCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: flightsData.departureAerport || '',
              destination: flightsData.destinationCountry || '',
              pax,
            }),
      );

      return;
    }
    setSwitched(true);
    const temp = topFilterData.destination;

    setTopFilterData({
      ...topFilterData,
      destination: topFilterData.from || topFilterData.city?.from.value,
      from: temp || topFilterData.city?.destination.value,
    });
    if (topFilterData.city?.from.value) {
      dispatch(setDestinationCountry(topFilterData.city.from.value));
    } else {
      dispatch(
        setDestinationCountry(topFilterData.from?.slice(topFilterData.from?.length - 3) || ''),
      );
    }
    if (topFilterData.city?.destination.value) {
      dispatch(setDepartureAerport(topFilterData.city?.destination.value));
    } else
      dispatch(
        setDepartureAerport(
          topFilterData.destination?.slice(topFilterData.destination?.length - 3) || '',
        ),
      );

    dispatch(
      flightsData.isRoundFlight
        ? getInitialRoundedCalendarFlights({
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            departure: topFilterData.from || '',
            destination: topFilterData.destination || '',
            pax,
          })
        : getCalendarFlights({
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            departure: flightsData.departureAerport || '',
            destination: flightsData.destinationCountry || '',
            pax,
          }),
    );
  };
  const extractDataFromAutoComplete = (data: FlightFilterType) => {
    setTopFilterData({ ...topFilterData, ...data });
    if (data.city?.destination.value || data.city?.from.value) {
      if (data.city?.destination.value) {
        dispatch(setDestinationCountry(data.city?.destination.value));
        if (data.city?.from.value) {
          dispatch(setDepartureAerport(data.city?.from.value));
        } else
          data.from && dispatch(setDepartureAerport(data.from.slice(data.from.length - 3)));
      } else if (data.city?.from.value) {
        dispatch(setDepartureAerport(data.city.from.value));
        data.destination &&
          dispatch(
            setDestinationCountry(data.destination?.slice(data.destination?.length - 3) || ''),
          );
      }
    } else {
      data.from && dispatch(setDepartureAerport(data.from.slice(data.from.length - 3)));
      data.destination &&
        dispatch(
          setDestinationCountry(data.destination?.slice(data.destination?.length - 3) || ''),
        );
    }
    setSwitched(false);
    dispatch(
      flightsData.isRoundFlight
        ? getInitialRoundedCalendarFlights({
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            departure:
              data.city?.from.value || data.from || flightsData.departureAerport || '',
            destination:
              data.city?.destination.value ||
              data.destination ||
              flightsData.destinationCountry ||
              '',
            pax,
          })
        : getCalendarFlights({
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            departure:
              data.city?.from.value || data.from || flightsData.departureAerport || '',
            destination:
              data.city?.destination.value ||
              data.destination ||
              flightsData.destinationCountry ||
              '',
            pax,
          }),
    );
  };

  useEffect(() => {
    const departureResult = formatDestinationAndDeparture({
      departure,
      destination,
    }).from.split(' ');
    const destinationResult = formatDestinationAndDeparture({
      departure,
      destination,
    }).destination.split(' ');

    dispatch(setDepartureAerport(departureResult[departureResult.length - 1]));
    dispatch(setDestinationCountry(destinationResult[destinationResult.length - 1]));

    setTopFilterData({
      ...topFilterData,
      from:
        departure && departureResult[departureResult.length - 1].length <= 3
          ? formatDestinationAndDeparture({
              departure,
              destination,
            }).from
          : '',
      destination:
        destination && destinationResult[destinationResult.length - 1].length <= 3
          ? formatDestinationAndDeparture({
              departure,
              destination,
            }).destination
          : '',
      city: {
        ...topFilterData.city,
        from:
          departure && departureResult[departureResult.length - 1].length > 3
            ? {
                value: formatDestinationAndDeparture({
                  departure,
                  destination,
                }).from,
                type: 'from',
              }
            : { value: '', type: '' },
        destination:
          destination && destinationResult[destinationResult.length - 1].length > 3
            ? {
                value: formatDestinationAndDeparture({
                  departure,
                  destination,
                }).destination,
                type: 'destination',
              }
            : { value: '', type: '' },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departure, destination, dispatch, formatDestinationAndDeparture]);

  const onSubmit = () => {
    dispatch(resetCalendar());
    history.push(
      `/flights?departure=${flightsData.departureAerport}&destination=${
        flightsData.destinationCountry
      }${departureDate ? `&start-date=${format(departureDate, 'yyyy-MM-dd')}` : ''}${
        returningDate ? `&returning-date=${format(returningDate, 'yyyy-MM-dd')}` : ''
      }${luggage ? `&luggage=${luggage}` : ''}${
        viaAirports ? `&via-airports=${viaAirports}` : ''
      }${pax || seats ? `&seats=${seats || pax}` : ''}${
        kids && Number(kids) > 0 ? `&kids=${kids}` : ''
      }${babies && Number(babies) > 0 ? `&babies=${babies}` : ''}`,
    );
  };
  const formMethods = useForm();
  const handleFlightTypeChange = useCallback(
    (isRoundFlight) => {
      dispatch(setFlightType(isRoundFlight));
      dispatch(
        isRoundFlight
          ? getInitialRoundedCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: topFilterData.from || '',
              destination: topFilterData.destination || '',
              pax,
            })
          : getCalendarFlights({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
              departure: flightsData.departureAerport || '',
              destination: flightsData.destinationCountry || '',
              pax,
            }),
      );
    },
    [dispatch, flightsData, pax, topFilterData],
  );

  return {
    onSubmit,
    switchInputs,
    extractDataFromAutoComplete,
    formMethods,
    topFilterData,
    switched,
    departure,
    destination,
    isRoundFlight: flightsData.isRoundFlight,
    handleFlightTypeChange,
    defaultDestinationAndDeparture: formatDestinationAndDeparture({
      departure: flightsData.departureAerport || departure,
      destination: flightsData.destinationCountry || destination,
    }),
  };
};
