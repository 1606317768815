import { IEntitiesTypes } from 'client/_redux/types/entitiesTypes';
import { CountriesTypes } from 'dashboard/_redux/types/countriesTypes';
import { ICountry } from 'server/models/HomePageCountry/types';
import { IReduxAction } from 'types/IReduxAction';

const initialState = {
  loading: true,
  countries: [],
  isPreloaded: false,
};

export interface IProductListInterface {
  countriesNames?: { name: string; code: string }[];
  countries: ICountry[];
  loading?: boolean;
  isPreloaded: boolean;
}

export default (
  state: IProductListInterface = initialState,
  { type, payload }: IReduxAction,
): IProductListInterface => {
  switch (type) {
    case IEntitiesTypes.GET_COUNTRIES_LIST_START:
      return { ...state, loading: true };
    case IEntitiesTypes.GET_COUNTRIES_LIST_FAILED:
      return { ...state, loading: false };
    case IEntitiesTypes.GET_COUNTRIES_LIST_SUCCESS:
      return { ...state, countriesNames: payload, loading: false };
    case CountriesTypes.GET_COUNTRIES_SUCCESS:
      return { ...state, countries: payload, loading: false };
    default:
      return state;
  }
};
