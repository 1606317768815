import { ExhibitedProductTypes } from 'client/_redux/types/exhibitedProductTypes';
import { ProductTypes } from 'client/_redux/types/productTypes';
import { ICatalogue } from 'server/models/Catalogue/types';
import { IExhibitedProduct } from 'server/models/ExhibitedProduct/types';
import { ICustomCatalogue } from 'types/ICatalogue';
import { IReduxAction } from 'types/IReduxAction';
import { ISelectorOption } from 'types/ISelectorOption';

const initialState = {
  loading: true,
  isPreloaded: false,
};

export interface IFormattedCataloguePricePerDurationAndFrom {
  _id: string;
  code: string;
  durationString: string;
  name: string;
  from: From;
  duration: number;
  prices: Price[];
}

export interface From {
  label: string;
  value: string;
}

export interface Price {
  _id: string;
  value: string | number;
  date: Date;
}
export interface IProductInterface {
  params?: {
    from?: string | undefined;
    'number-of-nights'?: string | undefined;
    permalink: string;
  };
  isPreloaded: boolean;
  product?: ICatalogue;
  customCatalogue?: ICustomCatalogue;
  products?: ICatalogue[]; // TODO: Make sure this is the right Type
  loading: boolean;
  cataloguePrices?: IFormattedCataloguePricePerDurationAndFrom;
  cataloguePricesAvailability?: { from: ISelectorOption; nights: ISelectorOption[] }[];
  exhibitedProduct?: IExhibitedProduct[];
}

export default (state = initialState, { type, payload }: IReduxAction): IProductInterface => {
  switch (type) {
    case ProductTypes.GET_PRODUCT_START:
    case ExhibitedProductTypes.GET__EXHIBITED_PRODUCTS_START:
      return { ...initialState };
    case ProductTypes.GET_PRODUCT_FAILED:
      return { ...state, loading: false };
    case ProductTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: payload.catalogue,
        customCatalogue: payload.customCatalogue,
        loading: false,
      };
    case ProductTypes.CLEAR_PRODUCT:
      return {
        ...state,
        product: undefined,
        customCatalogue: undefined,
        cataloguePricesAvailability: undefined,
        cataloguePrices: undefined,
      };
    case ProductTypes.FETCH_PRODUCTS_PRICES_SUCCESS:
      return { ...state, loading: false, cataloguePrices: payload };

    case ExhibitedProductTypes.GET__EXHIBITED_PRODUCTS_SUCCESS:
      return { ...state, loading: false, exhibitedProduct: payload };
    case ProductTypes.FETCH_PRODUCTS_PRICES_FAILED:
    case ExhibitedProductTypes.GET__EXHIBITED_PRODUCTS_FAILURE:
      return { ...state, loading: false };
    case ProductTypes.FETCH_PRODUCTS_PRICES_AVAILABILITY_SUCCESS:
      return { ...state, loading: false, cataloguePricesAvailability: payload };
    case ProductTypes.FETCH_PRODUCTS_PRICES_AVAILABILITY_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};
