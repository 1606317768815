import React from 'react';
import ContatctDetails from './ContactDetails';
import FormSelectFamilyOptions from './FormSelectFamilyOptions';
import HeaderStyle from './HeaderStyle';
import MainInformation from './MainInformation';
import AdditionalInformation from './AdditionalInformation';
import useReservationFormContainer from './useReservationFormContainer';
import SelectedAddonsFormOptions from './SelectedAddonsFormOptions';

const ReservationFormContainer: React.FC = () => {
  const {
    familyOptions,
    childCount,
    adultCount,
    selectedRooms,
    adultOptions,
    childOptions,
    selectedAddons,
  } = useReservationFormContainer();

  return (
    <>
      <HeaderStyle targetNumber={2} />
      <ContatctDetails />
      <div className="m-2 ">
        <MainInformation
          familyOptions={familyOptions}
          childCount={+childCount}
          adultCount={+adultCount}
        />
        <FormSelectFamilyOptions
          selectedRooms={selectedRooms}
          adultOptions={adultOptions}
          childOptions={childOptions}
        />

        <SelectedAddonsFormOptions
          selectedAddons={selectedAddons}
          familyOptions={familyOptions}
        />
      </div>
      <AdditionalInformation />
    </>
  );
};

export default ReservationFormContainer;
