import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import { useImagesSlider } from './useImagesSlider';
import LightboxGallery from '../LightboxGallery';
interface IProps {
  images?: string[];
  backgroundSize?: string;

  fullHight: string;

  thumbnailsHeight: string;
  currentIndex: number;
}
const ImageSlider: React.FC<IProps> = ({
  images,
  currentIndex,
  fullHight,
  thumbnailsHeight,
}) => {
  const {
    settingsMain,
    settingsThumbs,
    settingsThumbsMobile,
    slider1,
    slider2,
    nav1,
    nav2,
  } = useImagesSlider(currentIndex);

  return (
    <div>
      <Slider {...settingsMain} initialSlide={currentIndex} asNavFor={nav2} ref={slider1}>
        {images?.map((image, index) => (
          <div className="pt-1" key={image}>
            <LightboxGallery
              index={index}
              backgroundSize="contain"
              tabIndex={index}
              height={fullHight}
              imageUrl={image}
            />
          </div>
        ))}
      </Slider>
      <div className="thumbnail-slider-wrap">
        <ResponsiveWrapper>
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            initialSlide={currentIndex}
            ref={slider2}
          >
            {images?.map((image, index) => (
              <div key={image} className="pb-1 pl-2">
                <LightboxGallery
                  backgroundSize="cover"
                  index={index}
                  tabIndex={index}
                  height={thumbnailsHeight}
                  imageUrl={image}
                />
              </div>
            ))}
          </Slider>
        </ResponsiveWrapper>
        <ResponsiveWrapper isMobile>
          <Slider
            {...settingsThumbsMobile}
            asNavFor={nav1}
            initialSlide={currentIndex}
            ref={slider2}
          >
            {images?.map((image, index) => (
              <div key={image} className="pb-1 pl-2">
                <LightboxGallery
                  backgroundSize="cover"
                  index={index}
                  tabIndex={index}
                  height={thumbnailsHeight}
                  imageUrl={image}
                />
              </div>
            ))}
          </Slider>
        </ResponsiveWrapper>
      </div>
    </div>
  );
};

export default ImageSlider;
