import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFlightFinalForm } from 'types/IFlightData';

const FlightsReservationDetailsFooter = ({ flights }: { flights: IFlightFinalForm }) => {
  const price = flights.payment.DebitedAmount.toFixed(2);
  const [t] = useTranslation();

  return (
    <div className="reservation-details-card-footer">
      <div className="row justify-content-between my-3 ">
        <div className="col-sm-auto col-auto my-auto" />
        <div className="col-auto my-auto ">
          <h2 className="font-weight-bold">{t('reservationDetails.totalPaid')}</h2>
        </div>
        <div className="col-auto my-auto ml-auto">
          <h1 className="display-3 ">&euro; {price}</h1>
        </div>
      </div>
      <div className="row mb-3 mt-3 mt-md-0">
        <div className="col-auto border-line">
          <small className="text-white">BRAND:{flights.payment.Brand}</small>
        </div>
        <div className="col-auto border-line">
          <small className="text-white">CODE:{flights.payment.Ref} </small>
        </div>
        <div className="col-auto ">
          <small className="text-white">TYPE:{flights.payment.Type} </small>{' '}
        </div>
      </div>
    </div>
  );
};

export default FlightsReservationDetailsFooter;
