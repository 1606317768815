import AddAndUpdateFilterProductsForm from 'dashboard/containers/AddAndUpdateFilterProductsForm';
import React from 'react';
import useUpdateProductFilter from './useUpdateProductFilter';

const UpdateProductFilter = () => {
  const { id, filterToUpdate, onSubmit } = useUpdateProductFilter();

  if (!filterToUpdate) return null;

  return (
    <AddAndUpdateFilterProductsForm
      updateDefaultValue={filterToUpdate}
      id={id}
      onSubmit={onSubmit}
    />
  );
};

export default UpdateProductFilter;
