import React from 'react';
import VideoBackground from 'client/containers/VideoBackground';
import FilterHomePage from 'client/containers/FilterHomePage/index';
import { useTranslation } from 'react-i18next';
import ReinsuranceSection from 'client/components/ReinsuranceSection';
import SEO from 'client/components/SEO';
import { Helmet } from 'react-helmet';
import HomePageDescriptionSection from 'client/components/HomePageDescriptionSection';
import { Link } from 'react-router-dom';
import ProductCard from 'client/components/ProductCard';
import { getCompressedImageUrl } from 'client/helpers/getCompressedImageUrl';
import CountryCard from 'client/components/CountryCard';
import Slider from 'client/components/Slider';
import { useHome } from './useHome';
import Masonry from './Masonry';

const HomePage: React.FC = () => {
  const [t] = useTranslation();
  const { countries, homeProducts, promotion } = useHome();

  return (
    <main style={{ marginTop: '-55px' }}>
      <SEO
        title="Kepler Travel | L'ÉVASION"
        description="Le Tour Opérateur KEPLER, spécialiste des circuits, voyages à 
        thèmes, week-end et séjours balnéaires sur plusieurs destinations.
        "
      />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="crossOrigin" />
        <link
          href="https://fonts.googleapis.com/css2?family=Ephesis&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <VideoBackground />
      <section className="video-bg-section">
        <video autoPlay loop muted>
          <source src="/assets/videos/Ocean-Background-short.mp4" type="video/mp4" />
        </video>
        <FilterHomePage />
      </section>
      <div data-testid="homePageCardContainer" className="space-bottom-2">
        <div className="container">
          <p
            style={{ font: 'normal 60px/35px "Ephesis"' }}
            className="text-center space-1 ml-1 text-secondary "
          >
            {t('homePageSections.Countries')}
          </p>
        </div>
        <div className="container">
          <Masonry>
            {countries?.map((country) => (
              <CountryCard
                key={country._id}
                title={country.title}
                subtitle={country.subtitle}
                image={country.countryImage}
              />
            ))}
          </Masonry>
        </div>
      </div>
      <img className="home-logo-bg" src="/assets/svg/logos/logo-black.svg" alt="kepler" />
      {promotion && <HomePageDescriptionSection {...promotion} />}
      <div className="space-bottom-2 space-top-2">
        <div data-testid="homePageCardContainer">
          <div className="container">
            <p
              style={{ font: 'normal 60px/35px "Ephesis"' }}
              className="text-center space-1 ml-1 text-secondary"
            >
              {t('homePageSections.Discounts')}
            </p>
          </div>
          <div className="container container-xxl no-gutters m-auto ">
            <Slider autoPlay autoplaySpeed={4000}>
              {homeProducts?.map((product) => (
                <Link className="p-2" key={product._$id} to={`/product/${product.permalink}`}>
                  <ProductCard
                    title={product.name}
                    image={getCompressedImageUrl(product.media)}
                    price={product.price}
                    badgeColor="success"
                    badgeText="Disponible"
                    stars={product.rating ? Number(product.rating) : undefined}
                  />
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <ReinsuranceSection />
    </main>
  );
};

export default HomePage;
