export default {
  accepted: `Le champ doit être accepté.`,
  after_day: `Le champ doit être après :date.`,
  before_day: `Le champ doit être avant :date.`,
  after: `Le champ doit être après :date.`,
  after_or_equal: `Le champ doit correspondre ou bien être après :date.`,
  alpha: `Le champ ne peut contenir que des lettres.`,
  alpha_space: `Le champ ne peut contenir que des lettres et des espaces.`,
  alpha_num: `Le champ ne peut contenir que des lettres et des chiffres.`,
  alpha_num_space: `Le champ ne peut contenir que des lettres, chiffres, et espaces.`,
  alpha_num_dash: `Le champ ne peut contenir que des lettres, chiffres, et tirets.`,
  alpha_num_dash_space: `Le champ ne peut contenir que des lettres, chiffres, tirets, et espaces.`,
  array: `Le champ doit êttre un tableau.`,
  before: `Le champ doit être avant :date.`,
  before_or_equal: `Le champ doit correspondre ou bien être avant  :date.`,
  between: `Le champ doit être entre :min et :max.`,
  boolean: `Le champ doit être booléen.`,
  card_exp: `Le champ doit être une date d'expiration valide.`,
  card_num: `Le champ doit être un numéro valide de carte de crédit .`,
  currency: `Le champ doit être une devise valide.`,
  date: `Le champ doit être une date.`,
  date_equals: `Le champ doit correspondre à :date.`,
  email: `Le champ doit êre une adresse email valide.`,
  in: `Le champ selectionné doit être :values.`,
  integer: `Le champ doit être un entier.`,
  max: `Le champ ne doit pas dépasser :max.`,
  min: `Le champ doit au moins être :min.`,
  not_in: `Le champ selectionné ne doit pas être :values.`,
  not_regex: `Le champ ne doit pas correspondre au motif requis.`,
  numeric: `Le champ doit être un chiffre.`,
  phone: `Le champ doit être un numéro de téléphone valide.`,
  regex: `Le champ doit correspondre au motif requis.`,
  required: `Le champ est requis.`,
  size: `Le champ doit être :size.`,
  string: `Le champ doit être une chaîne.`,
  typeof: `Le champ n'est pas le type correcte de .`,
  url: `Le champ doit être un url.`,
  passwordConfirm: 'Veuillez entrer le même mot de passe',
};
export type IValidator =
  | 'accepted'
  | 'after'
  | 'before_day'
  | 'after_day'
  | 'after_or_equal'
  | 'alpha'
  | 'alpha_space'
  | 'alpha_num'
  | 'alpha_num_space'
  | 'alpha_num_dash'
  | 'alpha_num_dash_space'
  | 'before'
  | 'before_or_equal'
  | 'between'
  | 'boolean'
  | 'date'
  | 'email'
  | 'in'
  | 'integer'
  | 'max'
  | 'min'
  | 'maxLength'
  | 'minLength'
  | 'numeric'
  | 'phone'
  | 'required'
  | 'string'
  | 'url'
  | 'passwordConfirm';
