import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

export const useImagesSlider = (currentIndex: number) => {
  const slider1 = useRef<Slider>(null);
  const slider2 = useRef<Slider>(null);
  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();

  useEffect(() => {
    if (slider1.current) setNav1(slider1.current);
    if (slider2.current) setNav2(slider2.current);
  }, []);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
  };

  const settingsThumbs = {
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
  };
  const settingsThumbsMobile = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
  };

  return { settingsMain, settingsThumbs, slider1, slider2, nav1, nav2, settingsThumbsMobile };
};
