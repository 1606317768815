import FormSelect from 'client/components/Form/FormSelect';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { formCivilityOptions } from 'client/constants/formCivilityOptions';
import useReservationFormContainer from './useReservationFormContainer';

const ContatctDetails = () => {
  const { t, countryList } = useReservationFormContainer();

  return (
    <div className="d-flex flex-column flex-md-row my-3 border m-2">
      <div className="col-12 col-md-6 d-flex flex-column justify-content-center flex-start">
        <h2 className=" m-2 py-2 text-success">
          {t('room.formReservation.YourContactDetails')}
        </h2>
        <div className="d-flex flex-row align-items-center w-100 flex-start">
          <Input
            name="agent.firstName"
            type="text"
            label={`${t('room.formReservation.firstName')} (*)`}
            placeholder={t('room.formReservation.firstName')}
            className="mr-3 py-0 w-100 "
            validate={Validators([
              { validation: 'required' },
              { minParams: 3, validation: 'minLength' },
            ])}
            defaultValue=""
            labelClassName="w-100 d-flex flex-start"
          />
          <FormSelect
            label={`${t('room.formReservation.civility')} (*)`}
            name="agent.civility"
            options={formCivilityOptions}
            validate={Validators([{ validation: 'required' }])}
            initialValue={[{ label: '', value: '' }]}
            selectClassName="w-100"
          />
        </div>
        <Input
          name="agent.lastName"
          type="text"
          label={`${t('room.formReservation.lastName')} (*)`}
          placeholder={`${t('room.formReservation.lastName')}`}
          validate={Validators([
            { validation: 'required' },
            { minParams: 3, validation: 'minLength' },
          ])}
          defaultValue=""
          labelClassName="w-100 d-flex flex-start"
        />
        <Input
          name="agent.phoneNumber1"
          type="tel"
          label={`${t('room.formReservation.phoneNumber1')} (*)`}
          placeholder="123-4567-8901"
          validate={Validators([
            { validation: 'required' },
            { minParams: 8, validation: 'minLength' },
          ])}
          defaultValue=""
        />
        <Input
          name="agent.phoneNumber2"
          type="tel"
          label={`${t('room.formReservation.phoneNumber2')}`}
          placeholder="123-4567-8901"
          defaultValue=""
          labelClassName="w-100 d-flex flex-start"
        />
        <Input
          name="agent.email"
          type="email"
          label={`${t('room.formReservation.email')} (*)`}
          placeholder={`${t('room.formReservation.email')}`}
          validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
          defaultValue=""
          labelClassName="w-100 d-flex flex-start"
        />
      </div>
      <div className="col-12 col-md-6 d-flex flex-column border-left justify-content-center flex-start">
        <Input
          name="agent.adress"
          type="text"
          label={`${t('room.formReservation.adress')} (*)`}
          placeholder={`${t('room.formReservation.adress')}`}
          validate={Validators([{ validation: 'required' }])}
          defaultValue=""
          labelClassName="w-100 d-flex flex-start"
        />
        <div className="d-flex flex-row ">
          <Input
            name="agent.city"
            type="text"
            label={`${t('room.formReservation.city')} (*)`}
            placeholder={`${t('room.formReservation.city')}`}
            validate={Validators([{ validation: 'required' }])}
            className="mr-2 w-100"
            defaultValue=""
            labelClassName="w-100 d-flex flex-start"
          />
          <Input
            name="agent.postalcode"
            type="text"
            label={`${t('room.formReservation.postalcode')} (*)`}
            placeholder={`${t('room.formReservation.postalcode')} `}
            validate={Validators([{ validation: 'required' }])}
            className="ml-2"
            defaultValue=""
            labelClassName="w-100 d-flex flex-start"
          />
        </div>
        <FormSelect
          label={`${t('room.formReservation.nationality')} (*)`}
          name="agent.nationality"
          options={countryList}
          isSearchable
          validate={Validators([{ validation: 'required' }])}
          initialValue={[{ label: '', value: '' }]}
        />
      </div>
    </div>
  );
};

export default ContatctDetails;
