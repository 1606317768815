/* eslint-disable max-lines */
import ImageCarousel from 'client/components/ImageCarousel';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import ResponsiveWrapper from 'client/components/ResponsiveWrapper';
import { ICustomCatalogue, ISearchQueryCatalogue } from 'types/ICatalogue';
import { formatDate } from 'client/helpers/format/formatDate';
import Loader from './Loader';

interface IProps {
  fromQuery: string | Record<string, string | null> | null;
  loading?: boolean;
  product: ICustomCatalogue;
  hiddenPrice?: boolean;
  fromsQuery: ISearchQueryCatalogue;
  icons?: { title: string; icon: string }[];
}
export const dataIcon = [
  { icon: '/assets/svg/iconnotec/clock.svg', title: 'Time' },
  { icon: '/assets/svg/iconnotec/dish.svg', title: 'Lunch' },
  { icon: '/assets/svg/iconnotec/hotel.svg', title: 'Paris' },
  { icon: '/assets/svg/iconnotec/minigolf.svg', title: 'Fun' },
];
export const mainDescriptio = [{ text: 'piscine' }, { text: 'beach' }, { text: 'club' }];
const ProductListCardContainer: React.FC<IProps> = ({
  fromQuery,
  fromsQuery,
  product: {
    media,
    city,
    country,
    bestPrice,
    productType,
    keywords,
    permalink,
    mainDescription,
    name,
    icons = dataIcon,
    shortDescription,
    ...product
  },
  hiddenPrice,
  loading,
}) => {
  const urlProductPage = `/product/${permalink}?from=${
    (bestPrice &&
      !hiddenPrice &&
      // @ts-expect-error this is not typed
      bestPrice.bestPrice._$From !== 'undefined' &&
      // @ts-expect-error this is not typed
      bestPrice.bestPrice._$From) ||
    'XXX'
  }&number-of-nights=${bestPrice.nights}&at=${formatDate(bestPrice.startsAt)}`;

  const [t] = useTranslation();

  let price = 0;
  let valeurDiscount = 0;
  const valeurTax = bestPrice.taxes.Tax[0]._$Value;
  const chaineTax = valeurTax.toString(); // Convertir en chaîne de caractères
  const resultatTax = `${chaineTax.slice(0, -2)}.${chaineTax.slice(-2)}`;

  Array.isArray(bestPrice.discounts.Discount)
    ? (valeurDiscount = bestPrice.discounts.Discount[1]._$Value)
    : 0;
  const chaineDiscount = valeurDiscount.toString();
  const resultatTDiscount = `${chaineDiscount.slice(0, -2)}.${chaineDiscount.slice(-2)}`;

  bestPrice.discounts.Discount.length !== undefined
    ? (price = bestPrice.value - Number(resultatTax) + Number(resultatTDiscount))
    : (price = bestPrice.value - Number(resultatTax));

  const aeroports = [
    {
      libele: 'LYS',
      value: 'Lyon',
    },
    {
      libele: 'MRS',
      value: 'Marseille',
    },
    {
      libele: 'BOD',
      value: 'Bordeaux',
    },
    {
      libele: 'PAR',
      value: 'Paris',
    },
    {
      libele: 'NTE',
      value: 'Nantes',
    },
    {
      libele: 'GVA',
      value: 'Geneve',
    },
    {
      libele: 'CDG',
      value: 'Paris',
    },
    {
      libele: 'BRU',
      value: 'Bruxelles',
    },
    {
      libele: 'NCE',
      value: 'Nice',
    },
    {
      libele: 'SXB',
      value: 'Strasbourg',
    },
  ];

  const arrayFrom = aeroports.filter((aeroport) =>
    aeroport.libele === bestPrice.bestPrice._$From ? aeroport.value : aeroport.libele,
  );

  // console.log(
  //   '%csrcclientcontainersProductListCardContainerindex.tsx:58 productType',
  //   'color: #007acc;',
  //   arrayFrom.map((item) => item.value),
  // );

  return (
    <div data-testid="CardForListingProductsPage " className="shadow-serious rounded">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row flex-column-reverse flex-md-row justify-content-md-between justify-content-end no-gutters mx-5">
            <div data-testid="name" className="my-md-3 ">
              <div style={{ fontSize: '20px', fontWeight: 500, width: '100%' }}>
                <Link className="" to={urlProductPage}>
                  {upperCaseFirstLetter(name || '')}
                </Link>
              </div>
            </div>

            <ResponsiveWrapper>
              <div
                data-testid="location"
                className="border-left border-2 my-2 align-content-center text-right pl-8  "
              >
                <Link
                  className="h4 text-body font-weight-light"
                  to={`/products?sort=asc&country=${country}`}
                >
                  {country?.toUpperCase()}
                </Link>
                <Link
                  className="h4 text-body font-weight-light"
                  to={`/products?sort=asc&country=${country}&city=${city}`}
                >
                  <span className="d-block font-weight-bold">{city?.toUpperCase()}</span>
                </Link>
              </div>
            </ResponsiveWrapper>
            <ResponsiveWrapper isMobile>
              <div data-testid="location" className="mt-3 align-content-start ">
                <Link
                  className="h4 text-body font-weight-light"
                  to={`/products?sort=asc&country=${country}`}
                >
                  {upperCaseFirstLetter(country || '')}
                </Link>
                {' - '}
                <Link
                  className="h4 text-body font-weight-light"
                  to={`/products?sort=asc&country=${country}&city=${city}`}
                >
                  <span>{upperCaseFirstLetter(city || '')}</span>
                </Link>
              </div>
            </ResponsiveWrapper>
          </div>
          <div className="row border-top border-2 no-gutters">
            <div
              style={{ width: 'calc(100% - 340px)', minWidth: 'auto' }}
              className="col-12 col-md-6 mb-md-auto mb-3 align-items-md-start"
            >
              <Link to={urlProductPage}>
                <ImageCarousel media={media} />
              </Link>

              <div data-testid="productType">
                {productType && (
                  <div className="position-absolute top-0 left-1 p-3">
                    <span className="badge badge-success badge-pill">{productType}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6 mt-1 mt-md-3 px-1">
              <div className="col">
                {bestPrice && !hiddenPrice && (
                  <div className="d-flex flex-column mb-2 justify-content-start ">
                    <div className="my-1" style={{ fontSize: '13px', marginTop: '10px' }}>
                      Prochain départ à ce tarif :
                    </div>
                    <strong>
                      {/* {' '}
                      de {country.toUpperCase()} {city} */}
                      {arrayFrom.map((item) =>
                        bestPrice.bestPrice._$From === item.libele ? item.value : '',
                      )}
                    </strong>
                    <strong>pour {bestPrice.nights} nuitées,</strong>
                    <strong> le {formatDate(bestPrice.startsAt)}</strong>
                    <h6 className="mr-2 mt-auto mb-auto">{t('productCard.APartirDe')}</h6>
                    <div className="d-flex flex-row">
                      <div data-testid="price" className="h2 mt-auto mb-auto ">
                        <Link to={urlProductPage} className="text-secondary">
                          {Math.round(price + Number(resultatTax))}

                          <span className="font-weight-light">€</span>
                        </Link>
                      </div>

                      <div
                        className="mt-2 mb-auto ml-1 ttc_font_size text-secondary"
                        style={{
                          fontSize: '12px',
                          lineHeight: '10px',
                          position: 'relative',
                        }}
                      >
                        ttc/
                        <span className="d-block font-weight-bold text-secondary">pers</span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="h3">{upperCaseFirstLetter(shortDescription || '')}</div>
                <div>
                  <ul>
                    {mainDescription?.map((e, i) => (
                      <li key={`${e.text}${i + 1} `}>{e.text}</li>
                    ))}
                  </ul>
                </div>
                <div
                  style={{ maxWidth: '420px' }}
                  className="d-flex justify-content-center flex-wrap mx-auto"
                >
                  {(icons[0] === undefined && icons.length === 0 ? dataIcon : icons).map(
                    (e, i) => (
                      <div
                        key={`${e.title}${i + 1}`}
                        className={`${
                          i !== icons.length - 1 && 'border-right border-3'
                        } px-3 py-2 text-center`}
                      >
                        <img width="auto" height="20px" src={e.icon} alt="grat" />
                        <small className="d-block" style={{ fontSize: '65%' }}>
                          {e.title}
                        </small>
                      </div>
                    ),
                  )}
                </div>
                <div data-testid="keywords" className="mb-3 mt-5">
                  {keywords && (
                    <ul className="list-inline list-separator font-size-1 mb-3">
                      {Array.isArray(keywords) &&
                        keywords?.map(({ title, id }, index) => (
                          <li key={`${id}  ${title}`} className="list-inline-item">
                            <Link to={urlProductPage}>#{title}</Link>
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductListCardContainer;
