import React, { Children } from 'react';

const Masonry: React.FC = ({ children }) => {
  const childArray = Children.toArray(children);
  const topRow = childArray.slice(0, 2);
  const bottomRow = childArray.slice(2, 5);

  return (
    <>
      <div className="row">
        {topRow.map((child) => (
          <div className="no-gutters px-0 col-12 col-md-6">{child}</div>
        ))}
        {bottomRow.map((child) => (
          <div className="no-gutters px-0 col-12 col-md-4">{child}</div>
        ))}
      </div>
    </>
  );
};

export default Masonry;
