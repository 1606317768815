import SEO from 'client/components/SEO';
import React from 'react';
import { usePaymentError } from './usePaymentError';

const PaymentError = () => {
  const { isError, t } = usePaymentError();

  return (
    <>
      <SEO
        title="Payment Error page"
        description="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolores numquam est ullam"
        // eslint-disable-next-line max-len
        image="https://images.unsplash.com/photo-1578328819058-b69f3a3b0f6b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
      />
      <div className="container vh-100 ">
        <div className="row h-100">
          <div className="col-md-6 my-auto mx-auto mt-5">
            <div className={`${isError ? 'payment-error' : 'payment-abort'}`}>
              <div className={`${isError ? 'payment-error-header' : 'payment-abort-header'}`}>
                <div className="check">
                  <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                </div>
              </div>
              <div className="content">
                <h1>{`${
                  isError ? t('paymentError.errorTitle') : t('paymentAbort.errorTitle')
                }`}</h1>
                <p>{`${
                  isError ? t('paymentError.errorBody') : t('paymentAbort.errorBody')
                }`}</p>
                <a href="/">Go to Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentError;
