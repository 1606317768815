import FlightDetailsContainer from 'client/containers/FlightDetailsContainer';
import RoomDetails from 'client/components/RoomDetails';
import PriceDetailsList from 'client/containers/QuotedAvailability/PriceDetails';
import ProductAddons from 'client/containers/QuotedAvailability/ProductAddons';
import React from 'react';
import { Link } from 'react-router-dom';
import HeaderStyle from 'client/containers/ReservationFormContainer/HeaderStyle';
import SEO from 'client/components/SEO';
import { useQuotedAvailability } from './useQuotedAvailability';
import {} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import ProductSummary from './ProductSummary';
const QuotedAvailability = () => {
  const {
    onSubmit,
    title,
    adultCount,
    childCount,
    startDate,
    endDate,
    loading,
    alternatives,
    recommended,
    addons,
    supplement,
    t,
    permalink,
    tripTransportation,
  } = useQuotedAvailability();

  return (
    <>
      <SEO
        title="Page de Réservation"
        // eslint-disable-next-line max-len
        image="https://images.unsplash.com/photo-1505577058444-a3dab90d4253?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80"
      />
      <div className="container container-xxl">
        <HeaderStyle targetNumber={1} />

        <div className=" space-bottom-2 d-flex flex-md-row flex-column">
          <div className="container col-md-8 col-sm-12 border rounded border-fuchsia">
            <RoomDetails
              onSubmit={onSubmit}
              loading={loading}
              recommended={recommended}
              alternatives={alternatives}
            />
            {tripTransportation && (
              <>
                <h2>{`${tripTransportation ? '3' : ''}${t(
                  'quotedAvailabilityPage.3.Transport',
                )}`}</h2>
                <FlightDetailsContainer />
              </>
            )}
            {supplement && (
              <>
                <h2 className="pt-3">{`${tripTransportation ? '4' : '3'}${t(
                  'quotedAvailabilityPage.4.mandatorySupplement',
                )}`}</h2>

                <div className="container pt-3 d-flex flex-row flex-wrap justify-content-between">
                  <h4>{supplement.description}</h4>
                  <h4>
                    {supplement.totalPrice.toLocaleString('fr-FR', {
                      style: 'currency',
                      currency: supplement.currency,
                    })}
                  </h4>
                </div>
              </>
            )}
            {addons && addons?.length !== 0 && (
              <>
                <h2 className="pt-3">
                  {`${tripTransportation ? '5' : '4'}${t(
                    'quotedAvailabilityPage.5.optionelServices',
                  )}`}{' '}
                </h2>
                <ProductAddons productAddons={addons} />
              </>
            )}
          </div>
          <div className="col-md-4 col-sm-12">
            {recommended && (
              <>
                <PriceDetailsList />
                <div className="d-flex w-100 justify-content-center py-3 ">
                  <Link
                    to={`/product/${permalink}/quotation/reservation-Form`}
                    className="btn btn-success w-100"
                  >
                    {t('roomReserve.reserver')}
                  </Link>
                </div>
                <ProductSummary {...{ adultCount, title, childCount, startDate, endDate }} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotedAvailability;
