/* eslint-disable max-lines */
export const ICON_LIST_OPTIONS = [
  {
    label: 'adult-only',
    value: '/assets/svg/iconnotec/adult-only.svg',
    icon: '/assets/svg/iconnotec/adult-only.svg',
  },
  {
    label: '4x4',
    value: '/assets/svg/iconnotec/4x4.svg',
    icon: '/assets/svg/iconnotec/4x4.svg',
  },
  {
    label: 'air-conditioning',
    value: '/assets/svg/iconnotec/air-conditioning.svg',
    icon: '/assets/svg/iconnotec/air-conditioning.svg',
  },
  {
    label: 'atm-machine',
    value: '/assets/svg/iconnotec/atm-machine.svg',
    icon: '/assets/svg/iconnotec/atm-machine.svg',
  },
  {
    label: 'bathtub',
    value: '/assets/svg/iconnotec/bathtub.svg',
    icon: '/assets/svg/iconnotec/bathtub.svg',
  },
  {
    label: 'beach',
    value: '/assets/svg/iconnotec/beach.svg',
    icon: '/assets/svg/iconnotec/beach.svg',
  },
  {
    label: 'bed',
    value: '/assets/svg/iconnotec/bed.svg',
    icon: '/assets/svg/iconnotec/bed.svg',
  },
  {
    label: 'bicycle',
    value: '/assets/svg/iconnotec/bicycle.svg',
    icon: '/assets/svg/iconnotec/bicycle.svg',
  },
  {
    label: 'billiard',
    value: '/assets/svg/iconnotec/billiard.svg',
    icon: '/assets/svg/iconnotec/billiard.svg',
  },
  {
    label: 'calendar',
    value: '/assets/svg/iconnotec/calendar.svg',
    icon: '/assets/svg/iconnotec/calendar.svg',
  },
  {
    label: 'car-rental',
    value: '/assets/svg/iconnotec/car-rental.svg',
    icon: '/assets/svg/iconnotec/car-rental.svg',
  },
  {
    label: 'certification',
    value: '/assets/svg/iconnotec/certification.svg',
    icon: '/assets/svg/iconnotec/certification.svg',
  },
  {
    label: 'checklist',
    value: '/assets/svg/iconnotec/checklist.svg',
    icon: '/assets/svg/iconnotec/checklist.svg',
  },
  {
    label: 'clock',
    value: '/assets/svg/iconnotec/clock.svg',
    icon: '/assets/svg/iconnotec/clock.svg',
  },
  {
    label: 'club',
    value: '/assets/svg/iconnotec/club.svg',
    icon: '/assets/svg/iconnotec/club.svg',
  },
  {
    label: 'cocktail',
    value: '/assets/svg/iconnotec/cocktail.svg',
    icon: '/assets/svg/iconnotec/cocktail.svg',
  },
  {
    label: 'coffee-cup',
    value: '/assets/svg/iconnotec/coffee-cup.svg',
    icon: '/assets/svg/iconnotec/coffee-cup.svg',
  },
  {
    label: 'concierge',
    value: '/assets/svg/iconnotec/concierge.svg',
    icon: '/assets/svg/iconnotec/concierge.svg',
  },
  {
    label: 'disability',
    value: '/assets/svg/iconnotec/disability.svg',
    icon: '/assets/svg/iconnotec/disability.svg',
  },
  {
    label: 'dish',
    value: '/assets/svg/iconnotec/dish.svg',
    icon: '/assets/svg/iconnotec/dish.svg',
  },
  {
    label: 'doctor',
    value: '/assets/svg/iconnotec/doctor.svg',
    icon: '/assets/svg/iconnotec/doctor.svg',
  },
  {
    label: 'fashion',
    value: '/assets/svg/iconnotec/fashion.svg',
    icon: '/assets/svg/iconnotec/fashion.svg',
  },
  {
    label: 'food-safety',
    value: '/assets/svg/iconnotec/food-safety.svg',
    icon: '/assets/svg/iconnotec/food-safety.svg',
  },
  {
    label: 'food-service',
    value: '/assets/svg/iconnotec/food-service.svg',
    icon: '/assets/svg/iconnotec/food-service.svg',
  },
  {
    label: 'gate-of-india',
    value: '/assets/svg/iconnotec/gate-of-india.svg',
    icon: '/assets/svg/iconnotec/gate-of-india.svg',
  },
  {
    label: 'guide',
    value: '/assets/svg/iconnotec/guide.svg',
    icon: '/assets/svg/iconnotec/guide.svg',
  },
  {
    label: 'guided-tours',
    value: '/assets/svg/iconnotec/guided-tours.svg',
    icon: '/assets/svg/iconnotec/guided-tours.svg',
  },
  {
    label: 'hair-dryer',
    value: '/assets/svg/iconnotec/hair-dryer.svg',
    icon: '/assets/svg/iconnotec/hair-dryer.svg',
  },
  {
    label: 'heater',
    value: '/assets/svg/iconnotec/heater.svg',
    icon: '/assets/svg/iconnotec/heater.svg',
  },
  {
    label: 'hiking',
    value: '/assets/svg/iconnotec/hiking.svg',
    icon: '/assets/svg/iconnotec/hiking.svg',
  },
  {
    label: 'horse-saddle',
    value: '/assets/svg/iconnotec/horse-saddle.svg',
    icon: '/assets/svg/iconnotec/horse-saddle.svg',
  },
  {
    label: 'hotel',
    value: '/assets/svg/iconnotec/hotel.svg',
    icon: '/assets/svg/iconnotec/hotel.svg',
  },
  {
    label: 'iron',
    value: '/assets/svg/iconnotec/iron.svg',
    icon: '/assets/svg/iconnotec/iron.svg',
  },
  {
    label: 'luggage',
    value: '/assets/svg/iconnotec/luggage.svg',
    icon: '/assets/svg/iconnotec/luggage.svg',
  },
  {
    label: 'minigolf',
    value: '/assets/svg/iconnotec/minigolf.svg',
    icon: '/assets/svg/iconnotec/minigolf.svg',
  },
  {
    label: 'monumental',
    value: '/assets/svg/iconnotec/monumental.svg',
    icon: '/assets/svg/iconnotec/monumental.svg',
  },
  {
    label: 'necklace',
    value: '/assets/svg/iconnotec/necklace.svg',
    icon: '/assets/svg/iconnotec/necklace.svg',
  },
  {
    label: 'no-alcohol',
    value: '/assets/svg/iconnotec/no-alcohol.svg',
    icon: '/assets/svg/iconnotec/no-alcohol.svg',
  },
  {
    label: 'no-pets',
    value: '/assets/svg/iconnotec/no-pets.svg',
    icon: '/assets/svg/iconnotec/no-pets.svg',
  },
  {
    label: 'no-smoking',
    value: '/assets/svg/iconnotec/no-smoking.svg',
    icon: '/assets/svg/iconnotec/no-smoking.svg',
  },
  {
    label: 'parking',
    value: '/assets/svg/iconnotec/parking.svg',
    icon: '/assets/svg/iconnotec/parking.svg',
  },
  {
    label: 'ping-pong',
    value: '/assets/svg/iconnotec/ping-pong.svg',
    icon: '/assets/svg/iconnotec/ping-pong.svg',
  },
  {
    label: 'placeholder',
    value: '/assets/svg/iconnotec/placeholder.svg',
    icon: '/assets/svg/iconnotec/placeholder.svg',
  },
  {
    label: 'pool',
    value: '/assets/svg/iconnotec/pool.svg',
    icon: '/assets/svg/iconnotec/pool.svg',
  },
  {
    label: 'pottery',
    value: '/assets/svg/iconnotec/pottery.svg',
    icon: '/assets/svg/iconnotec/pottery.svg',
  },
  {
    label: 'pyramid',
    value: '/assets/svg/iconnotec/pyramid.svg',
    icon: '/assets/svg/iconnotec/pyramid.svg',
  },
  {
    label: 'reception',
    value: '/assets/svg/iconnotec/reception.svg',
    icon: '/assets/svg/iconnotec/reception.svg',
  },
  {
    label: 'relax',
    value: '/assets/svg/iconnotec/relax.svg',
    icon: '/assets/svg/iconnotec/relax.svg',
  },
  {
    label: 'room-service-1',
    value: '/assets/svg/iconnotec/room-service-1.svg',
    icon: '/assets/svg/iconnotec/room-service-1.svg',
  },
  {
    label: 'room-service',
    value: '/assets/svg/iconnotec/room-service.svg',
    icon: '/assets/svg/iconnotec/room-service.svg',
  },
  {
    label: 'sauna-1',
    value: '/assets/svg/iconnotec/sauna-1.svg',
    icon: '/assets/svg/iconnotec/sauna-1.svg',
  },
  {
    label: 'sauna',
    value: '/assets/svg/iconnotec/sauna.svg',
    icon: '/assets/svg/iconnotec/sauna.svg',
  },
  {
    label: 'scuba-diving',
    value: '/assets/svg/iconnotec/scuba-diving.svg',
    icon: '/assets/svg/iconnotec/scuba-diving.svg',
  },
  {
    label: 'shoe-shop',
    value: '/assets/svg/iconnotec/shoe-shop.svg',
    icon: '/assets/svg/iconnotec/shoe-shop.svg',
  },
  {
    label: 'shower',
    value: '/assets/svg/iconnotec/shower.svg',
    icon: '/assets/svg/iconnotec/shower.svg',
  },
  {
    label: 'slippers',
    value: '/assets/svg/iconnotec/slippers.svg',
    icon: '/assets/svg/iconnotec/slippers.svg',
  },
  {
    label: 'smoking',
    value: '/assets/svg/iconnotec/smoking.svg',
    icon: '/assets/svg/iconnotec/smoking.svg',
  },
  {
    label: 'snooker',
    value: '/assets/svg/iconnotec/snooker.svg',
    icon: '/assets/svg/iconnotec/snooker.svg',
  },
  {
    label: 'spa-1',
    value: '/assets/svg/iconnotec/spa-1.svg',
    icon: '/assets/svg/iconnotec/spa-1.svg',
  },
  {
    label: 'spa-2',
    value: '/assets/svg/iconnotec/spa-2.svg',
    icon: '/assets/svg/iconnotec/spa-2.svg',
  },
  {
    label: 'spa',
    value: '/assets/svg/iconnotec/spa.svg',
    icon: '/assets/svg/iconnotec/spa.svg',
  },
  {
    label: 'spray',
    value: '/assets/svg/iconnotec/spray.svg',
    icon: '/assets/svg/iconnotec/spray.svg',
  },
  {
    label: 'street-sign',
    value: '/assets/svg/iconnotec/street-sign.svg',
    icon: '/assets/svg/iconnotec/street-sign.svg',
  },
  {
    label: 'sunbed',
    value: '/assets/svg/iconnotec/sunbed.svg',
    icon: '/assets/svg/iconnotec/sunbed.svg',
  },
  {
    label: 'surfing',
    value: '/assets/svg/iconnotec/surfing.svg',
    icon: '/assets/svg/iconnotec/surfing.svg',
  },
  {
    label: 'towel-1',
    value: '/assets/svg/iconnotec/towel-1.svg',
    icon: '/assets/svg/iconnotec/towel-1.svg',
  },
  {
    label: 'towel',
    value: '/assets/svg/iconnotec/towel.svg',
    icon: '/assets/svg/iconnotec/towel.svg',
  },
  {
    label: 'washing-machine',
    value: '/assets/svg/iconnotec/washing-machine.svg',
    icon: '/assets/svg/iconnotec/washing-machine.svg',
  },
  {
    label: 'weight',
    value: '/assets/svg/iconnotec/weight.svg',
    icon: '/assets/svg/iconnotec/weight.svg',
  },
  {
    label: 'wifi-signal',
    value: '/assets/svg/iconnotec/wifi-signal.svg',
    icon: '/assets/svg/iconnotec/wifi-signal.svg',
  },
];
