import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormattedRoom,
  GroupedRooms,
} from 'server/helpers/soap/formatQuotedAvailabilitySoapResponse/types';
import RoomSelectorInput from './RoomSelectorInput';

interface IProps {
  handleRoomSelected: (room: FormattedRoom, index: number) => () => void;
  selectedPlaceSection: GroupedRooms;
}

const RoomsAndAlternativeRooms: React.FC<IProps> = ({
  selectedPlaceSection,
  handleRoomSelected,
}) => {
  const [t] = useTranslation();

  return (
    <>
      {selectedPlaceSection.rooms.map((room, index) => (
        <div
          className=" d-flex flex-column border rounded align-items-start m-0 mt-2"
          key={`${room.description}${index + 1}`}
        >
          <RoomSelectorInput
            defaultChecked
            name={`alternativeRooms-${index}`}
            id={`alternativeRooms-${index}-0`}
            htmlFor={`alternativeRooms-${index}-0`}
            room={room}
            priceDetails={`${t('room.inclus')}`}
            onChange={handleRoomSelected(room, index)}
          />

          {room.alternativeRooms.map((_room, i) => (
            <RoomSelectorInput
              key={`${room.description}${room.roomCode}${i + 1}`}
              name={`alternativeRooms-${index}`}
              id={`alternativeRooms-${index}-${i + 1}`}
              htmlFor={`alternativeRooms-${index}-${i + 1}`}
              room={_room}
              priceDetails={`${t('room.extraCharge')} ${_room.priceDifference.toFixed(2)}
       ${t('currency01.EURO')}`}
              onChange={handleRoomSelected(_room, index)}
            />
          ))}
        </div>
      ))}
    </>
  );
};

export default RoomsAndAlternativeRooms;
